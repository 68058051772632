/*
import it from './it'
import en from './en'
import es from './es'
import fr from './fr'
import nl from './nl'
import de from './de'

export default {
  it: it,
  en: en,
  fr: fr,
  es: es,
  de: de,
  nl: nl
}
*/

export default {
  it: {
    generic: {
      close: 'Chiudi',
      save: 'Salva',
      confirm: 'Conferma',
      cancel: 'Annulla',
      delete: 'Elimina',
      article: 'Articolo',
      no_search_results: 'Nessun risultato trovato'
    }
  },
  en: {
    generic: {
      close: 'Close',
      save: 'Save',
      confirm: 'Confirm',
      cancel: 'Cancel',
      delete: 'Delete',
      article: 'Item',
      no_search_results: 'No results found'
    }
  },
  fr: {
    generic: {
      close: 'Fermer',
      save: 'Enregistrer',
      confirm: 'Confirmer',
      cancel: 'Annuler',
      delete: 'Effacer',
      article: 'Produit',
      no_search_results: 'Aucun résultat trouvé'
    }
  },
  es: {
    generic: {
      close: 'Cerrar',
      save: 'Guardar',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
      delete: 'Eliminar',
      article: 'Artículo',
      no_search_results: 'No se han encontrado resultados'
    }
  },
  de: {
    generic: {
      close: 'Schließen',
      save: 'Speichern',
      confirm: 'Bestätigen',
      cancel: 'Stornieren',
      delete: 'Löschen',
      article: 'Artikel',
      no_search_results: 'Es konnten leider keine Suchergebnisse gefunden werden'
    }
  },
  nl: {
    generic: {
      close: 'Sluit',
      save: 'Bewaar',
      confirm: 'Bevestig',
      cancel: 'Annuleer',
      delete: 'Verwijder',
      article: 'Artikel',
      no_search_results: 'Geen resultaat gevonden'
    }
  }
}
