import GalleryAPI from '../api/gallery'

const FETCHING_IMAGES_LIST = 'FETCHING_IMAGES_LIST'
const FETCHING_IMAGES_LIST_SUCCESS = 'FETCHING_IMAGES_LIST_SUCCESS'
const FETCHING_IMAGES_LIST_ERROR = 'FETCHING_IMAGES_LIST_ERROR'

const FETCHING_IMAGES_SEARCH = 'FETCHING_IMAGES_SEARCH'
const FETCHING_IMAGES_SEARCH_SUCCESS = 'FETCHING_IMAGES_SEARCH_SUCCESS'
const FETCHING_IMAGES_SEARCH_ERROR = 'FETCHING_IMAGES_SEARCH_ERROR'

const UPLOADING_IMAGE = 'UPLOADING_IMAGE'
const UPLOADING_IMAGE_SUCCESS = 'UPLOADING_IMAGE_SUCCESS'
const UPLOADING_IMAGE_ERROR = 'UPLOADING_IMAGE_ERROR'

const REMOVING_TAG = 'REMOVING_TAG'
const REMOVING_TAG_SUCCESS = 'REMOVING_TAG_SUCCESS'
const REMOVING_TAG_ERROR = 'REMOVING_TAG_ERROR'

const UPDATING_TAGS = 'UPDATING_TAGS'
const UPDATING_TAGS_SUCCESS = 'UPDATING_TAGS_SUCCESS'
const UPDATING_TAGS_ERROR = 'UPDATING_TAGS_ERROR'

const DELETING_IMAGES = 'DELETING_IMAGES'
const DELETING_IMAGES_SUCCESS = 'DELETING_IMAGES_SUCCESS'
const DELETING_IMAGES_ERROR = 'DELETING_IMAGES_ERROR'

const DELETING_OWN_IMAGE = 'DELETING_OWN_IMAGE'
const DELETING_OWN_IMAGE_SUCCESS = 'DELETING_OWN_IMAGE_SUCCESS'
const DELETING_OWN_IMAGE_ERROR = 'DELETING_OWN_IMAGE_ERROR'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    imagesList: [],
    gallery: []
  },
  getters: {
    deleteImages (state) {
      return state.deleteImages
    },
    updateTags (state) {
      return state.updateTags
    },
    removeTag (state) {
      return state.removeTag
    },
    imagesList (state) {
      return state.imagesList
    },
    gallery (state) {
      return state.gallery
    },
    isLoading (state) {
      return state.isLoading
    },
    hasError (state) {
      return state.error !== null
    },
    error (state) {
      return state.error
    },
    errorMessage (state) {
      return state.error.response.data.detail
    }
  },
  mutations: {
    [FETCHING_IMAGES_LIST] (state) {
      state.isLoading = true
      state.imagesList = []
      state.error = null
    },
    [FETCHING_IMAGES_LIST_SUCCESS] (state, data) {
      state.isLoading = false
      state.imagesList = data
      state.error = null
    },
    [FETCHING_IMAGES_LIST_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [FETCHING_IMAGES_SEARCH] (state) {
      state.isLoading = true
      state.imagesList = []
      state.error = null
    },
    [FETCHING_IMAGES_SEARCH_SUCCESS] (state, data) {
      state.isLoading = false
      state.imagesList = data
      state.error = null
    },
    [FETCHING_IMAGES_SEARCH_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [UPLOADING_IMAGE] (state) {
      state.isLoading = true
      state.gallery = []
      state.error = null
    },
    [UPLOADING_IMAGE_SUCCESS] (state, data) {
      state.isLoading = false
      state.gallery.push(data)
      state.error = null
    },
    [UPLOADING_IMAGE_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [REMOVING_TAG] (state) {
      state.isLoading = true
      state.gallery = []
      state.error = null
    },
    [REMOVING_TAG_SUCCESS] (state, data) {
      state.isLoading = false
      state.gallery = data
      state.error = null
    },
    [REMOVING_TAG_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [UPDATING_TAGS] (state) {
      state.isLoading = true
      state.gallery = []
      state.error = null
    },
    [UPDATING_TAGS_SUCCESS] (state, data) {
      state.isLoading = false
      state.gallery = data
      state.error = null
    },
    [UPDATING_TAGS_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [DELETING_IMAGES] (state) {
      state.isLoading = true
      state.gallery = []
      state.error = null
    },
    [DELETING_IMAGES_SUCCESS] (state, data) {
      state.isLoading = false
      state.gallery = data
      state.error = null
    },
    [DELETING_IMAGES_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [DELETING_OWN_IMAGE] (state) {
      state.isLoading = true
      state.error = null
    },
    [DELETING_OWN_IMAGE_SUCCESS] (state) {
      state.isLoading = false
      state.error = null
    },
    [DELETING_OWN_IMAGE_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    }
  },

  actions: {
    async imagesList ({ commit }) {
      commit(FETCHING_IMAGES_LIST)
      try {
        const response = await GalleryAPI.imagesList()
        commit(FETCHING_IMAGES_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_IMAGES_LIST_ERROR, error)
        return null
      }
    },

    async search ({ commit }, data) {
      commit(FETCHING_IMAGES_SEARCH)
      try {
        const response = await GalleryAPI.search(data)
        commit(FETCHING_IMAGES_SEARCH_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_IMAGES_SEARCH_ERROR, error)
        return null
      }
    },

    async upload ({ commit }, data) {
      commit(UPLOADING_IMAGE)
      try {
        const response = await GalleryAPI.upload(data)
        commit(UPLOADING_IMAGE_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(UPLOADING_IMAGE_ERROR, error)
        return null
      }
    },

    async removeTag ({ commit }, data) {
      commit(REMOVING_TAG)
      try {
        const response = await GalleryAPI.removeTag(data)
        commit(REMOVING_TAG_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(REMOVING_TAG_ERROR, error)
        return null
      }
    },

    async updateTags ({ commit }, data) {
      commit(UPDATING_TAGS)
      try {
        const response = await GalleryAPI.updateTags(data)
        commit(UPDATING_TAGS_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(UPDATING_TAGS_ERROR, error)
        return null
      }
    },

    async deleteImages ({ commit }, data) {
      commit(DELETING_IMAGES)
      try {
        const response = await GalleryAPI.deleteImages(data)
        commit(DELETING_IMAGES_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(DELETING_IMAGES_ERROR, error)
        return null
      }
    },

    async deleteOwnImage ({ commit }, data) {
      commit(DELETING_OWN_IMAGE)
      try {
        const response = await GalleryAPI.deleteOwnImage(data)
        commit(DELETING_OWN_IMAGE_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(DELETING_OWN_IMAGE_ERROR, error)
        return null
      }
    }
  }
}
