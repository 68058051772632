export default (() => {
  let isApiSetUp = false

  return (options, loadCn) => {
    if (typeof document === 'undefined') {
      // Do nothing if run from server-side
      return
    }

    if (!isApiSetUp) {
      isApiSetUp = true

      const googleMapScript = document.createElement('SCRIPT')

      // Allow options to be an object.
      // This is to support more esoteric means of loading Google Maps,
      // such as Google for business
      // https://developers.google.com/maps/documentation/javascript/get-api-key#premium-auth
      if (typeof options !== 'object') {
        throw new Error('options should  be an object')
      }

      options.callback = 'googleMapsInit'

      let baseUrl = 'https://maps.googleapis.com/'

      if (typeof loadCn === 'boolean' && loadCn === true) {
        baseUrl = 'https://maps.google.cn/'
      }

      const query = Object.keys(options)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(options[key])}`
        )
        .join('&')

      const url = `${baseUrl}maps/api/js?${query}`

      googleMapScript.setAttribute('src', url)
      googleMapScript.setAttribute('async', '')
      googleMapScript.setAttribute('defer', '')
      document.head.appendChild(googleMapScript)
    } else {
      throw new Error('You already started the loading of google maps')
    }
  }
})()
