import axios from 'axios'

export default {
  listUsers () {
    return axios.get('/api/users/list-login')
  },
  listAgents () {
    return axios.get('/api/users/list-agents')
  },
  listAreaAgents () {
    return axios.get('/api/users/list-area-agent')
  },
  setUserAgent (data) {
    return axios.post('/api/users/set-user-agent', data)
  },
  setNations (data) {
    return axios.post('/api/users/set-nations', data)
  },
  setListini (data) {
    return axios.post('/api/users/set-listini', data)
  },
  saveTree (data) {
    return axios.post('/api/users/agents/set-tree', data)
  },
  listAreaDefinition () {
    return axios.get('/api/reports/get-areas-def')
  },
  agentsVisibleToAgent () {
    return axios.get('/api/users/agents/agentsvisibletoagent')
  },
  galleryAgents () {
    return axios.get('/api/users/agents/galleryagents')
  },
  nationsVisibleToAgent () {
    return axios.get('/api/users/agents/nationsvisibletoagent')
  },
  microAreasVisibleToAgent () {
    return axios.get('/api/users/agents/microareasvisibletoagent')
  },
  agentShippingAddress () {
    return axios.get('/api/users/agents/agentshippingaddress')
  },
  editMicroArea (data) {
    return axios.post('/api/users/agents/editmicroarea', data)
  },
  addMicroArea (data) {
    return axios.post('/api/users/agents/addmicroarea', data)
  },
  deleteMicroArea (data) {
    return axios.post('/api/users/agents/deletemicroarea', data)
  },
  areasVisibleToAgent () {
    return axios.get('/api/users/agents/areasvisibletoagent')
  },
  areasVisibleToSubAgent (agentId) {
    return axios.get('/api/users/agents/areasvisibletosubagent/' + agentId)
  },
  setLanguage (data) {
    return axios.post('/api/users/agents/set-language', data)
  }
}
