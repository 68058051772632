var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"items":_vm.areas,"item-text":"name","item-value":"cd_agent","label":_vm.label,"placeholder":_vm.$t('messages.Seleziona un Area'),"multiple":_vm.multiple,"clearable":_vm.clearable,"filled":_vm.filled,"attach":_vm.attach?'#attachAreaSelector':false,"readonly":_vm.readonly,"filter":_vm.filterAreas,"hide-details":"","menu-props":{
    maxHeight:200,
  }},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.confirmEvent.apply(null, arguments)}},scopedSlots:_vm._u([(_vm.attach)?{key:"append-outer",fn:function(){return [_c('div',{attrs:{"id":"attachAreaSelector"}})]},proxy:true}:null,{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.cd_agent)+" - "+_vm._s(item.name)+" ")])]}},{key:"selection",fn:function(ref){
  var item = ref.item;
  var index = ref.index;
return [(_vm.multiple)?[(index === _vm.selectedArea.length - 1)?[_vm._v(" "+_vm._s(item.cd_agent)+" - "+_vm._s(item.name)+" ")]:[_vm._v(" "+_vm._s(item.cd_agent)+" - "+_vm._s(item.name)+", "+_vm._s(' ')+" ")]]:[_vm._v(" "+_vm._s(item.cd_agent)+" - "+_vm._s(item.name)+" ")]]}}],null,true),model:{value:(_vm.selectedArea),callback:function ($$v) {_vm.selectedArea=$$v},expression:"selectedArea"}},[_c('template',{slot:"no-data"},[_c('v-list-item',[_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t('messages.Nessun Area presente'))+" ")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }