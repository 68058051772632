<template>
  <div
    class="container px-0 px-sm-3"
    :style="$vuetify.breakpoint.lgOnly ? 'max-width: 1250px;' : ''"
  >
    <div class="d-flex align-center justify-space-around ml-1">
      <h1>{{ $t('messages.Agente Esteso') }}</h1>
    </div>
    <v-progress-circular
      v-if="isInitializing > 0"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <div>
        <v-btn
          v-if="isSupervisor && false"
          color="#217346"
          dark
          class="btn btn-lg mb-2 ml-1"
          type="button"
          elevation="0"
          tile
          :loading="exportingToExcel"
          @click="exportToExcel"
        >
          <v-icon
            class="mr-1"
          >
            mdi-microsoft-excel
          </v-icon>
          {{ $t('messages.Esporta in Excel') }}
        </v-btn>
        <h3>
          {{ $t('messages.Evidenzia') }}:
        </h3>
        <v-radio-group
          v-model="highlight"
          class="mt-0"
          hide-details
          row
        >
          <v-radio
            :label="$t('messages.Anno Precedente')"
            value="last"
          />
          <v-radio
            :label="$t('messages.Anno Corrente')"
            value="now"
          />
          <v-radio
            :label="$t('messages.Differenza')"
            value="difference"
          />
        </v-radio-group>

        <v-switch
          v-model="hideUnhighlighted"
          :label="$t('messages.Nascondi non evidenziati')"
          class="mt-0"
          hide-details
        />
        <agent-selector
          v-model="filter.selectedAgent"
          :agents="agentsVisibleToAgent"
          :label="$t('messages.Filtra agente')"
          :readonly="isLoading"
          @input="getReportAgenteEsteso"
        />
        <area-selector
          v-model="filter.selectedArea"
          :areas="areasVisibleToAgent"
          :label="$t('messages.Filtra area')"
          :readonly="isLoading"
          @input="getReportAgenteEsteso"
        />
        <v-select
          v-model="filter.selectedYear"
          :items="selectableYears"
          :label="$t('messages.Anno')"
          :readonly="isLoading"
          hide-details
          filled
          @input="getReportAgenteEsteso"
        />

        <v-progress-circular
          v-if="isLoading"
          indeterminate
          color="primary"
          class="ml-3 mt-4"
        />
        <div v-else-if="reportBroad">
          <div
            v-for="serie in Object.keys(reportBroad)"
            :key="serie"
          >
            <div class="d-flex align-center mt-4">
              <v-avatar
                color="primary"
                class="ml-2 mt-1"
                size="28"
                :style="'z-index: 2;position: sticky;top: 113px;'"
              >
                <span
                  class="white--text"
                  style="font-size: 0.875rem; font-weight: bold;"
                >{{ reportBroad[serie].nomeShort }}</span>
              </v-avatar>
              <h5 class="ml-2">
                {{ reportBroad[serie].nome }}
              </h5>
            </div>

            <div
              style="overflow-x: auto; overflow-y: hidden;"
              class="mx-1"
            >
              <table class="mt-1">
                <thead>
                  <tr>
                    <th
                      v-for="(hr, l) in headers"
                      :key="l"
                      :class="hr.class"
                      :style="hr.width?('min-width: ' + hr.width):''"
                    >
                      {{ hr.text == $t('messages.Colore') ? '' : hr.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="row in Object.keys(reportBroad[serie].data)"
                    :key="row"
                  >
                    <td>
                      <span class="report-colore">
                        {{ row }}
                      </span>
                    </td>
                    <td
                      v-for="month in months"
                      :key="month"
                      class="text-right"
                      :style="'min-width: 83px'"
                    >
                      <!-- eslint-disable vue/no-v-html -->
                      <div
                        class="d-flex flex-column"
                      >
                        <div
                          v-if="!hideUnhighlighted || highlight=='last'"
                          class="d-flex justify-space-between"
                          style="font-weight: bold; font-size: 0.765rem;"
                        >
                          <span
                            v-if="reportBroad[serie].currency"
                            style="color: #999;font-weight: normal;"
                          >€</span>
                          <span v-else>&nbsp;</span>
                          <div
                            class="reportval text-right"
                            :class="highlight=='last' ? 'highlight':''"
                            v-html="euroFormat(reportBroad[serie].data[row][month+'1'])"
                          />
                        </div>
                        <div
                          v-if="!hideUnhighlighted || highlight=='now'"
                          class="d-flex justify-space-between"
                          style="font-weight: bold; font-size: 0.765rem;"
                        >
                          <span
                            v-if="reportBroad[serie].currency"
                            style="color: #999;font-weight: normal;"
                          >€</span>
                          <span v-else>&nbsp;</span>
                          <div
                            class="reportval text-right"
                            :class="highlight=='now' ? 'highlight':''"
                            v-html="euroFormat(reportBroad[serie].data[row][month+'2'])"
                          />
                        </div>
                        <div
                          v-if="!hideUnhighlighted || highlight=='difference'"
                          class="d-flex justify-space-between"
                          style="font-weight: bold; font-size: 0.765rem;"
                        >
                          <span
                            v-if="reportBroad[serie].currency"
                            style="color: #999;font-weight: normal;"
                          >€</span>
                          <span v-else>&nbsp;</span>
                          <div
                            class="reportval text-right"
                            :class="(highlight=='difference' ? 'highlight':'') + ((
                              reportBroad[serie].data[row][month+'2']-reportBroad[serie].data[row][month+'1']
                            )<0?' reportval-negative':'')"
                            v-html="euroFormat(reportBroad[serie].data[row][month+'2']-reportBroad[serie].data[row][month+'1'])"
                          />
                        </div>
                        <div
                          v-if="!hideUnhighlighted || highlight=='difference'"
                          class="d-flex justify-end"
                          style="font-weight: bold; font-size: 0.765rem;"
                        >
                          <div
                            class="reportval text-right"
                            :class="(highlight=='difference' ? 'highlight':'') + ((
                              reportBroad[serie].data[row][month+'2']-reportBroad[serie].data[row][month+'1']
                            )<0?' reportval-negative':'')"
                          >
                            {{ (reportBroad[serie].data[row][month+'1']) == 0 ? '-' : (100*(reportBroad[serie].data[row][month+'2']-reportBroad[serie].data[row][month+'1'])/reportBroad[serie].data[row][month+'1']).toFixed(1) + ' %' }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgentSelector from '../../components/AgentSelector'
import AreaSelector from '../../components/AreaSelector'

export default {
  name: 'ReportBroad',
  components: {

    AgentSelector,
    AreaSelector
  },

  data: () => ({
    reportBroad: null,
    isLoading: false,
    isInitializing: 3,
    exportingToExcel: false,
    months: ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic'],
    filter: {
      selectedArea: '',
      selectedAgent: '',
      selectedYear: ''
    },
    agentsVisibleToAgent: [],
    areasVisibleToAgent: [],
    highlight: 'now',
    hideUnhighlighted: false
  }),

  computed: {
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    headers () {
      return [
        { text: '', value: 'nome' },
        { text: this.$t('messages.Gen'), value: 'gen1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Feb'), value: 'feb1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Mar'), value: 'mar1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Apr'), value: 'apr1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Mag'), value: 'mag1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Giu'), value: 'giu1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Lug'), value: 'lug1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Ago'), value: 'ago1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Set'), value: 'set1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Ott'), value: 'ott1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Nov'), value: 'nov1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Dic'), value: 'dic1', align: 'right', width: '83px', filterable: false }
      ]
    },
    selectableYears () {
      const currentYear = new Date().getFullYear()
      return [
        (currentYear - 2) + '',
        (currentYear - 1) + '',
        currentYear + ''
      ]
    },
    isSupervisor () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN') || this.$store.getters['security/hasRole']('ROLE_AGENT_ADMIN')
    }
  },
  async created () {
    this.isInitializing = 2
    this.$store.dispatch('agents/agentsVisibleToAgent').then((r) => { this.isInitializing--; this.agentsVisibleToAgent = r })
    this.$store.dispatch('agents/areasVisibleToAgent').then((r) => { this.isInitializing--; this.areasVisibleToAgent = r })
    this.filter.selectedYear = this.selectableYears[2]
    this.getReportAgenteEsteso()
  },
  methods: {
    euroFormat (value) {
      value = parseFloat(value)
      if (!value) return '&nbsp;'
      return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },

    async getReportAgenteEsteso () {
      this.isLoading = true

      this.reportBroad = await this.$store.dispatch('reports/getReportAgenteEsteso', { filters: this.filter })

      this.isLoading = false
    },
    async exportToExcel () {
      this.exportingToExcel = true
      const tHeader = ['Cd_Area',
        'Area',
        'Categoria',
        'Serie',
        'Colore',
        'Tipo',
        'Tot',
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic']
      const exceldata = []
      const months = ['tot1', 'gen1', 'feb1', 'mar1', 'apr1', 'mag1', 'giu1', 'lug1', 'ago1', 'set1', 'ott1', 'nov1', 'dic1']
      this.filteredReport.forEach((agent, i) => {
        agent.superclass.forEach(superclass => {
          superclass.series.forEach(s => {
            s.colors.forEach((color) => {
              let rowData = [agent.area, agent.name, superclass.name, s.name, color.colore]
              rowData.push('EUR')
              for (let index = 0; index < months.length; index++) {
                rowData.push(color[months[index]])
              }
              exceldata.push(rowData)
              rowData = [agent.area, agent.name, superclass.name, s.name, color.colore]
              rowData.push('MQ')
              for (let index = 0; index < months.length; index++) {
                rowData.push(color['mq' + months[index]])
              }
              exceldata.push(rowData)
              rowData = [agent.area, agent.name, superclass.name, s.name, color.colore]
              rowData.push('QTA')
              for (let index = 0; index < months.length; index++) {
                rowData.push(color['qta' + months[index]])
              }
              exceldata.push(rowData)
            })
          })
        })
      })
      setTimeout(() => {
        import('../../vendor/Export2Excel').then(excel => {
          excel.export_json_to_excel({
            header: tHeader, // Header Required
            data: exceldata, // Specific data Required
            filename: this.$t('messages.Fatturato Colore'), // Optional
            autoWidth: true, // Optional
            bookType: 'xlsx' // Optional
          })
          this.exportingToExcel = false
        })
      }, 500)
    }
  }
}
</script>

<style scoped>
table > thead > tr > th, table > tbody > tr > td {
  padding: 0px;
  padding-right: 4px;
  padding-left: 4px;
}
.reportval {
  font-size: 0.665rem;
}
.reportval.highlight {
  font-weight: bold;
  font-size: 0.875rem;
}
.reportval.highlight.reportval-negative {
  color: red
}
table th {
  position: sticky;
  top: 0;
}
table th:first-child {
  position: sticky;
  left: 0;
  top: 0;
  background-color: #ddd;
  margin-right: 4px;
  padding-right: 4px;
  max-width: 160px;
  width: 120px;
  z-index: 1;
}
table td:first-child {
  position: sticky;
  left: 0;
  background-color: #ddd;
  margin-right: 4px;
  padding-right: 4px;
  min-width: 120px;
  max-width: 160px;
}
table td:nth-child(2n+3) {background: rgb(230, 230, 230)}
.report-azienda {
  font-size: 0.665rem;
  font-weight: bold;
}
.report-colore {
  font-size: 0.665rem;
  font-weight: bold;
}
.report-cdclien {
  font-size: 0.665rem;
  font-weight: bold;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 40px;
}
.v-expansion-panel > .v-expansion-panel-header {
  min-height: 40px;
}
.accordion-title {
  font-size: 0.7rem !important;
}
table > tbody > tr > th, table > thead > tr > th, table > tfoot > tr > th {
    font-size: 0.75rem;
}

.agent-header {
  background-color: #5e5e5e;
  min-height: 21px;
  color: white;
  font-size: 0.875rem;
}

table > thead > tr:last-child > th {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
table {
    border-spacing: 0;
}
::v-deep.v-pagination__navigation {
  width: 24px;
  height: 24px;
}
::v-deep.v-pagination__item {
  min-width: 24px;
  height: 24px;
}

</style>

<style>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
