export default {
  it: {
    cart: {
      order_success: 'Grazie! L\'ordine è stato preso in carico dal sistema',
      mail_order_confirm: 'Una mail di conferma è stata inviata all\'indirizzo {mail}',
      back_to_shop: 'Torna allo shop',
      empty_cart_message: 'Il tuo carrello è vuoto',
      go_to_shop_message: 'Vai allo shop per aggiungere prodotti al tuo carrello!',
      go_to_shop: 'Vai allo shop',
      cart: 'Carrello',
      data: 'DATI', // dati di spedizione
      proceed: 'PROCEDI',
      back: 'INDIETRO',
      items: 'Elementi',
      summary: 'Riepilogo',
      shipping: 'Spedizione',
      extra_notes: 'Note Extra',

      select_shipping: 'Seleziona indirizzo di spedizione',
      select_new: 'Inserisci nuovo',
      shipping_data: 'Dati di Spedizione',
      ditta: 'Ragione Sociale / Nome',
      via: 'Via e numero civico',
      citta: 'Città',
      cap: 'CAP',
      provincia: 'Provincia',
      telefono: 'Telefono',
      nazione: 'Nazione',

      invoice_data: 'Dati di Fatturazione',
      piva: 'Partita IVA',
      azienda: 'Ragione Sociale',
      indirizzo: 'Indirizzo',
      sdi: 'Codice SDI',
      value_mandatory: 'Obbligatorio.',
      email_not_valid: 'Formato e-mail non valido.',
      terms: 'Ho letto e accetto le condizioni generali di vendita',

      legend: 'Legenda',
      product_available: 'Prodotto disponibile',
      request_availability: 'Richiedere disponibilità',
      coming_soon: 'Prossimamente disponibile',

      copy_billing_data: 'Copia dati di fatturazione',
      different_shipping: 'Spedisci ad un indirizzo diverso da quello di fatturazione',
      bank: 'Banca',
      iban: 'IBAN'
    }
  },
  en: {
    cart: {
      order_success: 'Thank you, your order is being processed',
      mail_order_confirm: 'A confirmation email has been sent to {mail}',
      back_to_shop: 'Return to the shop',
      empty_cart_message: 'Your basket is empty',
      go_to_shop_message: 'Continue shopping',
      go_to_shop: 'Go to the shop',
      cart: 'Shopping basket',
      data: 'SHIPPING DETAILS', // dati di spedizione
      proceed: 'PROCEED',
      back: 'BACK',
      items: 'Elements',
      summary: 'Summary',
      shipping: 'Shipment',
      extra_notes: 'Further information',

      select_shipping: 'Select shipping address',
      select_new: 'Insert new address',
      shipping_data: 'Shipping details',
      ditta: 'Company name / Name',
      via: 'Street Address',
      citta: 'City',
      cap: 'Postcode',
      provincia: 'County',
      telefono: 'Phone',
      nazione: 'Country',

      invoice_data: 'Billing information',
      piva: 'Tax-Number',
      azienda: 'Company name',
      indirizzo: 'Address',
      sdi: 'Codice SDI',
      value_mandatory: 'Mandatory field.',
      email_not_valid: 'Invalid email format.',
      terms: 'I have read and accepted the general sales conditions',

      legend: 'Legend',
      product_available: 'Product available ',
      request_availability: 'Ask for availability',
      coming_soon: 'Coming soon',

      copy_billing_data: 'Copy billing data',
      different_shipping: 'Ship to an address other than the billing address',
      bank: 'Bank',
      iban: 'IBAN'
    }
  },
  fr: {
    cart: {
      order_success: 'Merci! La commande a été prise en charge par le système',
      mail_order_confirm: 'Un e-mail de confirmation a été envoyé à {mail}',
      back_to_shop: 'Retour à la boutique',
      empty_cart_message: 'Votre panier est vide',
      go_to_shop_message: 'Accédez à la boutique pour ajouter des produits à votre panier!',
      go_to_shop: 'Aller à la boutique',
      cart: 'Panier',
      data: 'DETAILS DE LIVRAISON', // dati di spedizione
      proceed: 'PROCÉDER',
      back: 'EN ARRIÈRE',
      items: 'Éléments',
      summary: 'Sommaire',
      shipping: 'Livraison',
      extra_notes: 'Informations supplémentaires',

      select_shipping: 'Sélectionnez l\'adresse de livraison',
      select_new: 'Entrez une nouvelle adresse de livraison',
      shipping_data: 'Détails de livraison',
      ditta: 'Nom de la société/Nom',
      via: 'Adresse',
      citta: 'Ville',
      cap: 'Code postal',
      provincia: 'département',
      telefono: 'Téléphone',
      nazione: 'Pays',

      invoice_data: 'Détails de facturation',
      piva: 'Numéro de TVA',
      azienda: 'Nom de l\'entreprise',
      indirizzo: 'Adresse',
      sdi: 'Codice SDI',
      value_mandatory: 'Champ obligatoire.',
      email_not_valid: 'Format d\'email invalide.',
      terms: 'J\'ai lu et j\'accepte les Conditions générales de vente',

      legend: 'Légende',
      product_available: 'Produit disponible',
      request_availability: 'Demande de disponibilité',
      coming_soon: 'Bientôt disponible',

      copy_billing_data: 'Copier les données de facturation',
      different_shipping: 'Expédier à une adresse autre que l\'adresse de facturation',
      bank: 'Banque',
      iban: 'IBAN'
    }
  },
  es: {
    cart: {
      order_success: '¡Gracias! El pedido ha sido tramitado',
      mail_order_confirm: 'Se ha enviado un correo electrónico de confirmación a {mail}',
      back_to_shop: 'Vuelve a la tienda',
      empty_cart_message: 'Su cesta está vacía',
      go_to_shop_message: '¡Ve a la tienda para agregar productos a tu carrito!',
      go_to_shop: 'Ve a la tienda',
      cart: 'Carrito de compras',
      data: 'DATOS', // dati di spedizione
      proceed: 'CONTINUAR',
      back: 'ATRÁS',
      items: 'Elementos',
      summary: 'Resumen',
      shipping: 'Envío',
      extra_notes: 'Notas adicionales',

      select_shipping: 'Seleccionar dirección de envío',
      select_new: 'Insertar nuevo',
      shipping_data: 'Datos de envío',
      ditta: 'Nombre de la empresa/Nombre',
      via: 'Dirección',
      citta: 'Ciudad',
      cap: 'CÓDIGO POSTAL',
      provincia: 'Provincia',
      telefono: 'Teléfono',
      nazione: 'Nación',

      invoice_data: 'Datos de facturación',
      piva: 'CIF',
      azienda: 'Nombre Fiscal',
      indirizzo: 'Dirección',
      sdi: 'Codice SDI',
      value_mandatory: 'Obligatorio.',
      email_not_valid: 'Formato de correo inválido.',
      terms: 'He leído y acepto las Condiciones Generales de Venta',

      legend: 'Leyenda',
      product_available: 'Producto disponible',
      request_availability: 'Solicitud de disponibilidad',
      coming_soon: 'Disponible próximamente',

      copy_billing_data: 'Copiar datos de facturación',
      different_shipping: 'Enviar a una dirección que no sea la dirección de facturación',
      bank: 'Banco',
      iban: 'IBAN'
    }
  },
  de: {
    cart: {
      order_success: 'Vielen Dank! Die Bestellung wurde erfolgreich vom System übernommen',
      mail_order_confirm: 'Eine Bestätigung wurde per E-Mail an Ihre E-Mailadresse {mail} gesendet',
      back_to_shop: 'Zurück zum Shop',
      empty_cart_message: 'Dein Warenkorb ist leer',
      go_to_shop_message: 'Gehen Sie in den Shop, um Produkte in Ihren Warenkorb hinzuzufügen!',
      go_to_shop: 'Zum Shop',
      cart: 'Warenkorb',
      data: 'Versanddaten', // dati di spedizione
      proceed: 'FORTFAHREN',
      back: 'ZURÜCK',
      items: 'Elemente',
      summary: 'Zusammenfassung',
      shipping: 'Versand',
      extra_notes: 'Weitere Informationen',

      select_shipping: 'Lieferadresse wählen',
      select_new: 'Neue Lieferadresse angeben',
      shipping_data: 'Versanddaten',
      ditta: 'Firmendaten/Name',
      via: 'Adresse',
      citta: 'Stadt',
      cap: 'PLZ',
      provincia: 'Bundesland',
      telefono: 'Tel-Nr.',
      nazione: 'Staat',

      invoice_data: 'Rechnungsdaten',
      piva: 'UST-Id-Nr.',
      azienda: 'Firmendaten',
      indirizzo: 'Adresse',
      sdi: 'Codice SDI',
      value_mandatory: 'Pflichtfeld.',
      email_not_valid: 'Ungültiges Email-Format.',
      terms: 'Ich habe die Allgemeine Geschäftsbedingungen gelesen und akzeptiere sie',

      legend: 'Legende',
      product_available: 'Verfügbar',
      request_availability: 'Nach der Verfügbarkeit fragen',
      coming_soon: 'Demnächst verfügbar',

      copy_billing_data: 'Rechnungsdaten kopieren',
      different_shipping: 'Versand an eine andere Adresse als die Rechnungsadresse',
      bank: 'Bank',
      iban: 'IBAN'
    }
  },
  nl: {
    cart: {
      order_success: 'Bedankt! De bestelling is door het systeem aangenomen',
      mail_order_confirm: 'Er is een bevestigingsmail gestuurd naar het email adres {mail}',
      back_to_shop: 'Terug naar de winkel',
      empty_cart_message: 'Uw winkelwagen is leeg',
      go_to_shop_message: 'Ga naar de winkel om producten aan de winkelwagen toe te voegen!',
      go_to_shop: 'Ga naar de winkel',
      cart: 'Winkelwagen',
      data: 'Verzendgegevens', // dati di spedizione
      proceed: 'Ga door',
      back: 'Ga terug',
      items: 'Elementen',
      summary: 'Overzicht',
      shipping: 'Verzending',
      extra_notes: 'Extra opmerkingen',

      select_shipping: 'Selecteer verzendadres',
      select_new: 'Voer nieuw in',
      shipping_data: 'Verzendgegevens',
      ditta: 'Bedrijfsnaam / Naam',
      via: 'Straat en nummer',
      citta: 'Stad',
      cap: 'Postcode',
      provincia: 'Provincie',
      telefono: 'Telefoon',
      nazione: 'Natie',

      invoice_data: 'Factuur gegevens',
      piva: 'BTW-nummer',
      azienda: 'Bedrijfsnaam',
      indirizzo: 'Indirizzo',
      sdi: 'Adres',
      value_mandatory: 'Verplicht veld',
      email_not_valid: 'Ongeldige email formaat.',
      terms: 'Ik heb de algemene verkoopvoorwaarden gelezen en ga ermee akkoord',

      legend: 'Legende',
      product_available: 'Product beschikbaar',
      request_availability: 'Vraag de beschikbaarheid',
      coming_soon: 'Binnenkort beschikbaar',

      copy_billing_data: 'Kopieer factuurgegevens',
      different_shipping: 'Verzenden naar een ander adres dan het factuuradres',
      bank: 'Bank',
      iban: 'IBAN'
    }
  }
}
