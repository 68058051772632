<template>
  <v-container class="pa-1">
    <v-progress-circular
      v-if="isInitializing != 0 || isSending"
      indeterminate
      color="primary"
      class="mt-3 ml-3"
    />
    <div
      v-else-if="orderSuccess"
    >
      <div>
        <h4>{{ $t('messages.Ordine Effettuato') }}</h4>
      </div>
      {{ $t('messages.Una mail di riepilogo è stata mandata a #mail#', [notifyMail]) }}
    </div>
    <div
      v-else
    >
      <div class="d-flex mt-2 mb-2">
        <client-selector
          v-model="orderDetails.client_id"
          class="flex-grow-1 persistent-placeholder"
          :clients="clientsListMinimal"
          @input="loadClient"
        />
      </div>
      <div
        v-if="loadingClientDetailsFromServer"
        class="d-flex justify-center mt-2 mb-2"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          class="mt-3 ml-3"
        />
      </div>
      <div v-else-if="!loadingClientDetailsFromServer && client">
        <v-switch
          v-model="shipToDifferent"
          :label="$t('messages.Spedisci ad un indirizzo diverso da quello di fatturazione')"
        />
        <div
          v-if="shipToDifferent"
          style="overflow: hidden;"
        >
          <div class="d-flex">
            <v-select
              v-model="cmbShipping"
              :items="client.indirizzi"
              item-value="id"
              class="font-class"
              :label="$t('messages.Seleziona indirizzo di spedizione')"
              @change="shippingChanged"
            >
              <template
                #[`item`]="{ item }"
              >
                <div class="font-class">
                  {{ cmbText(item) }}
                </div>
              </template>
              <template
                #[`selection`]="{ item }"
              >
                <div class="font-class">
                  {{ cmbText(item) }}
                </div>
              </template>
            </v-select>
          </div>
          <v-card
            outlined
            tile
            class="mb-2"
          >
            <v-form
              ref="shippingdata"
              class="ma-2"
            >
              <h3>{{ $t('messages.Dati di spedizione') }}</h3>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    ref="ship1"
                    :value="selectedShipping.azienda"
                    :readonly="selectedShipping.is_apa_sync == 1"
                    :label="$t('messages.Azienda')"
                    :rules="[rules.required]"
                    @input="selectedShipping.azienda = $event"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    ref="ship2"
                    :value="selectedShipping.indirizzo"
                    :readonly="selectedShipping.is_apa_sync == 1"
                    :label="$t('messages.Via e numero civico')"
                    :rules="[rules.required]"
                    @input="selectedShipping.indirizzo = $event"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    ref="ship3"
                    :value="selectedShipping.citta"
                    :readonly="selectedShipping.is_apa_sync == 1"
                    :label="$t('messages.Città')"
                    :rules="[rules.required]"
                    @input="selectedShipping.citta = $event"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    ref="ship4"
                    :value="selectedShipping.cap"
                    :readonly="selectedShipping.is_apa_sync == 1"
                    :label="$t('messages.CAP')"
                    :rules="[rules.required]"
                    @input="selectedShipping.cap = $event"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    ref="ship5"
                    :value="selectedShipping.provincia"
                    :readonly="selectedShipping.is_apa_sync == 1"
                    :label="$t('messages.Provincia')"
                    :rules="[rules.required]"
                    @input="selectedShipping.provincia = $event"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    ref="ship7"
                    :value="selectedShipping.nazione"
                    :readonly="selectedShipping.is_apa_sync == 1"
                    :items="nations"
                    :menu-props="{
                      maxHeight:200,
                    }"
                    item-text="name"
                    item-value="isocode"
                    :loading="isLoadingNations"
                    :label="$t('messages.Nazione')"
                    :rules="[rules.required]"
                    @input="selectedShipping.nazione = $event"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="pb-0"
                >
                  <v-combobox
                    ref="ship8"
                    :value="selectedShipping.type"
                    :items="indirizzoTypes"
                    item-text="text"
                    item-value="value"
                    :readonly="selectedShipping.is_apa_sync == 1"
                    :clearable="selectedShipping.is_apa_sync != 1"
                    :rules="[rules.required]"
                    :label="$t('messages.Tipologia Indirizzo')"
                    @input="selectedShipping.type = $event.value"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </div>

        <v-card
          outlined
          tile
          class="background_custom mb-4"
        >
          <v-form
            ref="invoicedata"
            class="ma-2"
          >
            <h3>{{ $t('messages.Dati di fatturazione') }}</h3>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  :value="client.piva"
                  :label="$t('messages.Partita Iva')"
                  :readonly="client.is_projx == 1"
                  :rules="[rules.requiredInvoice]"
                  @input="client.piva = $event"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  :value="client.codicefiscale"
                  :label="$t('messages.Codice Fiscale')"
                  :readonly="client.is_projx == 1"
                  :rules="client.nazione === 'IT' ? [rules.requiredInvoice] : []"
                  @input="client.codicefiscale = $event"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  :value="client.azienda"
                  :label="$t('messages.Azienda')"
                  :readonly="client.is_projx == 1"
                  :rules="[rules.requiredInvoice]"
                  @input="client.azienda = $event"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  :value="client.indirizzo"
                  :label="$t('messages.Indirizzo')"
                  :readonly="client.is_projx == 1"
                  :rules="[rules.requiredInvoice]"
                  @input="client.indirizzo = $event"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  :value="client.citta"
                  :label="$t('messages.Città')"
                  :readonly="client.is_projx == 1"
                  :rules="[rules.requiredInvoice]"
                  @input="client.citta = $event"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  :value="client.cap"
                  :label="$t('messages.CAP')"
                  :readonly="client.is_projx == 1"
                  :rules="[rules.requiredInvoice]"
                  @input="client.cap = $event"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  :value="client.provincia"
                  :label="$t('messages.Provincia')"
                  :readonly="client.is_projx == 1"
                  :rules="[rules.requiredInvoice]"
                  @input="client.provincia = $event"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  :value="client.telefono"
                  :label="$t('messages.Telefono')"
                  :readonly="client.is_projx == 1"
                  :rules="[rules.requiredInvoice]"
                  @input="client.telefono = $event"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-autocomplete
                  :value="client.nazione"
                  :readonly="client.is_projx == 1"
                  :items="nationsAgente"
                  :menu-props="{
                    maxHeight:200,
                  }"
                  item-text="name"
                  item-value="isocode"
                  :label="$t('messages.Nazione')"
                  :rules="[rules.requiredInvoice]"
                  @input="client.nazione = $event"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  :value="client.iban"
                  :label="$t('messages.IBAN')"
                  :readonly="client.is_projx == 1"
                  @input="client.iban = $event"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  :value="client.banca"
                  :label="$t('messages.Banca')"
                  :readonly="client.is_projx == 1"
                  @input="client.banca = $event"
                />
              </v-col>
              <v-col
                v-if="client.nazione === 'IT'"
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  ref="inv1"
                  :value="client.sdi"
                  :label="$t('messages.SDI')"
                  :readonly="client.is_projx == 1"
                  :rules="[rules.requiredSdi]"
                  @input="client.sdi = $event;$refs.inv2.validate(true);$refs.inv3.validate(true)"
                />
              </v-col>
              <v-col
                v-if="client.nazione === 'IT'"
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  ref="inv2"
                  :value="client.pec"
                  :label="$t('messages.PEC')"
                  :readonly="client.is_projx == 1"
                  :rules="[rules.requiredSdi]"
                  @input="client.pec = $event;$refs.inv1.validate(true);$refs.inv3.validate(true)"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  :value="client.email_order_notify ? client.email_order_notify : client.email"
                  :label="$t('messages.Email Notifica Ordine')"
                  :rules="[rules.email]"
                  @input="client.email_order_notify = $event"
                />
              </v-col>
              <v-col
                v-if="client.nazione === 'IT'"
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-checkbox
                  ref="inv3"
                  :input-value="client.is_forfettario"
                  :readonly="client.is_projx == 1"
                  :label="$t('messages.Regime forfettario')"
                  :rules="[rules.requiredSdi]"
                  @change="client.is_forfettario = !!$event;$refs.inv2.validate(true);$refs.inv1.validate(true)"
                />
              </v-col>
              <v-col
                v-if="client.nazione === 'IT'"
                cols="12"
              >
                <v-expansion-panels
                  tile
                  flat
                  accordion
                  class="pt-4 mt-4"
                >
                  <v-expansion-panel
                    style="border-bottom-color: rgba(0, 0, 0, 0.87);border-bottom-style: solid; border-bottom-width: thin;"
                  >
                    <v-expansion-panel-header
                      color="#eff3f7"
                      class="split-payment-title pa-0"
                      style="color:rgba(0, 0, 0, 0.6);"
                    >
                      <div class="d-flex flex-column">
                        <div style="margin-top:-18px; font-size: 12px">
                          <span>Split Payment</span>
                        </div>
                        <div class="py-2">
                          <span style="color: rgba(0, 0, 0, 0.87); font-size: 16px">
                            {{ client.is_split_payment===1 ? 'Rientriamo tra le società citate dal decreto' : 'Non rientriamo tra le società citate dal decreto' }}
                          </span>
                        </div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-2">
                      <span>
                        Vi chiediamo di volerci cortesemente confermare se la Vostra società rientra tra quelle citate dall’art 1 del D.L. n.50 del 24/04/2017 che ha esteso il meccanismo della scissione dei pagamenti dell’IVA (c.d.Split Payment) a ulteriori categorie di soggetti:
                      </span>
                      <ol>
                        <li>Società controllate direttamente dalla Presidenza del Consiglio dei Ministri e dai Ministeri;</li>
                        <li>Società controllate direttamente dalle regioni, province, città metropolitane, comuni e unione di comuni;</li>
                        <li>Società controllate direttamente o indirettamente dalle società di cui alle precedenti lettere a) e b);</li>
                        <li>Società quotate inserite nell’indice FTSE MIB della Borsa Italiana.</li>
                      </ol>
                      <span>In mancanza di una risposta la Vostra società sarà considerata come non appartenente alle categorie soggette al sistema di liquidazione IVA "Split payment", questo fino a quando eventualmente ci pervenga comunicazione contraria da parte Vostra, in termini utili per l’emissione della fattura.</span>

                      <v-radio-group
                        :value="client.is_split_payment"
                        row
                        :readonly="client.is_projx == 1"
                        @change="client.is_split_payment = $event; changeInvoiceData()"
                      >
                        <v-radio
                          label="Rientriamo tra le società citate dal decreto"
                          :value="1"
                          :readonly="client.is_projx == 1"
                        />
                        <v-radio
                          label="Non rientriamo tra le società citate dal decreto"
                          :value="0"
                          :readonly="client.is_projx == 1"
                        />
                      </v-radio-group>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </div>
      <v-card
        v-for="(article, i) in orderDetails.products"
        :key="i"
        outlined
        class="mb-2"
        tile
      >
        <v-icon
          color="error"
          class=""
          style="background-color: #ffffff9e;border-radius: 50%; position: absolute; right:0px; top:0px"
          @click.stop="article.confirmDialogRemoveProduct = true"
        >
          mdi-close
        </v-icon>
        <confirm-dialog
          v-model="article.confirmDialogRemoveProduct"
          color="error"
          :title="$t('messages.Rimuovere')"
          :description="'Rimuovere il prodotto ' + article.code + '?'"
          @confirm="remove(article); article.confirmDialogRemoveProduct=false"
          @cancel="article.confirmDialogRemoveProduct=false"
        />
        <h4 class="mx-1 mb-3">
          <div
            class="d-flex align-center"
          >
            <v-img
              v-if="hasImage(article)"
              class="article-image my-1 mr-2"
              :src="getImage(article)"
            />
            <div
              v-else-if="hasColor(article)"
              class="article-image my-1 mr-1"
              :style="'background-color: ' + getColor(article)"
            />
            <span
              class="font-md mr-2 mt-4"
              style="min-width:36px;"
            >
              <div class="d-flex flex-column align-center">

                <v-icon
                  :size="13"
                  :color="getAvailabilityColor(article.availability)"
                >
                  mdi-checkbox-blank-circle
                </v-icon>
                <h6 style="">{{ article.discGroup == '' ? '-' : article.discGroup }}</h6>
              </div>
            </span>
            <div>
              {{ article.code }}
            </div>
          </div>
        </h4>
        <div class="d-flex flex-wrap">
          <div class="d-flex">
            <v-select
              v-if="article.articles.standard_codes.length > 0"
              v-model="article.selectedHeight"
              style="min-width: 100px; max-width: 122px;"
              class="my-0 py-0 mx-1 persistent-placeholder"
              :items="article.heights"
              :error-messages="article.selectedHeightMessage"
              :label="$t('messages.Altp')"
              @change="selectedHeightChanged(article); checkAvailability(article)"
            />
            <v-select
              v-if="article.articles.standard_codes.length > 0"
              v-model="article.selectedLength"
              style="min-width: 100px; max-width: 122px;"
              class="my-0 py-0 mx-1 persistent-placeholder"
              :items="article.lengths"
              :error-messages="article.selectedLengthMessage"
              :label="$t('messages.Lunp')"
              @change="selectedLengthChanged(article); checkAvailability(article)"
            />
            <v-text-field
              v-model="article.quantity"
              style="min-width: 50px; max-width: 50px"
              class="my-0 py-0 mx-1 persistent-placeholder"
              :label="$t('messages.Qty')"
              @input="debounceCheckAvailability(article)"
            />
          </div>
          <v-text-field
            v-if="article.articles.standard_codes.length > 0"
            v-model="article.clientCutInstructions"
            style="min-width: 260px;"
            class="my-0 py-0 mx-1 persistent-placeholder"
            :label="$t('messages.Tagli')"
          />
        </div>

        <div class="d-flex">
          <v-text-field
            v-model="article.cutInstructions"
            style="min-width: 260px;"
            class="my-0 py-0 mx-1 persistent-placeholder"
            :label="$t('messages.Note')"
          />
        </div>
      </v-card>

      <div class="d-flex">
        <v-btn
          color="primary"
          class="btn btn-lg btn-primary white--text mb-2 ml-0 flex-grow-1"
          type="button"
          elevation="0"
          tile
          @click="selectingProduct=true"
        >
          <v-icon class="mr-1">
            mdi-plus
          </v-icon>
          {{ $t('messages.prodotto') }}
        </v-btn>
        <v-btn
          color="primary"
          class="btn btn-lg btn-primary white--text mb-2 ml-2 flex-grow-1"
          type="button"
          elevation="0"
          tile
          @click="selectingCampione=true"
        >
          <v-icon class="mr-1">
            mdi-plus
          </v-icon>
          {{ $t('messages.campione') }}
        </v-btn>
      </div>

      <v-textarea
        v-model="orderDetails.notes"
        class="persistent-placeholder mb-2"
        filled
        :label="$t('messages.Note Extra')"
        clearable
        hide-details
      />

      <v-checkbox
        v-model="orderDetails.requestAuth.doRequest"
        :label="$t('messages.Richiedi autorizzazione')"
        class="mb-2"
        hide-details
      />
      <h5
        v-if="orderDetails.requestAuth.doRequest"
        class="mb-3"
      >
        {{ $t('messages.È possibile inserire più mail separate da virgola ( , )') }}
      </h5>
      <v-text-field
        v-if="orderDetails.requestAuth.doRequest"
        ref="requestAuthMail"
        v-model="orderDetails.requestAuth.requestMail"
        style="min-width: 260px;"
        class="my-0 py-0 mx-1 persistent-placeholder"
        :rules="[rules.emailMultipla]"
        :label="$t('messages.Email')"
        hide-details
      />
      <v-btn
        color="success"
        class="btn btn-lg btn-primary white--text mb-2 ml-0 mt-5"
        type="button"
        block
        elevation="0"
        tile
        @click="sendOrder"
      >
        <v-icon class="mr-1">
          mdi-content-save
        </v-icon>
        {{ $t('messages.Conferma ordine') }}
      </v-btn>
      <v-dialog
        v-model="selectingProduct"
        max-width="290"
      >
        <v-card>
          <v-card-text class="pt-4 justify-center">
            <product-selector
              v-model="selectedProduct"
              :products="products"
              :label="$t('messages.Prodotti')"
            />
            <div class="d-flex justify-space-between justify-center align-center">
              <v-btn
                color="rgba(0, 0, 0, 0.06) white--text"
                small
                tile
                elevation="0"
                :loading="isLoading"
                @click="closePresentedSelector"
              >
                {{ $t('messages.Annulla') }}
              </v-btn>
              <v-btn
                color="success"
                tile
                :disabled="!selectedProduct || !selectedProduct.selectedCommercialCodeId"
                small
                :dark="!!selectedProduct && !!selectedProduct.selectedCommercialCodeId"
                elevation="0"
                :loading="isLoading"
                @click="insertProduct()"
              >
                {{ $t('messages.Aggiungi') }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="selectingCampione"
        max-width="290"
      >
        <v-card>
          <v-card-text class="pt-4 justify-center">
            <v-text-field
              v-model="selectedCampione"
              class="my-0 py-0 mx-1 persistent-placeholder"
              :label="$t('messages.Descrizione Campione')"
            />
            <div class="d-flex justify-space-between justify-center align-center">
              <v-btn
                color="rgba(0, 0, 0, 0.06) white--text"
                small
                tile
                elevation="0"
                :loading="isLoading"
                @click="closeCampioneSelector"
              >
                {{ $t('messages.Annulla') }}
              </v-btn>
              <v-btn
                color="success"
                tile
                :disabled="!selectedCampione"
                small
                :dark="!!selectedCampione"
                elevation="0"
                :loading="isLoading"
                @click="insertCampione()"
              >
                {{ $t('messages.Aggiungi') }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import ConfirmDialog from '../components/ConfirmDialog'
import ClientSelector from '../components/ClientSelector.vue'
import ProductSelector from '../components/ProductSelector.vue'

export default {
  name: 'OrderInsert',

  components: {
    ConfirmDialog,
    ClientSelector,
    ProductSelector
  },

  data: () => ({
    isInitializing: 0,
    isSending: false,
    filterDebounce: null,
    orderSuccess: false,
    selectingProduct: false,
    selectingCampione: false,
    selectedProduct: null,
    selectedCampione: null,
    isLoading: false,
    orderDetails: {
      client_id: '',
      notes: '',
      products: [],
      requestAuth: {
        doRequest: false,
        requestMail: ''
      }
    },
    notifyMail: '',
    shipToDifferent: false,
    selectedShipping: {
      id: -1,
      ditta: '',
      via: '',
      citta: '',
      cap: '',
      provincia: '',
      telefono: '',
      fax: '',
      mail: '',
      nazione: '',
      type: '',
      shipToDifferent: false
    },
    cmbShipping: -1,
    loadingClientDetailsFromServer: false,
    client: { cd_clien: '00000' },
    isLoadingNations: false,
    nations: [],
    nationsAgente: []
  }),

  computed: {
    indirizzoTypes () {
      return [{ value: 'Sede Operativa', text: this.$t('messages.Sede Operativa') },
        { value: 'Sede Legale', text: this.$t('messages.Sede Legale') },
        { value: 'Indirizzo di spedizione', text: this.$t('messages.Indirizzo di spedizione') }]
    },
    clientsListMinimal () {
      return this.$store.getters['clients/clientsListMinimal']
    },
    nationsVisibleToAgent () {
      return this.$store.getters['agents/nationsVisibleToAgent']
    },
    clientDetails () {
      return this.$store.getters['clientdetails/clientDetails']
    },
    products () {
      return this.$store.getters['products/list']
    },
    rules () {
      return {
        required: value => !!value || this.$t('messages.Obbligatorio'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('messages.E-Mail non valida')
        },
        emailMultipla: value => {
          const pattern = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})(,\s*)?)+$/
          return pattern.test(value) || this.$t('messages.E-Mail non valida')
        },
        requiredInvoice: value => {
          return parseInt(this.client.is_projx, 10) === 1 || !!value || this.$t('messages.Obbligatorio')
        },
        requiredSdi: () => {
          return this.client.is_forfettario || !!this.client.pec || !!this.client.sdi || this.$t('messages.Obbligatorio')
        }
      }
    }
  },

  watch: {
    errorMessage (value) {
      if (value) {
        const dataMessage = { isOpen: true, message: value, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
    }
  },

  created () {
    const clientId = this.$route.params.clientId
    this.isInitializing = 4
    if (clientId) {
      this.isInitializing++
      this.loadingClientDetailsFromServer = true
      this.$store.dispatch('clientdetails/clientDetails', clientId).then((r) => {
        this.client = r
        this.isInitializing--
        this.client.is_split_payment = (this.client.is_split_payment === 'y') ? 1 : 0
        this.client.is_forfettario = !!(this.client.is_forfettario === 'y')
        this.client.indirizzi.unshift({
          id: -1,
          azienda: '',
          indirizzo: '',
          citta: '',
          cap: '',
          provincia: '',
          telefono: '',
          fax: '',
          mail: '',
          nazione: ''
        })
        this.loadingClientDetailsFromServer = false
        this.initialize()
      })
    } else {
      this.$store.dispatch('clientdetails/resetClientDetails')
    }
    this.$store.dispatch('agents/nationsVisibleToAgent').then(() => { this.isInitializing--; this.initialize() })
    this.$store.dispatch('nations/get').then((r) => { this.nations = r; this.isInitializing--; this.isLoadingNations = false; this.initialize() })
    this.$store.dispatch('clients/clientsListMinimal').then(() => { this.isInitializing--; this.initialize() })
    this.$store.dispatch('products/list').then(() => { this.isInitializing--; this.initialize() })
  },

  methods: {
    shippingChanged () {
      this.selectedShipping = this.client.indirizzi.find((item) => { return item.id === this.cmbShipping })
      this.selectedShipping.shipToDifferent = this.shipToDifferent
      if (this.selectedShipping.shipToDifferent && this.$refs.shippingdata) {
        this.$refs.shippingdata.resetValidation()
      }
      if (this.$refs.invoicedata) {
        this.$refs.invoicedata.resetValidation()
      }
    /* selectedShipping: {
      id: -1,
      ditta: '',
      via: '',
      citta: '',
      cap: '',
      provincia: '',
      telefono: '',
      fax: '',
      mail: '',
      nazione: '',
      shipToDifferent: false
    }, */
    },
    loadClient () {
      this.loadingClientDetailsFromServer = true
      if (!this.orderDetails.client_id) {
        this.client = { cd_clien: '00000' }
      } else {
        this.$store.dispatch('clientdetails/clientDetails', this.orderDetails.client_id).then((r) => {
          this.client = r
          this.client.is_split_payment = (this.client.is_split_payment === 'y') ? 1 : 0
          this.client.is_forfettario = !!(this.client.is_forfettario === 'y')
          this.loadingClientDetailsFromServer = false
          this.client.indirizzi.unshift({
            id: -1,
            azienda: '',
            indirizzo: '',
            citta: '',
            cap: '',
            provincia: '',
            telefono: '',
            fax: '',
            mail: '',
            nazione: ''
          })
          this.orderDetails.products.forEach(p => {
            this.checkAvailability(p)
          })
        })
      }
      this.loadingClientDetailsFromServer = false
    },
    initialize () {
      if (!this.$route.params.orderId) {
        this.canWrite = 1
      }
      if (this.isInitializing === 0 && this.canWrite === 1) {
        if (this.$route.params.clientId && this.clientsListMinimal.find(c => c.id === this.$route.params.clientId)) {
          this.orderDetails.client_id = this.$route.params.clientId
        }
        this.orderDetails.microarea = this.clientsListMinimal.find(c => c.id === this.orderDetails.client_id)?.microarea
        this.nationsAgente = this.nations
        if (this.nationsVisibleToAgent.nations.length > 0) {
          this.nationsAgente = this.nationsAgente.filter(n => {
            return this.nationsVisibleToAgent.nations.some((nva) => { return nva === n.isocode })
          })
        }
      }
    },

    cmbText (item) {
      return item.id < 0 ? 'Inserisci nuovo' : item.azienda + ' - ' + item.indirizzo
    },

    async closePresentedSelector () {
      this.selectingProduct = false
    },

    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    },
    editQuantity (article, amount) {
      article.quantity += amount
      if (article.quantity < 1) article.quantity = 1
    },
    remove (article) {
      const index = this.orderDetails.products.findIndex(a => a === article)
      this.orderDetails.products.splice(index, 1)
    },
    debounceCheckAvailability (product) {
      clearTimeout(this.filterDebounce)
      this.isLoadingFilter = true
      product.availability = 0
      this.filterDebounce = setTimeout(() => {
        // do filter
        this.checkAvailability(product)
        this.isLoadingFilter = false
      }, 1000)
    },

    checkAvailability (product) {
      let foundArticle = null
      product.availability = 0
      product.discGroup = ''
      if (product.articles.standard_codes.length > 0) {
        if (product.selectedHeight === '' || product.selectedLength === '') {
          product.availability = 0
          product.discGroup = ''
          return
        }

        // Aggiungo hack per fare in modo che quando seleziono l'altezza 37,7 l'articolo è sempre forato
        // altrimenti seleziono non forato
        if (product.selectedHeight === '37,7') {
          // Forato
          product.selectedType = true
        } else {
          // Non Forato
          product.selectedType = false
        }
        foundArticle = product.articles.standard_codes.find(article => {
          return article.forato === product.selectedType && article.length === product.selectedLength && article.height === product.selectedHeight
        }, this)
      } else {
        foundArticle = product.articles.codes[0]
      }
      if (foundArticle) {
        const availability = this.$store.dispatch('products/getAvailability', { code: foundArticle.code, quantity: product.quantity, client: this.client ? this.client.cd_clien : '00000' })
        availability.then((response) => {
          if (response) {
            product.availability = response.availability
            product.discGroup = response.discGroup
          } else {
            product.availability = 0
            product.discGroup = ''
          }
        })
      }
    },

    async insertProduct () {
      if (!this.selectedProduct || !this.selectedProduct.selectedCommercialCodeId) {
        const message = this.$t('messages.Seleziona un Prodotto')
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        return
      }
      this.isLoading = true
      const r = await this.$store.dispatch('products/details', this.selectedProduct.selectedCommercialCodeId)
      if (r) {
        const article = r.article

        article.availability = 0
        article.discGroup = ''
        article.heights = []
        article.selectedHeight = ''
        article.lengths = []
        article.selectedLength = ''
        article.clientCutInstructions = ''
        article.cutInstructions = ''
        article.types = []
        article.selectedType = ''
        article.quantity = 1
        article.selectedHeightMessage = ''
        article.selectedLengthMessage = ''
        article.selectedTypeMessage = ''
        article.confirmDialogRemoveProduct = false
        // controllo se è un codice standard (13 caratteri con lunghezza e altezza e forato)
        if (article.articles.standard_codes.length > 0) {
          // creo un array con elementi non duplicati per le lunghezze
          article.lengths = article.articles.standard_codes.reduce((lengthList, item) => {
            if (lengthList.includes(item.length)) {
              return lengthList
            }
            lengthList.push(item.length)
            return lengthList
          }, []).sort((x, y) => parseInt(x) - parseInt(y))
          // converto l'array ordinato di elementi ad un array di oggetti per il v-select
          article.lengths = article.lengths.sort((x, y) => parseInt(x) - parseInt(y)).map((item) => { return { value: item, text: item + ' m', disabled: false } })
          // se esiste solo un elemento lo autoseleziono
          if (article.lengths.length === 1) {
            article.selectedLength = article.lengths[0].value
          }

          // creo un array con elementi non duplicati per le altezze
          article.heights = article.articles.standard_codes.reduce((heightList, item) => {
            if (heightList.includes(item.height)) {
              return heightList
            }
            heightList.push(item.height)
            return heightList
          }, []).sort((x, y) => parseInt(x) - parseInt(y))
          // converto l'array ordinato di elementi ad un array di oggetti per il v-select
          // hack se l'altezza è 37,7 deve essere forato
          article.heights = article.heights.sort((x, y) => parseInt(x) - parseInt(y)).map((item) => { return { value: item, text: item + ' cm' + (item === '37,7' ? ' - ' + 'Forato' + '' : ''), disabled: false } })
          // se esiste solo un elemento lo autoseleziono
          if (article.heights.length === 1) {
            article.selectedHeight = article.heights[0].value
          }

          // creo un array con elementi non duplicati per i tipi (forato o non)
          article.types = article.articles.standard_codes.reduce((typeList, item) => {
            if (typeList.includes(item.forato)) {
              return typeList
            }
            typeList.push(item.forato)
            return typeList
          }, []).sort()
          // converto l'array ordinato di elementi ad un array di oggetti per il v-select

          article.types = article.types.sort().reverse().map((item) => { return { value: item, text: item ? this.$t('messages.Forato') : this.$t('messages.Non Forato'), disabled: false } })
          // se esiste solo un elemento lo autoseleziono
          if (article.types.length === 1) {
            article.selectedType = article.types[0].value
          }
        }
        this.checkAvailability(article)
        this.orderDetails.products.push(article)
        this.closePresentedSelector()
      } else {
        const dataMessage = { isOpen: true, message: this.$store.getters['products/errorMessage'], color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
      this.isLoading = false
    },
    selectedHeightChanged (article) {
      article.selectedHeightMessage = []
      // lista di lunghezze disponibili con questa altezza e tipo (se selezionato)
      const availableLengths = article.articles.standard_codes.filter((item) => {
        return item.height === article.selectedHeight && (article.selectedType === '' ? true : item.forato === article.selectedType)
      }, article).reduce((lengthList, item) => {
        if (lengthList.includes(item.length)) {
          return lengthList
        }
        lengthList.push(item.length)
        return lengthList
      }, []).sort((x, y) => parseInt(x) - parseInt(y))

      // disabilito le lunghezze non disponibili
      article.lengths.forEach(length => {
        length.disabled = !availableLengths.some(function (availableLength) {
          return availableLength === length.value
        }, article)
      }, article)

      // se avevo già selezionato una lunghezza non disponibile, la deseleziono
      const foundLength = article.lengths.find(length => {
        return length.value === article.selectedLength
      }, article)
      if (foundLength && foundLength.disabled) {
        article.selectedLength = ''
      }

      // lista di tipi disponibili con questa altezza e lunghezza (se selezionata)
      const availableTypes = article.articles.standard_codes.filter((item) => {
        return item.height === article.selectedHeight && (article.selectedLength === '' ? true : item.length === article.selectedLength)
      }, article).reduce((typeList, item) => {
        if (typeList.includes(item.forato)) {
          return typeList
        }
        typeList.push(item.forato)
        return typeList
      }, [])

      // disabilito i tipi non disponibili
      article.types.forEach(type => {
        type.disabled = !availableTypes.some(function (availableType) {
          return availableType === type.value
        }, article)
      }, article)

      // se avevo già selezionato un tipo non disponibile, lo deseleziono
      const foundType = article.types.find(type => {
        return type.value === article.selectedType
      }, article)
      if (foundType && foundType.disabled) {
        article.selectedType = ''
      }
    },

    selectedLengthChanged (article) {
      article.selectedLengthMessage = []
      // lista di altezze disponibili con questa lunghezza e tipo (se selezionato)
      const availableHeights = article.articles.standard_codes.filter((item) => {
        return item.length === article.selectedLength && (article.selectedType === '' ? true : item.forato === article.selectedType)
      }, article).reduce((heightList, item) => {
        if (heightList.includes(item.height)) {
          return heightList
        }
        heightList.push(item.height)
        return heightList
      }, []).sort((x, y) => parseInt(x) - parseInt(y))

      // disabilito le altezze non disponibili
      article.heights.forEach(height => {
        height.disabled = !availableHeights.some(function (availableHeight) {
          return availableHeight === height.value
        }, article)
      }, article)

      // se avevo già selezionato una lunghezza non disponibile, la deseleziono
      const foundHeight = article.heights.find(height => {
        return height.value === article.selectedHeight
      }, article)
      if (foundHeight && foundHeight.disabled) {
        article.selectedHeight = ''
      }

      // lista di tipi disponibili con questa altezza (se selezionata) e lunghezza
      const availableTypes = article.articles.standard_codes.filter((item) => {
        return (article.selectedHeight === '' ? true : item.height === article.selectedHeight) && item.length === article.selectedLength
      }, article).reduce((typeList, item) => {
        if (typeList.includes(item.forato)) {
          return typeList
        }
        typeList.push(item.forato)
        return typeList
      }, []).sort()

      // disabilito i tipi non disponibili
      article.types.forEach(type => {
        type.disabled = !availableTypes.some(function (availableType) {
          return availableType === type.value
        }, article)
      }, article)

      // se avevo già selezionato un tipo non disponibile, lo deseleziono
      const foundType = article.types.find(type => {
        return type.value === article.selectedType
      }, article)
      if (foundType && foundType.disabled) {
        article.selectedType = ''
      }
    },

    selectedTypeChanged (article) {
      // lista di altezze disponibili con questo tipo e lunghezza (se selezionata)
      const availableHeights = article.articles.standard_codes.filter((item) => {
        return item.forato === article.selectedType && (article.selectedLength === '' ? true : item.length === article.selectedLength)
      }, article).reduce((heightList, item) => {
        if (heightList.includes(item.height)) {
          return heightList
        }
        heightList.push(item.height)
        return heightList
      }, []).sort((x, y) => parseInt(x) - parseInt(y))

      // disabilito le altezze non disponibili
      article.heights.forEach(height => {
        height.disabled = !availableHeights.some(function (availableHeight) {
          return availableHeight === height.value
        }, article)
      }, article)

      // se avevo già selezionato una lunghezza non disponibile, la deseleziono
      const foundHeight = article.heights.find(height => {
        return height.value === article.selectedHeight
      }, article)
      if (foundHeight && foundHeight.disabled) {
        article.selectedHeight = ''
      }

      // lista di lunghezze disponibili con questo tipo e altezza (se selezionata)
      const availableLengths = article.articles.standard_codes.filter((item) => {
        return item.forato === article.selectedType && (article.selectedHeight === '' ? true : item.height === article.selectedHeight)
      }, article).reduce((lengthList, item) => {
        if (lengthList.includes(item.length)) {
          return lengthList
        }
        lengthList.push(item.length)
        return lengthList
      }, []).sort((x, y) => parseInt(x) - parseInt(y))

      // disabilito le lunghezze non disponibili
      article.lengths.forEach(length => {
        length.disabled = !availableLengths.some(function (availableLength) {
          return availableLength === length.value
        }, article)
      }, article)

      // se avevo già selezionato una lunghezza non disponibile, la deseleziono
      const foundLength = article.lengths.find(length => {
        return length.value === article.selectedLength
      }, article)
      if (foundLength && foundLength.disabled) {
        article.selectedLength = ''
      }
    },
    closeCampioneSelector () {
      this.selectingCampione = false
    },
    async sendOrder () {
      let errorMessages = ''
      if (!this.orderDetails.client_id) {
        errorMessages += this.$t('messages.Selezionare un Cliente') + '\n'
      }
      if (!this.orderDetails.products.length) {
        errorMessages += this.$t('messages.Selezionare almeno un prodotto') + '\n'
      }

      if (errorMessages !== '') {
        const dataMessage = { isOpen: true, message: errorMessages, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        this.isLoading = false
        return
      }
      this.selectedShipping.shipToDifferent = this.shipToDifferent
      if (this.shipToDifferent) {
        if (!this.selectedShipping.is_apa_sync && !this.$refs.shippingdata.validate()) {
          const dataMessage = { isOpen: true, message: this.$t('messages.Compilare i dati di spedizione'), color: 'error' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          return
        }
      }
      if (!this.$refs.invoicedata.validate()) {
        const dataMessage = { isOpen: true, message: this.$t('messages.Compilare i dati di fatturazione'), color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        return
      }
      if (this.orderDetails.requestAuth.doRequest && !this.$refs.requestAuthMail.validate()) {
        const dataMessage = { isOpen: true, message: this.$t('messages.Compilare la mail di autorizzazione con una mail valida'), color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        return
      }
      this.isSending = true

      const data = {}
      data.selectedShipping = this.selectedShipping
      data.client = this.client
      data.clientId = this.orderDetails.clientId
      data.notes = this.orderDetails.notes
      data.requestAuth = this.orderDetails.requestAuth
      data.articles = []
      for (let index = 0; index < this.orderDetails.products.length; index++) {
        const product = this.orderDetails.products[index]
        let foundArticle = null

        if (product.articles.standard_codes.length > 0) {
          if (product.selectedHeight === '') {
            product.selectedHeightMessage = this.$t('messages.Selezionare un altezza')
          }
          if (product.selectedLength === '') {
            product.selectedLengthMessage = this.$t('messages.Selezionare una lunghezza')
          }

          if (product.selectedHeight === '' || product.selectedLength === '') {
            const messageData = { isOpen: true, color: 'error', message: this.$t('messages.Selezionare le misure dell articolo') }
            this.$store.dispatch('appState/setMessageSnackbar', messageData)
            this.isSending = false
            return
          }

          // Aggiungo hack per fare in modo che quando seleziono l'altezza 37,7 l'articolo è sempre forato
          // altrimenti seleziono non forato
          if (product.selectedHeight === '37,7') {
            // Forato
            product.selectedType = true
          } else {
            // Non Forato
            product.selectedType = false
          }
          foundArticle = product.articles.standard_codes.find(article => {
            return article.forato === product.selectedType && article.length === product.selectedLength && article.height === product.selectedHeight
          }, this)
        } else {
          foundArticle = product.articles.codes[0]
        }
        if (foundArticle) {
          data.articles.push({ article: foundArticle, quantity: product.quantity, notes: product.cutInstructions, clientCutInstructions: product.clientCutInstructions, mode: product.mode })
        } else {
          // foundArticle può essere null in quanto devo prevedere la possibilità che il codice con altezza 37,7 forato non sia in realtà disponibile
          // cioé è un malfunzionamento di logica APA tale per cui un prodotto deve essere forato e non lo è o viceversa
          const messageData = { isOpen: true, color: 'error', message: this.$t('messages.Si è verificato un errore') }
          this.$store.dispatch('appState/setMessageSnackbar', messageData)
          this.isSending = false
          return
        }
      }
      const r = await this.$store.dispatch('orders/send', data)
      if (r) {
        this.orderSuccess = true
        this.notifyMail = r.notifyMail
      } else {
        const messageData = { isOpen: true, color: 'error', message: this.$store.getters['orders/errorMessage'] }
        this.$store.dispatch('appState/setMessageSnackbar', messageData)
      }
      this.isSending = false
    },

    async insertCampione () {
      if (!this.selectedCampione) {
        const message = 'Inserisci la descrizione'
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        return
      }
      const article = {}
      article.heights = []
      article.selectedHeight = ''
      article.lengths = []
      article.selectedLength = ''
      article.cutInstructions = this.selectedCampione
      article.clientCutInstructions = ''
      article.types = []
      article.selectedType = ''
      article.quantity = 1
      article.selectedHeightMessage = ''
      article.selectedLengthMessage = ''
      article.selectedTypeMessage = ''
      article.confirmDialogRemoveProduct = false
      article.id = '-1'
      article.code = 'AM.CAMPIONATURA'
      article.colore = ''
      article.immagine = ''
      article.articles = {
        codes: [{ code: 'AM.CAMPIONATURA', standard_code: false }],
        standard_codes: []
      }
      this.orderDetails.products.push(article)

      this.closeCampioneSelector()
    },

    hasImage (article) {
      if (article.immagine !== '') {
        return true
      }
      return false
    },
    getImage (article) {
      if (article.immagine !== '') {
        const imageName = article.immagine // .codice_commerciale.replace('/', '').toLowerCase() + '.jpg'
        const imagePathName = 'https://shop.apaspa.com/apa/images/commercial_codes/' + imageName
        return imagePathName
      }
      return ''
    },
    getAvailabilityColor (n) {
      switch (n) {
      case -1:
        return 'orange'
      case 0:
        return '#ccc'
      case 1:
        return 'green'
      case 2:
        return 'orange'
      case 3:
        return 'red'
      default:
        return '#ccc'
      }
    },
    hasColor (article) {
      if (article.colore !== '') {
        return true
      }
      return false
    },
    getColor (article) {
      if (article.colore !== '') {
        return article.colore
      } else {
        return '#ffffff'
      }
    }

  }
}
</script>

<style scoped>
.article-image {
  min-width:51px;
  min-height:40px;
  max-width:51px;
  max-height:40px;
}
</style>
