import ProductsAPI from '../api/products'

const FETCHING_PRODUCTS_LIST = 'FETCHING_PRODUCTS_LIST'
const FETCHING_PRODUCTS_LIST_SUCCESS = 'FETCHING_PRODUCTS_LIST_SUCCESS'
const FETCHING_PRODUCTS_LIST_ERROR = 'FETCHING_PRODUCTS_LIST_ERROR'
const FETCHING_CAMPIONI_LIST = 'FETCHING_CAMPIONI_LIST'
const FETCHING_CAMPIONI_LIST_SUCCESS = 'FETCHING_CAMPIONI_LIST_SUCCESS'
const FETCHING_CAMPIONI_LIST_ERROR = 'FETCHING_CAMPIONI_LIST_ERROR'
const FETCHING_PRODUCT_DETAILS = 'FETCHING_PRODUCT_DETAILS'
const FETCHING_PRODUCT_DETAILS_SUCCESS = 'FETCHING_PRODUCT_DETAILS_SUCCESS'
const FETCHING_PRODUCT_DETAILS_ERROR = 'FETCHING_PRODUCT_DETAILS_ERROR'
const FETCHING_CAMPIONI_PRODOTTI_LIST = 'FETCHING_CAMPIONI_PRODOTTI_LIST'
const FETCHING_CAMPIONI_PRODOTTI_LIST_SUCCESS = 'FETCHING_CAMPIONI_PRODOTTI_LIST_SUCCESS'
const FETCHING_CAMPIONI_PRODOTTI_LIST_ERROR = 'FETCHING_CAMPIONI_PRODOTTI_LIST_ERROR'
const SAVING_CAMPIONI_PRODOTTI_LIST = 'SAVING_CAMPIONI_PRODOTTI_LIST'
const SAVING_CAMPIONI_PRODOTTI_LIST_SUCCESS = 'SAVING_CAMPIONI_PRODOTTI_LIST_SUCCESS'
const SAVING_CAMPIONI_PRODOTTI_LIST_ERROR = 'SAVING_CAMPIONI_PRODOTTI_LIST_ERROR'
const SET_FEEDBACK_CAMPIONI_CACHE = 'SET_FEEDBACK_CAMPIONI_CACHE'
const UPDATE_FEEDBACK_CAMPIONI_CACHE = 'UPDATE_FEEDBACK_CAMPIONI_CACHE'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    products: null,
    productsObsolete: null,
    campioni: [],
    feedbackCampioniCache: { articoli: [], campioni: [] }
  },
  getters: {
    isLoading (state) {
      return state.isLoading
    },
    hasError (state) {
      return state.error !== null
    },
    error (state) {
      return state.error
    },
    errorMessage (state) {
      return state.error.response.data.detail
    },
    list (state) {
      return state.products
    },
    listObsolete (state) {
      return state.productsObsolete
    },
    campioni (state) {
      return state.campioni
    },
    feedbackCampioniCache (state) {
      return state.feedbackCampioniCache
    }
  },
  mutations: {
    [FETCHING_CAMPIONI_LIST] (state) {
      state.isLoading = true
      state.campioni = []
      state.error = null
    },
    [FETCHING_CAMPIONI_LIST_SUCCESS] (state, data) {
      state.error = null
      state.campioni = data
      state.isLoading = false
    },
    [FETCHING_CAMPIONI_LIST_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [FETCHING_PRODUCTS_LIST] (state) {
      state.isLoading = true
      state.error = null
    },
    [FETCHING_PRODUCTS_LIST_SUCCESS] (state, data) {
      state.error = null

      if (data.obsolete) {
        state.productsObsolete = data.products
      } else {
        state.products = data.products
      }

      state.isLoading = false
    },
    [FETCHING_PRODUCTS_LIST_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [FETCHING_PRODUCT_DETAILS] (state) {
      state.error = null
    },
    [FETCHING_PRODUCT_DETAILS_SUCCESS] (state) {
      state.error = null
    },
    [FETCHING_PRODUCT_DETAILS_ERROR] (state, error) {
      state.error = error
    },
    [FETCHING_CAMPIONI_PRODOTTI_LIST] (state) {
      state.error = null
    },
    [FETCHING_CAMPIONI_PRODOTTI_LIST_SUCCESS] (state) {
      state.error = null
    },
    [FETCHING_CAMPIONI_PRODOTTI_LIST_ERROR] (state, error) {
      state.error = error
    },
    [SAVING_CAMPIONI_PRODOTTI_LIST] (state) {
      state.error = null
    },
    [SAVING_CAMPIONI_PRODOTTI_LIST_SUCCESS] (state) {
      state.error = null
    },
    [SAVING_CAMPIONI_PRODOTTI_LIST_ERROR] (state, error) {
      state.error = error
    },
    [SET_FEEDBACK_CAMPIONI_CACHE] (state, feedbackCampioniCache) {
      state.feedbackCampioniCache = feedbackCampioniCache
    },
    [UPDATE_FEEDBACK_CAMPIONI_CACHE] (state, rows) {
      const hashMapArticoli = {}

      state.feedbackCampioniCache.articoli.forEach(a => {
        if (!Object.prototype.hasOwnProperty.call(hashMapArticoli, a.value)) {
          hashMapArticoli[a.value] = []
        }
        hashMapArticoli[a.value].push(a)
      })
      rows.articoli.forEach(row => {
        let processed = false

        if (Object.prototype.hasOwnProperty.call(hashMapArticoli, row.value)) {
          hashMapArticoli[row.value].forEach(a => {
            if (a.format === row.format && a.note === row.note) {
              a.quantity += row.quantity
              processed = true
            }
          })
        } else {
          hashMapArticoli[row.value] = []
        }
        if (!processed) {
          hashMapArticoli[row.value].push(row)
          state.feedbackCampioniCache.articoli.push(row)
        }
      })

      const hashMapCampioni = {}

      state.feedbackCampioniCache.campioni.forEach(a => {
        if (!Object.prototype.hasOwnProperty.call(hashMapCampioni, a.value)) {
          hashMapCampioni[a.value] = []
        }
        hashMapCampioni[a.value].push(a)
      })
      rows.campioni.forEach(row => {
        let processed = false

        if (Object.prototype.hasOwnProperty.call(hashMapCampioni, row.value)) {
          hashMapCampioni[row.value].forEach(a => {
            if (a.note === row.note) {
              a.quantity += row.quantity
              processed = true
            }
          })
        } else {
          hashMapCampioni[row.value] = []
        }
        if (!processed) {
          hashMapCampioni[row.value].push(row)
          state.feedbackCampioniCache.campioni.push(row)
        }
      })
    }
  },
  actions: {
    async getAvailability ({ commit }, data) {
      try {
        const response = await ProductsAPI.getAvailability(data)
        return response.data
      } catch (error) {
        return null
      }
    },
    async setFeedbackCampioniCache ({ commit }, feedbackCampioniCache) {
      commit(SET_FEEDBACK_CAMPIONI_CACHE, feedbackCampioniCache)
    },
    async updateFeedbackCampioniCache ({ commit }, feedbackCampioniCache) {
      commit(UPDATE_FEEDBACK_CAMPIONI_CACHE, feedbackCampioniCache)
    },
    async list ({ commit, state }) {
      if (state.products) {
        return state.products
      }
      commit(FETCHING_PRODUCTS_LIST)
      try {
        const response = await ProductsAPI.list()
        commit(FETCHING_PRODUCTS_LIST_SUCCESS, { obsolete: false, products: response.data })
        return response.data
      } catch (error) {
        commit(FETCHING_PRODUCTS_LIST_ERROR, error)
        return null
      }
    },
    async listObsolete ({ commit, state }) {
      if (state.productsObsolete) {
        return state.productsObsolete
      }
      commit(FETCHING_PRODUCTS_LIST)
      try {
        const response = await ProductsAPI.listObsolete()
        commit(FETCHING_PRODUCTS_LIST_SUCCESS, { obsolete: true, products: response.data })
        return response.data
      } catch (error) {
        commit(FETCHING_PRODUCTS_LIST_ERROR, error)
        return null
      }
    },
    async details ({ commit }, productId) {
      commit(FETCHING_PRODUCT_DETAILS)
      try {
        const response = await ProductsAPI.detail(productId)
        commit(FETCHING_PRODUCT_DETAILS_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_PRODUCT_DETAILS_ERROR, error)
        return null
      }
    },
    async listcampioni ({ commit, state }) {
      if (state.campioni.length > 0) {
        return state.campioni
      }
      commit(FETCHING_CAMPIONI_LIST)
      try {
        const response = await ProductsAPI.listcampioni()
        commit(FETCHING_CAMPIONI_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_CAMPIONI_LIST_ERROR, error)
        return null
      }
    },
    async listcampioniprodotti ({ commit, state }) {
      commit(FETCHING_CAMPIONI_PRODOTTI_LIST)
      try {
        const response = await ProductsAPI.listcampioniprodotti()
        commit(FETCHING_CAMPIONI_PRODOTTI_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_CAMPIONI_PRODOTTI_LIST_ERROR, error)
        return null
      }
    },
    async savecampioniprodotti ({ commit, state }, data) {
      commit(SAVING_CAMPIONI_PRODOTTI_LIST)
      try {
        const response = await ProductsAPI.savecampioniprodotti(data)
        commit(SAVING_CAMPIONI_PRODOTTI_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(SAVING_CAMPIONI_PRODOTTI_LIST_ERROR, error)
        return null
      }
    }
  }
}
