<template>
  <div>
    <confirm-dialog
      v-model="confirmDialog"
      color="error"
      :title="$t('messages.Elimina')"
      :description="itemToDelete.description"
      @confirm="itemToDelete.deleteFunction(itemToDelete.index); confirmDialog=false"
      @cancel="confirmDialog=false"
    />
    <confirm-dialog
      v-model="confirmDialogMergeClient"
      color="error"
      :title="$t('messages.Conferma')"
      :description="clientMergeDetails ? $t('Il Codice Cliente digitato è già memorizzato come Cliente Projxp Si conferma di voler unire il Prospect al Cliente #Nome# Indirizzo: #Indirizzo# #Citta# (CAP: #CAP#)?',
                                            [clientMergeDetails.azienda, clientMergeDetails.indirizzo, clientMergeDetails.citta, clientMergeDetails.cap]) : ''"
      @confirm="mergeClientConfirm(); confirmDialogMergeClient=false"
      @cancel="confirmDialogMergeClient=false"
    />
    <v-container
      class="px-0 pt-0"
      style="margin-top: 1px"
    >
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        color="primary"
        class="mt-3 ml-3"
      />
      <v-tabs
        v-if="!isLoading && clientDetails && canOperate === 1"
        v-model="selectedTab"
        show-arrows
        fixed-tabs
        background-color="primary"
        class="navigationTab-fixed"
        dark
        style="width: 100%; min-width: 100%; max-width: 100%;"
      >
        <v-tab
          style="min-width: 0px;"
        >
          <v-icon
            dark
          >
            mdi-account-box
          </v-icon>
        </v-tab>
        <v-tab
          style="min-width: 0px;"
        >
          <v-icon
            dark
          >
            mdi-view-list-outline
          </v-icon>
        </v-tab>
        <v-tab
          style="min-width: 0px;"
        >
          <v-icon
            dark
          >
            mdi-printer
          </v-icon>
        </v-tab>
        <v-tab
          style="min-width: 0px;"
        >
          <v-icon
            dark
          >
            mdi-phone
          </v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-if="!isLoading && clientDetails && canOperate === 1"
        v-model="selectedTab"
        class="mt-12"
      >
        <!-- ************ DETTAGLI ************ -->
        <v-tab-item>
          <div class="mt-2 d-flex flex-column align-center">
            <div
              class="d-flex justify-center align-center"
            >
              <v-img
                v-if="clientDetails.is_projx === '1'"
                src="/logo.svg"
                class="mr-2"
                width="50px"
              />
              <h4>
                {{ clientDetails.azienda }}
              </h4>
            </div>
            <v-rating
              v-model="clientDetails.stars"
              color="primary"
              background-color="primary"
              hover
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              length="3"
              size="28"
              clearable
              @input="setDirty"
            />
          </div>
          <div
            v-if="clientDetails.is_projx === '1'"
            class="d-flex justify-center align-center"
          >
            <v-btn
              color="primary"
              dark
              class="btn"
              type="button"
              elevation="0"
              tile
              @click="openStorico = true"
            >
              {{ $t('messages.Visualizza Storico') }}
            </v-btn>
            <view-client-history
              v-model="openStorico"
              :client="clientDetails"
            />
          </div>
          <div
            v-if="isSupervisor"
            class="d-flex justify-center align-center"
          >
            <v-btn
              color="primary"
              dark
              class="btn mt-2 mb-1"
              type="button"
              elevation="0"
              tile
              @click="gotoCompatta"
            >
              {{ $t('messages.Visualizza Riepilogo Visivo') }}
            </v-btn>
          </div>
          <div class="ml-1">
            <v-text-field
              v-model="clientDetails.cd_clien"
              :disabled="isLoading"
              :readonly="clientDetails.is_projx === '1' || !$route.params.clientId"
              :label="$t('messages.Codice Cliente')"
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-text-field
              v-model="clientDetails.azienda"
              :disabled="isLoading"
              :readonly="clientDetails.is_projx === '1'"
              :label="clientDetails.is_mandatory + $t('messages.Ragione Sociale')"
              required
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-text-field
              v-model="clientDetails.piva"
              :disabled="isLoading"
              :readonly="clientDetails.is_projx === '1'"
              :label="$t('messages.PpIVA')"
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-text-field
              v-model="clientDetails.codicefiscale"
              :disabled="isLoading"
              :readonly="clientDetails.is_projx === '1'"
              :label="$t('messages.CodpFiscale')"
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-text-field
              v-model="clientDetails.indirizzo"
              :disabled="isLoading"
              :readonly="clientDetails.is_projx === '1'"
              :label="clientDetails.is_mandatory + $t('messages.Indirizzo')"
              required
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-text-field
              v-model="clientDetails.cap"
              :disabled="isLoading"
              :readonly="clientDetails.is_projx === '1'"
              :label="$t('messages.CAP')"
              required
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-text-field
              v-model="clientDetails.citta"
              :disabled="isLoading"
              :readonly="clientDetails.is_projx === '1'"
              :label="clientDetails.is_mandatory + $t('messages.Città')"
              required
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-text-field
              v-if="clientDetails.nazione=='' || clientDetails.nazione=='IT'"
              v-model="clientDetails.provincia"
              :disabled="isLoading"
              :readonly="clientDetails.is_projx === '1'"
              :label="clientDetails.is_mandatory + $t('messages.Provincia')"
              required
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-autocomplete
              v-model="clientDetails.nazione"
              :menu-props="{
                maxHeight:200,
              }"
              :items="nations"
              item-text="name"
              item-value="isocode"
              :label="clientDetails.is_mandatory + $t('messages.Nazione')"
              :disabled="isLoading"
              :readonly="clientDetails.is_projx === '1'"
              hide-details
              required
              class="persistent-placeholder mb-2"
              @input="setDirty"
              @keydown.enter="confirmEvent"
            />
            <v-text-field
              v-if="clientDetails.nazione=='' || clientDetails.nazione=='IT'"
              v-model="clientDetails.pec"
              :disabled="isLoading"
              :readonly="clientDetails.is_projx === '1'"
              :label="'PEC'"
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-text-field
              v-if="clientDetails.nazione=='' || clientDetails.nazione=='IT'"
              v-model="clientDetails.sdi"
              :disabled="isLoading"
              :readonly="clientDetails.is_projx === '1'"
              :label="'SDI'"
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-text-field
              v-if="clientDetails.nazione=='' || clientDetails.nazione=='IT'"
              v-model="clientDetails.iban"
              :disabled="isLoading"
              :readonly="clientDetails.is_projx === '1'"
              :label="'IBAN'"
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-text-field
              v-if="clientDetails.nazione=='' || clientDetails.nazione=='IT'"
              v-model="clientDetails.banca"
              :disabled="isLoading"
              :readonly="clientDetails.is_projx === '1'"
              :label="$t('messages.Banca')"
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-text-field
              v-model="clientDetails.pagamen"
              :disabled="isLoading"
              :readonly="true"
              :label="$t('messages.Pagamento')"
              class="persistent-placeholder"
              @input="setDirty"
            />
            <div class="mb-2">
              <area-selector
                v-model="clientDetails.cd_agent"
                :areas="areasVisibleToAgent"
                :label="clientDetails.is_mandatory + $t('messages.Seleziona un Area')"
                :disabled="isLoading"
                :clearable="clientDetails.is_projx !== '1'"
                :readonly="clientDetails.is_projx === '1'"
                :filled="false"
                class="persistent-placeholder"
                hide-details
                @input="setDirty"
              />
            </div>
            <div class="mt-1 mb-2">
              <micro-area-selector
                v-model="clientDetails.microarea"
                :microareas="microAreasVisibleToAgent"
                clearable
                :filled="false"
                class="persistent-placeholder mt-1"
                hide-details
                @input="setDirty"
              />
            </div>
            <v-text-field
              v-model="clientDetails.telefono"
              :disabled="isLoading"
              :readonly="clientDetails.is_projx === '1'"
              :label="clientDetails.is_half_mandatory + $t('messages.Telefono')"
              required
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-text-field
              v-model="clientDetails.email"
              :disabled="isLoading"
              :readonly="clientDetails.is_projx === '1'"
              :label="clientDetails.is_half_mandatory + $t('messages.E-Mail')"
              required
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-text-field
              v-model="clientDetails.email_order_notify"
              :disabled="isLoading"
              :label="$t('messages.Email Notifica Ordine')"
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-text-field
              v-model="clientDetails.website"
              :disabled="isLoading"
              :label="$t('messages.Website')"
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-textarea
              v-model="clientDetails.fornitore"
              :disabled="isLoading"
              :label="$t('messages.Fornitore')"
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-textarea
              v-model="clientDetails.notes_tmm"
              :disabled="isLoading"
              :label="$t('messages.Note')"
              class="persistent-placeholder"
              @input="setDirty"
            />
            <v-textarea
              v-if="clientDetails.is_projx === '1'"
              v-model="clientDetails.notes"
              :disabled="isLoading"
              readonly
              :label="$t('messages.Note Projx')"
              class="persistent-placeholder"
              @input="setDirty"
            />
          </div>

          <div
            v-if="clientDetails.is_projx === '1'"
            class="d-flex justify-center align-center"
          >
            <v-btn
              color="primary"
              dark
              class="btn"
              type="button"
              elevation="0"
              tile
              @click="openClientDiscounts = true"
            >
              {{ $t('messages.Sconti Cliente') }}
            </v-btn>
            <view-client-discounts
              v-model="openClientDiscounts"
              :client="clientDetails"
            />
          </div>
        </v-tab-item>
        <!-- ************ INDIRIZZI ************ -->
        <v-tab-item>
          <v-container class="px-1">
            <transition-group
              name="fade"
              tag="div"
            >
              <div
                v-for="(indirizzo, i) in clientDetails.indirizzi"
                :key="indirizzo.id"
                class="custom-transition-element pa-1"
                style="border: 3px solid silver; position: relative; margin-top: 4px"
              >
                <v-checkbox
                  v-model="indirizzo.is_favourite"
                  :label="$t('messages.Visualizzato su mappa')"
                  style="border-radius: 50%; position: absolute; left:0px; top:0px"
                  hide-details
                  @change="setIsFavourite(indirizzo);setDirty()"
                />
                <v-img
                  v-if="indirizzo.is_apa_sync === '1'"
                  style="position: absolute; right:0px; top:0px"
                  src="/logo.svg"
                  width="24px"
                />
                <v-icon
                  v-else
                  medium
                  color="error"
                  style="border-radius: 50%; position: absolute; right:0px; top:0px"
                  @click="deleteAddress(indirizzo, i);setDirty()"
                >
                  mdi-close
                </v-icon>
                <div
                  class="d-flex flex-column flex-grow-1 mt-6"
                >
                  <v-text-field
                    v-model="indirizzo.azienda"
                    :disabled="isLoading"
                    :readonly="indirizzo.is_apa_sync === '1'"
                    required
                    class="align-center mb-3 persistent-placeholder"
                    :label="$t('messages.Azienda')"
                    hide-details
                    @input="setDirty"
                  />
                  <v-text-field
                    v-model="indirizzo.indirizzo"
                    :disabled="isLoading"
                    :readonly="indirizzo.is_apa_sync === '1'"
                    class="align-center mb-2 persistent-placeholder"
                    required
                    :label="$t('messages.Via')"
                    hide-details
                    @input="setDirty"
                  />
                  <v-text-field
                    v-model="indirizzo.citta"
                    :disabled="isLoading"
                    :readonly="indirizzo.is_apa_sync === '1'"
                    class="align-center mb-2 persistent-placeholder"
                    required
                    :label="$t('messages.Città')"
                    hide-details
                    @input="setDirty"
                  />
                  <v-text-field
                    v-model="indirizzo.provincia"
                    :disabled="isLoading"
                    :readonly="indirizzo.is_apa_sync === '1'"
                    class="align-center mb-2 persistent-placeholder"
                    required
                    :label="$t('messages.Provincia')"
                    hide-details
                    @input="setDirty"
                  />
                  <v-text-field
                    v-model="indirizzo.cap"
                    :disabled="isLoading"
                    :readonly="indirizzo.is_apa_sync === '1'"
                    class="align-center mb-3 persistent-placeholder"
                    required
                    :label="$t('messages.CAP')"
                    hide-details
                    @input="setDirty"
                  />
                  <v-autocomplete
                    v-model="indirizzo.nazione"
                    :menu-props="{
                      maxHeight:200,
                    }"
                    :items="nations"
                    item-text="name"
                    item-value="isocode"
                    :label="$t('messages.Nazione')"
                    :disabled="isLoading"
                    :readonly="indirizzo.is_apa_sync === '1'"
                    hide-details
                    required
                    class="align-center mb-2 persistent-placeholder"
                    @input="setDirty"
                    @keydown.enter="confirmEvent"
                  />
                  <v-select
                    v-if="indirizzo.is_apa_sync !== '1'"
                    v-model="indirizzo.type"
                    :items="indirizzoTypes"
                    :disabled="isLoading"
                    :readonly="indirizzo.is_apa_sync === '1'"
                    required
                    item-text="text"
                    item-value="value"
                    :clearable="indirizzo.is_apa_sync !== '1'"
                    class="align-center mb-2 persistent-placeholder"
                    :label="$t('messages.Tipologia Indirizzo')"
                    hide-details
                    @input="setDirty"
                  />
                  <v-text-field
                    v-else
                    :value="$t('messages.' + indirizzo.type)"
                    :disabled="isLoading"
                    readonly
                    class="align-center mb-3 persistent-placeholder"
                    :label="$t('messages.Tipologia Indirizzo')"
                    hide-details
                  />
                </div>
              </div>
            </transition-group>
            <div
              class="d-flex align-center justify-space-around mt-1"
              style="border: 3px solid #e1e1e1; position: relative; margin-top: -3px; height: 382px"
            >
              <v-btn
                :disabled="selectedTab === 0"
                :loading="isLoading"
                fab
                dark
                elevation="0"
                color="#E1E1E1"
                class="hover-primary"
                @click="addField"
              >
                <v-icon color="white">
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>
          </v-container>
        </v-tab-item>
        <!-- ************ DEVICES ************ -->
        <v-tab-item>
          <v-container class="px-1">
            <transition-group
              name="fade"
              tag="div"
            >
              <div
                v-for="(device, i) in clientDetails.devices"
                :key="device.id"
                class="custom-transition-element pa-1"
                style="border: 3px solid silver; position: relative; margin-top: 4px"
              >
                <div>
                  <v-icon
                    medium
                    color="error"
                    style="border-radius: 50%; position: absolute; right:0px; top:0px"
                    @click="deleteDevice(device, i);setDirty()"
                  >
                    mdi-close
                  </v-icon>
                  <div
                    class="d-flex flex-column flex-grow-1 mt-6"
                  >
                    <v-combobox
                      v-model="device.brand"
                      :items="devicesBrand"
                      :disabled="isLoading"
                      class="mr-sm-2 mb-2 align-center persistent-placeholder"
                      required
                      clearable
                      :label="$t('messages.Nome')"
                      :filter="filterDevice"
                      @input="setDirty"
                    />
                    <v-autocomplete
                      v-model="device.type"
                      :menu-props="{
                        maxHeight:200,
                      }"
                      :items="devicesTypes"
                      :disabled="isLoading"
                      class="mt-2 mb-2 align-center persistent-placeholder"
                      required
                      clearable
                      :label="$t('messages.Tipo dispositivo')"
                      @input="setDirty"
                    />
                    <v-text-field
                      v-model="device.model"
                      :disabled="isLoading"
                      class="mt-2 align-center persistent-placeholder"
                      required
                      clearable
                      :label="$t('messages.Modello dispositivo')"
                      @input="setDirty"
                    />
                  </div>
                </div>
              </div>
            </transition-group>
            <div
              class="d-flex align-center justify-space-around mt-1"
              style="border: 3px solid #e1e1e1; position: relative; margin-top: -3px; height: 214px"
            >
              <v-btn
                :disabled="selectedTab === 0"
                :loading="isLoading"
                fab
                dark
                elevation="0"
                color="#E1E1E1"
                class="hover-primary"
                @click="addField"
              >
                <v-icon color="white">
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>
          </v-container>
        </v-tab-item>
        <!-- ************ CONTATTI ************ -->
        <v-tab-item>
          <v-container class="px-1">
            <transition-group
              name="fade"
              tag="div"
            >
              <div
                v-for="(contact, i) in clientDetails.contacts"
                :key="contact.id"
                class="custom-transition-element pa-1"
                style="border: 3px solid silver; position: relative; margin-top: 4px"
              >
                <v-img
                  v-if="contact.is_apa_sync === '1'"
                  style="position: absolute; right:0px; top:0px"
                  src="/logo.svg"
                  width="24px"
                />
                <v-icon
                  v-else
                  medium
                  color="error"
                  style="border-radius: 50%; position: absolute; right:0px; top:0px"
                  @click="deleteContact(contact, i);setDirty()"
                >
                  mdi-close
                </v-icon>
                <div
                  class="d-flex flex-column flex-grow-1"
                >
                  <v-text-field
                    v-model="contact.nome"
                    :disabled="isLoading"
                    :readonly="contact.is_apa_sync === '1'"
                    required
                    class="align-center persistent-placeholder"
                    :label="$t('messages.Nominativo')"
                    @input="setDirty"
                  />
                  <v-combobox
                    v-model="contact.tipologia"
                    :items="contactTypes"
                    :disabled="isLoading"
                    :readonly="contact.is_apa_sync === '1'"
                    class="align-center persistent-placeholder"
                    required
                    :label="$t('messages.Tipo')"
                    @input="setDirty"
                  />
                  <v-text-field
                    v-model="contact.telephone"
                    :disabled="isLoading"
                    :readonly="contact.is_apa_sync === '1'"
                    class="align-center persistent-placeholder"
                    required
                    :label="$t('messages.Telefono')"
                    @input="setDirty"
                  />
                  <v-text-field
                    v-model="contact.mobile"
                    :disabled="isLoading"
                    :readonly="contact.is_apa_sync === '1'"
                    class="align-center persistent-placeholder"
                    required
                    :label="$t('messages.Cellulare')"
                    @input="setDirty"
                  />
                  <v-text-field
                    v-model="contact.mail"
                    :disabled="isLoading"
                    :readonly="contact.is_apa_sync === '1'"
                    class="align-center persistent-placeholder"
                    required
                    :label="$t('messages.E-Mail')"
                    @input="setDirty"
                  />
                </div>
              </div>
            </transition-group>
            <div
              class="d-flex align-center justify-space-around mt-1"
              style="border: 3px solid #e1e1e1; position: relative; margin-top: -3px; height: 254px"
            >
              <v-btn
                :disabled="selectedTab === 0"
                :loading="isLoading"
                fab
                dark
                elevation="0"
                color="#E1E1E1"
                class="hover-primary"
                @click="addField"
              >
                <v-icon color="white">
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>
          </v-container>
        </v-tab-item>
      </v-tabs-items>

      <v-btn
        v-if="isDirty"
        :disabled="!isDirty"
        class="save-fixed"
        color="success"
        small
        fab
        :loading="isLoading"
        elevation="0"
        @click="updateClientDetails()"
      >
        <v-icon>
          mdi-content-save
        </v-icon>
      </v-btn>

      <v-btn
        class="back-fixed"
        color="warning"
        small
        fab
        elevation="0"
        @click="goback()"
      >
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <div
        v-if="!isLoading && canOperate !== 1"
        class="mt-4"
      >
        <v-card
          elevation="0"
          tile
          class="error d-flex align-center justify-center"
          style="min-height:150px; max-height:150px; height:150px;"
        >
          <span
            class="title white--text"
          >
            {{ $t('messages.Cliente inattivo o non trovato') }}
          </span>
        </v-card>
      </div>
    </v-container>

    <v-bottom-navigation
      app
      background-color="primary"
      fixed
      class="mb-0 pa-0"
    >
      <v-btn
        :to="mapLink"
        :disabled="isLoading || !clientDetails"
        :loading="isLoading"
        type="button"
        class="px-0"
      >
        <v-icon color="white">
          mdi-map-marker
        </v-icon>
      </v-btn>
      <v-btn
        :disabled="isLoading || !clientDetails"
        :loading="isLoading"
        type="button"
        class="px-0"
        :to="'/feedbackinsert/client/' + (clientDetails ? clientDetails.id : '')"
      >
        <v-icon color="white">
          mdi-pencil-plus-outline
        </v-icon>
      </v-btn>
      <v-btn
        :disabled="isLoading || !clientDetails"
        :loading="isLoading"
        type="button"
        class="px-0"
        :to="'/feedbacks/' + (clientDetails ? clientDetails.id : '')"
      >
        <v-icon color="white">
          mdi-book-open-variant
        </v-icon>
      </v-btn>

      <v-btn
        :disabled="isLoading || (clientDetails && !clientDetails.cd_clien)"
        :loading="isLoading"
        type="button"
        class="px-0"
        :to="'/galleryinsert/' + (clientDetails ? clientDetails.id : '')"
      >
        <v-icon color="white">
          mdi-camera-plus
        </v-icon>
      </v-btn>

      <v-btn
        :disabled="isLoading || !clientDetails"
        :loading="isLoading"
        type="button"
        class="px-0"
        :to="'/orderinsert/' + (clientDetails ? clientDetails.id : '')"
      >
        <v-icon color="white">
          mdi-cart-plus
        </v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import ConfirmDialog from '../components/ConfirmDialog'
import AreaSelector from '../components/AreaSelector.vue'
import MicroAreaSelector from '../components/MicroAreaSelector.vue'
import ViewClientHistory from '../components/ViewClientHistory'
import ViewClientDiscounts from '../components/ViewClientDiscounts'

export default {
  name: 'DettagliCliente',
  components: {
    ConfirmDialog,
    AreaSelector,
    MicroAreaSelector,
    ViewClientHistory,
    ViewClientDiscounts
  },

  data: () => ({
    uniqueCounter: 0,
    clientDetails: null,
    nations: [],
    isLoadingNations: true,
    isDirty: false,
    confirmDialog: false,
    confirmDialogMergeClient: false,
    clientMergeDetails: null,
    isLoading: false,
    selectedTab: 0,
    itemToDelete: {
      index: -1,
      description: '',
      deleteFunction: () => {}
    },
    devicesBrand: ['ROLAND', 'MIMAKI', 'EPSON', 'MUTOH', 'HP', 'OKI', 'LIYU', 'AGFA', 'RICOH', 'VUTEK', 'DURST', 'DGI', 'CANON'],
    canOperate: 0,
    openStorico: false,
    openClientDiscounts: false
  }),

  computed: {

    isSupervisor () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN') || this.$store.getters['security/hasRole']('ROLE_AGENT_ADMIN')
    },
    indirizzoTypes () {
      return [{ value: 'Sede Operativa', text: this.$t('messages.Sede Operativa') },
        { value: 'Sede Legale', text: this.$t('messages.Sede Legale') },
        { value: 'Indirizzo di spedizione', text: this.$t('messages.Indirizzo di spedizione') }]
    },
    devicesTypes () {
      return [{ value: 'Calandra', text: this.$t('messages.Calandra') },
        { value: 'Plotter', text: this.$t('messages.Plotter') },
        { value: 'Pressa', text: this.$t('messages.Pressa') },
        { value: 'Stampante latex', text: this.$t('messages.Stampante latex') },
        { value: 'Stampante solvente', text: this.$t('messages.Stampante solvente') },
        { value: 'Stampante UV', text: this.$t('messages.Stampante UV') }]
    },
    contactTypes () {
      return [this.$t('messages.Amministrazione'),
        this.$t('messages.Referente'),
        this.$t('messages.Commerciale'),
        this.$t('messages.Responsabile'),
        this.$t('messages.Segreteria')]
    },

    nationsVisibleToAgent () {
      return this.$store.getters['agents/nationsVisibleToAgent']
    },
    microAreasVisibleToAgent () {
      return this.$store.getters['agents/microAreasVisibleToAgent']
    },
    areasVisibleToAgent () {
      return this.$store.getters['agents/areasVisibleToAgent']
    },
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    errorMessageClients () {
      return this.$store.getters['clients/errorMessage']
    },
    errorMessageClientDetails () {
      return this.$store.getters['clientdetails/errorMessage']
    },
    mapLink () {
      if (!this.clientDetails) return ''
      let path = '/clientsmap?area=' + this.clientDetails.cd_agent + '&clientId=' + this.clientDetails.id
      if (this.clientDetails.indirizzi.length) {
        this.clientDetails.indirizzi.forEach(i => {
          if (i.is_favourite === 1) {
            if (i.lat !== null && i.lng !== null) {
              path += '&lat=' + i.lat + '&lng=' + i.lng
            } else if (i.lat_auto !== null && i.lng_auto !== null) {
              path += '&lat=' + i.lat_auto + '&lng=' + i.lng_auto
            }
          }
        })
      }
      return path
    }
  },

  watch: {
    async $route (to) {
      this.initialize(to)
    }
  },

  async created () {
    this.isInitializing = 4
    this.isLoading = true
    this.$store.dispatch('agents/nationsVisibleToAgent').then(() => { this.isInitializing--; this.initialize(this.$route) })
    this.$store.dispatch('nations/get').then((r) => { this.nations = r; this.isInitializing--; this.isLoadingNations = false; this.initialize(this.$route) })
    this.$store.dispatch('agents/areasVisibleToAgent').then(() => { this.isInitializing--; this.initialize(this.$route) })
    this.$store.dispatch('agents/microAreasVisibleToAgent').then(() => { this.isInitializing--; this.initialize(this.$route) })
  },

  methods: {
    goback () {
      this.$router.go(-1)
    },
    gotoCompatta () {
      this.$router.push({ path: '/reports/client-compact-color?selectedArea=' + this.clientDetails.cd_agent + '&selectedClient=' + encodeURIComponent(this.clientDetails.azienda) })
    },
    writeFeedbackOnClient (client) {
      // deprecated, todo delete
      this.$router.push({ path: '/feedbackinsert/client/' + client.id })
    },
    searchFeedbackOnClient (client) {
      // deprecated, todo delete
      this.$router.push({ path: '/feedbacks/' + client.id })
    },
    uploadImageOnClient (client) {
      // deprecated, todo delete
      this.$router.push({ path: '/galleryinsert/' + client.id })
    },

    setDirty () {
      this.isDirty = true
    },

    async initialize (route) {
      if (this.isInitializing !== 0) { return }
      let defaultNazione = ''
      if (this.nationsVisibleToAgent.nations.length > 0) {
        this.nations = this.nations.filter(n => {
          return this.nationsVisibleToAgent.nations.some((nva) => { return nva === n.isocode })
        })
        defaultNazione = this.nations[0].isocode
      }
      if (!this.$route.params.clientId) {
        this.canOperate = 1
      }
      if (this.$route.params.clientId) {
        this.clientDetails = await this.$store.dispatch('clientdetails/clientDetails', this.$route.params.clientId)
        if (this.clientDetails) {
          this.canOperate = 1
          this.clientDetails.stars = parseInt(this.clientDetails.stars)
          this.clientDetails.indirizzi.forEach(i => { i.is_favourite = parseInt(i.is_favourite) })
          this.clientDetails.is_mandatory = ''
          this.clientDetails.is_half_mandatory = ''
          if (!this.clientDetails.microarea) {
            this.clientDetails.microarea = ''
          }
        }
      } else { // new client
        this.clientDetails = {
          is_mandatory: '(*) - ',
          is_half_mandatory: '(+) - ',
          cd_clien: '',
          azienda: '',
          piva: '',
          codicefiscale: '',
          indirizzo: '',
          cap: '',
          citta: '',
          provincia: '',
          nazione: defaultNazione,
          cd_agent: this.areasVisibleToAgent[0].cd_agent,
          microarea: this.microAreasVisibleToAgent[0]?.microarea ?? '',
          telefono: '',
          email: '',
          email_order_notify: '',
          website: '',
          fornitore: '',
          notes_tmm: '',
          stars: 0,
          indirizzi: [],
          devices: [],
          contacts: [],
          isNew: true
        }
      }
      this.isLoading = false
      /* this.devicesList = await this.$store.dispatch('clientdetails/devicesList') */
    },

    async mergeClientConfirm () {
      this.isLoading = true
      if (this.clientDetails.cd_clien) {
        const result = await this.$store.dispatch('clientdetails/merge', this.clientDetails)
        if (!result) {
          // show error
          const message = this.errorMessageClientDetails
          const dataMessage = { isOpen: true, message: message, color: 'error' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        } else {
          const message = this.$t('messages.Prospect Unito al Cliente #nome# Indirizzo: #via# #citta# (CAP: #cap#)',
            [result.mergedInto.azienda, result.mergedInto.indirizzo, result.mergedInto.citta, result.mergedInto.cap])
          const dataMessage = { isOpen: true, message: message, color: 'success' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          this.isDirty = false
          this.$router.replace({ path: '/client/details/' + result.mergedInto.id })
        }
      }
      this.isLoading = false
    },
    async updateClientDetails () {
      let errorMessages = ''
      if (!this.clientDetails.azienda) {
        errorMessages += this.$t('Digitare la Ragione Sociale') + '\n'
      }
      if (!this.clientDetails.indirizzo || !this.clientDetails.citta || (this.clientDetails.nazione === 'IT' && !this.clientDetails.provincia) || !this.clientDetails.nazione) {
        errorMessages += this.$t('Compilare') + ': '
        if (!this.clientDetails.indirizzo) {
          errorMessages += ' ' + this.$t('Indirizzo') + ' - '
        }
        if (!this.clientDetails.citta) {
          errorMessages += ' ' + this.$t('Città') + ' - '
        }
        if ((this.clientDetails.nazione === 'IT' && !this.clientDetails.provincia)) {
          errorMessages += ' ' + this.$t('Provincia') + ' - '
        }
        if (!this.clientDetails.nazione) {
          errorMessages += ' ' + this.$t('Nazione') + ' - '
        }
        errorMessages = errorMessages.slice(0, -3)
        errorMessages += '\n'
      }
      if (!this.clientDetails.telefono && !this.clientDetails.email) {
        errorMessages += this.$t('Impostare almeno mail o telefono') + '\n'
      }
      if (!this.clientDetails.cd_agent) {
        errorMessages += this.$t('Selezionare un\'Area') + '\n'
      }
      if (errorMessages !== '') {
        const dataMessage = { isOpen: true, message: errorMessages, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        this.isLoading = false
        return
      }
      this.isLoading = true
      if (this.clientDetails.isNew) {
        const result = await this.$store.dispatch('clients/create', this.clientDetails)
        if (!result) {
        // show error
          const message = this.errorMessageClients
          const dataMessage = { isOpen: true, message: message, color: 'error' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        } else {
          const message = this.$t('Cliente Creato')
          const dataMessage = { isOpen: true, message: message, color: 'success' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          this.$router.push({ path: '/client/details/' + result.id })
          this.isDirty = false
        }
      } else {
        const result = await this.$store.dispatch('clientdetails/update', this.clientDetails)
        if (!result) {
          // show error
          const message = this.errorMessageClientDetails
          const dataMessage = { isOpen: true, message: message, color: 'error' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        } else {
          if (!result.canBeMerged) {
            const message = this.$t('Cliente Modificato')
            const dataMessage = { isOpen: true, message: message, color: 'success' }
            this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
            this.isDirty = false
          } else {
            this.clientMergeDetails = result.canBeMerged
            this.confirmDialogMergeClient = true
          }
        }
      }
      this.isLoading = false
    },

    deleteAddressConfirm (index) {
      this.clientDetails.indirizzi.splice(index, 1)
      const message = this.$t('Salvare per confermare le modifiche')
      const dataMessage = { isOpen: true, message: message, color: 'success' }
      this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
    },
    deleteAddress (indirizzo, index) {
      if (indirizzo.is_favourite) {
        const message = this.$t('Non è possibile eliminare l indirizzo preferito')
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        return
      }
      this.itemToDelete.description = this.$t('Sei sicuro di voler eliminare l indirizzo #azienda# selezionato?', [indirizzo.azienda])
      this.itemToDelete.index = index
      this.itemToDelete.deleteFunction = this.deleteAddressConfirm
      this.confirmDialog = true
    },

    deleteDeviceConfirm (index) {
      this.clientDetails.devices.splice(index, 1)
      const message = this.$t('Salvare per confermare le modifiche')
      const dataMessage = { isOpen: true, message: message, color: 'success' }
      this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
    },
    deleteDevice (device, index) {
      this.itemToDelete.description = this.$t('Sei sicuro di voler eliminare il dispositivo #brand#?', [device.brand])
      this.itemToDelete.index = index
      this.itemToDelete.deleteFunction = this.deleteDeviceConfirm
      this.confirmDialog = true
    },

    deleteContactConfirm (index) {
      this.clientDetails.contacts.splice(index, 1)
      const message = this.$t('Salvare per confermare le modifiche')
      const dataMessage = { isOpen: true, message: message, color: 'success' }
      this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
    },
    deleteContact (contact, index) {
      this.itemToDelete.description = this.$t('Sei sicuro di voler eliminare il contatto #nome#?', [contact.nome])
      this.itemToDelete.index = index
      this.itemToDelete.deleteFunction = this.deleteContactConfirm
      this.confirmDialog = true
    },

    addField () {
      switch (this.selectedTab) {
      case 1:
        this.addIndirizzo()
        break
      case 2:
        this.addDevice()
        break
      case 3:
        this.addContact()
        break
      default:
        break
      }
    },

    addIndirizzo () {
      const newIndirizzo = {
        azienda: '',
        indirizzo: '',
        cap: '',
        citta: '',
        nazione: '',
        provincia: '',
        type: 'Sede Operativa',
        id: 'new-' + this.uniqueCounter++,
        is_favourite: false,
        is_apa_sync: '0'
      }
      if (this.clientDetails.indirizzi.length === 0) {
        newIndirizzo.is_favourite = true
      }
      this.clientDetails.indirizzi.push(newIndirizzo)
    },

    addDevice () {
      const newDevice = {
        brand: '',
        type: '',
        model: '',
        id: 'new-' + this.uniqueCounter++
      }
      this.clientDetails.devices.push(newDevice)
    },

    addContact () {
      const newContact = {
        client_id: '',
        nome: '',
        tipologia: '',
        telephone: '',
        mobile: '',
        mail: '',
        id: 'new-' + this.uniqueCounter++
      }
      this.clientDetails.contacts.push(newContact)
    },

    openMap () {
      // deprecated use computed mapLink
      // todo delete
      if (this.clientDetails.indirizzi.length) {
        this.clientDetails.indirizzi.forEach(i => {
          if (i.is_favourite === 1) {
            if (i.lat !== null && i.lng !== null) {
              this.$router.push({ path: '/clientsmap?lat=' + i.lat + '&lng=' + i.lng })
            } else {
              this.$router.push({ path: '/clientsmap?lat=' + i.lat_auto + '&lng=' + i.lng_auto })
            }
          } else {
            this.$router.push({ path: '/clientsmap?clientId=' + this.clientDetails.id })
          }
        })
      } else {
        this.$router.push({ path: '/clientsmap?clientId=' + this.clientDetails.id })
      }
    },

    setIsFavourite (indirizzo) {
      this.clientDetails.indirizzi.forEach(i => {
        i.is_favourite = false
      })

      this.$nextTick(() => {
        indirizzo.is_favourite = true
      })
    },

    filterDevice (item, queryText, itemText) {
      if (item.brand) {
        return item.brand.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      } else {
        return false
      }
    }

    /* inputDeviceBrand (event, device) {
      if (!event) {
        device.brand = ''
      } else if (event.brand) {
        device.brand = event.brand
        device.type = event.type
      } else {
        device.brand = event
      }
    } */

  }
}
</script>
<style scoped>

::v-deep.v-item-group.v-bottom-navigation .v-btn {
  min-width: 80px;
}
@media (max-width:600px){
  ::v-deep.v-item-group.v-bottom-navigation .v-btn {
    min-width: 64px;
  }
}

.save-fixed {
  position: fixed;
  top: 137px;
  right: 4px;
}
.back-fixed {
  position: fixed;
  top: 137px;
  left: 4px;
}
.navigationTab-fixed {
  position: fixed;
  top: 48px;
  left: 0px;
  padding: 0px;
  z-index: 1;
}

::v-deep.v-text-field__details {
  min-height: 0px!important;
  height: 0px;
}
.v-text-field{
  padding-top: 8px;
  margin-top: 0px;
}
.v-input--selection-controls{
  margin-top: 0px;
  padding-top: 0px;
}
.v-btn:not(.v-btn--round).v-size--default{
  margin-left: -4px;
}
.hover-primary:hover {
  background-color: #2559b9!important;
}
</style>
