import axios from 'axios'

export default {
  send (data) {
    return axios.post('/api/orders/send', data)
  },
  sendcampionatura (data) {
    return axios.post('/api/orders/sendcampionatura', data)
  },
  saveCartCampionatura (data) {
    return axios.post('/api/cart/save', data)
  },
  fetchCartCampionatura (data) {
    return axios.get('/api/cart/fetch')
  }
}
