import clientdetailsAPI from '../api/client-details'

const FETCHING_CLIENTDETAILS_LIST = 'FETCHING_CLIENTDETAILS_LIST'
const FETCHING_CLIENTDETAILS_LIST_SUCCESS = 'FETCHING_CLIENTDETAILS_LIST_SUCCESS'
const FETCHING_CLIENTDETAILS_LIST_ERROR = 'FETCHING_CLIENTDETAILS_LIST_ERROR'

const RESET_CLIENTDETAILS = 'RESET_CLIENTDETAILS'

const UPDATING_CLIENTDETAILS_LIST = 'UPDATING_CLIENTDETAILS_LIST'
const UPDATING_CLIENTDETAILS_LIST_SUCCESS = 'UPDATING_CLIENTDETAILS_SUCCESS_LIST'
const UPDATING_CLIENTDETAILS_LIST_ERROR = 'UPDATING_CLIENTDETAILS_ERROR_LIST'

const MERGING_CLIENTDETAILS = 'MERGING_CLIENTDETAILS'
const MERGING_CLIENTDETAILS_SUCCESS = 'MERGING_CLIENTDETAILS_SUCCESS'
const MERGING_CLIENTDETAILS_ERROR = 'MERGING_CLIENTDETAILS_ERROR'

const MERGING_PROJX = 'MERGING_PROJX'
const MERGING_PROJX_SUCCESS = 'MERGING_PROJX_SUCCESS'
const MERGING_PROJX_ERROR = 'MERGING_PROJX_ERROR'

const FETCHING_DEVICES_LIST = 'FETCHING_DEVICES_LIST'
const FETCHING_DEVICES_LIST_SUCCESS = 'FETCHING_DEVICES_LIST_SUCCESS'
const FETCHING_DEVICES_LIST_ERROR = 'FETCHING_DEVICES_LIST_ERROR'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    clientDetails: null,
    clientAddress: [],
    clientDevice: []

  },
  getters: {
    isLoading (state) {
      return state.isLoading
    },
    hasError (state) {
      return state.error !== null
    },
    error (state) {
      return state.error
    },
    errorMessage (state) {
      return state.error.response.data.detail
    },
    clientDetails (state) {
      return state.clientDetails
    }
  },
  mutations: {
    [MERGING_CLIENTDETAILS] (state) {
      state.isLoading = true
      state.clientDetails = null
      state.error = null
    },
    [MERGING_CLIENTDETAILS_SUCCESS] (state, data) {
      state.error = null
      state.clientDetails = data
      state.isLoading = false
    },
    [MERGING_CLIENTDETAILS_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [MERGING_PROJX] (state) {
      state.isLoading = true
      state.error = null
    },
    [MERGING_PROJX_SUCCESS] (state, data) {
      state.error = null
      state.isLoading = false
    },
    [MERGING_PROJX_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [FETCHING_CLIENTDETAILS_LIST] (state) {
      state.isLoading = true
      state.clientDetails = null
      state.error = null
    },
    [FETCHING_CLIENTDETAILS_LIST_SUCCESS] (state, data) {
      state.error = null
      state.clientDetails = data
      state.isLoading = false
    },
    [FETCHING_CLIENTDETAILS_LIST_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [RESET_CLIENTDETAILS] (state) {
      state.clientDetails = null
    },
    [UPDATING_CLIENTDETAILS_LIST] (state) {
      state.isLoading = true
      state.error = null
    },
    [UPDATING_CLIENTDETAILS_LIST_SUCCESS] (state) {
      state.error = null
      state.isLoading = false
    },
    [UPDATING_CLIENTDETAILS_LIST_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [FETCHING_DEVICES_LIST] (state) {
      state.isLoading = true
      state.devices = null
      state.error = null
    },
    [FETCHING_DEVICES_LIST_SUCCESS] (state, data) {
      state.error = null
      state.devices = data
      state.isLoading = false
    },
    [FETCHING_DEVICES_LIST_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    }
  },
  actions: {
    async clientDetails ({ commit }, clientId) {
      commit(FETCHING_CLIENTDETAILS_LIST)
      try {
        const response = await clientdetailsAPI.details(clientId)
        commit(FETCHING_CLIENTDETAILS_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_CLIENTDETAILS_LIST_ERROR, error)
        return null
      }
    },
    resetClientDetails ({ commit }) {
      commit(RESET_CLIENTDETAILS)
      return null
    },
    async update ({ commit }, data) {
      commit(UPDATING_CLIENTDETAILS_LIST)
      try {
        const response = await clientdetailsAPI.update(data)
        commit(UPDATING_CLIENTDETAILS_LIST_SUCCESS, data)
        return response.data
      } catch (error) {
        commit(UPDATING_CLIENTDETAILS_LIST_ERROR, error)
        return null
      }
    },
    async merge ({ commit }, data) {
      commit(MERGING_CLIENTDETAILS)
      try {
        const response = await clientdetailsAPI.merge(data)
        commit(MERGING_CLIENTDETAILS_SUCCESS, data)
        return response.data
      } catch (error) {
        commit(MERGING_CLIENTDETAILS_ERROR, error)
        return null
      }
    },
    async mergeProjx ({ commit }, data) {
      commit(MERGING_PROJX)
      try {
        const response = await clientdetailsAPI.mergeProjx(data)
        commit(MERGING_PROJX_SUCCESS, data)
        return response.data
      } catch (error) {
        commit(MERGING_PROJX_ERROR, error)
        return null
      }
    },
    async devicesList ({ commit }) {
      commit(FETCHING_DEVICES_LIST)
      try {
        const response = await clientdetailsAPI.devicesList()
        commit(FETCHING_DEVICES_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_DEVICES_LIST_ERROR, error)
        return null
      }
    }
  }
}
