<template>
  <v-container fluid>
    <v-row
      style="margin-top: 48px"
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card
          elevation="0"
          style="border: 1px solid silver;"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Login form</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                id="inputUsername"
                v-model="login"
                label="Login"
                name="login"
                prepend-icon="mdi-account"
                type="text"
                autofocus
                @keyup.enter="performLogin()"
              />

              <v-text-field
                id="password"
                v-model="password"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                required
                @keyup.enter="performLogin()"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <div
              v-if="hasError && !isLoading"
              class="text-center mt-2"
            >
              <v-alert
                close-text="Close Alert"
                dense
                outlined
                type="error"
                dark
                dismissible
              >
                {{ errorMessage }}
              </v-alert>
            </div>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="isLoading || isRedirecting"
              :loading="isLoading || isRedirecting"
              class="btn btn-lg btn-primary btn-block"
              type="button"
              tile
              elevation="0"
              @click="performLogin()"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Login',
  data () {
    return {
      login: '',
      password: '',
      isRedirecting: false
    }
  },
  computed: {
    isLoading () {
      return this.$store.getters['security/isLoading']
    },
    hasError () {
      return this.$store.getters['security/hasError']
    },
    errorMessage () {
      return this.$store.getters['security/errorMessage']
    }
  },
  created () {
    const redirect = this.$route.query.redirect

    if (this.$store.getters['security/isAuthenticated']) {
      if (typeof redirect !== 'undefined') {
        this.$router.push({ path: redirect })
      } else {
        this.$router.push({ path: '/home' })
      }
    }
  },
  methods: {
    async performLogin () {
      if (this.isLoading) {
        return
      }
      const payload = { login: this.$data.login, password: this.$data.password }
      const redirect = this.$route.query.redirect
      const r = await this.$store.dispatch('security/login', payload)
      if (r) { // success
        if (typeof redirect !== 'undefined') {
          this.isRedirecting = true
          window.location.href = redirect

          // this.$router.push({ path: redirect })
          // location.reload()
        } else {
          this.isRedirecting = true
          window.location.href = '/home'

          // this.$router.push({ path: '/home' })
        }
      }
    }
  }
}
</script>

<style scoped>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
