<template>
  <v-autocomplete
    v-model="selectedAgentId"
    :items="agents"
    item-text="user_login"
    item-value="user_id"
    :label="label"
    :placeholder="$t('messages.Seleziona un Agente')"
    clearable
    filled
    hide-details
    :readonly="readonly"
    :attach="attach"
    :menu-props="{
      maxHeight:200,
    }"
    :filter="filterAgents"
    @keydown.enter="confirmEvent"
  >
    <template slot="no-data">
      <v-list-item>
        <v-list-item-content>
          {{ $t('messages.Nessun Agente presente') }}
        </v-list-item-content>
      </v-list-item>
    </template>
    <template
      #[`item`]="{ item }"
    >
      <div>
        {{ item.user_login }}
      </div>
    </template>
    <template
      #[`selection`]="{ item }"
    >
      <div>
        {{ item.user_login }}
      </div>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  name: 'AgentSelector',

  props: {
    value: {
      type: String,
      required: false,
      default: () => ''
    },
    agents: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
      validator: () => {
        return true
      }
    },
    label: {
      type: String,
      required: false,
      default: function () {
        return this.$t('messages.Seleziona un Agente')
      }
    },
    attach: {
      required: false,
      default: false,
      validator: () => {
        return true
      }
    }
  },
  data: () => ({
    selectedAgentId: ''
  }),
  watch: {
    value (newVal) {
      this.selectedAgentId = newVal
    },
    selectedAgentId (newVal) {
      if (this.selectedAgentId !== this.value) { this.$emit('input', newVal) }
    }
  },
  created () {
    this.selectedAgentId = this.value
  },

  methods: {
    filterAgents (agent, queryText, itemText) {
      // console.log(agent.azienda)
      // if (agent.azienda === 'Yosh') { console.log(agent.azienda) }
      // if (this.agents[this.agents.length - 1] === agent) { console.log(agent.azienda) }
      // console.log(this.agents[this.agents.length - 1])
      return ((agent.user_login ?? '').toLowerCase().includes(queryText.toLowerCase()) || (agent.id ?? '').toLowerCase().includes(queryText.toLowerCase()))
    },
    confirmEvent () {
      if (this.selectedAgentId) {
        this.$emit('confirm', this.selectedAgentId)
      }
    }
  }
}
</script>
