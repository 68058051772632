import axios from 'axios'

export default {
  search (data) {
    return axios.post('/api/feedback/search', data)
  },
  feedbackList () {
    return axios.get('/api/feedback/feedbacklist')
  },
  details (feedbackId) {
    return axios.get('/api/feedback/details/' + feedbackId)
  },
  upload (data) {
    return axios.post('/api/feedback/upload', data)
  },
  edit (data) {
    return axios.post('/api/feedback/edit', data)
  },
  deleteOwnFeedback (data) {
    return axios.post('/api/feedback/delete-own-feedback', data)
  }
}
