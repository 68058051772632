import ListiniAPI from '../api/listini'

const FETCHING_LISTINI = 'FETCHING_LISTINI'
const FETCHING_LISTINI_SUCCESS = 'FETCHING_LISTINI_SUCCESS'
const FETCHING_LISTINI_ERROR = 'FETCHING_LISTINI_ERROR'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    listini: []
  },
  getters: {
    isLoading (state) {
      return state.isLoading
    },
    hasError (state) {
      return state.error !== null
    },
    listini (state) {
      return state.listini
    },
    error (state) {
      return state.error
    },
    errorMessage (state) {
      return state.error !== null ? state.error.response.data.detail : null
    }
  },
  mutations: {
    [FETCHING_LISTINI] (state) {
      state.isLoading = true
      state.listini = []
      state.error = null
    },
    [FETCHING_LISTINI_SUCCESS] (state, listini) {
      state.error = null
      state.listini = listini
      state.isLoading = false
    },
    [FETCHING_LISTINI_ERROR] (state, error) {
      state.isLoading = false
      state.listini = []
      state.error = error
    }

  },

  actions: {
    async listListini ({ commit }) {
      commit(FETCHING_LISTINI)
      try {
        const response = await ListiniAPI.listListini()
        commit(FETCHING_LISTINI_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_LISTINI_ERROR, error)
        return null
      }
    },
    async get () {
      try {
        const response = await ListiniAPI.get()
        return response.data
      } catch (error) {
        return null
      }
    }
  }
}
