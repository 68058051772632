<template>
  <div class="container px-0 px-sm-3">
    <div class="d-flex align-center justify-space-around ml-1">
      <h1>{{ $t('messages.Scheda Clienti Compatta') }}</h1>
    </div>
    <v-progress-circular
      v-if="isLoading || isInitializing"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <area-selector
        v-model="filter.selectedArea"
        :areas="areasVisibleToAgent"
        :label="$t('messages.Filtra area')"
        @input="getReportClientCompact"
      />
      <v-select
        v-model="selectedYear"
        :items="selectableYears"
        :label="$t('messages.Anno')"
        hide-details
        filled
        @input="getReportClientCompact"
      />
      <div
        v-if="reportClientCompact.length > 0"
      >
        <v-btn
          color="#e74c3c"
          dark
          class="btn btn-lg ma-2"
          type="button"
          elevation="0"
          tile
          :loading="isExportingPDF"
          @click="esportaPDF"
        >
          <v-icon>
            mdi-file-pdf-outline
          </v-icon>
          {{ $t('messages.Esporta in PDF') }}
        </v-btn>
        <v-autocomplete
          v-model="filter.selectedMicroArea"
          :items="filter.microareas"
          :label="$t('messages.Seleziona una Microarea')"
          :placeholder="$t('messages.Seleziona una Microarea')"
          clearable
          filled
          hide-details
          :menu-props="{
            maxHeight:200,
          }"
          @input="doFilter"
        >
          <template slot="no-data">
            <v-list-item>
              <v-list-item-content>
                {{ $t('messages.Nessuna microarea presente') }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="filter.selectedClient"
          :items="filter.clients"
          :label="$t('messages.Seleziona un Cliente')"
          :placeholder="$t('messages.Seleziona un Cliente')"
          clearable
          filled
          hide-details
          :menu-props="{
            maxHeight:200,
          }"
          @input="doFilter"
        >
          <template slot="no-data">
            <v-list-item>
              <v-list-item-content>
                {{ $t('messages.Nessun Cliente trovato') }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="filter.selectedProducts"
          :items="filter.products"
          multiple
          :label="$t('messages.Seleziona un codice commerciale')"
          :placeholder="$t('messages.Seleziona un codice commerciale')"
          clearable
          filled
          hide-details
          :menu-props="{
            maxHeight:200,
          }"
          @input="doFilter"
        >
          <template slot="no-data">
            <v-list-item>
              <v-list-item-content>
                {{ $t('messages.Nessun codice commerciale presente') }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>

        <v-radio-group
          v-model="order"
          class="my-2"
          hide-details
          row
          @change="reOrder"
        >
          <v-radio
            :label="$t('messages.Ordina per nome')"
            value="nome"
          />
          <v-radio
            :label="$t('messages.Ordina per cap')"
            value="cap"
          />
        </v-radio-group>

        <div class="mb-2">
          <v-btn
            color="primary"
            dark
            class="btn"
            type="button"
            elevation="0"
            tile
            @click="changeView"
          >
            {{ $t('messages.Visualizza Compatta Visiva') }}
          </v-btn>
        </div>

        <div
          v-for="(client, j) in filteredReport"
          :key="j"
        >
          <div
            class="agent-header px-1 d-flex"
            :style="'z-index: 3;position: sticky;top: 48px;'"
          >
            <h4
              :style="client.client_id ? 'cursor: pointer;':''"
              @click="viewClient(client.client_id)"
            >
              {{ client.cap }} - {{ client.azienda }} - {{ client.cd_clien }}
            </h4>
          </div>

          <div
            style="overflow-x: auto; overflow-y: hidden;"
            class="mx-1"
          >
            <table class="my-2">
              <thead>
                <tr>
                  <th
                    v-for="(hr, l) in headers"
                    :key="l"
                    :class="hr.class"
                    :style="hr.width?('min-width: ' + hr.width):''"
                  >
                    {{ hr.text == 'Colore' ? '' : hr.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(product, k) in client.filteredProducts"
                  :key="k"
                >
                  <td>
                    <span class="report-colore">
                      {{ product.colore_calcolato }}
                    </span>
                  </td>
                  <template
                    v-for="(hr, m) in headers"
                  >
                    <!-- eslint-disable vue/no-v-html -->
                    <td
                      v-if="!hr.hidden"
                      :key="m"
                      class="text-right"
                      :style="'min-width: 83px'"
                    >
                      <div class="d-flex flex-column">
                        <span
                          class="reportval"
                          v-html="euroFormat(product[hr.value])"
                        />
                        <span
                          class="reportval"
                          :class="'highlight'"
                          v-html="euroFormat(product[hr.value2])"
                        />
                      </div>
                    </td>
                  </template>
                </tr>
                <tr>
                  <td style="border-top: 3px double black;">
                    <span
                      class="report-colore"
                      style="font-size: 0.965rem;"
                    >
                      TOTALE
                    </span>
                  </td>
                  <template
                    v-for="(hr, m) in headers"
                  >
                    <td
                      v-if="!hr.hidden"
                      :key="m"
                      class="text-right"
                      style="border-top: 3px double black;"
                      :style="'min-width: 83px'"
                    >
                      <div class="d-flex flex-column">
                        <span
                          class="reportval"
                          v-html="euroFormat(client[hr.value])"
                        />
                        <span
                          class="reportval"
                          :class="'highlight'"
                          v-html="euroFormat(client[hr.value2])"
                        />
                      </div>
                    </td>
                  <!-- eslint-enable vue/no-v-html -->
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AreaSelector from '../../components/AreaSelector'

export default {
  name: 'ReportClientSales',

  components: {
    AreaSelector
  },

  data: () => ({
    reportClientCompact: [],
    isLoading: false,
    filteredReport: [],
    areasVisibleToAgent: [],
    microAreasVisibleToAgent: [],
    filter: {
      selectedArea: '',
      microareas: [],
      selectedMicroArea: '',
      clients: [],
      selectedClient: '',
      products: [],
      selectedProducts: []
    },
    selectedYear: '',
    isFiltering: false,
    todayDate: new Date().toISOString().substr(0, 10),
    isInitializing: 1,
    order: 'nome',
    initializeFilter: false,
    isExportingPDF: false
  }),

  computed: {
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    isAdmin () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN')
    },
    selectableYears () {
      const currentYear = new Date().getFullYear()
      return [
        (currentYear - 1) + '',
        currentYear + ''
      ]
    },
    headers () {
      return [
        { text: this.$t('messages.Colore'), value: 'colore', hidden: true },
        { text: this.$t('messages.Tot'), value: 'tot1', value2: 'tot2', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Gen'), value: 'gen1', value2: 'gen2', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Feb'), value: 'feb1', value2: 'feb2', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Mar'), value: 'mar1', value2: 'mar2', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Apr'), value: 'apr1', value2: 'apr2', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Mag'), value: 'mag1', value2: 'mag2', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Giu'), value: 'giu1', value2: 'giu2', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Lug'), value: 'lug1', value2: 'lug2', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Ago'), value: 'ago1', value2: 'ago2', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Set'), value: 'set1', value2: 'set2', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Ott'), value: 'ott1', value2: 'ott2', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Nov'), value: 'nov1', value2: 'nov2', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Dic'), value: 'dic1', value2: 'dic2', align: 'right', width: '83px', filterable: false }
      ]
    }
  },

  async created () {
    this.isLoading = true
    this.isInitializing = 2
    this.selectedYear = this.selectableYears[1]

    this.initializeFilter = false
    Object.keys(this.filter).forEach(key => {
      if (this.$route.query[key] !== undefined) {
        this.initializeFilter = true
        if (key === 'selectedArea' || key === 'selectedMicroArea' || key === 'selectedClient') {
          this.filter[key] = this.$route.query[key]
        } else if (key === 'selectedProducts') {
          // parsa le stringhe come array
          if (this.$route.query[key] !== '') {
            this.filter[key] = this.$route.query[key].split('-')
          }
        }
      }
    })
    this.$store.dispatch('agents/areasVisibleToAgent').then((r) => { this.isInitializing--; this.areasVisibleToAgent = r })
    this.$store.dispatch('agents/microAreasVisibleToAgent').then((r) => { this.isInitializing--; this.microAreasVisibleToAgent = r })
    if (this.filter.selectedArea !== '') {
      await this.getReportClientCompact()
    }
    this.isLoading = false
  },
  methods: {

    changeView () {
      this.$router.push({ path: '/reports/client-compact-color', query: this.$route.query })
    },

    viewClient (clientId) {
      if (clientId) {
        // this.$router.push({ path: '/client/details/' + clientId })
        window.open('/client/details/' + clientId, '_blank')
      }
    },

    updateFilterQuery () {
      this.$router.replace({
        query: {
          selectedArea: this.filter.selectedArea,
          selectedMicroArea: this.filter.selectedMicroArea,
          selectedClient: this.filter.selectedClient,
          selectedProducts: this.filter.selectedProducts.join('-')
        }
      }).catch(() => {})
    },

    async esportaPDF () {
      const myWorker = new Worker('/workers/worker-client-compact.js')
      const _this = this
      _this.isExportingPDF = true
      myWorker.onmessage = function (e) {
        if (e.data.status === 'complete') {
          _this.isExportingPDF = false
          const ancorTag = document.createElement('a')
          ancorTag.href = e.data.value
          ancorTag.target = '_blank'
          ancorTag.download = _this.$t('messages.ReportSchedaCompattappdf')
          document.body.appendChild(ancorTag)
          ancorTag.click()
          document.body.removeChild(ancorTag)
          myWorker.terminate()
        }
      }

      const d1 = this.todayDate
      const datestring = this.formatDate(d1)

      const tables = []

      const months = ['tot', 'gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic']

      this.filteredReport.forEach((client) => {
        const table = []
        table.header = client.cap + ' - ' + client.azienda + ' - ' + client.cd_clien
        table.rows = []
        table.total = []
        let rowToPush = []
        client.filteredProducts.forEach((product) => {
          rowToPush = []
          const cellColor = {
            rowSpan: 2,
            content: product.colore_calcolato,
            styles: { valign: 'middle', halign: 'left' }
          }
          rowToPush.push(cellColor)
          // month1
          for (let index = 0; index < months.length; index++) {
            let cellContent = product[months[index] + '1'].toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            if (product[months[index] + '1'] === 0) {
              cellContent = ''
            }
            const cellStyle = { fontStyle: 'normal', fontSize: 6 }
            rowToPush.push({
              content: cellContent,
              styles: cellStyle
            })
          }
          table.rows.push(rowToPush)

          rowToPush = []
          // month2
          for (let index = 0; index < months.length; index++) {
            let cellContent = product[months[index] + '2'].toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            if (product[months[index] + '2'] === 0) {
              cellContent = ''
            }
            const cellStyle = { fontStyle: 'bold', fontSize: 8 }
            rowToPush.push({
              content: cellContent,
              styles: cellStyle
            })
          }
          table.rows.push(rowToPush)
        })

        rowToPush = []
        const cellTotal = {
          rowSpan: 2,
          content: 'TOTALE',
          styles: { valign: 'middle', halign: 'left', fontStyle: 'bold', fontSize: 8 }
        }
        rowToPush.push(cellTotal)
        // month1
        for (let index = 0; index < months.length; index++) {
          let cellContent = client[months[index] + '1'].toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          if (client[months[index] + '1'] === 0) {
            cellContent = ''
          }
          const cellStyle = { fontStyle: 'normal', fontSize: 6 }
          rowToPush.push({
            content: cellContent,
            styles: cellStyle
          })
        }
        table.rows.push(rowToPush)

        rowToPush = []
        // month2
        for (let index = 0; index < months.length; index++) {
          let cellContent = client[months[index] + '2'].toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          if (client[months[index] + '2'] === 0) {
            cellContent = ''
          }
          const cellStyle = { fontStyle: 'bold', fontSize: 8 }
          rowToPush.push({
            content: cellContent,
            styles: cellStyle
          })
        }
        table.rows.push(rowToPush)
        tables.push(table)
      })

      myWorker.postMessage({
        tables: tables,
        datestring: datestring,
        messages: {
          'messages.Colore': _this.$t('messages.Colore'),
          'messages.Tot': _this.$t('messages.Tot'),
          'messages.Gen': _this.$t('messages.Gen'),
          'messages.Feb': _this.$t('messages.Feb'),
          'messages.Mar': _this.$t('messages.Mar'),
          'messages.Apr': _this.$t('messages.Apr'),
          'messages.Mag': _this.$t('messages.Mag'),
          'messages.Giu': _this.$t('messages.Giu'),
          'messages.Lug': _this.$t('messages.Lug'),
          'messages.Ago': _this.$t('messages.Ago'),
          'messages.Set': _this.$t('messages.Set'),
          'messages.Ott': _this.$t('messages.Ott'),
          'messages.Nov': _this.$t('messages.Nov'),
          'messages.Dic': _this.$t('messages.Dic'),
          'messages.Report Scheda Compatta del #data#': _this.$t('messages.Report Scheda Compatta del #data#', [datestring])
        }
      })
    },
    async getReportClientCompact () {
      if (!this.filter.selectedArea) {
        return
      }
      this.updateFilterQuery()
      if (!this.initializeFilter) {
        this.filter.microareas = []
        this.filter.selectedMicroArea = ''
        this.filter.clients = []
        this.filter.selectedClient = ''
        this.filter.products = []
        this.filter.selectedProducts = []
      }
      this.order = 'nome'
      this.isLoading = true
      this.reportClientCompact = await this.$store.dispatch('reports/getReportClientCompact', { area: this.filter.selectedArea, year: this.selectedYear })
      this.reportClientCompact.forEach((c) => {
        c.tot1 = 0
        c.tot2 = 0
        c.gen1 = 0
        c.feb1 = 0
        c.mar1 = 0
        c.apr1 = 0
        c.mag1 = 0
        c.giu1 = 0
        c.lug1 = 0
        c.ago1 = 0
        c.set1 = 0
        c.ott1 = 0
        c.nov1 = 0
        c.dic1 = 0
        c.gen2 = 0
        c.feb2 = 0
        c.mar2 = 0
        c.apr2 = 0
        c.mag2 = 0
        c.giu2 = 0
        c.lug2 = 0
        c.ago2 = 0
        c.set2 = 0
        c.ott2 = 0
        c.nov2 = 0
        c.dic2 = 0
        if (c.microarea && this.filter.microareas.findIndex((m) => m === c.microarea) < 0) {
          this.filter.microareas.push(c.microarea)
        }
        if (this.filter.clients.findIndex((c) => c === c.azienda) < 0) {
          this.filter.clients.push(c.azienda)
        }

        c.products.forEach((p) => {
          if (this.filter.products.findIndex((prod) => prod === p.colore_calcolato) < 0) {
            this.filter.products.push(p.colore_calcolato)
          }
          p.tot1 = parseFloat(p.tot1)
          p.tot2 = parseFloat(p.tot2)
          p.gen1 = parseFloat(p.gen1)
          p.feb1 = parseFloat(p.feb1)
          p.mar1 = parseFloat(p.mar1)
          p.apr1 = parseFloat(p.apr1)
          p.mag1 = parseFloat(p.mag1)
          p.giu1 = parseFloat(p.giu1)
          p.lug1 = parseFloat(p.lug1)
          p.ago1 = parseFloat(p.ago1)
          p.set1 = parseFloat(p.set1)
          p.ott1 = parseFloat(p.ott1)
          p.nov1 = parseFloat(p.nov1)
          p.dic1 = parseFloat(p.dic1)
          p.gen2 = parseFloat(p.gen2)
          p.feb2 = parseFloat(p.feb2)
          p.mar2 = parseFloat(p.mar2)
          p.apr2 = parseFloat(p.apr2)
          p.mag2 = parseFloat(p.mag2)
          p.giu2 = parseFloat(p.giu2)
          p.lug2 = parseFloat(p.lug2)
          p.ago2 = parseFloat(p.ago2)
          p.set2 = parseFloat(p.set2)
          p.ott2 = parseFloat(p.ott2)
          p.nov2 = parseFloat(p.nov2)
          p.dic2 = parseFloat(p.dic2)

          c.tot1 += p.tot1
          c.tot2 += p.tot2
          c.gen1 += p.gen1
          c.feb1 += p.feb1
          c.mar1 += p.mar1
          c.apr1 += p.apr1
          c.mag1 += p.mag1
          c.giu1 += p.giu1
          c.lug1 += p.lug1
          c.ago1 += p.ago1
          c.set1 += p.set1
          c.ott1 += p.ott1
          c.nov1 += p.nov1
          c.dic1 += p.dic1
          c.gen2 += p.gen2
          c.feb2 += p.feb2
          c.mar2 += p.mar2
          c.apr2 += p.apr2
          c.mag2 += p.mag2
          c.giu2 += p.giu2
          c.lug2 += p.lug2
          c.ago2 += p.ago2
          c.set2 += p.set2
          c.ott2 += p.ott2
          c.nov2 += p.nov2
          c.dic2 += p.dic2
        })
        c.filteredProducts = c.products.filter(() => true)
      })
      this.filter.microareas.sort()
      this.filter.clients.sort()
      this.filter.products.sort()
      this.filteredReport = this.reportClientCompact.filter(() => true)
      if (this.initializeFilter) {
        this.initializeFilter = false
        this.doFilter()
      }
      this.isLoading = false
    },

    reOrder () {
      this.filteredReport.sort((x, y) => {
        if (this.order === 'nome') {
          if (x.azienda > y.azienda) {
            return 1
          }
          if (x.azienda < y.azienda) {
            return -1
          }
        } else if (this.order === 'cap') {
          if (x.cap > y.cap) {
            return 1
          }
          if (x.cap < y.cap) {
            return -1
          }
          if (x.cap === y.cap) {
            if (x.azienda > y.azienda) {
              return 1
            }
            if (x.azienda < y.azienda) {
              return -1
            }
          }
        }
        return 0
      })
    },

    async doFilter () {
      this.updateFilterQuery()
      this.filteredReport = this.reportClientCompact.filter(c => {
        let isFiltered = (
          ((this.filter.selectedMicroArea ?? '') === '' || c.microarea === this.filter.selectedMicroArea) &&
          ((this.filter.selectedClient ?? '') === '' || c.azienda === this.filter.selectedClient)
          // && ((this.filter.selectedAgent ?? '') === '' || areasSubAgentFilter.some((area) => { return c.cd_agent === area.cd_agent }))
        )
        if (isFiltered) {
          c.tot1 = 0
          c.tot2 = 0
          c.gen1 = 0
          c.feb1 = 0
          c.mar1 = 0
          c.apr1 = 0
          c.mag1 = 0
          c.giu1 = 0
          c.lug1 = 0
          c.ago1 = 0
          c.set1 = 0
          c.ott1 = 0
          c.nov1 = 0
          c.dic1 = 0
          c.gen2 = 0
          c.feb2 = 0
          c.mar2 = 0
          c.apr2 = 0
          c.mag2 = 0
          c.giu2 = 0
          c.lug2 = 0
          c.ago2 = 0
          c.set2 = 0
          c.ott2 = 0
          c.nov2 = 0
          c.dic2 = 0

          c.filteredProducts = c.products.filter(p => {
            const isProductFiltered = (
              this.filter.selectedProducts.length === 0 ||
              this.filter.selectedProducts.findIndex((sp) => sp === p.colore_calcolato) >= 0
            )
            if (isProductFiltered) {
              c.tot1 += p.tot1
              c.tot2 += p.tot2
              c.gen1 += p.gen1
              c.feb1 += p.feb1
              c.mar1 += p.mar1
              c.apr1 += p.apr1
              c.mag1 += p.mag1
              c.giu1 += p.giu1
              c.lug1 += p.lug1
              c.ago1 += p.ago1
              c.set1 += p.set1
              c.ott1 += p.ott1
              c.nov1 += p.nov1
              c.dic1 += p.dic1
              c.gen2 += p.gen2
              c.feb2 += p.feb2
              c.mar2 += p.mar2
              c.apr2 += p.apr2
              c.mag2 += p.mag2
              c.giu2 += p.giu2
              c.lug2 += p.lug2
              c.ago2 += p.ago2
              c.set2 += p.set2
              c.ott2 += p.ott2
              c.nov2 += p.nov2
              c.dic2 += p.dic2
            }
            return isProductFiltered
          })
          if (c.filteredProducts.length === 0) {
            isFiltered = false
          }
        }
        return isFiltered
      })
    },
    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    },
    euroFormat (value) {
      if (!value) return '&nbsp;'
      return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €'
    }
  }
}
</script>

<style scoped>
table > thead > tr > th, table > tbody > tr > td {
  padding: 0px;
  padding-right: 4px;
  padding-left: 4px;
}
.reportval {
  font-size: 0.665rem;
}
.reportval.highlight {
  font-weight: bold;
  font-size: 0.875rem;
}
.reportval.highlight.reportval-negative {
  color: red
}
table th {
  position: sticky;
  top: 0;
}
table th:first-child {
  position: sticky;
  left: 0;
  top: 0;
  background-color: #ddd;
  margin-right: 4px;
  padding-right: 4px;
  max-width: 160px;
  width: 120px;
  z-index: 1;
}
table td:first-child {
  position: sticky;
  left: 0;
  background-color: #ddd;
  margin-right: 4px;
  padding-right: 4px;
  min-width: 120px;
  max-width: 160px;
}
table td:nth-child(2n+3) {background: rgb(230, 230, 230)}
.report-azienda {
  font-size: 0.665rem;
  font-weight: bold;
}
.report-colore {
  font-size: 0.665rem;
  font-weight: bold;
}
.report-cdclien {
  font-size: 0.665rem;
  font-weight: bold;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 40px;
}
.v-expansion-panel > .v-expansion-panel-header {
  min-height: 40px;
}
.accordion-title {
  font-size: 0.7rem !important;
}
table > tbody > tr > th, table > thead > tr > th, table > tfoot > tr > th {
    font-size: 0.75rem;
}

.agent-header {
  background-color: #5e5e5e;
  min-height: 21px;
  color: white;
  font-size: 0.875rem;
}

table > thead > tr:last-child > th {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
table {
    border-spacing: 0;
}
::v-deep.v-pagination__navigation {
  width: 24px;
  height: 24px;
}
::v-deep.v-pagination__item {
  min-width: 24px;
  height: 24px
}
</style>
