<template>
  <div>
    <div ref="flyaway">
      <!-- so named because it will fly away to another component -->
      <!-- <slot> </slot> -->
      <div class="d-flex justify-center mt-5 mb-2">
        <h1>{{ client.azienda }}</h1>
      </div>
      <div class="d-flex justify-center mb-2">
        <h5>{{ client.indirizzo }}, {{ client.cap }} {{ client.citta }} ({{ client.provincia }})</h5>
      </div>
      <div
        class="d-flex justify-space-around mt-2"
      >
        <div
          class="d-flex flex-column align-center"
          style="cursor: pointer; width: 36px"
          @click="endMoving(false)"
        >
          <v-btn
            icon
            color="red"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
          <h5>{{ $t('messages.Annulla') }}</h5>
        </div>
        <v-divider vertical />
        <div
          class="d-flex flex-column align-center"
          style="cursor: pointer; width: 36px"
          @click="endMoving(true)"
        >
          <v-btn
            icon
            color="primary"
            :loading="isSaving"
          >
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <h5>{{ $t('messages.Salva') }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    map: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    infowindow: null,
    isSaving: false
  }),

  computed: {
    globalMovingMarker () {
      return this.$store.getters['map/movingMarker']
    }
  },

  mounted () {
    /* eslint-disable no-undef */
    this.infowindow = new google.maps.InfoWindow({
      content: this.$refs.flyaway
    })
    google.maps.event.addListener(this.infowindow, 'closeclick', () => {
      this.endMoving(false)
      this.$emit('save', false)
    })
    /* eslint-enable no-undef */
    const el = this.$refs.flyaway
    el.parentNode.removeChild(el)
    this.infowindow.open(this.map, this.client.marker)
  },

  beforeDestroy () {
    /* eslint-disable no-undef */
    this.infowindow.setMap()
    this.infowindow = null
  },

  methods: {
    async endMoving (save) {
      if (this.isSaving) return
      if (save) {
        const data = {
          clientId: this.client.id,
          indirizzoId: this.client.indirizzo_id,
          position: {
            lat: this.client.marker.position.lat(),
            lng: this.client.marker.position.lng()
          }
        }
        this.isSaving = true
        const r = await this.$store.dispatch('map/saveNewPosition', data)
        if (!r) {
          // errore
          const dataMessage = { isOpen: true, message: this.$t('messages.si è verificato un errore'), color: 'error' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          this.isSaving = false
        } else {
          // salvato ok
          const dataMessage = { isOpen: true, message: this.$t('messages.La posizione è stata aggiornata'), color: 'success' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          this.isSaving = false
          this.$store.dispatch('map/setMovingMarker', false)
          this.$emit('save', true)
        }
      } else {
        this.$store.dispatch('map/setMovingMarker', false)
        this.$emit('save', false)
      }
    }
  }
}
</script>

<style scoped>
.container-info {
    position:absolute;
    margin-top: -16px;
    padding:4px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
</style>
