<template>
  <v-container class="px-1 py-0">
    <v-progress-circular
      v-if="isInitializing != 0"
      indeterminate
      color="primary"
      class="mt-3 ml-3"
    />
    <div
      v-else
      class="mt-2"
    >
      <div
        v-if="!searchMenu"
        class="d-flex flex-row-reverse"
      >
        <v-btn
          icon
          color="grey"
          @click="openSearchMenu"
        >
          <v-icon>
            mdi-tune
          </v-icon>
        </v-btn>
      </div>

      <div
        v-if="searchMenu"
        class="mb-2"
      >
        <div
          class="d-flex flex-row justify-center"
        >
          <v-row>
            <v-col
              cols="10"
            >
              <div
                v-if="isAdmin"
                class="d-flex justify-center"
              >
                <v-switch
                  v-model="searchOrEdit"
                  :label="$t('messages.Modifica')"
                  color="error"
                  value="edit"
                  hide-details
                />
              </div>
            </v-col>
            <v-col
              cols="2"
            >
              <div
                v-if="isAdmin"
                class="d-flex flex-row-reverse justify-start"
              >
                <v-btn
                  icon
                  color="grey"
                  @click="closeSearchMenu"
                >
                  <v-icon>
                    mdi-eye-off-outline
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>

        <div>
          <div
            class="caption mt-2"
          >
            <h5>
              {{ $t('messages.Imposta i parametri di ricerca!') }}
            </h5>
          </div>
          <div
            :class="'d-flex justify-center mb-2' + (searchOrEdit ? ' flex-column pa-1' : '') "
            :style="searchOrEdit ? 'border: 3px solid silver;' : ''"
          >
            <div class="d-flex flex-row flex-grow-1">
              <div class="d-flex flex-grow-1">
                <v-text-field
                  v-model="selectedText"
                  filled
                  hide-details
                  elevation="0"
                  tile
                  clearable
                  :label="searchOrEdit ? $t('messages.Digita qui la parola o le parole da inserire') : $t('messages.Digita qui la parola o le parole da cercare')"
                  :placeholder="searchOrEdit ? $t('messages.Digita qui la parola o le parole da inserire') : $t('messages.Digita qui la parola o le parole da cercare')"
                  class="persistent-placeholder"
                />
              </div>
              <div class="d-flex justify-center align-center">
                <v-dialog
                  v-model="infoDialog"
                  width="500"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="ml-2 align-center justify-center"
                      color="grey"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>
                        mdi-information
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>{{ $t('messages.Comandi di ricerca') }}</v-card-title>
                    <v-card-text>
                      {{ $t('messages.Comandi riga1') }}<br>
                      {{ $t('messages.Comandi riga2') }}
                    </v-card-text>
                    <v-card-actions>
                      <v-col class="text-right">
                        <v-btn
                          tile
                          color="primary"
                          elevation="0"
                          @click="infoDialog = false"
                        >
                          {{ $t('messages.Chiudi') }}
                        </v-btn>
                      </v-col>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>
            <div class="d-flex flex-row justify-center mt-2">
              <v-btn
                v-if="isAdmin && searchOrEdit"
                color="purple darken-1"
                :disabled="!selectedText"
                small
                :dark="!!selectedText"
                fab
                elevation="0"
                :loading="isLoading"
                @click="insertTag('editText')"
              >
                <v-icon>
                  mdi-plus-circle
                </v-icon>
              </v-btn>
            </div>
          </div>

          <div
            :class="'justify-center mb-2' + (searchOrEdit ? ' flex-column pa-1' : '') "
            :style="searchOrEdit ? 'border: 3px solid silver;' : ''"
          >
            <div>
              <client-selector
                v-model="selectedClient"
                :clients="clientsListMinimal"
                hide-details
                class="persistent-placeholder"
              />
            </div>
            <div class="d-flex flex-row justify-center mt-2">
              <v-btn
                v-if="isAdmin && searchOrEdit"
                color="deep-orange darken-1"
                :disabled="!selectedClient"
                small
                :dark="!!selectedClient"
                fab
                elevation="0"
                :loading="isLoading"
                @click="insertTag('editClient')"
              >
                <v-icon>
                  mdi-plus-circle
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div
            v-if="isAdmin && !searchOrEdit"
            class="d-flex mt-n2 mb-2"
          >
            <agent-selector
              v-model="selectedAgent"
              :agents="galleryAgents"
              hide-details
              class="persistent-placeholder"
            />
          </div>

          <div
            class="d-flex flex-column justify-center mb-2 pa-1"
            style="border: 3px solid silver;"
          >
            <div class="d-flex flex-grow-1">
              <product-selector
                v-model="productTag"
                :products="products"
                hide-details
                style="width:100%; min-width:100%; max-width:100%;"
                class="persistent-placeholder"
                @input="updateValue"
              />
            </div>
            <div class="d-flex flex-row justify-center">
              <v-btn
                v-if="isAdmin && searchOrEdit"
                color="primary"
                :disabled="!productTag"
                small
                :dark="!!productTag"
                fab
                elevation="0"
                :loading="isLoading"
                @click="insertTag('editProduct')"
              >
                <v-icon>
                  mdi-plus-circle
                </v-icon>
              </v-btn>
              <!-- <v-btn
                v-if="!searchOrEdit"
                color="primary"
                :disabled="!productTag"
                small
                :dark="!!productTag"
                fab
                elevation="0"
                :loading="isLoading"
                @click="insertTag('search')"
              >
                <v-icon>
                  mdi-magnify
                </v-icon>
              </v-btn> -->
            </div>
          </div>

          <!-- <div
            v-if="!searchOrEdit"
            class="d-flex flex-column caption mb-2 pa-1"
            style="background-color: rgba(0, 0, 0, 0.06);"
          >
            <div>
              <v-icon
                class="mr-1"
              >
                mdi-magnify
              </v-icon>
              Etichette da ricercare:
            </div>
            <div class="d-flex">
              <div
                class="pa-2 mb-1"
                style="border-radius: 20px; background-color: white;"
              >
                <span v-if="!tagsToSearch.length">Nessun filtro impostato</span>
                <v-chip
                  v-for="(tagTS, tts) in tagsToSearch"
                  v-else
                  :key="tts"
                  close
                  class="mx-1 my-1"
                  :color="tagChipBGColor(tagTS.type)"
                  text-color="white"
                  small
                  @click:close="tagsToSearch.splice(tts, 1)"
                >
                  {{ tagDescription (tagTS.type, tagTS.value, tagTS) }}
                </v-chip>
              </div>
            </div>
          </div> -->

          <div
            v-if="isAdmin"
            class="mb-2"
          >
            <transition
              name="transition-element"
              tag="div"
            >
              <div
                v-if="searchOrEdit"
                class="d-flex flex-column caption pa-1"
                style="background-color: rgba(0, 0, 0, 0.06);"
              >
                <div>
                  <v-icon
                    class="mr-1"
                  >
                    mdi-image-multiple
                  </v-icon>
                  {{ $t('messages.Etichette immagini selezionate') }}:
                </div>
                <div class="d-flex">
                  <div
                    class="pa-2 mb-1"
                    style="border-radius: 20px; background-color: white;"
                  >
                    <span v-if="!selectedImagesTags.length">{{ $t('messages.Nessuna immagine selezionata') }}</span>
                    <v-chip
                      v-for="(selectedImageTag, sit) in selectedImagesTags"
                      v-else
                      :key="sit"
                      close
                      class="mx-1 my-1"
                      :color="tagChipBGColor(selectedImageTag.type)"
                      :text-color="selectedImageTag.omni ? 'white' : tagChipBGColor(selectedImageTag.type)"
                      small
                      :outlined="!selectedImageTag.omni"
                      @click:close="removeTag(sit)"
                      @click="insertTag('fromSelectedToSearch', sit)"
                    >
                      {{ tagDescription (selectedImageTag.type, selectedImageTag.value, selectedImageTag) }}
                    </v-chip>
                  </div>
                </div>
              </div>
            </transition>
            <confirm-dialog
              v-if="isAdmin"
              v-model="confirmDialogRemoveTag"
              color="error"
              :title="$t('messages.Rimuovi')"
              :description="$t('messages.Sei sicuro di voler rimuovere questa Etichetta da #Numero# immagini?', [selectedImages.length])"
              @confirm="removeTagConfirm(); confirmDialogRemoveTag=false"
              @cancel="tagIndexToRemove=-1;confirmDialogRemoveTag=false"
            />
          </div>

          <div
            v-if="isAdmin && searchOrEdit"
            class="d-flex flex-column caption mb-2 pa-1"
            style="background-color: rgba(0, 0, 0, 0.06);"
          >
            <div>
              <v-icon
                class="mr-1"
              >
                mdi-plus-circle
              </v-icon>
              {{ $t('messages.Etichette da aggiungere') }}:
            </div>
            <div class="d-flex">
              <div
                class="pa-2 mb-1"
                style="border-radius: 20px; background-color: white;"
              >
                <span v-if="!tagsToAdd.length">{{ $t('messages.Nessuna etichetta inserita') }}</span>
                <v-chip
                  v-for="(tagTA, tta) in tagsToAdd"
                  v-else
                  :key="tta"
                  close
                  class="mx-1 my-1 white--text"
                  :color="tagChipBGColor(tagTA.type)"
                  small
                  @click:close="tagsToAdd.splice(tta, 1)"
                  @click="insertTag('fromAddToSearch', tta)"
                >
                  {{ tagDescription (tagTA.type, tagTA.value, tagTA) }}
                </v-chip>
              </div>
            </div>
          </div>

          <div
            v-if="isAdmin && searchOrEdit"
            class="d-flex flex-column caption mb-3 pa-1"
            style="background-color: rgba(0, 0, 0, 0.06);"
          >
            <div class="d-flex justify-space-between">
              <div>
                <v-icon
                  class="mr-1"
                >
                  mdi-wrench
                </v-icon>
                {{ $t('messages.Operazioni') }}:
              </div>
              <v-btn
                color="primary"
                tile
                :width="173"
                elevation="0"
                small
                dark
                @click="selectedImages.length === imagesList.length ? (selectedImages = []) : (selectedImages = imagesList)"
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  {{ selectedImages.length === imagesList.length ? 'mdi-selection-multiple' : 'mdi-checkbox-multiple-marked-outline' }}
                </v-icon>
                {{ selectedImages.length === imagesList.length ? 'Pulisci Selezione' : 'Seleziona Tutto' }}
              </v-btn>
            </div>
            <div class="d-flex flex-column align-center mt-3 pb-2">
              <v-btn
                class="ma-1 pa-1 white--text"
                color="success"
                small
                :width="171"
                tile
                elevation="0"
                :disabled="!selectedImages.length"
                @click="updateTags"
              >
                <v-icon class="mr-1">
                  mdi-plus-circle
                </v-icon>
                {{ $t('messages.Aggiungi Etichetta') }}
              </v-btn>

              <v-btn
                class="ma-1 pa-1 white--text"
                color="error"
                small
                :width="171"
                tile
                :disabled="!selectedImages.length"
                elevation="0"
                @click="deleteImages"
              >
                <v-icon class="mr-1">
                  mdi-delete
                </v-icon>
                {{ $t('messages.Elimina Immagini') }}
              </v-btn>
            </div>
          </div>
        </div>

        <div
          v-if="!searchOrEdit"
          class="d-flex justify-center mb-3"
        >
          <v-btn
            color="success"
            class="btn btn-lg btn-primary white--text mx-0"
            type="button"
            :loading="isLoading"
            elevation="0"
            block
            tile
            @click="updateImageFilter()"
          >
            <v-icon>
              mdi-magnify
            </v-icon>
            {{ $t('messages.Ricerca') }}
          </v-btn>
        </div>

        <v-dialog
          v-if="isAdmin"
          v-model="confirmDialogUpdateTag"
          max-width="290"
        >
          <v-card>
            <v-card-text class="pt-4 align-center justify-center">
              {{ $t('messages.Sei sicuro di voler aggiornare le etichette di #Numero# immagini?', [selectedImages.length]) }}
              <div class="d-flex justify-space-between justify-center align-center">
                <v-btn
                  color="rgba(0, 0, 0, 0.06) white--text"
                  small
                  tile
                  elevation="0"
                  :loading="isLoading"
                  @click="confirmDialogUpdateTag=false"
                >
                  {{ $t('messages.Annulla') }}
                </v-btn>
                <v-btn
                  color="success"
                  small
                  dark
                  tile
                  elevation="0"
                  :loading="isLoading"
                  @click="updateTagsConfirm(); confirmDialogUpdateTag=false"
                >
                  {{ $t('messages.Aggiungi') }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-if="isAdmin"
          v-model="confirmDialogDeleteImage"
          max-width="290"
        >
          <v-card>
            <v-card-text class="pt-4 align-center justify-center">
              {{ $t('messages.Sei sicuro di voler eliminare #Numero# immagini?', [selectedImages.length]) }}
              <div class="d-flex justify-space-between justify-center align-center">
                <v-btn
                  color="rgba(0, 0, 0, 0.06) white--text"
                  small
                  tile
                  elevation="0"
                  :loading="isLoading"
                  @click="confirmDialogDeleteImage=false"
                >
                  {{ $t('messages.Annulla') }}
                </v-btn>
                <v-btn
                  color="error"
                  small
                  dark
                  tile
                  elevation="0"
                  :loading="isLoading"
                  @click="deleteImagesConfirm(); confirmDialogDeleteImage=false"
                >
                  {{ $t('messages.Elimina') }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <div
        v-if="imagesList.length"
        class="d-flex justify-center caption"
      >
        {{ $t('messages.Totale Immagini') }}: <b>{{ imagesList.length }}</b>
      </div>

      <div>
        <v-pagination
          v-if="imagesList.length"
          v-model="currentPage"
          :length="Math.ceil(imagesList.length/pageElements)"
        />
      </div>

      <v-item-group
        v-if="imagesList.length"
        v-model="selectedImages"
        class="mt-4 mb-3"
        multiple
      >
        <div>
          <v-row class="ml-0 mr-n1">
            <v-item
              v-for="(image) in filtererImageList"
              :key="image.id"
              v-slot="{ active, toggle }"
              :value="image"
              class="white--text"
            >
              <v-col
                cols="4"
                lg="3"
                class="pl-0 pr-1 pt-0 pb-1"
              >
                <v-img
                  :src="'/uploads/'+image.name"
                  :lazy-src="'/uploads/'+image.name"
                  aspect-ratio="1"
                  class="mx-0"
                  style="background-color: rgba(0, 0, 0, 0.06); cursor:pointer;"
                  :color="active ? 'primary' : ''"
                  text-color="white"
                  @click="imageClick(toggle, $event, '/uploads/'+image.name)"
                >
                  <div v-if="image.tags.length<=4 || image.expand">
                    <v-chip
                      v-for="(tag,t) in image.tags"
                      :key="t"
                      class="mx-1 my-1 hidden-sm-and-down d-md-flex float-left white--text"
                      :color="tagChipBGColor(tag.type)"
                      small
                      @click.stop="insertTag('fromImage', tag)"
                    >
                      {{ tagDescription(tag.type, tag.value, tag) }}
                    </v-chip>
                  </div>
                  <div v-else>
                    <v-chip
                      class="mx-1 my-1 hidden-sm-and-down d-md-flex float-left white--text"
                      color="success"
                      small
                      @click.stop="image.expand=true; imagesList.splice(0,1,imagesList[0])"
                    >
                      {{ image.tags.length }} {{ $t('messages.Etichette') }}
                    </v-chip>
                  </div>
                  <v-card
                    v-if="active && isAdmin"
                    :color="active ? 'primary' : ''"
                    height="100%"
                    class="transparent"
                  />
                  <v-icon
                    v-if="image.last_day && image.user_id === userId"
                    color="error"
                    class="pa-1"
                    style="background-color: #ffffff9e;border-radius: 50%; position: absolute; right:5px; top:5px"
                    @click.stop="deleteOwnImage(image)"
                  >
                    mdi-delete
                  </v-icon>
                </v-img>

                <!-- ToDo: Check se esiste modo migliore per mostrare Tag dentro / fuori a seconda dello schermo -->

                <div v-if="image.tags.length<=4 || image.expand">
                  <v-chip
                    v-for="(tag,t) in image.tags"
                    :key="t"
                    class="hidden-md-and-up d-xs-flex float-left px-1"
                    :color="tagChipBGColor(tag.type)"
                    text-color="white"
                    x-small
                    @click.stop="insertTag('fromImage', tag)"
                  >
                    {{ tagDescription(tag.type, tag.value, tag) }}
                  </v-chip>
                </div>
                <div v-else>
                  <v-chip
                    class="hidden-md-and-up d-xs-flex float-left px-1"
                    color="success"
                    text-color="white"
                    x-small
                    @click.stop="image.expand=true; imagesList.splice(0,1,imagesList[0])"
                  >
                    {{ image.tags.length }} {{ $t('messages.Etichette') }}
                  </v-chip>
                </div>
              </v-col>
            </v-item>
          </v-row>
        </div>
      </v-item-group>
      <v-card
        v-else-if="!isLoading"
        elevation="0"
        tile
        class="error d-flex align-center justify-center"
        style="min-height:150px; max-height:150px; height:150px;"
      >
        <span
          class="title white--text"
        >
          {{ $t('messages.Nessuna immagine trovata secondo i parametri di ricerca impostati') }}
        </span>
      </v-card>
      <div>
        <v-pagination
          v-if="imagesList.length"
          v-model="currentPage"
          class="mt-2"
          :length="Math.ceil(imagesList.length/pageElements)"
        />
      </div>

      <div
        v-if="imagesList.length"
        class="d-flex justify-center caption"
      >
        {{ $t('messages.Totale Immagini') }}: <b>{{ imagesList.length }}</b>
      </div>
    </div>

    <confirm-dialog
      v-model="confirmDialogDeleteOwnImage"
      color="error"
      :title="$t('messages.Rimuovi')"
      :description="$t('messages.Sei sicuro di voler rimuovere quest Immagine?')"
      @confirm="deleteOwnImageConfirm(); confirmDialogDeleteOwnImage=false"
      @cancel="confirmDialogDeleteOwnImage=false"
    />
  </v-container>
</template>

<script>
import ConfirmDialog from '../components/ConfirmDialog'
import AgentSelector from '../components/AgentSelector.vue'
import ClientSelector from '../components/ClientSelector.vue'
import ProductSelector from '../components/ProductSelector.vue'

export default {
  name: 'Gallery',

  components: {
    ConfirmDialog,
    AgentSelector,
    ClientSelector,
    ProductSelector
  },

  data: () => ({
    searchOrEdit: false,
    searchMenu: true,
    infoDialog: false,
    isInitializing: 0,
    isLoading: false,
    tags: [],
    files: [],
    tagsToAdd: [],
    tagsToSearch: [],
    selectedTag: null,
    selectedImages: [],
    selectedClient: null,
    productTag: null,
    selectedProduct: null,
    selectedAgent: null,
    selectedText: null,
    confirmDialogRemoveTag: false,
    confirmDialogUpdateTag: false,
    confirmDialogDeleteImage: false,
    confirmDialogDeleteOwnImage: false,
    ownImageToDelete: null,
    tagIndexToRemove: -1,
    currentPage: 1,
    pageElements: 12
  }),

  computed: {
    userId () {
      return this.$store.getters['security/userId']
    },
    isAdmin () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN')
    },
    deletedMsg () {
      return this.selectedImages.length > '1' ? 'cancellate' : 'cancellata'
    },
    immagine () {
      return this.selectedImages.length > '1' ? 'immagini' : 'immagine'
    },
    imagesList () {
      return this.$store.getters['gallery/imagesList']
    },
    filtererImageList () {
      return this.imagesList.filter((img, i) => {
        return i >= (this.currentPage - 1) * this.pageElements && i < (this.currentPage) * this.pageElements
      })
    },
    clientsListMinimal () {
      return this.$store.getters['clients/clientsListMinimal']
    },
    galleryAgents () {
      return this.$store.getters['agents/galleryAgents']
    },
    products () {
      return this.$store.getters['products/list']
    },

    selectedImagesTags () {
      const result = this.selectedImages.reduce((iterator, image) => {
        image.tags.forEach(t => {
          let existingTag = iterator.tags.find(et => {
            return et.type === t.type && et.value === t.value
          })
          if (existingTag === undefined) {
            existingTag = iterator.omniTags.find(et => {
              return et.type === t.type && et.value === t.value
            })
            if (existingTag === undefined) {
              if (iterator.counter === 0) {
                iterator.omniTags.push({ type: t.type, value: t.value, azienda: t.azienda })
              } else {
                iterator.tags.push({ type: t.type, value: t.value, azienda: t.azienda })
              }
            }
          }
        })

        for (let index = iterator.omniTags.length - 1; index >= 0; index--) {
          const omniTag = iterator.omniTags[index]
          if (!image.tags.some(t => {
            return t.type === omniTag.type && t.value === omniTag.value
          })) {
            iterator.tags.push(iterator.omniTags.splice(index, 1)[0])
          }
        }
        iterator.counter++
        return iterator
      }, { counter: 0, tags: [], omniTags: [] })
      result.omniTags.forEach(t => { t.omni = true })
      result.tags.forEach(t => { t.omni = false })
      const orderdTags = [...result.tags, ...result.omniTags].sort((a, b) => {
        return a.value.toLowerCase() >= b.value.toLowerCase()
      })
      return orderdTags
    }
  },

  watch: {
    errorMessage (value) {
      if (value) {
        const dataMessage = { isOpen: true, message: value, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
    }
  },

  async created () {
    this.isInitializing = 4
    this.$store.dispatch('clients/clientsListMinimal', { isProjx: true }).then(() => this.isInitializing--)
    this.$store.dispatch('agents/galleryAgents').then(() => this.isInitializing--)
    this.$store.dispatch('products/list').then(() => this.isInitializing--)
    this.$store.dispatch('gallery/imagesList').then(() => this.isInitializing--)
  },

  methods: {
    openSearchMenu () {
      if (!(this.searchMenu === true)) {
        this.searchMenu = true
      } else this.searchMenu = false
    },
    closeSearchMenu () {
      this.searchMenu = false
    },

    imageClick (doToggle, $event, imagePath) {
      if (this.searchOrEdit) {
        doToggle($event)
      } else {
        window.open(imagePath, '_blank').focus()
      }
    },

    async updateImageFilter () {
      this.isLoading = true
      this.selectedImages = []
      // if (this.selectedText) {
      //   if (!(this.tagsToSearch.some(t => t.value === this.selectedText))) {
      //     this.tagsToSearch.push({ type: 'free', value: this.selectedText })
      //   }
      // }
      // if (this.selectedClient) {
      //   if (!(this.tagsToSearch.some(t => t.value === this.selectedClient))) {
      //     this.tagsToSearch.push({ type: 'client', value: this.selectedClient, azienda: ((this.clientsListMinimal.find((c) => c.id === this.selectedClient))?.azienda ?? 'non trovato: ' + this.selectedClient) })
      //   }
      //   this.selectedClient = ''
      // }
      if (this.productTag) {
        if (this.productTag.selectedCategoryId) {
          this.tagsToSearch.push({ type: 'categoryId', value: this.productTag.selectedCategoryId })
        }
        if (this.productTag.selectedLineId) {
          this.tagsToSearch.push({ type: 'lineId', value: this.productTag.selectedLineId })
        }
        if (this.productTag.selectedCommercialCodeId) {
          this.tagsToSearch.push({ type: 'commercialCodeId', value: this.productTag.selectedCommercialCodeId })
        }
      }

      await this.$store.dispatch('gallery/search', {
        client: this.selectedClient,
        text: this.selectedText,
        agent: this.selectedAgent,
        product: this.tagsToSearch

        // tags: this.tagsToSearch
      })
      if (this.imagesList.length === 0) {
        const message = this.$t('messages.Nessuna immagine trovata secondo i parametri di ricerca impostati')
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
      this.currentPage = 1
      this.isLoading = false
      this.tagsToSearch = []
    },

    tagDescription (tagType, value, tag) {
      switch (tagType) {
      case 'client':
        return tag.azienda// (this.clientsList.find((c) => c.id === value))?.azienda ?? 'non trovato: '.value
      case 'categoryId':
        return this.products.categories[value]?.titolo ?? 'undefined'
      case 'subCategoryId':
        return this.products.subCategories[value]?.titolo ?? 'undefined'
      case 'lineId':
        return this.products.lines[value]?.titolo ?? 'undefined'
      case 'commercialCodeId':
        return this.products.commercialcodes[value]?.codice ?? 'undefined'
      case 'free':
      case 'user':
        return value
      }
    },

    tagChipBGColor (tagType) {
      switch (tagType) {
      case 'client':
        return 'deep-orange darken-1 !important'
      case 'categoryId':
        return 'primary darken-2'
      case 'subCategoryId':
        return 'primary'
      case 'lineId':
        return 'primary'
      case 'commercialCodeId':
        return 'primary lighten-2'
      case 'free':
        return 'purple darken-1'
      case 'user':
        return 'brown darken-4'
      }
    },

    async deleteImagesConfirm () {
      if (this.isAdmin) {
        const result = await this.$store.dispatch('gallery/deleteImages', { images: this.selectedImages })
        if (result !== null) {
          const message = this.$t('messages.Immagini eliminate')
          const dataMessage = { isOpen: true, message: message, color: 'success' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          this.selectedImages.forEach(image => {
            const removedImageIndex = this.imagesList.findIndex(i => image.id === i.id)
            this.imagesList.splice(removedImageIndex, 1)
          })
          this.updateImageFilter()
          this.tagsToAdd = []
          this.selectedImages = []
        }
      }
    },
    deleteImages () {
      if (this.isAdmin) {
        this.confirmDialogDeleteImage = true
      }
    },
    async deleteOwnImageConfirm () {
      const result = await this.$store.dispatch('gallery/deleteOwnImage', { image: this.ownImageToDelete })
      if (result !== null) {
        const message = this.$t('messages.Immagine eliminata')
        const dataMessage = { isOpen: true, message: message, color: 'success' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        const removedImageIndex = this.imagesList.findIndex(i => this.ownImageToDelete.id === i.id)
        this.imagesList.splice(removedImageIndex, 1)
      } else {
        const message = this.$store.getters['gallery/errorMessage']
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
    },
    deleteOwnImage (image) {
      this.ownImageToDelete = image
      this.confirmDialogDeleteOwnImage = true
    },

    removePendingTagNoConfirm (i, tagArray) {
      const RemoveTag = i
      this.tagArray.splice(RemoveTag, 1)
    },

    async removeTagConfirm () {
      if (this.isAdmin) {
        const result = await this.$store.dispatch('gallery/removeTag', { images: this.selectedImages, tag: this.selectedImagesTags[this.tagIndexToRemove] })
        if (result !== null) {
          const message = this.$t('messages.Etichetta rimossa')
          const dataMessage = { isOpen: true, message: message, color: 'success' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          this.selectedImages.forEach(image => {
            const tagToRemove = this.selectedImagesTags[this.tagIndexToRemove]
            const currentTagIndexToRemove = image.tags.findIndex(t => {
              return t.type === tagToRemove.type && t.value === tagToRemove.value
            })
            if (currentTagIndexToRemove !== -1) {
              image.tags.splice(currentTagIndexToRemove, 1)
            }
          })
        } else {
          const message = this.$t('messages.ERRORE: Etichetta non rimossa')
          const dataMessage = { isOpen: true, message: message, color: 'error' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        }
        this.tagIndexToRemove = -1
      }
    },
    removeTag (i) {
      if (this.isAdmin) {
        this.tagIndexToRemove = i
        this.confirmDialogRemoveTag = true
      }
    },

    async updateTagsConfirm () {
      if (this.isAdmin) {
        const result = await this.$store.dispatch('gallery/updateTags', { images: this.selectedImages, tags: this.tagsToAdd })
        if (result !== null) {
          const message = this.$t('messages.Etichette aggiornate')
          const dataMessage = { isOpen: true, message: message, color: 'success' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          this.selectedImages.forEach(image => {
            this.tagsToAdd.forEach(tagToAdd => {
              if (!image.tags.some(t => {
                return t.type === tagToAdd.type && t.value === tagToAdd.value
              })) {
                image.tags.push(tagToAdd)
              }
            })
          })
          this.tagsToAdd = []
        }
      }
    },
    updateTags () {
      if (this.isAdmin) {
        let errorMessages = ''
        if (this.selectedImages.length === 0) {
          errorMessages += this.$t('messages.Selezionare almeno un immagine') + '\n'
        }
        if (!this.selectedText && !this.selectedClient && this.tagsToAdd.length === 0) {
          errorMessages += this.$t('messages.Selezionare almeno un Testo Libero o un Cliente o un Prodotto da inserire') + '\n'
        }
        if (errorMessages !== '') {
          const dataMessage = { isOpen: true, message: errorMessages, color: 'error' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          this.isLoading = false
          return
        }
        this.confirmDialogUpdateTag = true
      }
    },

    insertTag (type, index) {
      this.isLoading = true
      if (type === 'editText') {
        if (this.isAdmin) {
          if (!(this.tagsToAdd.some(t => t.value === this.selectedText))) {
            this.tagsToAdd.push({ type: 'free', value: this.selectedText })
          } else {
            const message = this.$t('messages.Etichetta già presente')
            const dataMessage = { isOpen: true, message: message, color: 'error' }
            this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          }
        }
      }
      if (type === 'editClient') {
        if (this.isAdmin) {
          const client = this.clientsListMinimal.find(c => c.id === this.selectedClient)
          if (!(this.tagsToAdd.some(t => t.value === this.selectedClient.value))) {
            this.tagsToAdd.push({ azienda: client.azienda, type: 'client', value: this.selectedClient })
          } else {
            const message = this.$t('messages.Etichetta già presente')
            const dataMessage = { isOpen: true, message: message, color: 'error' }
            this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          }
        }
      }
      if (type === 'editProduct') {
        if (this.isAdmin) {
          if (!(this.tagsToAdd.some(t => t.value === this.selectedProduct.value))) {
            this.tagsToAdd.push(this.selectedProduct)
          } else {
            const message = this.$t('messages.Etichetta già presente')
            const dataMessage = { isOpen: true, message: message, color: 'error' }
            this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          }
        }
      }
      if (type === 'search') {
        if (!(this.tagsToSearch.some(t => t.value === this.selectedProduct.value))) {
          this.tagsToSearch.push(this.selectedProduct)
        } else {
          const message = this.$t('messages.Etichetta già presente')
          const dataMessage = { isOpen: true, message: message, color: 'error' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        }
      }
      if (type === 'fromAddToSearch') {
        if (!(this.tagsToSearch.some(t => t.value === this.tagsToAdd[index].value))) {
          this.tagsToSearch.push(this.tagsToAdd[index])
        } else {
          const message = this.$t('messages.Etichetta già presente')
          const dataMessage = { isOpen: true, message: message, color: 'error' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        }
      }
      if (type === 'fromImage') {
        const tag = index
        if (tag.type === 'client') {
          this.selectedClient = tag.value
        } else if (tag.type === 'categoryId') {
          if (!this.productTag) {
            this.productTag = {}
          }
          this.productTag = JSON.parse(JSON.stringify(this.productTag))
          this.productTag.selectedCategoryId = tag.value
        } else if (tag.type === 'lineId') {
          if (!this.productTag) {
            this.productTag = {}
          }
          this.productTag = JSON.parse(JSON.stringify(this.productTag))
          this.productTag.selectedLineId = tag.value
        } else if (tag.type === 'commercialCodeId') {
          if (!this.productTag) {
            this.productTag = {}
          }
          this.productTag = JSON.parse(JSON.stringify(this.productTag))
          this.productTag.selectedCommercialCodeId = tag.value
        } else if (tag.type === 'user') {
          this.selectedAgent = this.galleryAgents.find(a => a.user_id === tag.user_id)?.user_id ?? null
        }
        // if (!(this.tagsToSearch.some(t => t.value === index.value))) {
        //   this.tagsToSearch.push(index)
        // } else {
        //   const message = 'Etichetta già presente'
        //   const dataMessage = { isOpen: true, message: message, color: 'error' }
        //   this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        // }
      }
      if (type === 'fromSelectedToSearch') {
        if (!(this.tagsToAdd.some(t => t.value === this.selectedImagesTags[index].value))) {
          this.tagsToAdd.push(this.selectedImagesTags[index])
        } else {
          const message = this.$t('messages.Etichetta già presente')
          const dataMessage = { isOpen: true, message: message, color: 'error' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        }
      }
      this.isLoading = false
    },

    confirmTag () {
      this.insertTag('edit')
    },

    updateValue () {
      if (!this.productTag) {
        this.$emit('input', null)
        return
      }
      this.selectedProduct = {}
      if (this.productTag) {
        if (this.productTag.selectedCommercialCodeId) {
          this.selectedProduct.type = 'commercialCodeId'
          this.selectedProduct.value = this.productTag.selectedCommercialCodeId
          this.selectedProduct.level = '4'
          this.selectedProduct.text = this.products.commercialcodes[this.productTag.selectedCommercialCodeId].codice
        } else if (this.productTag.selectedLineId) {
          this.selectedProduct.type = 'lineId'
          this.selectedProduct.value = this.productTag.selectedLineId
          this.selectedProduct.level = '3'
          this.selectedProduct.text = this.products.lines[this.productTag.selectedLineId].titolo
        } else if (this.productTag.selectedSubCategoryId) {
          this.selectedProduct.type = 'subCategoryId'
          this.selectedProduct.value = this.productTag.selectedSubCategoryId
          this.selectedProduct.level = '2'
          this.selectedProduct.text = this.products.subCategories[this.productTag.selectedSubCategoryId].titolo
        } else if (this.productTag.selectedCategoryId) {
          this.selectedProduct.type = 'categoryId'
          this.selectedProduct.value = this.productTag.selectedCategoryId
          this.selectedProduct.level = '1'
          this.selectedProduct.text = this.products.categories[this.productTag.selectedCategoryId].titolo
        } else {
          this.$emit('input', null)
          return
        }
        this.$emit('input', this.selectedProduct)
      }
    }

  }
}
</script>

<style scoped>
.transparent {
  background-color: white!important;
  opacity: 0.65;
  border-color: transparent!important;
  color: white!important;
  text-align: center;
  vertical-align: baseline;
}
</style>

<style>
.transition-element-enter-active,
.transition-element-leave-active {
  transition: all 0.3s;
  transition-timing-function: linear;
  max-height:200px;
}
.transition-element-enter,
.transition-element-leave-to
{
  opacity: 0;
  max-height: 0px;
}
</style>
