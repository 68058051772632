<template>
  <div class="container">
    <div class="row col">
      <h1>{{ $t('messages.Trasferimento Feedback') }}</h1>
    </div>
    <h5>{{ $t('messages.Trasferisce i feedback e le immagini da clienti projx chiusi a clienti projx attivi') }}</h5>
    <h5>{{ $t('messages.I feedback trasferiti avranno la dicitura Trasferito ex: XXXXX aggiunta alla fine delle note dei feedback trasferiti') }}</h5>
    <v-progress-circular
      v-if="isInitializing != 0"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <div
        class="mx-n3 d-lg-flex justify-lg-space-around"
      >
        <div class="d-flex flex-column align-center mt-4">
          <h6>{{ $t('messages.Clienti Projx chiusi') }}</h6>
          <h5>{{ $t('messages.Trasferisci da') }}</h5>
          <client-selector
            v-model="inactiveClientId"
            :clients="inactiveClients"
            style="max-width: 500px;width:300px;"
          />
        </div>

        <div class="d-flex justify-center align-center mt-4">
          <v-btn
            type="button"
            elevation="0"
            class="px-0"
            fab
            :dark="!!inactiveClientId && !!activeClientId"
            large
            color="green"
            :disabled="!inactiveClientId || !activeClientId"
            :loading="joiningClients"
            @click="doTransfer()"
          >
            <v-icon
              v-if="$vuetify.breakpoint.lgAndUp"
              dark
            >
              mdi-arrow-right-bold
            </v-icon>
            <v-icon
              v-else
              dark
            >
              mdi-arrow-down-bold
            </v-icon>
          </v-btn>
        </div>

        <div class="d-flex flex-column align-center mt-4">
          <h6>{{ $t('messages.Clienti Projx aperti') }}</h6>
          <h5>{{ $t('messages.Trasferisci a') }}</h5>
          <client-selector
            v-model="activeClientId"
            :clients="activeClients"
            style="max-width: 500px;width:300px;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ClientSelector from '../../components/ClientSelector'
export default {
  name: 'JoinProjxClients',

  components: {
    ClientSelector
  },

  data: () => ({
    isInitializing: 1,
    activeClients: [],
    inactiveClients: [],
    activeClientId: '',
    inactiveClientId: '',
    joiningClients: false
  }),

  computed: {
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    isAdmin () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN')
    },
    error () {
      return this.$store.getters['classes/error']
    }
  },

  created () {
    this.isInitializing = 2
    this.$store.dispatch('clients/clientsListMinimal', { isProjx: true }).then((r) => { this.isInitializing--; this.activeClients = r })
    this.$store.dispatch('clients/clientsListMinimalInactiveFeedbacks', { isProjx: true }).then((r) => { this.isInitializing--; this.inactiveClients = r })
  },

  methods: {
    async doTransfer () {
      this.joiningClients = true
      const result = await this.$store.dispatch('clientdetails/mergeProjx', { inactiveClientId: this.inactiveClientId, activeClientId: this.activeClientId })
      if (!result) {
        // show error
        const message = this.$store.getters['clientdetails/errorMessage']
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      } else {
        const message = this.$t('messages.Feedback e Immagini trasferiti')
        const dataMessage = { isOpen: true, message: message, color: 'success' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        this.activeClientId = ''
        this.inactiveClientId = ''
      }
      this.joiningClients = false
    }
  }
}
</script>
