<template>
  <v-autocomplete
    v-model="selectedArea"
    :items="areas"
    item-text="name"
    item-value="cd_agent"
    :label="label"
    :placeholder="$t('messages.Seleziona un Area')"
    :multiple="multiple"
    :clearable="clearable"
    :filled="filled"
    :attach="attach?'#attachAreaSelector':false"
    :readonly="readonly"
    :filter="filterAreas"
    hide-details
    :menu-props="{
      maxHeight:200,
    }"
    @keydown.enter="confirmEvent"
  >
    <template
      v-if="attach"
      #append-outer
    >
      <div id="attachAreaSelector" />
    </template>
    <template slot="no-data">
      <v-list-item>
        <v-list-item-content>
          {{ $t('messages.Nessun Area presente') }}
        </v-list-item-content>
      </v-list-item>
    </template>
    <template
      #[`item`]="{ item }"
    >
      <div>
        {{ item.cd_agent }} - {{ item.name }}
      </div>
    </template>
    <template
      #[`selection`]="{ item, index }"
    >
      <template v-if="multiple">
        <template v-if="index === selectedArea.length - 1">
          {{ item.cd_agent }} - {{ item.name }}
        </template>
        <template v-else>
          {{ item.cd_agent }} - {{ item.name }}, {{ ' ' }}
        </template>
      </template>
      <template v-else>
        {{ item.cd_agent }} - {{ item.name }}
      </template>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  name: 'AreaSelector',

  props: {
    value: {
      type: [String, Array],
      required: false,
      default: () => ''
    },
    label: {
      type: String,
      required: false,
      default: function () {
        return this.$t('messages.Seleziona un Area')
      }
    },
    filled: {
      type: Boolean,
      required: false,
      default: () => true
    },
    areas: {
      type: Array,
      required: true
    },
    attach: {
      required: false,
      default: false,
      validator: () => {
        return true
      }
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
      validator: () => {
        return true
      }
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
      validator: () => {
        return true
      }
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
      validator: () => {
        return true
      }
    }
  },
  data: () => ({
    selectedArea: ''
  }),
  watch: {
    value (newVal) {
      this.selectedArea = newVal
    },
    selectedArea (newVal) {
      if (this.selectedArea !== this.value) { this.$emit('input', newVal) }
    }
  },

  created () {
    this.selectedArea = this.value
  },

  methods: {
    filterAreas (area, queryText, itemText) {
      // console.log(area.azienda)
      // if (area.azienda === 'Yosh') { console.log(area.azienda) }
      // if (this.areas[this.areas.length - 1] === area) { console.log(area.azienda) }
      // console.log(this.areas[this.areas.length - 1])
      return ((area.name ?? '').toLowerCase().includes(queryText.toLowerCase()) || (area.cd_agent ?? '').toLowerCase().includes(queryText.toLowerCase()))
    },
    confirmEvent () {
      if (this.selectedArea) {
        this.$emit('confirm', this.selectedArea)
      }
    }
  }
}
</script>
