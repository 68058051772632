import axios from 'axios'

export default {
  list (data) {
    return axios.get('/api/classes/list', data)
  },
  setSuperClass (data) {
    return axios.post('/api/classes/set-superclass', data)
  }
}
