import ShopAPI from '../api/shop'

const FETCHING_NATIONS = 'FETCHING_NATIONS'
const FETCHING_NATIONS_SUCCESS = 'FETCHING_NATIONS_SUCCESS'
const FETCHING_NATIONS_ERROR = 'FETCHING_NATIONS_ERROR'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    isUpdating: false,
    error: null,
    nations: []
  },
  getters: {
    isLoading (state) {
      return state.isLoading
    },
    error (state) {
      return state.error
    },
    nations (state) {
      return state.nations
    }
  },
  mutations: {
    [FETCHING_NATIONS] (state) {
      state.isLoading = true
      state.error = null
    },
    [FETCHING_NATIONS_SUCCESS] (state, nations) {
      state.isLoading = false
      state.error = null
      state.nations = nations
    },
    [FETCHING_NATIONS_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
      state.nations = []
    }
  },
  actions: {
    async get ({ commit }) {
      commit(FETCHING_NATIONS)
      try {
        const response = await ShopAPI.listNations()
        commit(FETCHING_NATIONS_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_NATIONS_ERROR, error)
        return null
      }
    }
  }
}
