<template>
  <v-snackbar
    v-model="messageSnackbarIsOpen"
    :timeout="messageSnackbar.timeout"
    :color="messageSnackbar.color"
    :width="600"
    :style="'z-index:600000;top:100px;'"
    top
    right
    vertical
    tile
    transition="slide-x-reverse-transition"
  >
    <div
      id="show-message-progress-bar"
      class="progress-bar"
    />
    <div
      class="font-md"
    >
      <span style="white-space: pre-wrap;">{{ messageSnackbar.message }}</span>
    </div>
    <template #action="{ attrs }">
      <v-btn
        v-bind="attrs"
        class="font-md-button ml-4"
        elevation="0"
        tile
        :small="!$vuetify.breakpoint.smAndUp"
        @click="messageSnackbarIsOpen = false"
      >
        {{ $t('messages.Chiudi') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'ShowMessage',

  computed: {
    messageSnackbarIsOpen: {
      get: function () {
        return this.$store.getters['appState/messageSnackbarIsOpen']
      },
      set: function (newValue) {
        const data = { isOpen: newValue }
        this.$store.dispatch('appState/setMessageSnackbar', data)
      }
    },
    messageSnackbar () {
      return this.$store.getters['appState/messageSnackbar']
    }
  },

  watch: {
    messageSnackbarIsOpen (newVal) {
      const element = document.getElementById('show-message-progress-bar')
      if (newVal) {
        element.classList.remove('progress-bar-decreasing')
        setTimeout(() => element.classList.add('progress-bar-decreasing'), 200)
      }
    }
  }
}
</script>
<style scoped>
.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height:4px;
  background-color: white;
  transition: width 0s;
}

.progress-bar-decreasing {
  width:0%;
  transition: width 2.5s;
}

.font-md {
  font-size: 1.1rem
}
.font-md-button {
  font-size: 0.7rem
}
@media (max-width:600px){
  .font-md {
    font-size: 0.7rem
  }
  .font-md-button {
    font-size: 0.5rem
  }
}
</style>
<style>
.v-snack__wrapper {
  min-width: 344px;
}
@media (max-width:600px){
  .v-snack__wrapper {
    min-width: 90% !important;
  }
}
</style>
