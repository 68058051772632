<template>
  <v-container class="pa-1">
    <v-progress-circular
      v-if="isInitializing != 0 || isSending"
      indeterminate
      color="primary"
      class="mt-3 ml-3"
    />
    <div
      v-else-if="orderSuccess"
    >
      <div>
        <h4>{{ $t('messages.Ordine Effettuato') }}</h4>
      </div>
      {{ $t('messages.Una mail di riepilogo è stata mandata a #mail#', [notifyMail]) }}
    </div>
    <div
      v-else
      class="mt-2"
    >
      <div
        style="overflow: hidden;"
      >
        <v-card
          outlined
          tile
          class="mb-2"
        >
          <v-form
            ref="shippingdata"
            class="ma-2"
          >
            <h3>{{ $t('messages.Dati di spedizione') }}</h3>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  ref="ship1"
                  :value="selectedShipping.azienda"
                  :label="$t('messages.Azienda o Nome')"
                  :rules="[rules.required]"
                  @input="selectedShipping.azienda = $event;isDirty=true"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  ref="ship2"
                  :value="selectedShipping.indirizzo"
                  :label="$t('messages.Via e numero civico')"
                  :rules="[rules.required]"
                  @input="selectedShipping.indirizzo = $event;isDirty=true"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  ref="ship3"
                  :value="selectedShipping.citta"
                  :label="$t('messages.Città')"
                  :rules="[rules.required]"
                  @input="selectedShipping.citta = $event;isDirty=true"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  ref="ship4"
                  :value="selectedShipping.cap"
                  :label="$t('messages.CAP')"
                  :rules="[rules.required]"
                  @input="selectedShipping.cap = $event;isDirty=true"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-text-field
                  ref="ship5"
                  :value="selectedShipping.provincia"
                  :label="$t('messages.Provincia')"
                  :rules="[rules.required]"
                  @input="selectedShipping.provincia = $event;isDirty=true"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
              >
                <v-autocomplete
                  ref="ship7"
                  :value="selectedShipping.nazione"
                  :items="nations"
                  :menu-props="{
                    maxHeight:200,
                  }"
                  item-text="name"
                  item-value="isocode"
                  :label="$t('messages.Nazione')"
                  :rules="[rules.required]"
                  @input="selectedShipping.nazione = $event;isDirty=true"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </div>
      <v-card
        v-for="(article, i) in orderDetails.products"
        :key="i"
        outlined
        class="mb-2"
        tile
      >
        <v-icon
          color="error"
          class=""
          style="background-color: #ffffff9e;border-radius: 50%; position: absolute; right:0px; top:0px"
          @click.stop="article.confirmDialogRemoveProduct = true"
        >
          mdi-close
        </v-icon>
        <confirm-dialog
          v-model="article.confirmDialogRemoveProduct"
          color="error"
          :title="$t('messages.Rimuovere')"
          :description="$t('messages.Rimuovere il prodotto #codice#?', [article.code])"
          @confirm="remove(article); article.confirmDialogRemoveProduct=false"
          @cancel="article.confirmDialogRemoveProduct=false"
        />
        <h4 class="mx-1 mb-3">
          <div
            class="d-flex align-center"
          >
            <v-img
              v-if="hasCover(article)"
              class="article-image my-1 mr-2"
              :src="getCover(article)"
            />
            <v-img
              v-else-if="hasImage(article)"
              class="article-image my-1 mr-2"
              :src="getImage(article)"
            />
            <div
              v-else-if="hasColor(article)"
              class="article-image my-1 mr-2"
              :style="'background-color: ' + getColor(article)"
            />
            <div>
              {{ article.code }}
            </div>
          </div>
        </h4>
        <div class="d-flex">
          <v-select
            v-if="article.mode==='articolo'"
            v-model="article.selectedType"
            style="min-width: 244px; max-width: 244px;"
            class="my-0 py-0 mx-1 persistent-placeholder"
            :items="article.samplesFormats"
            item-value="value"
            item-text="text"
            :error-messages="article.selectedTypeMessage"
            :label="$t('messages.Tipo')"
            @input="isDirty=true"
          />

          <span
            v-if="article.mode==='campione'"
            class="px-1 mr-2"
            style="min-width: 236px; max-width: 236px; font-size: 0.9rem; margin-top: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
          >
            {{ article.description }}
          </span>
          <v-text-field
            v-model="article.quantity"
            style="min-width: 50px; max-width: 50px"
            class="my-0 py-0 mx-1 persistent-placeholder"
            :label="$t('messages.Qty')"
            @input="isDirty=true"
          />
        </div>

        <div class="d-flex">
          <v-text-field
            v-model="article.cutInstructions"
            style="min-width: 260px;"
            class="my-0 py-0 mx-1 persistent-placeholder"
            :label="$t('messages.Note')"
            @input="isDirty=true"
          />
        </div>
      </v-card>
      <div class="d-flex">
        <v-btn
          color="primary"
          class="btn btn-lg btn-primary white--text mb-2 ml-0 flex-grow-1"
          type="button"
          elevation="0"
          tile
          @click="selectingProduct=true"
        >
          <v-icon class="mr-1">
            mdi-plus
          </v-icon>
          {{ $t('messages.campioni') }}
        </v-btn>
        <v-btn
          color="primary"
          class="btn btn-lg btn-primary white--text mb-2 ml-2 flex-grow-1"
          type="button"
          elevation="0"
          tile
          @click="selectingCampione=true"
        >
          <v-icon class="mr-1">
            mdi-plus
          </v-icon>
          marketing
        </v-btn>
      </div>

      <v-textarea
        v-model="orderDetails.notes"
        class="persistent-placeholder mb-2"
        filled
        label="Note Extra"
        clearable
        hide-details
        @input="isDirty=true"
      />

      <v-checkbox
        v-model="orderDetails.requestAuth.doRequest"
        :label="$t('messages.Richiedi autorizzazione')"
        class="mb-2"
        hide-details
      />
      <h5
        v-if="orderDetails.requestAuth.doRequest"
        class="mb-3"
      >
        {{ $t('messages.È possibile inserire più mail separate da virgola ( , )') }}
      </h5>
      <v-text-field
        v-if="orderDetails.requestAuth.doRequest"
        ref="requestAuthMail"
        v-model="orderDetails.requestAuth.requestMail"
        style="min-width: 260px;"
        class="my-0 py-0 mx-1 persistent-placeholder"
        :rules="[rules.emailMultipla]"
        :label="$t('messages.Email')"
        hide-details
        @input="isDirty=true"
      />

      <v-btn
        color="primary"
        class="btn btn-lg btn-primary white--text mb-2 ml-0 mt-2"
        type="button"
        block
        elevation="0"
        tile
        @click="saveCart"
      >
        <v-icon class="mr-1">
          mdi-cart-check
        </v-icon>
        {{ $t('messages.Salva bozza') }}
      </v-btn>

      <v-btn
        color="success"
        class="btn btn-lg btn-primary white--text mb-2 ml-0"
        type="button"
        block
        elevation="0"
        tile
        @click="sendOrder"
      >
        <v-icon class="mr-1">
          mdi-content-save
        </v-icon>
        {{ $t('messages.Conferma ordine') }}
      </v-btn>
      <v-dialog
        v-model="selectingProduct"
        max-width="290"
      >
        <v-card>
          <v-card-text class="pt-4 justify-center">
            <product-selector
              v-model="selectedProduct"
              :products="products"
              @input="updateTypes"
            />
            <v-select
              v-model="selectedProductType"
              class=""
              filled
              :items="selectedProductTypes"
              :label="'Tipo'"
            />
            <div class="d-flex justify-space-between justify-center align-center">
              <v-btn
                color="rgba(0, 0, 0, 0.06) white--text"
                small
                tile
                elevation="0"
                :loading="isLoading"
                @click="closePresentedSelector"
              >
                {{ $t('messages.Annulla') }}
              </v-btn>
              <v-btn
                color="success"
                tile
                :disabled="!selectedProduct || !selectedProduct.selectedCommercialCodeId"
                small
                :dark="!!selectedProduct && !!selectedProduct.selectedCommercialCodeId"
                elevation="0"
                :loading="isLoading"
                @click="insertProduct()"
              >
                {{ $t('messages.Aggiungi') }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="selectingCampione"
        max-width="290"
      >
        <v-card>
          <v-card-text class="pt-4 justify-center">
            <v-autocomplete
              v-model="selectedCampione"
              :items="campioniList"
              label="Seleziona un campione"
              placeholder="Seleziona un campione"
              clearable
              item-value="codice_articolo"
              filled
              :menu-props="{
                maxHeight:200,
              }"
              :filter="customCampioneFilter"
            >
              <template slot="no-data">
                <v-list-item>
                  <v-list-item-content>
                    {{ $t('messages.Nessun campione presente') }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template
                #[`item`]="{ item }"
              >
                <div class="d-flex flex-column">
                  {{ item.codice_articolo }}
                  <v-chip
                    style="font-size: 12px"
                    class="px-1 mb-2"
                    x-small
                  >
                    {{ item.descrizione }}
                  </v-chip>
                </div>
              </template>
              <template
                #[`selection`]="{ item }"
              >
                <div>
                  {{ item.codice_articolo }}
                </div>
              </template>
            </v-autocomplete>
            <div class="d-flex justify-space-between justify-center align-center">
              <v-btn
                color="rgba(0, 0, 0, 0.06) white--text"
                small
                tile
                elevation="0"
                :loading="isLoading"
                @click="closeCampioneSelector"
              >
                {{ $t('messages.Annulla') }}
              </v-btn>
              <v-btn
                color="success"
                tile
                :disabled="!selectedCampione"
                small
                :dark="!!selectedCampione"
                elevation="0"
                :loading="isLoading"
                @click="insertCampione()"
              >
                {{ $t('messages.Aggiungi') }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <confirm-dialog
        v-model="confirmSave"
        persistent
        color="primary"
        :title="$t('messages.Salva')"
        :title-secondary="$t('messages.Non Salvare')"
        :description="$t('messages.Il carrello è stato modificato, salvarlo prima di uscire?')"
        @confirm="$event.secondary ? next() : saveAndNext()"
        @cancel="next(false); confirmSave = false"
      />
    </div>
  </v-container>
</template>

<script>
import ConfirmDialog from '../components/ConfirmDialog'
import ProductSelector from '../components/ProductSelector.vue'

export default {
  name: 'OrderCampionatura',

  components: {
    ConfirmDialog,
    ProductSelector
  },

  beforeRouteLeave (to, from, next) {
    if (!this.isDirty) {
      next()
      return
    }
    this.next = next
    this.confirmSave = true
  },

  data: () => ({
    isInitializing: 0,
    next: null,
    confirmSave: false,
    selectingProduct: false,
    selectingCampione: false,
    selectedProduct: null,
    selectedCampione: null,
    isLoading: false,
    orderDetails: {
      notes: '',
      products: [],
      requestAuth: {
        doRequest: false,
        requestMail: ''
      }
    },
    products: {},
    listcampioniprodotti: [],
    selectedShipping: {
      azienda: '',
      indirizzo: '',
      citta: '',
      cap: '',
      provincia: '',
      telefono: '',
      fax: '',
      mail: '',
      nazione: ''
    },
    nations: [],
    orderSuccess: false,
    notifyMail: '',
    isSending: false,
    selectedProductType: '',
    selectedProductTypes: [],
    initErrorMessage: '',
    isValidating: false,
    fetchedCart: [],
    isDirty: false
  }),

  computed: {
    samplesFormats () {
      return [
        { value: 'Rotolino', text: this.$t('messages.Rotolino') },
        { value: 'Roll Print (40x70)', text: this.$t('messages.Roll Print (40x70)') },
        { value: 'A4', text: this.$t('messages.A4') },
        { value: 'A5', text: this.$t('messages.A5') }
      ]
    },
    clientsListMinimal () {
      return this.$store.getters['clients/clientsListMinimal']
    },
    clientDetails () {
      return this.$store.getters['clientdetails/clientDetails']
    },
    campioniList () {
      return this.$store.getters['products/campioni']
    },
    rules () {
      return {
        required: value => !!value || this.$t('messages.Obbligatorio'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('messages.E-Mail non valida')
        },
        emailMultipla: value => {
          const pattern = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})(,\s*)?)+$/
          return pattern.test(value) || this.$t('messages.E-Mail non valida')
        },
        requiredInvoice: value => {
          return parseInt(this.client.is_projx, 10) === 1 || !!value || this.$t('messages.Obbligatorio')
        },
        requiredSdi: () => {
          return this.client.is_forfettario || !!this.client.pec || !!this.client.sdi || this.$t('messages.Obbligatorio')
        }
      }
    }
  },

  watch: {
    errorMessage (value) {
      if (value) {
        const dataMessage = { isOpen: true, message: value, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
    }
  },

  created () {
    this.isInitializing = 6
    this.$store.dispatch('products/list').then((r) => { this.isInitializing--; this.initialize() })
    this.$store.dispatch('agents/agentShippingAddress').then((r) => {
      this.isInitializing--
      if (r) {
        this.selectedShipping = r
      }
      this.initialize()
    })
    this.$store.dispatch('products/listcampioni').then(() => { this.isInitializing--; this.initialize() })
    this.$store.dispatch('products/listcampioniprodotti').then((r) => { this.isInitializing--; this.listcampioniprodotti = r; this.initialize() })
    this.$store.dispatch('nations/get').then((r) => { this.nations = r; this.isInitializing--; this.initialize() })
    this.$store.dispatch('orders/fetchCartCampionatura').then((r) => { this.fetchedCart = r; this.isInitializing--; this.initialize() })
  },

  methods: {
    initialize () {
      if (this.isInitializing === 0) {
        this.isInitializing++

        this.products = JSON.parse(JSON.stringify(this.$store.getters['products/list']))
        Object.keys(this.products.commercialcodes).forEach(commercialCode => {
          const lcp = this.listcampioniprodotti.find(lcp => this.products.commercialcodes[commercialCode].codice.toLowerCase().startsWith(lcp.codice.toLowerCase()))
          if (!lcp) {
            delete this.products.commercialcodes[commercialCode]
          } else {
            if (lcp.raggruppa) {
              if (lcp.isGrouped) {
                delete this.products.commercialcodes[commercialCode]
                return
              } else {
                lcp.isGrouped = true
                this.products.commercialcodes[commercialCode].codice = lcp.codice
                const lineaPadre = Object.keys(this.products.lines).find(l => {
                  return this.products.lines[l].commercialcodes.some(cc => cc === commercialCode)
                })
                if (lineaPadre >= 0) {
                  this.products.commercialcodes[commercialCode].cover = this.products.lines[lineaPadre].cover
                }
              }
            }
            this.products.commercialcodes[commercialCode].rotolino = lcp.rotolino
            this.products.commercialcodes[commercialCode].printRoll = lcp.printRoll
            this.products.commercialcodes[commercialCode].a4 = lcp.a4
            this.products.commercialcodes[commercialCode].a5 = lcp.a5
          }
        })

        if (this.$store.getters['products/feedbackCampioniCache'].articoli.length > 0 || this.$store.getters['products/feedbackCampioniCache'].campioni > 0) {
          this.isDirty = true
        }
        if (!this.fetchedCart.empty) {
          this.selectedShipping = this.fetchedCart.shipping
          this.orderDetails.notes = this.fetchedCart.notes
          if (this.fetchedCart.requestAuthEmail) {
            this.orderDetails.requestAuth.doRequest = true
            this.orderDetails.requestAuth.requestMail = this.fetchedCart.requestAuthEmail
          }
          this.orderDetails.notes = this.fetchedCart.notes
          this.$store.dispatch('products/updateFeedbackCampioniCache', this.fetchedCart.rows)
        }
        const feedbackCampioniCache = this.$store.getters['products/feedbackCampioniCache']
        this.initErrorMessage = ''
        feedbackCampioniCache.articoli.forEach((articolo) => {
          this.isInitializing++
          const commercialCodeId = Object.keys(this.products.commercialcodes).find(commercialCode => this.products.commercialcodes[commercialCode].codice === articolo.value)
          this.$store.dispatch('products/details', commercialCodeId).then((r) => {
            if (!r) {
              this.initErrorMessage += '\n' + articolo.value + ' ' + articolo.format
              this.isInitializing--
              return
            }
            const article = r.article
            if (this.hasCover(this.products.commercialcodes[commercialCodeId])) {
              article.cover = this.products.commercialcodes[commercialCodeId].cover
            }
            article.code = this.products.commercialcodes[commercialCodeId].codice
            article.mode = 'articolo'
            article.selectedType = articolo.format
            article.quantity = articolo.quantity
            article.cutInstructions = articolo.note
            article.selectedTypeMessage = []
            article.confirmDialogRemoveProduct = false
            article.samplesFormats = []
            if (this.products.commercialcodes[commercialCodeId].rotolino) {
              article.samplesFormats.push({ value: 'Rotolino', text: this.$t('messages.Rotolino') })
            }
            if (this.products.commercialcodes[commercialCodeId].printRoll) {
              article.samplesFormats.push({ value: 'Roll Print (40x70)', text: this.$t('messages.Roll Print (40x70)') })
            }
            if (this.products.commercialcodes[commercialCodeId].a4) {
              article.samplesFormats.push({ value: 'A4', text: this.$t('messages.A4') })
            }
            if (this.products.commercialcodes[commercialCodeId].a5) {
              article.samplesFormats.push({ value: 'A5', text: this.$t('messages.A5') })
            }

            this.orderDetails.products.push(article)
            this.isInitializing--
            this.endInitialize()
          })
        })
        feedbackCampioniCache.campioni.forEach((campione) => {
          const article = {}
          const camp = this.campioniList.find(c => c.codice_articolo === campione.value)
          if (!camp) {
            this.initErrorMessage += '\n' + campione.value
            return
          }
          article.mode = 'campione'
          article.code = campione.value
          article.description = camp.descrizione
          article.quantity = campione.quantity
          article.cutInstructions = campione.note
          article.confirmDialogRemoveProduct = false

          this.orderDetails.products.push(article)
        })
        this.$store.dispatch('products/setFeedbackCampioniCache', { articoli: [], campioni: [] })
        this.isInitializing--
        this.endInitialize()
      }
    },
    endInitialize () {
      if (this.isInitializing === 0) {
        if (this.initErrorMessage) {
          const dataMessage = { isOpen: true, message: this.$t('messages.I seguenti campioni non sono più disponibili e non sono stati aggiunti all ordine:') + this.initErrorMessage, color: 'error' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          this.initErrorMessage = ''
        }
      }
    },
    async closePresentedSelector () {
      this.selectingProduct = false
    },

    closeCampioneSelector () {
      this.selectingCampione = false
    },

    customCampioneFilter (item, queryText) {
      return (item.descrizione.toLowerCase().includes(queryText.toLowerCase()) ||
              item.codice_articolo.toLowerCase().includes(queryText.toLowerCase()))
    },

    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    },
    editQuantity (article, amount) {
      article.quantity += amount
      if (article.quantity < 1) article.quantity = 1
    },
    remove (article) {
      this.isDirty = true
      const index = this.orderDetails.products.findIndex(a => a === article)
      this.orderDetails.products.splice(index, 1)
    },

    async insertProduct () {
      if (!this.selectedProduct || !this.selectedProduct.selectedCommercialCodeId) {
        const message = this.$t('messages.Seleziona un Prodotto')
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        return
      }
      this.isDirty = true
      this.isLoading = true
      const r = await this.$store.dispatch('products/details', this.selectedProduct.selectedCommercialCodeId)
      if (r) {
        const article = r.article
        if (this.hasCover(this.products.commercialcodes[this.selectedProduct.selectedCommercialCodeId])) {
          article.cover = this.products.commercialcodes[this.selectedProduct.selectedCommercialCodeId].cover
        }
        article.code = this.products.commercialcodes[this.selectedProduct.selectedCommercialCodeId].codice
        article.mode = 'articolo'
        article.selectedType = this.selectedProductType
        article.quantity = 1
        article.cutInstructions = ''
        article.selectedTypeMessage = []
        article.confirmDialogRemoveProduct = false
        article.samplesFormats = []

        if (this.products.commercialcodes[this.selectedProduct.selectedCommercialCodeId].rotolino) {
          article.samplesFormats.push({ value: 'Rotolino', text: this.$t('messages.Rotolino') })
        }
        if (this.products.commercialcodes[this.selectedProduct.selectedCommercialCodeId].printRoll) {
          article.samplesFormats.push({ value: 'Roll Print (40x70)', text: this.$t('messages.Roll Print (40x70)') })
        }
        if (this.products.commercialcodes[this.selectedProduct.selectedCommercialCodeId].a4) {
          article.samplesFormats.push({ value: 'A4', text: this.$t('messages.A4') })
        }
        if (this.products.commercialcodes[this.selectedProduct.selectedCommercialCodeId].a5) {
          article.samplesFormats.push({ value: 'A5', text: this.$t('messages.A5') })
        }
        if (article.samplesFormats.length === 1) {
          article.selectedType = article.samplesFormats[0].value
        }

        this.orderDetails.products.push(article)
        this.closePresentedSelector()
      } else {
        const dataMessage = { isOpen: true, message: this.$store.getters['products/errorMessage'], color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
      this.isLoading = false
    },

    async insertCampione () {
      if (!this.selectedCampione) {
        const message = this.$t('messages.Seleziona un Campione')
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        return
      }
      this.isDirty = true
      const article = {}
      article.mode = 'campione'
      article.code = this.selectedCampione
      article.description = this.campioniList.find(c => c.codice_articolo === this.selectedCampione).descrizione
      article.quantity = 1
      article.cutInstructions = ''
      article.confirmDialogRemoveProduct = false

      this.orderDetails.products.push(article)
      this.closeCampioneSelector()
    },

    validateCart (needProducts = true) {
      let errorMessages = ''
      if (!this.$refs.shippingdata.validate()) {
        errorMessages += this.$t('messages.Compilare i dati di spedizione') + '\n'
      }
      if (needProducts && !this.orderDetails.products.length) {
        errorMessages += this.$t('messages.Selezionare almeno un prodotto') + '\n'
      }

      if (this.orderDetails.requestAuth.doRequest && !this.$refs.requestAuthMail.validate()) {
        errorMessages += this.$t('messages.Compilare la mail di autorizzazione con una mail valida') + '\n'
      }

      if (errorMessages !== '') {
        const dataMessage = { isOpen: true, message: errorMessages, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        this.isLoading = false
        return false
      }

      const data = {}
      data.selectedShipping = this.selectedShipping
      data.notes = this.orderDetails.notes
      data.requestAuth = this.orderDetails.requestAuth
      data.articles = []
      for (let index = 0; index < this.orderDetails.products.length; index++) {
        const product = this.orderDetails.products[index]
        let foundArticle = null
        if (product.mode === 'articolo') {
          if (product.selectedType === '') {
            product.selectedTypeMessage = this.$t('messages.Selezionare il tipo di articolo')
          }

          if (product.selectedType === '') {
            const messageData = { isOpen: true, color: 'error', message: this.$t('messages.Compilare i campi dell articolo') }
            this.$store.dispatch('appState/setMessageSnackbar', messageData)
            return false
          }
          foundArticle = product
        } else if (product.mode === 'campione') {
          foundArticle = product
        }
        if (foundArticle) {
          data.articles.push(foundArticle)
        } else {
          const messageData = { isOpen: true, color: 'error', message: this.$t('messages.Si è verificato un errore') }
          this.$store.dispatch('appState/setMessageSnackbar', messageData)
          return false
        }
      }
      return data
    },

    async sendOrder () {
      if (this.isValidating || this.isSending) {
        return
      }
      this.isValidating = true
      const data = this.validateCart()
      if (!data) {
        this.isValidating = false
        return
      }
      this.isValidating = false
      this.isSending = true
      const r = await this.$store.dispatch('orders/sendcampionatura', data)
      if (r) {
        this.orderSuccess = true
        this.isDirty = false
        this.notifyMail = r.notifyMail
      } else {
        const messageData = { isOpen: true, color: 'error', message: this.$store.getters['orders/errorMessage'] }
        this.$store.dispatch('appState/setMessageSnackbar', messageData)
      }
      this.isSending = false
    },

    updateTypes () {
      this.selectedProductTypes = []
      if (this.selectedProduct && this.selectedProduct.selectedCommercialCodeId) {
        if (this.products.commercialcodes[this.selectedProduct.selectedCommercialCodeId].rotolino) {
          this.selectedProductTypes.push({ value: 'Rotolino', text: this.$t('messages.Rotolino') })
        }
        if (this.products.commercialcodes[this.selectedProduct.selectedCommercialCodeId].printRoll) {
          this.selectedProductTypes.push({ value: 'Roll Print (40x70)', text: this.$t('messages.Roll Print (40x70)') })
        }
        if (this.products.commercialcodes[this.selectedProduct.selectedCommercialCodeId].a4) {
          this.selectedProductTypes.push({ value: 'A4', text: this.$t('messages.A4') })
        }
        if (this.products.commercialcodes[this.selectedProduct.selectedCommercialCodeId].a5) {
          this.selectedProductTypes.push({ value: 'A5', text: this.$t('messages.A5') })
        }
        if (this.selectedProductTypes.length === 1) {
          this.selectedProductType = this.selectedProductTypes[0]
        }
      } else {
        this.selectedProductType = ''
      }
    },

    async saveCart () {
      if (this.isValidating || this.isSending) {
        return false
      }
      const data = this.validateCart(false)
      if (!data) {
        this.isValidating = false
        return false
      }

      this.isValidating = false
      this.isSending = true
      const r = await this.$store.dispatch('orders/saveCartCampionatura', data)
      if (r) {
        const messageData = { isOpen: true, color: 'success', message: this.$t('messages.Bozza salvata') }
        this.$store.dispatch('appState/setMessageSnackbar', messageData)
        this.isDirty = false
      } else {
        const messageData = { isOpen: true, color: 'error', message: this.$store.getters['orders/errorMessage'] }
        this.$store.dispatch('appState/setMessageSnackbar', messageData)
      }
      this.isSending = false
      return !!r
    },
    async saveAndNext () {
      this.next(await this.saveCart())
      this.confirmSave = false
    },

    hasCover (article) {
      if (article.cover && article.cover !== '') {
        return true
      }
      return false
    },
    getCover (article) {
      if (article.cover !== '') {
        const coverName = article.cover // .codice_commerciale.replace('/', '').toLowerCase() + '.jpg'
        const coverPathName = 'https://shop.apaspa.com/apa/images/lines/' + coverName
        return coverPathName
      }
      return ''
    },
    hasImage (article) {
      if (article.immagine && article.immagine !== '') {
        return true
      }
      return false
    },
    getImage (article) {
      if (article.immagine !== '') {
        const imageName = article.immagine // .codice_commerciale.replace('/', '').toLowerCase() + '.jpg'
        const imagePathName = 'https://shop.apaspa.com/apa/images/commercial_codes/' + imageName
        return imagePathName
      }
      return ''
    },
    hasColor (article) {
      if (article.colore && article.colore !== '') {
        return true
      }
      return false
    },
    getColor (article) {
      if (article.colore !== '') {
        return article.colore
      } else {
        return '#ffffff'
      }
    }

  }
}
</script>

<style scoped>
.article-image {
  min-width:51px;
  min-height:40px;
  max-width:51px;
  max-height:40px;
}
</style>
