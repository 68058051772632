<template>
  <v-combobox
    v-model="selectedMicroArea"
    item-text="name"
    item-id="name"
    :items="microareas"
    :label="label"
    :placeholder="$t('messages.Seleziona una Microarea')"
    clearable
    :filled="filled"
    :attach="attach?'#attachMicroAreaSelector':false"
    :filter="filterMicroAreas"
    :disabled="disabled"
    hide-details
    :menu-props="{
      maxHeight:200,
    }"
    @keydown.enter="confirmEvent"
  >
    <template
      v-if="attach"
      #append-outer
    >
      <div id="attachMicroAreaSelector" />
    </template>
    <template slot="no-data">
      <v-list-item>
        <v-list-item-content>
          {{ $t('messages.Nessuna microarea presente') }}
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
</template>
<script>
export default {
  name: 'MicroAreaSelector',

  props: {
    value: {
      required: false,
      default: () => '',
      validator: () => true
    },
    label: {
      type: String,
      required: false,
      default: function () {
        return this.$t('messages.Seleziona una Microarea')
      }
    },
    filled: {
      type: Boolean,
      required: false,
      default: () => true
    },
    attach: {
      required: false,
      default: false,
      validator: () => {
        return true
      }
    },
    disabled: {
      required: false,
      default: false,
      validator: () => {
        return true
      }
    },
    microareas: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    selectedMicroArea: ''
  }),

  /* computed: {
    microAreasVisibleToAgent () {
      return this.$store.getters['agents/microAreasVisibleToAgent']
    }
  }, */

  watch: {
    value (newVal) {
      this.selectedMicroArea = newVal
    },
    selectedMicroArea (newVal) {
      if (this.selectedMicroArea !== this.value) { this.$emit('input', (typeof newVal === 'string' || newVal instanceof String) ? newVal : newVal?.name ?? '') }
    }
  },

  created () {
    this.selectedMicroArea = this.value
  },

  methods: {
    filterMicroAreas (microArea, queryText, itemText) {
      // console.log(area.azienda)
      // if (area.azienda === 'Yosh') { console.log(area.azienda) }
      // if (this.areas[this.areas.length - 1] === area) { console.log(area.azienda) }
      // console.log(this.areas[this.areas.length - 1])
      return ((microArea.name ?? '').toLowerCase().includes(queryText.toLowerCase()))
    },
    confirmEvent () {
      if (this.selectedMicroArea) {
        this.$emit('confirm', (typeof this.selectedMicroArea === 'string' || this.selectedMicroArea instanceof String) ? this.selectedMicroArea : this.selectedMicroArea?.name ?? '')
      }
    }
  }
}
</script>
