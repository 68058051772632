<template>
  <div class="container">
    <v-progress-circular
      v-if="isInitializing != 0"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <div
        v-if="!searchMenu"
        class="d-flex flex-row-reverse"
      >
        <v-btn
          icon
          color="grey"
          @click="openSearchMenu"
        >
          <v-icon>
            mdi-tune
          </v-icon>
        </v-btn>
      </div>

      <div
        v-if="searchMenu"
        class="mb-2"
      >
        <v-row>
          <v-col>
            <div>
              <h1>{{ $t('messages.Lista Clienti') }}</h1>
            </div>
          </v-col>
          <v-col
            cols="2"
          >
            <div
              class="d-flex flex-row-reverse justify-start"
            >
              <v-btn
                icon
                color="grey"
                @click="closeSearchMenu"
              >
                <v-icon>
                  mdi-eye-off-outline
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <div
          class="d-flex mb-8"
          :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''"
        >
          <v-btn
            color="primary"
            dark
            class="btn btn-lg"
            type="button"
            elevation="0"
            tile
            @click="createClient()"
          >
            {{ $t('messages.Nuovo Cliente') }}
          </v-btn>
          <v-btn
            v-if="isAdmin"
            :color="showDeleted ? 'success' : 'error'"
            dark
            class="btn btn-lg"
            :class="$vuetify.breakpoint.smAndDown ? 'mt-1' : 'ml-2'"
            type="button"
            elevation="0"
            tile
            @click="toggleDeleted"
          >
            {{ showDeleted ? $t('messages.Mostra attivi'):$t('messages.Mostra eliminati') }}
          </v-btn>
          <v-btn
            v-if="isSupervisor"
            color="#217346"
            dark
            class="btn btn-lg"
            :class="$vuetify.breakpoint.smAndDown ? 'mt-1' : 'ml-2'"
            type="button"
            elevation="0"
            tile
            :loading="exportingToExcel"
            @click="exportToExcel"
          >
            {{ $t('messages.Esporta in Excel') }}
          </v-btn>
        </div>
        <area-selector
          v-model="searchFilter.area"
          :areas="areasVisibleToAgent"
          @input="debounceSearch"
        />
        <micro-area-selector
          v-model="searchFilter.microArea"
          :microareas="microAreas"
          class="mb-2"
          @input="debounceSearch"
        />

        <h4>{{ $t('messages.Data Ultimo Ordine') }}:</h4>
        <div
          class="d-flex justify-space-between mb-2"
        >
          <v-menu
            v-model="invoiceDateFromMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(searchFilter.invoiceDateFrom)"
                :label="$t('messages.Da')"
                :placeholder="$t('messages.Seleziona una data')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                style="font-size: 0.665rem; width:40px;"
                class="mr-1"
                hide-details
                clearable
                @click:clear="searchFilter.invoiceDateFrom = null"
                v-on="on"
                @input="debounceSearch"
              />
            </template>
            <v-date-picker
              v-model="searchFilter.invoiceDateFrom"
              :locale="$t('messages.locale')"
              :first-day-of-week="1"
              @input="invoiceDateFromMenu = false"
              @change="debounceSearch"
            />
          </v-menu>

          <v-menu
            v-model="invoiceDateToMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(searchFilter.invoiceDateTo)"
                :label="$t('messages.A')"
                :placeholder="$t('messages.Seleziona una data')"
                prepend-icon="mdi-calendar"
                readonly
                hide-details
                v-bind="attrs"
                style="font-size: 0.665rem; width:40px;"
                clearable
                @click:clear="searchFilter.invoiceDateTo = null"
                v-on="on"
                @input="debounceSearch"
              />
            </template>
            <v-date-picker
              v-model="searchFilter.invoiceDateTo"
              :locale="$t('messages.locale')"
              :first-day-of-week="1"
              @input="invoiceDateToMenu = false"
              @change="debounceSearch"
            />
          </v-menu>
        </div>

        <h4>{{ $t('messages.Data Ultima Visita') }}:</h4>
        <div
          class="d-flex justify-space-between mb-2"
        >
          <v-menu
            v-model="visitDateFromMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(searchFilter.visitDateFrom)"
                :label="$t('messages.Da')"
                :placeholder="$t('messages.Seleziona una data')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                style="font-size: 0.665rem; width:40px;"
                class="mr-1"
                hide-details
                clearable
                @click:clear="searchFilter.visitDateFrom = null"
                v-on="on"
                @input="debounceSearch"
              />
            </template>
            <v-date-picker
              v-model="searchFilter.visitDateFrom"
              :locale="$t('messages.locale')"
              :first-day-of-week="1"
              @input="visitDateFromMenu = false"
              @change="debounceSearch"
            />
          </v-menu>

          <v-menu
            v-model="visitDateToMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(searchFilter.visitDateTo)"
                :label="$t('messages.A')"
                :placeholder="$t('messages.Seleziona una data')"
                prepend-icon="mdi-calendar"
                readonly
                hide-details
                v-bind="attrs"
                style="font-size: 0.665rem; width:40px;"
                clearable
                @click:clear="searchFilter.visitDateTo = null"
                v-on="on"
                @input="debounceSearch"
              />
            </template>
            <v-date-picker
              v-model="searchFilter.visitDateTo"
              :locale="$t('messages.locale')"
              :first-day-of-week="1"
              @input="visitDateToMenu = false"
              @change="debounceSearch"
            />
          </v-menu>
        </div>

        <h4>{{ $t('messages.Data Ultima Telefonata') }}:</h4>
        <div
          class="d-flex justify-space-between mb-2"
        >
          <v-menu
            v-model="phoneDateFromMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(searchFilter.phoneDateFrom)"
                :label="$t('messages.Da')"
                :placeholder="$t('messages.Seleziona una data')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                style="font-size: 0.665rem; width:40px;"
                class="mr-1"
                hide-details
                clearable
                @click:clear="searchFilter.phoneDateFrom = null"
                v-on="on"
                @input="debounceSearch"
              />
            </template>
            <v-date-picker
              v-model="searchFilter.phoneDateFrom"
              :locale="$t('messages.locale')"
              :first-day-of-week="1"
              @input="phoneDateFromMenu = false"
              @change="debounceSearch"
            />
          </v-menu>

          <v-menu
            v-model="phoneDateToMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(searchFilter.phoneDateTo)"
                :label="$t('messages.A')"
                :placeholder="$t('messages.Seleziona una data')"
                prepend-icon="mdi-calendar"
                readonly
                hide-details
                v-bind="attrs"
                style="font-size: 0.665rem; width:40px;"
                clearable
                @click:clear="searchFilter.phoneDateTo = null"
                v-on="on"
                @input="debounceSearch"
              />
            </template>
            <v-date-picker
              v-model="searchFilter.phoneDateTo"
              :locale="$t('messages.locale')"
              :first-day-of-week="1"
              @input="phoneDateToMenu = false"
              @change="debounceSearch"
            />
          </v-menu>
        </div>
        <h4>{{ $t('messages.Visualizza') }}:</h4>
        <div class="d-flex flex-row mb-2">
          <v-radio-group
            v-model="searchFilter.clientsOrProspectors"
            class="mt-0"
            hide-details
            row
            @change="debounceSearch"
          >
            <v-radio
              :label="$t('messages.Tutti')"
              value="all"
            />
            <v-radio
              :label="$t('messages.Projx')"
              value="projx"
            />
            <v-radio
              :label="$t('messages.Prospects')"
              value="prospectors"
            />
          </v-radio-group>
        </div>
        <div class="d-flex flex-row mb-2">
          <v-radio-group
            v-model="searchFilter.view"
            class="mt-0"
            hide-details
            row
            @change="debounceSearch"
          >
            <v-radio
              :label="$t('messages.Tutti')"
              value="all"
            />
            <v-radio
              :label="$t('messages.Non presenti in mappa')"
              value="notOnMap"
            />
            <v-radio
              :label="$t('messages.Presenti in mappa')"
              value="onMap"
            />
          </v-radio-group>
        </div>

        <h4>{{ $t('messages.Numero Stelle') }}:</h4>
        <div class="d-flex flex-row mb-1">
          <v-checkbox
            v-model="searchFilter.starszero"
            hide-details
            class="mx-2 my-1"
            dense
            label="0*"
            @change="debounceSearch"
          />
          <v-checkbox
            v-model="searchFilter.starsone"
            hide-details
            class="mx-2 my-1"
            dense
            label="1*"
            @change="debounceSearch"
          />
          <v-checkbox
            v-model="searchFilter.starstwo"
            hide-details
            class="mx-2 my-1"
            dense
            label="2*"
            @change="debounceSearch"
          />
          <v-checkbox
            v-model="searchFilter.starsthree"
            hide-details
            class="mx-2 my-1"
            dense
            label="3*"
            @change="debounceSearch"
          />
        </div>

        <h4>{{ $t('messages.Cerca in') }}:</h4>
        <div class="d-flex flex-wrap">
          <v-checkbox
            v-model="searchFilter.cdClien"
            hide-details
            class="mx-2 my-1"
            dense
            :label="$t('messages.CodpCliente')"
            @change="debounceSearch"
          />
          <v-checkbox
            v-model="searchFilter.azienda"
            hide-details
            class="mx-2 my-1"
            dense
            :label="$t('messages.Ragione Sociale')"
            @change="debounceSearch"
          />
          <v-checkbox
            v-model="searchFilter.piva"
            hide-details
            class="mx-2 my-1"
            dense
            :label="$t('messages.PpIVA')"
            @change="debounceSearch"
          />
          <v-checkbox
            v-model="searchFilter.citta"
            hide-details
            class="mx-2 my-1"
            dense
            :label="$t('messages.Città')"
            @change="debounceSearch"
          />
          <v-checkbox
            v-model="searchFilter.cap"
            hide-details
            class="mx-2 my-1"
            dense
            :label="$t('messages.CAP')"
            @change="debounceSearch"
          />
        </div>
        <v-text-field
          v-model="searchFilter.search"
          filled
          dense
          hide-details
          :loading="isLoadingFilter"
          :placeholder="$t('messages.Cerca ppp')"
          class=""
          @input="debounceSearch"
        />
      </div>

      <v-data-table
        :headers="headers"
        :items="filteredClientList"
        :loading="isLoading"
        :locale="$t('messages.locale')"
        elevation="0"
        :header-props="{ sortByText: $t('messages.Ordina per')}"
        :footer-props="{
          itemsPerPageAllText: $t('messages.Tutte'),
          itemsPerPageText: $t('messages.Per pagina')
        }"
        :page="searchFilter.page"
        :items-per-page="searchFilter.itemsPerPage"
        :sort-by.sync="searchFilter.sortBy"
        :sort-desc.sync="searchFilter.sortDesc"
        @pagination="updatePagination"
        @update:sort-by="updateFilterQuery"
        @update:sort-desc="updateFilterQuery"
      >
        <template #[`item.cd_clien`]="{ item }">
          <div class="d-flex align-center">
            <img
              v-if="item.is_projx === '1'"
              src="/logo.svg"
              width="24"
              class="mr-2"
            >
            <span>{{ item.cd_clien }}</span>
          </div>
        </template>

        <template #[`item.azienda`]="{ item }">
          <div class="d-flex align-center">
            <router-link
              :to="'/client/details/' + item.id"
            >
              <span>{{ item.azienda }}</span>
            </router-link>
          </div>
        </template>

        <template #[`footer.page-text`]="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} {{ $t('messages.di') }} {{ itemsLength }}
        </template>
        <template #[`item.last_invoice_date`]="{ item }">
          {{ formatDate(item.last_invoice_date) }}
        </template>
        <template #[`item.last_visit_date`]="{ item }">
          {{ formatDate(item.last_visit_date) }}
        </template>
        <template #[`item.last_phone_date`]="{ item }">
          {{ formatDate(item.last_phone_date) }}
        </template>

        <template #[`item.actions`]="{ item }">
          <v-icon
            v-if="!showDeleted"
            medium
            color="primary"
            @click="writeFeedbackOnClient(item)"
          >
            mdi-pencil-plus-outline
          </v-icon>
          <v-icon
            v-if="showDeleted"
            medium
            color="primary"
            @click="openRestoreClientDialog(item)"
          >
            mdi-account-reactivate
          </v-icon>
          <v-icon
            v-if="item.is_projx != 1"
            medium
            color="error"
            @click="openDeleteClientDialog(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>

      <confirm-dialog
        v-model="deleteClientDialog"
        max-width="290"
        color="error"
        :title="showDeleted ? $t('messages.Elimina').toUpperCase() : $t('messages.Elimina')"
        :description="(showDeleted ? $t('messages.ATTENZIONE - Eliminare definitivamente lanagrafica #cliente#?', [clientToDelete.azienda]) : $t('messages.Eliminare l anagrafica #cliente#?', [clientToDelete.azienda]))"
        @confirm="deleteClient(clientToDelete); deleteClientDialog=false"
        @cancel="deleteClientDialog=false"
      />

      <confirm-dialog
        v-model="restoreClientDialog"
        max-width="290"
        color="success"
        :title="$t('messages.Ripristina')"
        :description="$t('messages.Ripristinare l anagrafica #cliente#?', [clientToDelete.azienda])"
        @confirm="restoreClient(clientToRestore); restoreClientDialog=false"
        @cancel="restoreClientDialog=false"
      />
    </div>
  </div>
</template>

<script>
import AreaSelector from '../components/AreaSelector.vue'
import MicroAreaSelector from '../components/MicroAreaSelector.vue'
import ConfirmDialog from '../components/ConfirmDialog'

export default {
  name: 'ListaClienti',

  components: {
    AreaSelector,
    MicroAreaSelector,
    ConfirmDialog
  },
  data: () => ({
    searchFilter: {
      search: '',
      cdClien: true,
      azienda: true,
      piva: true,
      citta: true,
      cap: true,
      starszero: true,
      starsone: true,
      starstwo: true,
      starsthree: true,
      microArea: '',
      area: '',
      clientsOrProspectors: 'all',
      view: 'all',
      invoiceDateFrom: null,
      invoiceDateTo: null,
      visitDateFrom: null,
      visitDateTo: null,
      phoneDateFrom: null,
      phoneDateTo: null,
      page: 1,
      itemsPerPage: 10,
      sortBy: '',
      sortDesc: false
    },
    searchMenu: true,
    deleteClientDialog: false,
    restoreClientDialog: false,
    clientToDelete: {},
    clientToRestore: {},
    microAreas: [],
    showDeleted: false,
    isInitializing: 1,
    isLoading: false,
    filteredClientList: [],
    filterDebounce: null,
    isLoadingFilter: false,
    exportingToExcel: false,
    invoiceDateFromMenu: false,
    invoiceDateToMenu: false,
    visitDateFromMenu: false,
    visitDateToMenu: false,
    phoneDateFromMenu: false,
    phoneDateToMenu: false,
    clientStars: ['0', '1', '2', '3'],
    initializeFilter: false
  }
  ),

  computed: {
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    hasError () {
      return this.$store.getters['clients/hasError']
    },
    areasVisibleToAgent () {
      return this.$store.getters['agents/areasVisibleToAgent']
    },
    isAdmin () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN')
    },
    isSupervisor () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN') || this.$store.getters['security/hasRole']('ROLE_AGENT_ADMIN')
    },
    headers () {
      return [
        { text: this.$t('messages.CodpCliente'), value: 'cd_clien', filterable: true, width: '140px' },
        { text: this.$t('messages.Ragione Sociale'), value: 'azienda', filterable: false },
        { text: this.$t('messages.Città'), value: 'citta', filterable: false, width: '250px' },
        { text: this.$t('messages.CAP'), value: 'cap', filterable: false, width: '140px' },
        { text: this.$t('messages.Microarea'), value: 'microarea', filterable: false, width: '150px' },
        { text: this.$t('messages.Ultimo Ordine'), value: 'last_invoice_date', filterable: false, width: '140px' },
        { text: this.$t('messages.Ultima Visita'), value: 'last_visit_date', filterable: false, width: '140px' },
        { text: this.$t('messages.Ultima Telefonata'), value: 'last_phone_date', filterable: false, width: '140px' },
        { text: this.$t('messages.Azioni'), value: 'actions', filterable: false, sortable: false, width: '80px' }
      ]
    }
  },

  created () {
    this.isInitializing = 3

    this.initializeFilter = false
    Object.keys(this.searchFilter).forEach(key => {
      if (this.$route.query[key] !== undefined) {
        this.initializeFilter = true
        if (key === 'page' || key === 'itemsPerPage') {
          this.searchFilter[key] = parseInt(this.$route.query[key])
        } else {
          // parsa le stringhe 'true' o 'false' come boolean, altrimenti parsa come stringa
          this.searchFilter[key] = (this.$route.query[key] === 'true' || this.$route.query[key] === 'false') ? this.$route.query[key] === 'true' : this.$route.query[key]
        }
      }
    })

    this.$store.dispatch('agents/areasVisibleToAgent').then(() => { this.isInitializing--; this.initialize() })
    this.$store.dispatch('agents/microAreasVisibleToAgent').then((r) => { this.isInitializing--; this.microAreas = r; this.initialize() })
    this.$store.dispatch('clients/clientsList').then((cl) => {
      if (cl !== null) {
        this.filteredClientList = cl
        this.isInitializing--
        this.initialize()
      }
    })
  },

  methods: {
    initialize () {
      if (this.isInitializing === 0) {
        if (this.initializeFilter) {
          this.doFilter()
        }
      }
    },

    openSearchMenu () {
      if (!(this.searchMenu === true)) {
        this.searchMenu = true
      } else this.searchMenu = false
    },
    closeSearchMenu () {
      this.searchMenu = false
    },

    debounceSearch () {
      clearTimeout(this.filterDebounce)
      this.isLoadingFilter = true
      this.filterDebounce = setTimeout(() => {
        // do filter
        this.doFilter()
        this.isLoadingFilter = false
      }, 1000)
    },

    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    },

    doFilter () {
      const clientList = this.$store.getters['clients/clientsList']
      this.updateFilterQuery()
      this.filteredClientList = clientList.filter((c) => {
        const isFound = (this.searchFilter.search === '' || (
          (this.searchFilter.azienda && (c.azienda ?? '').toLowerCase().includes(this.searchFilter.search.toLowerCase())) ||
          (this.searchFilter.cdClien && (c.cd_clien ?? '').toLowerCase().includes(this.searchFilter.search.toLowerCase())) ||
          (this.searchFilter.piva && (c.piva ?? '').toLowerCase().includes(this.searchFilter.search.toLowerCase())) ||
          (this.searchFilter.citta && (c.citta ?? '').toLowerCase().includes(this.searchFilter.search.toLowerCase())) ||
          (this.searchFilter.cap && (c.cap ?? '').toLowerCase().includes(this.searchFilter.search.toLowerCase()))
        )
        ) &&
        ((this.searchFilter.microArea ?? '') === '' || ((c.microarea ?? '') === this.searchFilter.microArea)) &&
        ((this.searchFilter.area ?? '') === '' || ((c.cd_agent ?? '') === this.searchFilter.area)) &&
        ((this.searchFilter.clientsOrProspectors === 'all') || (
          ((this.searchFilter.clientsOrProspectors === 'projx') && ((c.is_projx ?? '') === '1')) ||
          ((this.searchFilter.clientsOrProspectors === 'prospectors') && ((c.is_projx ?? '') !== '1'))
        )
        ) &&
        ((this.searchFilter.view === 'all') || (
          ((this.searchFilter.view === 'notOnMap') && (((c.lat ?? '') === '') && ((c.lat_auto ?? '') === ''))) ||
          ((this.searchFilter.view === 'onMap') && (((c.lat ?? '') !== '') || ((c.lat_auto ?? '') !== '')))
        )
        ) &&
        (
          (!this.searchFilter.invoiceDateFrom && !this.searchFilter.invoiceDateTo) ||
          (
            ((this.searchFilter.invoiceDateFrom && this.searchFilter.invoiceDateTo) && // Caso valorizzato entrambi
            ((c.last_invoice_date ?? '') >= this.searchFilter.invoiceDateFrom) &&
            ((c.last_invoice_date ?? '') <= this.searchFilter.invoiceDateTo)) ||
            ((this.searchFilter.invoiceDateFrom && !this.searchFilter.invoiceDateTo) && // Caso valorizzato da
            ((c.last_invoice_date ?? '') >= this.searchFilter.invoiceDateFrom)) ||
            ((!this.searchFilter.invoiceDateFrom && this.searchFilter.invoiceDateTo) && // Caso valorizzato a
            ((c.last_invoice_date ?? '') <= this.searchFilter.invoiceDateTo) && ((c.last_invoice_date ?? '') !== ''))
          )
        ) &&
        (
          (!this.searchFilter.visitDateFrom && !this.searchFilter.visitDateTo) ||
          (
            ((this.searchFilter.visitDateFrom && this.searchFilter.visitDateTo) && // Caso valorizzato entrambi
            ((c.last_visit_date ?? '') >= this.searchFilter.visitDateFrom) &&
            ((c.last_visit_date ?? '') <= this.searchFilter.visitDateTo)) ||
            ((this.searchFilter.visitDateFrom && !this.searchFilter.visitDateTo) && // Caso valorizzato da
            ((c.last_visit_date ?? '') >= this.searchFilter.visitDateFrom)) ||
            ((!this.searchFilter.visitDateFrom && this.searchFilter.visitDateTo) && // Caso valorizzato a
            ((c.last_visit_date ?? '') <= this.searchFilter.visitDateTo) && ((c.last_visit_date ?? '') !== ''))
          )
        ) &&
        (
          (!this.searchFilter.phoneDateFrom && !this.searchFilter.phoneDateTo) ||
          (
            ((this.searchFilter.phoneDateFrom && this.searchFilter.phoneDateTo) && // Caso valorizzato entrambi
            ((c.last_phone_date ?? '') >= this.searchFilter.phoneDateFrom) &&
            ((c.last_phone_date ?? '') <= this.searchFilter.phoneDateTo)) ||
            ((this.searchFilter.phoneDateFrom && !this.searchFilter.phoneDateTo) && // Caso valorizzato da
            ((c.last_phone_date ?? '') >= this.searchFilter.phoneDateFrom)) ||
            ((!this.searchFilter.phoneDateFrom && this.searchFilter.phoneDateTo) && // Caso valorizzato a
            ((c.last_phone_date ?? '') <= this.searchFilter.phoneDateTo) && ((c.last_phone_date ?? '') !== ''))
          )
        ) &&
        (
          (!this.searchFilter.starszero && !this.searchFilter.starsone && !this.searchFilter.starstwo && !this.searchFilter.starsthree) ||
          (this.searchFilter.starszero && ((c.stars ?? '') === '0')) ||
          (this.searchFilter.starsone && ((c.stars ?? '') === '1')) ||
          (this.searchFilter.starstwo && ((c.stars ?? '') === '2')) ||
          (this.searchFilter.starsthree && ((c.stars ?? '') === '3'))
        )

        // filtro visualizza microaree dei clienti selezionati
        // if (isFound && c.microarea != null && this.microAreas.findIndex(ma => ma.name === c.microarea) < 0) {
        //   this.microAreas.push({ name: c.microarea })
        // }
        return isFound
      })
    },

    createClient () {
      this.$router.push({ path: '/client/details/' })
    },

    writeFeedbackOnClient (client) {
      this.$router.push({ path: '/feedbackinsert/client/' + client.id })
    },

    async deleteClient () {
      if (!this.clientToDelete.is_projx) {
        const data = { id: this.clientToDelete.id, deletePerma: this.showDeleted }
        const r = await this.$store.dispatch('clients/delete', data)
        if (!r) {
          const message = this.$store.getters['clients/errorMessage']
          const dataMessage = { isOpen: true, message: message, color: 'error' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          this.deleteClientDialog = false
          this.clientToDelete = {}
          return
        }
        const message = this.$t('messages.Cliente Eliminato')
        const dataMessage = { isOpen: true, message: message, color: 'success' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      } else {
        const message = this.$t('messages.Il Cliente deriva da Projx: impossibile eliminare il Cliente')
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
    },
    openDeleteClientDialog (client) {
      this.deleteClientDialog = true
      this.clientToDelete = client
    },

    async restoreClient () {
      const data = { id: this.clientToRestore.id }
      const r = await this.$store.dispatch('clients/restore', data)
      if (!r) {
        const message = this.$store.getters['clients/errorMessage']
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        this.restoreClientDialog = false
        this.clientToRestore = {}
        return
      }
      const message = this.$t('messages.Cliente Ripristinato')
      const dataMessage = { isOpen: true, message: message, color: 'success' }
      this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
    },

    openRestoreClientDialog (client) {
      this.restoreClientDialog = true
      this.clientToRestore = client
    },

    async toggleDeleted () {
      this.isLoading = true
      this.showDeleted = !this.showDeleted
      await this.$store.dispatch('clients/clientsList', { listDeleted: this.showDeleted })
      this.doFilter()
      this.isLoading = false
    },
    updatePagination (event) {
      this.searchFilter.page = event.page
      this.searchFilter.itemsPerPage = event.itemsPerPage
      this.updateFilterQuery()
    },
    updateFilterQuery () {
      this.$router.replace({ query: this.searchFilter }).catch(() => {})
    },

    formatCurrency (number) {
      if (number === undefined) {
        number = 0
      }
      return (Number(number)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },

    async exportToExcel () {
      this.exportingToExcel = true
      const tHeader = [this.$t('messages.Projx'),
        this.$t('messages.Codp Cliente'),
        this.$t('messages.Ragione Sociale'),
        this.$t('messages.Città'),
        this.$t('messages.CAP'),
        this.$t('messages.Nazione'),
        this.$t('messages.Microarea'),
        this.$t('messages.Cd_Agent'),
        this.$t('messages.Area'),
        this.$t('messages.Ultimo Ordine'),
        this.$t('messages.Ultima Visita'),
        this.$t('messages.Ultima Telefonata'),
        this.$t('messages.PIVA'),
        this.$t('messages.Stelle'),
        this.$t('messages.Anno Mobile 0/12'),
        this.$t('messages.Anno Mobile 12/24')]
      const exceldata = []

      const clientList = this.filteredClientList.filter(client => { return client.is_projx === '1' }).map((client) => {
        return client.cd_clien
      })

      let fatturatoClientiMobile = await this.$store.dispatch('reports/getReportFatturatoClientiMobile', { clientList: clientList })
      if (!fatturatoClientiMobile) {
        fatturatoClientiMobile = {}
      }

      this.filteredClientList.forEach((client) => {
        let first12 = 0
        let second12 = 0
        if (fatturatoClientiMobile[client.cd_clien]) {
          first12 = fatturatoClientiMobile[client.cd_clien].first12
          second12 = fatturatoClientiMobile[client.cd_clien].second12
        }
        const rowData = [
          client.is_projx ?? '',
          client.cd_clien ?? '',
          client.azienda ?? '',
          client.citta ?? '',
          client.cap ?? '',
          client.nazione ?? '',
          client.microarea ?? '',
          client.cd_agent ?? '',
          client.area ?? '',
          client.last_invoice_date ?? '',
          client.last_visit_date ?? '',
          client.last_phone_date ?? '',
          client.piva ?? '',
          client.stars ?? '',
          this.formatCurrency(first12),
          this.formatCurrency(second12)
        ]
        exceldata.push(rowData)
      })
      setTimeout(() => {
        import('../vendor/Export2Excel').then(excel => {
          excel.export_json_to_excel({
            header: tHeader, // Header Required
            data: exceldata, // Specific data Required
            filename: this.$t('messages.Lista Clienti'), // Optional
            autoWidth: true, // Optional
            bookType: 'xlsx' // Optional
          })
          this.exportingToExcel = false
        })
      }, 500)
    }
  }
}
</script>
