import Vue from 'vue'
import Vuex from 'vuex'
import ClassesModule from './classes'
import ClientsModule from './clients'
import ProductsModule from './products'
import GalleryModule from './gallery'
import SecurityModule from './security'
import AppState from './app-state'
import Listini from './listini'
import ClientDetails from './clientdetails'
import FeedbackModule from './feedback'
import Map from './map'
import GiroModule from './giro'
import Agents from './agents'
import Reports from './reports'
import Orders from './orders'
import Nations from './nations'
import Importer from './importer'
import Language from './language'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    classes: ClassesModule,
    clients: ClientsModule,
    products: ProductsModule,
    gallery: GalleryModule,
    security: SecurityModule,
    appState: AppState,
    listini: Listini,
    clientdetails: ClientDetails,
    feedback: FeedbackModule,
    map: Map,
    giro: GiroModule,
    agents: Agents,
    reports: Reports,
    orders: Orders,
    nations: Nations,
    importer: Importer,
    language: Language
  }
})
