import axios from 'axios'

export default {
  getReportFatturatoCliente (year) {
    return axios.get('/api/reports/get-current-agent-stats' + (year ? '?year=' + year : ''))
  },
  getReportFatturatoColore (year) {
    return axios.get('/api/reports/get-current-agent-product' + (year ? '?year=' + year : ''))
  },
  getReportClientVisits () {
    return axios.get('/api/reports/get-current-agent-client-visits')
  },
  getReportClientCompact (area, year) {
    return axios.get('/api/reports/get-scheda-clienti-compatta/' + area + (year ? '?year=' + year : ''))
  },
  getReportClientCompactColor (area) {
    return axios.get('/api/reports/get-scheda-clienti-compatta-color/' + area)
  },
  getReportVisitRatings (year) {
    return axios.get('/api/reports/get-current-agent-visit-ratings/' + year)
  },
  getHomePageReport (filter) {
    return axios.post('/api/reports/homepage', filter)
  },
  getReportFatturatoClientiMobile (clientList) {
    return axios.post('/api/reports/get-client-mobile-sales', clientList)
  },
  getReportAgenteEsteso (data) {
    return axios.post('/api/reports/get-current-agent-broad', data)
  }
}
