<template>
  <div
    class="container px-0 px-sm-3"
    :style="$vuetify.breakpoint.lgOnly ? 'max-width: 1250px;' : ''"
  >
    <div class="d-flex align-center justify-space-around ml-1">
      <h1>{{ $t('messages.Fatturato Colore') }}</h1>
    </div>
    <v-progress-circular
      v-if="isLoading"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <div
        v-if="reportFatturatoColore.length > 0"
      >
        <v-btn
          v-if="isSupervisor"
          color="#217346"
          dark
          class="btn btn-lg mb-2 ml-1"
          type="button"
          elevation="0"
          tile
          :loading="exportingToExcel"
          @click="exportToExcel"
        >
          <v-icon
            class="mr-1"
          >
            mdi-microsoft-excel
          </v-icon>
          {{ $t('messages.Esporta in Excel') }}
        </v-btn>
        <v-text-field
          v-model="search"
          class="mx-1"
          append-icon="mdi-magnify"
          :label="$t('messages.Ricercappp')"
          dense
          :loading="isLoadingFilter"
          single-line
          hide-details
          @input="filterSearch"
        />

        <v-select
          v-model="selectedArea"
          :items="reportFatturatoColore"
          item-value="name"
          item-text="area"
          hide-details
          :label="$t('messages.Area')"
        />

        <v-autocomplete
          v-model="selectedProducts"
          :items="products"
          multiple
          :label="$t('messages.Seleziona un codice commerciale')"
          :placeholder="$t('messages.Seleziona un codice commerciale')"
          clearable
          hide-details
          :menu-props="{
            maxHeight:200,
          }"
          @input="doFilter"
        >
          <template slot="no-data">
            <v-list-item>
              <v-list-item-content>
                {{ $t('messages.Nessun codice commerciale presente') }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>

        <v-select
          v-model="selectedYear"
          :items="selectableYears"
          :label="$t('messages.Anno')"
          @input="getReportFatturatoColore"
        />

        <v-btn
          :color="(sortOrder === 'faAsc' || sortOrder === 'faDesc') ? 'primary' : '#C4C2BB'"
          class="btn btn-lg mb-4 ml-6"
          type="button"
          elevation="0"
          tile
          @click="doSortBy('fa')"
        >
          {{ $t('messages.ORDINA PER FATTURATO') }}
          <v-icon
            v-if="(sortOrder === 'faAsc' || sortOrder === 'faDesc')"
            class="mr-1"
            small
          >
            {{ sortOrder === 'faAsc' ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
          </v-icon>
        </v-btn>

        <v-btn
          :color="(sortOrder === 'mqAsc' || sortOrder === 'mqDesc') ? 'primary' : '#C4C2BB'"
          class="btn btn-lg mb-4 ml-6"
          type="button"
          elevation="0"
          tile
          @click="doSortBy('mq')"
        >
          {{ $t('messages.ORDINA PER METRI QUADRI') }}
          <v-icon
            v-if="(sortOrder === 'mqAsc' || sortOrder === 'mqDesc')"
            class="mr-1"
            small
          >
            {{ sortOrder === 'mqAsc' ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
          </v-icon>
        </v-btn>
        <div
          v-for="(agent, j) in filteredReport"
          :key="j"
        >
          <div
            v-if="selectedArea == agent.name"
          >
            <div
              v-if="!isFiltered"
              style="overflow-x: auto; overflow-y: hidden;"
              class="mx-1"
            >
              <table class="my-2">
                <thead>
                  <tr>
                    <th
                      v-for="(hr, l) in headers"
                      :key="l"
                      :class="hr.class"
                      :style="hr.width?('min-width: ' + hr.width):''"
                    >
                      {{ hr.text == $t('messages.Colore') ? '' : hr.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span class="report-colore">
                        {{ $t('messages.Totale #Area#', [agent.name]) }}
                      </span>
                    </td>
                    <td
                      class="text-right"
                      :style="'min-width: 83px'"
                    >
                      <div class="d-flex flex-column">
                        <span
                          class="reportval"
                          :class="highlight=='imp' ? 'highlight':''"
                        >
                          {{
                            agent.tot1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                          }}
                        </span>
                        <span
                          class="reportval"
                          :class="highlight=='qta' ? 'highlight':''"
                        >
                          {{
                            agent.mqtot1 == 0 ?
                              (agent.qtatot1 == 0 ? '0' : (agent.qtatot1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                              agent.mqtot1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                          }}
                        </span>
                      </div>
                    </td>
                    <td
                      class="text-right"
                      :style="'min-width: 83px'"
                    >
                      <div class="d-flex flex-column">
                        <span
                          class="reportval"
                          :class="highlight=='imp' ? 'highlight':''"
                        >
                          {{
                            agent.gen1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                          }}
                        </span>
                        <span
                          class="reportval"
                          :class="highlight=='qta' ? 'highlight':''"
                        >
                          {{
                            agent.mqgen1 == 0 ?
                              (agent.qtagen1 == 0 ? '0' : (agent.qtagen1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                              agent.mqgen1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                          }}
                        </span>
                      </div>
                    </td>
                    <td
                      class="text-right"
                      :style="'min-width: 83px'"
                    >
                      <div class="d-flex flex-column">
                        <span
                          class="reportval"
                          :class="highlight=='imp' ? 'highlight':''"
                        >
                          {{
                            agent.feb1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                          }}
                        </span>
                        <span
                          class="reportval"
                          :class="highlight=='qta' ? 'highlight':''"
                        >
                          {{
                            agent.mqfeb1 == 0 ?
                              (agent.qtafeb1 == 0 ? '0' : (agent.qtafeb1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                              agent.mqfeb1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                          }}
                        </span>
                      </div>
                    </td>
                    <td
                      class="text-right"
                      :style="'min-width: 83px'"
                    >
                      <div class="d-flex flex-column">
                        <span
                          class="reportval"
                          :class="highlight=='imp' ? 'highlight':''"
                        >
                          {{
                            agent.mar1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                          }}
                        </span>
                        <span
                          class="reportval"
                          :class="highlight=='qta' ? 'highlight':''"
                        >
                          {{
                            agent.mqmar1 == 0 ?
                              (agent.qtamar1 == 0 ? '0' : (agent.qtamar1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                              agent.mqmar1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                          }}
                        </span>
                      </div>
                    </td>
                    <td
                      class="text-right"
                      :style="'min-width: 83px'"
                    >
                      <div class="d-flex flex-column">
                        <span
                          class="reportval"
                          :class="highlight=='imp' ? 'highlight':''"
                        >
                          {{
                            agent.apr1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                          }}
                        </span>
                        <span
                          class="reportval"
                          :class="highlight=='qta' ? 'highlight':''"
                        >
                          {{
                            agent.mqapr1 == 0 ?
                              (agent.qtaapr1 == 0 ? '0' : (agent.qtaapr1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                              agent.mqapr1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                          }}
                        </span>
                      </div>
                    </td>
                    <td
                      class="text-right"
                      :style="'min-width: 83px'"
                    >
                      <div class="d-flex flex-column">
                        <span
                          class="reportval"
                          :class="highlight=='imp' ? 'highlight':''"
                        >
                          {{
                            agent.mag1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                          }}
                        </span>
                        <span
                          class="reportval"
                          :class="highlight=='qta' ? 'highlight':''"
                        >
                          {{
                            agent.mqmag1 == 0 ?
                              (agent.qtamag1 == 0 ? '0' : (agent.qtamag1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                              agent.mqmag1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                          }}
                        </span>
                      </div>
                    </td>
                    <td
                      class="text-right"
                      :style="'min-width: 83px'"
                    >
                      <div class="d-flex flex-column">
                        <span
                          class="reportval"
                          :class="highlight=='imp' ? 'highlight':''"
                        >
                          {{
                            agent.giu1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                          }}
                        </span>
                        <span
                          class="reportval"
                          :class="highlight=='qta' ? 'highlight':''"
                        >
                          {{
                            agent.mqgiu1 == 0 ?
                              (agent.qtagiu1 == 0 ? '0' : (agent.qtagiu1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                              agent.mqgiu1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                          }}
                        </span>
                      </div>
                    </td>
                    <td
                      class="text-right"
                      :style="'min-width: 83px'"
                    >
                      <div class="d-flex flex-column">
                        <span
                          class="reportval"
                          :class="highlight=='imp' ? 'highlight':''"
                        >
                          {{
                            agent.lug1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                          }}
                        </span>
                        <span
                          class="reportval"
                          :class="highlight=='qta' ? 'highlight':''"
                        >
                          {{
                            agent.mqlug1 == 0 ?
                              (agent.qtalug1 == 0 ? '0' : (agent.qtalug1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                              agent.mqlug1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                          }}
                        </span>
                      </div>
                    </td>
                    <td
                      class="text-right"
                      :style="'min-width: 83px'"
                    >
                      <div class="d-flex flex-column">
                        <span
                          class="reportval"
                          :class="highlight=='imp' ? 'highlight':''"
                        >
                          {{
                            agent.ago1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                          }}
                        </span>
                        <span
                          class="reportval"
                          :class="highlight=='qta' ? 'highlight':''"
                        >
                          {{
                            agent.mqago1 == 0 ?
                              (agent.qtaago1 == 0 ? '0' : (agent.qtaago1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                              agent.mqago1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                          }}
                        </span>
                      </div>
                    </td>
                    <td
                      class="text-right"
                      :style="'min-width: 83px'"
                    >
                      <div class="d-flex flex-column">
                        <span
                          class="reportval"
                          :class="highlight=='imp' ? 'highlight':''"
                        >
                          {{
                            agent.set1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                          }}
                        </span>
                        <span
                          class="reportval"
                          :class="highlight=='qta' ? 'highlight':''"
                        >
                          {{
                            agent.mqset1 == 0 ?
                              (agent.qtaset1 == 0 ? '0' : (agent.qtaset1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                              agent.mqset1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                          }}
                        </span>
                      </div>
                    </td>
                    <td
                      class="text-right"
                      :style="'min-width: 83px'"
                    >
                      <div class="d-flex flex-column">
                        <span
                          class="reportval"
                          :class="highlight=='imp' ? 'highlight':''"
                        >
                          {{
                            agent.ott1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                          }}
                        </span>
                        <span
                          class="reportval"
                          :class="highlight=='qta' ? 'highlight':''"
                        >
                          {{
                            agent.mqott1 == 0 ?
                              (agent.qtaott1 == 0 ? '0' : (agent.qtaott1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                              agent.mqott1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                          }}
                        </span>
                      </div>
                    </td>
                    <td
                      class="text-right"
                      :style="'min-width: 83px'"
                    >
                      <div class="d-flex flex-column">
                        <span
                          class="reportval"
                          :class="highlight=='imp' ? 'highlight':''"
                        >
                          {{
                            agent.nov1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                          }}
                        </span>
                        <span
                          class="reportval"
                          :class="highlight=='qta' ? 'highlight':''"
                        >
                          {{
                            agent.mqnov1 == 0 ?
                              (agent.qtanov1 == 0 ? '0' : (agent.qtanov1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                              agent.mqnov1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                          }}
                        </span>
                      </div>
                    </td>
                    <td
                      class="text-right"
                      :style="'min-width: 83px'"
                    >
                      <div class="d-flex flex-column">
                        <span
                          class="reportval"
                          :class="highlight=='imp' ? 'highlight':''"
                        >
                          {{
                            agent.dic1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                          }}
                        </span>
                        <span
                          class="reportval"
                          :class="highlight=='qta' ? 'highlight':''"
                        >
                          {{
                            agent.mqdic1 == 0 ?
                              (agent.qtadic1 == 0 ? '0' : (agent.qtadic1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                              agent.mqdic1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                          }}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <v-expansion-panels
              v-model="openAccordions"
              tile
              multiple
              flat
              accordion
              class="pl-lg-4 pb-16"
            >
              <v-expansion-panel
                v-for="(superClass, i) in agent.superclass"
                :key="i"
                class="pb-2"
              >
                <v-expansion-panel-header
                  color="#2559b9"
                  class="accordion-title py-1"
                  style="color:#fff"
                  :style="'z-index: 4;position: sticky;top: 48px;'"
                >
                  <v-icon
                    slot="actions"
                    style="color:#fff"
                  >
                    $expand
                  </v-icon>
                  <h3 class="ma-0 pa-0">
                    {{ superClass.name }}
                  </h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  eager
                >
                  <div>
                    <div
                      class="agent-header ml-1 px-1 d-flex"
                      :style="'z-index: 3;position: sticky;top: 88px;'"
                    >
                      <h4>
                        {{ agent.name }} - {{ agent.area }}
                      </h4>
                    </div>

                    <div
                      v-if="!isFiltered"
                      style="overflow-x: auto; overflow-y: hidden;"
                      class="mx-1"
                    >
                      <table class="my-2">
                        <thead>
                          <tr>
                            <th
                              v-for="(hr, l) in headers"
                              :key="l"
                              :class="hr.class"
                              :style="hr.width?('min-width: ' + hr.width):''"
                            >
                              {{ hr.text == 'Colore' ? '' : hr.text }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span class="report-colore">
                                Totale {{ superClass.name }}
                              </span>
                            </td>
                            <td
                              class="text-right"
                              :style="'min-width: 83px'"
                            >
                              <div class="d-flex flex-column">
                                <span
                                  class="reportval"
                                  :class="highlight=='imp' ? 'highlight':''"
                                >
                                  {{
                                    superClass.tot1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                  }}
                                </span>
                                <span
                                  class="reportval"
                                  :class="highlight=='qta' ? 'highlight':''"
                                >
                                  {{
                                    superClass.mqtot1 == 0 ?
                                      (superClass.qtatot1 == 0 ? '0' : (superClass.qtatot1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                      superClass.mqtot1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                  }}
                                </span>
                              </div>
                            </td>
                            <td
                              class="text-right"
                              :style="'min-width: 83px'"
                            >
                              <div class="d-flex flex-column">
                                <span
                                  class="reportval"
                                  :class="highlight=='imp' ? 'highlight':''"
                                >
                                  {{
                                    superClass.gen1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                  }}
                                </span>
                                <span
                                  class="reportval"
                                  :class="highlight=='qta' ? 'highlight':''"
                                >
                                  {{
                                    superClass.mqgen1 == 0 ?
                                      (superClass.qtagen1 == 0 ? '0' : (superClass.qtagen1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                      superClass.mqgen1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                  }}
                                </span>
                              </div>
                            </td>
                            <td
                              class="text-right"
                              :style="'min-width: 83px'"
                            >
                              <div class="d-flex flex-column">
                                <span
                                  class="reportval"
                                  :class="highlight=='imp' ? 'highlight':''"
                                >
                                  {{
                                    superClass.feb1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                  }}
                                </span>
                                <span
                                  class="reportval"
                                  :class="highlight=='qta' ? 'highlight':''"
                                >
                                  {{
                                    superClass.mqfeb1 == 0 ?
                                      (superClass.qtafeb1 == 0 ? '0' : (superClass.qtafeb1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                      superClass.mqfeb1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                  }}
                                </span>
                              </div>
                            </td>
                            <td
                              class="text-right"
                              :style="'min-width: 83px'"
                            >
                              <div class="d-flex flex-column">
                                <span
                                  class="reportval"
                                  :class="highlight=='imp' ? 'highlight':''"
                                >
                                  {{
                                    superClass.mar1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                  }}
                                </span>
                                <span
                                  class="reportval"
                                  :class="highlight=='qta' ? 'highlight':''"
                                >
                                  {{
                                    superClass.mqmar1 == 0 ?
                                      (superClass.qtamar1 == 0 ? '0' : (superClass.qtamar1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                      superClass.mqmar1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                  }}
                                </span>
                              </div>
                            </td>
                            <td
                              class="text-right"
                              :style="'min-width: 83px'"
                            >
                              <div class="d-flex flex-column">
                                <span
                                  class="reportval"
                                  :class="highlight=='imp' ? 'highlight':''"
                                >
                                  {{
                                    superClass.apr1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                  }}
                                </span>
                                <span
                                  class="reportval"
                                  :class="highlight=='qta' ? 'highlight':''"
                                >
                                  {{
                                    superClass.mqapr1 == 0 ?
                                      (superClass.qtaapr1 == 0 ? '0' : (superClass.qtaapr1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                      superClass.mqapr1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                  }}
                                </span>
                              </div>
                            </td>
                            <td
                              class="text-right"
                              :style="'min-width: 83px'"
                            >
                              <div class="d-flex flex-column">
                                <span
                                  class="reportval"
                                  :class="highlight=='imp' ? 'highlight':''"
                                >
                                  {{
                                    superClass.mag1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                  }}
                                </span>
                                <span
                                  class="reportval"
                                  :class="highlight=='qta' ? 'highlight':''"
                                >
                                  {{
                                    superClass.mqmag1 == 0 ?
                                      (superClass.qtamag1 == 0 ? '0' : (superClass.qtamag1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                      superClass.mqmag1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                  }}
                                </span>
                              </div>
                            </td>
                            <td
                              class="text-right"
                              :style="'min-width: 83px'"
                            >
                              <div class="d-flex flex-column">
                                <span
                                  class="reportval"
                                  :class="highlight=='imp' ? 'highlight':''"
                                >
                                  {{
                                    superClass.giu1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                  }}
                                </span>
                                <span
                                  class="reportval"
                                  :class="highlight=='qta' ? 'highlight':''"
                                >
                                  {{
                                    superClass.mqgiu1 == 0 ?
                                      (superClass.qtagiu1 == 0 ? '0' : (superClass.qtagiu1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                      superClass.mqgiu1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                  }}
                                </span>
                              </div>
                            </td>
                            <td
                              class="text-right"
                              :style="'min-width: 83px'"
                            >
                              <div class="d-flex flex-column">
                                <span
                                  class="reportval"
                                  :class="highlight=='imp' ? 'highlight':''"
                                >
                                  {{
                                    superClass.lug1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                  }}
                                </span>
                                <span
                                  class="reportval"
                                  :class="highlight=='qta' ? 'highlight':''"
                                >
                                  {{
                                    superClass.mqlug1 == 0 ?
                                      (superClass.qtalug1 == 0 ? '0' : (superClass.qtalug1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                      superClass.mqlug1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                  }}
                                </span>
                              </div>
                            </td>
                            <td
                              class="text-right"
                              :style="'min-width: 83px'"
                            >
                              <div class="d-flex flex-column">
                                <span
                                  class="reportval"
                                  :class="highlight=='imp' ? 'highlight':''"
                                >
                                  {{
                                    superClass.ago1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                  }}
                                </span>
                                <span
                                  class="reportval"
                                  :class="highlight=='qta' ? 'highlight':''"
                                >
                                  {{
                                    superClass.mqago1 == 0 ?
                                      (superClass.qtaago1 == 0 ? '0' : (superClass.qtaago1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                      superClass.mqago1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                  }}
                                </span>
                              </div>
                            </td>
                            <td
                              class="text-right"
                              :style="'min-width: 83px'"
                            >
                              <div class="d-flex flex-column">
                                <span
                                  class="reportval"
                                  :class="highlight=='imp' ? 'highlight':''"
                                >
                                  {{
                                    superClass.set1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                  }}
                                </span>
                                <span
                                  class="reportval"
                                  :class="highlight=='qta' ? 'highlight':''"
                                >
                                  {{
                                    superClass.mqset1 == 0 ?
                                      (superClass.qtaset1 == 0 ? '0' : (superClass.qtaset1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                      superClass.mqset1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                  }}
                                </span>
                              </div>
                            </td>
                            <td
                              class="text-right"
                              :style="'min-width: 83px'"
                            >
                              <div class="d-flex flex-column">
                                <span
                                  class="reportval"
                                  :class="highlight=='imp' ? 'highlight':''"
                                >
                                  {{
                                    superClass.ott1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                  }}
                                </span>
                                <span
                                  class="reportval"
                                  :class="highlight=='qta' ? 'highlight':''"
                                >
                                  {{
                                    superClass.mqott1 == 0 ?
                                      (superClass.qtaott1 == 0 ? '0' : (superClass.qtaott1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                      superClass.mqott1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                  }}
                                </span>
                              </div>
                            </td>
                            <td
                              class="text-right"
                              :style="'min-width: 83px'"
                            >
                              <div class="d-flex flex-column">
                                <span
                                  class="reportval"
                                  :class="highlight=='imp' ? 'highlight':''"
                                >
                                  {{
                                    superClass.nov1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                  }}
                                </span>
                                <span
                                  class="reportval"
                                  :class="highlight=='qta' ? 'highlight':''"
                                >
                                  {{
                                    superClass.mqnov1 == 0 ?
                                      (superClass.qtanov1 == 0 ? '0' : (superClass.qtanov1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                      superClass.mqnov1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                  }}
                                </span>
                              </div>
                            </td>
                            <td
                              class="text-right"
                              :style="'min-width: 83px'"
                            >
                              <div class="d-flex flex-column">
                                <span
                                  class="reportval"
                                  :class="highlight=='imp' ? 'highlight':''"
                                >
                                  {{
                                    superClass.dic1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                  }}
                                </span>
                                <span
                                  class="reportval"
                                  :class="highlight=='qta' ? 'highlight':''"
                                >
                                  {{
                                    superClass.mqdic1 == 0 ?
                                      (superClass.qtadic1 == 0 ? '0' : (superClass.qtadic1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                      superClass.mqdic1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                  }}
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      v-for="(serie, k) in superClass.series"
                      :key="k"
                    >
                      <v-avatar
                        color="primary"
                        class="ml-2 mt-1"
                        size="28"
                        :style="'z-index: 2;position: sticky;top: 113px;'"
                      >
                        <span
                          class="white--text"
                          style="font-size: 0.875rem; font-weight: bold;"
                        >{{ serie.name }}</span>
                      </v-avatar>

                      <div
                        style="overflow-x: auto; overflow-y: hidden;"
                        class="mx-1"
                      >
                        <table class="mt-1">
                          <tbody>
                            <tr>
                              <td>
                                <span class="report-colore">
                                  {{ $t('messages.Totale Serie #Nome#', [serie.name]) }}
                                </span>
                              </td>
                              <td
                                class="text-right"
                                :style="'min-width: 83px'"
                              >
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      serie.tot1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      serie.mqtot1 == 0 ?
                                        (serie.qtatot1 == 0 ? '0' : (serie.qtatot1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        serie.mqtot1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td
                                class="text-right"
                                :style="'min-width: 83px'"
                              >
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      serie.gen1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      serie.mqgen1 == 0 ?
                                        (serie.qtagen1 == 0 ? '0' : (serie.qtagen1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        serie.mqgen1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td
                                class="text-right"
                                :style="'min-width: 83px'"
                              >
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      serie.feb1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      serie.mqfeb1 == 0 ?
                                        (serie.qtafeb1 == 0 ? '0' : (serie.qtafeb1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        serie.mqfeb1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td
                                class="text-right"
                                :style="'min-width: 83px'"
                              >
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      serie.mar1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      serie.mqmar1 == 0 ?
                                        (serie.qtamar1 == 0 ? '0' : (serie.qtamar1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        serie.mqmar1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td
                                class="text-right"
                                :style="'min-width: 83px'"
                              >
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      serie.apr1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      serie.mqapr1 == 0 ?
                                        (serie.qtaapr1 == 0 ? '0' : (serie.qtaapr1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        serie.mqapr1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td
                                class="text-right"
                                :style="'min-width: 83px'"
                              >
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      serie.mag1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      serie.mqmag1 == 0 ?
                                        (serie.qtamag1 == 0 ? '0' : (serie.qtamag1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        serie.mqmag1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td
                                class="text-right"
                                :style="'min-width: 83px'"
                              >
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      serie.giu1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      serie.mqgiu1 == 0 ?
                                        (serie.qtagiu1 == 0 ? '0' : (serie.qtagiu1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        serie.mqgiu1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td
                                class="text-right"
                                :style="'min-width: 83px'"
                              >
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      serie.lug1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      serie.mqlug1 == 0 ?
                                        (serie.qtalug1 == 0 ? '0' : (serie.qtalug1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        serie.mqlug1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td
                                class="text-right"
                                :style="'min-width: 83px'"
                              >
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      serie.ago1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      serie.mqago1 == 0 ?
                                        (serie.qtaago1 == 0 ? '0' : (serie.qtaago1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        serie.mqago1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td
                                class="text-right"
                                :style="'min-width: 83px'"
                              >
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      serie.set1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      serie.mqset1 == 0 ?
                                        (serie.qtaset1 == 0 ? '0' : (serie.qtaset1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        serie.mqset1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td
                                class="text-right"
                                :style="'min-width: 83px'"
                              >
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      serie.ott1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      serie.mqott1 == 0 ?
                                        (serie.qtaott1 == 0 ? '0' : (serie.qtaott1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        serie.mqott1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td
                                class="text-right"
                                :style="'min-width: 83px'"
                              >
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      serie.nov1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      serie.mqnov1 == 0 ?
                                        (serie.qtanov1 == 0 ? '0' : (serie.qtanov1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        serie.mqnov1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td
                                class="text-right"
                                :style="'min-width: 83px'"
                              >
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      serie.dic1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      serie.mqdic1 == 0 ?
                                        (serie.qtadic1 == 0 ? '0' : (serie.qtadic1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        serie.mqdic1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table class="mt-1">
                          <thead>
                            <tr>
                              <th
                                v-for="(hr, l) in headers"
                                :key="l"
                                :class="hr.class"
                                :style="hr.width?('min-width: ' + hr.width):''"
                              >
                                {{ hr.text }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, m) in serie.colors"
                              :key="m"
                            >
                              <td>
                                <span class="report-colore">
                                  {{ item.colore }}
                                </span>
                              </td>
                              <td class="text-right">
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      item.tot1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      item.mqtot1 == 0 ?
                                        (item.qtatot1 == 0 ? '0' : (item.qtatot1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        item.mqtot1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td class="text-right">
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      item.gen1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      item.mqgen1 == 0 ?
                                        (item.qtagen1 == 0 ? '0' : (item.qtagen1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        item.mqgen1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td class="text-right">
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      item.feb1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      item.mqfeb1 == 0 ?
                                        (item.qtafeb1 == 0 ? '0' : (item.qtafeb1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        item.mqfeb1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td class="text-right">
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      item.mar1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      item.mqmar1 == 0 ?
                                        (item.qtamar1 == 0 ? '0' : (item.qtamar1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        item.mqmar1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td class="text-right">
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      item.apr1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      item.mqapr1 == 0 ?
                                        (item.qtaapr1 == 0 ? '0' : (item.qtaapr1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        item.mqapr1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td class="text-right">
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      item.mag1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      item.mqmag1 == 0 ?
                                        (item.qtamag1 == 0 ? '0' : (item.qtamag1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        item.mqmag1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td class="text-right">
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      item.giu1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      item.mqgiu1 == 0 ?
                                        (item.qtagiu1 == 0 ? '0' : (item.qtagiu1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        item.mqgiu1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td class="text-right">
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      item.lug1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      item.mqlug1 == 0 ?
                                        (item.qtalug1 == 0 ? '0' : (item.qtalug1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        item.mqlug1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td class="text-right">
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      item.ago1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      item.mqago1 == 0 ?
                                        (item.qtaago1 == 0 ? '0' : (item.qtaago1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        item.mqago1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td class="text-right">
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      item.set1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      item.mqset1 == 0 ?
                                        (item.qtaset1 == 0 ? '0' : (item.qtaset1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        item.mqset1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td class="text-right">
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      item.ott1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      item.mqott1 == 0 ?
                                        (item.qtaott1 == 0 ? '0' : (item.qtaott1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        item.mqott1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td class="text-right">
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      item.nov1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      item.mqnov1 == 0 ?
                                        (item.qtanov1 == 0 ? '0' : (item.qtanov1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        item.mqnov1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td class="text-right">
                                <div class="d-flex flex-column">
                                  <span
                                    class="reportval"
                                    :class="highlight=='imp' ? 'highlight':''"
                                  >
                                    {{
                                      item.dic1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
                                    }}
                                  </span>
                                  <span
                                    class="reportval"
                                    :class="highlight=='qta' ? 'highlight':''"
                                  >
                                    {{
                                      item.mqdic1 == 0 ?
                                        (item.qtadic1 == 0 ? '0' : (item.qtadic1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + ' u') :
                                        item.mqdic1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' mq'
                                    }}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReportColorSales',
  data: () => ({
    reportFatturatoColore: [],
    openAccordions: [],
    selectedArea: 'TTTT',
    filterDebounce: null,
    isFiltered: false,
    search: '',
    isLoading: false,
    highlight: 'imp',
    isLoadingFilter: false,
    filteredReport: [],
    areaDefinition: [],
    sortOrder: '',
    selectedYear: '',
    products: [],
    selectedProducts: [],
    exportingToExcel: false
  }),

  computed: {
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    headers () {
      return [
        { text: this.$t('messages.Colore'), value: 'colore' },
        { text: this.$t('messages.Tot'), value: 'tot1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Gen'), value: 'gen1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Feb'), value: 'feb1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Mar'), value: 'mar1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Apr'), value: 'apr1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Mag'), value: 'mag1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Giu'), value: 'giu1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Lug'), value: 'lug1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Ago'), value: 'ago1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Set'), value: 'set1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Ott'), value: 'ott1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Nov'), value: 'nov1', align: 'right', width: '83px', filterable: false },
        { text: this.$t('messages.Dic'), value: 'dic1', align: 'right', width: '83px', filterable: false }
      ]
    },
    selectableYears () {
      const currentYear = new Date().getFullYear()
      return [
        (currentYear - 2) + '',
        (currentYear - 1) + '',
        currentYear + ''
      ]
    },
    isSupervisor () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN') || this.$store.getters['security/hasRole']('ROLE_AGENT_ADMIN')
    }
  },
  async created () {
    const r = await this.$store.dispatch('agents/listAreaDefinition')
    r.forEach(a => {
      if (a.cd_agent) { this.areaDefinition[a.cd_agent] = a }
    })
    this.selectedYear = this.selectableYears[2]
    this.getReportFatturatoColore()
  },
  methods: {
    doSortBy (order) {
      if (order === 'mq') {
        if (this.sortOrder === 'mqDesc') {
          this.sortOrder = 'mqAsc'
        } else {
          this.sortOrder = 'mqDesc' // default value
        }
      } else if (order === 'fa') {
        if (this.sortOrder === 'faDesc') {
          this.sortOrder = 'faAsc'
        } else {
          this.sortOrder = 'faDesc' // default value
        }
      }
      this.filteredReport.forEach(r => {
        r.superclass.forEach(sc => {
          // sort series tot1 e mqtot1
          // sc.series
          sc.series.sort((x, y) => {
            switch (this.sortOrder) {
            case 'faAsc':
              return x.tot1 - y.tot1
            case 'faDesc':
              return y.tot1 - x.tot1
            case 'mqAsc':
              return x.mqtot1 - y.mqtot1
            case 'mqDesc':
              return y.mqtot1 - x.mqtot1
            default:
              return 0
            }
          })

          sc.series.forEach(s => {
            s.colors.sort((x, y) => {
              switch (this.sortOrder) {
              case 'faAsc':
                return x.tot1 - y.tot1
              case 'faDesc':
                return y.tot1 - x.tot1
              case 'mqAsc':
                return x.mqtot1 - y.mqtot1
              case 'mqDesc':
                return y.mqtot1 - x.mqtot1
              default:
                return 0
              }
            })
          })
        })
      })
    },
    async getReportFatturatoColore () {
      this.isLoading = true
      // this.selectedArea = 'TTTT'
      // this.search = ''
      this.reportFatturatoColore = await this.$store.dispatch('reports/getReportFatturatoColore', this.selectedYear)
      this.reportFatturatoColore.forEach((a, i) => {
        // this.openAccordions.push(i)
        a.area = this.getArea(a.name)
      })
      this.filteredReport = this.reportFatturatoColore

      this.reportFatturatoColore.forEach(agent => {
        agent.superclass.forEach(superclass => {
          superclass.series.forEach(s => {
            s.colors.forEach((color) => {
              if (this.products.findIndex((prod) => prod === color.colore) < 0) {
                this.products.push(color.colore)
              }
            })
          })
        })
      })
      await this.doFilter()
      this.isLoading = false
    },
    getArea (cdAgent) {
      if (cdAgent === 'TTTT') return this.$t('messages.TUTTI')
      return this.areaDefinition[cdAgent]?.nome
    },
    filterSearch () {
      clearTimeout(this.filterDebounce)
      this.isLoadingFilter = true
      this.filterDebounce = setTimeout(() => {
        // do filter
        this.doFilter()
        this.isLoadingFilter = false
      }, 1000)
    },
    doFilter () {
      this.isFiltered = false
      if (!this.search && this.selectedProducts.length === 0) {
        this.filteredReport = this.reportFatturatoColore
        return
      }
      this.isFiltered = true
      this.filteredReport = []
      this.reportFatturatoColore.forEach((agent, i) => {
        this.filteredReport[i] = {}
        this.filteredReport[i].name = agent.name
        this.filteredReport[i].area = agent.area
        this.filteredReport[i].superclass = []
        agent.superclass.forEach(superclass => {
          let currentSuperclass = this.filteredReport[i].superclass.find((sc) => { return sc.name === superclass.name })
          if (!currentSuperclass) {
            currentSuperclass = {}
            Object.assign(currentSuperclass, superclass)
            this.filteredReport[i].superclass.push(currentSuperclass)
            currentSuperclass.series = []
          }
          const currentSeries = currentSuperclass.series

          superclass.series.forEach(s => {
            let currentSerie = currentSeries.find((sf) => { return sf.name === s.name })
            if (!currentSerie) {
              currentSerie = {}
              Object.assign(currentSerie, s)
              currentSeries.push(currentSerie)
              currentSerie.colors = []
              currentSerie.tot1 = 0
              currentSerie.gen1 = 0
              currentSerie.feb1 = 0
              currentSerie.mar1 = 0
              currentSerie.apr1 = 0
              currentSerie.mag1 = 0
              currentSerie.giu1 = 0
              currentSerie.lug1 = 0
              currentSerie.ago1 = 0
              currentSerie.set1 = 0
              currentSerie.ott1 = 0
              currentSerie.nov1 = 0
              currentSerie.dic1 = 0
              currentSerie.mqtot1 = 0
              currentSerie.mqgen1 = 0
              currentSerie.mqfeb1 = 0
              currentSerie.mqmar1 = 0
              currentSerie.mqapr1 = 0
              currentSerie.mqmag1 = 0
              currentSerie.mqgiu1 = 0
              currentSerie.mqlug1 = 0
              currentSerie.mqago1 = 0
              currentSerie.mqset1 = 0
              currentSerie.mqott1 = 0
              currentSerie.mqnov1 = 0
              currentSerie.mqdic1 = 0
              currentSerie.qtatot1 = 0
              currentSerie.qtagen1 = 0
              currentSerie.qtafeb1 = 0
              currentSerie.qtamar1 = 0
              currentSerie.qtaapr1 = 0
              currentSerie.qtamag1 = 0
              currentSerie.qtagiu1 = 0
              currentSerie.qtalug1 = 0
              currentSerie.qtaago1 = 0
              currentSerie.qtaset1 = 0
              currentSerie.qtaott1 = 0
              currentSerie.qtanov1 = 0
              currentSerie.qtadic1 = 0
            }
            s.colors.forEach((color) => {
              if (this.search.length > 0) {
                if (!color.colore.toLowerCase().replace(/[.\\/-]/gi, '').startsWith(this.search.toLowerCase().replace(/[.\\/-]/gi, ''))) {
                  return
                }
              }
              if (this.selectedProducts.length > 0 && this.selectedProducts.findIndex((prod) => prod === color.colore) < 0) {
                return
              }
              let currentColor = currentSerie.colors.find((cf) => { return cf.colore === color.colore })
              if (!currentColor) {
                currentColor = {}
                Object.assign(currentColor, color)
                currentSerie.colors.push(currentColor)
              } else {
                currentColor.tot1 += parseFloat(color.tot1)
                currentColor.gen1 += parseFloat(color.gen1)
                currentColor.feb1 += parseFloat(color.feb1)
                currentColor.mar1 += parseFloat(color.mar1)
                currentColor.apr1 += parseFloat(color.apr1)
                currentColor.mag1 += parseFloat(color.mag1)
                currentColor.giu1 += parseFloat(color.giu1)
                currentColor.lug1 += parseFloat(color.lug1)
                currentColor.ago1 += parseFloat(color.ago1)
                currentColor.set1 += parseFloat(color.set1)
                currentColor.ott1 += parseFloat(color.ott1)
                currentColor.nov1 += parseFloat(color.nov1)
                currentColor.dic1 += parseFloat(color.dic1)
                currentColor.mqtot1 += parseFloat(color.mqtot1)
                currentColor.mqgen1 += parseFloat(color.mqgen1)
                currentColor.mqfeb1 += parseFloat(color.mqfeb1)
                currentColor.mqmar1 += parseFloat(color.mqmar1)
                currentColor.mqapr1 += parseFloat(color.mqapr1)
                currentColor.mqmag1 += parseFloat(color.mqmag1)
                currentColor.mqgiu1 += parseFloat(color.mqgiu1)
                currentColor.mqlug1 += parseFloat(color.mqlug1)
                currentColor.mqago1 += parseFloat(color.mqago1)
                currentColor.mqset1 += parseFloat(color.mqset1)
                currentColor.mqott1 += parseFloat(color.mqott1)
                currentColor.mqnov1 += parseFloat(color.mqnov1)
                currentColor.mqdic1 += parseFloat(color.mqdic1)
                currentColor.qtatot1 += parseFloat(color.qtatot1)
                currentColor.qtagen1 += parseFloat(color.qtagen1)
                currentColor.qtafeb1 += parseFloat(color.qtafeb1)
                currentColor.qtamar1 += parseFloat(color.qtamar1)
                currentColor.qtaapr1 += parseFloat(color.qtaapr1)
                currentColor.qtamag1 += parseFloat(color.qtamag1)
                currentColor.qtagiu1 += parseFloat(color.qtagiu1)
                currentColor.qtalug1 += parseFloat(color.qtalug1)
                currentColor.qtaago1 += parseFloat(color.qtaago1)
                currentColor.qtaset1 += parseFloat(color.qtaset1)
                currentColor.qtaott1 += parseFloat(color.qtaott1)
                currentColor.qtanov1 += parseFloat(color.qtanov1)
                currentColor.qtadic1 += parseFloat(color.qtadic1)
              }

              currentSerie.tot1 += parseFloat(color.tot1)
              currentSerie.gen1 += parseFloat(color.gen1)
              currentSerie.feb1 += parseFloat(color.feb1)
              currentSerie.mar1 += parseFloat(color.mar1)
              currentSerie.apr1 += parseFloat(color.apr1)
              currentSerie.mag1 += parseFloat(color.mag1)
              currentSerie.giu1 += parseFloat(color.giu1)
              currentSerie.lug1 += parseFloat(color.lug1)
              currentSerie.ago1 += parseFloat(color.ago1)
              currentSerie.set1 += parseFloat(color.set1)
              currentSerie.ott1 += parseFloat(color.ott1)
              currentSerie.nov1 += parseFloat(color.nov1)
              currentSerie.dic1 += parseFloat(color.dic1)
              currentSerie.mqtot1 += parseFloat(color.mqtot1)
              currentSerie.mqgen1 += parseFloat(color.mqgen1)
              currentSerie.mqfeb1 += parseFloat(color.mqfeb1)
              currentSerie.mqmar1 += parseFloat(color.mqmar1)
              currentSerie.mqapr1 += parseFloat(color.mqapr1)
              currentSerie.mqmag1 += parseFloat(color.mqmag1)
              currentSerie.mqgiu1 += parseFloat(color.mqgiu1)
              currentSerie.mqlug1 += parseFloat(color.mqlug1)
              currentSerie.mqago1 += parseFloat(color.mqago1)
              currentSerie.mqset1 += parseFloat(color.mqset1)
              currentSerie.mqott1 += parseFloat(color.mqott1)
              currentSerie.mqnov1 += parseFloat(color.mqnov1)
              currentSerie.mqdic1 += parseFloat(color.mqdic1)
              currentSerie.qtatot1 += parseFloat(color.qtatot1)
              currentSerie.qtagen1 += parseFloat(color.qtagen1)
              currentSerie.qtafeb1 += parseFloat(color.qtafeb1)
              currentSerie.qtamar1 += parseFloat(color.qtamar1)
              currentSerie.qtaapr1 += parseFloat(color.qtaapr1)
              currentSerie.qtamag1 += parseFloat(color.qtamag1)
              currentSerie.qtagiu1 += parseFloat(color.qtagiu1)
              currentSerie.qtalug1 += parseFloat(color.qtalug1)
              currentSerie.qtaago1 += parseFloat(color.qtaago1)
              currentSerie.qtaset1 += parseFloat(color.qtaset1)
              currentSerie.qtaott1 += parseFloat(color.qtaott1)
              currentSerie.qtanov1 += parseFloat(color.qtanov1)
              currentSerie.qtadic1 += parseFloat(color.qtadic1)
            })
          })
        })
      })

      for (let i = this.filteredReport.length - 1; i >= 0; i -= 1) {
        const agent = this.filteredReport[i]
        for (let j = agent.superclass.length - 1; j >= 0; j -= 1) {
          const superclass = agent.superclass[j]

          for (let k = superclass.series.length - 1; k >= 0; k -= 1) {
            const serie = superclass.series[k]
            if (serie.colors.length === 0) {
              superclass.series.splice(k, 1)
            }
          }
          /* if (superclass.series.length === 0) {
            agent.superclass.splice(j, 1)
          } */
        }
      }

      this.doSortBy()

      // Apre l'accordion di cui trova il risultato
      this.openAccordions = []
      for (let i = this.filteredReport.length - 1; i >= 0; i -= 1) {
        const agent = this.filteredReport[i]
        for (let j = agent.superclass.length - 1; j >= 0; j -= 1) {
          const superclass = agent.superclass[j]

          for (let k = superclass.series.length - 1; k >= 0; k -= 1) {
            const serie = superclass.series[k]
            if (serie.colors.length > 0) {
              if (!this.openAccordions.find(o => o === j)) {
                this.openAccordions.push(j)
              }
            }
          }
        }
      }
    },
    async exportToExcel () {
      this.exportingToExcel = true
      const tHeader = ['Cd_Area',
        'Area',
        'Categoria',
        'Serie',
        'Colore',
        'Tipo',
        'Tot',
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic']
      const exceldata = []
      const months = ['tot1', 'gen1', 'feb1', 'mar1', 'apr1', 'mag1', 'giu1', 'lug1', 'ago1', 'set1', 'ott1', 'nov1', 'dic1']
      this.filteredReport.forEach((agent, i) => {
        agent.superclass.forEach(superclass => {
          superclass.series.forEach(s => {
            s.colors.forEach((color) => {
              let rowData = [agent.area, agent.name, superclass.name, s.name, color.colore]
              rowData.push('EUR')
              for (let index = 0; index < months.length; index++) {
                rowData.push(color[months[index]])
              }
              exceldata.push(rowData)
              rowData = [agent.area, agent.name, superclass.name, s.name, color.colore]
              rowData.push('MQ')
              for (let index = 0; index < months.length; index++) {
                rowData.push(color['mq' + months[index]])
              }
              exceldata.push(rowData)
              rowData = [agent.area, agent.name, superclass.name, s.name, color.colore]
              rowData.push('QTA')
              for (let index = 0; index < months.length; index++) {
                rowData.push(color['qta' + months[index]])
              }
              exceldata.push(rowData)
            })
          })
        })
      })
      setTimeout(() => {
        import('../../vendor/Export2Excel').then(excel => {
          excel.export_json_to_excel({
            header: tHeader, // Header Required
            data: exceldata, // Specific data Required
            filename: this.$t('messages.Fatturato Colore'), // Optional
            autoWidth: true, // Optional
            bookType: 'xlsx' // Optional
          })
          this.exportingToExcel = false
        })
      }, 500)
    }
  }
}
</script>

<style scoped>
table > thead > tr > th, table > tbody > tr > td {
  padding: 0px;
  padding-right: 4px;
  padding-left: 4px;
}
.reportval {
  font-size: 0.665rem;
}
.reportval.highlight {
  font-weight: bold;
  font-size: 0.875rem;
}
.reportval.highlight.reportval-negative {
  color: red
}
table th {
  position: sticky;
  top: 0;
}
table th:first-child {
  position: sticky;
  left: 0;
  top: 0;
  background-color: #ddd;
  margin-right: 4px;
  padding-right: 4px;
  max-width: 160px;
  width: 120px;
  z-index: 1;
}
table td:first-child {
  position: sticky;
  left: 0;
  background-color: #ddd;
  margin-right: 4px;
  padding-right: 4px;
  min-width: 120px;
  max-width: 160px;
}
table td:nth-child(2n+3) {background: rgb(230, 230, 230)}
.report-azienda {
  font-size: 0.665rem;
  font-weight: bold;
}
.report-colore {
  font-size: 0.665rem;
  font-weight: bold;
}
.report-cdclien {
  font-size: 0.665rem;
  font-weight: bold;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 40px;
}
.v-expansion-panel > .v-expansion-panel-header {
  min-height: 40px;
}
.accordion-title {
  font-size: 0.7rem !important;
}
table > tbody > tr > th, table > thead > tr > th, table > tfoot > tr > th {
    font-size: 0.75rem;
}

.agent-header {
  background-color: #5e5e5e;
  min-height: 21px;
  color: white;
  font-size: 0.875rem;
}

table > thead > tr:last-child > th {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
table {
    border-spacing: 0;
}
::v-deep.v-pagination__navigation {
  width: 24px;
  height: 24px;
}
::v-deep.v-pagination__item {
  min-width: 24px;
  height: 24px;
}

</style>

<style>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
