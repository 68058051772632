import clientsAPI from '../api/client-list'

const FETCHING_CLIENTS_LIST = 'FETCHING_CLIENTS_LIST'
const FETCHING_CLIENTS_LIST_SUCCESS = 'FETCHING_CLIENTS_LIST_SUCCESS'
const FETCHING_CLIENTS_LIST_CACHED = 'FETCHING_CLIENTS_LIST_CACHED'
const FETCHING_CLIENTS_LIST_DELETED_SUCCESS = 'FETCHING_CLIENTS_LIST_DELETED_SUCCESS'
const FETCHING_CLIENTS_LIST_ERROR = 'FETCHING_CLIENTS_LIST_ERROR'

const FETCHING_CLIENTS_LIST_MINIMAL = 'FETCHING_CLIENTS_LIST_MINIMAL'
const FETCHING_CLIENTS_LIST_MINIMAL_SUCCESS = 'FETCHING_CLIENTS_LIST_MINIMAL_SUCCESS'
const FETCHING_CLIENTS_LIST_MINIMAL_ERROR = 'FETCHING_CLIENTS_LIST_MINIMAL_ERROR'
const FETCHING_CLIENTS_LIST_MINIMAL_CACHED = 'FETCHING_CLIENTS_LIST_MINIMAL_CACHED'

const FETCHING_CLIENTS_LIST_MINIMAL_INACTIVE_FEEDBACKS = 'FETCHING_CLIENTS_LIST_MINIMAL_INACTIVE_FEEDBACKS'
const FETCHING_CLIENTS_LIST_MINIMAL_INACTIVE_FEEDBACKS_SUCCESS = 'FETCHING_CLIENTS_LIST_MINIMAL_INACTIVE_FEEDBACKS_SUCCESS'
const FETCHING_CLIENTS_LIST_MINIMAL_INACTIVE_FEEDBACKS_ERROR = 'FETCHING_CLIENTS_LIST_MINIMAL_INACTIVE_FEEDBACKS_ERROR'

const CREATING_CLIENT = 'CREATING_CLIENT'
const CREATING_CLIENT_SUCCESS = 'CREATING_USER_SUCCESS'
const CREATING_CLIENT_ERROR = 'CREATING_USER_ERROR'

const DELETING_CLIENT = 'DELETING_CLIENT'
const DELETING_CLIENT_SUCCESS = 'DELETING_CLIENT_SUCCESS'
const DELETING_CLIENT_ERROR = 'DELETING_CLIENT_ERROR'

const MICROAREAS_ADD_TO_CLIENTS = 'MICROAREAS_ADD_TO_CLIENTS'
const SET_FORCED_TO_MAP = 'SET_FORCED_TO_MAP'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    clientsList: [],
    clientsDeletedList: [],
    clientsListMinimal: [],
    clientsListMinimalisProjx: false,
    clientsListMinimalInactiveFeedbacks: [],
    showDeleted: false
  },
  getters: {
    isLoading (state) {
      return state.isLoading
    },
    hasError (state) {
      return state.error !== null
    },
    error (state) {
      return state.error
    },
    errorMessage (state) {
      return state.error.response.data.detail
    },
    clientsList (state) {
      return state.showDeleted ? state.clientsDeletedList : state.clientsList
    },
    clientsListMinimal (state) {
      if (state.clientsListMinimalisProjx) {
        return state.clientsListMinimal.filter(c => c.is_projx === '1' || c.is_projx === 1)
      } else {
        return state.clientsListMinimal
      }
    },
    clientsListMinimalInactiveFeedbacks (state) {
      return state.clientsListMinimalInactiveFeedbacks
    }
  },
  mutations: {
    [FETCHING_CLIENTS_LIST] (state) {
      state.isLoading = true
      state.error = null
    },
    [FETCHING_CLIENTS_LIST_SUCCESS] (state, data) {
      state.error = null
      state.clientsList = data
      state.showDeleted = false
      state.isLoading = false
    },
    [FETCHING_CLIENTS_LIST_CACHED] (state, data) {
      state.showDeleted = data
    },
    [FETCHING_CLIENTS_LIST_DELETED_SUCCESS] (state, data) {
      state.error = null
      state.clientsDeletedList = data
      state.showDeleted = true
      state.isLoading = false
    },
    [FETCHING_CLIENTS_LIST_ERROR] (state, error) {
      state.isLoading = false
      state.clientsList = []
      state.clientsDeletedList = []
      state.error = error
    },
    [FETCHING_CLIENTS_LIST_MINIMAL] (state) {
      state.isLoading = true
      state.clientsListMinimal = []
      state.error = null
    },
    [FETCHING_CLIENTS_LIST_MINIMAL_SUCCESS] (state, { data, isProjx }) {
      state.error = null
      state.clientsListMinimal = data
      state.clientsListMinimalisProjx = isProjx
      state.isLoading = false
    },
    [FETCHING_CLIENTS_LIST_MINIMAL_CACHED] (state, { isProjx }) {
      state.error = null
      state.clientsListMinimalisProjx = isProjx
    },
    [FETCHING_CLIENTS_LIST_MINIMAL_ERROR] (state, error) {
      state.clientsListMinimal = false
      state.error = error
    },
    [FETCHING_CLIENTS_LIST_MINIMAL_INACTIVE_FEEDBACKS] (state) {
      state.isLoading = true
      state.clientsListMinimalInactiveFeedbacks = []
      state.error = null
    },
    [FETCHING_CLIENTS_LIST_MINIMAL_INACTIVE_FEEDBACKS_SUCCESS] (state, data) {
      state.error = null
      state.clientsListMinimalInactiveFeedbacks = data
      state.isLoading = false
    },
    [FETCHING_CLIENTS_LIST_MINIMAL_INACTIVE_FEEDBACKS_ERROR] (state, error) {
      state.clientsListMinimalInactiveFeedbacks = false
      state.error = error
    },
    [CREATING_CLIENT] (state) {
      state.isLoading = true
      state.error = null
    },
    [CREATING_CLIENT_SUCCESS] (state, data) {
      state.isLoading = false
      state.clientsList.push(data.client)
    },
    [CREATING_CLIENT_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
      console.log(error.response)
    },
    [DELETING_CLIENT] (state) {
      state.isLoading = true
      state.error = null
    },
    [DELETING_CLIENT_SUCCESS] (state, id) {
      state.isLoading = false
      if (state.showDeleted) {
        const clientIndex = state.clientsDeletedList.findIndex((client) => { return client.id === id })
        state.clientsDeletedList.splice(clientIndex, 1)
      } else {
        const clientIndex = state.clientsList.findIndex((client) => { return client.id === id })
        state.clientsList.splice(clientIndex, 1)
      }
    },
    [DELETING_CLIENT_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
      console.log(error.response)
    },
    [MICROAREAS_ADD_TO_CLIENTS] (state, data) {
      const clientList = state.showDeleted ? state.clientsDeletedList : state.clientsList
      const microarea = data.microarea
      const clients = data.clients
      clients.forEach(c => {
        const client = clientList.find(cc => cc.id === c)
        client.microarea = microarea
      })
    },
    [SET_FORCED_TO_MAP] (state, data) {

    }
  },
  actions: {
    async clientsList ({ commit, state }, { listDeleted = false, isProjx = false } = {}) {
      if (!listDeleted && state.clientsList.length > 0) {
        commit(FETCHING_CLIENTS_LIST_CACHED, listDeleted)
        return state.clientsList
      } else if (listDeleted && state.clientsDeletedList.length) {
        commit(FETCHING_CLIENTS_LIST_CACHED, listDeleted)
        return state.clientsDeletedList
      }
      commit(FETCHING_CLIENTS_LIST)
      try {
        let response = null
        if (listDeleted === true) {
          response = await clientsAPI.list({ listDeleted, isProjx })
          commit(FETCHING_CLIENTS_LIST_DELETED_SUCCESS, response.data)
        } else {
          response = await clientsAPI.list({ listDeleted, isProjx })
          commit(FETCHING_CLIENTS_LIST_SUCCESS, response.data)
        }
        return response.data
      } catch (error) {
        commit(FETCHING_CLIENTS_LIST_ERROR, error)
        return null
      }
    },
    async clientsListMinimal ({ commit, state, getters }, { isProjx = false } = {}) {
      if (state.clientsListMinimal.length > 0) {
        commit(FETCHING_CLIENTS_LIST_MINIMAL_CACHED, { isProjx })
        return getters.clientsListMinimal
      }
      commit(FETCHING_CLIENTS_LIST_MINIMAL)
      try {
        const response = await clientsAPI.listMinimal({ isProjx: false })
        commit(FETCHING_CLIENTS_LIST_MINIMAL_SUCCESS, { data: response.data, isProjx })
        return getters.clientsListMinimal
      } catch (error) {
        commit(FETCHING_CLIENTS_LIST_MINIMAL_ERROR, error)
        return null
      }
    },
    async clientsListMinimalInactiveFeedbacks ({ commit, state, getters }) {
      if (state.clientsListMinimalInactiveFeedbacks.length > 0) {
        return getters.clientsListMinimalInactiveFeedbacks
      }
      commit(FETCHING_CLIENTS_LIST_MINIMAL_INACTIVE_FEEDBACKS)
      try {
        const response = await clientsAPI.listMinimalInactiveFeedbacks()
        commit(FETCHING_CLIENTS_LIST_MINIMAL_INACTIVE_FEEDBACKS_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_CLIENTS_LIST_MINIMAL_INACTIVE_FEEDBACKS_ERROR, error)
        return null
      }
    },
    async create ({ commit }, data) {
      commit(CREATING_CLIENT)
      try {
        const response = await clientsAPI.create(data)
        commit(CREATING_CLIENT_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(CREATING_CLIENT_ERROR, error)
        return null
      }
    },
    async delete ({ commit }, data) {
      commit(DELETING_CLIENT)
      try {
        let response = null
        if (data.deletePerma) {
          response = await clientsAPI.deletePerma(data)
        } else {
          response = await clientsAPI.delete(data)
        }
        commit(DELETING_CLIENT_SUCCESS, data.id)
        return response.data
      } catch (error) {
        commit(DELETING_CLIENT_ERROR, error)
        return null
      }
    },
    async restore ({ commit }, data) {
      commit(DELETING_CLIENT)
      try {
        const response = await clientsAPI.restore(data)
        commit(DELETING_CLIENT_SUCCESS, data.id)
        return response.data
      } catch (error) {
        commit(DELETING_CLIENT_ERROR, error)
        return null
      }
    },
    async addToMicroArea ({ commit }, data) {
      try {
        const response = await clientsAPI.addToMicroArea(data)
        commit(MICROAREAS_ADD_TO_CLIENTS, data)
        return response.data
      } catch (error) {
        return null
      }
    },
    async setForcedToMap ({ commit }, data) {
      try {
        const response = await clientsAPI.setForcedToMap(data)
        commit(SET_FORCED_TO_MAP, { isForced: data.isForced, response })
        return response.data
      } catch (error) {
        return null
      }
    }
  }
}
