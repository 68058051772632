<template>
  <div class="container px-0 px-sm-3">
    <div class="d-flex align-center justify-space-around ml-1">
      <h1>{{ $t('messages.Rating Visite') }}</h1>
    </div>
    <v-progress-circular
      v-if="isLoading"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <div
        v-if="reportRatingVisite.length > 0"
      >
        <v-btn
          v-if="isAdmin"
          color="#e74c3c"
          dark
          class="btn btn-lg"
          type="button"
          elevation="0"
          tile
          :loading="isLoading"
          @click="esportaPDF"
        >
          <v-icon>
            mdi-file-pdf-outline
          </v-icon>
          {{ $t('messages.Esporta in PDF') }}
        </v-btn>
        <h3>
          {{ $t('messages.Visualizza') }}:
        </h3>
        <v-radio-group
          v-model="viewRatings"
          class="mt-0 mb-2"
          hide-details
          row
        >
          <v-radio
            :label="$t('messages.Tutti')"
            value="all"
          />
          <v-radio
            v-for="(item, r) in ratingTypes"
            :key="r"
            color="primary"
            :value="item.value"
          >
            <template
              slot="label"
            >
              <v-icon
                class="mr-1 ml-n1"
                :color="item.iconColor"
              >
                {{ item.icon }}
              </v-icon>
            </template>
          </v-radio>
        </v-radio-group>
        <v-select
          v-model="viewYears"
          :items="years"
          class="mb-2"
          filled
          :label="$t('messages.Seleziona Anno')"
          hide-details
          @input="updateReport"
        />
        <h3>
          {{ $t('messages.Ricerca') }}:
        </h3>
        <v-switch
          v-model="groupedByArea"
          :label="$t('messages.Raggruppa per area')"
          class="mt-0"
          hide-details
          @change="groupByArea"
        />
        <agent-selector
          v-model="filter.selectedAgent"
          :agents="agentsVisibleToAgent"
          :label="$t('messages.Filtra agente')"
          @input="doFilter"
        />
        <area-selector
          v-model="filter.selectedArea"
          :areas="areasVisibleToAgent"
          :label="$t('messages.Filtra area')"
          @input="doFilter"
        />
        <micro-area-selector
          v-model="filter.selectedMicroArea"
          :microareas="microAreasVisibleToAgent"
          :label="$t('messages.Filtra microarea')"
          @input="doFilter"
        />

        <v-data-table
          v-if="reportRatingVisite.length > 0"
          :headers="headers"
          :items="filteredReport"
          :search="search"
          :loading="isLoading || isFiltering"
          class="mt-1"
          :mobile-breakpoint="0"
          filterable
        >
          <template slot="no-data">
            <v-list-item>
              <v-list-item-content class="justify-center">
                {{ $t('messages.Nessun report trovato') }}
              </v-list-item-content>
            </v-list-item>
          </template>
          <template
            #top
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('messages.Ricercappp')"
              dense
              clearable
              single-line
              hide-details
            />
          </template>
          <template #[`item.azienda`]="{ item }">
            <div class="d-flex flex-column">
              <span
                class="report-cdagent"
              >{{ item.cd_agent }} {{ item.area }}</span>
              <span
                :style="'color:'+$vuetify.theme.themes.light.primary"
                class="report-cdclien"
              >{{ (item.cd_clien ? item.cd_clien + ' ' : '') + (item.azienda ? item.azienda + ' ' + item.cap : '') }}</span>
            </div>
          </template>
          <template #[`item.tot`]="{ item }">
            <div class="d-flex flex-column">
              <div
                class="d-flex flex-row"
                :class="viewRatings === 'all' ? 'justify-space-between' : 'justify-space-around'"
              >
                <div
                  v-if="viewRatings === 'all' || viewRatings == '1'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="error"
                    small
                  >
                    mdi-emoticon-sad
                  </v-icon>
                  {{ item.r1.tot ? parseFloat(item.r1.tot).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings == '2'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="amber darken-2"
                    small
                  >
                    mdi-emoticon-neutral
                  </v-icon>
                  {{ item.r2.tot ? parseFloat(item.r2.tot).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings === '3'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="success"
                    small
                  >
                    mdi-emoticon
                  </v-icon>
                  {{ item.r3.tot ? parseFloat(item.r3.tot).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
              </div>
              <div
                v-if="viewRatings === 'all'"
                class="d-flex justify-center reportval"
              >
                ( {{ parseFloat(item.tot).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} )
              </div>
            </div>
          </template>
          <template #[`item.gen`]="{ item }">
            <div
              class="d-flex flex-column"
            >
              <div
                class="d-flex flex-row"
                :class="viewRatings === 'all' ? 'justify-space-between' : 'justify-space-around'"
              >
                <div
                  v-if="viewRatings === 'all' || viewRatings == '1'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="error"
                    small
                  >
                    mdi-emoticon-sad
                  </v-icon>
                  {{ item.r1.gen ? parseFloat(item.r1.gen).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings == '2'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="amber darken-2"
                    small
                  >
                    mdi-emoticon-neutral
                  </v-icon>
                  {{ item.r2.gen ? parseFloat(item.r2.gen).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings === '3'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="success"
                    small
                  >
                    mdi-emoticon
                  </v-icon>
                  {{ item.r3.gen ? parseFloat(item.r3.gen).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
              </div>
              <div
                v-if="viewRatings === 'all'"
                class="d-flex justify-center reportval"
              >
                ( {{ parseFloat(item.gen).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} )
              </div>
            </div>
          </template>
          <template #[`item.feb`]="{ item }">
            <div
              class="d-flex flex-column"
            >
              <div
                class="d-flex flex-row"
                :class="viewRatings === 'all' ? 'justify-space-between' : 'justify-space-around'"
              >
                <div
                  v-if="viewRatings === 'all' || viewRatings == '1'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="error"
                    small
                  >
                    mdi-emoticon-sad
                  </v-icon>
                  {{ item.r1.feb ? parseFloat(item.r1.feb).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings == '2'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="amber darken-2"
                    small
                  >
                    mdi-emoticon-neutral
                  </v-icon>
                  {{ item.r2.feb ? parseFloat(item.r2.feb).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings === '3'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="success"
                    small
                  >
                    mdi-emoticon
                  </v-icon>
                  {{ item.r3.feb ? parseFloat(item.r3.feb).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
              </div>
              <div
                v-if="viewRatings === 'all'"
                class="d-flex justify-center reportval"
              >
                ( {{ parseFloat(item.feb).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} )
              </div>
            </div>
          </template>
          <template #[`item.mar`]="{ item }">
            <div
              class="d-flex flex-column"
            >
              <div
                class="d-flex flex-row"
                :class="viewRatings === 'all' ? 'justify-space-between' : 'justify-space-around'"
              >
                <div
                  v-if="viewRatings === 'all' || viewRatings == '1'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="error"
                    small
                  >
                    mdi-emoticon-sad
                  </v-icon>
                  {{ item.r1.mar ? parseFloat(item.r1.mar).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings == '2'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="amber darken-2"
                    small
                  >
                    mdi-emoticon-neutral
                  </v-icon>
                  {{ item.r2.mar ? parseFloat(item.r2.mar).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings === '3'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="success"
                    small
                  >
                    mdi-emoticon
                  </v-icon>
                  {{ item.r3.mar ? parseFloat(item.r3.mar).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
              </div>
              <div
                v-if="viewRatings === 'all'"
                class="d-flex justify-center reportval"
              >
                ( {{ parseFloat(item.mar).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} )
              </div>
            </div>
          </template>
          <template #[`item.apr`]="{ item }">
            <div
              class="d-flex flex-column"
            >
              <div
                class="d-flex flex-row"
                :class="viewRatings === 'all' ? 'justify-space-between' : 'justify-space-around'"
              >
                <div
                  v-if="viewRatings === 'all' || viewRatings == '1'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="error"
                    small
                  >
                    mdi-emoticon-sad
                  </v-icon>
                  {{ item.r1.apr ? parseFloat(item.r1.apr).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings == '2'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="amber darken-2"
                    small
                  >
                    mdi-emoticon-neutral
                  </v-icon>
                  {{ item.r2.apr ? parseFloat(item.r2.apr).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings === '3'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="success"
                    small
                  >
                    mdi-emoticon
                  </v-icon>
                  {{ item.r3.apr ? parseFloat(item.r3.apr).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
              </div>
              <div
                v-if="viewRatings === 'all'"
                class="d-flex justify-center reportval"
              >
                ( {{ parseFloat(item.apr).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} )
              </div>
            </div>
          </template>
          <template #[`item.mag`]="{ item }">
            <div
              class="d-flex flex-column"
            >
              <div
                class="d-flex flex-row"
                :class="viewRatings === 'all' ? 'justify-space-between' : 'justify-space-around'"
              >
                <div
                  v-if="viewRatings === 'all' || viewRatings == '1'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="error"
                    small
                  >
                    mdi-emoticon-sad
                  </v-icon>
                  {{ item.r1.mag ? parseFloat(item.r1.mag).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings == '2'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="amber darken-2"
                    small
                  >
                    mdi-emoticon-neutral
                  </v-icon>
                  {{ item.r2.mag ? parseFloat(item.r2.mag).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings === '3'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="success"
                    small
                  >
                    mdi-emoticon
                  </v-icon>
                  {{ item.r3.mag ? parseFloat(item.r3.mag).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
              </div>
              <div
                v-if="viewRatings === 'all'"
                class="d-flex justify-center reportval"
              >
                ( {{ parseFloat(item.mag).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} )
              </div>
            </div>
          </template>
          <template #[`item.giu`]="{ item }">
            <div
              class="d-flex flex-column"
            >
              <div
                class="d-flex flex-row"
                :class="viewRatings === 'all' ? 'justify-space-between' : 'justify-space-around'"
              >
                <div
                  v-if="viewRatings === 'all' || viewRatings == '1'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="error"
                    small
                  >
                    mdi-emoticon-sad
                  </v-icon>
                  {{ item.r1.giu ? parseFloat(item.r1.giu).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings == '2'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="amber darken-2"
                    small
                  >
                    mdi-emoticon-neutral
                  </v-icon>
                  {{ item.r2.giu ? parseFloat(item.r2.giu).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings === '3'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="success"
                    small
                  >
                    mdi-emoticon
                  </v-icon>
                  {{ item.r3.giu ? parseFloat(item.r3.giu).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
              </div>
              <div
                v-if="viewRatings === 'all'"
                class="d-flex justify-center reportval"
              >
                ( {{ parseFloat(item.giu).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} )
              </div>
            </div>
          </template>
          <template #[`item.lug`]="{ item }">
            <div
              class="d-flex flex-column"
            >
              <div
                class="d-flex flex-row"
                :class="viewRatings === 'all' ? 'justify-space-between' : 'justify-space-around'"
              >
                <div
                  v-if="viewRatings === 'all' || viewRatings == '1'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="error"
                    small
                  >
                    mdi-emoticon-sad
                  </v-icon>
                  {{ item.r1.lug ? parseFloat(item.r1.lug).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings == '2'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="amber darken-2"
                    small
                  >
                    mdi-emoticon-neutral
                  </v-icon>
                  {{ item.r2.lug ? parseFloat(item.r2.lug).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings === '3'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="success"
                    small
                  >
                    mdi-emoticon
                  </v-icon>
                  {{ item.r3.lug ? parseFloat(item.r3.lug).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
              </div>
              <div
                v-if="viewRatings === 'all'"
                class="d-flex justify-center reportval"
              >
                ( {{ parseFloat(item.lug).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} )
              </div>
            </div>
          </template>
          <template #[`item.ago`]="{ item }">
            <div
              class="d-flex flex-column"
            >
              <div
                class="d-flex flex-row"
                :class="viewRatings === 'all' ? 'justify-space-between' : 'justify-space-around'"
              >
                <div
                  v-if="viewRatings === 'all' || viewRatings == '1'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="error"
                    small
                  >
                    mdi-emoticon-sad
                  </v-icon>
                  {{ item.r1.ago ? parseFloat(item.r1.ago).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings == '2'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="amber darken-2"
                    small
                  >
                    mdi-emoticon-neutral
                  </v-icon>
                  {{ item.r2.ago ? parseFloat(item.r2.ago).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings === '3'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="success"
                    small
                  >
                    mdi-emoticon
                  </v-icon>
                  {{ item.r3.ago ? parseFloat(item.r3.ago).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
              </div>
              <div
                v-if="viewRatings === 'all'"
                class="d-flex justify-center reportval"
              >
                ( {{ parseFloat(item.ago).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} )
              </div>
            </div>
          </template>
          <template #[`item.sept`]="{ item }">
            <div
              class="d-flex flex-column"
            >
              <div
                class="d-flex flex-row"
                :class="viewRatings === 'all' ? 'justify-space-between' : 'justify-space-around'"
              >
                <div
                  v-if="viewRatings === 'all' || viewRatings == '1'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="error"
                    small
                  >
                    mdi-emoticon-sad
                  </v-icon>
                  {{ item.r1.sept ? parseFloat(item.r1.sept).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings == '2'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="amber darken-2"
                    small
                  >
                    mdi-emoticon-neutral
                  </v-icon>
                  {{ item.r2.sept ? parseFloat(item.r2.sept).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings === '3'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="success"
                    small
                  >
                    mdi-emoticon
                  </v-icon>
                  {{ item.r3.sept ? parseFloat(item.r3.sept).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
              </div>
              <div
                v-if="viewRatings === 'all'"
                class="d-flex justify-center reportval"
              >
                ( {{ parseFloat(item.sept).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} )
              </div>
            </div>
          </template>
          <template #[`item.ott`]="{ item }">
            <div
              class="d-flex flex-column"
            >
              <div
                class="d-flex flex-row"
                :class="viewRatings === 'all' ? 'justify-space-between' : 'justify-space-around'"
              >
                <div
                  v-if="viewRatings === 'all' || viewRatings == '1'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="error"
                    small
                  >
                    mdi-emoticon-sad
                  </v-icon>
                  {{ item.r1.ott ? parseFloat(item.r1.ott).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings == '2'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="amber darken-2"
                    small
                  >
                    mdi-emoticon-neutral
                  </v-icon>
                  {{ item.r2.ott ? parseFloat(item.r2.ott).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings === '3'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="success"
                    small
                  >
                    mdi-emoticon
                  </v-icon>
                  {{ item.r3.ott ? parseFloat(item.r3.ott).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
              </div>
              <div
                v-if="viewRatings === 'all'"
                class="d-flex justify-center reportval"
              >
                ( {{ parseFloat(item.ott).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} )
              </div>
            </div>
          </template>
          <template #[`item.nov`]="{ item }">
            <div
              class="d-flex flex-column"
            >
              <div
                class="d-flex flex-row"
                :class="viewRatings === 'all' ? 'justify-space-between' : 'justify-space-around'"
              >
                <div
                  v-if="viewRatings === 'all' || viewRatings == '1'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="error"
                    small
                  >
                    mdi-emoticon-sad
                  </v-icon>
                  {{ item.r1.nov ? parseFloat(item.r1.nov).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings == '2'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="amber darken-2"
                    small
                  >
                    mdi-emoticon-neutral
                  </v-icon>
                  {{ item.r2.nov ? parseFloat(item.r2.nov).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings === '3'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="success"
                    small
                  >
                    mdi-emoticon
                  </v-icon>
                  {{ item.r3.nov ? parseFloat(item.r3.nov).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
              </div>
              <div
                v-if="viewRatings === 'all'"
                class="d-flex justify-center reportval"
              >
                ( {{ parseFloat(item.nov).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} )
              </div>
            </div>
          </template>
          <template #[`item.dic`]="{ item }">
            <div
              class="d-flex flex-column"
            >
              <div
                class="d-flex flex-row"
                :class="viewRatings === 'all' ? 'justify-space-between' : 'justify-space-around'"
              >
                <div
                  v-if="viewRatings === 'all' || viewRatings == '1'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="error"
                    small
                  >
                    mdi-emoticon-sad
                  </v-icon>
                  {{ item.r1.dic ? parseFloat(item.r1.dic).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings == '2'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="amber darken-2"
                    small
                  >
                    mdi-emoticon-neutral
                  </v-icon>
                  {{ item.r2.dic ? parseFloat(item.r2.dic).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
                <div
                  v-if="viewRatings === 'all' || viewRatings === '3'"
                  class="d-flex flex-column align-center reportval"
                  :class="highlight=='now' ? 'highlight':''"
                >
                  <v-icon
                    color="success"
                    small
                  >
                    mdi-emoticon
                  </v-icon>
                  {{ item.r3.dic ? parseFloat(item.r3.dic).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }}
                </div>
              </div>
              <div
                v-if="viewRatings === 'all'"
                class="d-flex justify-center reportval"
              >
                ( {{ parseFloat(item.dic).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} )
              </div>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import JsPDF from 'jspdf'
import 'jspdf-autotable'
import AgentSelector from '../../components/AgentSelector'
import MicroAreaSelector from '../../components/MicroAreaSelector'
import AreaSelector from '../../components/AreaSelector'

export default {
  name: 'ReportVisitRatings',

  components: {
    AgentSelector,
    AreaSelector,
    MicroAreaSelector
  },

  data: () => ({
    reportRatingVisite: [],
    search: '',
    isLoading: false,
    highlight: 'now',
    groupedByArea: false,
    filteredReport: [],
    agentsVisibleToAgent: [],
    areasVisibleToAgent: [],
    microAreasVisibleToAgent: [],
    areaDefinition: [],
    filter: {
      selectedArea: '',
      selectedAgent: '',
      selectedMicroArea: ''
    },
    isFiltering: false,
    ratingTypes: [
      { value: '1', iconColor: 'error', icon: 'mdi-emoticon-sad' },
      { value: '2', iconColor: 'amber darken-2', icon: 'mdi-emoticon-neutral' },
      { value: '3', iconColor: 'success', icon: 'mdi-emoticon' }
    ],
    viewRatings: 'all',
    todayDate: new Date().toISOString().substr(0, 10)
  }),

  computed: {
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    isAdmin () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN')
    },
    viewYears () {
      return this.$t('messages.Anno Corrente')
    },
    years () {
      return [this.$t('messages.Anno Corrente'), this.$t('messages.Anno Precedente')]
    },
    headers () {
      return [
        { text: this.$t('messages.Cliente'), value: 'azienda', filterable: true },
        { text: this.$t('messages.Area'), value: 'area', class: 'd-none', cellClass: 'd-none', filterable: true },
        { text: this.$t('messages.Cap'), value: 'cap', class: 'd-none', cellClass: 'd-none', filterable: true },
        { text: this.$t('messages.Agente'), value: 'cd_agent', class: 'd-none', cellClass: 'd-none', filterable: true },
        { text: this.$t('messages.Cod'), value: 'cd_clien', class: 'd-none', cellClass: 'd-none', filterable: true },
        { text: this.$t('messages.Tot'), value: 'tot', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Gen'), value: 'gen', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Feb'), value: 'feb', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Mar'), value: 'mar', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Apr'), value: 'apr', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Mag'), value: 'mag', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Giu'), value: 'giu', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Lug'), value: 'lug', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Ago'), value: 'ago', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Set'), value: 'sept', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Ott'), value: 'ott', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Nov'), value: 'nov', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Dic'), value: 'dic', align: 'right', width: '60px', filterable: false }
      ]
    }
  },

  async created () {
    this.isLoading = true
    this.$store.dispatch('agents/agentsVisibleToAgent').then((r) => { this.isInitializing--; this.agentsVisibleToAgent = r })
    this.$store.dispatch('agents/areasVisibleToAgent').then((r) => { this.isInitializing--; this.areasVisibleToAgent = r })
    this.$store.dispatch('agents/microAreasVisibleToAgent').then((r) => { this.isInitializing--; this.microAreasVisibleToAgent = r })
    await this.$store.dispatch('agents/listAreaDefinition').then((r) => {
      r.forEach(a => {
        if (a.cd_agent) { this.areaDefinition[a.cd_agent] = a }
      })
    })
    this.getReportRatingVisite()
  },

  methods: {
    async esportaPDF () {
      // var body = []
      const d1 = this.todayDate
      const datestring = this.formatDate(d1)

      // let doc = new JsPDF('l') // orizzontale --- new JsPDF() // verticale
      const doc = new JsPDF('l')
      const totalPagesExp = '{total_pages_count_string}'

      const body = []

      this.filteredReport.forEach((item) => {
        let rowToPush = []
        // const neutralIcon = charCodeAt(80)
        // const sadIcon = String.fromCharCode(80) // 0x1F61F
        // const neutralIcon = String.fromCharCode(0x263A) // U+1F601 => 0x1F601
        // const happyIcon = String.fromCharCode(0x1F60A)
        rowToPush = [(item.cd_agent + ' ' + (item.area ?? '') + '\n' + (item.cd_clien ? 'Codp cli: ' + item.cd_clien : '') + (item.azienda ? ' - cap: ' + item.cap : '') + '\n' + (item.azienda ? item.azienda : '')),
          (item.r1.tot ? parseFloat(item.r1.tot).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r2.tot ? parseFloat(item.r2.tot).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r3.tot ? parseFloat(item.r3.tot).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '\n' +
          '(' + parseFloat(item.tot).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ')',
          (item.r1.gen ? parseFloat(item.r1.gen).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r2.gen ? parseFloat(item.r2.gen).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r3.gen ? parseFloat(item.r3.gen).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '\n' +
          '(' + parseFloat(item.gen).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ')',
          (item.r1.feb ? parseFloat(item.r1.feb).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r2.feb ? parseFloat(item.r2.feb).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r3.feb ? parseFloat(item.r3.feb).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '\n' +
          '(' + parseFloat(item.feb).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ')',
          (item.r1.mar ? parseFloat(item.r1.mar).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r2.mar ? parseFloat(item.r2.mar).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r3.mar ? parseFloat(item.r3.mar).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '\n' +
          '(' + parseFloat(item.mar).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ')',
          (item.r1.apr ? parseFloat(item.r1.apr).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r2.apr ? parseFloat(item.r2.apr).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r3.apr ? parseFloat(item.r3.apr).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '\n' +
          '(' + parseFloat(item.apr).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ')',
          (item.r1.mag ? parseFloat(item.r1.mag).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r2.mag ? parseFloat(item.r2.mag).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r3.mag ? parseFloat(item.r3.mag).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '\n' +
          '(' + parseFloat(item.mag).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ')',
          (item.r1.giu ? parseFloat(item.r1.giu).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r2.giu ? parseFloat(item.r2.giu).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r3.giu ? parseFloat(item.r3.giu).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '\n' +
          '(' + parseFloat(item.giu).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ')',
          (item.r1.lug ? parseFloat(item.r1.lug).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r2.lug ? parseFloat(item.r2.lug).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r3.lug ? parseFloat(item.r3.lug).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '\n' +
          '(' + parseFloat(item.lug).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ')',
          (item.r1.ago ? parseFloat(item.r1.ago).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r2.ago ? parseFloat(item.r2.ago).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r3.ago ? parseFloat(item.r3.ago).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '\n' +
          '(' + parseFloat(item.ago).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ')',
          (item.r1.sept ? parseFloat(item.r1.sept).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r2.sept ? parseFloat(item.r2.sept).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r3.sept ? parseFloat(item.r3.sept).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '\n' +
          '(' + parseFloat(item.sept).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ')',
          (item.r1.ott ? parseFloat(item.r1.ott).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r2.ott ? parseFloat(item.r2.ott).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r3.ott ? parseFloat(item.r3.ott).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '\n' +
          '(' + parseFloat(item.ott).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ')',
          (item.r1.nov ? parseFloat(item.r1.nov).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r2.nov ? parseFloat(item.r2.nov).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r3.nov ? parseFloat(item.r3.nov).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '\n' +
          '(' + parseFloat(item.nov).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ')',
          (item.r1.dic ? parseFloat(item.r1.dic).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r2.dic ? parseFloat(item.r2.dic).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '  -  ' +
          (item.r3.dic ? parseFloat(item.r3.dic).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0') + '\n' +
          '(' + parseFloat(item.dic).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ')'
        ]
        body.push(rowToPush)
      })
      // const base64Img = images.logoBase64()
      doc.autoTable({
        head: [[this.$t('messages.Cliente'),
          this.$t('messages.Tot'),
          this.$t('messages.Gen'),
          this.$t('messages.Feb'),
          this.$t('messages.Mar'),
          this.$t('messages.Apr'),
          this.$t('messages.Mag'),
          this.$t('messages.Giu'),
          this.$t('messages.Lug'),
          this.$t('messages.Ago'),
          this.$t('messages.Set'),
          this.$t('messages.Ott'),
          this.$t('messages.Nov'),
          this.$t('messages.Dic')]],
        body: body,
        alternateRowStyles: { fillColor: [232, 233, 252] },
        headStyles: {
          fillColor: [196, 199, 245],
          fontSize: 10,
          textColor: 40,
          halign: 'center',
          valign: 'center'
        },
        bodyStyles: {
          fontSize: 7,
          fontStyle: 'bold',
          cellPadding: 1,
          valign: 'bottom'
        },
        didParseCell: (data) => {
          data.row.height = 11
        },
        didDrawCell: (data) => {
          if (data.cell.section === 'body' && data.column.index > 0) {
            const xPos = data.cell.x
            const yPos = data.cell.y
            // const base64Img = '/sad.jpg'.logoBase64()
            doc.addImage('/emoticon-sad.png', 'png', xPos + 1, yPos + 1, 3, 3)
            doc.addImage('/emoticon-neutral.png', 'png', xPos + 6, yPos + 1, 3, 3)
            doc.addImage('/emoticon-happy.png', 'png', xPos + 12, yPos + 1, 3, 3)
          }
        },
        columnStyles: {
          0: { cellWidth: 'auto', overflow: 'ellipsize' },
          1: { cellWidth: 16, halign: 'center' },
          2: { cellWidth: 16, halign: 'center' },
          3: { cellWidth: 16, halign: 'center' },
          4: { cellWidth: 16, halign: 'center' },
          5: { cellWidth: 16, halign: 'center' },
          6: { cellWidth: 16, halign: 'center' },
          7: { cellWidth: 16, halign: 'center' },
          8: { cellWidth: 16, halign: 'center' },
          9: { cellWidth: 16, halign: 'center' },
          10: { cellWidth: 16, halign: 'center' },
          11: { cellWidth: 16, halign: 'center' },
          12: { cellWidth: 16, halign: 'center' },
          13: { cellWidth: 16, halign: 'center' },
          14: { cellWidth: 16, halign: 'center' }
        },
        tableLineColor: [44, 62, 80],
        tableLineWidth: 0.1,
        styles: {
          lineColor: [44, 62, 80],
          lineWidth: 0.1
        },
        didDrawPage: function (data) {
          // Header
          doc.setFontSize(18)
          doc.setTextColor(40)

          const currentFont = doc.getFont()
          doc.getFont(currentFont.fontName, 'normal', 'normal')
          // doc.setFontStyle('normal')
          // doc.addImage(base64Img, 'JPEG', data.settings.margin.left, 15, 24.551, 11)
          doc.addImage('/logo.jpg', 'JPEG', data.settings.margin.left + 5, 13, 15, 15)
          doc.text(this.$t('messages.Report Rating Visite del #data#', [datestring]), data.settings.margin.left + 24.551, 22)

          // Footer
          let str = 'Page ' + doc.internal.getNumberOfPages()
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' of ' + totalPagesExp
          }
          doc.setFontSize(7)

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          const pageSize = doc.internal.pageSize
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
          doc.text(str, data.settings.margin.left, pageHeight - 10)
        },
        margin: { top: 30 }
      })

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
      }
      doc.save(this.$t('ReportRatingVisiteppdf'))
    },

    async toBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },

    async getReportRatingVisite () {
      this.isLoading = true
      this.reportRatingVisite = await this.$store.dispatch('reports/getReportVisitRatings')
      const total = {
        area: this.$t('Totale'),
        cd_agent: ' ',
        tot: 0,
        gen: 0,
        feb: 0,
        mar: 0,
        apr: 0,
        mag: 0,
        giu: 0,
        lug: 0,
        ago: 0,
        sept: 0,
        ott: 0,
        nov: 0,
        dic: 0,
        r1: { tot: 0, gen: 0, feb: 0, mar: 0, apr: 0, mag: 0, giu: 0, lug: 0, ago: 0, sept: 0, ott: 0, nov: 0, dic: 0 },
        r2: { tot: 0, gen: 0, feb: 0, mar: 0, apr: 0, mag: 0, giu: 0, lug: 0, ago: 0, sept: 0, ott: 0, nov: 0, dic: 0 },
        r3: { tot: 0, gen: 0, feb: 0, mar: 0, apr: 0, mag: 0, giu: 0, lug: 0, ago: 0, sept: 0, ott: 0, nov: 0, dic: 0 }
      }
      this.reportRatingVisite.forEach((c) => {
        c.area = this.getArea(c.cd_agent)
        c.tot = (parseFloat(c.gen) + parseFloat(c.feb) + parseFloat(c.mar) +
                  parseFloat(c.apr) + parseFloat(c.mag) + parseFloat(c.giu) + parseFloat(c.lug) +
                  parseFloat(c.ago) + parseFloat(c.sept) + parseFloat(c.ott) + parseFloat(c.nov) +
                  parseFloat(c.dic))
        c.gen = parseFloat(c.gen)
        c.feb = parseFloat(c.feb)
        c.mar = parseFloat(c.mar)
        c.apr = parseFloat(c.apr)
        c.mag = parseFloat(c.mag)
        c.giu = parseFloat(c.giu)
        c.lug = parseFloat(c.lug)
        c.ago = parseFloat(c.ago)
        c.sept = parseFloat(c.sept)
        c.ott = parseFloat(c.ott)
        c.nov = parseFloat(c.nov)
        c.dic = parseFloat(c.dic)
        if (c.r1) {
          c.r1.tot = (parseFloat(c.r1.gen) + parseFloat(c.r1.feb) + parseFloat(c.r1.mar) +
          parseFloat(c.r1.apr) + parseFloat(c.r1.mag) + parseFloat(c.r1.giu) + parseFloat(c.r1.lug) +
          parseFloat(c.r1.ago) + parseFloat(c.r1.sept) + parseFloat(c.r1.ott) + parseFloat(c.r1.nov) +
          parseFloat(c.r1.dic))
          c.r1.gen = parseFloat(c.r1.gen)
          c.r1.feb = parseFloat(c.r1.feb)
          c.r1.mar = parseFloat(c.r1.mar)
          c.r1.apr = parseFloat(c.r1.apr)
          c.r1.mag = parseFloat(c.r1.mag)
          c.r1.giu = parseFloat(c.r1.giu)
          c.r1.lug = parseFloat(c.r1.lug)
          c.r1.ago = parseFloat(c.r1.ago)
          c.r1.sept = parseFloat(c.r1.sept)
          c.r1.ott = parseFloat(c.r1.ott)
          c.r1.nov = parseFloat(c.r1.nov)
          c.r1.dic = parseFloat(c.r1.dic)
        }
        if (c.r2) {
          c.r2.tot = (parseFloat(c.r2.gen) + parseFloat(c.r2.feb) + parseFloat(c.r2.mar) +
          parseFloat(c.r2.apr) + parseFloat(c.r2.mag) + parseFloat(c.r2.giu) + parseFloat(c.r2.lug) +
          parseFloat(c.r2.ago) + parseFloat(c.r2.sept) + parseFloat(c.r2.ott) + parseFloat(c.r2.nov) +
          parseFloat(c.r2.dic))
          c.r2.gen = parseFloat(c.r2.gen)
          c.r2.feb = parseFloat(c.r2.feb)
          c.r2.mar = parseFloat(c.r2.mar)
          c.r2.apr = parseFloat(c.r2.apr)
          c.r2.mag = parseFloat(c.r2.mag)
          c.r2.giu = parseFloat(c.r2.giu)
          c.r2.lug = parseFloat(c.r2.lug)
          c.r2.ago = parseFloat(c.r2.ago)
          c.r2.sept = parseFloat(c.r2.sept)
          c.r2.ott = parseFloat(c.r2.ott)
          c.r2.nov = parseFloat(c.r2.nov)
          c.r2.dic = parseFloat(c.r2.dic)
        }
        if (c.r3) {
          c.r3.tot = (parseFloat(c.r3.gen) + parseFloat(c.r3.feb) + parseFloat(c.r3.mar) +
          parseFloat(c.r3.apr) + parseFloat(c.r3.mag) + parseFloat(c.r3.giu) + parseFloat(c.r3.lug) +
          parseFloat(c.r3.ago) + parseFloat(c.r3.sept) + parseFloat(c.r3.ott) + parseFloat(c.r3.nov) +
          parseFloat(c.r3.dic))
          c.r3.gen = parseFloat(c.r3.gen)
          c.r3.feb = parseFloat(c.r3.feb)
          c.r3.mar = parseFloat(c.r3.mar)
          c.r3.apr = parseFloat(c.r3.apr)
          c.r3.mag = parseFloat(c.r3.mag)
          c.r3.giu = parseFloat(c.r3.giu)
          c.r3.lug = parseFloat(c.r3.lug)
          c.r3.ago = parseFloat(c.r3.ago)
          c.r3.sept = parseFloat(c.r3.sept)
          c.r3.ott = parseFloat(c.r3.ott)
          c.r3.nov = parseFloat(c.r3.nov)
          c.r3.dic = parseFloat(c.r3.dic)
        }
        total.tot += c.tot
        total.gen += c.gen
        total.feb += c.feb
        total.mar += c.mar
        total.apr += c.apr
        total.mag += c.mag
        total.giu += c.giu
        total.lug += c.lug
        total.ago += c.ago
        total.sept += c.sept
        total.ott += c.ott
        total.nov += c.nov
        total.dic += c.dic
        if (c.r1) {
          if (parseFloat(c.r1.tot)) {
            total.r1.tot += c.r1.tot
          }
          if (parseFloat(c.r1.gen)) {
            total.r1.gen += c.r1.gen
          }
          if (parseFloat(c.r1.feb)) {
            total.r1.feb += c.r1.feb
          }
          if (parseFloat(c.r1.mar)) {
            total.r1.mar += c.r1.mar
          }
          if (parseFloat(c.r1.apr)) {
            total.r1.apr += c.r1.apr
          }
          if (parseFloat(c.r1.mag)) {
            total.r1.mag += c.r1.mag
          }
          if (parseFloat(c.r1.giu)) {
            total.r1.giu += c.r1.giu
          }
          if (parseFloat(c.r1.lug)) {
            total.r1.lug += c.r1.lug
          }
          if (parseFloat(c.r1.ago)) {
            total.r1.ago += c.r1.ago
          }
          if (parseFloat(c.r1.sept)) {
            total.r1.sept += c.r1.sept
          }
          if (parseFloat(c.r1.ott)) {
            total.r1.ott += c.r1.ott
          }
          if (parseFloat(c.r1.nov)) {
            total.r1.nov += c.r1.nov
          }
          if (parseFloat(c.r1.dic)) {
            total.r1.dic += c.r1.dic
          }
        }
        if (c.r2) {
          if (parseFloat(c.r2.tot)) {
            total.r2.tot += c.r2.tot
          }
          if (parseFloat(c.r2.gen)) {
            total.r2.gen += c.r2.gen
          }
          if (parseFloat(c.r2.feb)) {
            total.r2.feb += c.r2.feb
          }
          if (parseFloat(c.r2.mar)) {
            total.r2.mar += c.r2.mar
          }
          if (parseFloat(c.r2.apr)) {
            total.r2.apr += c.r2.apr
          }
          if (parseFloat(c.r2.mag)) {
            total.r2.mag += c.r2.mag
          }
          if (parseFloat(c.r2.giu)) {
            total.r2.giu += c.r2.giu
          }
          if (parseFloat(c.r2.lug)) {
            total.r2.lug += c.r2.lug
          }
          if (parseFloat(c.r2.ago)) {
            total.r2.ago += c.r2.ago
          }
          if (parseFloat(c.r2.sept)) {
            total.r2.sept += c.r2.sept
          }
          if (parseFloat(c.r2.ott)) {
            total.r2.ott += c.r2.ott
          }
          if (parseFloat(c.r2.nov)) {
            total.r2.nov += c.r2.nov
          }
          if (parseFloat(c.r2.dic)) {
            total.r2.dic += c.r2.dic
          }
        }
        if (c.r3) {
          if (parseFloat(c.r3.tot)) {
            total.r3.tot += c.r3.tot
          }
          if (parseFloat(c.r3.gen)) {
            total.r3.gen += c.r3.gen
          }
          if (parseFloat(c.r3.feb)) {
            total.r3.feb += c.r3.feb
          }
          if (parseFloat(c.r3.mar)) {
            total.r3.mar += c.r3.mar
          }
          if (parseFloat(c.r3.apr)) {
            total.r3.apr += c.r3.apr
          }
          if (parseFloat(c.r3.mag)) {
            total.r3.mag += c.r3.mag
          }
          if (parseFloat(c.r3.giu)) {
            total.r3.giu += c.r3.giu
          }
          if (parseFloat(c.r3.lug)) {
            total.r3.lug += c.r3.lug
          }
          if (parseFloat(c.r3.ago)) {
            total.r3.ago += c.r3.ago
          }
          if (parseFloat(c.r3.sept)) {
            total.r3.sept += c.r3.sept
          }
          if (parseFloat(c.r3.ott)) {
            total.r3.ott += c.r3.ott
          }
          if (parseFloat(c.r3.nov)) {
            total.r3.nov += c.r3.nov
          }
          if (parseFloat(c.r3.dic)) {
            total.r3.dic += c.r3.dic
          }
        }
      })
      this.filteredReport = this.reportRatingVisite.filter(() => true)
      this.filteredReport.unshift(total)
      this.isLoading = false
    },

    async updateReport () {
      this.isLoading = true
      const viewingYear = new Date().getFullYear()
      if (this.viewYears === this.$t('messages.Anno Corrente')) {
        const viewYear = viewingYear
        this.filteredReport = await this.$store.dispatch('reports/getReportVisitRatings', viewYear)
        this.filteredReport.forEach((c) => {
          c.area = this.getArea(c.cd_agent)
          c.tot = (parseFloat(c.gen) + parseFloat(c.feb) + parseFloat(c.mar) +
                  parseFloat(c.apr) + parseFloat(c.mag) + parseFloat(c.giu) + parseFloat(c.lug) +
                  parseFloat(c.ago) + parseFloat(c.sept) + parseFloat(c.ott) + parseFloat(c.nov) +
                  parseFloat(c.dic))
          c.gen = parseFloat(c.gen)
          c.feb = parseFloat(c.feb)
          c.mar = parseFloat(c.mar)
          c.apr = parseFloat(c.apr)
          c.mag = parseFloat(c.mag)
          c.giu = parseFloat(c.giu)
          c.lug = parseFloat(c.lug)
          c.ago = parseFloat(c.ago)
          c.sept = parseFloat(c.sept)
          c.ott = parseFloat(c.ott)
          c.nov = parseFloat(c.nov)
          c.dic = parseFloat(c.dic)
        })
      }
      if (this.viewYears === this.$t('messages.Anno Precedente')) {
        const viewYear = viewingYear - 1
        this.filteredReport = await this.$store.dispatch('reports/getReportVisitRatings', viewYear)
        this.filteredReport.forEach((c) => {
          c.area = this.getArea(c.cd_agent)
          c.tot = (parseFloat(c.gen) + parseFloat(c.feb) + parseFloat(c.mar) +
                  parseFloat(c.apr) + parseFloat(c.mag) + parseFloat(c.giu) + parseFloat(c.lug) +
                  parseFloat(c.ago) + parseFloat(c.sept) + parseFloat(c.ott) + parseFloat(c.nov) +
                  parseFloat(c.dic))
          c.gen = parseFloat(c.gen)
          c.feb = parseFloat(c.feb)
          c.mar = parseFloat(c.mar)
          c.apr = parseFloat(c.apr)
          c.mag = parseFloat(c.mag)
          c.giu = parseFloat(c.giu)
          c.lug = parseFloat(c.lug)
          c.ago = parseFloat(c.ago)
          c.sept = parseFloat(c.sept)
          c.ott = parseFloat(c.ott)
          c.nov = parseFloat(c.nov)
          c.dic = parseFloat(c.dic)
        })
      }
      this.isLoading = false
    },

    async doFilter () {
      let areasSubAgentFilter = null
      if ((this.filter.selectedAgent ?? '') !== '') {
        this.isFiltering = true
        areasSubAgentFilter = await this.$store.dispatch('agents/areasVisibleToSubAgent', this.filter.selectedAgent)
        this.isFiltering = false
      }
      const total = {
        area: this.$t('Totale'),
        cd_agent: ' ',
        tot: 0,
        gen: 0,
        feb: 0,
        mar: 0,
        apr: 0,
        mag: 0,
        giu: 0,
        lug: 0,
        ago: 0,
        sept: 0,
        ott: 0,
        nov: 0,
        dic: 0,
        r1: { tot: 0, gen: 0, feb: 0, mar: 0, apr: 0, mag: 0, giu: 0, lug: 0, ago: 0, sept: 0, ott: 0, nov: 0, dic: 0 },
        r2: { tot: 0, gen: 0, feb: 0, mar: 0, apr: 0, mag: 0, giu: 0, lug: 0, ago: 0, sept: 0, ott: 0, nov: 0, dic: 0 },
        r3: { tot: 0, gen: 0, feb: 0, mar: 0, apr: 0, mag: 0, giu: 0, lug: 0, ago: 0, sept: 0, ott: 0, nov: 0, dic: 0 }
      }
      this.filteredReport = this.reportRatingVisite.filter(c => {
        const isFiltered = ((this.filter.selectedArea ?? '') === '' || c.cd_agent === this.filter.selectedArea) &&
          ((this.filter.selectedMicroArea ?? '') === '' || c.microarea === this.filter.selectedMicroArea) &&
          ((this.filter.selectedAgent ?? '') === '' || areasSubAgentFilter.some((area) => { return c.cd_agent === area.cd_agent }))
        if (isFiltered) {
          total.tot += c.tot
          total.gen += c.gen
          total.feb += c.feb
          total.mar += c.mar
          total.apr += c.apr
          total.mag += c.mag
          total.giu += c.giu
          total.lug += c.lug
          total.ago += c.ago
          total.sept += c.sept
          total.ott += c.ott
          total.nov += c.nov
          total.dic += c.dic
          if (c.r1) {
            if (parseFloat(c.r1.tot)) {
              total.r1.tot += c.r1.tot
            }
            if (parseFloat(c.r1.gen)) {
              total.r1.gen += c.r1.gen
            }
            if (parseFloat(c.r1.feb)) {
              total.r1.feb += c.r1.feb
            }
            if (parseFloat(c.r1.mar)) {
              total.r1.mar += c.r1.mar
            }
            if (parseFloat(c.r1.apr)) {
              total.r1.apr += c.r1.apr
            }
            if (parseFloat(c.r1.mag)) {
              total.r1.mag += c.r1.mag
            }
            if (parseFloat(c.r1.giu)) {
              total.r1.giu += c.r1.giu
            }
            if (parseFloat(c.r1.lug)) {
              total.r1.lug += c.r1.lug
            }
            if (parseFloat(c.r1.ago)) {
              total.r1.ago += c.r1.ago
            }
            if (parseFloat(c.r1.sept)) {
              total.r1.sept += c.r1.sept
            }
            if (parseFloat(c.r1.ott)) {
              total.r1.ott += c.r1.ott
            }
            if (parseFloat(c.r1.nov)) {
              total.r1.nov += c.r1.nov
            }
            if (parseFloat(c.r1.dic)) {
              total.r1.dic += c.r1.dic
            }
          }
          if (c.r2) {
            if (parseFloat(c.r2.tot)) {
              total.r2.tot += c.r2.tot
            }
            if (parseFloat(c.r2.gen)) {
              total.r2.gen += c.r2.gen
            }
            if (parseFloat(c.r2.feb)) {
              total.r2.feb += c.r2.feb
            }
            if (parseFloat(c.r2.mar)) {
              total.r2.mar += c.r2.mar
            }
            if (parseFloat(c.r2.apr)) {
              total.r2.apr += c.r2.apr
            }
            if (parseFloat(c.r2.mag)) {
              total.r2.mag += c.r2.mag
            }
            if (parseFloat(c.r2.giu)) {
              total.r2.giu += c.r2.giu
            }
            if (parseFloat(c.r2.lug)) {
              total.r2.lug += c.r2.lug
            }
            if (parseFloat(c.r2.ago)) {
              total.r2.ago += c.r2.ago
            }
            if (parseFloat(c.r2.sept)) {
              total.r2.sept += c.r2.sept
            }
            if (parseFloat(c.r2.ott)) {
              total.r2.ott += c.r2.ott
            }
            if (parseFloat(c.r2.nov)) {
              total.r2.nov += c.r2.nov
            }
            if (parseFloat(c.r2.dic)) {
              total.r2.dic += c.r2.dic
            }
          }
          if (c.r3) {
            if (parseFloat(c.r3.tot)) {
              total.r3.tot += c.r3.tot
            }
            if (parseFloat(c.r3.gen)) {
              total.r3.gen += c.r3.gen
            }
            if (parseFloat(c.r3.feb)) {
              total.r3.feb += c.r3.feb
            }
            if (parseFloat(c.r3.mar)) {
              total.r3.mar += c.r3.mar
            }
            if (parseFloat(c.r3.apr)) {
              total.r3.apr += c.r3.apr
            }
            if (parseFloat(c.r3.mag)) {
              total.r3.mag += c.r3.mag
            }
            if (parseFloat(c.r3.giu)) {
              total.r3.giu += c.r3.giu
            }
            if (parseFloat(c.r3.lug)) {
              total.r3.lug += c.r3.lug
            }
            if (parseFloat(c.r3.ago)) {
              total.r3.ago += c.r3.ago
            }
            if (parseFloat(c.r3.sept)) {
              total.r3.sept += c.r3.sept
            }
            if (parseFloat(c.r3.ott)) {
              total.r3.ott += c.r3.ott
            }
            if (parseFloat(c.r3.nov)) {
              total.r3.nov += c.r3.nov
            }
            if (parseFloat(c.r3.dic)) {
              total.r3.dic += c.r3.dic
            }
          }
        }
        return isFiltered
      })
      this.filteredReport.unshift(total)
    },
    getArea (cdAgent) {
      return this.areaDefinition[cdAgent]?.nome
      // this.reportRatingVisite = await this.$store.dispatch('reports/getReportRatingVisite')
    },
    async groupByArea () {
      await this.doFilter()
      if (this.groupedByArea) {
        this.filteredReport = this.filteredReport.reduce((cg, c) => {
          if (cg.currentGroupedHash[c.cd_agent]) {
            cg.currentGroupedHash[c.cd_agent].gen += parseFloat(c.gen)
            cg.currentGroupedHash[c.cd_agent].feb += parseFloat(c.feb)
            cg.currentGroupedHash[c.cd_agent].mar += parseFloat(c.mar)
            cg.currentGroupedHash[c.cd_agent].apr += parseFloat(c.apr)
            cg.currentGroupedHash[c.cd_agent].mag += parseFloat(c.mag)
            cg.currentGroupedHash[c.cd_agent].giu += parseFloat(c.giu)
            cg.currentGroupedHash[c.cd_agent].lug += parseFloat(c.lug)
            cg.currentGroupedHash[c.cd_agent].ago += parseFloat(c.ago)
            cg.currentGroupedHash[c.cd_agent].sept += parseFloat(c.sept)
            cg.currentGroupedHash[c.cd_agent].ott += parseFloat(c.ott)
            cg.currentGroupedHash[c.cd_agent].nov += parseFloat(c.nov)
            cg.currentGroupedHash[c.cd_agent].dic += parseFloat(c.dic)
            cg.currentGroupedHash[c.cd_agent].tot += parseFloat(c.tot)
            cg.currentGroupedHash[c.cd_agent].r1.gen += parseFloat(c.r1.gen)
            cg.currentGroupedHash[c.cd_agent].r1.feb += parseFloat(c.r1.feb)
            cg.currentGroupedHash[c.cd_agent].r1.mar += parseFloat(c.r1.mar)
            cg.currentGroupedHash[c.cd_agent].r1.apr += parseFloat(c.r1.apr)
            cg.currentGroupedHash[c.cd_agent].r1.mag += parseFloat(c.r1.mag)
            cg.currentGroupedHash[c.cd_agent].r1.giu += parseFloat(c.r1.giu)
            cg.currentGroupedHash[c.cd_agent].r1.lug += parseFloat(c.r1.lug)
            cg.currentGroupedHash[c.cd_agent].r1.ago += parseFloat(c.r1.ago)
            cg.currentGroupedHash[c.cd_agent].r1.sept += parseFloat(c.r1.sept)
            cg.currentGroupedHash[c.cd_agent].r1.ott += parseFloat(c.r1.ott)
            cg.currentGroupedHash[c.cd_agent].r1.nov += parseFloat(c.r1.nov)
            cg.currentGroupedHash[c.cd_agent].r1.dic += parseFloat(c.r1.dic)
            cg.currentGroupedHash[c.cd_agent].r1.tot += parseFloat(c.r1.tot)
            cg.currentGroupedHash[c.cd_agent].r2.gen += parseFloat(c.r2.gen)
            cg.currentGroupedHash[c.cd_agent].r2.feb += parseFloat(c.r2.feb)
            cg.currentGroupedHash[c.cd_agent].r2.mar += parseFloat(c.r2.mar)
            cg.currentGroupedHash[c.cd_agent].r2.apr += parseFloat(c.r2.apr)
            cg.currentGroupedHash[c.cd_agent].r2.mag += parseFloat(c.r2.mag)
            cg.currentGroupedHash[c.cd_agent].r2.giu += parseFloat(c.r2.giu)
            cg.currentGroupedHash[c.cd_agent].r2.lug += parseFloat(c.r2.lug)
            cg.currentGroupedHash[c.cd_agent].r2.ago += parseFloat(c.r2.ago)
            cg.currentGroupedHash[c.cd_agent].r2.sept += parseFloat(c.r2.sept)
            cg.currentGroupedHash[c.cd_agent].r2.ott += parseFloat(c.r2.ott)
            cg.currentGroupedHash[c.cd_agent].r2.nov += parseFloat(c.r2.nov)
            cg.currentGroupedHash[c.cd_agent].r2.dic += parseFloat(c.r2.dic)
            cg.currentGroupedHash[c.cd_agent].r2.tot += parseFloat(c.r2.tot)
            cg.currentGroupedHash[c.cd_agent].r3.gen += parseFloat(c.r3.gen)
            cg.currentGroupedHash[c.cd_agent].r3.feb += parseFloat(c.r3.feb)
            cg.currentGroupedHash[c.cd_agent].r3.mar += parseFloat(c.r3.mar)
            cg.currentGroupedHash[c.cd_agent].r3.apr += parseFloat(c.r3.apr)
            cg.currentGroupedHash[c.cd_agent].r3.mag += parseFloat(c.r3.mag)
            cg.currentGroupedHash[c.cd_agent].r3.giu += parseFloat(c.r3.giu)
            cg.currentGroupedHash[c.cd_agent].r3.lug += parseFloat(c.r3.lug)
            cg.currentGroupedHash[c.cd_agent].r3.ago += parseFloat(c.r3.ago)
            cg.currentGroupedHash[c.cd_agent].r3.sept += parseFloat(c.r3.sept)
            cg.currentGroupedHash[c.cd_agent].r3.ott += parseFloat(c.r3.ott)
            cg.currentGroupedHash[c.cd_agent].r3.nov += parseFloat(c.r3.nov)
            cg.currentGroupedHash[c.cd_agent].r3.dic += parseFloat(c.r3.dic)
            cg.currentGroupedHash[c.cd_agent].r3.tot += parseFloat(c.r3.tot)
          } else {
            cg.currentGroupedHash[c.cd_agent] = {}
            cg.currentGroupedHash[c.cd_agent] = JSON.parse(JSON.stringify(c))
            cg.currentGroupedArray.push(cg.currentGroupedHash[c.cd_agent])
            cg.currentGroupedHash[c.cd_agent].cd_clien = ''
            cg.currentGroupedHash[c.cd_agent].azienda = ''
          }
          return cg
        }, { currentGroupedHash: [], currentGroupedArray: [] }).currentGroupedArray
      } else {
        // this.filteredReport = this.filteredReport
      }
    },
    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    }
  }
}
</script>

<style scoped>
::v-deep.v-data-table > .v-data-table__wrapper > table > thead > tr > th, ::v-deep.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0px;
  padding-right: 4px;
  padding-left: 4px;
}
.reportval {
  font-size: 0.665rem;
}
.reportval.highlight {
  font-weight: bold;
  font-size: 0.875rem;
}
.reportval.highlight.reportval-negative {
  color: red
}
::v-deep.v-data-table table th:first-child {
  position: sticky;
  left: 0;
  background-color: #ddd;
  margin-right: 4px;
  padding-right: 4px;
  max-width: 160px;
  width: 120px;
}
::v-deep.v-data-table table td:first-child {
  position: sticky;
  left: 0;
  background-color: #ddd;
  margin-right: 4px;
  padding-right: 4px;
  min-width: 120px;
  max-width: 160px;
  z-index: 4;
}
.report-azienda {
  font-size: 0.665rem;
  font-weight: bold;
}
.report-cdagent {
  font-size: 0.665rem;
  font-weight: bold;
}
.report-cdclien {
  font-size: 0.665rem;
  font-weight: bold;
}
::v-deep.v-data-table table td:nth-child(2n+3) {background: #e6e6e6}

::v-deep.v-data-table table th:nth-child(6) {
  max-width: 66px;
  min-width: 66px;
  width: 66px;
}
::v-deep.v-data-table table td:nth-child(6) {
  min-width: 66px;
  max-width: 66px;
  width: 66px;
}
</style>
