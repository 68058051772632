<template>
  <div class="container px-0 px-sm-3">
    <div class="d-flex align-center justify-space-around ml-1">
      <h1>{{ $t('messages.Fatturato Cliente') }}</h1>
    </div>
    <v-progress-circular
      v-if="isLoading"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <div
        v-if="reportFatturatoCliente.length > 0"
      >
        <v-btn
          v-if="isSupervisor"
          color="#e74c3c"
          dark
          class="btn btn-lg"
          type="button"
          elevation="0"
          tile
          :loading="isExportingPDF"
          @click="esportaPDF"
        >
          <v-icon>
            mdi-file-pdf-outline
          </v-icon>
          {{ $t('messages.Esporta in PDF') }}
        </v-btn>
        <h3>
          {{ $t('messages.Evidenzia') }}:
        </h3>
        <v-radio-group
          v-model="highlight"
          class="mt-0"
          hide-details
          row
        >
          <v-radio
            :label="$t('messages.Anno Precedente')"
            value="last"
          />
          <v-radio
            :label="$t('messages.Anno Corrente')"
            value="now"
          />
          <v-radio
            :label="$t('messages.Differenza')"
            value="difference"
          />
        </v-radio-group>
        <div class="d-flex">
          <v-switch
            v-model="groupedByArea"
            :label="$t('messages.Raggruppa per area')"
            class="mt-0"
            hide-details
            @change="groupByArea"
          />
          <v-switch
            v-model="hideUnhighlighted"
            :label="$t('messages.Nascondi non evidenziati')"
            class="mt-0 ml-4"
            hide-details
          />
        </div>
        <agent-selector
          v-model="filter.selectedAgent"
          :agents="agentsVisibleToAgent"
          :label="$t('messages.Filtra agente')"
          @input="groupByArea"
        />
        <area-selector
          v-model="filter.selectedArea"
          :areas="areasVisibleToAgent"
          :label="$t('messages.Filtra area')"
          @input="groupByArea"
        />
        <micro-area-selector
          v-model="filter.selectedMicroArea"
          :microareas="microAreasVisibleToAgent"
          :label="$t('messages.Filtra microarea')"
          @input="groupByArea"
        />

        <v-select
          v-model="selectedYear"
          :items="selectableYears"
          :label="$t('messages.Anno')"
          hide-details
          filled
          @input="getReportFatturatoCliente"
        />

        <v-data-table
          v-if="reportFatturatoCliente.length > 0"
          :headers="headers"
          :items="filteredReport"
          :search="search"
          :loading="isLoading || isFiltering"
          class="mt-1"
          :mobile-breakpoint="0"
          filterable
          :custom-sort="customSort"
        >
          <template
            #top
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('messages.Ricercappp')"
              dense
              clearable
              single-line
              hide-details
            />
          </template>
          <template #[`item`]="{ item }">
            <tr>
              <td colspan="2">
                <div
                  class="d-flex flex-column"
                  :style="item.client_id ? 'cursor: pointer;':''"
                  @click="viewClient(item.client_id)"
                >
                  <span
                    class="report-cdagent"
                  >{{ item.cd_agent }} {{ item.area }}</span>
                  <span
                    v-if="item.cd_clien"
                    :style="'color:'+$vuetify.theme.themes.light.primary"
                    class="report-cdclien"
                  >{{ item.cd_clien + ' ' + (item.azienda ? item.azienda + ' ' + item.cap : '') }}</span>
                </div>
              </td>
              <td>
                <span
                  class="reportval"
                  style="font-weight: bold; font-size: 0.665rem;"
                  :class="item.isVisitedLastThreeMonths ? '' : 'red--text'"
                >{{ formatDate(item.last_visit_date) }}
                </span>
              </td>
              <td>
                <span
                  v-if="!isSupervisor && !item.isVisitedLastThreeMonths"
                  class="reportval highlight red--text"
                >
                  {{ $t('messages.DA VISITARE') }}
                </span>
                <div
                  v-else
                  class="d-flex flex-column"
                >
                  <!-- eslint-disable vue/no-v-html -->

                  <div
                    v-if="!hideUnhighlighted || highlight=='last'"
                    class="reportval text-right"
                    :class="highlight=='last' ? 'highlight':''"
                    v-html="euroFormat(item.tot1)"
                  />
                  <div
                    v-if="!hideUnhighlighted || highlight=='now'"
                    class="reportval text-right"
                    :class="highlight=='now' ? 'highlight':''"
                    v-html="euroFormat(item.tot2)"
                  />
                  <div
                    v-if="!hideUnhighlighted || highlight=='difference'"
                    class="reportval text-right"
                    :class="(highlight=='difference' ? 'highlight':'') + ((
                      item.tot2-item.tot1
                    )<0?' reportval-negative':'')"
                    v-html="euroFormat(item.tot2-item.tot1)"
                  />
                </div>
              </td>
              <td
                v-for="month in months"
                :key="month"
              >
                <div
                  v-if="isSupervisor || item.isVisitedLastThreeMonths"
                  class="d-flex flex-column"
                >
                  <div
                    v-if="!hideUnhighlighted || highlight=='last'"
                    class="reportval text-right"
                    :class="highlight=='last' ? 'highlight':''"
                    v-html="euroFormat(item[month+'1'])"
                  />
                  <div
                    v-if="!hideUnhighlighted || highlight=='now'"
                    class="reportval text-right"
                    :class="highlight=='now' ? 'highlight':''"
                    v-html="euroFormat(item[month+'2'])"
                  />
                  <div
                    v-if="!hideUnhighlighted || highlight=='difference'"
                    class="reportval text-right"
                    :class="(highlight=='difference' ? 'highlight':'') + ((
                      item[month+'2']-item[month+'1']
                    )<0?' reportval-negative':'')"
                    v-html="euroFormat(item[month+'2']-item[month+'1'])"
                  />
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import AgentSelector from '../../components/AgentSelector'
import MicroAreaSelector from '../../components/MicroAreaSelector'
import AreaSelector from '../../components/AreaSelector'

export default {
  name: 'ReportClientSales',

  components: {
    AgentSelector,
    AreaSelector,
    MicroAreaSelector
  },

  data: () => ({
    reportFatturatoCliente: [],
    search: '',
    isLoading: false,
    highlight: 'now',
    groupedByArea: false,
    filteredReport: [],
    agentsVisibleToAgent: [],
    areasVisibleToAgent: [],
    microAreasVisibleToAgent: [],
    areaDefinition: [],
    filter: {
      selectedArea: '',
      selectedAgent: '',
      selectedMicroArea: ''
    },
    months: ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic'],
    isFiltering: false,
    todayDate: new Date().toISOString().substr(0, 10),
    selectedYear: '',
    isExportingPDF: false,
    hideUnhighlighted: false
  }),

  computed: {
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    isAdmin () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN')
    },
    isSupervisor () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN') || this.$store.getters['security/hasRole']('ROLE_AGENT_ADMIN')
    },
    selectableYears () {
      const currentYear = new Date().getFullYear()
      return [
        (currentYear - 1) + '',
        currentYear + ''
      ]
    },
    headers () {
      return [
        { text: this.$t('messages.Cliente'), value: 'azienda', filterable: true },
        { text: this.$t('messages.Area'), value: 'area', class: 'd-none', cellClass: 'd-none', filterable: true },
        { text: this.$t('messages.Cap'), value: 'cap', cellClass: 'd-none', width: '30px', filterable: true },
        { text: this.$t('messages.Agente'), value: 'cd_agent', class: 'd-none', cellClass: 'd-none', filterable: true },
        { text: this.$t('messages.Codice'), value: 'cd_clien', class: 'd-none', cellClass: 'd-none', filterable: true },
        { text: this.$t('messages.Visita'), value: 'last_visit_date', align: 'center', width: '30px', filterable: false },
        { text: this.$t('messages.Tot'), value: 'tot2', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Gen'), value: 'gen2', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Feb'), value: 'feb2', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Mar'), value: 'mar2', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Apr'), value: 'apr2', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Mag'), value: 'mag2', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Giu'), value: 'giu2', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Lug'), value: 'lug2', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Ago'), value: 'ago2', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Set'), value: 'set2', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Ott'), value: 'ott2', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Nov'), value: 'nov2', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Dic'), value: 'dic2', align: 'right', width: '60px', filterable: false }
      ]
    }
  },

  async created () {
    this.isLoading = true
    this.$store.dispatch('agents/agentsVisibleToAgent').then((r) => { this.isInitializing--; this.agentsVisibleToAgent = r })
    this.$store.dispatch('agents/areasVisibleToAgent').then((r) => { this.isInitializing--; this.areasVisibleToAgent = r })
    this.$store.dispatch('agents/microAreasVisibleToAgent').then((r) => { this.isInitializing--; this.microAreasVisibleToAgent = r })
    await this.$store.dispatch('agents/listAreaDefinition').then((r) => {
      r.forEach(a => {
        if (a.cd_agent) { this.areaDefinition[a.cd_agent] = a }
      })
    })
    this.selectedYear = this.selectableYears[1]
    this.getReportFatturatoCliente()
  },
  methods: {
    euroFormat (value) {
      value = parseFloat(value)
      if (!value) return '&nbsp;'
      return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    viewClient (clientId) {
      if (clientId) {
        // this.$router.push({ path: '/client/details/' + clientId })
        window.open('/client/details/' + clientId, '_blank')
      }
    },
    getNestedValue (obj, path, fallback) {
      const last = path.length - 1

      if (last < 0) return obj === undefined ? fallback : obj

      for (let i = 0; i < last; i++) {
        if (obj == null) {
          return fallback
        }
        obj = obj[path[i]]
      }

      if (obj == null) return fallback

      return obj[path[last]] === undefined ? fallback : obj[path[last]]
    },
    getObjectValueByPath (obj, path, fallback) {
      if (obj == null || !path || typeof path !== 'string') return fallback
      if (obj[path] !== undefined) return obj[path]
      path = path.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
      path = path.replace(/^\./, '') // strip a leading dot
      return this.getNestedValue(obj, path.split('.'), fallback)
    },
    customSort (
      items,
      sortBy,
      sortDesc,
      locale,
      customSorters
    ) {
      if (sortBy === null || !sortBy.length) return items
      const stringCollator = new Intl.Collator(locale, { sensitivity: 'accent', usage: 'sort' })

      const totale = this.$t('messages.Totale')
      const clientiServiti = this.$t('messages.Serviti')
      return items.sort((a, b) => {
        if (a.area === totale || a.area === clientiServiti) {
          if (b.area === clientiServiti) {
            return 1
          }
          return -1
        }
        if (b.area === totale || b.area === clientiServiti) {
          return 1
        }

        for (let i = 0; i < sortBy.length; i++) {
          const sortKey = sortBy[i]

          let sortA = this.getObjectValueByPath(a, sortKey)
          let sortB = this.getObjectValueByPath(b, sortKey)

          if (sortDesc[i]) {
            [sortA, sortB] = [sortB, sortA]
          }

          if (customSorters && customSorters[sortKey]) {
            const customResult = customSorters[sortKey](sortA, sortB)

            if (!customResult) continue

            return customResult
          }

          // Check if both cannot be evaluated
          if (sortA === null && sortB === null) {
            continue
          }

          // Dates should be compared numerically
          if (sortA instanceof Date && sortB instanceof Date) {
            return sortA.getTime() - sortB.getTime()
          }

          [sortA, sortB] = [sortA, sortB].map(s => (s || '').toString().toLocaleLowerCase())

          if (sortA !== sortB) {
            if (!isNaN(sortA) && !isNaN(sortB)) return Number(sortA) - Number(sortB)
            return stringCollator.compare(sortA, sortB)
          }
        }

        return 0
      })
    },
    createInlineWorker (fn) {
      const blob = new Blob(['self.onmessage = ', fn.toString()], { type: 'text/javascript' })
      const url = URL.createObjectURL(blob)

      return new Worker(url)
    },

    async esportaPDF () {
      const myWorker = new Worker('/workers/worker-client-sales.js')

      const _this = this
      _this.isExportingPDF = true
      myWorker.onmessage = function (e) {
        // var worker_data = e.data
        // doc = worker_data.value
        if (e.data.status === 'complete') {
          _this.isExportingPDF = false
          const ancorTag = document.createElement('a')
          ancorTag.href = e.data.value
          ancorTag.target = '_blank'
          ancorTag.download = _this.$t('messages.ReportFatturatoClienteppdf')
          document.body.appendChild(ancorTag)
          ancorTag.click()
          document.body.removeChild(ancorTag)
          myWorker.terminate()
        }
      }

      const d1 = _this.todayDate
      const datestring = _this.formatDate(d1)

      // let doc = new JsPDF('l') // orizzontale --- new JsPDF() // verticale

      const body = []

      const months = ['tot', 'gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic']
      _this.filteredReport.forEach((item) => {
        const cellCliente = {
          rowSpan: 3,
          content: (item.cd_agent + ' ' + (item.area ?? '') + '\n' + (item.cd_clien ? 'Cod cli: ' + item.cd_clien : '') + (item.azienda ? ' - cap: ' + item.cap : '') + '\n' + (item.azienda ? item.azienda : '')),
          styles: { valign: 'middle', halign: 'left' }
        }
        const totale = _this.$t('messages.Totale')
        const clientiServiti = _this.$t('messages.Serviti')
        if (item.area === totale || item.area === clientiServiti) {
          cellCliente.content = (item.cd_agent + ' ' + (item.area ?? ''))
        }
        const cellVisita = {
          rowSpan: 3,
          content: _this.formatDate(item.last_visit_date),
          styles: { valign: 'middle', halign: 'center' }
        }
        let rowData = [cellCliente, cellVisita]

        // month1
        for (let index = 0; index < months.length; index++) {
          let cellContent = item[months[index] + '1'].toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          if (item[months[index] + '1'] === 0) {
            cellContent = ''
          }
          let cellStyle = { fontStyle: 'normal', fontSize: 6 }
          if (_this.highlight === 'last') {
            cellStyle = { fontStyle: 'bold', fontSize: 8 }
          }
          rowData.push({
            content: cellContent,
            styles: cellStyle
          })
        }
        body.push(rowData)

        rowData = []
        // month2
        for (let index = 0; index < months.length; index++) {
          let cellContent = item[months[index] + '2'].toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          if (item[months[index] + '2'] === 0) {
            cellContent = ''
          }
          let cellStyle = { fontStyle: 'normal', fontSize: 6 }
          if (_this.highlight === 'now') {
            cellStyle = { fontStyle: 'bold', fontSize: 8 }
          }
          rowData.push({
            content: cellContent,
            styles: cellStyle
          })
        }
        body.push(rowData)

        rowData = []
        // month2 - month1
        for (let index = 0; index < months.length; index++) {
          let cellContent = (item[months[index] + '2'] - item[months[index] + '1']).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          if ((item[months[index] + '2'] - item[months[index] + '1']) === 0) {
            cellContent = ''
          }
          let cellStyle = { fontStyle: 'normal', fontSize: 6 }
          if (_this.highlight === 'difference') {
            cellStyle = { fontStyle: 'bold', fontSize: 8 }
          }
          if ((item[months[index] + '2'] - item[months[index] + '1']) < 0) {
            cellStyle.textColor = [255, 0, 0]
          }
          rowData.push({
            content: cellContent,
            styles: cellStyle
          })
          rowData.push()
        }
        body.push(rowData)
      })
      myWorker.postMessage({
        body: body,
        datestring: datestring,
        messages: {
          'messages.Cliente': _this.$t('messages.Cliente'),
          'messages.Visita': _this.$t('messages.Visita'),
          'messages.Tot': _this.$t('messages.Tot'),
          'messages.Gen': _this.$t('messages.Gen'),
          'messages.Feb': _this.$t('messages.Feb'),
          'messages.Mar': _this.$t('messages.Mar'),
          'messages.Apr': _this.$t('messages.Apr'),
          'messages.Mag': _this.$t('messages.Mag'),
          'messages.Giu': _this.$t('messages.Giu'),
          'messages.Lug': _this.$t('messages.Lug'),
          'messages.Ago': _this.$t('messages.Ago'),
          'messages.Set': _this.$t('messages.Set'),
          'messages.Ott': _this.$t('messages.Ott'),
          'messages.Nov': _this.$t('messages.Nov'),
          'messages.Dic': _this.$t('messages.Dic'),
          'messages.Report Fatturato Cliente del #data#': _this.$t('messages.Report Fatturato Cliente del #data#', [datestring])
        }
      })
    },

    async getReportFatturatoCliente () {
      this.isLoading = true
      const lastThreeMonthsDate = new Date(Date.now())
      lastThreeMonthsDate.setMonth(lastThreeMonthsDate.getMonth() - 3)
      // this.filter = {
      //   selectedArea: '',
      //   selectedAgent: '',
      //   selectedMicroArea: ''
      // }
      this.reportFatturatoCliente = await this.$store.dispatch('reports/getReportFatturatoCliente', this.selectedYear)
      const total = {
        area: this.$t('messages.Totale'),
        isVisitedLastThreeMonths: true,
        cd_agent: '(€)',
        tot2: 0,
        tot1: 0,
        gen1: 0,
        feb1: 0,
        mar1: 0,
        apr1: 0,
        mag1: 0,
        giu1: 0,
        lug1: 0,
        ago1: 0,
        set1: 0,
        ott1: 0,
        nov1: 0,
        dic1: 0,
        gen2: 0,
        feb2: 0,
        mar2: 0,
        apr2: 0,
        mag2: 0,
        giu2: 0,
        lug2: 0,
        ago2: 0,
        set2: 0,
        ott2: 0,
        nov2: 0,
        dic2: 0
      }
      const clients = {
        area: this.$t('messages.Serviti'),
        isVisitedLastThreeMonths: true,
        cd_agent: '(n)',
        tot2: 0,
        tot1: 0,
        gen1: 0,
        feb1: 0,
        mar1: 0,
        apr1: 0,
        mag1: 0,
        giu1: 0,
        lug1: 0,
        ago1: 0,
        set1: 0,
        ott1: 0,
        nov1: 0,
        dic1: 0,
        gen2: 0,
        feb2: 0,
        mar2: 0,
        apr2: 0,
        mag2: 0,
        giu2: 0,
        lug2: 0,
        ago2: 0,
        set2: 0,
        ott2: 0,
        nov2: 0,
        dic2: 0
      }
      this.reportFatturatoCliente.forEach((c) => {
        c.isVisitedLastThreeMonths = false

        // starting fix - periodo di condono
        const today = new Date()
        const year = today.getFullYear()
        const month = ('0' + (today.getMonth() + 1)).slice(-2)
        const day = ('0' + today.getDate()).slice(-2)
        const time = year + '-' + month + '-' + day

        if (time <= '2022-01-01') {
          c.isVisitedLastThreeMonths = true
        }

        if (c.last_visit_date) {
          const visitDate = new Date(c.last_visit_date)

          if (visitDate >= lastThreeMonthsDate) {
            c.isVisitedLastThreeMonths = true
          }
        }
        c.area = this.getArea(c.cd_agent)
        c.tot2 = (parseFloat(c.gen2) + parseFloat(c.feb2) + parseFloat(c.mar2) +
                parseFloat(c.apr2) + parseFloat(c.mag2) + parseFloat(c.giu2) + parseFloat(c.lug2) +
                parseFloat(c.ago2) + parseFloat(c.set2) + parseFloat(c.ott2) + parseFloat(c.nov2) +
                parseFloat(c.dic2))
        c.tot1 = (parseFloat(c.gen1) + parseFloat(c.feb1) + parseFloat(c.mar1) +
                  parseFloat(c.apr1) + parseFloat(c.mag1) + parseFloat(c.giu1) + parseFloat(c.lug1) +
                  parseFloat(c.ago1) + parseFloat(c.set1) + parseFloat(c.ott1) + parseFloat(c.nov1) +
                  parseFloat(c.dic1))
        c.gen1 = parseFloat(c.gen1)
        c.feb1 = parseFloat(c.feb1)
        c.mar1 = parseFloat(c.mar1)
        c.apr1 = parseFloat(c.apr1)
        c.mag1 = parseFloat(c.mag1)
        c.giu1 = parseFloat(c.giu1)
        c.lug1 = parseFloat(c.lug1)
        c.ago1 = parseFloat(c.ago1)
        c.set1 = parseFloat(c.set1)
        c.ott1 = parseFloat(c.ott1)
        c.nov1 = parseFloat(c.nov1)
        c.dic1 = parseFloat(c.dic1)
        c.gen2 = parseFloat(c.gen2)
        c.feb2 = parseFloat(c.feb2)
        c.mar2 = parseFloat(c.mar2)
        c.apr2 = parseFloat(c.apr2)
        c.mag2 = parseFloat(c.mag2)
        c.giu2 = parseFloat(c.giu2)
        c.lug2 = parseFloat(c.lug2)
        c.ago2 = parseFloat(c.ago2)
        c.set2 = parseFloat(c.set2)
        c.ott2 = parseFloat(c.ott2)
        c.nov2 = parseFloat(c.nov2)
        c.dic2 = parseFloat(c.dic2)
        total.tot1 += c.tot1
        total.tot2 += c.tot2
        total.gen1 += c.gen1
        total.feb1 += c.feb1
        total.mar1 += c.mar1
        total.apr1 += c.apr1
        total.mag1 += c.mag1
        total.giu1 += c.giu1
        total.lug1 += c.lug1
        total.ago1 += c.ago1
        total.set1 += c.set1
        total.ott1 += c.ott1
        total.nov1 += c.nov1
        total.dic1 += c.dic1
        total.gen2 += c.gen2
        total.feb2 += c.feb2
        total.mar2 += c.mar2
        total.apr2 += c.apr2
        total.mag2 += c.mag2
        total.giu2 += c.giu2
        total.lug2 += c.lug2
        total.ago2 += c.ago2
        total.set2 += c.set2
        total.ott2 += c.ott2
        total.nov2 += c.nov2
        total.dic2 += c.dic2

        clients.tot1 += (c.tot1 > 0) ? 1 : 0
        clients.tot2 += (c.tot2 > 0) ? 1 : 0
        clients.gen1 += (c.gen1 > 0) ? 1 : 0
        clients.feb1 += (c.feb1 > 0) ? 1 : 0
        clients.mar1 += (c.mar1 > 0) ? 1 : 0
        clients.apr1 += (c.apr1 > 0) ? 1 : 0
        clients.mag1 += (c.mag1 > 0) ? 1 : 0
        clients.giu1 += (c.giu1 > 0) ? 1 : 0
        clients.lug1 += (c.lug1 > 0) ? 1 : 0
        clients.ago1 += (c.ago1 > 0) ? 1 : 0
        clients.set1 += (c.set1 > 0) ? 1 : 0
        clients.ott1 += (c.ott1 > 0) ? 1 : 0
        clients.nov1 += (c.nov1 > 0) ? 1 : 0
        clients.dic1 += (c.dic1 > 0) ? 1 : 0
        clients.gen2 += (c.gen2 > 0) ? 1 : 0
        clients.feb2 += (c.feb2 > 0) ? 1 : 0
        clients.mar2 += (c.mar2 > 0) ? 1 : 0
        clients.apr2 += (c.apr2 > 0) ? 1 : 0
        clients.mag2 += (c.mag2 > 0) ? 1 : 0
        clients.giu2 += (c.giu2 > 0) ? 1 : 0
        clients.lug2 += (c.lug2 > 0) ? 1 : 0
        clients.ago2 += (c.ago2 > 0) ? 1 : 0
        clients.set2 += (c.set2 > 0) ? 1 : 0
        clients.ott2 += (c.ott2 > 0) ? 1 : 0
        clients.nov2 += (c.nov2 > 0) ? 1 : 0
        clients.dic2 += (c.dic2 > 0) ? 1 : 0
      })
      this.filteredReport = this.reportFatturatoCliente.filter(() => true)
      // this.reportFatturatoCliente.unshift(total)
      this.filteredReport.unshift(total)
      this.filteredReport.unshift(clients)
      await this.groupByArea()
      this.isLoading = false
    },

    async doFilter () {
      let areasSubAgentFilter = null
      if ((this.filter.selectedAgent ?? '') !== '') {
        this.isFiltering = true
        areasSubAgentFilter = await this.$store.dispatch('agents/areasVisibleToSubAgent', this.filter.selectedAgent)
        this.isFiltering = false
      }
      const total = {
        area: this.$t('messages.Totale'),
        isVisitedLastThreeMonths: true,
        cd_agent: '(€)',
        tot2: 0,
        tot1: 0,
        gen1: 0,
        feb1: 0,
        mar1: 0,
        apr1: 0,
        mag1: 0,
        giu1: 0,
        lug1: 0,
        ago1: 0,
        set1: 0,
        ott1: 0,
        nov1: 0,
        dic1: 0,
        gen2: 0,
        feb2: 0,
        mar2: 0,
        apr2: 0,
        mag2: 0,
        giu2: 0,
        lug2: 0,
        ago2: 0,
        set2: 0,
        ott2: 0,
        nov2: 0,
        dic2: 0
      }
      const clients = {
        area: this.$t('messages.Serviti'),
        isVisitedLastThreeMonths: true,
        cd_agent: '(n)',
        tot2: 0,
        tot1: 0,
        gen1: 0,
        feb1: 0,
        mar1: 0,
        apr1: 0,
        mag1: 0,
        giu1: 0,
        lug1: 0,
        ago1: 0,
        set1: 0,
        ott1: 0,
        nov1: 0,
        dic1: 0,
        gen2: 0,
        feb2: 0,
        mar2: 0,
        apr2: 0,
        mag2: 0,
        giu2: 0,
        lug2: 0,
        ago2: 0,
        set2: 0,
        ott2: 0,
        nov2: 0,
        dic2: 0
      }
      this.filteredReport = this.reportFatturatoCliente.filter(c => {
        const isFiltered = ((this.filter.selectedArea ?? '') === '' || c.cd_agent === this.filter.selectedArea) &&
          ((this.filter.selectedMicroArea ?? '') === '' || c.microarea === this.filter.selectedMicroArea) &&
          ((this.filter.selectedAgent ?? '') === '' || areasSubAgentFilter.some((area) => { return c.cd_agent === area.cd_agent }))
        if (isFiltered) {
          total.tot1 += c.tot1
          total.tot2 += c.tot2
          total.gen1 += c.gen1
          total.feb1 += c.feb1
          total.mar1 += c.mar1
          total.apr1 += c.apr1
          total.mag1 += c.mag1
          total.giu1 += c.giu1
          total.lug1 += c.lug1
          total.ago1 += c.ago1
          total.set1 += c.set1
          total.ott1 += c.ott1
          total.nov1 += c.nov1
          total.dic1 += c.dic1
          total.gen2 += c.gen2
          total.feb2 += c.feb2
          total.mar2 += c.mar2
          total.apr2 += c.apr2
          total.mag2 += c.mag2
          total.giu2 += c.giu2
          total.lug2 += c.lug2
          total.ago2 += c.ago2
          total.set2 += c.set2
          total.ott2 += c.ott2
          total.nov2 += c.nov2
          total.dic2 += c.dic2
          clients.tot1 += (c.tot1 > 0) ? 1 : 0
          clients.tot2 += (c.tot2 > 0) ? 1 : 0
          clients.gen1 += (c.gen1 > 0) ? 1 : 0
          clients.feb1 += (c.feb1 > 0) ? 1 : 0
          clients.mar1 += (c.mar1 > 0) ? 1 : 0
          clients.apr1 += (c.apr1 > 0) ? 1 : 0
          clients.mag1 += (c.mag1 > 0) ? 1 : 0
          clients.giu1 += (c.giu1 > 0) ? 1 : 0
          clients.lug1 += (c.lug1 > 0) ? 1 : 0
          clients.ago1 += (c.ago1 > 0) ? 1 : 0
          clients.set1 += (c.set1 > 0) ? 1 : 0
          clients.ott1 += (c.ott1 > 0) ? 1 : 0
          clients.nov1 += (c.nov1 > 0) ? 1 : 0
          clients.dic1 += (c.dic1 > 0) ? 1 : 0
          clients.gen2 += (c.gen2 > 0) ? 1 : 0
          clients.feb2 += (c.feb2 > 0) ? 1 : 0
          clients.mar2 += (c.mar2 > 0) ? 1 : 0
          clients.apr2 += (c.apr2 > 0) ? 1 : 0
          clients.mag2 += (c.mag2 > 0) ? 1 : 0
          clients.giu2 += (c.giu2 > 0) ? 1 : 0
          clients.lug2 += (c.lug2 > 0) ? 1 : 0
          clients.ago2 += (c.ago2 > 0) ? 1 : 0
          clients.set2 += (c.set2 > 0) ? 1 : 0
          clients.ott2 += (c.ott2 > 0) ? 1 : 0
          clients.nov2 += (c.nov2 > 0) ? 1 : 0
          clients.dic2 += (c.dic2 > 0) ? 1 : 0
        }
        return isFiltered
      })
      this.filteredReport.unshift(total)
      this.filteredReport.unshift(clients)
    },
    getArea (cdAgent) {
      return this.areaDefinition[cdAgent]?.nome
      // this.reportFatturatoCliente = await this.$store.dispatch('reports/getReportFatturatoCliente')
    },
    async groupByArea () {
      await this.doFilter()
      if (this.groupedByArea) {
        this.filteredReport = this.filteredReport.reduce((cg, c) => {
          if (cg.currentGroupedHash[c.cd_agent]) {
            cg.currentGroupedHash[c.cd_agent].gen1 += parseFloat(c.gen1)
            cg.currentGroupedHash[c.cd_agent].feb1 += parseFloat(c.feb1)
            cg.currentGroupedHash[c.cd_agent].mar1 += parseFloat(c.mar1)
            cg.currentGroupedHash[c.cd_agent].apr1 += parseFloat(c.apr1)
            cg.currentGroupedHash[c.cd_agent].mag1 += parseFloat(c.mag1)
            cg.currentGroupedHash[c.cd_agent].giu1 += parseFloat(c.giu1)
            cg.currentGroupedHash[c.cd_agent].lug1 += parseFloat(c.lug1)
            cg.currentGroupedHash[c.cd_agent].ago1 += parseFloat(c.ago1)
            cg.currentGroupedHash[c.cd_agent].set1 += parseFloat(c.set1)
            cg.currentGroupedHash[c.cd_agent].ott1 += parseFloat(c.ott1)
            cg.currentGroupedHash[c.cd_agent].nov1 += parseFloat(c.nov1)
            cg.currentGroupedHash[c.cd_agent].dic1 += parseFloat(c.dic1)
            cg.currentGroupedHash[c.cd_agent].gen2 += parseFloat(c.gen2)
            cg.currentGroupedHash[c.cd_agent].feb2 += parseFloat(c.feb2)
            cg.currentGroupedHash[c.cd_agent].mar2 += parseFloat(c.mar2)
            cg.currentGroupedHash[c.cd_agent].apr2 += parseFloat(c.apr2)
            cg.currentGroupedHash[c.cd_agent].mag2 += parseFloat(c.mag2)
            cg.currentGroupedHash[c.cd_agent].giu2 += parseFloat(c.giu2)
            cg.currentGroupedHash[c.cd_agent].lug2 += parseFloat(c.lug2)
            cg.currentGroupedHash[c.cd_agent].ago2 += parseFloat(c.ago2)
            cg.currentGroupedHash[c.cd_agent].set2 += parseFloat(c.set2)
            cg.currentGroupedHash[c.cd_agent].ott2 += parseFloat(c.ott2)
            cg.currentGroupedHash[c.cd_agent].nov2 += parseFloat(c.nov2)
            cg.currentGroupedHash[c.cd_agent].dic2 += parseFloat(c.dic2)
            cg.currentGroupedHash[c.cd_agent].tot1 += parseFloat(c.tot1)
            cg.currentGroupedHash[c.cd_agent].tot2 += parseFloat(c.tot2)
          } else {
            cg.currentGroupedHash[c.cd_agent] = {}
            Object.assign(cg.currentGroupedHash[c.cd_agent], c)
            cg.currentGroupedHash[c.cd_agent].last_visit_date = null
            cg.currentGroupedArray.push(cg.currentGroupedHash[c.cd_agent])
            cg.currentGroupedHash[c.cd_agent].cd_clien = ''
            cg.currentGroupedHash[c.cd_agent].azienda = ''
            cg.currentGroupedHash[c.cd_agent].isVisitedLastThreeMonths = true
          }
          return cg
        }, { currentGroupedHash: [], currentGroupedArray: [] }).currentGroupedArray
      } else {
        // this.filteredReport = this.filteredReport
      }
    },
    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    }
  }
}
</script>

<style scoped>
::v-deep.v-data-table > .v-data-table__wrapper > table > thead > tr > th, ::v-deep.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0px;
  padding-right: 4px;
  padding-left: 4px;
}
.reportval {
  font-size: 0.665rem;
}
.reportval.highlight {
  font-weight: bold;
  font-size: 0.875rem;
}
.reportval.highlight.reportval-negative {
  color: red
}
::v-deep.v-data-table table th:first-child {
  position: sticky;
  left: 0;
  background-color: #ddd;
  margin-right: 4px;
  padding-right: 4px;
  max-width: 160px;
  width: 120px;
}
::v-deep.v-data-table table th:nth-child(3) {
  background-color: #eaeaea;
}
::v-deep.v-data-table table td:first-child {
  position: sticky;
  left: 0;
  background-color: #ddd;
  margin-right: 4px;
  padding-right: 4px;
  min-width: 120px;
  max-width: 160px;
}
.report-azienda {
  font-size: 0.665rem;
  font-weight: bold;
}
.report-cdagent {
  font-size: 0.665rem;
  font-weight: bold;
}
.report-cdclien {
  font-size: 0.665rem;
  font-weight: bold;
}
::v-deep.v-data-table table td:nth-child(2n+3) {background: #e6e6e6}

::v-deep.v-data-table table th:nth-child(6) {
  max-width: 66px;
  min-width: 66px;
  width: 66px;
}
::v-deep.v-data-table table td:nth-child(6) {
  min-width: 66px;
  max-width: 66px;
  width: 66px;
}
</style>
