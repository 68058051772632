var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(_vm.isInitializing != 0)?_c('v-progress-circular',{staticClass:"ml-3",attrs:{"indeterminate":"","color":"primary"}}):_c('div',[_c('v-btn',{staticClass:"btn btn-lg",class:_vm.$vuetify.breakpoint.smAndDown ? 'mt-1' : 'ml-2',attrs:{"color":"#217346","dark":"","type":"button","elevation":"0","tile":"","loading":_vm.isLoadingDatabase},on:{"click":_vm.doSyncDatabase}},[_vm._v(" "+_vm._s(_vm.$t('messages.Sync Users'))+" ")]),_c('v-data-table',{attrs:{"search":_vm.search,"items":_vm.users,"mobile-breakpoint":0,"headers":_vm.headers,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":_vm.$t('messages.Ricerca')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.is_agent",fn:function(ref){
var item = ref.item;
return [(item.is_agent == 1)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-badge-account-horizontal ")]):_vm._e(),(item.is_agent == 1 && item.is_agent_admin == 1)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-shield-star ")]):_vm._e()]}},{key:"item.listini",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{attrs:{"value":item.listini ? item.listini.split(';'):[],"items":_vm.listini,"loading":_vm.isLoadingListini || ((item.isUpdatingListini) > 0),"menu-props":{
            maxHeight:200,
          },"hide-details":"","multiple":"","dense":"","label":"","item-value":"id","item-text":"name"},on:{"input":function($event){return _vm.updateAgentListino(item, $event)}},scopedSlots:_vm._u([{key:"item",fn:function(appearance){return [_vm._v(" "+_vm._s(appearance.item.name)+" ")]}},{key:"selection",fn:function(selection){return [_c('v-chip',{staticClass:"mx-1 white--text",attrs:{"color":"success","small":""}},[_vm._v(" "+_vm._s(selection.item.name)+" ")])]}}],null,true)})]}},{key:"item.nations",fn:function(ref){
          var item = ref.item;
return [_c('v-autocomplete',{attrs:{"value":item.nations ? item.nations.split(';'):[],"items":_vm.nations,"loading":_vm.isLoadingNations || ((item.isUpdating) > 0),"menu-props":{
            maxHeight:200,
          },"hide-details":"","multiple":"","dense":"","label":"","item-value":"isocode","item-text":"name"},on:{"input":function($event){return _vm.updateAgentNation(item, $event)}},scopedSlots:_vm._u([{key:"item",fn:function(appearance){return [_vm._v(" "+_vm._s(appearance.item.name)+" ")]}},{key:"selection",fn:function(selection){return [_c('v-chip',{staticClass:"mx-1 white--text",attrs:{"color":"success","small":""}},[_vm._v(" "+_vm._s(selection.item.name)+" ")])]}}],null,true)})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(item.is_agent == 1 && item.is_agent_admin == 0)?_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.removeAgent(item)}}},[_vm._v(" mdi-account-remove ")]):_vm._e(),(item.is_agent == 0)?_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addAgent(item)}}},[_vm._v(" mdi-account-plus ")]):_vm._e(),(item.is_agent == 1 && item.is_agent_admin == 0)?_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.upgradeAgent(item)}}},[_vm._v(" mdi-chevron-double-up ")]):_vm._e(),(item.is_agent == 1 && item.is_agent_admin == 1)?_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.downgradeAgent(item)}}},[_vm._v(" mdi-chevron-double-down ")]):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }