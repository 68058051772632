import axios from 'axios'

export default {
  getCloseClients (data) {
    return axios.post('/api/clients/closeby', data)
  },
  getClientsByArea (data) {
    return axios.post('/api/clients/map/area/' + data)
  },
  saveNewPosition (data) {
    return axios.post('/api/clients/set-position', data)
  },
  getClientAddress (data) {
    return axios.post('/api/clients/get-favourite-address', data)
  }
}
