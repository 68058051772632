import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Login from '../views/Login'
import store from '../store'
import AgentAssign from '../views/admin/AgentAssign'
import AreaAssign from '../views/admin/AreaAssign'
import ClassesAssign from '../views/admin/ClassesAssign'
import JoinProjxClients from '../views/admin/JoinProjxClients'
import CampionatureProdottiAssign from '../views/admin/CampionatureProdottiAssign'
import ClientList from '../views/ClientList'
import Feedbacks from '../views/Feedbacks'
import FeedbackInsert from '../views/FeedbackInsert'
import Gallery from '../views/Gallery'
import GalleryInsert from '../views/GalleryInsert'
import ClientDetails from '../views/ClientDetails'
import ClientsMap from '../views/ClientsMap'
import ReportClientSales from '../views/reports/ReportClientSales'
import ReportColorSales from '../views/reports/ReportColorSales'
import ReportClientCompact from '../views/reports/ReportClientCompact'
import ReportClientCompactColor from '../views/reports/ReportClientCompactColor'
import ReportClientVisits from '../views/reports/ReportClientVisits'
import ReportBroad from '../views/reports/ReportBroad'
import ReportVisitRatings from '../views/reports/ReportVisitRatings'
import OrderInsert from '../views/OrderInsert'
import OrderCampionatura from '../views/OrderCampionatura'
import Listini from '../views/Listini'
import MicroareaList from '../views/MicroareaList'
import ViewOpenDues from '../views/ViewOpenDues'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/home', component: Home, meta: { requiresAuth: true, title: 'Estelio' } },
    { path: '/login', component: Login, meta: { title: 'Login' } },
    { path: '/listini', component: Listini, meta: { requiresAuth: true, title: 'Listini' } },
    { path: '/clientlist', component: ClientList, meta: { requiresAuth: true, title: 'Lista Clienti' } },
    { path: '/client/details', component: ClientDetails, meta: { requiresAuth: true, title: 'Nuovo Cliente' } },
    { path: '/client/details/:clientId', component: ClientDetails, meta: { requiresAuth: true, title: 'Dettagli Cliente' } },
    { path: '/agent-assign', component: AgentAssign, meta: { requiresAuth: true, title: 'Imposta Agenti' } },
    { path: '/area-assign', component: AreaAssign, meta: { requiresAuth: true, title: 'Assegna Aree' } },
    { path: '/classes-assign', component: ClassesAssign, meta: { requiresAuth: true, title: 'Gestione Classi' } },
    { path: '/projx-join-clients', component: JoinProjxClients, meta: { requiresAuth: true, title: 'Trasferimento Feedbacks Projx' } },
    { path: '/campionature-assign', component: CampionatureProdottiAssign, meta: { requiresAuth: true, title: 'Gestione Campionature' } },
    { path: '/feedbacks/:clientId?', component: Feedbacks, meta: { requiresAuth: true, title: 'Consulta Feedbacks' } },
    { path: '/feedbackinsert', component: FeedbackInsert, meta: { requiresAuth: true, title: 'Nuovo Feedback' } },
    { path: '/feedbackinsert/client/:clientId?', component: FeedbackInsert, meta: { requiresAuth: true, title: 'Nuovo Feedback' } },
    { path: '/feedbackinsert/edit/:feedbackId?', component: FeedbackInsert, meta: { requiresAuth: true, title: 'Modifica Feedback' } },
    { path: '/gallery', component: Gallery, meta: { requiresAuth: true, title: 'Consulta Gallery' } },
    { path: '/galleryinsert/:clientId?', component: GalleryInsert, meta: { requiresAuth: true, title: 'Inserimento Immagini' } },
    { path: '/clientsmap', component: ClientsMap, meta: { requiresAuth: true, title: 'Mappa' } },
    { path: '/reports/client-sales', component: ReportClientSales, meta: { requiresAuth: true, title: 'Fatturato Cliente' } },
    { path: '/reports/client-compact', component: ReportClientCompact, meta: { requiresAuth: true, title: 'Cliente Compatta' } },
    { path: '/reports/client-compact-color', component: ReportClientCompactColor, meta: { requiresAuth: true, title: 'Cliente Compatta2' } },
    { path: '/reports/color-sales', component: ReportColorSales, meta: { requiresAuth: true, title: 'Fatturato Colore' } },
    { path: '/reports/client-visits', component: ReportClientVisits, meta: { requiresAuth: true, title: 'Visite Cliente' } },
    { path: '/reports/visit-ratings', component: ReportVisitRatings, meta: { requiresAuth: true, title: 'Rating Visite' } },
    { path: '/reports/agent-broad', component: ReportBroad, meta: { requiresAuth: true, title: 'Agente Esteso' } },
    { path: '/orderinsert/:clientId?', component: OrderInsert, meta: { requiresAuth: true, title: 'Nuovo Ordine' } },
    { path: '/ordercampionatura', component: OrderCampionatura, meta: { requiresAuth: true, title: 'Nuovo Ordine Campionatura' } },
    { path: '/microaree', component: MicroareaList, meta: { requiresAuth: true, title: 'Microaree' } },
    { path: '/scadenze-aperte', component: ViewOpenDues, meta: { requiresAuth: true, title: 'Scadenze Aperte' } },
    { path: '*', redirect: '/home' }
  ]
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters['security/isAuthenticated']) {
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
