import AgentsAPI from '../api/agents'

const FETCHING_USERS = 'FETCHING_USERS'
const FETCHING_USERS_SUCCESS = 'FETCHING_USERS_SUCCESS'
const FETCHING_USERS_ERROR = 'FETCHING_USERS_ERROR'

const SAVING_TREE = 'SAVING_TREE'
const SAVING_TREE_SUCCESS = 'SAVING_TREE_SUCCESS'
const SAVING_TREE_ERROR = 'SAVING_TREE_ERROR'

const AGENTS_LIST = 'AGENTS_LIST'
const AGENTS_LIST_SUCCESS = 'AGENTS_LIST_SUCCESS'
const AGENTS_LIST_ERROR = 'AGENTS_LIST_ERROR'

const AGENTS_VISIBLE_TO_AGENT_LIST = 'AGENTS_VISIBLE_TO_AGENT_LIST'
const AGENTS_VISIBLE_TO_AGENT_LIST_SUCCESS = 'AGENTS_VISIBLE_TO_AGENT_LIST_SUCCESS'
const AGENTS_VISIBLE_TO_AGENT_LIST_ERROR = 'AGENTS_VISIBLE_TO_AGENT_LIST_ERROR'

const GALLERY_AGENTS_LIST = 'GALLERY_AGENTS_LIST'
const GALLERY_AGENTS_LIST_SUCCESS = 'GALLERY_AGENTS_LIST_SUCCESS'
const GALLERY_AGENTS_LIST_ERROR = 'GALLERY_AGENTS_LIST_ERROR'

const NATIONS_VISIBLE_TO_AGENT_LIST = 'NATIONS_VISIBLE_TO_AGENT_LIST'
const NATIONS_VISIBLE_TO_AGENT_LIST_SUCCESS = 'NATIONS_VISIBLE_TO_AGENT_LIST_SUCCESS'
const NATIONS_VISIBLE_TO_AGENT_LIST_ERROR = 'NATIONS_VISIBLE_TO_AGENT_LIST_ERROR'

const MICROAREAS_VISIBLE_TO_AGENT_LIST = 'MICROAREAS_VISIBLE_TO_AGENT_LIST'
const MICROAREAS_VISIBLE_TO_AGENT_LIST_SUCCESS = 'MICROAREAS_VISIBLE_TO_AGENT_LIST_SUCCESS'
const MICROAREAS_VISIBLE_TO_AGENT_LIST_ERROR = 'MICROAREAS_VISIBLE_TO_AGENT_LIST_ERROR'

const AREAS_VISIBLE_TO_AGENT_LIST = 'AREAS_VISIBLE_TO_AGENT_LIST'
const AREAS_VISIBLE_TO_AGENT_LIST_SUCCESS = 'AREAS_VISIBLE_TO_AGENT_LIST_SUCCESS'
const AREAS_VISIBLE_TO_AGENT_LIST_ERROR = 'AREAS_VISIBLE_TO_AGENT_LIST_ERROR'

const UPDATED_LANGUAGE = 'UPDATED_LANGUAGE'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    users: [],
    agentsList: [],
    agentsVisibleToAgent: [],
    galleryAgents: [],
    nationsVisibleToAgent: [],
    microAreasVisibleToAgent: [],
    areasVisibleToAgent: []
  },
  getters: {
    agentsVisibleToAgent (state) {
      return state.agentsVisibleToAgent
    },
    galleryAgents (state) {
      return state.galleryAgents
    },
    nationsVisibleToAgent (state) {
      return state.nationsVisibleToAgent
    },
    microAreasVisibleToAgent (state) {
      return state.microAreasVisibleToAgent
    },
    areasVisibleToAgent (state) {
      return state.areasVisibleToAgent
    },
    agentsList (state) {
      return state.agentsList
    },
    isLoading (state) {
      return state.isLoading
    },
    hasError (state) {
      return state.error !== null
    },
    users (state) {
      return state.users
    },
    error (state) {
      return state.error
    },
    errorMessage (state) {
      return state.error !== null ? state.error.response.data.detail : null
    }
  },
  mutations: {
    [FETCHING_USERS] (state) {
      state.isLoading = true
      state.users = []
      state.error = null
    },
    [FETCHING_USERS_SUCCESS] (state, users) {
      state.error = null
      state.users = users
      state.isLoading = false
    },
    [FETCHING_USERS_ERROR] (state, error) {
      state.isLoading = false
      state.users = []
      state.error = error
    },
    [SAVING_TREE] (state) {
      state.isLoading = true
      state.error = null
    },
    [SAVING_TREE_SUCCESS] (state) {
      state.error = null
      state.isLoading = false
    },
    [SAVING_TREE_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [AGENTS_LIST] (state) {
      state.isLoading = true
      state.agentsList = []
      state.error = null
    },
    [AGENTS_LIST_SUCCESS] (state, data) {
      state.error = null
      state.agentsList = data.agents
      state.isLoading = false
    },
    [AGENTS_LIST_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [AGENTS_VISIBLE_TO_AGENT_LIST] (state) {
      state.isLoading = true
      state.agentsVisibleToAgent = []
      state.error = null
    },
    [AGENTS_VISIBLE_TO_AGENT_LIST_SUCCESS] (state, data) {
      state.error = null
      state.agentsVisibleToAgent = data
      state.isLoading = false
    },
    [AGENTS_VISIBLE_TO_AGENT_LIST_ERROR] (state, error) {
      state.agentsVisibleToAgent = false
      state.error = error
    },
    [GALLERY_AGENTS_LIST] (state) {
      state.isLoading = true
      state.galleryAgents = []
      state.error = null
    },
    [GALLERY_AGENTS_LIST_SUCCESS] (state, data) {
      state.error = null
      state.galleryAgents = data
      state.isLoading = false
    },
    [GALLERY_AGENTS_LIST_ERROR] (state, error) {
      state.galleryAgents = []
      state.error = error
    },
    [NATIONS_VISIBLE_TO_AGENT_LIST] (state) {
      state.isLoading = true
      state.nationsVisibleToAgent = []
      state.error = null
    },
    [NATIONS_VISIBLE_TO_AGENT_LIST_SUCCESS] (state, data) {
      state.error = null
      state.nationsVisibleToAgent = data
      state.isLoading = false
    },
    [NATIONS_VISIBLE_TO_AGENT_LIST_ERROR] (state, error) {
      state.nationsVisibleToAgent = false
      state.error = error
    },
    [MICROAREAS_VISIBLE_TO_AGENT_LIST] (state) {
      state.isLoading = true
      state.microAreasVisibleToAgent = []
      state.error = null
    },
    [MICROAREAS_VISIBLE_TO_AGENT_LIST_SUCCESS] (state, data) {
      state.error = null
      state.microAreasVisibleToAgent = data
      state.isLoading = false
    },
    [MICROAREAS_VISIBLE_TO_AGENT_LIST_ERROR] (state, error) {
      state.microAreasVisibleToAgent = false
      state.error = error
    },
    [AREAS_VISIBLE_TO_AGENT_LIST] (state) {
      state.isLoading = true
      state.areasVisibleToAgent = []
      state.error = null
    },
    [AREAS_VISIBLE_TO_AGENT_LIST_SUCCESS] (state, data) {
      state.error = null
      state.areasVisibleToAgent = data
      state.isLoading = false
    },
    [AREAS_VISIBLE_TO_AGENT_LIST_ERROR] (state, error) {
      state.areasVisibleToAgent = false
      state.error = error
    },
    [UPDATED_LANGUAGE] (state) {
      state.error = null
    }
  },

  actions: {
    async saveTree ({ commit }, data) {
      commit(SAVING_TREE)
      try {
        const response = await AgentsAPI.saveTree(data)
        commit(SAVING_TREE_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(SAVING_TREE_ERROR, error)
        return null
      }
    },
    async listUsers ({ commit }) {
      commit(FETCHING_USERS)
      try {
        const response = await AgentsAPI.listUsers()
        commit(FETCHING_USERS_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_USERS_ERROR, error)
        return null
      }
    },
    async setUserAgent ({ commit }, data) {
      try {
        const response = await AgentsAPI.setUserAgent(data)
        return response.data
      } catch (error) {
        return null
      }
    },
    async setNations ({ commit }, data) {
      try {
        const response = await AgentsAPI.setNations(data)
        return response.data
      } catch (error) {
        return null
      }
    },
    async setListini ({ commit }, data) {
      try {
        const response = await AgentsAPI.setListini(data)
        return response.data
      } catch (error) {
        return null
      }
    },
    async listAgents ({ commit }) {
      commit(AGENTS_LIST)
      try {
        const response = await AgentsAPI.listAgents()
        commit(AGENTS_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(AGENTS_LIST_ERROR, error)
        return null
      }
    },
    async listAreaAgents ({ commit }) {
      try {
        const response = await AgentsAPI.listAreaAgents()
        return response.data
      } catch (error) {
        return null
      }
    },
    async listAreaDefinition ({ commit }) {
      try {
        const response = await AgentsAPI.listAreaDefinition()
        return response.data
      } catch (error) {
        return null
      }
    },
    async nationsVisibleToAgent ({ commit, state }) {
      if (state.nationsVisibleToAgent.length > 0) {
        return state.nationsVisibleToAgent
      }
      commit(NATIONS_VISIBLE_TO_AGENT_LIST)
      try {
        const response = await AgentsAPI.nationsVisibleToAgent()
        commit(NATIONS_VISIBLE_TO_AGENT_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(NATIONS_VISIBLE_TO_AGENT_LIST_ERROR, error)
        return null
      }
    },
    async microAreasVisibleToAgent ({ commit, state }) {
      /* if (state.microAreasVisibleToAgent.length > 0) {
        return state.microAreasVisibleToAgent
      } */
      commit(MICROAREAS_VISIBLE_TO_AGENT_LIST)
      try {
        const response = await AgentsAPI.microAreasVisibleToAgent()
        commit(MICROAREAS_VISIBLE_TO_AGENT_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(MICROAREAS_VISIBLE_TO_AGENT_LIST_ERROR, error)
        return null
      }
    },
    async areasVisibleToAgent ({ commit, state }) { // Aree visibili da me
      if (state.areasVisibleToAgent.length > 0) {
        return state.areasVisibleToAgent
      }
      commit(AREAS_VISIBLE_TO_AGENT_LIST)
      try {
        const response = await AgentsAPI.areasVisibleToAgent()
        commit(AREAS_VISIBLE_TO_AGENT_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(AREAS_VISIBLE_TO_AGENT_LIST_ERROR, error)
        return null
      }
    },
    async agentShippingAddress () { // Indirizzo spedizione agente corrente
      try {
        const response = await AgentsAPI.agentShippingAddress()
        return response.data
      } catch (error) {
        return null
      }
    },
    async areasVisibleToSubAgent ({ commit, state }, agentId) { // arree visibili ad uno dei sottoagenti (verifica se il sottoagente è diretto sottoagente del richiedente)
      try {
        const response = await AgentsAPI.areasVisibleToSubAgent(agentId)
        return response.data
      } catch (error) {
        return null
      }
    },
    async agentsVisibleToAgent ({ commit, state }) {
      if (state.agentsVisibleToAgent.length > 0) {
        return state.agentsVisibleToAgent
      }
      commit(AGENTS_VISIBLE_TO_AGENT_LIST)
      try {
        const response = await AgentsAPI.agentsVisibleToAgent()
        commit(AGENTS_VISIBLE_TO_AGENT_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(AGENTS_VISIBLE_TO_AGENT_LIST_ERROR, error)
        return null
      }
    },
    async galleryAgents ({ commit, state }) {
      if (state.galleryAgents.length > 0) {
        return state.galleryAgents
      }
      commit(GALLERY_AGENTS_LIST)
      try {
        const response = await AgentsAPI.galleryAgents()
        commit(GALLERY_AGENTS_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(GALLERY_AGENTS_LIST_ERROR, error)
        return null
      }
    },
    async editMicroArea ({ commit }, data) {
      try {
        const response = await AgentsAPI.editMicroArea(data)
        commit(MICROAREAS_VISIBLE_TO_AGENT_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        return null
      }
    },
    async addMicroArea ({ commit }, data) {
      try {
        const response = await AgentsAPI.addMicroArea(data)
        commit(MICROAREAS_VISIBLE_TO_AGENT_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        return null
      }
    },
    async deleteMicroArea ({ commit }, data) {
      try {
        const response = await AgentsAPI.deleteMicroArea(data)
        commit(MICROAREAS_VISIBLE_TO_AGENT_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        return null
      }
    },
    async setLanguage ({ commit }, data) {
      try {
        const response = await AgentsAPI.setLanguage(data)
        commit(UPDATED_LANGUAGE, response.data)
        return response.data
      } catch (error) {
        return null
      }
    }
  }
}
