import FeedbackAPI from '../api/feedback'

const FETCHING_FEEDBACK_LIST = 'FETCHING_FEEDBACK_LIST'
const FETCHING_FEEDBACK_LIST_SUCCESS = 'FETCHING_FEEDBACK_LIST_SUCCESS'
const FETCHING_FEEDBACK_LIST_ERROR = 'FETCHING_FEEDBACK_LIST_ERROR'

const FETCHING_FEEDBACKDETAILS_LIST = 'FETCHING_FEEDBACKDETAILS_LIST'
const FETCHING_FEEDBACKDETAILS_LIST_SUCCESS = 'FETCHING_FEEDBACKDETAILS_LIST_SUCCESS'
const FETCHING_FEEDBACKDETAILS_LIST_ERROR = 'FETCHING_FEEDBACKDETAILS_LIST_ERROR'

const FETCHING_FEEDBACK_SEARCH = 'FETCHING_FEEDBACK_SEARCH'
const FETCHING_FEEDBACK_SEARCH_SUCCESS = 'FETCHING_FEEDBACK_SEARCH_SUCCESS'
const FETCHING_FEEDBACK_SEARCH_ERROR = 'FETCHING_FEEDBACK_SEARCH_ERROR'

const UPLOADING_FEEDBACK = 'UPLOADING_FEEDBACK'
const UPLOADING_FEEDBACK_SUCCESS = 'UPLOADING_FEEDBACK_SUCCESS'
const UPLOADING_FEEDBACK_ERROR = 'UPLOADING_FEEDBACK_ERROR'

const EDITING_FEEDBACK = 'EDITING_FEEDBACK'
const EDITING_FEEDBACK_SUCCESS = 'EDITING_FEEDBACK_SUCCESS'
const EDITING_FEEDBACK_ERROR = 'EDITING_FEEDBACK_ERROR'

const DELETING_OWN_FEEDBACK = 'DELETING_OWN_FEEDBACK'
const DELETING_OWN_FEEDBACK_SUCCESS = 'DELETING_OWN_FEEDBACK_SUCCESS'
const DELETING_OWN_FEEDBACK_ERROR = 'DELETING_OWN_FEEDBACK_ERROR'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    feedbackList: [],
    feedbackDetails: null,
    feedbacks: []
  },
  getters: {
    feedbackList (state) {
      return state.feedbackList
    },
    feedbackDetails (state) {
      return state.feedbackDetails
    },
    feedbacks (state) {
      return state.feedback
    },
    isLoading (state) {
      return state.isLoading
    },
    hasError (state) {
      return state.error !== null
    },
    error (state) {
      return state.error
    },
    errorMessage (state) {
      return state.error.response.data.detail
    }
  },
  mutations: {
    [FETCHING_FEEDBACK_LIST] (state) {
      state.isLoading = true
      state.feedbackList = []
      state.error = null
    },
    [FETCHING_FEEDBACK_LIST_SUCCESS] (state, data) {
      state.isLoading = false
      state.feedbackList = data.feedbackList
      state.error = null
    },
    [FETCHING_FEEDBACK_LIST_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [FETCHING_FEEDBACKDETAILS_LIST] (state) {
      state.isLoading = true
      state.error = null
    },
    [FETCHING_FEEDBACKDETAILS_LIST_SUCCESS] (state) {
      state.error = null
      state.isLoading = false
    },
    [FETCHING_FEEDBACKDETAILS_LIST_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [UPLOADING_FEEDBACK] (state) {
      state.isLoading = true
      state.feedbacks = []
      state.error = null
    },
    [UPLOADING_FEEDBACK_SUCCESS] (state, data) {
      state.isLoading = false
      state.feedbacks.push(data)
      state.error = null
    },
    [UPLOADING_FEEDBACK_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [EDITING_FEEDBACK] (state) {
      state.isLoading = true
      state.feedbacks = []
      state.error = null
    },
    [EDITING_FEEDBACK_SUCCESS] (state, data) {
      state.isLoading = false
      state.feedbacks.push(data)
      state.error = null
    },
    [EDITING_FEEDBACK_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [FETCHING_FEEDBACK_SEARCH] (state) {
      state.isLoading = true
      state.feedbackList = []
      state.error = null
    },
    [FETCHING_FEEDBACK_SEARCH_SUCCESS] (state, data) {
      state.isLoading = false
      state.feedbackList = data
      state.error = null
    },
    [FETCHING_FEEDBACK_SEARCH_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [DELETING_OWN_FEEDBACK] (state) {
      state.isLoading = true
      state.error = null
    },
    [DELETING_OWN_FEEDBACK_SUCCESS] (state) {
      state.isLoading = false
      state.error = null
    },
    [DELETING_OWN_FEEDBACK_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    }
  },

  actions: {
    async feedbackList ({ commit }) {
      commit(FETCHING_FEEDBACK_LIST)
      try {
        const response = await FeedbackAPI.feedbackList()
        commit(FETCHING_FEEDBACK_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_FEEDBACK_LIST_ERROR, error)
        return null
      }
    },
    async search ({ commit }, data) {
      commit(FETCHING_FEEDBACK_SEARCH)
      try {
        const response = await FeedbackAPI.search(data)
        commit(FETCHING_FEEDBACK_SEARCH_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_FEEDBACK_SEARCH_ERROR, error)
        return null
      }
    },
    async feedbackDetails ({ commit }, feedbackId) {
      commit(FETCHING_FEEDBACKDETAILS_LIST)
      try {
        const response = await FeedbackAPI.details(feedbackId)
        commit(FETCHING_FEEDBACKDETAILS_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_FEEDBACKDETAILS_LIST_ERROR, error)
        return null
      }
    },
    async upload ({ commit }, data) {
      commit(UPLOADING_FEEDBACK)
      try {
        const response = await FeedbackAPI.upload(data)
        commit(UPLOADING_FEEDBACK_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(UPLOADING_FEEDBACK_ERROR, error)
        return null
      }
    },
    async edit ({ commit }, data) {
      commit(EDITING_FEEDBACK)
      try {
        const response = await FeedbackAPI.edit(data)
        commit(EDITING_FEEDBACK_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(EDITING_FEEDBACK_ERROR, error)
        return null
      }
    },
    async deleteOwnFeedback ({ commit }, data) {
      commit(DELETING_OWN_FEEDBACK)
      try {
        const response = await FeedbackAPI.deleteOwnFeedback(data)
        commit(DELETING_OWN_FEEDBACK_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(DELETING_OWN_FEEDBACK_ERROR, error)
        return null
      }
    }

  }
}
