<template>
  <v-container class="px-1 px-sm-3">
    <v-progress-circular
      v-if="isInitializing != 0"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <div
        v-if="!searchMenu"
        class="d-flex flex-row-reverse mt-n3"
      >
        <v-btn
          icon
          color="grey"
          @click="openSearchMenu"
        >
          <v-icon>
            mdi-tune
          </v-icon>
        </v-btn>
      </div>

      <div
        v-if="searchMenu"
      >
        <div
          class="d-flex flex-row-reverse mt-n3"
        >
          <v-btn
            icon
            color="grey"
            @click="closeSearchMenu"
          >
            <v-icon>
              mdi-eye-off-outline
            </v-icon>
          </v-btn>
        </div>

        <div class="d-flex mb-2">
          <agent-selector
            v-model="searchAgent"
            :agents="feedbackAgents"
            label="Seleziona un Agente"
            class="persistent-placeholder"
          />
        </div>
        <div class="d-flex mb-2">
          <area-selector
            v-model="searchArea"
            :areas="areasVisibleToAgent"
            multiple
            class="persistent-placeholder"
            hide-details
            @input="setClients"
          />
        </div>

        <div class="d-flex mb-2">
          <micro-area-selector
            v-model="searchMicroarea"
            :microareas="microAreasVisibleToAgent"
            clearable
            class="persistent-placeholder"
            hide-details
            @input="setClients"
          />
        </div>

        <div class="mb-2">
          <client-selector
            v-model="searchClient"
            :clients="filteredClientsList"
            class="persistent-placeholder"
            hide-details
            @input="setArea(); setMicroarea()"
          />
        </div>

        <div>
          <div class="caption">
            {{ $t('messages.Ricerca libera') }}
          </div>
          <div class="d-flex flex-row">
            <div class="d-flex flex-grow-1">
              <v-text-field
                v-model="searchText"
                filled
                hide-details
                elevation="0"
                tile
                clearable
                :label="$t('messages.Digita qui la parola o le parole da cercare')"
                :placeholder="$t('messages.Digita qui la parola o le parole da cercare')"
                class="persistent-placeholder mb-2"
              />
            </div>
            <div class="d-flex justify-center">
              <v-dialog
                v-model="infoDialog"
                width="500"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="ml-2 align-center justify-center"
                    color="grey"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-information
                    </v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>{{ $t('messages.Comandi di ricerca') }}</v-card-title>
                  <v-card-text>
                    {{ $t('messages.Comandi riga1') }}<br>
                    {{ $t('messages.Comandi riga2') }}
                  </v-card-text>
                  <v-card-actions>
                    <v-col class="text-right">
                      <v-btn
                        tile
                        color="primary"
                        elevation="0"
                        @click="infoDialog = false"
                      >
                        {{ $t('messages.Chiudi') }}
                      </v-btn>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>

          <div class="mb-2">
            <div class="caption">
              {{ $t('messages.Ricerca testo in') }}:
            </div>
            <div
              class="d-flex mt-n1 pt-0"
            >
              <v-checkbox
                v-for="(txtFields, tf) in searchTextFields"
                :key="tf"
                v-model="searchTextDestination"
                class="mx-1 px-n2"
                hide-details
                dense
                :label="txtFields"
                :value="txtFields"
                @change="searchTextDestinationCheck(txtFields)"
              >
                <template #[`label`]>
                  <span style="font-size: 0.665rem;">{{ txtFields }}</span>
                </template>
              </v-checkbox>
            </div>
          </div>

          <div
            class="d-flex justify-space-between mb-2 mt-n1"
          >
            <v-menu
              v-model="visitDateFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formatDate(visitDateFrom)"
                  :label="$t('messages.Da')"
                  :placeholder="$t('messages.Seleziona una data')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  style="font-size: 0.665rem; width:40px;"
                  class="mr-1"
                  hide-details
                  clearable
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="visitDateFrom"
                :value="visitDateFrom"
                :locale="$t('messages.locale')"
                :first-day-of-week="1"
                @input="visitDateFromMenu = false"
              />
            </v-menu>

            <v-menu
              v-model="visitDateToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formatDate(visitDateTo)"
                  :label="$t('messages.A')"
                  :placeholder="$t('messages.Seleziona una data')"
                  prepend-icon="mdi-calendar"
                  readonly
                  hide-details
                  v-bind="attrs"
                  style="font-size: 0.665rem; width:40px;"
                  clearable
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="visitDateTo"
                :locale="$t('messages.locale')"
                :first-day-of-week="1"
                @input="visitDateToMenu = false"
              />
            </v-menu>
          </div>

          <div
            class="d-flex justify-space-between mb-2"
          >
            <v-row
              no-gutters
              style="flex-wrap: nowrap;"
            >
              <v-col>
                <div
                  class="align-center caption"
                >
                  {{ $t('messages.Tipo Contatto') }}
                  <div class="d-flex flex-wrap">
                    <v-checkbox
                      v-for="(item, t) in contactType"
                      :key="t"
                      v-model="contactSelectedType"
                      hide-details
                      :disabled="isLoading"
                      color="primary"
                      :value="item.definition"
                    >
                      <template
                        slot="label"
                      >
                        <v-icon
                          color="grey"
                          class="mr-1 ml-n1"
                        >
                          {{ item.icon }}
                        </v-icon>
                        <span class="mr-2 ">{{ item.text }}</span>
                      </template>
                    </v-checkbox>
                  </div>
                </div>
              </v-col>
              <v-divider
                class="mx-2 mr-2"
                vertical
              />
              <v-col>
                <div
                  class="align-center caption"
                >
                  {{ $t('messages.Finalità Contatto') }}
                  <div class="d-flex flex-wrap">
                    <v-checkbox
                      v-for="(item, p) in contactPurpose"
                      :key="p"
                      v-model="contactSelectedPurpose"
                      hide-details
                      :disabled="isLoading"
                      color="primary"
                      :value="item.definition"
                    >
                      <template
                        slot="label"
                      >
                        <v-icon
                          :color="item.iconColor"
                          class="mr-1 ml-n1"
                        >
                          {{ item.icon }}
                        </v-icon>
                        <span class="mr-2 ">{{ item.text }}</span>
                      </template>
                    </v-checkbox>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-row
            no-gutters
            class="mb-2 align-end"
          >
            <v-col>
              <div
                class="caption"
              >
                {{ $t('messages.Presentazione prodotti') }}
                <div class="d-flex flex-wrap">
                  <v-btn
                    color="success"
                    small
                    fab
                    elevation="0"
                    :loading="isLoading"
                    @click="openPresentedProductSelector"
                  >
                    <v-icon v-if="!presentedProductSelector">
                      mdi-plus
                    </v-icon>
                    <v-icon v-else>
                      mdi-minus
                    </v-icon>
                  </v-btn>
                </div>
                <v-dialog
                  v-model="presentedProductSelector"
                  max-width="290"
                >
                  <v-card>
                    <v-card-text class="pt-4 align-center justify-center">
                      <product-selector
                        v-model="searchPresentedProduct"
                        :products="products"
                        :label="$t('messages.Prodotti presentati')"
                      />
                      <div class="d-flex justify-space-between justify-center align-center">
                        <v-btn
                          color="rgba(0, 0, 0, 0.06) white--text"
                          small
                          tile
                          elevation="0"
                          :loading="isLoading"
                          @click="closePresentedProductSelector"
                        >
                          {{ $t('messages.Annulla') }}
                        </v-btn>
                        <v-btn
                          color="success"
                          :disabled="!searchPresentedProduct"
                          small
                          :dark="!!searchPresentedProduct"
                          tile
                          elevation="0"
                          :loading="isLoading"
                          @click="searchThis('presented')"
                        >
                          {{ $t('messages.Cerca') }}
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
            <v-divider
              class="mx-1"
              vertical
            />
            <v-col>
              <div
                class="caption"
              >
                {{ $t('messages.Prodotti con problematiche') }}
                <div class="d-flex flex-wrap">
                  <v-btn
                    color="error"
                    small
                    fab
                    elevation="0"
                    hide-details
                    :loading="isLoading"
                    @click="openProblemProductSelector"
                  >
                    <v-icon v-if="!problemProductSelector">
                      mdi-plus
                    </v-icon>
                    <v-icon v-else>
                      mdi-minus
                    </v-icon>
                  </v-btn>
                </div>
                <v-dialog
                  v-model="problemProductSelector"
                  max-width="290"
                >
                  <v-card>
                    <v-card-text class="pt-4 align-center justify-center">
                      <product-selector
                        v-model="searchProblemProduct"
                        :products="products"
                        :label="$t('messages.Prodotti con problematiche')"
                      />
                      <div class="d-flex justify-space-between justify-center align-center">
                        <v-btn
                          color="rgba(0, 0, 0, 0.06) white--text"
                          small
                          tile
                          elevation="0"
                          :loading="isLoading"
                          @click="closeProblemProductSelector"
                        >
                          {{ $t('messages.Annulla') }}
                        </v-btn>
                        <v-btn
                          color="error"
                          :disabled="!searchProblemProduct"
                          small
                          :dark="!!searchProblemProduct"
                          tile
                          elevation="0"
                          :loading="isLoading"
                          @click="searchThis('problem')"
                        >
                          {{ $t('messages.Cerca') }}
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
            <v-divider
              class="mx-1"
              vertical
            />
            <v-col>
              <div
                class="caption"
              >
                {{ $t('messages.Campionature') }}
                <div class="d-flex flex-wrap">
                  <v-btn
                    color="primary"
                    small
                    fab
                    elevation="0"
                    :loading="isLoading"
                    @click="openSampleProductSelector"
                  >
                    <v-icon v-if="!sampleProductSelector">
                      mdi-plus
                    </v-icon>
                    <v-icon v-else>
                      mdi-minus
                    </v-icon>
                  </v-btn>
                </div>
                <v-dialog
                  v-model="sampleProductSelector"
                  max-width="290"
                >
                  <v-card>
                    <v-card-text class="pt-4 align-center justify-center">
                      <product-selector
                        v-model="searchSampleProduct"
                        :products="products"
                        :label="$t('messages.Campionature')"
                      />
                      <div class="d-flex justify-space-between justify-center align-center">
                        <v-btn
                          color="rgba(0, 0, 0, 0.06) white--text"
                          small
                          tile
                          elevation="0"
                          :loading="isLoading"
                          @click="closeSampleProductSelector"
                        >
                          {{ $t('messages.Annulla') }}
                        </v-btn>
                        <v-btn
                          color="primary"
                          :disabled="!searchSampleProduct"
                          small
                          :dark="!!searchSampleProduct"
                          tile
                          elevation="0"
                          :loading="isLoading"
                          @click="searchThis('sample')"
                        >
                          {{ $t('messages.Cerca') }}
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
          </v-row>

          <div
            class="caption flex-column mb-2 pa-1"
            style="background-color: rgba(0, 0, 0, 0.06);"
          >
            <v-icon
              class="mr-1"
            >
              mdi-magnify
            </v-icon>
            {{ $t('messages.Filtro') }}:
            <div class="d-flex">
              <div
                class="d-flex pa-2 mb-1"
                style="border-radius: 20px; background-color: white;"
              >
                <span v-if="!stringsToSearch.presentedProducts.length && !stringsToSearch.problemProducts.length && !stringsToSearch.sampleProducts.length">Nessun filtro impostato</span>
                <div
                  v-for="(value, propertyName, index) in stringsToSearch"
                  :key="index"
                >
                  <v-chip
                    v-for="(stringTS, sts) in value"
                    :key="sts"
                    close
                    class="white--text mx-1 my-1"
                    :color="searchChipBGColor(stringTS.type, stringTS.selectionType)"
                    small
                    @click:close="value.splice(sts, 1)"
                  >
                    {{ searchDescription (stringTS.type, stringTS.value) }}
                  </v-chip>
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-space-between mb-3"
          >
            <div
              class="justify-center caption"
            >
              {{ $t('messages.Deve contenere') }}:
              <div class="d-flex flex-wrap">
                <v-checkbox
                  v-model="hasPresentedProducts"
                  :label="$t('messages.Prodotti presentati')"
                  true-value="1"
                  value="1"
                  hide-details
                />
                <v-checkbox
                  v-model="hasProblemProducts"
                  :label="$t('messages.Prodotti con problemi')"
                  true-value="1"
                  value="1"
                  hide-details
                />
                <v-checkbox
                  v-model="hasSampleProducts"
                  :label="$t('messages.Campionature')"
                  true-value="1"
                  value="1"
                  hide-details
                />
                <v-checkbox
                  v-model="hasText"
                  :label="$t('messages.Testo')"
                  true-value="1"
                  value="1"
                  hide-details
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="d-flex flex-column align-center justify-center mb-3"
        >
          <v-btn
            color="success"
            class="btn btn-lg btn-primary white--text mb-2"
            type="button"
            :loading="isLoading"
            block
            elevation="0"
            tile
            @click="searchFilter"
          >
            <v-icon>
              mdi-magnify
            </v-icon>
            {{ $t('messages.Ricerca') }}
          </v-btn>

          <v-btn
            color="amber darken-2"
            class="btn btn-lg btn-primary white--text mb-2"
            type="button"
            :loading="isLoading"
            block
            elevation="0"
            tile
            @click="searchResetConfirm()"
          >
            <v-icon>
              mdi-eraser
            </v-icon>
            {{ $t('messages.Reset / Nuova Ricerca') }}
          </v-btn>

          <v-btn
            color="primary"
            class="btn btn-lg btn-primary white--text"
            type="button"
            :loading="isLoading"
            block
            elevation="0"
            tile
            @click="addToCampioniCart()"
          >
            <v-icon>
              mdi-eyedropper-plus
            </v-icon>
            {{ $t('messages.Ordina campioni') }}
          </v-btn>
          <v-btn
            v-if="isSupervisor"
            color="#217346"
            dark
            class="btn btn-lg white--text mt-2"
            type="button"
            elevation="0"
            block
            tile
            :loading="exportingToExcel"
            @click="exportToExcel"
          >
            <v-icon
              class="mr-1"
            >
              mdi-microsoft-excel
            </v-icon>
            {{ $t('messages.Esporta in Excel') }}
          </v-btn>
        </div>

        <v-dialog
          v-model="searchResetConfirmDialog"
          max-width="290"
        >
          <v-card>
            <v-card-text class="pt-4 align-center justify-center">
              {{ $t('messages.Sei sicuro di voler resettare il form di ricerca?') }}
              <div class="d-flex justify-space-between justify-center align-center">
                <v-btn
                  color="rgba(0, 0, 0, 0.06) white--text"
                  small
                  tile
                  elevation="0"
                  :loading="isLoading"
                  @click="searchResetConfirmDialog=false"
                >
                  {{ $t('messages.Annulla') }}
                </v-btn>
                <v-btn
                  color="amber darken-2"
                  small
                  dark
                  tile
                  elevation="0"
                  :loading="isLoading"
                  @click="searchReset(); searchResetConfirmDialog=false"
                >
                  {{ $t('messages.Reset') }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <div
          class="justify-center caption flex-column mb-2 pa-1"
          style="background-color: rgba(0, 0, 0, 0.06);"
        >
          <v-icon
            class="mr-1"
          >
            mdi-list-status
          </v-icon>
          {{ $t('messages.Visualizza solo') }}:
          <div class="d-flex flex-wrap">
            <v-checkbox
              v-model="viewOnlyPresentedProducts"
              :label="$t('messages.Prodotti presentati')"
              true-value="1"
              value="1"
              class="my-1 py-0"
              hide-details
            />
            <v-checkbox
              v-model="viewOnlyProblemProducts"
              :label="$t('messages.Prodotti con problemi')"
              true-value="1"
              value="1"
              class="my-1 py-0"
              hide-details
            />
            <v-checkbox
              v-model="viewOnlySampleProducts"
              :label="$t('messages.Campionature')"
              true-value="1"
              value="1"
              class="my-1 py-0"
              hide-details
            />
            <v-checkbox
              v-model="viewOnlyNotes"
              :label="$t('messages.Note')"
              true-value="1"
              value="1"
              class="my-1 py-0"
              hide-details
            />
            <v-checkbox
              v-model="viewOnlyNextVisitNotes"
              :label="$t('messages.Argomento prossima visita')"
              true-value="1"
              value="1"
              class="my-1 py-0"
              hide-details
            />
          </div>
        </div>
      </div>

      <div class="d-flex justify-center caption">
        {{ $t('messages.Totale Feedbacks') }}: <b>{{ feedbackList.length }}</b>
      </div>

      <div>
        <v-pagination
          v-if="feedbackList.length"
          v-model="currentPage"
          :length="Math.ceil(feedbackList.length/pageElements)"
        />
      </div>

      <v-item-group
        v-if="feedbackList.length"
        multiple
      >
        <v-container
          class="d-flex flex-wrap justify-center"
          :class="$vuetify.breakpoint.xlAndUp ? 'enlargeWidth' : ''"
        >
          <v-item
            v-for="(feedback,fb) in feedbackList"
            :key="fb"
            :value="feedback"
            class="white--text mt-2 mx-2 px-0 "
          >
            <v-card
              v-if="fb>=(currentPage - 1)*pageElements && fb<(currentPage)*pageElements"
              elevation="0"
              class="mx-0"
              tile
              style="background-color: rgba(0, 0, 0, 0.06); min-width:318px; max-width:318px; width:318px; overflow-x: hidden;"
            >
              <v-card-title
                class="primary white--text align-center justify-space-between"
                style="font-size: 0.86rem !important"
              >
                <div class="d-flex flex-column">
                  <div class="truncate-line-single">
                    <router-link
                      style="a, a:hover, a:active, a:visited { color: white; }"
                      class="white--text"
                      :to="'/client/details/' + feedback.client_id"
                    >
                      <span>{{ (feedback.client_azienda) }}</span>
                    </router-link>
                  </div>
                  <div
                    v-if="feedback.client_areaName"
                    class="truncate-line-single"
                    style="font-size: 0.665rem;"
                  >
                    [ {{ (feedback.client_areaName) }} ]
                  </div>
                </div>
                <div>
                  <v-icon
                    v-if="feedback.visit_rating"
                    :color="ratingVisitIcon(feedback.visit_rating).color"
                    large
                  >
                    {{ ratingVisitIcon(feedback.visit_rating).icon }}
                  </v-icon>
                </div>
              </v-card-title>
              <v-card-text
                class="mt-2 px-1 justify-center"
              >
                <div class="mb-2">
                  <div v-if="!viewOnlyPresentedProducts && !viewOnlyProblemProducts && !viewOnlySampleProducts && !viewOnlyNotes && !viewOnlyNextVisitNotes">
                    <div class="caption font-italic">
                      {{ $t('messages.Data Visita') }}:
                    </div>
                    <div
                      class="white rounded pl-1"
                    >
                      {{ formattedDate(feedback.visit_date) }}
                    </div>
                    <div class="mb-2">
                      <div class="caption font-italic">
                        {{ $t('messages.Tipo Contatto') }}:
                      </div>
                      <div class="d-flex justify-space-between align-center white rounded pl-1">
                        {{ $t('messages.'+feedback.contact_type) }}
                        <v-icon
                          color="grey"
                          class="pa-1"
                        >
                          {{ iconContactType(feedback.contact_type) }}
                        </v-icon>
                      </div>
                    </div>
                    <div class="mb-2">
                      <div class="caption font-italic">
                        {{ $t('messages.Finalità Contatto') }}:
                      </div>
                      <div
                        v-if="feedback.contact_purpose !== null"
                        class="d-flex justify-space-between align-center white rounded pl-1"
                      >
                        {{ $t('messages.'+feedback.contact_purpose) }}
                        <v-icon
                          :color="iconContactPurpose(feedback.contact_purpose).iconColor"
                          class="pa-1"
                        >
                          {{ iconContactPurpose(feedback.contact_purpose).icon }}
                        </v-icon>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-space-between align-center white rounded pl-1"
                      >
                        -
                      </div>
                    </div>
                  </div>
                  <div v-if="!viewOnlyNotes && !viewOnlyNextVisitNotes || viewOnlyPresentedProducts || viewOnlyProblemProducts || viewOnlySampleProducts">
                    <div
                      v-if="!viewOnlyProblemProducts && !viewOnlySampleProducts || viewOnlyPresentedProducts"
                      class="mb-2"
                    >
                      <div class="caption font-italic">
                        {{ $t('messages.Presentazione prodotti') }}:
                      </div>
                      <div
                        v-if="feedback.presented.length"
                        class="white rounded pl-1"
                      >
                        <div
                          v-for="(product,p) in feedback.presented"
                          :key="p"
                        >
                          <div
                            v-if="product.purpose === 'presented'"
                            class="d-block d-flex justify-space-between align-center"
                          >
                            <v-chip
                              class="my-1 white--text"
                              color="success"
                              small
                            >
                              {{ searchDescription(product.type, product.value) }}
                            </v-chip>
                            <div
                              v-if="product.rating"
                            >
                              <v-icon
                                v-for="(pIcons,pI) in productIcons"
                                :key="pI"
                                :color="ratingIcon(product.rating)[pI].color"
                              >
                                {{ ratingIcon(product.rating)[pI].icon }}
                              </v-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else
                        class="d-flex white rounded pl-1"
                      >
                        -
                      </div>
                    </div>
                    <div
                      v-if="!viewOnlyPresentedProducts && !viewOnlySampleProducts || viewOnlyProblemProducts"
                      class="mb-2"
                    >
                      <div class="caption font-italic">
                        {{ $t('messages.Gestione problematiche') }}:
                      </div>
                      <div
                        v-if="feedback.problem.length"
                        class="white rounded pl-1"
                      >
                        <div
                          v-for="(prProduct,pr) in feedback.problem"
                          :key="pr"
                        >
                          <div
                            v-if="prProduct.purpose === 'problem'"
                            class="d-block d-flex justify-space-between align-center"
                          >
                            <v-chip
                              class="my-1 white--text"
                              color="error"
                              small
                            >
                              {{ searchDescription(prProduct.type, prProduct.value) }}
                            </v-chip>
                            <div
                              v-if="prProduct.rating"
                            >
                              <v-icon
                                v-for="(prIcons,prI) in prProductIcons"
                                :key="prI"
                                :color="ratingIcon(prProduct.rating)[prI].color"
                              >
                                {{ ratingIcon(prProduct.rating)[prI].icon }}
                              </v-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else
                        class="d-flex white rounded pl-1"
                      >
                        -
                      </div>
                    </div>
                    <div
                      v-if="!viewOnlyPresentedProducts && !viewOnlyProblemProducts || viewOnlySampleProducts"
                      class="mb-2"
                    >
                      <div class="caption font-italic">
                        {{ $t('messages.Campionature') }}:
                      </div>
                      <div
                        v-if="feedback.sample.length"
                        class="white rounded pl-1"
                      >
                        <div
                          v-for="(saProduct,sa) in feedback.sample"
                          :key="sa"
                        >
                          <div
                            v-if="saProduct.purpose === 'sample'"
                            class="d-block d-flex justify-space-between align-center"
                          >
                            <v-chip
                              class="my-1 white--text"
                              color="primary"
                              small
                            >
                              {{ searchDescription(saProduct.type, saProduct.value) }}
                            </v-chip>
                            <div
                              v-if="saProduct.format"
                              class="caption"
                            >
                              {{ $t('messages.' + saProduct.format) }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else
                        class="d-flex white rounded pl-1"
                      >
                        -
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!viewOnlyPresentedProducts && !viewOnlyProblemProducts && !viewOnlySampleProducts && !viewOnlyNextVisitNotes || viewOnlyNotes"
                  class="mb-2"
                >
                  <div class="caption font-italic">
                    {{ $t('messages.Note') }}:
                  </div>
                  <div
                    v-if="feedback.notes"
                    class="white rounded pl-1"
                  >
                    <span style="white-space: pre-wrap;">{{ feedback.notes }}</span>
                  </div>
                  <div
                    v-else
                    class="d-flex white rounded pl-1"
                  >
                    -
                  </div>
                </div>
                <div v-if="!viewOnlyPresentedProducts && !viewOnlyProblemProducts && !viewOnlySampleProducts && !viewOnlyNotes || viewOnlyNextVisitNotes">
                  <div class="caption font-italic">
                    {{ $t('messages.Argomento prossima visita') }}:
                  </div>
                  <div class="white rounded pl-1">
                    <span style="white-space: pre-wrap;">{{ feedback.next_visit_note }}</span>
                  </div>
                </div>
                <v-icon
                  v-if="feedback.last_day && feedback.user_id === userId"
                  color="error"
                  class="pa-1"
                  style="background-color: #ffffff9e;border-radius: 50%; position: absolute; left:5px; bottom:5px"
                  @click.stop="deleteOwnFeedback(feedback)"
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  v-if="feedback.last_day && feedback.user_id === userId"
                  color="amber darken-2"
                  class="pa-1"
                  style="background-color: #ffffff9e;border-radius: 50%; position: absolute; left:40px; bottom: 5px"
                  @click.stop="editOwnFeedback(feedback)"
                >
                  mdi-pencil
                </v-icon>
                <div class="caption font-italic text-right mt-3">
                  {{ feedback.username }}
                </div>
              </v-card-text>
            </v-card>
          </v-item>
        </v-container>
      </v-item-group>
      <v-card
        v-if="!feedbackList.length && !isLoading && !isResetting"
        elevation="0"
        tile
        class="error d-flex align-center justify-center"
        style="min-height:150px; max-height:150px; height:150px;"
      >
        <span
          class="title white--text"
        >
          {{ $t('messages.Nessun feedback trovato secondo i parametri di ricerca impostati') }}
        </span>
      </v-card>

      <div>
        <v-pagination
          v-if="feedbackList.length"
          v-model="currentPage"
          class="mt-2"
          :length="Math.ceil(feedbackList.length/pageElements)"
        />
      </div>

      <div class="d-flex justify-center caption">
        {{ $t('messages.Totale Feedbacks') }}: <b>{{ feedbackList.length }}</b>
      </div>
    </div>

    <confirm-dialog
      v-model="confirmDialogDeleteOwnFeedback"
      color="error"
      :title="$t('messages.Rimuovi')"
      :description="'Sei sicuro di voler eliminare questo Feedback?'"
      @confirm="deleteOwnFeedbackConfirm(); confirmDialogDeleteOwnFeedback=false"
      @cancel="confirmDialogDeleteOwnFeedback=false"
    />
    <confirm-dialog
      v-model="confirmDialogEditOwnFeedback"
      color="amber darken-2"
      :title="$t('messages.Modifica')"
      :description="'Sei sicuro di voler modificare questo Feedback?'"
      @confirm="editOwnFeedbackConfirm(); confirmDialogEditOwnFeedback=false"
      @cancel="confirmDialogEditOwnFeedback=false"
    />
  </v-container>
</template>

<script>
import ConfirmDialog from '../components/ConfirmDialog'
import ClientSelector from '../components/ClientSelector.vue'
import ProductSelector from '../components/ProductSelector.vue'
import AreaSelector from '../components/AreaSelector.vue'
import MicroAreaSelector from '../components/MicroAreaSelector.vue'
import AgentSelector from '../components/AgentSelector'

export default {
  name: 'Feedbacks',

  components: {
    ConfirmDialog,
    ClientSelector,
    ProductSelector,
    AreaSelector,
    MicroAreaSelector,
    AgentSelector
  },

  data: () => ({
    searchMenu: true,
    infoDialog: false,
    productIcons: 3,
    prProductIcons: 3,
    isInitializing: 0,
    isLoading: false,
    isResetting: false,
    contactSelectedType: [],
    contactSelectedPurpose: [],
    tags: [],
    stringsToSearch: {
      presentedProducts: [],
      problemProducts: [],
      sampleProducts: []
    },
    hasPresentedProducts: null,
    hasProblemProducts: null,
    hasSampleProducts: null,
    hasText: null,
    viewOnlyPresentedProducts: null,
    viewOnlyProblemProducts: null,
    viewOnlySampleProducts: null,
    viewOnlyNotes: null,
    viewOnlyNextVisitNotes: null,
    searchMicroarea: null,
    searchArea: [],
    searchClient: null,
    searchingClient: null,
    searchText: null,
    presentedProductSelector: false,
    problemProductSelector: false,
    sampleProductSelector: false,
    searchPresentedProduct: null,
    searchProblemProduct: null,
    searchSampleProduct: null,
    searchAgent: null,
    currentPage: 1,
    pageElements: 12,
    visitDateFrom: null, // initialized in created
    visitDateFromMenu: false,
    visitDateTo: new Date().toISOString().substr(0, 10),
    visitDateToMenu: false,
    searchResetConfirmDialog: false,
    confirmDialogDeleteOwnFeedback: false,
    confirmDialogEditOwnFeedback: false,
    filteredClientsList: [],
    feedbackAgents: [],
    exportingToExcel: false
  }),

  computed: {
    userId () {
      return this.$store.getters['security/userId']
    },
    errorMessage () {
      return this.$store.getters['feedback/errorMessage']
    },
    feedbackList () {
      if (this.isSupervisor) {
        return this.$store.getters['feedback/feedbackList']
      } else {
        return this.$store.getters['feedback/feedbackList'].filter(f => {
          if (f.contact_type === 'affiancamento') {
            return false
          }
          return true
        })
      }
    },
    clientsList () {
      return this.$store.getters['clients/clientsListMinimal']
    },
    agentsVisibleToAgent () {
      return this.$store.getters['agents/agentsVisibleToAgent']
    },
    areasVisibleToAgent () {
      return this.$store.getters['agents/areasVisibleToAgent']
    },
    microAreasVisibleToAgent () {
      return this.$store.getters['agents/microAreasVisibleToAgent']
    },
    products () {
      return this.$store.getters['products/listObsolete']
    },
    searchTextFields () {
      return [this.$t('messages.Note'), this.$t('messages.Argomento prossima visita')]
    },
    searchTextDestination () {
      return [this.$t('messages.Note'), this.$t('messages.Argomento prossima visita')]
    },
    isSupervisor () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN') || this.$store.getters['security/hasRole']('ROLE_AGENT_ADMIN')
    },
    contactType () {
      if (this.isSupervisor) {
        return [{ definition: 'visita', text: this.$t('messages.visita'), icon: 'mdi-account-voice' },
          { definition: 'telefonico', text: this.$t('messages.telefonico'), icon: 'mdi-phone-in-talk' },
          { definition: 'assente', text: this.$t('messages.assente'), icon: 'mdi-cancel' },
          { definition: 'affiancamento', text: this.$t('messages.affiancamento'), icon: 'mdi-account-multiple' }]
      } else {
        return [{ definition: 'visita', text: this.$t('messages.visita'), icon: 'mdi-account-voice' },
          { definition: 'telefonico', text: this.$t('messages.telefonico'), icon: 'mdi-phone-in-talk' },
          { definition: 'assente', text: this.$t('messages.assente'), icon: 'mdi-cancel' }]
      }
    },
    contactPurpose () {
      return [{ definition: 'vendita', text: this.$t('messages.vendita'), icon: 'mdi-cash-multiple', iconColor: 'success' },
        { definition: 'assistenza', text: this.$t('messages.assistenza'), icon: 'mdi-tools', iconColor: 'error' },
        { definition: 'incasso', text: this.$t('messages.incasso'), icon: 'mdi-cash-register', iconColor: 'primary' }]
    }
  },

  async created () {
    this.isInitializing = 4
    if (this.$route.params.clientId) {
      this.searchMenu = false
      this.isLoading = true
      this.searchClient = this.$route.params.clientId
      await this.$store.dispatch('feedback/search', {
        client: this.searchClient
      })
      this.isLoading = false
    }
    const now = new Date(Date.now())
    now.setMonth(now.getMonth() - 1)
    this.visitDateFrom = now.toISOString().substr(0, 10)
    // this.filteredClientsList = await this.$store.dispatch('clients/list')
    this.filteredClientsList = await this.$store.dispatch('clients/clientsListMinimal')
    this.$store.dispatch('agents/areasVisibleToAgent').then(() => this.isInitializing--)
    this.$store.dispatch('agents/microAreasVisibleToAgent').then(() => this.isInitializing--)
    this.$store.dispatch('agents/agentsVisibleToAgent').then(() => this.isInitializing--)
    this.$store.dispatch('products/listObsolete').then(() => this.isInitializing--)
    if (!this.$route.params.clientId) {
      this.searchMenu = true
      this.isInitializing++
      this.$store.dispatch('feedback/feedbackList').then((r) => { this.isInitializing--; this.feedbackAgents = r.feedbackAgents })
    }
  },

  methods: {
    openSearchMenu () {
      if (!(this.searchMenu === true)) {
        this.searchMenu = true
      } else this.searchMenu = false
    },
    closeSearchMenu () {
      this.searchMenu = false
    },

    async deleteOwnFeedbackConfirm () {
      const result = await this.$store.dispatch('feedback/deleteOwnFeedback', { feedback: this.ownFeedbackToDelete })
      if (result !== null) {
        const message = 'Feedback eliminato'
        const dataMessage = { isOpen: true, message: message, color: 'success' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        const removedFeedbackIndex = this.feedbackList.findIndex(i => this.ownFeedbackToDelete.id === i.id)
        this.feedbackList.splice(removedFeedbackIndex, 1)
      } else {
        const message = this.$store.getters['feedback/errorMessage']
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
    },
    deleteOwnFeedback (feedback) {
      this.ownFeedbackToDelete = feedback
      this.confirmDialogDeleteOwnFeedback = true
    },

    async editOwnFeedbackConfirm () {
      this.$router.push({ path: '/feedbackinsert/edit/' + this.ownFeedbackToEdit.id })
    },
    editOwnFeedback (feedback) {
      this.ownFeedbackToEdit = feedback
      this.confirmDialogEditOwnFeedback = true
    },

    async setArea () {
      if (this.searchClient) {
        this.client = await this.$store.dispatch('clientdetails/clientDetails', this.searchClient)
        this.searchArea = [this.client.cd_agent]
      }
    },

    async setMicroarea () {
      if (this.searchClient) {
        this.client = await this.$store.dispatch('clientdetails/clientDetails', this.searchClient)
        this.searchMicroarea = this.client.microarea
      }
    },

    setClients () {
      if (this.searchArea.length > 0 || this.searchMicroarea) {
        if (this.searchArea.length > 0) {
          this.filteredClientsList = this.clientsList.filter((c) => {
            return (this.searchArea.some(a => a === c.cd_agent))
          })
        }
        if (this.searchMicroarea) {
          this.filteredClientsList = this.clientsList.filter((c) => {
            return (this.searchMicroarea === '' || (
              (this.searchMicroarea && (c.microarea ?? '') === this.searchMicroarea)
            )
            )
          })
        }
      } else {
        this.filteredClientsList = this.clientsList
      }
    },

    async searchReset () {
      this.isLoading = true
      this.isResetting = true
      await this.$store.dispatch('feedback/feedbackList')

      this.searchText = null
      this.searchClient = null
      this.searchMicroarea = null
      this.searchArea = null
      this.contactSelectedType = []
      this.contactSelectedPurpose = []
      this.hasPresentedProducts = null
      this.hasProblemProducts = null
      this.hasSampleProducts = null
      this.hasText = null
      this.stringsToSearch = {
        presentedProducts: [],
        problemProducts: [],
        sampleProducts: []
      }
      this.isResetting = false
      this.isLoading = false
    },
    searchResetConfirm () {
      this.searchResetConfirmDialog = true
    },

    async searchFilter () {
      this.isLoading = true
      let errorMessages = ''
      let txtDestination = ''
      if (this.searchText && this.searchTextDestination.length === 0) {
        errorMessages += this.$t('messages.Selezionare almeno un campo di ricerca testo') + '\n'
        const dataMessage = { isOpen: true, message: errorMessages, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        this.isLoading = false
        return
      }
      if (this.searchTextDestination.length === 2) {
        txtDestination = '2'
      } else if (this.searchTextDestination.length && this.searchTextDestination.length < 2) {
        if (this.searchTextDestination[0] === this.$t('messages.Note')) {
          txtDestination = '0' // Note
        }
        if (this.searchTextDestination[0] !== this.$t('messages.Note')) {
          txtDestination = '1' // Argomento prossima visita
        }
      }
      await this.$store.dispatch('feedback/search', {
        text: this.searchText,
        textDestination: txtDestination,
        agentId: this.searchAgent,
        client: this.searchClient,
        area: this.searchArea,
        microarea: this.searchMicroarea,
        dateFrom: this.visitDateFrom,
        dateTo: this.visitDateTo,
        contactType: this.contactSelectedType,
        contactPurpose: this.contactSelectedPurpose,
        presented: this.stringsToSearch.presentedProducts,
        problem: this.stringsToSearch.problemProducts,
        sample: this.stringsToSearch.sampleProducts,
        hasPresentedProducts: this.hasPresentedProducts,
        hasProblemProducts: this.hasProblemProducts,
        hasSampleProducts: this.hasSampleProducts,
        hasText: this.hasText
      })
      if (this.feedbackList.length === 0 && !this.searchReset) {
        const message = this.$t('messages.Nessun feedback trovato secondo i parametri di ricerca impostati')
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
      this.currentPage = 1
      this.isLoading = false
    },

    openPresentedProductSelector () {
      if (!(this.presentedProductSelector === true)) {
        this.presentedProductSelector = true
      } else this.presentedProductSelector = false
    },
    closePresentedProductSelector () {
      this.presentedProductSelector = false
    },

    openProblemProductSelector () {
      if (!(this.problemProductSelector === true)) {
        this.problemProductSelector = true
      } else this.problemProductSelector = false
    },
    closeProblemProductSelector () {
      this.problemProductSelector = false
    },

    openSampleProductSelector () {
      if (!(this.sampleProductSelector === true)) {
        this.sampleProductSelector = true
      } else this.sampleProductSelector = false
    },
    closeSampleProductSelector () {
      this.sampleProductSelector = false
    },

    searchTextDestinationCheck () {
      if (!this.searchTextFields.length) {
        const message = this.$t('messages.Seleziona almeno un campo in cui ricercare il testo')
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
    },

    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    },

    formattedDate (dateToFormat) {
      const dateToConvert = new Date(dateToFormat)
      const day = dateToConvert.getDate()
      const month = dateToConvert.getMonth() + 1
      const year = dateToConvert.getFullYear()
      return '' + (day <= 9 ? '0' + day : day) + '/' + (month <= 9 ? '0' + month : month) + '/' + year
    },

    ratingVisitIcon (value) {
      switch (value) {
      case '1':
        return { icon: 'mdi-emoticon-sad', color: 'error' }
      case '2':
        return { icon: 'mdi-emoticon-neutral', color: 'amber darken-2' }
      case '3':
        return { icon: 'mdi-emoticon', color: 'success' }
      }
    },

    ratingIcon (value) {
      switch (value) {
      case '1':
        return [{ icon: 'mdi-emoticon-sad', color: 'error' }, { icon: 'mdi-emoticon-neutral-outline', color: 'grey' }, { icon: 'mdi-emoticon-outline', color: 'grey' }]
      case '2':
        return [{ icon: 'mdi-emoticon-sad-outline', color: 'grey' }, { icon: 'mdi-emoticon-neutral', color: 'amber darken-2' }, { icon: 'mdi-emoticon-outline', color: 'grey' }]
      case '3':
        return [{ icon: 'mdi-emoticon-sad-outline', color: 'grey' }, { icon: 'mdi-emoticon-neutral-outline', color: 'grey' }, { icon: 'mdi-emoticon', color: 'success' }]
      }
    },

    iconContactType (definition) {
      return this.contactType.find(ct => ct.definition === definition).icon
    },

    iconContactPurpose (definition) {
      return this.contactPurpose.find(ct => ct.definition === definition)
    },

    searchDescription (searchType, value) {
      switch (searchType) {
      case 'categoryId':
        return this.products.categories[value]?.titolo ?? 'undefined'
      case 'subCategoryId':
        return this.products.subCategories[value]?.titolo ?? 'undefined'
      case 'lineId':
        return this.products.lines[value]?.titolo ?? 'undefined'
      case 'commercialCodeId':
        return this.products.commercialcodes[value]?.codice ?? 'undefined'
      default:
        return value
      }
    },

    searchChipBGColor (searchType, purpose) {
      switch (searchType) {
      case 'categoryId':
      case 'subCategoryId':
      case 'lineId':
      case 'commercialCodeId':
        return purpose === 'presented' ? 'success' : purpose === 'problem' ? 'error' : 'primary'
      }
    },

    searchThis (type) {
      this.isLoading = true
      if (type === 'presented') {
        const selectedProduct = {}
        if (this.searchPresentedProduct) {
          selectedProduct.selectionType = type
          if (this.searchPresentedProduct.selectedCommercialCodeId) {
            selectedProduct.type = 'commercialCodeId'
            selectedProduct.value = this.searchPresentedProduct.selectedCommercialCodeId
            selectedProduct.level = '4'
            selectedProduct.text = this.products.commercialcodes[this.searchPresentedProduct.selectedCommercialCodeId].codice
          } else if (this.searchPresentedProduct.selectedLineId) {
            selectedProduct.type = 'lineId'
            selectedProduct.value = this.searchPresentedProduct.selectedLineId
            selectedProduct.level = '3'
            selectedProduct.text = this.products.lines[this.searchPresentedProduct.selectedLineId].titolo
          } else if (this.searchPresentedProduct.selectedSubCategoryId) {
            selectedProduct.type = 'subCategoryId'
            selectedProduct.value = this.searchPresentedProduct.selectedSubCategoryId
            selectedProduct.level = '2'
            selectedProduct.text = this.products.subCategories[this.searchPresentedProduct.selectedSubCategoryId].titolo
          } else if (this.searchPresentedProduct.selectedCategoryId) {
            selectedProduct.type = 'categoryId'
            selectedProduct.value = this.searchPresentedProduct.selectedCategoryId
            selectedProduct.level = '1'
            selectedProduct.text = this.products.categories[this.searchPresentedProduct.selectedCategoryId].titolo
          } else {
            return
          }
          if (!(this.stringsToSearch.presentedProducts.some(t => t.type === selectedProduct.type && t.value === selectedProduct.value && t.selectionType === selectedProduct.selectionType))) {
            this.stringsToSearch.presentedProducts.push({ type: selectedProduct.type, value: selectedProduct.value, selectionType: selectedProduct.selectionType })
          } else {
            const message = this.$t('messages.Prodotto già presente')
            const dataMessage = { isOpen: true, message: message, color: 'error' }
            this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          }
        }
        this.closePresentedProductSelector()
      }
      if (type === 'problem') {
        const selectedProduct = {}
        if (this.searchProblemProduct) {
          selectedProduct.selectionType = type
          if (this.searchProblemProduct.selectedCommercialCodeId) {
            selectedProduct.type = 'commercialCodeId'
            selectedProduct.value = this.searchProblemProduct.selectedCommercialCodeId
            selectedProduct.level = '4'
            selectedProduct.text = this.products.commercialcodes[this.searchProblemProduct.selectedCommercialCodeId].codice
          } else if (this.searchProblemProduct.selectedLineId) {
            selectedProduct.type = 'lineId'
            selectedProduct.value = this.searchProblemProduct.selectedLineId
            selectedProduct.level = '3'
            selectedProduct.text = this.products.lines[this.searchProblemProduct.selectedLineId].titolo
          } else if (this.searchProblemProduct.selectedSubCategoryId) {
            selectedProduct.type = 'subCategoryId'
            selectedProduct.value = this.searchProblemProduct.selectedSubCategoryId
            selectedProduct.level = '2'
            selectedProduct.text = this.products.subCategories[this.searchProblemProduct.selectedSubCategoryId].titolo
          } else if (this.searchProblemProduct.selectedCategoryId) {
            selectedProduct.type = 'categoryId'
            selectedProduct.value = this.searchProblemProduct.selectedCategoryId
            selectedProduct.level = '1'
            selectedProduct.text = this.products.categories[this.searchProblemProduct.selectedCategoryId].titolo
          } else {
            return
          }
          if (!(this.stringsToSearch.problemProducts.some(t => t.type === selectedProduct.type && t.value === selectedProduct.value && t.selectionType === selectedProduct.selectionType))) {
            this.stringsToSearch.problemProducts.push({ type: selectedProduct.type, value: selectedProduct.value, selectionType: selectedProduct.selectionType })
          } else {
            const message = this.$t('messages.Prodotto già presente')
            const dataMessage = { isOpen: true, message: message, color: 'error' }
            this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          }
        }
        this.closeProblemProductSelector()
      }
      if (type === 'sample') {
        const selectedProduct = {}
        if (this.searchSampleProduct) {
          selectedProduct.selectionType = type
          if (this.searchSampleProduct.selectedCommercialCodeId) {
            selectedProduct.type = 'commercialCodeId'
            selectedProduct.value = this.searchSampleProduct.selectedCommercialCodeId
            selectedProduct.level = '4'
            selectedProduct.text = this.products.commercialcodes[this.searchSampleProduct.selectedCommercialCodeId].codice
          } else if (this.searchSampleProduct.selectedLineId) {
            selectedProduct.type = 'lineId'
            selectedProduct.value = this.searchSampleProduct.selectedLineId
            selectedProduct.level = '3'
            selectedProduct.text = this.products.lines[this.searchSampleProduct.selectedLineId].titolo
          } else if (this.searchSampleProduct.selectedSubCategoryId) {
            selectedProduct.type = 'subCategoryId'
            selectedProduct.value = this.searchSampleProduct.selectedSubCategoryId
            selectedProduct.level = '2'
            selectedProduct.text = this.products.subCategories[this.searchSampleProduct.selectedSubCategoryId].titolo
          } else if (this.searchSampleProduct.selectedCategoryId) {
            selectedProduct.type = 'categoryId'
            selectedProduct.value = this.searchSampleProduct.selectedCategoryId
            selectedProduct.level = '1'
            selectedProduct.text = this.products.categories[this.searchSampleProduct.selectedCategoryId].titolo
          } else {
            return
          }
          if (!(this.stringsToSearch.sampleProducts.some(t => t.type === selectedProduct.type && t.value === selectedProduct.value && t.selectionType === selectedProduct.selectionType))) {
            this.stringsToSearch.sampleProducts.push({ type: selectedProduct.type, value: selectedProduct.value, selectionType: selectedProduct.selectionType })
          } else {
            const message = this.$t('messages.Prodotto già presente')
            const dataMessage = { isOpen: true, message: message, color: 'error' }
            this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          }
        }
        this.closeSampleProductSelector()
      }
      this.isLoading = false
    },

    addToCampioniCart () {
      const campioniLista = {
        articoli: [],
        campioni: []
      }
      for (let index = 0; index < this.feedbackList.length; index++) {
        const feedback = this.feedbackList[index]
        feedback.sample.forEach(sample => {
          if (sample.type === 'articolo') {
            let articolo = campioniLista.articoli.find(a => a.value === sample.value && a.format === sample.format)
            if (articolo) {
              articolo.quantity++
            } else {
              articolo = {
                value: sample.value,
                format: sample.format,
                quantity: 1,
                note: ''
              }
              campioniLista.articoli.push(articolo)
            }
          } else if (sample.type === 'campione') {
            let articolo = campioniLista.campioni.find(a => a.value === sample.value)
            if (articolo) {
              articolo.quantity++
            } else {
              articolo = {
                value: sample.value,
                quantity: 1,
                note: ''
              }
              campioniLista.campioni.push(articolo)
            }
          }
        })
      }
      // push campioni lista in vuex
      this.$store.dispatch('products/setFeedbackCampioniCache', campioniLista)
      // redirect to order campioni
      this.$router.push({ path: '/ordercampionatura' })
    },
    async exportToExcel () {
      this.exportingToExcel = true
      const tHeader = ['id',
        'Utente',
        'Data',
        'Area',
        'Azienda',
        'Tipo Contatto',
        'Finalità Contatto',
        'Note',
        'Note Prossima Visita',
        'Prodotto',
        'Tipo',
        'Rating Prodotto',
        'Rating Visita']
      const exceldata = []
      this.feedbackList.forEach((f) => {
        if (f.presented.length === 0 && f.problem.length === 0 && f.sample.length === 0) {
          const rowData = [f.id, f.username, f.visit_date, f.client_areaName, f.client_azienda, f.contact_type, f.contact_purpose, f.notes, f.next_visit_note, '-', '-', '-', f.visit_rating]
          exceldata.push(rowData)
        } else {
          f.presented.forEach(p => {
            const rowData = [f.id, f.username, f.visit_date, f.client_areaName, f.client_azienda, f.contact_type, f.contact_purpose, f.notes, f.next_visit_note, this.searchDescription(p.type, p.value), 'Presentato', p.rating, f.visit_rating]
            exceldata.push(rowData)
          })
          f.problem.forEach(p => {
            const rowData = [f.id, f.username, f.visit_date, f.client_areaName, f.client_azienda, f.contact_type, f.contact_purpose, f.notes, f.next_visit_note, this.searchDescription(p.type, p.value), 'Problematica', p.rating, f.visit_rating]
            exceldata.push(rowData)
          })
          f.sample.forEach(p => {
            const rowData = [f.id, f.username, f.visit_date, f.client_areaName, f.client_azienda, f.contact_type, f.contact_purpose, f.notes, f.next_visit_note, this.searchDescription(p.type, p.value), 'Campionatura', (p.format ? p.format : ''), f.visit_rating]
            exceldata.push(rowData)
          })
        }
      })
      setTimeout(() => {
        import('../vendor/Export2Excel').then(excel => {
          excel.export_json_to_excel({
            header: tHeader, // Header Required
            data: exceldata, // Specific data Required
            filename: 'Feedbacks', // Optional
            autoWidth: true, // Optional
            bookType: 'xlsx' // Optional
          })
          this.exportingToExcel = false
        })
      }, 500)
    }
  }
}
</script>

<style scoped>
.transition-element-enter-active,
.transition-element-leave-active {
  transition: all 0.3s;
  transition-timing-function: linear;
  max-height:200px;
}
.transition-element-enter,
.transition-element-leave-to
{
  opacity: 0;
  max-height: 0px;
}

.enlargeWidth {
  min-width:1590px;
  max-width:1590px;
  width:1590px;
  overflow-x: hidden;
}

a, a:hover, a:active, a:visited { color: white; }

.truncate-line-single {
  max-width:250px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
}
</style>
