import ReportsAPI from '../api/reports'

const FETCHING_REPORT = 'FETCHING_REPORT'
const FETCHING_REPORT_SUCCESS = 'FETCHING_REPORT_SUCCESS'
const FETCHING_REPORT_ERROR = 'FETCHING_REPORT_ERROR'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null
  },
  getters: {
    isLoading (state) {
      return state.isLoading
    },
    hasError (state) {
      return state.error !== null
    },
    error (state) {
      return state.error
    },
    errorMessage (state) {
      return state.error !== null ? state.error.response.data.detail : null
    }
  },
  mutations: {
    [FETCHING_REPORT] (state) {
      state.isLoading = true
      state.error = null
    },
    [FETCHING_REPORT_SUCCESS] (state, data) {
      state.isLoading = false
      state.error = null
    },
    [FETCHING_REPORT_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    }
  },

  actions: {
    async getReportFatturatoCliente ({ commit }, year) {
      commit(FETCHING_REPORT)
      try {
        const response = await ReportsAPI.getReportFatturatoCliente(year)
        commit(FETCHING_REPORT_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_REPORT_ERROR, error)
        return null
      }
    },
    async getReportFatturatoColore ({ commit }, year) {
      commit(FETCHING_REPORT)
      try {
        const response = await ReportsAPI.getReportFatturatoColore(year)
        commit(FETCHING_REPORT_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_REPORT_ERROR, error)
        return null
      }
    },
    async getReportClientVisits ({ commit }) {
      commit(FETCHING_REPORT)
      try {
        const response = await ReportsAPI.getReportClientVisits()
        commit(FETCHING_REPORT_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_REPORT_ERROR, error)
        return null
      }
    },
    async getReportVisitRatings ({ commit }, year) {
      commit(FETCHING_REPORT)
      try {
        const response = await ReportsAPI.getReportVisitRatings(year)
        commit(FETCHING_REPORT_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_REPORT_ERROR, error)
        return null
      }
    },
    async getReportClientCompact ({ commit }, data) {
      commit(FETCHING_REPORT)
      try {
        const response = await ReportsAPI.getReportClientCompact(data.area, data.year)
        commit(FETCHING_REPORT_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_REPORT_ERROR, error)
        return null
      }
    },
    async getReportClientCompactColor ({ commit }, area) {
      commit(FETCHING_REPORT)
      try {
        const response = await ReportsAPI.getReportClientCompactColor(area)
        commit(FETCHING_REPORT_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_REPORT_ERROR, error)
        return null
      }
    },
    async getReportFatturatoClientiMobile ({ commit }, clientList) {
      commit(FETCHING_REPORT)
      try {
        const response = await ReportsAPI.getReportFatturatoClientiMobile(clientList)
        commit(FETCHING_REPORT_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_REPORT_ERROR, error)
        return null
      }
    },
    async getReportAgenteEsteso ({ commit }, data) {
      commit(FETCHING_REPORT)
      try {
        const response = await ReportsAPI.getReportAgenteEsteso(data)
        commit(FETCHING_REPORT_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_REPORT_ERROR, error)
        return null
      }
    },
    async getHomePageReport ({ commit }, filter) {
      commit(FETCHING_REPORT)
      try {
        const response = await ReportsAPI.getHomePageReport(filter)
        commit(FETCHING_REPORT_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_REPORT_ERROR, error)
        return null
      }
    }
  }
}
