import ImporterAPI from '../api/importer'

const SENDING_COMMAND = 'SENDING_COMMAND'
const COMMAND_SUCCESS = 'COMMAND_SUCCESS'
const COMMAND_ERROR = 'COMMAND_ERROR'

export default {
  namespaced: true,
  state: {
    errors: {},
    messages: {}
  },
  getters: {
    hasError (state) {
      return command => {
        if (typeof (state.errors[command]) !== 'undefined') { return true }
      }
    },
    error (state) {
      return command => {
        if (typeof (state.errors[command]) !== 'undefined') { return state.errors[command] }
        return ''
      }
    },
    message (state) {
      return command => {
        if (typeof (state.messages[command]) !== 'undefined') { return state.messages[command] }
        return ''
      }
    }
  },
  mutations: {
    [SENDING_COMMAND] (state, command) {
      delete state.errors[command]
      delete state.messages[command]
    },
    [COMMAND_SUCCESS] (state, data) {
      state.errors[data.command] = data.message
    },
    [COMMAND_ERROR] (state, data) {
      console.log(data.error)
      state.errors[data.command] = data.error.response.data.detail
      // <span v-if="hasError">{{ error.response.data.detail }}</span>
    }
  },
  actions: {
    async sendCommand ({ commit }, command) {
      commit(SENDING_COMMAND, command)
      try {
        const response = await ImporterAPI.sendCommand(command)
        commit(COMMAND_SUCCESS, { command: command, message: response.data })
        return response.data
      } catch (error) {
        console.log(error)
        commit(COMMAND_ERROR, { command: command, error: error })
        return null
      }
    }
  }
}
