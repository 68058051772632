import classesAPI from '../api/classes'

const FETCHING_CLASSES_LIST = 'FETCHING_CLASSES_LIST'
const FETCHING_CLASSES_LIST_SUCCESS = 'FETCHING_CLASSES_LIST_SUCCESS'
const FETCHING_CLASSES_LIST_ERROR = 'FETCHING_CLASSES_LIST_ERROR'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    classesList: []
  },
  getters: {
    isLoading (state) {
      return state.isLoading
    },
    error (state) {
      return state.error
    },
    errorMessage (state) {
      return state.error.response.data.detail
    },
    classesList (state) {
      return state.classesList
    }
  },
  mutations: {
    [FETCHING_CLASSES_LIST] (state) {
      state.isLoading = true
      state.classesList = []
      state.error = null
    },
    [FETCHING_CLASSES_LIST_SUCCESS] (state, data) {
      state.error = null
      state.classesList = data
      state.isLoading = false
    },
    [FETCHING_CLASSES_LIST_ERROR] (state, error) {
      state.classesList = false
      state.error = error
    }
  },
  actions: {
    async classesList ({ commit }, data) {
      commit(FETCHING_CLASSES_LIST)
      try {
        const response = await classesAPI.list(data)
        commit(FETCHING_CLASSES_LIST_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_CLASSES_LIST_ERROR, error)
        return null
      }
    },
    async setSuperClass ({ commit }, data) {
      try {
        const response = await classesAPI.setSuperClass(data)
        return response.data
      } catch (error) {
        return null
      }
    }
  }
}
