<template>
  <div class="container">
    <v-progress-circular
      v-if="isInitializing != 0"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <v-btn
        v-if="treeItems"
        color="green"
        class="mb-2 ml-2"
        tile
        :width="173"
        elevation="0"
        small
        dark
        :loading="isSaving"
        @click="saveTree"
      >
        <v-icon
          small
          class="mr-1"
        >
          mdi-content-save
        </v-icon>
        {{ $t('messages.Salva Modifiche') }}
      </v-btn>

      <v-treeview
        v-if="treeItems"
        ref="mytreeview"
        style="margin-left:-24px"
        dense
        :item-key="'id'"
        :items="treeItems"
        :open.sync="open"
      >
        <template #label="{ item }">
          <div
            v-if="!item.cd_agent"
            class="d-flex node-agent"
            :class="(!item.children || item.children.length === 0) ? 'ml-6' : ''"
          >
            <v-icon
              class="ml-1"
              @click="editAgent(item)"
            >
              mdi-account
            </v-icon>
            <v-icon
              v-if="item.name != 'APAWP_Admin'"
              small
              class="ml-1"
              color="primary"
              @click="moveNode(item, 1)"
            >
              mdi-arrow-down
            </v-icon>
            <v-icon
              v-if="item.name != 'APAWP_Admin'"
              small
              class="mr-1"
              color="primary"
              @click="moveNode(item, -1)"
            >
              mdi-arrow-up
            </v-icon>
            {{ item.name == 'APAWP_Admin' ? 'root' : item.name.split('@')[0] }}
            <v-icon
              small
              class="ml-1"
              color="primary"
              @click="openSelectorSubAgent(item)"
            >
              mdi-plus-circle
            </v-icon>
            <v-icon
              v-if="item.name != 'APAWP_Admin'"
              small
              class="ml-1"
              color="primary"
              @click="openSelectorArea(item)"
            >
              mdi-web
            </v-icon>
            <v-icon
              v-if="item.name != 'APAWP_Admin'"
              small
              class="ml-1"
              color="error"
              @click="removeAgent(item)"
            >
              mdi-close
            </v-icon>
          </div>
          <div
            v-else
            class="d-flex node-area"
          >
            <v-chip
              small
              close
              class="white--text area-child"
              color="primary"
              @click:close="removeArea(item)"
            >
              {{ item.name }}
            </v-chip>
          </div>
        </template>
      </v-treeview>
    </div>
    <v-dialog
      v-model="dialogAddSubAgent.isOpen"
      max-width="600"
    >
      <v-card v-if="dialogAddSubAgent.item">
        <v-card-title class="headline">
          {{ $t('messages.Aggiungi sottoagente a #agente#', [dialogAddSubAgent.item.name.split('@')[0]]) }}
        </v-card-title>

        <div
          style="min-width: 300px"
          class="px-3"
        >
          <v-data-table
            :headers="[{ text: 'Agenti', value: 'user_login' }]"
            :items="agents"
            :search="agentFilter"
            :items-per-page="-1"
            height="300px"
            dense
            fixed-header
            hide-default-footer
            :mobile-breakpoint="0"
          >
            <template
              #top
            >
              <v-text-field
                v-model="agentFilter"
                append-icon="mdi-magnify"
                label="Filtro Agente"
                single-line
                hide-details
              />
            </template>
            <template #[`item.user_login`]="{ item }">
              <div class="d-flex">
                <v-icon
                  small
                  class="mr-1"
                  color="primary"
                  @click="selectedSubAgent(item)"
                >
                  mdi-plus-circle
                </v-icon>
                <span
                  class="px-1"
                  :style="'background-color: ' + (item.isFather ? '#ffbebe': (item.isAssigned ? '#ffe9be': ''))"
                >{{ item.user_login.split('@')[0] }}</span>
              </div>
            </template>
          </v-data-table>
        </div>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            tile
            @click="dialogAddSubAgent.item = null; dialogAddSubAgent.isOpen = false"
          >
            {{ $t('messages.Chiudi') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAddArea.isOpen"
      max-width="600"
    >
      <v-card v-if="dialogAddArea.item">
        <v-card-title class="headline">
          {{ $t('messages.Aggiungi area a #agente#', [dialogAddArea.item.name.split('@')[0]]) }}
        </v-card-title>

        <div
          style="min-width: 300px"
          class="px-3"
        >
          <v-data-table
            :headers="[{ text: 'Codice', value: 'cd_agent' }, { text: 'Nome', value: 'nome' }]"
            :items="areas"
            dense
            :search="areaFilter"
            :items-per-page="-1"
            height="300px"
            fixed-header
            hide-default-footer
            :mobile-breakpoint="0"
          >
            <template
              #top
            >
              <v-text-field
                v-model="areaFilter"
                append-icon="mdi-magnify"
                label="Filtro Area"
                single-line
                hide-details
              />
            </template>
            <template #[`item.cd_agent`]="{ item }">
              <div class="d-flex">
                <v-icon
                  small
                  class="mr-1"
                  color="primary"
                  @click="selectedArea(item)"
                >
                  mdi-plus-circle
                </v-icon>
                <span
                  class="px-1"
                  :style="'background-color: ' + (item.isAssigned ? '#ffe9be': '')"
                >{{ item.cd_agent }}</span>
              </div>
            </template>
          </v-data-table>
        </div>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            tile
            @click="dialogAddArea.item = null; dialogAddArea.isOpen = false"
          >
            {{ $t('messages.Chiudi') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog
      v-model="confirmDialogParams.isOpen"
      :title="confirmDialogParams.title"
      :title-secondary="confirmDialogParams.titleSecondary"
      :color-secondary="confirmDialogParams.colorSecondary"
      :description="confirmDialogParams.description"
      :color="confirmDialogParams.color"
      @confirm="confirmDialogParams.confirm(confirmDialogParams.item, $event); confirmDialogParams.isOpen=false"
      @cancel="confirmDialogParams.isOpen=false"
    />
  </div>
</template>

<script>
import ConfirmDialog from '../../components/ConfirmDialog'

export default {
  name: 'AreaAssign',
  components: {
    ConfirmDialog
  },
  data: () => ({
    isInitializing: 1,
    agents: [],
    areas: [],
    agentsHashMap: {},
    areasHashMap: {},
    treeItems: [],
    treeHashMap: {},
    agentFilter: '',
    areaFilter: '',
    dialogAddSubAgent: { isOpen: false, item: null },
    dialogAddArea: { isOpen: false, item: null },
    dialogEditAgent: { isOpen: false, item: null },
    confirmDialogParams: { title: '', description: '', confirm: () => {}, isOpen: false, item: null, titleSecondary: '', colorSecondary: '' },
    open: [],
    isSaving: false
  }),

  computed: {
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    errorMessage () {
      return this.$store.getters['agents/errorMessage']
    }
  },

  async created () {
    this.isInitializing = 2
    this.$store.dispatch('agents/listAgents').then((r) => { this.agents = r.agents; this.areas = r.areas; this.open = r.open; this.isInitializing--; this.initialize() })
    this.$store.dispatch('agents/listAreaAgents').then((r) => { this.treeItems = r; this.isInitializing--; this.initialize() })
  },

  methods: {
    async saveTree () {
      this.confirmDialogParams.title = this.$t('messages.Salva')
      this.confirmDialogParams.titleSecondary = ''
      this.confirmDialogParams.colorSecondary = '#C4C2BB'
      this.confirmDialogParams.description = this.$t('messages.Salvare le modifiche effettuate?')
      this.confirmDialogParams.confirm = this.saveTreeConfrim
      this.confirmDialogParams.color = 'success'
      this.confirmDialogParams.item = null
      this.confirmDialogParams.isOpen = true
    },
    async saveTreeConfrim () {
      this.isSaving = true
      const r = await this.$store.dispatch('agents/saveTree', { tree: this.treeItems, open: this.open })
      if (!r) {
        const messageData = { isOpen: true, color: 'error', message: this.errorMessage }
        this.$store.dispatch('appState/setMessageSnackbar', messageData)
        this.isSaving = false
        return
      }

      const dataMessage = { isOpen: true, message: this.$t('messages.Modifiche Salvate'), color: 'success' }
      this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      this.isSaving = false
    },
    removeArea (item) {
      this.confirmDialogParams.title = this.$t('messages.Rimuovi')
      this.confirmDialogParams.titleSecondary = ''
      this.confirmDialogParams.colorSecondary = '#C4C2BB'
      this.confirmDialogParams.description = this.$t('messages.Rimuovere l area #nome#?', [item.name])
      this.confirmDialogParams.confirm = this.confirmRemoveArea
      this.confirmDialogParams.color = 'error'
      this.confirmDialogParams.item = item
      this.confirmDialogParams.isOpen = true
    },
    confirmRemoveArea (item) {
      const removingIndex = this.treeHashMap['id_' + item.parent_id].children.findIndex((c) => c.id === item.id)
      this.treeHashMap['id_' + item.parent_id].children.splice(removingIndex, 1)
      if (this.areasHashMap[item.id] && this.areasHashMap[item.id].isAssigned) {
        this.areasHashMap[item.id].isAssigned = false
      }
      this.updateTree()
    },

    openSelectorArea (item) {
      this.dialogAddArea.item = item
      this.dialogAddArea.isOpen = true
    },
    selectedArea (item) {
      /*  // REMOVED CHECK IF IS AREADY ASSIGNED
      if (item.isAssigned) {
        this.confirmDialogParams.title = 'Sposta'
        this.confirmDialogParams.titleSecondary = 'Aggiungi'
        this.confirmDialogParams.colorSecondary = 'primary'
        this.confirmDialogParams.description = 'Attenzione\nL\'area selezionata è già assegnata.\nSpostare l\'area ' + item.cd_agent + ' o aggiungerla come nuova?'
        this.confirmDialogParams.confirm = this.confirmArea
        this.confirmDialogParams.color = 'warning'
        this.confirmDialogParams.item = item
        this.confirmDialogParams.isOpen = true
        return
      } */ // REMOVED CHECK IF IS AREADY ASSIGNED

      this.confirmDialogParams.title = 'Conferma'
      this.confirmDialogParams.titleSecondary = ''
      this.confirmDialogParams.colorSecondary = '#C4C2BB'
      this.confirmDialogParams.description = this.$t('messages.Inserire l area #nome#?', [item.cd_agent])
      this.confirmDialogParams.confirm = this.confirmArea
      this.confirmDialogParams.color = 'primary'
      this.confirmDialogParams.item = item
      this.confirmDialogParams.isOpen = true
    },
    confirmArea (item, event) {
      if (!this.dialogAddArea.item.children) {
        this.dialogAddArea.item.children = []
      } else {
        if (this.dialogAddArea.item.children.find(i => i.id === 'zn_' + item.cd_agent)) {
          return
        }
      }
      const newParent = this.dialogAddArea.item
      // if (!item.isAssigned) { // REMOVED CHECK IF IS AREADY ASSIGNED
      // PUSH CHILD ALSO IN TREENODE STRUCTURE
      // ---
      const key = newParent.id
      const parentNode = this.$refs.mytreeview.nodes[key]

      const childNode = { ...parentNode, item: item, vnode: null }
      this.$refs.mytreeview.nodes['zn_' + item.cd_agent] = childNode
      // ---
      const newChild =
          {
            parent_id: newParent.id,
            id: 'zn_' + item.cd_agent,
            cd_agent: item.cd_agent,
            name: item.nome
          }
      newParent.children.unshift(newChild)
      this.areasHashMap['zn_' + item.cd_agent].isAssigned = true
      // this.treeHashMap['id_' + 'zn_' + item.cd_agent] = newChild

      // FORCE RERENDER
      const granpaIndex = this.open.findIndex((o) => o === newParent.parent_id)
      if (granpaIndex >= 0) {
        this.open.splice(granpaIndex, 1)
        setTimeout(() => { this.open.push(newParent.parent_id) }, 10)
      }
      // } // REMOVED CHECK IF IS AREADY ASSIGNED
      /* else if (item.isAssigned) {
        const itemNode = this.treeHashMap['id_' + 'zn_' + item.cd_agent]
        const removingIndex = this.treeHashMap['id_' + itemNode.parent_id].children.findIndex((c) => c.id === itemNode.id)
        const removedNode = this.treeHashMap['id_' + itemNode.parent_id].children.splice(removingIndex, 1)[0]
        removedNode.parent_id = newParent.id
        newParent.children.push(removedNode)
        this.updateTree()
      } */ // END REMOVED CHECK IF IS AREADY ASSIGNED
      const parentIndex = this.open.findIndex((o) => o === newParent.id)
      if (parentIndex >= 0) {
        this.open.splice(parentIndex, 1)
      }
      setTimeout(() => { this.open.push(newParent.id) }, 10)
    },

    openSelectorSubAgent (item) {
      this.dialogAddSubAgent.item = item
      this.dialogAddSubAgent.isOpen = true

      // calcola parentele
      this.agents.forEach((a) => {
        a.isFather = false
        a.isSelf = false
      })
      this.setAgentIsFather(this.treeItems[0], item.id)
    },
    selectedSubAgent (item) {
      if (item.isSelf) {
        const dataMessage = { isOpen: true, message: this.$t('messages.Impossibile assegnare l agente a se stesso'), color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        return
      }

      if (item.isFather) {
        const dataMessage = { isOpen: true, message: this.$t('messages.Impossibile assegnare all agente un elemento direttamente ascendente'), color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        return
      }

      if (item.isAssigned) {
        this.confirmDialogParams.title = this.$t('messages.Conferma')
        this.confirmDialogParams.titleSecondary = ''
        this.confirmDialogParams.colorSecondary = '#C4C2BB'
        this.confirmDialogParams.description = this.$t('messages.Attenzione L agente selezionato è già assegnatop Spostare l agente #nome# insieme a tutta la sua struttura discendente?', [item.user_login.split('@')[0]])
        this.confirmDialogParams.confirm = this.confirmSubAgent
        this.confirmDialogParams.color = 'warning'
        this.confirmDialogParams.item = item
        this.confirmDialogParams.isOpen = true
        return
      }

      this.confirmDialogParams.title = this.$t('messages.Conferma')
      this.confirmDialogParams.titleSecondary = ''
      this.confirmDialogParams.colorSecondary = '#C4C2BB'
      this.confirmDialogParams.description = this.$t('messages.Inserire l agente #nome#?', [item.user_login.split('@')[0]])
      this.confirmDialogParams.confirm = this.confirmSubAgent
      this.confirmDialogParams.color = 'primary'
      this.confirmDialogParams.item = item
      this.confirmDialogParams.isOpen = true
    },
    confirmSubAgent (item) {
      if (!this.dialogAddSubAgent.item.children) {
        this.dialogAddSubAgent.item.children = []
      }
      const newParent = this.dialogAddSubAgent.item

      if (!item.isAssigned) {
        // PUSH CHILD ALSO IN TREENODE STRUCTURE
        // ---
        const key = newParent.id
        const parentNode = this.$refs.mytreeview.nodes[key]

        const childNode = { ...parentNode, item: item, vnode: null }
        this.$refs.mytreeview.nodes[item.id] = childNode
        // ---
        const newChild =
          {
            parent_id: newParent.id,
            id: item.id,
            name: item.user_login
          }

        // trovo il primo elemento che non sia area, e lo aggiungo come primo elemento agente
        let firstIndexNonArea = 0
        for (firstIndexNonArea = 0; firstIndexNonArea < newParent.children.length; firstIndexNonArea++) {
          if (!newParent.children[firstIndexNonArea].cd_agent) {
            break
          }
        }
        newParent.children.splice(firstIndexNonArea, 0, newChild)

        this.agentsHashMap['id_' + item.id].isAssigned = true
        this.treeHashMap['id_' + item.id] = newChild

        // FORCE RERENDER
        const granpaIndex = this.open.findIndex((o) => o === newParent.parent_id)
        if (granpaIndex >= 0) {
          this.open.splice(granpaIndex, 1)
          setTimeout(() => { this.open.push(newParent.parent_id) }, 10)
        }
      } else if (item.isAssigned) {
        const itemNode = this.treeHashMap['id_' + item.id]
        const removingIndex = this.treeHashMap['id_' + itemNode.parent_id].children.findIndex((c) => c.id === itemNode.id)
        const removedNode = this.treeHashMap['id_' + itemNode.parent_id].children.splice(removingIndex, 1)[0]
        removedNode.parent_id = newParent.id
        newParent.children.push(removedNode)
        this.updateTree()
      }
      const parentIndex = this.open.findIndex((o) => o === newParent.id)
      if (parentIndex >= 0) {
        this.open.splice(parentIndex, 1)
      }
      setTimeout(() => { this.open.push(newParent.id) }, 10)
    },
    moveNode (item, direction) {
      const movingIndex = this.treeHashMap['id_' + item.parent_id].children.findIndex((c) => c.id === item.id)
      const parentItem = this.treeHashMap['id_' + item.parent_id]
      if ((direction < 0 && movingIndex !== 0) || (direction > 0 && movingIndex !== (parentItem.children.length - 1))) {
        if (!parentItem.children[movingIndex + direction].cd_agent) {
          parentItem.children.splice(movingIndex + direction, 0, parentItem.children.splice(movingIndex, 1)[0])
        }
      }
      // this.updateTree()
    },
    removeAgent (item) {
      this.confirmDialogParams.title = this.$t('messages.Elimina')
      this.confirmDialogParams.description = this.$t('messages.Eliminare l agente #nome# e tutta la sottostruttura?', [item.name.split('@')[0]])
      this.confirmDialogParams.confirm = this.confirmRemoveAgent
      this.confirmDialogParams.color = 'error'
      this.confirmDialogParams.item = item
      this.confirmDialogParams.isOpen = true
    },
    removeIsAssignedChildren (item) {
      if (this.agentsHashMap['id_' + item.id] && this.agentsHashMap['id_' + item.id].isAssigned) {
        this.agentsHashMap['id_' + item.id].isAssigned = false
      }
      if (this.treeHashMap['id_' + item.id].children) {
        this.treeHashMap['id_' + item.id].children.forEach(c => {
          this.removeIsAssignedChildren(c)
        })
      }
      delete this.treeHashMap['id_' + item.id]
    },
    confirmRemoveAgent (item) {
      this.removeIsAssignedChildren(item)

      const removingIndex = this.treeHashMap['id_' + item.parent_id].children.findIndex((c) => c.id === item.id)
      this.treeHashMap['id_' + item.parent_id].children.splice(removingIndex, 1)
      this.updateTree()
    },

    editAgent (item) {
      console.log(item)
    },

    // funzioni ricorsive per impstare i parametri albero
    setAgentIsFather (node, id) {
      if (node.id === id) {
        if (this.agentsHashMap['id_' + node.id]) {
          this.agentsHashMap['id_' + node.id].isSelf = true
        }
        return true
      }
      if (node.children) {
        if (node.children.some((n) => this.setAgentIsFather(n, id))) {
          if (this.agentsHashMap['id_' + node.id]) {
            this.agentsHashMap['id_' + node.id].isFather = true
          }
          return true
        }
        return false
      } else {
        return false
      }
    },
    setAgentAssigned (node) {
      this.treeHashMap['id_' + node.id] = node
      if (node.id !== '1') {
        if (this.agentsHashMap['id_' + node.id]) {
          this.agentsHashMap['id_' + node.id].isAssigned = true
        }
        if (this.areasHashMap[node.id]) {
          this.areasHashMap[node.id].isAssigned = true
        }
      }
      if (node.children) {
        node.children.forEach((n) => this.setAgentAssigned(n))
      }
    },
    updateTree () {
      const back = this.treeItems
      this.treeItems = null
      const back2 = this.open
      setTimeout(() => { this.treeItems = back; this.open = back2 }, 10)
    },
    initialize () {
      if (this.isInitializing > 0) return
      this.agents.forEach((a) => {
        this.agentsHashMap['id_' + a.id] = a
      })
      this.areas.forEach((a) => {
        this.areasHashMap['zn_' + a.cd_agent] = a
      })
      this.setAgentAssigned(this.treeItems[0])
      this.$nextTick(() => {
        // this.$refs.mytreeview.updateAll(true)
      })
    }
  }
}
</script>
<style scoped>
>>> .v-data-table > .v-data-table__wrapper > table > thead > tr > th, >>>.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0px;
  padding-right: 4px;
}
</style>
<style>
.v-treeview-node__level {
  width: 0px !important;
}
.v-treeview-node {
  border: white;
  background-color: #ebebeb;
  border-top-width: 1px;
  border-left-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 1px;
  border-style: solid;
  margin-left: 24px;
}
/*
.v-treeview-node.v-treeview-node--leaf {
  border: white;
  background-color: #ebebeb;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-style: solid;
  margin-left: 24px;
}*/
.area-child {
  margin-left: 0px;
}
</style>
