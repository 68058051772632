<template>
  <div class="container">
    <v-progress-circular
      v-if="isInitializing"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <v-row>
        <v-col>
          <div>
            <h1>{{ $t('messages.Microaree') }}</h1>
          </div>
        </v-col>
      </v-row>

      <v-btn
        color="primary"
        dark
        class="btn btn-lg mr-1"
        type="button"
        :loading="isLoading"
        elevation="0"
        tile
        @click="addNewConfirmDialog = true"
      >
        {{ $t('messages.Aggiungi Microarea') }}
      </v-btn>

      <div
        v-for="(microarea, i) in microAreasVisibleToAgent"
        :key="i"
        class="d-flex"
      >
        <v-text-field
          :value="microarea.name"
          hide-details
          class="persistent-placeholder"
          :loading="!!(dirtyMicroareas[i] && dirtyMicroareas[i].isLoading)"
          @input="setDirty(microarea.name, i, $event)"
        />
        <div class="d-flex align-end">
          <v-icon
            medium
            color="primary"
            :disabled="!(dirtyMicroareas[i] && dirtyMicroareas[i].isDirty) || !!(dirtyMicroareas[i] && dirtyMicroareas[i].isLoading)"
            @click="saveMicroarea(i)"
          >
            mdi-content-save
          </v-icon>
        </div>
        <div class="d-flex align-end">
          <v-icon
            medium
            color="error"
            :disabled="!!(dirtyMicroareas[i] && dirtyMicroareas[i].isLoading)"
            @click="openMicroareaConfirmDialog(microarea.name, i)"
          >
            mdi-delete
          </v-icon>
        </div>
      </div>
      <confirm-dialog
        v-model="confirmDeleteDialog"
        color="error"
        :title="$t('messages.Rimuovi')"
        :description="'Sei sicuro di voler eliminare questa Microarea?'"
        @confirm="deleteMicroareaConfirm(); confirmDeleteDialog=false"
        @cancel="confirmDeleteDialog=false"
      />
      <v-dialog
        v-model="addNewConfirmDialog"
        content-class="my-custom-dialog"
        max-width="290"
        persistent
      >
        <v-card>
          <v-card-text class="pt-4 justify-center">
            <v-text-field
              v-model="newMicroarea"
              label="Nome Microarea"
              class="persistent-placeholder"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="rgba(0, 0, 0, 0.06) white--text"
              class="mr-2"
              tile
              elevation="0"
              :loading="addingNew"
              @click="addNewConfirmDialog=false"
            >
              {{ $t('messages.Annulla') }}
            </v-btn>
            <v-btn
              color="primary"
              tile
              dark
              elevation="0"
              :loading="addingNew"
              @click="addNew"
            >
              {{ $t('messages.Salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row>
        <v-col>
          <div class="mt-4">
            <h1>{{ $t('messages.Clienti') }}</h1>
          </div>
        </v-col>
      </v-row>
      <area-selector
        v-model="searchFilter.area"
        :areas="areasVisibleToAgent"
        @input="debounceSearch"
      />
      <micro-area-selector
        v-model="searchFilter.microArea"
        :microareas="microAreasVisibleToAgent"
        class="mb-2"
        @input="debounceSearch"
      />

      <h4>{{ $t('messages.Data Ultimo Ordine') }}:</h4>
      <div
        class="d-flex justify-space-between mb-2"
      >
        <v-menu
          v-model="invoiceDateFromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="formatDate(searchFilter.invoiceDateFrom)"
              :label="$t('messages.Da')"
              :placeholder="$t('messages.Seleziona una data')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              style="font-size: 0.665rem; width:40px;"
              class="mr-1"
              hide-details
              clearable
              @click:clear="searchFilter.invoiceDateFrom = null"
              v-on="on"
              @input="debounceSearch"
            />
          </template>
          <v-date-picker
            v-model="searchFilter.invoiceDateFrom"
            :locale="$t('messages.locale')"
            :first-day-of-week="1"
            @input="invoiceDateFromMenu = false"
            @change="debounceSearch"
          />
        </v-menu>

        <v-menu
          v-model="invoiceDateToMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="formatDate(searchFilter.invoiceDateTo)"
              :label="$t('messages.A')"
              :placeholder="$t('messages.Seleziona una data')"
              prepend-icon="mdi-calendar"
              readonly
              hide-details
              v-bind="attrs"
              style="font-size: 0.665rem; width:40px;"
              clearable
              @click:clear="searchFilter.invoiceDateTo = null"
              v-on="on"
              @input="debounceSearch"
            />
          </template>
          <v-date-picker
            v-model="searchFilter.invoiceDateTo"
            :locale="$t('messages.locale')"
            :first-day-of-week="1"
            @input="invoiceDateToMenu = false"
            @change="debounceSearch"
          />
        </v-menu>
      </div>

      <h4>{{ $t('messages.Data Ultima Visita') }}:</h4>
      <div
        class="d-flex justify-space-between mb-2"
      >
        <v-menu
          v-model="visitDateFromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="formatDate(searchFilter.visitDateFrom)"
              :label="$t('messages.Da')"
              :placeholder="$t('messages.Seleziona una data')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              style="font-size: 0.665rem; width:40px;"
              class="mr-1"
              hide-details
              clearable
              @click:clear="searchFilter.visitDateFrom = null"
              v-on="on"
              @input="debounceSearch"
            />
          </template>
          <v-date-picker
            v-model="searchFilter.visitDateFrom"
            :locale="$t('messages.locale')"
            :first-day-of-week="1"
            @input="visitDateFromMenu = false"
            @change="debounceSearch"
          />
        </v-menu>

        <v-menu
          v-model="visitDateToMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="formatDate(searchFilter.visitDateTo)"
              :label="$t('messages.A')"
              :placeholder="$t('messages.Seleziona una data')"
              prepend-icon="mdi-calendar"
              readonly
              hide-details
              v-bind="attrs"
              style="font-size: 0.665rem; width:40px;"
              clearable
              @click:clear="searchFilter.visitDateTo = null"
              v-on="on"
              @input="debounceSearch"
            />
          </template>
          <v-date-picker
            v-model="searchFilter.visitDateTo"
            :locale="$t('messages.locale')"
            :first-day-of-week="1"
            @input="visitDateToMenu = false"
            @change="debounceSearch"
          />
        </v-menu>
      </div>

      <h4>{{ $t('messages.Visualizza') }}:</h4>
      <div class="d-flex flex-row mb-2">
        <v-radio-group
          v-model="searchFilter.clientsOrProspectors"
          class="mt-0"
          hide-details
          row
          @change="debounceSearch"
        >
          <v-radio
            :label="$t('messages.Tutti')"
            value="all"
          />
          <v-radio
            :label="$t('messages.Projx')"
            value="projx"
          />
          <v-radio
            :label="$t('messages.Prospects')"
            value="prospectors"
          />
        </v-radio-group>
      </div>
      <div class="d-flex flex-row mb-2">
        <v-radio-group
          v-model="searchFilter.view"
          class="mt-0"
          hide-details
          row
          @change="debounceSearch"
        >
          <v-radio
            :label="$t('messages.Tutti')"
            value="all"
          />
          <v-radio
            :label="$t('messages.Non presenti in mappa')"
            value="notOnMap"
          />
          <v-radio
            :label="$t('messages.Presenti in mappa')"
            value="onMap"
          />
        </v-radio-group>
      </div>

      <h4>{{ $t('messages.Numero Stelle') }}:</h4>
      <div class="d-flex flex-row mb-1">
        <v-checkbox
          v-model="searchFilter.starszero"
          hide-details
          class="mx-2 my-1"
          dense
          label="0*"
          @change="debounceSearch"
        />
        <v-checkbox
          v-model="searchFilter.starsone"
          hide-details
          class="mx-2 my-1"
          dense
          label="1*"
          @change="debounceSearch"
        />
        <v-checkbox
          v-model="searchFilter.starstwo"
          hide-details
          class="mx-2 my-1"
          dense
          label="2*"
          @change="debounceSearch"
        />
        <v-checkbox
          v-model="searchFilter.starsthree"
          hide-details
          class="mx-2 my-1"
          dense
          label="3*"
          @change="debounceSearch"
        />
      </div>

      <h4>{{ $t('messages.Cerca in') }}:</h4>
      <div class="d-flex flex-wrap">
        <v-checkbox
          v-model="searchFilter.cdClien"
          hide-details
          class="mx-2 my-1"
          dense
          :label="$t('messages.CodpCliente')"
          @change="debounceSearch"
        />
        <v-checkbox
          v-model="searchFilter.azienda"
          hide-details
          class="mx-2 my-1"
          dense
          :label="$t('messages.Ragione Sociale')"
          @change="debounceSearch"
        />
        <v-checkbox
          v-model="searchFilter.piva"
          hide-details
          class="mx-2 my-1"
          dense
          :label="$t('messages.PpIVA')"
          @change="debounceSearch"
        />
        <v-checkbox
          v-model="searchFilter.citta"
          hide-details
          class="mx-2 my-1"
          dense
          :label="$t('messages.Città')"
          @change="debounceSearch"
        />
        <v-checkbox
          v-model="searchFilter.cap"
          hide-details
          class="mx-2 my-1"
          dense
          :label="$t('messages.CAP')"
          @change="debounceSearch"
        />
      </div>
      <v-text-field
        v-model="searchFilter.search"
        filled
        dense
        hide-details
        :loading="isLoadingFilter"
        :placeholder="$t('messages.Cerca ppp')"
        class=""
        @input="debounceSearch"
      />

      <div>
        <h5 class="font-weight-black my-2">
          {{ selectedClients.length }} selezionati.
        </h5>
      </div>
      <v-data-table
        :headers="headers"
        :items="filteredClientList"
        :loading="isLoading"
        :locale="$t('messages.locale')"
        elevation="0"
        :header-props="{ sortByText: $t('messages.Ordina per')}"
        :footer-props="{
          itemsPerPageAllText: $t('messages.Tutte'),
          itemsPerPageText: $t('messages.Per pagina')
        }"
        :page="searchFilter.page"
        :items-per-page="searchFilter.itemsPerPage"
        :sort-by.sync="searchFilter.sortBy"
        :sort-desc.sync="searchFilter.sortDesc"
      >
        <template #[`header.selection`]="">
          <v-btn
            type="button"
            class="px-0"
            color="grey"
            icon
            @click="setSelectedAll()"
          >
            <v-icon>
              mdi-checkbox-multiple-marked
            </v-icon>
          </v-btn>
        </template>
        <template #[`item.selection`]="{ item }">
          <v-checkbox
            v-model="item.selected"
            hide-details
            dense
            @change="setSelected(item)"
          />
        </template>
        <template #[`item.cd_clien`]="{ item }">
          <div class="d-flex align-center">
            <img
              v-if="item.is_projx === '1'"
              src="/logo.svg"
              width="24"
              class="mr-2"
            >
            <span>{{ item.cd_clien }}</span>
          </div>
        </template>

        <template #[`item.azienda`]="{ item }">
          <div class="d-flex align-center">
            <router-link
              :to="'/client/details/' + item.id"
            >
              <span>{{ item.azienda }}</span>
            </router-link>
          </div>
        </template>

        <template #[`footer.page-text`]="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} {{ $t('messages.di') }} {{ itemsLength }}
        </template>
        <template #[`item.last_invoice_date`]="{ item }">
          {{ formatDate(item.last_invoice_date) }}
        </template>
        <template #[`item.last_visit_date`]="{ item }">
          {{ formatDate(item.last_visit_date) }}
        </template>
      </v-data-table>
      <div>
        <div>
          <h5 class="font-weight-black  mb-2">
            {{ $t('messages.#Numero# selezionatip', [selectedClients.length]) }}
          </h5>
        </div>
        <v-btn
          color="primary"
          dark
          class="btn btn-lg mr-3 mb-2"
          type="button"
          :loading="isLoading"
          elevation="0"
          tile
          @click="addToMicroareaDialog = true"
        >
          {{ $t('messages.Assegna selezionati a microarea') }}
        </v-btn>
        <v-btn
          color="secondary"
          dark
          class="btn btn-lg mr-1 mb-2"
          type="button"
          :loading="isLoading"
          elevation="0"
          tile
          @click="clearSelection"
        >
          {{ $t('messages.Pulisci selezione') }}
        </v-btn>
      </div>
      <v-dialog
        v-model="addToMicroareaDialog"
        content-class="my-custom-dialog"
        max-width="290"
        persistent
      >
        <v-card>
          <v-card-text class="pt-4 justify-center">
            <micro-area-selector
              v-model="selectedAddToMicroarea"
              :microareas="microAreasVisibleToAgent"
              class="mb-2"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="rgba(0, 0, 0, 0.06) white--text"
              class="mr-2"
              tile
              elevation="0"
              :loading="addingNew"
              @click="addToMicroareaDialog=false"
            >
              {{ $t('messages.Annulla') }}
            </v-btn>
            <v-btn
              color="primary"
              tile
              :dark="!!selectedAddToMicroarea"
              elevation="0"
              :disabled="!selectedAddToMicroarea"
              :loading="addingToMicroarea"
              @click="addToMicroarea"
            >
              {{ $t('messages.Salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import AreaSelector from '../components/AreaSelector'
import MicroAreaSelector from '../components/MicroAreaSelector'
import ConfirmDialog from '../components/ConfirmDialog'
export default {
  name: 'MicroareaList',

  components: {
    ConfirmDialog,
    AreaSelector,
    MicroAreaSelector
  },
  data: () => ({
    isInitializing: 1,
    confirmDeleteDialog: false,
    dirtyMicroareas: [],
    deletingMicroarea: null,
    addNewConfirmDialog: false,
    newMicroarea: '',
    selectedAddToMicroarea: '',
    addingToMicroarea: false,
    addToMicroareaDialog: false,
    addingNew: false,
    searchFilter: {
      search: '',
      cdClien: true,
      azienda: true,
      piva: true,
      citta: true,
      starszero: true,
      starsone: true,
      starstwo: true,
      starsthree: true,
      microArea: '',
      area: '',
      clientsOrProspectors: 'all',
      view: 'all',
      invoiceDateFrom: null,
      invoiceDateTo: null,
      visitDateFrom: null,
      visitDateTo: null,
      page: 1,
      itemsPerPage: 10,
      sortBy: '',
      sortDesc: false
    },
    filteredClientList: [],
    filterDebounce: null,
    isLoadingFilter: false,
    invoiceDateFromMenu: false,
    invoiceDateToMenu: false,
    visitDateFromMenu: false,
    visitDateToMenu: false,
    isLoading: false,
    clientStars: ['0', '1', '2', '3'],
    selectedClients: []
  }),

  computed: {
    areasVisibleToAgent () {
      return this.$store.getters['agents/areasVisibleToAgent']
    },
    microAreasVisibleToAgent () {
      return this.$store.getters['agents/microAreasVisibleToAgent']
    },
    headers () {
      return [
        { text: '', value: 'selection', width: '60px', sortable: false },
        { text: this.$t('messages.CodpCliente'), value: 'cd_clien', filterable: true },
        { text: this.$t('messages.Ragione Sociale'), value: 'azienda', filterable: false },
        { text: this.$t('messages.Città'), value: 'citta', filterable: false },
        { text: this.$t('messages.CAP'), value: 'cap', filterable: false },
        { text: this.$t('messages.Microarea'), value: 'microarea', filterable: false },
        { text: this.$t('messages.Ultimo Ordine'), value: 'last_invoice_date', filterable: false },
        { text: this.$t('messages.Ultima Visita'), value: 'last_visit_date', filterable: false }
      ]
    }
  },

  async created () {
    this.isInitializing = 3
    this.$store.dispatch('agents/areasVisibleToAgent').then(() => { this.isInitializing--; this.initialize() })
    this.$store.dispatch('agents/microAreasVisibleToAgent').then((r) => { this.isInitializing--; this.microAreas = r; this.initialize() })
    this.$store.dispatch('clients/clientsList').then((cl) => {
      if (cl !== null) {
        this.filteredClientList = cl
        this.isInitializing--
        this.initialize()
      }
    })
  },

  methods: {
    async deleteMicroareaConfirm () {
      const index = this.deletingMicroarea.index
      if (!this.dirtyMicroareas[index]) {
        this.dirtyMicroareas[index] = {}
      }
      this.dirtyMicroareas[index].isLoading = true
      this.dirtyMicroareas.splice(index, 1, this.dirtyMicroareas[index])

      const r = await this.$store.dispatch('agents/deleteMicroArea', this.deletingMicroarea)
      if (r) {
        this.dirtyMicroareas[index] = {
          isDirty: false,
          isLoading: false
        }
        this.dirtyMicroareas.splice(index, 1, this.dirtyMicroareas[index])
      } else {
        const dataMessage = { isOpen: true, message: this.$t('messages.Errore nella eliminazione della microarea'), color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        this.dirtyMicroareas[index].isLoading = false
        this.dirtyMicroareas.splice(index, 1, this.dirtyMicroareas[index])
      }
    },
    openMicroareaConfirmDialog (microarea, index) {
      this.confirmDeleteDialog = true
      this.deletingMicroarea = {
        microarea,
        index
      }
    },
    setDirty (microarea, index, value) {
      this.dirtyMicroareas[index] = {
        isDirty: true,
        isLoading: false,
        microarea,
        value
      }
      this.dirtyMicroareas.splice(index, 1, this.dirtyMicroareas[index])
    },
    async addNew () {
      this.addingNew = true
      const r = await this.$store.dispatch('agents/addMicroArea', { microarea: this.newMicroarea })
      if (r) {
        this.addNewConfirmDialog = false
        this.newMicroarea = ''
      } else {
        const dataMessage = { isOpen: true, message: this.$t('messages.Errore nell aggiunta della microarea'), color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
      this.addingNew = false
    },
    async saveMicroarea (index) {
      this.dirtyMicroareas[index].isDirty = false
      this.dirtyMicroareas[index].isLoading = true
      this.dirtyMicroareas.splice(index, 1, this.dirtyMicroareas[index])

      const r = await this.$store.dispatch('agents/editMicroArea', this.dirtyMicroareas[index])
      if (r) {
        this.dirtyMicroareas[index] = {
          isDirty: false,
          isLoading: false
        }
        this.dirtyMicroareas.splice(index, 1, this.dirtyMicroareas[index])
      } else {
        const dataMessage = { isOpen: true, message: this.$t('messages.Errore nell aggiornamento della microarea'), color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        this.dirtyMicroareas[index].isLoading = false
        this.dirtyMicroareas[index].isDirty = true
        this.dirtyMicroareas.splice(index, 1, this.dirtyMicroareas[index])
      }
    },

    initialize () {
      if (this.isInitializing === 0) {
        this.clearSelection()
        this.doFilter()
      }
    },

    openSearchMenu () {
      if (!(this.searchMenu === true)) {
        this.searchMenu = true
      } else this.searchMenu = false
    },
    closeSearchMenu () {
      this.searchMenu = false
    },

    debounceSearch () {
      clearTimeout(this.filterDebounce)
      this.isLoadingFilter = true
      this.filterDebounce = setTimeout(() => {
        // do filter
        this.doFilter()
        this.isLoadingFilter = false
      }, 1000)
    },

    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    },
    setSelected (item) {
      if (item.selected) {
        this.selectedClients.push(item.id)
      } else {
        const index = this.selectedClients.indexOf(item.id)
        if (index !== -1) {
          this.selectedClients.splice(index, 1)
        }
      }
    },
    setSelectedAll () {
      this.filteredClientList.forEach((c) => {
        c.selected = true
        this.selectedClients.push(c.id)
        // const index = this.selectedClients.indexOf(c.id)
      })

      const seen = {}
      this.selectedClients = this.selectedClients.filter(c => {
        return seen[c] ? false : (seen[c] = true)
      })
    },
    clearSelection () {
      const clientList = this.$store.getters['clients/clientsList']
      clientList.forEach(c => { c.selected = false })
      this.selectedClients = []
    },
    doFilter () {
      const clientList = this.$store.getters['clients/clientsList']
      this.filteredClientList = clientList.filter((c) => {
        const isFound = (this.searchFilter.search === '' || (
          (this.searchFilter.azienda && (c.azienda ?? '').toLowerCase().includes(this.searchFilter.search.toLowerCase())) ||
          (this.searchFilter.cdClien && (c.cd_clien ?? '').toLowerCase().includes(this.searchFilter.search.toLowerCase())) ||
          (this.searchFilter.piva && (c.piva ?? '').toLowerCase().includes(this.searchFilter.search.toLowerCase())) ||
          (this.searchFilter.citta && (c.citta ?? '').toLowerCase().includes(this.searchFilter.search.toLowerCase())) ||
          (this.searchFilter.cap && (c.cap ?? '').toLowerCase().includes(this.searchFilter.search.toLowerCase()))
        )
        ) &&
        ((this.searchFilter.microArea ?? '') === '' || ((c.microarea ?? '') === this.searchFilter.microArea)) &&
        ((this.searchFilter.area ?? '') === '' || ((c.cd_agent ?? '') === this.searchFilter.area)) &&
        ((this.searchFilter.clientsOrProspectors === 'all') || (
          ((this.searchFilter.clientsOrProspectors === 'projx') && ((c.is_projx ?? '') === '1')) ||
          ((this.searchFilter.clientsOrProspectors === 'prospectors') && ((c.is_projx ?? '') !== '1'))
        )
        ) &&
        ((this.searchFilter.view === 'all') || (
          ((this.searchFilter.view === 'notOnMap') && (((c.lat ?? '') === '') && ((c.lat_auto ?? '') === ''))) ||
          ((this.searchFilter.view === 'onMap') && (((c.lat ?? '') !== '') || ((c.lat_auto ?? '') !== '')))
        )
        ) &&
        (
          (!this.searchFilter.invoiceDateFrom && !this.searchFilter.invoiceDateTo) ||
          (
            ((this.searchFilter.invoiceDateFrom && this.searchFilter.invoiceDateTo) && // Caso valorizzato entrambi
            ((c.last_invoice_date ?? '') >= this.searchFilter.invoiceDateFrom) &&
            ((c.last_invoice_date ?? '') <= this.searchFilter.invoiceDateTo)) ||
            ((this.searchFilter.invoiceDateFrom && !this.searchFilter.invoiceDateTo) && // Caso valorizzato da
            ((c.last_invoice_date ?? '') >= this.searchFilter.invoiceDateFrom)) ||
            ((!this.searchFilter.invoiceDateFrom && this.searchFilter.invoiceDateTo) && // Caso valorizzato a
            ((c.last_invoice_date ?? '') <= this.searchFilter.invoiceDateTo) && ((c.last_invoice_date ?? '') !== ''))
          )
        ) &&
        (
          (!this.searchFilter.visitDateFrom && !this.searchFilter.visitDateTo) ||
          (
            ((this.searchFilter.visitDateFrom && this.searchFilter.visitDateTo) && // Caso valorizzato entrambi
            ((c.last_visit_date ?? '') >= this.searchFilter.visitDateFrom) &&
            ((c.last_visit_date ?? '') <= this.searchFilter.visitDateTo)) ||
            ((this.searchFilter.visitDateFrom && !this.searchFilter.visitDateTo) && // Caso valorizzato da
            ((c.last_visit_date ?? '') >= this.searchFilter.visitDateFrom)) ||
            ((!this.searchFilter.visitDateFrom && this.searchFilter.visitDateTo) && // Caso valorizzato a
            ((c.last_visit_date ?? '') <= this.searchFilter.visitDateTo) && ((c.last_visit_date ?? '') !== ''))
          )
        ) &&
        (
          (!this.searchFilter.starszero && !this.searchFilter.starsone && !this.searchFilter.starstwo && !this.searchFilter.starsthree) ||
          (this.searchFilter.starszero && ((c.stars ?? '') === '0')) ||
          (this.searchFilter.starsone && ((c.stars ?? '') === '1')) ||
          (this.searchFilter.starstwo && ((c.stars ?? '') === '2')) ||
          (this.searchFilter.starsthree && ((c.stars ?? '') === '3'))
        )
        if (isFound) {
          c.selected = false
          if (this.selectedClients.indexOf(c.id) >= 0) {
            c.selected = true
          }
        }
        // filtro visualizza microaree dei clienti selezionati
        // if (isFound && c.microarea != null && this.microAreas.findIndex(ma => ma.name === c.microarea) < 0) {
        //   this.microAreas.push({ name: c.microarea })
        // }
        return isFound
      })
    },
    async addToMicroarea () {
      this.addingToMicroarea = true
      const r = await this.$store.dispatch('clients/addToMicroArea', { microarea: this.selectedAddToMicroarea, clients: this.selectedClients })
      if (r) {
        this.addToMicroareaDialog = false
        this.selectedAddToMicroarea = ''
        this.selectedClients = []
        const dataMessage = { isOpen: true, message: this.$t('messages.Clienti assegnati'), color: 'success' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        this.doFilter()
      } else {
        const dataMessage = { isOpen: true, message: this.$t('messages.Errore nell assegnazione della microarea'), color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
      this.addingToMicroarea = false
    }
  }
}
</script>
