<template>
  <v-container class="pa-1">
    <v-progress-circular
      v-if="isInitializing != 0"
      indeterminate
      color="primary"
      class="mt-3 ml-3"
    />
    <div
      v-else-if="isInitializing === 0 && canWrite === 1 && ($route.params.clientId && feedbackDetails.client_id !== '') || canWrite === 1 && !$route.params.clientId"
    >
      <div class="d-flex mt-2 mb-2">
        <client-selector
          v-model="feedbackDetails.client_id"
          class="flex-grow-1 persistent-placeholder"
          :clients="clientsListMinimal"
          @input="setMicroarea"
        />
      </div>
      <div class="d-flex mt-2 mb-2">
        <micro-area-selector
          v-model="feedbackDetails.microarea"
          :microareas="microAreasVisibleToAgent"
          class="persistent-placeholder"
          :disabled="!feedbackDetails.client_id"
          hide-details
        />
      </div>
      <div class="d-flex flex-column mb-2">
        <div class="caption mb-n5">
          {{ feedbackDetails.contact_type !== 'assente' ? 'Data Visita' : 'Data Tentata Visita' }}
        </div>
        <div>
          <v-menu
            v-model="visitDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(feedbackDetails.visit_date)"
                :placeholder="$t('messages.Seleziona una data')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                hide-details
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="feedbackDetails.visit_date"
              :locale="$t('messages.locale')"
              :first-day-of-week="1"
              clearable
              :max="(new Date()).toISOString().substr(0, 10)"
              @input="visitDateMenu = false"
            />
          </v-menu>
        </div>
      </div>

      <div
        class="d-flex justify-space-between mb-2"
      >
        <v-row
          no-gutters
          style="flex-wrap: nowrap;"
        >
          <v-col>
            <div class="d-flex flex-column mb-2">
              <div class="caption justify-center">
                {{ $t('messages.Tipo Contatto') }}
              </div>
              <div>
                <v-radio-group
                  v-model="feedbackDetails.contact_type"
                  row
                  :disabled="isLoading"
                  hide-details
                >
                  <v-radio
                    v-for="(item, t) in contactType"
                    :key="t"
                    color="primary"
                    :value="item.definition"
                  >
                    <template
                      slot="label"
                    >
                      <v-icon
                        color="grey"
                        class="mr-1 ml-n1"
                      >
                        {{ item.icon }}
                      </v-icon>
                      {{ item.text }}
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </div>
          </v-col>
          <v-divider
            v-if="feedbackDetails.contact_type !== 'assente'"
            class="mx-2 mr-2"
            vertical
          />
          <v-col>
            <div
              v-if="feedbackDetails.contact_type !== 'assente'"
              class="d-flex flex-column mb-2"
            >
              <div class="caption justify-center">
                {{ $t('messages.Finalità Contatto') }}
              </div>
              <div>
                <v-radio-group
                  v-model="feedbackDetails.contact_purpose"
                  row
                  :disabled="isLoading"
                  hide-details
                >
                  <v-radio
                    v-for="(item, p) in contactPurpose"
                    :key="p"
                    color="primary"
                    :value="item.definition"
                  >
                    <template
                      slot="label"
                    >
                      <v-icon
                        class="mr-1 ml-n1"
                        :color="item.iconColor"
                      >
                        {{ item.icon }}
                      </v-icon>
                      {{ item.text }}
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <div
        v-if="feedbackDetails.contact_type !== 'assente'"
        class="caption flex-column pa-1 mb-2"
        style="background-color: rgba(0, 0, 0, 0.06);"
      >
        <v-icon
          class="mr-1"
          @click="openPresentedSelector"
        >
          mdi-plus-circle
        </v-icon>
        {{ $t('messages.Prodotti presentati') }}:
        <div class="d-flex">
          <div
            class="pa-2 mb-1"
            style="border-radius: 20px; background-color: white;"
          >
            <span v-if="!feedbackDetails.presented.length">{{ $t('messages.Nessun prodotto selezionato') }}</span>
            <div
              v-for="(presSP, psp) in feedbackDetails.presented"
              v-else
              :key="psp"
              class="px-1"
            >
              <v-row
                class="d-flex justify-space-between align-center my-n4"
              >
                <v-chip
                  close
                  class="ma-1 white--text"
                  color="success"
                  small
                  @click:close="feedbackDetails.presented.splice(psp, 1); feedbackDetails.presented.rating.splice(psp, 1)"
                >
                  {{ presSP.text }}
                </v-chip>
                <div class="">
                  <v-radio-group
                    v-model="presSP.rating"
                    row
                    class="ml-1"
                  >
                    <v-radio
                      v-for="n in 3"
                      :key="n"
                      :value="n"
                      :color="icons[n].color"
                      :off-icon="icons[n].empty"
                      :on-icon="icons[n].filled"
                      class="mr-0"
                    />
                  </v-radio-group>
                </div>
              </v-row>
            </div>
          </div>
        </div>

        <v-dialog
          v-model="presentedSelector"
          max-width="290"
        >
          <v-card>
            <v-card-text class="pt-4 justify-center">
              <product-selector
                v-model="presentedProducts"
                :products="products"
                :label="$t('messages.Prodotti presentati')"
              />
              <div class="d-flex justify-space-between justify-center align-center">
                <v-btn
                  color="rgba(0, 0, 0, 0.06) white--text"
                  small
                  tile
                  elevation="0"
                  :loading="isLoading"
                  @click="closePresentedSelector"
                >
                  {{ $t('messages.Annulla') }}
                </v-btn>
                <v-btn
                  color="success"
                  :disabled="!presentedProducts"
                  small
                  :dark="!!presentedProducts"
                  tile
                  elevation="0"
                  :loading="isLoading"
                  @click="insertProduct('presented')"
                >
                  {{ $t('messages.OK') }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <div
        v-if="feedbackDetails.contact_type !== 'assente'"
        class="caption flex-column pa-1 mb-2"
        style="background-color: rgba(0, 0, 0, 0.06);"
      >
        <v-icon
          class="mr-1"
          @click="openProblemSelector"
        >
          mdi-plus-circle
        </v-icon>
        {{ $t('messages.Prodotti con problematiche') }}:
        <div class="d-flex">
          <div
            class="pa-2 mb-1"
            style="border-radius: 20px; background-color: white;"
          >
            <span v-if="!feedbackDetails.problem.length">{{ $t('messages.Nessun prodotto selezionato') }}</span>
            <div
              v-for="(probSP, i) in feedbackDetails.problem"
              v-else
              :key="i"
              class="px-1"
            >
              <v-row
                class="d-flex justify-space-between align-center my-n4"
              >
                <v-chip
                  close
                  class="ma-1 white--text"
                  color="error"
                  small
                  @click:close="feedbackDetails.problem.splice(i, 1); feedbackDetails.problem.rating.splice(i, 1)"
                >
                  {{ probSP.text }}
                </v-chip>
                <div class="">
                  <v-radio-group
                    v-model="probSP.rating"
                    row
                    class="ml-1"
                  >
                    <v-radio
                      v-for="r in 3"
                      :key="r"
                      :value="r"
                      :color="icons[r].color"
                      :off-icon="icons[r].empty"
                      :on-icon="icons[r].filled"
                      class="mr-0"
                    />
                  </v-radio-group>
                </div>
              </v-row>
            </div>
          </div>
        </div>

        <v-dialog
          v-model="problemSelector"
          max-width="290"
        >
          <v-card>
            <v-card-text class="pt-4 justify-center">
              <product-selector
                v-model="problemProducts"
                :products="products"
                :label="$t('messages.Prodotti con problematiche')"
              />
              <div class="d-flex justify-space-between justify-center align-center">
                <v-btn
                  color="rgba(0, 0, 0, 0.06) white--text"
                  small
                  tile
                  elevation="0"
                  :loading="isLoading"
                  @click="closeProblemSelector"
                >
                  {{ $t('messages.Annulla') }}
                </v-btn>
                <v-btn
                  color="error"
                  :disabled="!problemProducts"
                  small
                  :dark="!!problemProducts"
                  tile
                  elevation="0"
                  :loading="isLoading"
                  @click="insertProduct('problem')"
                >
                  {{ $t('messages.OK') }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <div
        v-if="feedbackDetails.contact_type !== 'assente'"
        class="d-flex mb-2"
      >
        <v-textarea
          v-model="feedbackDetails.notes"
          class="persistent-placeholder"
          filled
          :label="$t('messages.Note / Testo libero')"
          :disabled="isLoading"
          clearable
          hide-details
        />
      </div>

      <div
        v-if="feedbackDetails.contact_type !== 'assente'"
        class="caption flex-column pa-1 mb-2"
        style="background-color: rgba(0, 0, 0, 0.06);"
      >
        <v-icon
          class="mr-1"
          @click="openSamplesSelector"
        >
          mdi-plus-circle
        </v-icon>
        {{ $t('messages.Campionature') }}:
        <div class="d-flex">
          <div
            class="pa-2 mb-1"
            style="border-radius: 20px; background-color: white;"
          >
            <span v-if="!feedbackDetails.sample.length">{{ $t('messages.Nessuna campionatura selezionata') }}</span>
            <div
              v-for="(samSP, ssp) in feedbackDetails.sample"
              v-else
              :key="ssp"
              class="px-1"
            >
              <v-row
                class="d-flex justify-space-between align-center my-1"
              >
                <v-chip
                  close
                  class="ma-1 white--text"
                  color="primary"
                  small
                  @click:close="feedbackDetails.sample.splice(ssp, 1)"
                >
                  {{ samSP.text }}
                </v-chip>
                <div class="mr-1">
                  {{ samSP.format }}
                </div>
              </v-row>
            </div>
          </div>
        </div>

        <v-dialog
          v-model="samplesSelector"
          max-width="290"
        >
          <v-card>
            <v-tabs
              v-model="selectedTab"
              fixed-tabs
              background-color="primary"
              class="navigationTab-fixed"
              dark
              style="width: 100%; min-width: 100%; max-width: 100%;"
            >
              <v-tab
                style="min-width: 0px;"
              >
                {{ $t('messages.Campioni') }}
              </v-tab>
              <v-tab
                style="min-width: 0px;"
              >
                {{ $t('messages.Marketing') }}
              </v-tab>
            </v-tabs>

            <v-tabs-items
              v-model="selectedTab"
            >
              <v-tab-item class="pa-2">
                <product-selector
                  v-model="selectedCampioneProduct"
                  :products="campioniProducts"
                  @input="updateTypes"
                />
                <v-select
                  v-model="selectedCampioneProductType"
                  class=""
                  filled
                  :items="selectedCampioneProductTypes"
                  item-value="value"
                  item-text="text"
                  :label="$t('messages.Tipo')"
                />
                <div class="d-flex justify-space-between justify-center align-center">
                  <v-btn
                    color="rgba(0, 0, 0, 0.06) white--text"
                    small
                    tile
                    elevation="0"
                    @click="closeSamplesSelector"
                  >
                    {{ $t('messages.Annulla') }}
                  </v-btn>
                  <v-btn
                    color="success"
                    tile
                    :disabled="!selectedCampioneProduct || !selectedCampioneProduct.selectedCommercialCodeId || !selectedCampioneProductType"
                    small
                    :dark="!!selectedCampioneProduct && !!selectedCampioneProduct.selectedCommercialCodeId && !!selectedCampioneProductType"
                    elevation="0"
                    @click="insertProduct('sample')"
                  >
                    {{ $t('messages.Aggiungi') }}
                  </v-btn>
                </div>
              </v-tab-item>
              <v-tab-item class="pa-2">
                <v-autocomplete
                  v-model="selectedCampione"
                  :items="campioniList"
                  :label="$t('messages.Seleziona un campione')"
                  :placeholder="$t('messages.Seleziona un campione')"
                  clearable
                  item-value="codice_articolo"
                  filled
                  :menu-props="{
                    maxHeight:200,
                  }"
                  :filter="customCampioneFilter"
                >
                  <template slot="no-data">
                    <v-list-item>
                      <v-list-item-content>
                        {{ $t('messages.Nessun campione presente') }}
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template
                    #[`item`]="{ item }"
                  >
                    <div class="d-flex flex-column">
                      {{ item.codice_articolo }}
                      <v-chip
                        style="font-size: 12px"
                        class="px-1 mb-2"
                        x-small
                      >
                        {{ item.descrizione }}
                      </v-chip>
                    </div>
                  </template>
                  <template
                    #[`selection`]="{ item }"
                  >
                    <div>
                      {{ item.codice_articolo }}
                    </div>
                  </template>
                </v-autocomplete>
                <div class="d-flex justify-space-between justify-center align-center">
                  <v-btn
                    color="rgba(0, 0, 0, 0.06) white--text"
                    small
                    tile
                    elevation="0"
                    :loading="isLoading"
                    @click="closeSamplesSelector"
                  >
                    {{ $t('messages.Annulla') }}
                  </v-btn>
                  <v-btn
                    color="success"
                    tile
                    :disabled="!selectedCampione"
                    small
                    :dark="!!selectedCampione"
                    elevation="0"
                    @click="insertProduct('sample')"
                  >
                    {{ $t('messages.Aggiungi') }}
                  </v-btn>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-dialog>
      </div>

      <v-text-field
        v-if="feedbackDetails.contact_type !== 'assente'"
        v-model="feedbackDetails.next_visit_note"
        filled
        class="persistent-placeholder mb-2"
        required
        :label="$t('messages.Argomento prossima visita')"
        :disabled="isLoading"
        clearable
        hide-details
      />

      <div
        class="d-flex justify-space-around align-center primary py-2"
      >
        <div
          v-if="feedbackDetails.contact_type !== 'assente'"
          class="d-flex flex-column caption align-center pa-1"
          style="border-radius: 10px; background-color: white;"
        >
          <div>
            <v-icon
              class="mr-1 align-center"
            >
              mdi-star-check-outline
            </v-icon>
            {{ $t('messages.Esito visita') }}
          </div>
          <div>
            <v-radio-group
              v-model="feedbackDetails.visit_rating"
              row
              class="align-center no-margin-radio"
              hide-details
            >
              <v-radio
                v-for="n in 3"
                :key="n"
                :value="n"
                :color="icons[n].color"
                :off-icon="icons[n].empty"
                :on-icon="icons[n].filled"
                class="mr-0"
              />
            </v-radio-group>
          </div>
        </div>
        <div class="d-flex flex-column justify-center align-center">
          <v-btn
            color="success"
            :disabled="isLoading"
            :loading="isLoading"
            tile
            small
            elevation="0"
            class="white--text"
            @click="uploadFeedback"
          >
            <v-icon>
              mdi-content-save
            </v-icon>
            {{ $t('messages.Salva') }}
          </v-btn>
          <v-btn
            v-if="$route.params.feedbackId"
            color="error"
            :disabled="isLoading"
            :loading="isLoading"
            tile
            small
            elevation="0"
            class="white--text mt-2"
            @click="deleteOwnFeedback"
          >
            <v-icon>
              mdi-delete
            </v-icon>
            {{ $t('messages.Elimina') }}
          </v-btn>
        </div>
      </div>
    </div>
    <div
      v-else-if="isInitializing === 0 && canWrite !== 1"
      class="mt-4"
    >
      <v-card
        elevation="0"
        tile
        class="error d-flex align-center justify-center"
        style="min-height:150px; max-height:150px; height:150px;"
      >
        <span
          class="title white--text"
        >
          {{ $t('messages.Feedback non trovato o non modificabile') }}
        </span>
      </v-card>
    </div>
    <div
      v-else-if="isInitializing === 0 && $route.params.clientId && !feedbackDetails.client_id"
      class="mt-4"
    >
      <v-card
        elevation="0"
        tile
        class="error d-flex align-center justify-center"
        style="min-height:150px; max-height:150px; height:150px;"
      >
        <span
          class="title white--text"
        >
          {{ $t('messages.Cliente non trovato') }}
        </span>
      </v-card>
    </div>

    <confirm-dialog
      v-model="confirmDialogDeleteOwnFeedback"
      color="error"
      :title="$t('messages.Rimuovi')"
      :description="$t('messages.Sei sicuro di voler eliminare questo Feedback?')"
      @confirm="deleteOwnFeedbackConfirm(); confirmDialogDeleteOwnFeedback=false"
      @cancel="confirmDialogDeleteOwnFeedback=false"
    />
  </v-container>
</template>

<script>
import ConfirmDialog from '../components/ConfirmDialog'
import ClientSelector from '../components/ClientSelector.vue'
import ProductSelector from '../components/ProductSelector.vue'
import MicroAreaSelector from '../components/MicroAreaSelector.vue'

export default {
  name: 'FeedbackInsert',

  components: {
    ConfirmDialog,
    ClientSelector,
    ProductSelector,
    MicroAreaSelector
  },

  data: () => ({
    icons: [
      { id: '' },
      { id: '1', color: 'error', empty: 'mdi-emoticon-sad-outline', filled: 'mdi-emoticon-sad' },
      { id: '2', color: 'amber darken-2', empty: 'mdi-emoticon-neutral-outline', filled: 'mdi-emoticon-neutral' },
      { id: '3', color: 'success', empty: 'mdi-emoticon-outline', filled: 'mdi-emoticon' }
    ],
    isInitializing: 0,
    isLoading: false,
    feedbackDetails: null,
    selectedFeedback: null,
    visitDateMenu: false,
    presentedProducts: null,
    presentedSelector: false,
    problemProducts: null,
    problemSelector: false,
    // samplesProducts: null,
    // sampleFormat: null,
    // samplesFormats: ['Rotolino', 'Roll Print (40x70)', 'A4', 'A5'],
    samplesSelector: false,
    canWrite: 0,
    listcampioniprodotti: [],
    campioniProducts: null,
    confirmDialogDeleteOwnFeedback: false,
    selectedTab: 0,
    selectedCampioneProduct: null,
    selectedCampioneProductType: null,
    selectedCampioneProductTypes: [],
    selectedCampione: null
  }),

  computed: {
    errorMessage () {
      return this.$store.getters['feedback/errorMessage']
    },
    microAreasVisibleToAgent () {
      return this.$store.getters['agents/microAreasVisibleToAgent']
    },
    clientsListMinimal () {
      return this.$store.getters['clients/clientsListMinimal']
    },
    clientDetails () {
      return this.$store.getters['clientdetails/clientDetails']
    },
    campioniList () {
      return this.$store.getters['products/campioni']
    },
    products () {
      return this.$store.getters['products/list']
    },
    isSupervisor () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN') || this.$store.getters['security/hasRole']('ROLE_AGENT_ADMIN')
    },
    contactType () {
      if (this.isSupervisor) {
        return [{ definition: 'visita', text: this.$t('messages.visita'), icon: 'mdi-account-voice' },
          { definition: 'telefonico', text: this.$t('messages.telefonico'), icon: 'mdi-phone-in-talk' },
          { definition: 'assente', text: this.$t('messages.assente'), icon: 'mdi-cancel' },
          { definition: 'affiancamento', text: this.$t('messages.affiancamento'), icon: 'mdi-account-multiple' }]
      } else {
        return [{ definition: 'visita', text: this.$t('messages.visita'), icon: 'mdi-account-voice' },
          { definition: 'telefonico', text: this.$t('messages.telefonico'), icon: 'mdi-phone-in-talk' },
          { definition: 'assente', text: this.$t('messages.assente'), icon: 'mdi-cancel' }]
      }
    },
    contactPurpose () {
      return [{ definition: 'vendita', text: this.$t('messages.vendita'), icon: 'mdi-cash-multiple', iconColor: 'success' },
        { definition: 'assistenza', text: this.$t('messages.assistenza'), icon: 'mdi-tools', iconColor: 'error' },
        { definition: 'incasso', text: this.$t('messages.incasso'), icon: 'mdi-cash-register', iconColor: 'primary' }]
    }
  },

  async created () {
    this.isInitializing = 5
    this.$store.dispatch('clients/clientsListMinimal').then(() => { this.isInitializing--; this.initialize() })
    this.$store.dispatch('products/list').then(() => { this.isInitializing--; this.initialize() })
    this.$store.dispatch('products/listcampioni').then(() => { this.isInitializing--; this.initialize() })
    this.$store.dispatch('products/listcampioniprodotti').then((r) => { this.isInitializing--; this.listcampioniprodotti = r; this.initialize() })
    this.$store.dispatch('agents/microAreasVisibleToAgent').then(() => { this.isInitializing--; this.initialize() })
    this.feedbackDetails = {
      client_id: '',
      contact_purpose: '',
      contact_type: '',
      feedback_date: '',
      id: '',
      last_day: '',
      microarea: '',
      next_visit_note: '',
      notes: '',
      presented: [],
      problem: [],
      sample: [],
      user_id: '',
      visit_date: '',
      visit_rating: ''
    }
    this.feedbackDetails.visit_date = new Date().toISOString().substr(0, 10)
    if (this.$route.params.feedbackId) {
      this.isInitializing++
      this.$store.dispatch('feedback/feedbackDetails', this.$route.params.feedbackId).then((r) => {
        if (r) {
          this.feedbackDetails = r
          if (this.feedbackDetails.last_day) {
            this.canWrite = 1
          }
        }
        this.isInitializing--
        this.initialize()
      })
    }
  },

  methods: {
    initialize () {
      if (!this.$route.params.feedbackId) {
        this.canWrite = 1
      }
      if (this.isInitializing === 0 && this.canWrite === 1) {
        if (this.$route.params.clientId && this.clientsListMinimal.find(c => c.id === this.$route.params.clientId)) {
          this.feedbackDetails.client_id = this.$route.params.clientId
        }
        this.feedbackDetails.microarea = this.clientsListMinimal.find(c => c.id === this.feedbackDetails.client_id)?.microarea
        if (!this.$route.params.clientId) {
          if (this.$route.params.feedbackId) {
            const r = this.feedbackDetails
            this.parseFeedbackResponse(r)
          }
        }

        if (!this.campioniProducts) {
          this.campioniProducts = JSON.parse(JSON.stringify(this.products))
          Object.keys(this.campioniProducts.commercialcodes).forEach(commercialCode => {
            const lcp = this.listcampioniprodotti.find(lcp => this.campioniProducts.commercialcodes[commercialCode].codice.toLowerCase().startsWith(lcp.codice.toLowerCase()))
            if (!lcp) {
              delete this.campioniProducts.commercialcodes[commercialCode]
            } else {
              if (lcp.raggruppa) {
                if (lcp.isGrouped) {
                  delete this.campioniProducts.commercialcodes[commercialCode]
                  return
                } else {
                  lcp.isGrouped = true
                  this.campioniProducts.commercialcodes[commercialCode].codice = lcp.codice
                }
              }
              this.campioniProducts.commercialcodes[commercialCode].rotolino = lcp.rotolino
              this.campioniProducts.commercialcodes[commercialCode].printRoll = lcp.printRoll
              this.campioniProducts.commercialcodes[commercialCode].a4 = lcp.a4
              this.campioniProducts.commercialcodes[commercialCode].a5 = lcp.a5
            }
          })
        }
      }
    },

    openPresentedSelector () {
      if (!(this.presentedSelector === true)) {
        this.presentedSelector = true
      } else this.presentedSelector = false
    },
    closePresentedSelector () {
      this.presentedSelector = false
    },
    openProblemSelector () {
      if (!(this.problemSelector === true)) {
        this.problemSelector = true
      } else this.problemSelector = false
    },
    closeProblemSelector () {
      this.problemSelector = false
    },
    openSamplesSelector () {
      if (!(this.samplesSelector === true)) {
        this.samplesSelector = true
      } else this.samplesSelector = false
    },
    closeSamplesSelector () {
      this.samplesSelector = false
    },

    async setMicroarea () {
      if (this.feedbackDetails.client_id) {
        this.client = await this.$store.dispatch('clientdetails/clientDetails', this.feedbackDetails.client_id)
        this.feedbackDetails.microarea = this.client.microarea
      }
    },

    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    },

    async deleteOwnFeedbackConfirm () {
      const result = await this.$store.dispatch('feedback/deleteOwnFeedback', { feedback: this.ownFeedbackToDelete })
      if (result !== null) {
        const message = this.$t('messages.Feedback eliminato')
        const dataMessage = { isOpen: true, message: message, color: 'success' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        this.$router.push({ path: '/feedbacks' })
      } else {
        const message = this.$store.getters['feedback/errorMessage']
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
    },
    deleteOwnFeedback (feedback) {
      this.ownFeedbackToDelete = this.feedbackDetails
      this.confirmDialogDeleteOwnFeedback = true
    },

    async uploadFeedback () {
      let errorMessages = ''
      if (!this.feedbackDetails.client_id) {
        errorMessages += this.$t('messages.Selezionare un Cliente') + '\n'
      }
      if (!this.feedbackDetails.visit_date) {
        errorMessages += this.$t('messages.Selezionare la Data di visita') + '\n'
      }
      if (this.feedbackDetails.contact_type.length === 0) {
        errorMessages += this.$t('messages.Selezionare il tipo di contatto') + '\n'
      }
      if (this.feedbackDetails.contact_type !== 'assente' && this.feedbackDetails.contact_purpose.length === 0) {
        errorMessages += this.$t('messages.Selezionare la finalità del contatto') + '\n'
      }
      if (this.feedbackDetails.contact_type !== 'assente' && this.feedbackDetails.presented.length) {
        this.feedbackDetails.presented.forEach((o, p) => {
          if (!this.feedbackDetails.presented[p].rating) {
            errorMessages += this.$t('messages.Specificare rating prodotti presentati') + '\n'
          }
        })
      }
      if (this.feedbackDetails.contact_type !== 'assente' && this.feedbackDetails.problem.length) {
        this.feedbackDetails.problem.forEach((q, r) => {
          if (!this.feedbackDetails.problem[r].rating) {
            errorMessages += this.$t('messages.Specificare rating prodotti con problematiche') + '\n'
          }
        })
      }
      if (this.feedbackDetails.contact_type !== 'assente' && !this.feedbackDetails.next_visit_note) {
        errorMessages += this.$t('messages.Indicare l argomento della prossima visita') + '\n'
      }
      if (this.feedbackDetails.contact_type !== 'assente' && (this.feedbackDetails.visit_rating === '')) {
        errorMessages += this.$t('messages.Selezionare l esito della visita') + '\n'
      }
      if (errorMessages !== '') {
        const dataMessage = { isOpen: true, message: errorMessages, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        this.isLoading = false
        return
      }
      this.isInitializing++
      this.isLoading = true

      const productsParams = {}

      if (this.feedbackDetails.presented.length) {
        this.feedbackDetails.presented.forEach((o, p) => {
          this.$set(this.feedbackDetails.presented[p], 'rating', this.feedbackDetails.presented[p].rating)
          this.$set(this.feedbackDetails.presented[p], 'purpose', 'presented')
        })
        productsParams.presented = this.feedbackDetails.presented
      }

      if (this.feedbackDetails.problem.length) {
        this.feedbackDetails.problem.forEach((q, r) => {
          this.$set(this.feedbackDetails.problem[r], 'rating', this.feedbackDetails.problem[r].rating)
          this.$set(this.feedbackDetails.problem[r], 'purpose', 'problem')
        })
        productsParams.problem = this.feedbackDetails.problem
      }

      if (this.feedbackDetails.sample.length) {
        this.feedbackDetails.sample.forEach((t, s) => {
          this.$set(this.feedbackDetails.sample[s], 'purpose', 'sample')
        })
        productsParams.sample = this.feedbackDetails.sample
      }

      let result = ''
      if (!this.$route.params.feedbackId) {
        result = await this.$store.dispatch('feedback/upload', { feedback: this.feedbackDetails })
      }
      if (this.$route.params.feedbackId) {
        this.$set(this.feedbackDetails, 'feedbackId', this.$route.params.feedbackId)
        result = await this.$store.dispatch('feedback/edit', { feedback: this.feedbackDetails })
      }

      if (result !== null) {
        let message = ''
        if (!this.$route.params.feedbackId) {
          message = this.$t('messages.Feedback generato')
          const dataMessage = { isOpen: true, message: message, color: 'success' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)

          this.feedbackDetails = {
            client_id: '',
            contact_purpose: '',
            contact_type: '',
            feedback_date: '',
            id: '',
            last_day: '',
            microarea: '',
            next_visit_note: '',
            notes: '',
            presented: [],
            problem: [],
            sample: [],
            user_id: '',
            visit_date: new Date().toISOString().substr(0, 10),
            visit_rating: ''
          }

          this.$router.replace({ path: '/feedbackinsert' }).catch(() => {})
          // this.$router.push({ path: '/feedbackinsert' })
          // this.$router.go()
        }
        if (this.$route.params.feedbackId) {
          message = this.$t('messages.Feedback modificato')
          const dataMessage = { isOpen: true, message: message, color: 'success' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)

          this.$store.dispatch('feedback/feedbackDetails', this.$route.params.feedbackId).then((r) => {
            this.parseFeedbackResponse(r)
          })
        }
      } else {
        const message = this.errorMessage
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
      this.isLoading = false
      this.isInitializing--
      this.initialize()
    },
    parseFeedbackResponse (r) {
      this.feedbackDetails = r
      this.feedbackDetails.visit_rating = parseInt(r.visit_rating)
      this.setMicroarea()
      if (r.presented) {
        r.presented.forEach(pres => {
          if (pres.type === 'commercialCodeId') {
            pres.text = this.products.commercialcodes[pres.value].codice
          } else if (pres.type === 'lineId') {
            pres.text = this.products.lines[pres.value].titolo
          } else if (pres.type === 'subCategoryId') {
            pres.text = this.products.subCategories[pres.value].titolo
          } else if (pres.type === 'categoryId') {
            pres.text = this.products.categories[pres.value].titolo
          } else {
            pres.text = pres.value
          }
          pres.rating = parseInt(pres.rating)
        })
      }
      if (r.problem) {
        r.problem.forEach(prob => {
          if (prob.type === 'commercialCodeId') {
            prob.text = this.products.commercialcodes[prob.value].codice
          } else if (prob.type === 'lineId') {
            prob.text = this.products.lines[prob.value].titolo
          } else if (prob.type === 'subCategoryId') {
            prob.text = this.products.subCategories[prob.value].titolo
          } else if (prob.type === 'categoryId') {
            prob.text = this.products.categories[prob.value].titolo
          } else {
            prob.text = prob.value
          }
          prob.rating = parseInt(prob.rating)
        })
      }
      if (r.sample) {
        r.sample.forEach(sam => {
          if (sam.type === 'commercialCodeId') {
            sam.text = this.products.commercialcodes[sam.value].codice
          } else if (sam.type === 'lineId') {
            sam.text = this.products.lines[sam.value].titolo
          } else if (sam.type === 'subCategoryId') {
            sam.text = this.products.subCategories[sam.value].titolo
          } else if (sam.type === 'categoryId') {
            sam.text = this.products.categories[sam.value].titolo
          } else {
            sam.text = sam.value
          }
        })
      }
    },
    insertProduct (type) {
      this.isLoading = true
      if (type === 'presented') {
        const selectedProduct = {}
        if (this.presentedProducts) {
          selectedProduct.purpose = 'presented'
          if (this.presentedProducts.selectedCommercialCodeId) {
            selectedProduct.type = 'commercialCodeId'
            selectedProduct.value = this.presentedProducts.selectedCommercialCodeId
            selectedProduct.text = this.products.commercialcodes[this.presentedProducts.selectedCommercialCodeId].codice
          } else if (this.presentedProducts.selectedLineId) {
            selectedProduct.type = 'lineId'
            selectedProduct.value = this.presentedProducts.selectedLineId
            selectedProduct.text = this.products.lines[this.presentedProducts.selectedLineId].titolo
          } else if (this.presentedProducts.selectedSubCategoryId) {
            selectedProduct.type = 'subCategoryId'
            selectedProduct.value = this.presentedProducts.selectedSubCategoryId
            selectedProduct.text = this.products.subCategories[this.presentedProducts.selectedSubCategoryId].titolo
          } else if (this.presentedProducts.selectedCategoryId) {
            selectedProduct.type = 'categoryId'
            selectedProduct.value = this.presentedProducts.selectedCategoryId
            selectedProduct.text = this.products.categories[this.presentedProducts.selectedCategoryId].titolo
          } else {
            return
          }
          if (!(this.feedbackDetails.presented.some(t => t.type === selectedProduct.type && t.value === selectedProduct.value))) {
            this.feedbackDetails.presented.push(selectedProduct)
            this.presentedSelector = false
          } else {
            const message = this.$t('messages.Prodotto già presente')
            const dataMessage = { isOpen: true, message: message, color: 'error' }
            this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
            this.presentedSelector = false
          }
        }
      }
      if (type === 'problem') {
        const selectedProduct = {}
        if (this.problemProducts) {
          selectedProduct.purpose = 'problem'
          if (this.problemProducts.selectedCommercialCodeId) {
            selectedProduct.type = 'commercialCodeId'
            selectedProduct.value = this.problemProducts.selectedCommercialCodeId
            selectedProduct.text = this.products.commercialcodes[this.problemProducts.selectedCommercialCodeId].codice
          } else if (this.problemProducts.selectedLineId) {
            selectedProduct.type = 'lineId'
            selectedProduct.value = this.problemProducts.selectedLineId
            selectedProduct.text = this.products.lines[this.problemProducts.selectedLineId].titolo
          } else if (this.problemProducts.selectedSubCategoryId) {
            selectedProduct.type = 'subCategoryId'
            selectedProduct.value = this.problemProducts.selectedSubCategoryId
            selectedProduct.text = this.products.subCategories[this.problemProducts.selectedSubCategoryId].titolo
          } else if (this.problemProducts.selectedCategoryId) {
            selectedProduct.type = 'categoryId'
            selectedProduct.value = this.problemProducts.selectedCategoryId
            selectedProduct.text = this.products.categories[this.problemProducts.selectedCategoryId].titolo
          } else {
            return
          }
          if (!(this.feedbackDetails.problem.some(t => t.type === selectedProduct.type && t.value === selectedProduct.value))) {
            this.feedbackDetails.problem.push(selectedProduct)
            this.problemSelector = false
          } else {
            const message = this.$t('messages.Prodotto già presente')
            const dataMessage = { isOpen: true, message: message, color: 'error' }
            this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
            this.problemSelector = false
          }
        }
      }
      if (type === 'sample') {
        const selectedProduct = {}
        if (this.selectedTab === 0) { // Campione
          selectedProduct.purpose = 'sample'
          selectedProduct.format = this.selectedCampioneProductType
          selectedProduct.type = 'articolo'
          selectedProduct.value = this.campioniProducts.commercialcodes[this.selectedCampioneProduct.selectedCommercialCodeId].codice
          selectedProduct.text = this.campioniProducts.commercialcodes[this.selectedCampioneProduct.selectedCommercialCodeId].codice

          if (!(this.feedbackDetails.sample.some(t => t.type === 'articolo' && t.value === selectedProduct.value && t.format === selectedProduct.format))) {
            this.feedbackDetails.sample.push(selectedProduct)
            this.samplesSelector = false
          } else {
            const message = this.$t('messages.Prodotto già presente')
            const dataMessage = { isOpen: true, message: message, color: 'error' }
            this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
            this.samplesSelector = false
          }
        } else if (this.selectedTab === 1) { // Campione
          selectedProduct.purpose = 'sample'
          selectedProduct.format = ''
          selectedProduct.type = 'campione'
          selectedProduct.value = this.selectedCampione
          selectedProduct.text = this.selectedCampione

          if (!(this.feedbackDetails.sample.some(t => t.type === selectedProduct.type && t.value === selectedProduct.value))) {
            this.feedbackDetails.sample.push(selectedProduct)
            this.samplesSelector = false
          } else {
            const message = this.$t('messages.Prodotto già presente')
            const dataMessage = { isOpen: true, message: message, color: 'error' }
            this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
            this.samplesSelector = false
          }
        }
      }
      this.isLoading = false
    },

    customCampioneFilter (item, queryText) {
      return (item.descrizione.toLowerCase().includes(queryText.toLowerCase()) ||
              item.codice_articolo.toLowerCase().includes(queryText.toLowerCase()))
    },
    updateTypes () {
      this.selectedCampioneProductTypes = []
      if (this.selectedCampioneProduct && this.selectedCampioneProduct.selectedCommercialCodeId) {
        if (this.campioniProducts.commercialcodes[this.selectedCampioneProduct.selectedCommercialCodeId].rotolino) {
          this.selectedCampioneProductTypes.push({ value: 'Rotolino', text: this.$t('messages.Rotolino') })
        }
        if (this.campioniProducts.commercialcodes[this.selectedCampioneProduct.selectedCommercialCodeId].printRoll) {
          this.selectedCampioneProductTypes.push({ value: 'Roll Print (40x70)', text: this.$t('messages.Roll Print (40x70)') })
        }
        if (this.campioniProducts.commercialcodes[this.selectedCampioneProduct.selectedCommercialCodeId].a4) {
          this.selectedCampioneProductTypes.push({ value: 'A4', text: this.$t('messages.A4') })
        }
        if (this.campioniProducts.commercialcodes[this.selectedCampioneProduct.selectedCommercialCodeId].a5) {
          this.selectedCampioneProductTypes.push({ value: 'A5', text: this.$t('messages.A5') })
        }
        if (this.selectedCampioneProductTypes.length === 1) {
          this.selectedCampioneProductType = this.selectedCampioneProductTypes[0].value
        }
      } else {
        this.selectedCampioneProductType = ''
      }
    },
    productDescription (productType, value) {
      switch (productType) {
      case 'categoryId':
        return this.products.categories[value]?.titolo ?? 'undefined'
      case 'subCategoryId':
        return this.products.subCategories[value]?.titolo ?? 'undefined'
      case 'lineId':
        return this.products.lines[value]?.titolo ?? 'undefined'
      case 'commercialCodeId':
        return this.products.commercialcodes[value]?.codice ?? 'undefined'
      }
    }

  }
}
</script>

<style scoped>
.no-margin-radio >>>.v-input--selection-controls__input {
  margin-right: 4px;
  margin-left: 4px;
}
</style>
