import axios from 'axios'

export default {
  search (data) {
    return axios.post('/api/gallery/search', data)
  },
  imagesList () {
    return axios.get('/api/gallery/imageslist')
  },
  upload (data) {
    return axios.post('/api/gallery/upload', data)
  },
  removeTag (data) {
    return axios.post('/api/gallery/removetag', data)
  },
  updateTags (data) {
    return axios.post('/api/gallery/updatetags', data)
  },
  deleteImages (data) {
    return axios.post('/api/gallery/deleteimages', data)
  },
  deleteOwnImage (data) {
    return axios.post('/api/gallery/delete-own-image', data)
  }

}
