export default {
  it: {
    title: {
      redirecting: 'Redirecting',
      import_panel: 'Import Panel',
      listini: 'Listini',
      edit_courses: 'Edit Courses',
      product_permission: 'Disable Products',
      edit_users: 'Edit Users',

      profile: 'Profilo',
      company: 'Dati Aziendali',
      user: 'Utente',
      invoices: 'Fatture',
      orders: 'Storico Ordini',
      orders_admin: 'Storico Ordini',
      cart: 'Carrello',
      users: 'Gestione Utenze',
      search: 'Risultati ricerca'
    }
  },
  en: {
    title: {
      redirecting: 'Redirecting',
      import_panel: 'Import Panel',
      listini: 'Listini',
      edit_courses: 'Edit Courses',
      product_permission: 'Disable Products',
      edit_users: 'Edit Users',

      profile: 'Profile',
      company: 'Company',
      user: 'User',
      invoices: 'Invoices',
      orders: 'Orders',
      orders_admin: 'Orders',
      cart: 'Cart',
      users: 'Users',
      search: 'Search results'
    }
  },
  fr: {
    title: {
      redirecting: 'Redirecting',
      import_panel: 'Import Panel',
      listini: 'Listini',
      edit_courses: 'Edit Courses',
      product_permission: 'Disable Products',
      edit_users: 'Edit Users',

      profile: 'Profil',
      company: 'Données d\'entreprise',
      user: 'Utilisateur',
      invoices: 'Factures',
      orders: 'Vos commandes',
      orders_admin: 'Vos commandes',
      cart: 'Panier d\'achat',
      users: 'Gestion des utilisateurs',
      search: 'Résultats de recherche'
    }
  },
  es: {
    title: {
      redirecting: 'Redirecting',
      import_panel: 'Import Panel',
      listini: 'Listini',
      edit_courses: 'Edit Courses',
      product_permission: 'Disable Products',
      edit_users: 'Edit Users',

      profile: 'Perfil',
      company: 'Datos corporativos',
      user: 'Usuario',
      invoices: 'Facturas',
      orders: 'Historial de pedidos',
      orders_admin: 'Historial de pedidos',
      cart: 'Carrito de compras',
      users: 'Gestión de utilidades',
      search: 'Resultados de la búsqueda'
    }
  },
  de: {
    title: {
      redirecting: 'Redirecting',
      import_panel: 'Import Panel',
      listini: 'Listini',
      edit_courses: 'Edit Courses',
      product_permission: 'Disable Products',
      edit_users: 'Edit Users',

      profile: 'Profil',
      company: 'Firmendaten',
      user: 'Benutzer',
      invoices: 'Rechnungen',
      orders: 'Meine Bestellungen',
      orders_admin: 'Meine Bestellungen',
      cart: 'Warenkorb',
      users: 'Benutzerkonto',
      search: 'Suchergebnis'
    }
  },
  nl: {
    title: {
      redirecting: 'Redirecting',
      import_panel: 'Import Panel',
      listini: 'Listini',
      edit_courses: 'Edit Courses',
      product_permission: 'Disable Products',
      edit_users: 'Edit Users',

      profile: 'Profiel',
      company: 'Bedrijfsgegevens',
      user: 'Gebruiker',
      invoices: 'Factuur',
      orders: 'Bestelgeschiedenis',
      orders_admin: 'Bestelgeschiedenis',
      cart: 'Winkelwagen',
      users: 'Beheer van gebruikers',
      search: 'Zoekresultaten'
    }
  }
}
