<template>
  <div class="container">
    <div class="row col">
      <h1>{{ $t('messages.Listini') }}</h1>
    </div>
    <div class="row col" />

    <v-progress-circular
      v-if="isLoading"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-for="listino in listini"
      v-else
      :key="listino.id"
    >
      <v-btn
        class="mb-4"
        color="primary"
        style="width: 300px; height: 40px"
        tile
        dark
        :loading="downloadingListino"
        elevation="0"
        @click="downloadListino(listino)"
      >
        <span class="custom-button">{{ listino.name }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'Listini',
  data: () => ({
    test: 'testo esempio',
    downloadingListino: false
  }),

  computed: {
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    listini () {
      return this.$store.getters['listini/listini']
    },
    isLoading () {
      return this.$store.getters['listini/isLoading']
    }
  },

  created () {
    this.$store.dispatch('listini/listListini')
  },
  methods: {
    async downloadListino (listino) {
      if (this.downloadingListino) {
        return
      }
      this.downloadingListino = true
      try {
        const r = await axios.get('/api/listino/download/' + listino.listino_id, { responseType: 'arraybuffer' })
        const returnedFile = new Blob([r.data])
        const fileName = listino.name
        const fileURL = URL.createObjectURL(returnedFile)
        const ancorTag = document.createElement('a')
        ancorTag.href = fileURL; ancorTag.target = '_blank'
        ancorTag.download = fileName
        document.body.appendChild(ancorTag)
        ancorTag.click()
        document.body.removeChild(ancorTag)
      } catch (error) {
        console.log(error)
      }
      this.downloadingListino = false
    }
  }
}
</script>

<style scoped>
.custom-button {
    word-wrap: break-word;
    white-space: normal;
    align-items: center;
    color: inherit;
    display: flex;
    flex: 1 1 auto;
    justify-content: inherit;
    line-height: normal;
    position: relative;
}
>>>.v-btn__content {
    flex: 1 1 auto;
}
</style>
