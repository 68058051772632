import GiroAPI from '../api/giro'

const FETCHING_DETAILS_GIRO = 'FETCHING_DETAILS_GIRO'
const FETCHING_DETAILS_GIRO_SUCCESS = 'FETCHING_DETAILS_GIRO_SUCCESS'
const FETCHING_DETAILS_GIRO_ERROR = 'FETCHING_DETAILS_GIRO_ERROR'

const SAVE_GIRO = 'SAVE_GIRO'
const SAVE_GIRO_SUCCESS = 'SAVE_GIRO_SUCCESS'
const SAVE_GIRO_ERROR = 'SAVE_GIRO_ERROR'

const SET_VISITED = 'SET_VISITED'
const SET_VISITED_SUCCESS = 'SET_VISITED_SUCCESS'
const SET_VISITED_ERROR = 'SET_VISITED_ERROR'

const LIST_GIRI = 'LIST_GIRI'
const LIST_GIRI_SUCCESS = 'LIST_GIRI_SUCCESS'
const LIST_GIRI_ERROR = 'LIST_GIRI_ERROR'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    giroList: []
  },
  getters: {
    list (state) {
      return state.giroList
    },
    isLoading (state) {
      return state.isLoading
    },
    error (state) {
      return state.error
    },
    errorMessage (state) {
      return state.error.response.data.detail
    }
  },
  mutations: {
    [FETCHING_DETAILS_GIRO] (state) {
      state.isLoading = true
      state.error = null
    },
    [FETCHING_DETAILS_GIRO_SUCCESS] (state, data) {
      state.isLoading = false
      state.error = null
    },
    [FETCHING_DETAILS_GIRO_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [SAVE_GIRO] (state) {
      state.isLoading = true
      state.error = null
    },
    [SAVE_GIRO_SUCCESS] (state, data) {
      state.isLoading = false
      state.giroList.push(data.date)
      state.error = null
    },
    [SAVE_GIRO_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [SET_VISITED] (state) {
      state.error = null
    },
    [SET_VISITED_SUCCESS] (state, data) {
      state.error = null
    },
    [SET_VISITED_ERROR] (state, error) {
      state.error = error
    },
    [LIST_GIRI] (state) {
      state.error = null
    },
    [LIST_GIRI_SUCCESS] (state, data) {
      state.error = null
      state.giroList = data
    },
    [LIST_GIRI_ERROR] (state, error) {
      state.error = error
    }
  },

  actions: {
    async details ({ commit }, data) {
      commit(FETCHING_DETAILS_GIRO)
      try {
        const response = await GiroAPI.details(data)
        commit(FETCHING_DETAILS_GIRO_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_DETAILS_GIRO_ERROR, error)
        return null
      }
    },

    async save ({ commit }, data) {
      commit(SAVE_GIRO)
      try {
        const response = await GiroAPI.save(data)
        commit(SAVE_GIRO_SUCCESS, data)
        return response.data
      } catch (error) {
        commit(SAVE_GIRO_ERROR, error)
        return null
      }
    },

    async setVisited ({ commit }, data) {
      commit(SET_VISITED)
      try {
        const response = await GiroAPI.setVisited(data)
        commit(SET_VISITED_SUCCESS, data)
        return response.data
      } catch (error) {
        commit(SET_VISITED_ERROR, error)
        return null
      }
    },

    async list ({ commit }, area) {
      commit(LIST_GIRI)
      try {
        const response = await GiroAPI.list(area)
        commit(LIST_GIRI_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(LIST_GIRI_ERROR, error)
        return null
      }
    }
  }
}
