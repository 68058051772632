<template>
  <v-container class="px-1 py-0">
    <v-progress-circular
      v-if="isInitializing != 0"
      indeterminate
      color="primary"
      class="mt-3 ml-3"
    />
    <div
      v-else-if="canUpload === 1"
    >
      <div class="mb-2">
        <v-file-input
          v-model="currFiles"
          accept="image/*"
          multiple
          hide-details
          :label="$t('messages.Carica immagini')"
          @change="inputChanged"
        />
      </div>
      <h5>
        {{ $t('messages.Imposta le proprietà dell immagine!') }}
      </h5>
      <div
        class="mt-2 mb-2"
      >
        <client-selector
          v-if="!anonymousClient"
          v-model="selectedClient"
          :clients="clientsList"
          :label="$t('messages.Seleziona un Cliente')"
          hide-details
          class="persistent-placeholder"
        />
        <v-text-field
          v-if="anonymousClient"
          :value="$t('messages.Solo uso interno')"
          filled
          :label="$t('messages.Seleziona un Cliente')"
          hide-details
          class="persistent-placeholder"
          :readonly="true"
        />
        <div class="d-flex justify-end align-center">
          <h6 class="mr-1">
            {{ $t('messages.Cliente sconosciuto') }}
          </h6>
          <v-checkbox
            v-model="anonymousClient"
            class="pt-0 mt-0"
            hide-details
          />
        </div>
      </div>
      {{ $t('messages.Prodotti nelle immagini') }}:
      <div
        v-for="(item, i) in selectedProducts"
        :key="item.id"
        class="pa-1"
        style="border: 3px solid silver; position: relative; margin-top: 4px"
      >
        <product-selector
          v-model="item.product"
          :products="products"
          class="flex-grow-1 mr-2 persistent-placeholder"
          hide-details
          style="width:100%; min-width:100%; max-width:100%;"
        />
        <v-icon
          v-if="i !== 0"
          medium
          color="error"
          style="border-radius: 50%; position: absolute; right:0px; top:0px"
          @click="deleteField(item, i)"
        >
          mdi-close
        </v-icon>
      </div>
      <div
        class="d-flex align-center justify-space-around mt-1"
        style="border: 3px solid #e1e1e1; position: relative; margin-top: -3px; height: 206px"
      >
        <v-btn
          fab
          dark
          elevation="0"
          color="#E1E1E1"
          class="hover-primary"
          @click="addField"
        >
          <v-icon color="white">
            mdi-plus
          </v-icon>
        </v-btn>
      </div>
      <div class="d-flex mb-3 mt-2">
        <v-text-field
          v-model="selectedText"
          filled
          hide-details
          elevation="0"
          tile
          :label="$t('messages.Note / Testo libero')"
          :placeholder="$t('messages.Note / Testo libero')"
          class="persistent-placeholder"
        />
      </div>
      <div class="d-flex justify-center">
        <v-btn
          color="success"
          class="btn btn-lg btn-primary white--text mx-0"
          type="button"
          :loading="isLoading"
          block
          elevation="0"
          tile
          @click="uploadFile"
        >
          <v-icon class="mr-1">
            mdi-cloud-upload-outline
          </v-icon>
          {{ $t('messages.Carica') }}
        </v-btn>
      </div>

      <div
        v-if="files.length"
        class="d-flex flex-grow-1 caption mt-3"
      >
        {{ $t('messages.Immagini pre-caricate') }}: <b>{{ files.length }}</b>
      </div>

      <div
        v-if="files.length"
        class="d-flex flex-wrap"
      >
        <div
          v-for="(file, index) in files"
          :key="index"
          class="d-flex align-start ma-2"
        >
          <v-img
            max-height="100"
            min-height="100"
            max-width="100"
            min-width="100"
            aspect-ratio="1"
            :src="computePreview(file)"
          />
          <v-icon
            class="ml-n3 mt-n3"
            color="red"
            style="background-color: white;border-radius: 50%;"
            @click="removeImage(index)"
          >
            mdi-close-circle
          </v-icon>
        </div>
      </div>
    </div>
    <div
      v-else-if="canUpload !== 1"
      class="mt-4"
    >
      <v-card
        elevation="0"
        tile
        class="error d-flex align-center justify-center"
        style="min-height:150px; max-height:150px; height:150px;"
      >
        <span
          class="title white--text"
        >
          {{ $t('messages.Cliente non trovato') }}
        </span>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import ClientSelector from '../components/ClientSelector.vue'
import ProductSelector from '../components/ProductSelector.vue'

export default {
  name: 'GalleryInsert',

  components: {
    ClientSelector,
    ProductSelector
  },

  data: () => ({
    isInitializing: 0,
    isLoading: false,
    isRemoving: false,
    selectedClient: null,
    selectedText: null,
    tags: [],
    currFiles: [],
    files: [],
    canUpload: 0,
    selectedProducts: [{ product: null, id: 0 }],
    currentId: 1,
    anonymousClient: false
  }),

  computed: {
    clientsList () {
      return this.$store.getters['clients/clientsList']
    },
    products () {
      return this.$store.getters['products/list']
    },
    errorMessage () {
      return this.$store.getters['gallery/errorMessage']
    }
  },

  created () {
    this.isInitializing = 2
    this.$store.dispatch('clients/clientsList', { isProjx: true }).then(() => { this.isInitializing--; this.initialize() })
    this.$store.dispatch('products/list').then(() => { this.isInitializing--; this.initialize() })
  },

  methods: {
    initialize () {
      if (this.$route.params.clientId && this.clientsList.find(c => c.id === this.$route.params.clientId)) {
        this.selectedClient = this.$route.params.clientId
        this.canUpload = 1
      }
      if (!this.$route.params.clientId) {
        this.canUpload = 1
      }
    },

    async toBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },

    inputChanged () {
      this.files = [
        ...this.currFiles,
        ...this.files
      ]
    },

    addField () {
      this.selectedProducts.push({ product: null, id: this.currentId++ })
    },

    deleteField (item, index) {
      this.selectedProducts.splice(index, 1)
    },

    async uploadFile () {
      this.isLoading = true
      let errorMessages = ''
      if (this.files.length === 0) {
        errorMessages += this.$t('messages.Caricare almeno un immagine') + '\n'
      }
      if (!this.anonymousClient && !this.selectedClient) {
        errorMessages += this.$t('messages.Selezionare un Cliente') + '\n'
      }
      this.selectedProducts.find(item => {
        if (!item.product ||
        !item.product.selectedCategoryId ||
        !item.product.selectedLineId ||
        !item.product.selectedCommercialCodeId) {
          errorMessages += this.$t('messages.Compilare tutti campi dei prodotti') + '\n'
          return true
        }
        return false
      })
      if (errorMessages !== '') {
        const dataMessage = { isOpen: true, message: errorMessages, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        this.isLoading = false
        return
      }
      const images = []
      for (let index = 0; index < this.files.length; index++) {
        const file = this.files[index]
        const imageParams = {}
        imageParams.name = file.name
        imageParams.type = file.type
        imageParams.size = file.size
        imageParams.image = await this.toBase64(file)
        images.push(imageParams)
      }

      const tags = {}
      if (this.selectedClient) {
        const clientParams = {}
        clientParams.type = 'client'
        clientParams.value = this.selectedClient
        tags.client = clientParams
      }
      if (this.anonymousClient) {
        const extraTag = {}
        extraTag.type = 'free'
        extraTag.value = 'Internal use Only'
        tags.extraTags = [extraTag]
      }

      tags.products = []
      this.selectedProducts.forEach(item => {
        const categoryIdParams = {}
        const lineIdParams = {}
        const commercialCodeIdParams = {}
        categoryIdParams.type = 'categoryId'
        categoryIdParams.value = item.product.selectedCategoryId
        tags.products.push(categoryIdParams)
        lineIdParams.type = 'lineId'
        lineIdParams.value = item.product.selectedLineId
        tags.products.push(lineIdParams)
        commercialCodeIdParams.type = 'commercialCodeId'
        commercialCodeIdParams.value = item.product.selectedCommercialCodeId
        tags.products.push(commercialCodeIdParams)
      })

      if (this.selectedText) {
        const textParams = {}
        textParams.type = 'free'
        textParams.value = this.selectedText
        tags.text = textParams
      }

      const result = await this.$store.dispatch('gallery/upload', { images: images, tags: tags })
      if (result !== null) {
        if (images.length === '1') {
          const message = this.$t('messages.Immagine caricata')
          const dataMessage = { isOpen: true, message: message, color: 'success' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        } else if (images.length > '1') {
          const message = this.$t('messages.Immagini caricate')
          const dataMessage = { isOpen: true, message: message, color: 'success' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        }
        this.$router.go()
      } else {
        const message = this.errorMessage
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }

      this.isLoading = false
    },

    computePreview (file) {
      return URL.createObjectURL(file)
    },

    removeImage (index) {
      this.files.splice(index, 1)
    }
  }
}
</script>

<style>
.transition-element-enter-active,
.transition-element-leave-active {
  transition: all 0.3s;
  transition-timing-function: linear;
  max-height:200px;
}
.transition-element-enter,
.transition-element-leave-to
{
  opacity: 0;
  max-height: 0px;
}
.hover-primary:hover {
  background-color: #2559b9!important;
}
</style>
