<template>
  <div class="container">
    <v-progress-circular
      v-if="isInitializing != 0"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <div class="row col">
        <h1>{{ $t('messages.Gestione Classi') }}</h1>
      </div>

      <h4>{{ $t('messages.Cerca in') }}:</h4>
      <div class="d-flex flex-wrap">
        <v-checkbox
          v-model="searchFilter.cdClass"
          hide-details
          class="mx-2 my-1"
          dense
          :label="$t('messages.CodpClasse')"
          @change="debounceSearch"
        />
        <v-checkbox
          v-model="searchFilter.cdSuperClass"
          hide-details
          class="mx-2 my-1"
          dense
          :label="$t('messages.CodpSuperclasse')"
          @change="debounceSearch"
        />
      </div>
      <v-text-field
        v-model="searchFilter.search"
        filled
        dense
        hide-details
        :loading="isLoadingFilter"
        :placeholder="$t('messages.Cerca ppp')"
        class=""
        @input="debounceSearch"
      />
      <v-data-table
        :headers="headers"
        :items="filteredClassList"
        :loading="isLoading"
        :locale="$t('messages.locale')"
        elevation="0"
        :header-props="{ sortByText: $t('messages.Ordina per')}"
        :footer-props="{
          itemsPerPageAllText: $t('messages.Tutte'),
          itemsPerPageText: $t('messages.Per pagina')
        }"
        :page="searchFilter.page"
        :items-per-page="searchFilter.itemsPerPage"
        @pagination="updatePagination"
      >
        <template #[`item.cd_superclasse`]="{ item }">
          <v-autocomplete
            :value="item.cd_superclasse"
            :items="superClasses"
            :loading="isLoading"
            :menu-props="{
              maxHeight:200,
            }"
            hide-details
            dense
            label=""
            @input="updateSuperClass(item, $event)"
          >
            <template #[`item`]="appearance">
              {{ appearance.item }}
            </template>
            <template #[`selection`]="selection">
              <v-chip
                class="mx-1 white--text"
                color="primary"
                small
              >
                {{ selection.item }}
              </v-chip>
            </template>
          </v-autocomplete>
        </template>

        <template #[`footer.page-text`]="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} di {{ itemsLength }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ClassesAssign',

  data: () => ({
    searchFilter: {
      search: '',
      cdClass: true,
      cdSuperClass: true,
      page: 1,
      itemsPerPage: 10
    },
    isInitializing: 1,
    isLoading: false,
    superClasses: ['AD', 'AP', 'WR', 'NA'],
    filteredClassList: [],
    filterDebounce: null,
    isLoadingFilter: false,
    initializeFilter: false
  }
  ),

  computed: {
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    isAdmin () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN')
    },
    error () {
      return this.$store.getters['classes/error']
    },
    headers () {
      return [
        { text: this.$t('messages.CodpClasse'), value: 'cd_classe', filterable: true },
        { text: this.$t('messages.CodpSuperclasse'), value: 'cd_superclasse', filterable: false }
      ]
    }
  },

  created () {
    this.isInitializing = 1

    this.initializeFilter = false
    Object.keys(this.searchFilter).forEach(key => {
      if (this.$route.query[key] !== undefined) {
        this.initializeFilter = true
        if (key === 'page' || key === 'itemsPerPage') {
          this.searchFilter[key] = parseInt(this.$route.query[key])
        } else {
          // parsa le stringhe 'true' o 'false' come boolean, altrimenti parsa come stringa
          this.searchFilter[key] = (this.$route.query[key] === 'true' || this.$route.query[key] === 'false') ? this.$route.query[key] === 'true' : this.$route.query[key]
        }
      }
    })

    this.$store.dispatch('classes/classesList').then((cl) => {
      if (cl !== null) {
        this.filteredClassList = cl
        this.isInitializing--
        this.initialize()
      }
    })
  },

  methods: {
    initialize () {
      if (this.isInitializing === 0) {
        if (this.initializeFilter) {
          this.doFilter()
        }
      }
    },

    debounceSearch () {
      clearTimeout(this.filterDebounce)
      this.isLoadingFilter = true
      this.filterDebounce = setTimeout(() => {
        // do filter
        this.doFilter()
        this.isLoadingFilter = false
      }, 1000)
    },

    doFilter () {
      const classList = this.$store.getters['classes/classesList']
      this.$router.replace({ query: this.searchFilter }).catch(() => {})
      this.filteredClassList = classList.filter((c) => {
        const isFound = (this.searchFilter.search === '' || (
          (this.searchFilter.cdClass && (c.cd_classe ?? '').toLowerCase().includes(this.searchFilter.search.toLowerCase())) ||
          (this.searchFilter.cdSuperClass && (c.cd_superclasse ?? '').toLowerCase().includes(this.searchFilter.search.toLowerCase()))
        )
        )
        return isFound
      })
    },

    updatePagination (event) {
      this.searchFilter.page = event.page
      this.searchFilter.itemsPerPage = event.itemsPerPage
      this.$router.replace({ query: this.searchFilter }).catch(() => {})
    },

    async updateSuperClass (item, superclass) {
      item.isLoading = true
      const data = { superclass: superclass, classId: item.id }
      const result = await this.$store.dispatch('classes/setSuperClass', data)
      if (result !== null) {
        const message = this.$t('messages.Superclasse aggiornata')
        const dataMessage = { isOpen: true, message: message, color: 'success' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
      item.cd_superclasse = superclass
      item.isLoading = false
    }

  }
}
</script>
