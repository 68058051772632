import axios from 'axios'

export default {
  list () {
    return axios.get('/api/products')
  },
  listObsolete () {
    return axios.get('/api/products?obsolete=1')
  },
  detail (productId) {
    return axios.get('/api/products/detail/' + productId)
  },
  listcampioni () {
    return axios.get('/api/campionature')
  },
  listcampioniprodotti () {
    return axios.get('/api/campionatureprodotti')
  },
  savecampioniprodotti (data) {
    return axios.post('/api/campionatureprodotti/save', data)
  },
  getAvailability (data) {
    return axios.post('/api/articlegetdisp', data)
  }
}
