import { render, staticRenderFns } from "./ReportBroad.vue?vue&type=template&id=054348a9&scoped=true&"
import script from "./ReportBroad.vue?vue&type=script&lang=js&"
export * from "./ReportBroad.vue?vue&type=script&lang=js&"
import style0 from "./ReportBroad.vue?vue&type=style&index=0&id=054348a9&scoped=true&lang=css&"
import style1 from "./ReportBroad.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "054348a9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VAvatar,VBtn,VIcon,VProgressCircular,VRadio,VRadioGroup,VSelect,VSwitch})
