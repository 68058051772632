
import message from './message'
import generic from './generic'
import shop from './shop'
import cart from './cart'
import pageTitles from './page-titles'
import profile from './profile'
import messages from './messages'

const translations = { it: {}, en: {}, fr: {}, es: {}, de: {}, nl: {} }

function merge (element) {
  Object.keys(translations).forEach(function (key) {
    translations[key] = { ...translations[key], ...element[key] }
  })
}

merge(shop)
merge(message)
merge(messages)
merge(generic)
merge(pageTitles)
merge(cart)
merge(profile)
// console.log(translations)
export default {
  ...translations
}
