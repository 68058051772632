const SHOW_MESSAGE = 'SHOW_MESSAGE'

const messageSnackbarDefaultState = { isOpen: false, message: '', color: 'success', timeout: 3000 }
export default {
  namespaced: true,
  state: {
    messageSnackbar: messageSnackbarDefaultState
  },
  getters: {
    messageSnackbar (state) {
      return state.messageSnackbar
    },
    messageSnackbarIsOpen (state) {
      return state.messageSnackbar.isOpen
    }
  },
  mutations: {
    [SHOW_MESSAGE] (state, data) {
      state.messageSnackbar = messageSnackbarDefaultState

      if (data.message !== undefined) {
        state.messageSnackbar.message = data.message
      }
      if (data.color !== undefined) {
        state.messageSnackbar.color = data.color
      }
      if (data.timeout !== undefined) {
        state.messageSnackbar.timeout = data.timeout
      }

      state.messageSnackbar.isOpen = false
      setTimeout(() => { state.messageSnackbar.isOpen = data.isOpen }, 10)
    }
  },

  actions: {
    setMessageSnackbar ({ commit }, data) {
      commit(SHOW_MESSAGE, data)
    }
  }
}
