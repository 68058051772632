<template>
  <v-container>
    <v-card-title class="headline d-flex justify-center">
      {{ $t('messages.Scadenze Aperte') }}
    </v-card-title>

    <v-card-text
      class="mx-n2 px-0"
    >
      <div
        v-if="loadingDues"
        class="d-flex justify-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          :size="70"
          class="mt-3 ml-3"
        />
      </div>
      <div v-else-if="openDues!=null">
        <area-selector
          v-model="selectedArea"
          class="my-y"
          :areas="areasVisibleToAgent"
        />

        <div
          v-for="(area) in filteredOpenDues"
          :key="area.Cd_Agent"
          class="mt-12"
        >
          <h3>
            {{ area.Cd_Agent + ' - ' + area.Area }}
          </h3>
          <h3 class="font-weight-black">
            {{ $t('messages.Totp') }} {{ formatCurrency(area.Importo,area.Valuta) }}
          </h3>
          <div>
            <div
              v-for="(cliente) in area.Clienti"
              :key="cliente.Cd_Clien"
            >
              <div
                style="border-left: 2px solid #2559b9;"
                class="pl-1 my-4 mr-n3"
              >
                <h4>
                  {{ cliente.Cd_Clien + ' - ' + cliente.Azienda }}
                </h4>
                <h5 class="grey--text">
                  {{ cliente.Localita + ' - ' + cliente.Indirizz }}
                </h5>
                <h4 class="font-weight-black">
                  {{ $t('messages.Totp') }} {{ formatCurrency(cliente.Importo,cliente.Valuta) }}
                </h4>
                <div style="font-size: 0.750rem;">
                  <div class="d-flex">
                    <div
                      class="scadenza-cell font-weight-black mr-1"
                      style="width:70px;min-width:70px"
                    >
                      {{ $t('messages.Scadenza') }}
                    </div>
                    <div
                      class="scadenza-cell font-weight-black mr-1 text-end"
                      style="width:60px;min-width:60px"
                    >
                      {{ $t('messages.Fattp') }}
                    </div>
                    <div
                      class="scadenza-cell font-weight-black mr-1 text-end"
                      style="width:60px;min-width:60px"
                    >
                      {{ $t('messages.Importo') }}
                    </div>
                    <div
                      class="scadenza-cell font-weight-black mr-1 text-end"
                      style="width:90px"
                    >
                      {{ $t('messages.Pagam') }}
                    </div>
                    <div
                      class="scadenza-cell font-weight-black mr-1 text-end"
                      style="width:30px;min-width:30px"
                    >
                      {{ $t('messages.Ritar') }}
                    </div>
                  </div>
                  <div
                    v-for="(scadenza) in cliente.Scadenze"
                    :key="scadenza.ID"
                    style="border-bottom: 1px solid #ddd;"
                  >
                    <div class="d-flex">
                      <div
                        class="scadenza-cell mr-1"
                        style="width:70px;min-width:70px"
                      >
                        {{ formatDate(scadenza.ScadData) }}
                      </div>
                      <div
                        class="scadenza-cell mr-1 text-end"
                        style="width:60px;min-width:60px"
                      >
                        {{ scadenza.FattNum }}
                      </div>
                      <div
                        class="scadenza-cell mr-1 text-end"
                        style="width:60px;min-width:60px"
                      >
                        {{ formatCurrency(scadenza.Import,scadenza.Valuta) }}
                      </div>
                      <div
                        class="scadenza-cell mr-1 text-end"
                        style="width:90px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                      >
                        {{ scadenza.TipoPagamento }}
                      </div>
                      <div
                        class="scadenza-cell mr-1 text-end"
                        style="width:30px;min-width:30px"
                      >
                        {{ scadenza.Ritardo }}
                      </div>
                    </div>
                    <div v-if="scadenza.Note">
                      <!-- eslint-disable-next-line -->
                          <h5 style="white-space: pre-wrap; display: block; line-height: 1.4;font-size: 0.7rem;">{{ scadenza.Note }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-container>
</template>
<script>
import AreaSelector from '../components/AreaSelector.vue'
import axios from 'axios'

export default {
  name: 'ViewopenDues',

  components: {
    AreaSelector
  },
  data: () => ({
    open: false,
    openDues: null,
    loadingDues: false,
    expanded: [],
    selectedArea: ''
  }),

  computed: {
    areasVisibleToAgent () {
      return this.$store.getters['agents/areasVisibleToAgent']
    },
    filteredOpenDues () {
      if (!this.selectedArea) {
        return this.openDues
      } else {
        return this.openDues.filter(od => {
          return this.selectedArea === od.Cd_Agent
        })
      }
    }
  },

  async created () {
    if (this.openDues === null) {
      if (this.loadingDues) {
        return
      }
      this.loadingDues = true
      try {
        const r = await axios.get('/api/clients/open-dues')
        this.openDues = r.data.scadenzeAperte
      } catch (error) {
        const message = error.response.data.detail
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
      await this.$store.dispatch('agents/areasVisibleToAgent')
      this.loadingDues = false
    }
  },
  methods: {
    formatCurrency (currency, valuta = 'EUR') {
      if (currency === null) return ''

      return (new Intl.NumberFormat('it-IT', { style: 'currency', currency: valuta }).format(currency))
    },
    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    }
  }
}
</script>
