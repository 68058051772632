
export default {
  it: {
    profile: {
      company_edit_message: 'Il profilo aziendale è registrato internamente. Per modificare l\'anagrafica aziendale scrivere a ',
      mail_edit_projx: 'info.ita@apaspa.com',
      view_orders: 'Visualizza Ordini',
      view_invoices: 'Visualizza Fatture',
      download_price_sheet: 'Scarica Listino',
      user_data: 'Dati Utente',
      manage_users: 'Gestisci Utenti',

      invoice_mail: 'E-Mail Fatturazione',

      first_name: 'Nome',
      last_name: 'Cognome',
      user_email: 'E-Mail',
      password: 'Password',
      reset_password: 'Password reset',
      old_password: 'Password vecchia',
      new_password: 'Password nuova',
      confirm_password: 'Conferma password',
      password_error_message: 'La password originale non è corretta',
      password_mismatch_message: 'Le password non corrispondono',
      password_updated_message: 'Password aggiornata',
      invoice_email_updated_message: 'Email aggiornata',

      invoices_list: 'Lista Fatture',
      no_invoices: 'Nessuna fattura presente',

      no_orders: 'Nessun ordine presente',
      order_detail: 'Dettaglio Ordine',
      repeat_order: 'Ripeti Ordine',
      warning: 'Attenzione',
      cart_overwrite_message: 'Il carrello attuale verrà sovrascritto, continuare?',

      date: 'Data',
      items: 'Elementi',
      code: 'Codice',
      quantity_short: 'Q.ta',
      updated_cart: 'Carrello aggiornato',

      confirm_delete_message: 'Sei sicuro di voler eliminare l\'utente {user}?',
      make_user_pricipal: 'Rendi Principale',
      confirm_principal_one: 'È possibile avere solo un utente principale.',
      confirm_principal_two: 'Sei sicuro di voler rendere principale l\'utente {user}?',
      confirm_principal_three: 'Il tuo account verrà reso secondario.',
      edit_roles: 'Modifica Permessi',
      roles: 'Permessi',
      user: 'Utente',
      create_user: 'Crea nuovo utente',
      primary: 'PRINCIPALE',

      actions: 'Azioni',
      role_orders_text: 'Ordini',
      role_invoices_text: 'Fatture',

      roles_updated: 'Ruoli aggiornati',
      generic_error: 'Si è verificato un errore',
      upgrade_done: 'Upgrade effettuato',
      user_deleted: 'Utente eliminato',
      user_created: 'Utente creato'
    }
  },
  en: {
    profile: {
      company_edit_message: 'The company profile is saved internally. To modify the company registration please email ',
      mail_edit_projx: 'info@apaspa.com',
      view_orders: 'Your Orders',
      view_invoices: 'Your invoices',
      download_price_sheet: 'Download Price List',
      user_data: 'User data',
      manage_users: 'Manage Users',
      invoice_mail: 'Billing E-Mail',

      first_name: 'Name',
      last_name: 'Surname',
      user_email: 'E-Mail',
      password: 'Password',
      reset_password: 'Reset password',
      old_password: 'Old password',
      new_password: 'New password',
      confirm_password: 'Confirm password',
      password_error_message: 'The password is incorrect',
      password_mismatch_message: 'Passwords do not match',
      password_updated_message: 'Password updated',
      invoice_email_updated_message: 'Email updated',

      invoices_list: 'Invoice List',
      no_invoices: 'No invoice present',

      no_orders: 'No order present',
      order_detail: 'Order Detail',
      repeat_order: 'Repeat Order',
      warning: 'Caution',
      cart_overwrite_message: 'The current basket will be overwritten, continue?',

      date: 'Date',
      items: 'Elements',
      codice: 'Part-No.',
      quantity_short: 'Q.ty',
      updated_cart: 'Shopping basket updated',

      confirm_delete_message: 'Are you sure you want to delete user {user}?',
      make_user_pricipal: 'Make main user',
      confirm_principal_one: 'It is only possible to have only one main user.',
      confirm_principal_two: 'Are you sure you want to make {user} the main user?',
      confirm_principal_three: 'Your account will be made secondary.',
      edit_roles: 'Change Permissions',
      roles: 'Permissions',
      user: 'User',
      create_user: 'Create new user',
      primary: 'MAIN USER',

      actions: 'Actions',
      role_orders_text: 'Orders',
      role_invoices_text: 'Invoices',

      roles_updated: 'Updated roles',
      generic_error: 'An error has occurred',
      upgrade_done: 'Upgrade done',
      user_deleted: 'User deleted',
      user_created: 'User created'
    }
  },
  fr: {
    profile: {
      company_edit_message: 'Le profil de l\'entreprise est enregistré en interne. Pour modifier le registre de l\'entreprise, écrivez à  ',
      mail_edit_projx: 'info@apaspa.com',
      view_orders: 'Voir liste des commandes',
      view_invoices: 'Voir liste des factures',
      download_price_sheet: 'Télécharger la liste de prix',
      user_data: 'Données d\'utilisateur',
      manage_users: 'Gestion des utilisateurs',
      invoice_mail: 'E-Mail de facturation',

      first_name: 'Prénom',
      last_name: 'Nom',
      user_email: 'E-Mail',
      password: 'Mot de passe',
      reset_password: 'Réinitialisation du mot de passe',
      old_password: 'Ancien mot de passe',
      new_password: 'Nouveau mot de passe',
      confirm_password: 'Confirmez le mot de passe',
      password_error_message: 'Le mot de passe d\'origine est incorrect',
      password_mismatch_message: 'Les mots de passe ne correspondent pas',
      password_updated_message: 'Email mis à jour',
      invoice_email_updated_message: 'Email updated',

      invoices_list: 'Liste des factures',
      no_invoices: 'Aucune facture presentè',

      no_orders: 'Aucune facture présente',
      order_detail: 'Détails de la commande',
      repeat_order: 'Répète la commande',
      warning: 'Attention',
      cart_overwrite_message: 'Le panier actuel sera écrasé, continuer?',

      date: 'Date',
      items: 'Éléments',
      codice: 'Code',
      quantity_short: 'Quantité',
      updated_cart: 'Panier mis à jour',

      confirm_delete_message: 'Voulez-vous vraiment supprimer l\'utilisateur {user}?',
      make_user_pricipal: 'Rendre l\'utilisateur principal.',
      confirm_principal_one: 'Vous ne pouvez avoir qu\'un seul utilisateur principal.',
      confirm_principal_two: 'Voulez-vous vraiment rendre l\'utilisateur {user} principal?',
      confirm_principal_three: 'Votre compte sera rendu secondaire.',
      edit_roles: 'Modifier les autorisations',
      roles: 'Autorisations',
      user: 'Utilisateur',
      create_user: 'Créer un nouvel utilisateur',
      primary: 'PRINCIPALE',

      actions: 'Actions',
      role_orders_text: 'Commandes',
      role_invoices_text: 'Factures',

      roles_updated: 'Rôles mis à jour',
      generic_error: 'Une erreur est survenue',
      upgrade_done: 'Mise à jour terminée',
      user_deleted: 'Utilisateur supprimé',
      user_created: 'Utilisateur créé'
    }
  },
  es: {
    profile: {
      company_edit_message: 'El perfil de la empresa se registra internamente. Para cambiar los datos de la empresa, escriba en ',
      mail_edit_projx: 'info@apaspa.com',
      view_orders: 'Ver pedidos',
      view_invoices: 'Ver facturas',
      download_price_sheet: 'Descargar lista de precios',
      user_data: 'Datos del usuario',
      manage_users: 'Gestión usuarios',
      invoice_mail: 'E-Mail de facturación',

      first_name: 'Nombre',
      last_name: 'Apellido',
      user_email: 'E-Mail',
      password: 'Contraseña',
      reset_password: 'Restablecer contraseña',
      old_password: 'Contraseña anterior',
      new_password: 'Nueva contraseña',
      confirm_password: 'Confirmar contraseña',
      password_error_message: 'La contraseña original es incorrecta',
      password_mismatch_message: 'Las contraseñas no coinciden',
      password_updated_message: 'Contraseña actualiza',
      invoice_email_updated_message: 'Email actualiza',

      invoices_list: 'Lista de facturas',
      no_invoices: 'Sin factura presente',

      no_orders: 'No hay orden presente',
      order_detail: 'Detalle de la orden',
      repeat_order: 'Repite la orden',
      warning: 'Atención',
      cart_overwrite_message: 'Se sobrescribirá al carrito actual, ¿continuar?',

      date: 'Fecha',
      items: 'Elementos',
      codice: 'Código',
      quantity_short: 'Cant.',
      updated_cart: 'Carrito actualizado',

      confirm_delete_message: '¿Está seguro de que desea eliminar al usuario {user}?',
      make_user_pricipal: 'Hacer principal',
      confirm_principal_one: 'Solo puede tener un usuario principal.',
      confirm_principal_two: '¿Está seguro de que desea que el usuario {user} sea el principal?',
      confirm_principal_three: 'Su cuenta se convertirá en secundaria.',
      edit_roles: 'Editar permisos',
      roles: 'Permisos',
      user: 'Usuario',
      create_user: 'Crear nuevo usuario',
      primary: 'PRINCIPAL',

      actions: 'Acciones',
      role_orders_text: 'Pedidos',
      role_invoices_text: 'Facturas',

      roles_updated: 'Roles actualizados',
      generic_error: 'Se ha producido un error',
      upgrade_done: 'Actualización realizada',
      user_deleted: 'Usuario eliminado',
      user_created: 'Usuario creado'
    }
  },
  de: {
    profile: {
      company_edit_message: 'Das Firmenprofil wurde intern gespeichert. Um die Unternehmensregistrierung zu ändern, schreiben Sie an ',
      mail_edit_projx: 'info@apaspa.com',
      view_orders: 'Deine Bestellungen',
      view_invoices: 'Deine Rechnungen',
      download_price_sheet: 'Preisliste herunterladen',
      user_data: 'Benutzerdaten',
      manage_users: 'Konto verwalten',
      invoice_mail: 'E-Mail-Adresse zum elektronischen Rechnungsversand',

      first_name: 'Name',
      last_name: 'Vorname',
      user_email: 'E-Mail',
      password: 'Passwort',
      reset_password: 'Passwort zurücksetzen',
      old_password: 'Altes Passwort',
      new_password: 'Neues Passwort',
      confirm_password: 'Passwort bestätigen',
      password_error_message: 'Falsches Passwort',
      password_mismatch_message: 'Passwörter stimmen nicht überein',
      password_updated_message: 'Passwort aktualisiert',
      invoice_email_updated_message: 'Email aktualisiert',

      invoices_list: 'Rechnungsauflistung',
      no_invoices: 'Keine Rechnung vorhanden',

      no_orders: 'Keine Bestellung vorhanden',
      order_detail: 'Zusammenfassung Bestellung',
      repeat_order: 'Bestellung wiederholen',
      warning: 'Achtung',
      cart_overwrite_message: 'Der aktuelle Warenkorb wird überschrieben, weiter?',

      date: 'Datum',
      items: 'Elemente',
      codice: 'Artikel-Nr.',
      quantity_short: 'Menge',
      updated_cart: 'Warenkorb aktualisiert',

      confirm_delete_message: 'Möchten Sie sich als Benutzer {user} wirklich löschen?',
      make_user_pricipal: 'Hauptnutzer werden',
      confirm_principal_one: 'Sie können nur einen Hauptnutzer haben.',
      confirm_principal_two: 'Sind Sie sicher, dass Sie der Benutzer {user} Hauptnutzer werden möchten?',
      confirm_principal_three: 'Dein Konto wird Nebenutzer',
      edit_roles: 'Wechseln Berechtigungen.',
      roles: 'Berechtigungen',
      user: 'Benutzer',
      create_user: 'Neuer Nutzer erstellen',
      primary: 'HAUPTNUTZER',

      actions: 'Aktionen',
      role_orders_text: 'Bestellungen',
      role_invoices_text: 'Rechnungen',

      roles_updated: 'Nutzerstatus aktualisiert',
      generic_error: 'Es ist ein Fehler aufgetreten',
      upgrade_done: 'Upgrade abgeschlossen',
      user_deleted: 'Benutzer gelöscht',
      user_created: 'Benutzer erstellt'
    }
  },
  nl: {
    profile: {
      company_edit_message: 'Het bedrijfsprofiel is intern geregistreerd. Om de bedrijfsgegevens te wijzigen, graag schrijven naar ',
      mail_edit_projx: 'info@apaspa.com',
      view_orders: 'Bekijk bestellingen',
      view_invoices: 'Bekijk facturen',
      download_price_sheet: 'Prijstlijst downloaden',
      user_data: 'Gegevens gebruiker',
      manage_users: 'Beheer van gebruikers',
      invoice_mail: 'Email voor facturering',

      first_name: 'Naam',
      last_name: 'Achternaam',
      user_email: 'Email',
      password: 'Wachtwoord',
      reset_password: 'Wachtwoord opnieuw instellen',
      old_password: 'Wachtwoord oud',
      new_password: 'Wachtwoord nieuw',
      confirm_password: 'Bevestig wachtwoord',
      password_error_message: 'Het originele wachtwoord is niet correct',
      password_mismatch_message: 'De wachtwoorden komen niet overeen',
      password_updated_message: 'Wachtwoord bijgewerkt',
      invoice_email_updated_message: 'Email bijgewerkt',

      invoices_list: 'Lijst facturen',
      no_invoices: 'Er zijn geen facturen',

      no_orders: 'Er zijn geen bestellingen',
      order_detail: 'Details bestelling',
      repeat_order: 'Herhaal de bestelling',
      warning: 'Attentie',
      cart_overwrite_message: 'De huidige winkelwagen wordt overschreven, doorgaan?',

      date: 'Datum',
      items: 'Elementen',
      codice: 'Code',
      quantity_short: 'Aantal',
      updated_cart: 'Winkelwagen bijgewerkt',

      confirm_delete_message: 'Weet u zeker dat u de gebruiker {user} wilt verwijderen?',
      make_user_pricipal: 'Maak het de hoofdgebruiker',
      confirm_principal_one: 'U kunt slechts één hoofdgebruiker hebben.',
      confirm_principal_two: 'Weet u zeker dat u de gebruiker {user} hoofdgebruiker wilt maken?',
      confirm_principal_three: 'Uw account wordt secundair gemaakt.',
      edit_roles: 'Autorisatie bewerken',
      roles: 'Autorisatie',
      user: 'Gebruiker',
      create_user: 'Maak nieuwe gebruiker',
      primary: 'HOOFDGEBRUIKER',

      actions: 'Acties',
      role_orders_text: 'Bestellingen',
      role_invoices_text: 'Facturen',

      roles_updated: 'Rollen bijgewerkt',
      generic_error: 'Er is een fout opgetreden',
      upgrade_done: 'Upgrade voltooid',
      user_deleted: 'Gebruiker verwijderd',
      user_created: 'Gebruiker gemaakt'
    }
  }
}
