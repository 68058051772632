export default (() => {
  let map_ = null
  let node_ = null
  let i = 0

  /* eslint-disable no-undef */
  return {
    test: () => {
      console.log(i++)
    },
    getMap: (element, options) => {
      if (!map_) {
        map_ = new google.maps.Map(element, options)
        node_ = element
      } else {
        element.parentNode.replaceChild(node_, element)
      }
      return map_
    },
    getNode: () => {
      return node_
    }
  }
  /* eslint-enable no-undef */
})()
