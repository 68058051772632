<template>
  <div>
    <div
      ref="vue-map"
      v-resize="onResize"
      :style="mapStyle"
    />
    <div
      ref="currentLocationButton"
      class="mr-4 mb-4"
      style="background-color: #ffffff99; z-index: 10"
    >
      <div
        class="d-flex align-center pa-2"
        style="cursor: pointer"
        @click="panToCurrentLocationMarker"
      >
        <v-icon>
          mdi-crosshairs-gps
        </v-icon>
      </div>
    </div>
    <div
      style="position: absolute;top: 0;left: 0; width: 100%; height: 100%;pointer-events: none;"
    >
      <div
        ref="filterMenu"
        class="mt-4 ml-4"
        style="background-color: #ffffff99; z-index: 999999"
      >
        <div
          class="d-flex align-center pa-4"
          style="cursor: pointer"
          @click="$store.dispatch('map/setMapFilters', true)"
        >
          <v-icon>
            mdi-filter-variant
          </v-icon>
          <h2 class="ml-2">
            {{ $t('messages.Filtri') }}
          </h2>
        </div>
        <v-navigation-drawer
          :value="$store.getters['map/mapFilters']"
          fixed
          :mobile-breakpoint="0"
          width="320"
          color="#eff3f7"
          @input="$store.dispatch('map/setMapFilters', $event)"
        >
          <v-list
            style="margin-top: 48px; margin-bottom: 30px"
            nav
            dense
          >
            <v-list-item v-if="isInitializing != 0">
              <v-list-item-content>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="ml-3"
                />
              </v-list-item-content>
              <v-list-item-action>
                <v-icon @click="$store.dispatch('map/setMapFilters', false)">
                  mdi-close
                </v-icon>
              </v-list-item-action>
            </v-list-item>
            <div
              v-else
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title />
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon @click="$store.dispatch('map/setMapFilters', false)">
                    mdi-close
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
              <div>
                <div
                  ref="domClientSelector"
                  style="position: relative;"
                />
                <client-selector
                  v-model="selectedClientId"
                  class="persistent-placeholder"
                  :clients="filteredClientList"
                  :attach="$refs.domClientSelector"
                  :expand-address="false"
                  @input="updatedClient"
                />
              </div>
              <div v-if="selectedClientId && selectedClientIndirizzo">
                <div class="d-flex justify-center mt-2 mb-2">
                  <h1>{{ selectedClient.azienda }}</h1>
                </div>
                <div class="d-flex justify-center mb-2">
                  <h5>{{ selectedClientIndirizzo.indirizzo }}, {{ selectedClientIndirizzo.cap }} {{ selectedClientIndirizzo.citta }} ({{ selectedClientIndirizzo.provincia }})</h5>
                </div>
                <template v-if="selectedClient.is_projx === '1' && selectedClientIndirizzo.olderThanTwoYears && !selectedClientIndirizzo.isForcedOnMap">
                  <div
                    class="d-flex justify-space-around mt-2"
                  >
                    <div
                      class="d-flex flex-column align-center"
                    >
                      <h5>
                        {{ $t('messages.Il cliente non ordina da più di due anni ed è stato nascosto') }}
                      </h5><h5>
                        {{ $t('messages.Forzare la visualizzazione sulla mappa?') }}
                      </h5>
                      <h5
                        style="text-decoration: underline; color: #2559b9; cursor: pointer;"
                        @click="forceOnMap(selectedClient, true)"
                      >
                        {{ $t('messages.Attiva sulla mappa') }}
                      </h5>
                    </div>
                  </div>
                </template>
                <template v-else-if="selectedClientIndirizzo.lat || selectedClientIndirizzo.latAuto">
                  <div
                    class="d-flex justify-space-around mt-2"
                  >
                    <div
                      class="d-flex flex-column align-center"
                    >
                      <template v-if="selectedClient.is_projx === '1' && selectedClientIndirizzo.olderThanTwoYears && selectedClientIndirizzo.isForcedOnMap">
                        <h5>
                          {{ $t('messages.Il cliente non ordina da più di due anni ed è stato forzato') }}
                        </h5><h5>
                          {{ $t('messages.Nascondere la visualizzazione sulla mappa?') }}
                        </h5>
                        <h5
                          style="text-decoration: underline; color: #2559b9; cursor: pointer;"
                          @click="forceOnMap(selectedClient, false)"
                        >
                          {{ $t('messages.Nascondi dalla mappa') }}
                        </h5>
                      </template>
                      <div
                        style="cursor: pointer; width: 36px"
                        @click="panToLocation((selectedClientIndirizzo.lat ? selectedClientIndirizzo.lat : selectedClientIndirizzo.latAuto), selectedClientIndirizzo.lng ? selectedClientIndirizzo.lng : selectedClientIndirizzo.lngAuto); openInfowindow(selectedClientId)"
                      >
                        <v-btn
                          icon
                          color="primary"
                        >
                          <v-icon>
                            mdi-map-marker
                          </v-icon>
                        </v-btn>
                        <h5>{{ $t('messages.Visualizza') }}</h5>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="d-flex justify-space-around mt-2"
                  >
                    <div
                      class="d-flex flex-column align-center"
                      style="cursor: pointer; width: 36px"
                      @click="setNewMarker"
                    >
                      <v-btn
                        icon
                        color="primary"
                      >
                        <v-icon>
                          mdi-map-marker-question
                        </v-icon>
                      </v-btn>
                      <h5>{{ $t('messages.Imposta') }}</h5>
                    </div>
                  </div>
                </template>
              </div>
              <div class="d-flex justify-center mt-2">
                <div
                  v-for="filterMarker in filtersMarker"
                  :key="filterMarker.value"
                  class="d-flex flex-column align-center mx-1"
                >
                  {{ filterMarker.text }}
                  <img
                    :src="filterMarker.path"
                    alt=""
                    width="50"
                    :style="filterMarker.isActive ? 'filter: grayscale(100%);' : ''"
                    @click="filterMarker.isActive = !filterMarker.isActive; doFilterClients()"
                  >
                </div>
              </div>
              <div
                ref="domAreaSelector"
                style="position: relative;"
              />
              <area-selector
                v-model="filterArea"
                :areas="areasVisibleToAgent"
                :label="$t('messages.Filtro Area')"
                class="persistent-placeholder mt-2"
                :attach="$refs.domAreaSelector"
                @input="updateMarkers(queryByArea); filterClients()"
              />
              <div
                ref="domMicroAreaSelector"
                style="position: relative;"
              />
              <micro-area-selector
                v-model="filterMicroArea"
                :microareas="microAreasVisibleToAgent"
                :label="$t('messages.Filtro Microarea')"
                class="persistent-placeholder"
                hide-details
                :attach="$refs.domMicroAreaSelector"
                @input="updateMarkers(false); filterClients()"
              />

              <div
                ref="domDateSelector"
                class="d-flex mt-2 mb-2"
              >
                <v-menu
                  v-model="dateSettingGiroMenu"
                  :close-on-content-click="false"
                  min-width="290px"
                  top
                  :nudge-top="40"
                  :attach="$refs.domDateSelector"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(dateSettingGiro)"
                      :label="$t('messages.Giro Visita (Data)')"
                      :placeholder="$t('messages.Seleziona una data')"
                      readonly
                      v-bind="attrs"
                      style="font-size: 0.665rem; width:40px;"
                      class="persistent-placeholder"
                      hide-details
                      :clearable="!isAssigningGiro"
                      filled
                      v-on="on"
                      @click:clear="dateSettingGiro = null; searchGiro()"
                    />
                    <div class="ml-2 d-flex flex-column align-center justify-center">
                      <h4 class="mb-1">
                        {{ $t('messages.Imposta') }}
                      </h4>
                      <v-switch
                        v-model="isAssigningGiro"
                        :disabled="isAssigningGiro || !dateSettingGiro || !(dateGiriMinimumSet <= dateSettingGiro)"
                        hide-details
                        class="mt-0"
                        :color="'#ee712a'"
                        @change="assignGiro()"
                      />
                    </div>
                  </template>
                  <v-date-picker
                    v-model="dateSettingGiro"
                    :readonly="isAssigningGiro"
                    :locale="$t('messages.locale')"
                    :first-day-of-week="1"
                    :events="giriEsistenti"
                    :min="dateGiriMinimumView"
                    event-color="#ee712a"
                    @input="dateSettingGiroMenu = false; searchGiro();"
                  />
                </v-menu>
              </div>
              <div
                class="d-flex justify-end"
              >
                <v-btn
                  v-if="isAssigningGiro"
                  small
                  dark
                  tile
                  elevation="0"
                  color="#C4C2BB"
                  class="mr-2"
                  :disabled="isSavingGiro"
                  @click="isAssigningGiro = false; assignGiro()"
                >
                  {{ $t('messages.Annulla') }}
                </v-btn>
                <v-btn
                  v-if="isAssigningGiro"
                  small
                  dark
                  tile
                  elevation="0"
                  color="primary"
                  :loading="isSavingGiro"
                  @click="savingGiro()"
                >
                  {{ $t('messages.Salva') }}
                </v-btn>
              </div>
              <div
                v-if="!!dateSettingGiro && !isLoadingGiro"
                class="mt-4"
              >
                <div
                  v-for="(client, i) in sortedClientiGiroList"
                  :key="i"
                  class="d-flex mt-1"
                >
                  <span style="margin-top:2px; max-width:230px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{ client.azienda }}
                  </span>
                  <v-spacer />
                  <v-icon
                    small
                    color="primary"
                    @click="panToLocation((client.lat ? parseFloat(client.lat) : parseFloat(client.latAuto)), client.lng ? parseFloat(client.lng) : parseFloat(client.lngAuto)); openInfowindow(client)"
                  >
                    mdi-crosshairs-gps
                  </v-icon>
                  <v-icon
                    v-if="isAssigningGiro"
                    small
                    :disabled="!isAssigningGiro"
                    color="error"
                    class="ml-2"
                    @click="removeFromGiro(client)"
                  >
                    mdi-close-circle-outline
                  </v-icon>
                  <v-icon
                    v-if="!isAssigningGiro"
                    small
                    :color="client.visited ? 'green' : '#E0E0E0'"
                    class="ml-2"
                    @click="setVisited(client)"
                  >
                    mdi-check-bold
                  </v-icon>
                </div>
              </div>
            </div>
          </v-list>
        </v-navigation-drawer>
      </div>
    </div>
    <info-window-vue
      v-if="infoclient && infoclient.infoOpen"
      v-model="infoclient.infoOpen"
      :map="map"
      :client="infoclient"
      :filter-giro="!!dateSettingGiro && !isLoadingGiro && !isAssigningGiro"
      @input="closeInfoWindow"
      @visited="setVisited"
    />
    <info-window-assign
      v-if="markerNewAssign"
      v-model="markerNewAssign.infoOpen"
      :map="map"
      :client="markerNewAssign"
      @save="saveNewMarker"
    />
    <v-dialog
      v-model="dialogAreaSelector"
      content-class="my-custom-dialog"
      max-width="290"
      persistent
    >
      <v-card>
        <v-card-text class="pt-4 justify-center">
          <area-selector
            v-model="filterArea"
            :areas="areasVisibleToAgent"
            :label="$t('messages.Area')"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            tile
            elevation="0"
            @click="confirmAreaSelector"
          >
            {{ $t('messages.Conferma') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import infoWindowVue from '../maps/info-window.vue'
import infoWindowAssign from '../maps/info-window-assign.vue'

import loadGmapApi from '../maps/initializer'
import mapCacher from '../maps/map-cacher'
import lazyLoader from '../maps/lazy-loader'
import MapLabel from '../maps/utils/map-label'
import ClientSelector from '../components/ClientSelector.vue'
import AreaSelector from '../components/AreaSelector.vue'
import MicroAreaSelector from '../components/MicroAreaSelector.vue'

export default {
  name: 'ClientsMap',

  components: {
    infoWindowVue,
    infoWindowAssign,
    ClientSelector,
    AreaSelector,
    MicroAreaSelector
  },

  data: () => ({
    map: null,
    mapStyle: '',
    dragDebounce: null,
    currentLocationMarker: null,
    clients: [],
    group: false,
    currentCenter: { lat: 0, lng: 0 },
    selectedClientId: '',
    selectedClientIndirizzo: null,
    mapListeners: [],
    markerNewAssign: null,
    domClientSelector: false,
    infoclient: null,
    cachedCloseClients: [],
    filterArea: '',
    filterMicroArea: '',
    isInitializing: 1,
    defaultCenter: null, // default center viene preso dai queryparam
    queryByArea: true,
    // selectedArea: '', // area selezionata per la query clienti
    dialogAreaSelector: false,
    filteredClientList: [],
    dateSettingGiro: null,
    dateSettingGiroMenu: false,
    clientsSettingGiro: [],
    clientsGiro: [],
    isAssigningGiro: false,
    isSavingGiro: false,
    sortedClientiGiroList: [],
    idWatchPosition: null,
    firstFix: true,
    settingVisited: {} // controlla che non invio due volte lo stesso setting cliente
  }),
  computed: {
    areasVisibleToAgent () {
      return this.$store.getters['agents/areasVisibleToAgent']
    },
    clientsListMinimal () {
      return this.$store.getters['clients/clientsListMinimal']
    },
    globalMovingMarker () {
      return this.$store.getters['map/movingMarker']
    },
    selectedClient () {
      return this.selectedClientId ? this.clientsListMinimal.find((c) => c.id === this.selectedClientId) : ''
    },
    microAreasVisibleToAgent () {
      return this.$store.getters['agents/microAreasVisibleToAgent']
    },
    giriEsistenti () {
      return this.$store.getters['giro/list']
    },
    errorMessage () {
      return this.$store.getters['giro/errorMessage']
    },
    isLoadingGiro () {
      return this.$store.getters['giro/isLoading']
    },
    dateGiriMinimumView () { // data minima per visualizzare un giro (posso vedere un giro passato)
      const d = new Date()
      d.setFullYear(d.getFullYear() - 1)
      const year = d.getFullYear()
      const month = ('0' + (d.getMonth() + 1)).slice(-2)
      const day = ('0' + d.getDate()).slice(-2)
      const time = year + '-' + month + '-' + day
      return time
    },
    dateGiriMinimumSet () { // data minima per impostare un giro (posso impostare il giro da oggi in poi)
      const d = new Date()
      const year = d.getFullYear()
      const month = ('0' + (d.getMonth() + 1)).slice(-2)
      const day = ('0' + d.getDate()).slice(-2)
      const time = year + '-' + month + '-' + day
      return time
    },
    filtersMarker () {
      return [
        {
          text: '0-30 ' + this.$t('messages.gg'),
          value: 'Verde',
          path: '/markers/markerVerde0.svg',
          isActive: false
        },
        {
          text: '+30 ' + this.$t('messages.gg'),
          value: 'Giallo',
          path: '/markers/markerGiallo0.svg',
          isActive: false
        },
        {
          text: '+90 ' + this.$t('messages.gg'),
          value: 'Rosso',
          path: '/markers/markerRosso0.svg',
          isActive: false
        },
        {
          text: '+180 ' + this.$t('messages.gg'),
          value: 'Grigio',
          path: '/markers/markerGrigio0.svg',
          isActive: false
        },
        {
          text: 'Prosp.',
          value: 'isProspector',
          path: '/markers/markerBluAPA0.svg',
          isActive: false
        }
      ]
    }
  },

  watch: {
    selectedClient (newVal) {
      if (newVal) {
        this.getClientAddress()
      } else {
        this.selectedClientIndirizzo = null
      }
    }
  },

  beforeDestroy () {
    navigator.geolocation.clearWatch(this.idWatchPosition)
    /* eslint-disable no-undef */
    this.map.controls[google.maps.ControlPosition.LEFT_TOP].pop()
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].pop()
    // unmount markers
    this.clients.forEach(client => {
      client.marker.setMap()
      client.marker = null
      client.label.setMap()
      client.label = null
    })
    this.mapListeners.forEach(l => {
      google.maps.event.removeListener(l)
    })
    /* eslint-enable no-undef */
  },

  async created () {
    // this.isInitializing = 1
    // this.$store.dispatch('clients/clientsListMinimal').then(() => this.isInitializing--)

    // this.giriEsistenti = [...Array(6)].map(() => {
    //   const day = Math.floor(Math.random() * 30)
    //   const d = new Date()
    //   d.setDate(day)
    //   return d.toISOString().substr(0, 10)
    // })

    if (this.$route.query.clientId) {
      this.selectedClientId = this.$route.query.clientId
    }
    if (this.$route.query.clientId && !(this.$route.query.lat && this.$route.query.lng)) { // se ho il clientId ma non ho lat e lng -> imposta
      this.$store.dispatch('map/setMapFilters', true)
    }
    if (this.$route.query.lat && this.$route.query.lng) {
      this.defaultCenter = {
        lat: this.$route.query.lat,
        lng: this.$route.query.lng
      }
    }
    this.isInitializing = 3
    this.$store.dispatch('agents/microAreasVisibleToAgent').then(() => { this.isInitializing--; this.initialize() })
    this.$store.dispatch('clients/clientsListMinimal').then((cl) => {
      this.filteredClientList = cl
      this.isInitializing--
      this.initialize()
    })
    this.$store.dispatch('agents/areasVisibleToAgent').then(() => { this.isInitializing--; this.initialize() })
  },
  mounted () {
    try {
      this.domClientSelector = this.$refs.domClientSelector
      /* eslint-disable no-undef */
      const filterMenu = this.$refs.filterMenu
      filterMenu.parentNode.removeChild(filterMenu)
      const currentLocationButton = this.$refs.currentLocationButton
      currentLocationButton.parentNode.removeChild(currentLocationButton)
      const lazy = lazyLoader(loadGmapApi)
      lazy().then(() => {
        const element = this.$refs['vue-map']
        const zoomLevel = 14
        let center = new google.maps.LatLng(45.543041, 9.4021117)
        if (this.defaultCenter) {
          center = new google.maps.LatLng(this.defaultCenter.lat, this.defaultCenter.lng)
        }
        const options = {
          center: center,
          zoom: zoomLevel,
          mapTypeId: 'roadmap',
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          gestureHandling: 'greedy'
        }
        // mapChacher è un componente che mi fornisce la mappa
        // se è la prima volta che lo chiamo mi crea la mappa
        // altrimenti mi restituisce quella già creata
        this.map = mapCacher.getMap(element, options)
        if (this.defaultCenter) {
          this.map.setCenter(center)
        }
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(filterMenu)
        this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(currentLocationButton)

        // CENTER MAP ON CURRENT LOCATION
        const self = this
        this.currentCenter = {
          lat: this.map.getCenter().lat(),
          lng: this.map.getCenter().lng()
        }
        const listenerDragStart = self.map.addListener('dragstart', self.onMapDragStart)
        self.mapListeners.push(listenerDragStart)
        const listenerDragEnd = self.map.addListener('dragend', self.onMapDrag)
        self.mapListeners.push(listenerDragEnd)
        // navigator.geolocation = richiesta posizione gps del dispositivo SOLO HTTPS
        // todo continuos navigator
        if (navigator.geolocation) {
          self.idWatchPosition = navigator.geolocation.watchPosition(self.updateCurrentLocation, self.errorUpdateCurrentLocation, { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 })
        } else {
          // Browser doesn't support Geolocation
          const dataMessage = { isOpen: true, message: this.$t('messages.Il browser non supporta le funzioni di geolocalizzazione'), color: 'success' }
          self.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        }

        /*
        var contentString =
                    '<div class="iw-container">' +
                        // '<span title="Dettagli cliente" class="'+ (isProvisional? "iw-customer-details-green" : "iw-customer-details-blue") +'"><a href="/multi/clients/details/'+ taxCode + '/' + businessName + '"><i class="material-icons">notes</i></a></span>'+
                        '<span>xxx</span>' +
                    '</div>'
        var infowindow = new google.maps.InfoWindow({
          content: contentString
        }) */

        // infoWindows.push(infowindow)
        // var GeoMarker = new GeolocationMarker(currentMap)
        // Marker posizione attuale in aggiornamento continuo
        // var GeoMarker = new GeolocationMarker(currentMap)
        if (!self.queryByArea) {
          self.updateMarkers()
        }
      })
    } catch (error) {
      console.log(error)
    }
    /* eslint-enable no-undef */
  },

  methods: {
    initialize () {
      if (this.isInitializing === 0) {
        // popup select map
        if (this.$route.query.area && this.queryByArea) {
          this.filterArea = this.$route.query.area
          const donotpan = this.$route.query.lat && this.$route.query.lng
          this.updateMarkers(true, donotpan)
          this.filterClients()
        } else if (this.queryByArea) {
          this.dialogAreaSelector = true
        }
      }
    },
    confirmAreaSelector () {
      // popup select map
      if (this.filterArea) {
        const query = Object.assign({}, this.$route.query)
        // delete query.article
        query.area = this.filterArea
        this.$router.replace({ query }).catch(() => {})
        this.dialogAreaSelector = false
        this.updateMarkers()
        this.filterClients()
      }
    },
    onResize () {
      this.mapStyle = 'height:' + (window.innerHeight - 48) + 'px;'
    },
    coordinatesDistance (lat1, lon1, lat2, lon2) {
      const R = 6371 // km (change this constant to get miles)
      const dLat = (lat2 - lat1) * Math.PI / 180
      const dLon = (lon2 - lon1) * Math.PI / 180
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const d = R * c
      return Math.round(d)
      /* if (d > 1) return Math.round(d) + 'km'
      else if (d <= 1) return Math.round(d * 1000) + 'm'
      return d */
    },
    onMapDragStart () {
      clearTimeout(this.dragDebounce)
    },
    onMapDrag () {
      // const currentZoom = this.map.getZoom()
      clearTimeout(this.dragDebounce)
      if (!this.queryByArea) { // se non faccio query per area allora faccio sul closeby aggiornato via drag
        this.dragDebounce = setTimeout(() => {
          if (!this.globalMovingMarker) { // se non sto muovendo un marker allora posso aggiornare i clienti
            const currentLat = this.map.getCenter().lat()
            const currentLng = this.map.getCenter().lng()
            const distance = this.coordinatesDistance(this.currentCenter.lat, this.currentCenter.lng, currentLat, currentLng)

            // se distanza superiore a 40km, aggiorno i marker
            if (distance > 40) {
              this.currentCenter = {
                lat: currentLat,
                lng: currentLng
              }
              this.updateMarkers()
            }
          }
        }, 1000)
      }
    },
    handleLocationError (browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos)
      infoWindow.setContent(browserHasGeolocation
        ? this.$t('messages.Errore: Richiesta di geolocalizzazione fallitap')
        : this.$t('messages.Errore: Il browser non supporta le funzioni di geolocalizzazionep'))
      infoWindow.open(this.map)
    },
    async updateMarkers (requery = true, donotpan = false) {
      if (requery) {
        if (!this.queryByArea) {
          this.cachedCloseClients = await this.$store.dispatch('map/getCloseClients', this.currentCenter)
        } else {
          if (!this.filterArea) { return }

          // upate query
          const query = Object.assign({}, this.$route.query)
          query.area = this.filterArea
          this.$router.replace({ query }).catch(() => {})

          // update calendar
          this.$store.dispatch('giro/list', this.filterArea)

          // upate client map
          this.cachedCloseClients = await this.$store.dispatch('map/getClientsByArea', this.filterArea)
          if (this.cachedCloseClients.length > 0 && !donotpan) {
            const firstClient = this.cachedCloseClients.find(client => client.lat)
            if (firstClient) {
              this.panToLocation(parseFloat(firstClient.lat), parseFloat(firstClient.lng))
            }
          }
        }
      }
      let closeClients = []
      // Se non sto assegnando il giro, ma ho selezionato una data, devo filtrare i clienti del giro
      if (!this.isAssigningGiro && this.dateSettingGiro) {
        closeClients = this.cachedCloseClients.filter(cachedClient => {
          return this.clientsGiro.some(giro => giro.clientId === cachedClient.id)
        })
      } else {
        closeClients = this.cachedCloseClients
      }
      // console.log('update markers')
      // console.log('this.clientsGiro: ' + this.clientsGiro)
      /* eslint-disable no-undef */

      this.clients.forEach(client => {
        client.marker.setMap()
        client.marker = null
        client.label.setMap()
        client.label = null
      })
      this.clients = []

      for (let index = 0; index < closeClients.length; index++) {
        const client = closeClients[index]
        const isProspector = client.is_projx !== '1'

        let color = 'Grigio'
        if (isProspector) {
          color = 'BluAPA'
        } else if (client.last_visit !== null) {
          const lastVisitDate = new Date(client.last_visit)
          const today = new Date()
          const daysDifference = Math.round((today - lastVisitDate) / (1000 * 60 * 60 * 24))

          if (daysDifference <= 30) {
            color = 'Verde'
          } else if (daysDifference <= 90) {
            color = 'Giallo'
          } else if (daysDifference <= 180) {
            color = 'Rosso'
          }
        }

        // doFilter
        if (client.is_projx === '1') {
          const filterMarker = this.filtersMarker.find(fm => fm.value === color)
          if (filterMarker.isActive) {
            continue
          }
        } else {
          const filterMarker = this.filtersMarker.find(fm => fm.value === 'isProspector')
          if (isProspector && filterMarker.isActive) {
            continue
          }
        }
        if (this.filterArea) {
          if (client.cd_agent !== this.filterArea) {
            continue
          }
        }
        if (this.filterMicroArea) {
          if (client.microarea !== this.filterMicroArea) {
            continue
          }
        }

        const currentClient = {}
        Object.assign(currentClient, client)

        let iconUrl = '/markers/marker#color##rating#.svg'

        iconUrl = iconUrl.replace('#color#', color)
        iconUrl = iconUrl.replace('#rating#', client.stars)

        // se sto assegnando il giro e il cliente è uno di quelli presente nel giro il marker deve essere sovrascritto
        let icon = null
        if (this.isAssigningGiro && this.clientsGiro.some(giro => giro.clientId === currentClient.id)) {
          const selectedIconUrl = '/markers/markerSelected.svg'
          const originalIcon = {
            url: iconUrl,
            scaledSize: new google.maps.Size(40, 40),
            labelOrigin: { x: 20, y: -20 }
          }
          icon = {
            url: selectedIconUrl,
            scaledSize: new google.maps.Size(40, 40),
            labelOrigin: { x: 20, y: -20 }
          }
          currentClient.originalIcon = originalIcon

          this.clientsSettingGiro.push(currentClient)
        } else {
          // se non sto assegnando il giro, ma sto filtrando per giro e il cliente è visitato, deve avere un'altra icona
          if (this.dateSettingGiro) {
            const isVisited = this.clientsGiro.find(giro => giro.clientId === currentClient.id)?.visited ?? false
            if (isVisited) {
              iconUrl = '/markers/markerVisited.svg'
              currentClient.visited = true
            }
            /* this.cachedCloseClients.filter(cachedClient => {
              return this.clientsGiro.some(giro => giro.clientId === cachedClient.id)
            }) */
          }

          icon = {
            url: iconUrl,
            scaledSize: new google.maps.Size(40, 40),
            labelOrigin: { x: 20, y: -20 }
          }
        }

        currentClient.marker = new google.maps.Marker({
          position: { lat: parseFloat(client.lat), lng: parseFloat(client.lng) },
          map: this.map,
          optimized: false,
          icon: icon
        })

        currentClient.label = new MapLabel({
          position: currentClient.marker.position,
          map: this.map,
          align: 'center',
          fontColor: '#404040',
          fontSize: 18,
          minZoom: 14,
          text: client.azienda,
          // text: client.azienda.startsWith('PROJXAPA ') ? client.azienda.slice(9) : client.azienda,
          strokeWeight: 4
        })
        currentClient.marker.addListener('click', () => {
          this.markerClick(currentClient)
        })
        currentClient.marker.addListener('dragstart', () => {
          currentClient.label.setMap()
        })
        currentClient.marker.addListener('dragend', () => {
          currentClient.label.position = currentClient.marker.position
          currentClient.label.setMap(this.map)
        })

        const query = Object.assign({}, this.$route.query)
        if (query.clientId === currentClient.id && !this.isAssigningGiro) {
          this.openInfowindow(currentClient)
        } else {
          currentClient.infoOpen = false
        }
        this.clients.push(currentClient)
        /* eslint-enable no-undef */
      }
      this.sortedClientiGiroList = (this.isAssigningGiro ? this.clientsSettingGiro.sort((c1, c2) => c1.azienda < c2.azienda) : this.clients.sort((c1, c2) => c1.azienda < c2.azienda))
    },
    updateCurrentLocation (position) {
      /* eslint-disable no-undef */
      const pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }

      if (this.firstFix) {
        const currentMap = this.map
        this.firstFix = false
        if (!this.defaultCenter && !this.queryByArea) {
          currentMap.setCenter(pos)
        }

        this.currentCenter = {
          lat: this.map.getCenter().lat(),
          lng: this.map.getCenter().lng()
        }

        this.currentLocationMarker = new google.maps.Marker({
          position: pos,
          mainMarker: true,
          map: this.map,
          icon: {
            url: '/markers/currentLocation.svg',
            scaledSize: new google.maps.Size(14, 14),
            labelOrigin: { x: 7, y: -7 }
          }
        })
        if (!this.queryByArea) {
          this.updateMarkers()
        }
        return
      }

      if (this.currentLocationMarker) {
        this.currentLocationMarker.setPosition(pos)
      }
      /* eslint-enable no-undef */
    },
    errorUpdateCurrentLocation (err) {
      console.warn('ERROR(' + err.code + '): ' + err.message)
    },
    addClientToSelectedGiro (client) {
      /* eslint-disable no-undef */
      const iconUrl = '/markers/markerSelected.svg'
      const originalIcon = client.marker.icon
      const selectedIcon = {
        url: iconUrl,
        scaledSize: new google.maps.Size(40, 40),
        labelOrigin: { x: 20, y: -20 }
      }
      client.marker.setIcon(selectedIcon)
      client.originalIcon = originalIcon
      this.clientsSettingGiro.push(client)
      /* eslint-enable no-undef */
    },
    markerClick (currentClient) {
      this.openInfowindow(currentClient)
      if (this.isAssigningGiro) {
        const index = this.clientsSettingGiro.findIndex(c => c.id === currentClient.id)
        if (index >= 0) {
          this.clientsSettingGiro[index].marker.setIcon(this.clientsSettingGiro[index].originalIcon)
          this.clientsSettingGiro.splice(index, 1)
        } else {
          this.addClientToSelectedGiro(currentClient)
        }
      }
    },
    openInfowindow (currentClient) {
      if (typeof currentClient === 'string' || currentClient instanceof String) {
        currentClient = this.clients.find(c => c.id === currentClient)
        if (!currentClient) return
      }
      this.infoclient = null
      setTimeout(() => {
        const query = Object.assign({}, this.$route.query)
        // delete query.article
        query.clientId = currentClient.id
        this.$router.replace({ query }).catch(() => {})
        currentClient.infoOpen = true
        this.infoclient = currentClient
      }, 100)
    },
    removeFromGiro (client) {
      if (this.isAssigningGiro) {
        const index = this.clientsSettingGiro.findIndex(c => c.id === client.id)
        if (index >= 0) {
          this.clientsSettingGiro[index].marker.setIcon(this.clientsSettingGiro[index].originalIcon)
          this.clientsSettingGiro.splice(index, 1)
        }
      }
    },
    async getClientAddress () {
      this.selectedClientIndirizzo = null
      this.selectedClientIndirizzo = await this.$store.dispatch('map/getClientAddress', { clientId: this.selectedClientId })
    },
    panToCurrentLocationMarker () {
      if (this.currentLocationMarker) {
        this.panToLocation(this.currentLocationMarker.position.lat(), this.currentLocationMarker.position.lng())
      }
    },
    panToLocation (lat, lng) {
      // const lat = this.selectedClientIndirizzo.lat ? this.selectedClientIndirizzo.lat : this.selectedClientIndirizzo.latAuto
      // const lng = this.selectedClientIndirizzo.lng ? this.selectedClientIndirizzo.lng : this.selectedClientIndirizzo.lngAuto
      this.map.panTo({ lat: lat, lng: lng })
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$store.dispatch('map/setMapFilters', false)
      }
      this.onMapDrag()
    },
    setNewMarker () {
      /* eslint-disable no-undef */
      if (!this.globalMovingMarker) {
        this.$store.dispatch('map/setMovingMarker', true)
        // se sono in mobile chiudo il menu filtri
        if (this.$vuetify.breakpoint.smAndDown) {
          this.$store.dispatch('map/setMapFilters', false)
        }
        this.markerNewAssign = {}
        Object.assign(this.markerNewAssign, this.selectedClient)
        Object.assign(this.markerNewAssign, this.selectedClientIndirizzo)
        this.markerNewAssign.id = this.selectedClient.id
        this.markerNewAssign.indirizzo_id = this.selectedClientIndirizzo.id
        const iconUrl = '/markers/markerAssign.svg'

        this.markerNewAssign.marker = new google.maps.Marker({
          position: {
            lat: this.map.getCenter().lat(),
            lng: this.map.getCenter().lng()
          },
          map: this.map,
          icon: {
            url: iconUrl,
            scaledSize: new google.maps.Size(40, 40),
            labelOrigin: { x: 20, y: -20 }
          }
        })
        this.markerNewAssign.label = new MapLabel({
          position: this.markerNewAssign.marker.position,
          map: this.map,
          align: 'center',
          fontColor: '#404040',
          fontSize: 18,
          minZoom: 14,
          text: this.selectedClient.azienda,
          // text: this.selectedClient.azienda.startsWith('PROJXAPA ') ? this.selectedClient.azienda.slice(9) : this.selectedClient.azienda,
          strokeWeight: 4
        })
        this.markerNewAssign.marker.setDraggable(true)
        this.markerNewAssign.infoOpen = true

        /* eslint-enable no-undef */

        this.markerNewAssign.marker.addListener('dragstart', () => {
          this.markerNewAssign.label.setMap()
        })
        this.markerNewAssign.marker.addListener('dragend', () => {
          this.markerNewAssign.label.position = this.markerNewAssign.marker.position
          this.markerNewAssign.label.setMap(this.map)
        })
      } else {
        const dataMessage = { isOpen: true, message: this.$t('messages.Stai già impostando una location Salvare o impostare l operazione corrente prima di continuare'), color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
    },
    saveNewMarker (isSaved) {
      // se è salvato isSaved = true
      this.$store.dispatch('map/setMovingMarker', false)
      this.markerNewAssign.marker.setMap()
      this.markerNewAssign.label.setMap()
      this.markerNewAssign.marker = null
      this.markerNewAssign.label = null
      if (isSaved) {
        this.currentCenter = {
          lat: this.map.getCenter().lat(),
          lng: this.map.getCenter().lng()
        }
        this.selectedClientId = ''
        this.selectedClientIndirizzo = null
        this.updateMarkers()
      }
      this.markerNewAssign = null
    },
    doFilterClients () {
      this.updateMarkers(false) // update without requery
    },
    updatedClient () {
      if (!this.filterArea) {
        this.filterArea = this.filteredClientList.find(client => client.id === this.selectedClientId).cd_agent
        this.updateMarkers(this.queryByArea)
      }
    },
    closeInfoWindow () {
      const query = Object.assign({}, this.$route.query)
      if (query && query.clientId) {
        delete query.clientId
        this.$router.replace({ query }).catch(() => {})
      }
    },

    formatDate (date) {
      if (date === null) return ''
      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    },

    async searchGiro () {
      if (this.filterArea && this.dateSettingGiro) {
        this.clientsGiro = await this.$store.dispatch('giro/details', {
          area: this.filterArea,
          date: this.dateSettingGiro
        })
      }
      this.filterClients()
      this.updateMarkers(false)
      // this.sortedClientiGiroList = (this.isAssigningGiro ? this.clientsSettingGiro.sort((c1, c2) => c1.azienda < c2.azienda) : this.clients.sort((c1, c2) => c1.azienda < c2.azienda))
      // this.filteredClientList = this.selectedGiro
      // this.filteredClientList = this.selectedGiro.filter(clients => this.selectedGiro ? clients.id === this.selectedGiro.client_id : true)
    },

    filterClients () {
      this.filteredClientList = this.clientsListMinimal.filter(client => {
        let isVisible = true
        if (this.filterArea) {
          isVisible = client.cd_agent === this.filterArea
        }
        if (isVisible && this.filterMicroArea) {
          isVisible = client.microarea === this.filterMicroArea
        }
        if (isVisible && this.dateSettingGiro && !this.isAssigningGiro) {
          isVisible = this.clientsGiro.some(giro => giro.clientId === client.id)
        }
        return isVisible
      })
    },

    assignGiro () {
      if (!this.isAssigningGiro) {
        this.clientsSettingGiro.forEach(cl => {
          cl.marker.setIcon(cl.originalIcon)
        })
        this.clientsSettingGiro = []
      }
      this.updateMarkers(false)
      this.filterClients()
    },

    async setVisited (client) {
      if (this.settingVisited[client.id]) {
        return
      }
      this.settingVisited[client.id] = true
      const result = await this.$store.dispatch('giro/setVisited', { area: this.filterArea, date: this.dateSettingGiro, clientId: client.id, visited: !client.visited })
      if (result !== null) {
        const cg = this.clientsGiro.find(c => c.clientId === client.id)
        cg.visited = !client.visited
        this.updateMarkers(false)
        this.settingVisited[client.id] = false
      } else {
        const message = this.errorMessage
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        this.settingVisited[client.id] = false
      }
    },

    async savingGiro () {
      let errorMessages = ''
      if (!this.filterArea) {
        errorMessages += this.$t('messages.Selezionare un Area') + '\n'
      }
      if (!this.dateSettingGiro) {
        errorMessages += this.$t('messages.Selezionare una data') + '\n'
      }
      if (!this.clientsSettingGiro.length) {
        errorMessages += this.$t('messages.Selezionare almeno un Cliente') + '\n'
      }
      if (errorMessages !== '') {
        const dataMessage = { isOpen: true, message: errorMessages, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        this.isLoading = false
      }
      this.isSavingGiro = true

      // conservo proprietà visited
      const newClientsGiro = this.clientsSettingGiro.map(c => { return { clientId: c.id, visited: false } })
      newClientsGiro.forEach(cg => {
        const preExisting = this.clientsGiro.find(c => c.clientId === cg.clientId)
        if (preExisting) {
          cg.visited = preExisting.visited
        }
      })
      this.clientsGiro = newClientsGiro

      const result = await this.$store.dispatch('giro/save', { area: this.filterArea, date: this.dateSettingGiro, clients: this.clientsGiro })
      if (result !== null) {
        const message = this.$t('messages.Giro salvato')
        const dataMessage = { isOpen: true, message: message, color: 'success' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        this.isAssigningGiro = false
        this.assignGiro()
      } else {
        const message = this.errorMessage
        const dataMessage = { isOpen: true, message: message, color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
      this.isSavingGiro = false
    },

    async forceOnMap (client, forced) {
      const responseClient = await this.$store.dispatch('clients/setForcedToMap', { isForced: forced, clientId: client.id })
      // this.cachedCloseClients this.filterArea
      if (responseClient) {
        if (forced && client.cd_agent === this.filterArea) {
          this.cachedCloseClients.push(responseClient)
          this.selectedClientIndirizzo.lat = parseFloat(responseClient.lat)
          this.selectedClientIndirizzo.lng = parseFloat(responseClient.lng)
          this.updateMarkers(false)
        } else if (!forced) {
          const cachedClientIndex = this.cachedCloseClients.findIndex(c => c.id === responseClient.id)
          const clientIndex = this.clients.findIndex(c => c.id === responseClient.id)
          this.clients[clientIndex].marker.setMap()
          this.clients[clientIndex].marker = null
          this.clients[clientIndex].label.setMap()
          this.clients[clientIndex].label = null
          this.clients.splice(clientIndex, 1)
          this.cachedCloseClients.splice(cachedClientIndex, 1)
        }
        this.selectedClientIndirizzo.isForcedOnMap = forced
      }
    }
  }

}
</script>

<style scoped>
>>>.v-text-field__details {
  margin-bottom: 0px !important;
  min-height: 0px;
  height: 0px !important;
}

>>> .my-custom-dialog {
  align-self: flex-start;
  margin-top: 60px;
}
</style>
