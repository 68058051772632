<template>
  <v-dialog
    v-model="open"
    fullscreen
  >
    <v-card>
      <v-container>
        <div class="d-flex justify-end ">
          <v-btn
            icon
            color="#C4C2BB"
            style="margin-top: -36px;top: 36px;position: relative;"
            @click="cancelDialog()"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-card-title class="headline d-flex justify-center">
          {{ $t('messages.Sconti Particolari #Azienda#', [client.azienda]) }}
        </v-card-title>
        <v-card-text>
          <div
            v-if="loadingDiscounts"
            class="d-flex justify-center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              :size="70"
              class="mt-3 ml-3"
            />
          </div>
          <div v-else-if="clientDiscounts!=null">
            <div class="d-flex flex-column align-end">
              <h5>{{ client.cd_clien }} - {{ client.azienda }}</h5>
              <h6>{{ client.indirizzo }} - {{ client.cap }} - {{ client.citta }}</h6>
            </div>
            <div
              class="d-flex justify-center mt-8"
            >
              <h5>{{ $t('messages.Sconti') }}</h5>
            </div>

            <div
              style="overflow-x: auto; overflow-y: hidden;"
              class="mx-1"
            >
              <table
                style="max-width:630px;margin-left:auto;margin-right:auto;"
              >
                <thead>
                  <tr>
                    <th
                      style="min-width: 200px; font-weight: normal; text-align: center;"
                      colspan="2"
                    >
                      {{ $t('messages.CodpClasse') }}
                    </th>
                    <!-- <th style="min-width: 50px; font-weight: normal; text-align: center;">
                      {{ $t('messages.Altp') }}
                    </th> -->
                    <th style="min-width: 50px; font-weight: normal; text-align: center;">
                      {{ $t('messages.Lunp') }}
                    </th>
                    <th style="min-width: 50px; font-weight: normal; text-align: center;">
                      {{ $t('messages.Listini') }}
                    </th>
                    <th style="min-width: 70px; font-weight: normal; text-align: center;">
                      {{ $t('messages.Sconti') }}
                    </th>
                    <!-- <th style="min-width: 40px; font-weight: normal; text-align: center;" />
                    <th
                      style="min-width: 200px; font-weight: normal; text-align: center;"
                      colspan="2"
                    >
                      {{ $t('messages.ValiditaDaA') }}
                    </th>
                    <th style="min-width: 70px; font-weight: normal; text-align: center;">
                      {{ 'Provv.' }}
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(item, k) in clientDiscounts"
                  >
                    <tr
                      :key="k"
                    >
                      <!-- <td style="font-weight: bold; text-align: left;">
                        {{ convertSequence(item.sequenza_class) }}
                      </td> -->
                      <td
                        style="font-weight: bold; text-align: left;"
                        colspan="2"
                      >
                        {{ item.cd_codice_class }}
                      </td>
                      <!-- <td style="font-weight: bold; text-align: center;">
                        {{ item.altezza }}
                      </td> -->
                      <td style="font-weight: bold; text-align: center;">
                        {{ item.lunghezza }}
                      </td>
                      <td style="font-weight: bold; text-align: center;">
                        {{ item.cd_listi }}
                      </td>
                      <td style="font-weight: bold; text-align: center;">
                        {{ formatPercentuale(item.sconto) }}
                      </td>
                      <!-- <td style="font-weight: bold; text-align: center;" />
                      <td style="font-weight: bold; text-align: center;">
                        {{ formatDate(item.data_inizio_valid) }}
                      </td>
                      <td style="font-weight: bold; text-align: center;">
                        {{ formatDate(item.data_fine_valid) }}
                      </td>
                      <td style="font-weight: bold; text-align: center;">
                        {{ formatPercentuale(item.provvig) }}
                      </td> -->
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-center"
          >
            <div class="d-flex justify-center">
              <h3>Nessuno sconto trovato</h3>
            </div>
          </div>
        </v-card-text>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'

export default {
  name: 'ViewClientDiscounts',
  model: {
    prop: 'isOpen',
    event: 'change'
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    client: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    open: false,
    clientDiscounts: null,
    loadingDiscounts: false
  }),

  computed: {
    headers () {
      return [
        { text: this.$t('messages.Scadenza'), value: 'Data', filterable: true, width: '110px' },
        { text: this.$t('messages.Fattura'), value: 'Oggetto', filterable: true, width: '110px' },
        { text: this.$t('messages.Data Fatp'), value: 'Data_Ogg', filterable: true, width: '110px' },
        { text: this.$t('messages.Importo'), value: 'Importo', filterable: true, width: '110px' },
        { text: this.$t('messages.Ritarp'), value: 'Ritardo', filterable: true, width: '80px' },
        { text: this.$t('messages.Stato'), value: 'Status1', filterable: true, width: '80px' }
      ]
    }
  },

  watch: {
    open (newVal) {
      if (newVal !== this.isOpen) {
        this.$emit('change', newVal)
      }
    },
    isOpen (newVal) {
      this.open = newVal
      if (this.open) {
        this.loadDiscounts()
      }
    }
  },

  methods: {
    cancelDialog () {
      this.open = false
    },
    async loadDiscounts () {
      if (this.clientDiscounts === null) {
        if (this.loadingDiscounts) {
          return
        }
        this.loadingDiscounts = true
        try {
          const r = await axios.get('/api/clients/discounts/' + this.client.cd_clien)
          this.clientDiscounts = r.data
        } catch (error) {
          const message = error.response.data.detail
          const dataMessage = { isOpen: true, message: message, color: 'error' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        }
        this.loadingDiscounts = false
      }
    },
    convertSequence (seq) {
      switch (seq) {
      case '100':
        return 'Prodotto'
      case '1':
        return 'Categoria'
      case '2':
        return 'Famiglia'
      case '3':
        return 'Tipo Uno'
      case '4':
        return 'Tipo Due'
      case '5':
        return 'Tipo Tre'
      case '6':
        return 'Tipo Quattro'
      default:
        return seq
      }
    },
    formatPercentuale (number) {
      if (number === '') return number

      if (number.indexOf('.') > -1) {
        const [beforeDot, afterDot] = number.split('.')
        const afterDotTrimmed = afterDot.slice(0, 2)
        return beforeDot + '.' + afterDotTrimmed + ' %'
      }
      return number + ' %'
    },
    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    }
  }
}
</script>
