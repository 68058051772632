
export default {
  it: {
    message: {
      hello: 'ciao world'
    }
  },
  en: {
    message: {
      hello: 'hello world'
    }
  },
  fr: {
    message: {
      hello: 'fr'
    }
  },
  es: {
    message: {
      hello: 'es'
    }
  },
  de: {
    message: {
      hello: 'de'
    }
  },
  nl: {
    message: {
      hello: 'nl'
    }
  }
}
