var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',{attrs:{"items":Object.keys(_vm.products.categories),"label":_vm.$t('messages.Seleziona una categoria'),"placeholder":_vm.$t('messages.Seleziona una categoria'),"clearable":"","filled":"","filter":_vm.customCategoryFilter,"menu-props":{
      maxHeight:200,
    }},on:{"input":_vm.inputCategory,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.confirmEvent.apply(null, arguments)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.products.categories[item].titolo)+" ")])]}},{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.products.categories[item].titolo)+" ")])]}}],null,true),model:{value:(_vm.selectedCategoryId),callback:function ($$v) {_vm.selectedCategoryId=$$v},expression:"selectedCategoryId"}},[_c('template',{slot:"no-data"},[_c('v-list-item',[_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t('messages.Nessuna categoria presente'))+" ")])],1)],1)],2),((1 === 2))?_c('v-autocomplete',{attrs:{"menu-props":{
      maxHeight:200,
    },"items":_vm.filteredSubCategories,"label":_vm.$t('messages.Seleziona una sotto-categoria'),"placeholder":_vm.$t('messages.Seleziona una sotto-categoria'),"clearable":"","filled":"","filter":_vm.customSubCategoryFilter},on:{"input":_vm.inputSubCategory,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.confirmEvent.apply(null, arguments)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.products.subCategories[item].titolo)+" ")])]}},{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.products.subCategories[item].titolo)+" ")])]}}],null,true),model:{value:(_vm.selectedSubCategoryId),callback:function ($$v) {_vm.selectedSubCategoryId=$$v},expression:"selectedSubCategoryId"}},[_c('template',{slot:"no-data"},[_c('v-list-item',[_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t('messages.Nessuna sotto-categoria presente'))+" ")])],1)],1)],2):_vm._e(),_c('v-autocomplete',{attrs:{"menu-props":{
      maxHeight:200,
    },"items":_vm.filteredLines,"label":_vm.$t('messages.Seleziona una linea'),"placeholder":_vm.$t('messages.Seleziona una linea'),"clearable":"","filled":"","filter":_vm.customLineFilter},on:{"input":_vm.inputLine,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.confirmEvent.apply(null, arguments)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(_vm.products.lines[item].titolo)+" "),(_vm.products.lines[item].sottotitolo)?[_c('v-chip',{staticClass:"px-1 mb-2",staticStyle:{"font-size":"12px"},attrs:{"x-small":""},domProps:{"innerHTML":_vm._s(_vm.products.lines[item].sottotitolo)}})]:_vm._e()],2)]}},{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.products.lines[item].titolo)+" ")])]}}],null,true),model:{value:(_vm.selectedLineId),callback:function ($$v) {_vm.selectedLineId=$$v},expression:"selectedLineId"}},[_c('template',{slot:"no-data"},[_c('v-list-item',[_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t('messages.Nessuna linea presente'))+" ")])],1)],1)],2),_c('v-autocomplete',{staticClass:"cclist",attrs:{"menu-props":{
      maxHeight:200,
    },"items":_vm.filteredCommercialCodes,"label":_vm.$t('messages.Seleziona un codice commerciale'),"placeholder":_vm.$t('messages.Seleziona un codice commerciale'),"clearable":"","filled":"","filter":_vm.customCommercialCodeFilter},on:{"input":_vm.inputCommercialCode,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.confirmEvent.apply(null, arguments)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center mx-n3"},[(_vm.hasImage(_vm.products.commercialcodes[item]))?_c('v-img',{staticClass:"article-image",attrs:{"src":_vm.getImage(_vm.products.commercialcodes[item])}}):(_vm.hasCover(_vm.products.commercialcodes[item]))?_c('v-img',{staticClass:"article-image",attrs:{"src":_vm.getCover(_vm.products.commercialcodes[item])}}):_c('div',{staticClass:"article-image",style:('background-color: ' + _vm.getColor(_vm.products.commercialcodes[item]))}),_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.products.commercialcodes[item].codice)+" ")])],1)]}},{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center ml-n2"},[(_vm.hasImage(_vm.products.commercialcodes[item]))?_c('v-img',{staticClass:"article-image",attrs:{"src":_vm.getImage(_vm.products.commercialcodes[item])}}):(_vm.hasCover(_vm.products.commercialcodes[item]))?_c('v-img',{staticClass:"article-image",attrs:{"src":_vm.getCover(_vm.products.commercialcodes[item])}}):_c('div',{staticClass:"article-image",style:('background-color: ' + _vm.getColor(_vm.products.commercialcodes[item]))}),_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.products.commercialcodes[item].codice)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedCommercialCodeId),callback:function ($$v) {_vm.selectedCommercialCodeId=$$v},expression:"selectedCommercialCodeId"}},[_c('template',{slot:"no-data"},[_c('v-list-item',[_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t('messages.Nessun codice commerciale presente'))+" ")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }