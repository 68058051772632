var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(_vm.isInitializing != 0)?_c('v-progress-circular',{staticClass:"ml-3",attrs:{"indeterminate":"","color":"primary"}}):_c('div',[_c('div',{staticClass:"row col mb-4"},[_c('h1',[_vm._v(_vm._s(_vm.$t('messages.Gestione Campionature')))])]),_c('v-btn',{staticClass:"btn btn-lg mr-1",attrs:{"color":"primary","dark":"","type":"button","loading":_vm.isLoading,"elevation":"0","tile":""},on:{"click":function($event){return _vm.addNew()}}},[_vm._v(" "+_vm._s(_vm.$t('messages.Aggiungi Nuovo'))+" ")]),_c('v-btn',{staticClass:"btn btn-lg mx-1",attrs:{"color":"green","dark":"","type":"button","elevation":"0","loading":_vm.isLoading,"tile":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t('messages.Salva'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listcampioniprodotti,"locale":_vm.$t('messages.locale'),"items-per-page":-1,"elevation":"0","header-props":{ sortByText: _vm.$t('messages.Ordina per')},"footer-props":{
        itemsPerPageAllText: _vm.$t('messages.Tutte'),
        itemsPerPageText: _vm.$t('messages.Per pagina')
      }},scopedSlots:_vm._u([{key:"item.codice",fn:function(ref){
      var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.setDirty(item)}},model:{value:(item.codice),callback:function ($$v) {_vm.$set(item, "codice", $$v)},expression:"item.codice"}})]}},{key:"item.rotolino",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","dense":""},on:{"change":function($event){return _vm.setDirty(item)}},model:{value:(item.rotolino),callback:function ($$v) {_vm.$set(item, "rotolino", $$v)},expression:"item.rotolino"}})]}},{key:"item.printRoll",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","dense":""},on:{"change":function($event){return _vm.setDirty(item)}},model:{value:(item.printRoll),callback:function ($$v) {_vm.$set(item, "printRoll", $$v)},expression:"item.printRoll"}})]}},{key:"item.a4",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","dense":""},on:{"change":function($event){return _vm.setDirty(item)}},model:{value:(item.a4),callback:function ($$v) {_vm.$set(item, "a4", $$v)},expression:"item.a4"}})]}},{key:"item.a5",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","dense":""},on:{"change":function($event){return _vm.setDirty(item)}},model:{value:(item.a5),callback:function ($$v) {_vm.$set(item, "a5", $$v)},expression:"item.a5"}})]}},{key:"item.raggruppa",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","dense":""},on:{"change":function($event){return _vm.setDirty(item)}},model:{value:(item.raggruppa),callback:function ($$v) {_vm.$set(item, "raggruppa", $$v)},expression:"item.raggruppa"}})]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{attrs:{"medium":"","color":"error"},on:{"click":function($event){_vm.remove(item);_vm.setDirty()}}},[_vm._v(" mdi-delete ")])]}},{key:"footer.page-text",fn:function(ref){
      var pageStart = ref.pageStart;
      var pageStop = ref.pageStop;
      var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" di "+_vm._s(itemsLength)+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }