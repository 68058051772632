import { render, staticRenderFns } from "./ProductSelector.vue?vue&type=template&id=65e3476f&scoped=true&"
import script from "./ProductSelector.vue?vue&type=script&lang=js&"
export * from "./ProductSelector.vue?vue&type=script&lang=js&"
import style0 from "./ProductSelector.vue?vue&type=style&index=0&id=65e3476f&scoped=true&lang=css&"
import style1 from "./ProductSelector.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e3476f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VAutocomplete,VChip,VImg,VListItem,VListItemContent})
