<template>
  <div>
    <v-autocomplete
      v-model="selectedCategoryId"
      :items="Object.keys(products.categories)"
      :label="$t('messages.Seleziona una categoria')"
      :placeholder="$t('messages.Seleziona una categoria')"
      clearable
      filled
      :filter="customCategoryFilter"
      :menu-props="{
        maxHeight:200,
      }"
      @input="inputCategory"
      @keydown.enter="confirmEvent"
    >
      <template slot="no-data">
        <v-list-item>
          <v-list-item-content>
            {{ $t('messages.Nessuna categoria presente') }}
          </v-list-item-content>
        </v-list-item>
      </template>
      <template
        #[`item`]="{ item }"
      >
        <div>
          {{ products.categories[item].titolo }}
        </div>
      </template>
      <template
        #[`selection`]="{ item }"
      >
        <div>
          {{ products.categories[item].titolo }}
        </div>
      </template>
    </v-autocomplete>

    <v-autocomplete
      v-if="(1 === 2)"
      v-model="selectedSubCategoryId"
      :menu-props="{
        maxHeight:200,
      }"
      :items="filteredSubCategories"
      :label="$t('messages.Seleziona una sotto-categoria')"
      :placeholder="$t('messages.Seleziona una sotto-categoria')"
      clearable
      filled
      :filter="customSubCategoryFilter"
      @input="inputSubCategory"
      @keydown.enter="confirmEvent"
    >
      <template slot="no-data">
        <v-list-item>
          <v-list-item-content>
            {{ $t('messages.Nessuna sotto-categoria presente') }}
          </v-list-item-content>
        </v-list-item>
      </template>
      <template
        #[`item`]="{ item }"
      >
        <div>
          {{ products.subCategories[item].titolo }}
        </div>
      </template>
      <template
        #[`selection`]="{ item }"
      >
        <div>
          {{ products.subCategories[item].titolo }}
        </div>
      </template>
    </v-autocomplete>

    <v-autocomplete
      v-model="selectedLineId"
      :menu-props="{
        maxHeight:200,
      }"
      :items="filteredLines"
      :label="$t('messages.Seleziona una linea')"
      :placeholder="$t('messages.Seleziona una linea')"
      clearable
      filled
      :filter="customLineFilter"
      @input="inputLine"
      @keydown.enter="confirmEvent"
    >
      <template slot="no-data">
        <v-list-item>
          <v-list-item-content>
            {{ $t('messages.Nessuna linea presente') }}
          </v-list-item-content>
        </v-list-item>
      </template>
      <template
        #[`item`]="{ item }"
      >
        <div class="d-flex flex-column">
          {{ products.lines[item].titolo }}
          <!-- eslint-disable vue/no-v-html -->
          <template v-if="products.lines[item].sottotitolo">
            <v-chip
              style="font-size: 12px"
              class="px-1 mb-2"

              x-small
              v-html="products.lines[item].sottotitolo"
            />
          <!-- eslint-enable vue/no-v-html -->
          </template>
        </div>
      </template>
      <template
        #[`selection`]="{ item }"
      >
        <div>
          {{ products.lines[item].titolo }}
        </div>
      </template>
    </v-autocomplete>

    <v-autocomplete
      v-model="selectedCommercialCodeId"
      :menu-props="{
        maxHeight:200,
      }"
      :items="filteredCommercialCodes"
      :label="$t('messages.Seleziona un codice commerciale')"
      :placeholder="$t('messages.Seleziona un codice commerciale')"
      class="cclist"
      clearable
      filled
      :filter="customCommercialCodeFilter"
      @input="inputCommercialCode"
      @keydown.enter="confirmEvent"
    >
      <template slot="no-data">
        <v-list-item>
          <v-list-item-content>
            {{ $t('messages.Nessun codice commerciale presente') }}
          </v-list-item-content>
        </v-list-item>
      </template>
      <template
        #[`item`]="{ item }"
      >
        <div
          class="d-flex align-center mx-n3"
        >
          <v-img
            v-if="hasImage(products.commercialcodes[item])"
            class="article-image"
            :src="getImage(products.commercialcodes[item])"
          />
          <v-img
            v-else-if="hasCover(products.commercialcodes[item])"
            class="article-image"
            :src="getCover(products.commercialcodes[item])"
          />
          <div
            v-else
            class="article-image"
            :style="'background-color: ' + getColor(products.commercialcodes[item])"
          />
          <div class="ml-1">
            {{ products.commercialcodes[item].codice }}
          </div>
        </div>
      </template>
      <template
        #[`selection`]="{ item }"
      >
        <div
          class="d-flex align-center ml-n2"
        >
          <v-img
            v-if="hasImage(products.commercialcodes[item])"
            class="article-image"
            :src="getImage(products.commercialcodes[item])"
          />
          <v-img
            v-else-if="hasCover(products.commercialcodes[item])"
            class="article-image"
            :src="getCover(products.commercialcodes[item])"
          />
          <div
            v-else
            class="article-image"
            :style="'background-color: ' + getColor(products.commercialcodes[item])"
          />
          <div class="ml-1">
            {{ products.commercialcodes[item].codice }}
          </div>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>

export default {
  name: 'ProductSelector',
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    },
    products: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    selectedProduct: false,
    selectedCategoryId: '',
    selectedSubCategoryId: '',
    selectedLineId: '',
    selectedCommercialCodeId: '',
    filteredSubCategories: [],
    filteredLines: [],
    filteredCommercialCodes: []
  }),

  watch: {
    value (newVal) {
      this.selectedProduct = newVal
      if (this.selectedProduct) {
        if (this.selectedProduct.selectedCategoryId) {
          this.selectedCategoryId = this.selectedProduct.selectedCategoryId
        }
        if (this.selectedProduct.selectedSubCategoryId) {
          this.selectedSubCategoryId = this.selectedProduct.selectedSubCategoryId
        }
        if (this.selectedProduct.selectedLineId) {
          this.selectedLineId = this.selectedProduct.selectedLineId
        }
        if (this.selectedProduct.selectedCommercialCodeId) {
          this.selectedCommercialCodeId = this.selectedProduct.selectedCommercialCodeId
        }
      }
    }
  },

  async created () {
    this.inputCategory()
    this.inputSubCategory()
    this.inputLine()
  },

  methods: {
    updateValue () {
      // verifichiamo poi che se era seleziona c'è qualcosa selezionato, questo esiste ancora tra quelle filtrate
      if (this.selectedSubCategoryId && !this.filteredSubCategories.some(s => this.selectedSubCategoryId === s)) {
        this.selectedSubCategoryId = ''
      }
      if (this.selectedLineId && !this.filteredLines.some(l => this.selectedLineId === l)) {
        this.selectedLineId = ''
      }
      if (this.selectedCommercialCodeId && !this.filteredCommercialCodes.some(cc => this.selectedCommercialCodeId === cc)) {
        this.selectedCommercialCodeId = ''
      }
      this.selectedProduct = {
        selectedCategoryId: this.selectedCategoryId,
        selectedSubCategoryId: this.selectedSubCategoryId,
        selectedLineId: this.selectedLineId,
        selectedCommercialCodeId: this.selectedCommercialCodeId
      }
      if (!this.selectedCategoryId && !this.selectedSubCategoryId && !this.selectedLineId && !this.selectedCommercialCodeId) {
        this.$emit('input', null)
      } else {
        this.$emit('input', this.selectedProduct)
      }
    },

    confirmEvent () {
      if (this.value) {
        this.$emit('confirm', this.value)
      }
    },

    inputCategory () {
      // Se è valorizzata una categoria
      // allora filtriamo le rispettive sottocategorie
      if (this.selectedCategoryId) {
        this.filteredSubCategories = Object.keys(this.products.subCategories).filter((s) => this.products.categories[this.selectedCategoryId].subcategories.some((cs) => cs === s))

        this.filteredLines = Object.keys(this.products.lines).filter(l => this.filteredSubCategories.some(sc => {
          return this.products.subCategories[sc].lines.some((sl) => sl === l)
        }))

        this.filteredCommercialCodes = Object.keys(this.products.commercialcodes).filter(cc => this.filteredLines.some(l => {
          return this.products.lines[l].commercialcodes.some((lcc) => lcc === cc)
        }))
      } else {
        this.filteredSubCategories = Object.keys(this.products.subCategories)

        if (!this.selectedSubCategoryId) {
          this.filteredLines = Object.keys(this.products.lines)
        }
        if (!this.selectedSubCategoryId && !this.selectedLineId) {
          this.filteredCommercialCodes = Object.keys(this.products.commercialcodes)
        }
      }
      this.updateValue()
    },

    inputSubCategory () {
      if (this.selectedSubCategoryId) {
        this.filteredLines = Object.keys(this.products.lines).filter((l) => this.products.subCategories[this.selectedSubCategoryId].lines.some((sl) => sl === l))

        this.filteredCommercialCodes = Object.keys(this.products.commercialcodes).filter(cc => this.filteredLines.some(l => {
          return this.products.lines[l].commercialcodes.some((lcc) => lcc === cc)
        }))
        this.updateValue()
      } else {
        if (!this.selectedLineId) {
          this.inputCategory()
        }
      }
    },

    inputLine () {
      if (this.selectedLineId) {
        this.filteredCommercialCodes = Object.keys(this.products.commercialcodes).filter((cc) => this.products.lines[this.selectedLineId].commercialcodes.some((lcc) => lcc === cc))
        this.updateValue()
      } else {
        this.inputSubCategory()
      }
    },

    inputCommercialCode () {
      this.updateValue()
    },

    /*
     * Gestione filtri autocomplete
     */
    customCategoryFilter (item, queryText, itemText) {
      return this.customProductFilter(item, queryText, 'categories')
    },
    customSubCategoryFilter (item, queryText, itemText) {
      return this.customProductFilter(item, queryText, 'subCategories')
    },
    customLineFilter (item, queryText, itemText) {
      return this.customProductFilter(item, queryText, 'lines')
    },
    customCommercialCodeFilter (item, queryText, itemText) {
      return this.customProductFilter(item, queryText, 'commercialcodes')
    },
    customProductFilter (item, queryText, type) {
      // verifico se esiste la proprietà codice
      // perché questa funzione di filtro viene chiamata da tutti gli autocomplete
      // e codicecommerciale ha la proprietà codice invece che titolo come tutti gli altri casi
      if (('codice' in this.products[type][item])) {
        // codicecommerciale
        const value = this.products[type][item].codice.toLowerCase().replace(/[.\\/-]/gi, '')
        const search = queryText.toLowerCase().replace(/[.\\/-]/gi, '')
        return (value.includes(search))
      } else {
        // categoria sottocategoria linea
        return (this.products[type][item].titolo.toLowerCase().includes(queryText.toLowerCase()) ||
          (('sottotitolo' in this.products[type][item]) && this.products[type][item].sottotitolo.toLowerCase().includes(queryText.toLowerCase()))
        )
      }
    },

    hasCover (article) {
      if (article.cover && article.cover !== '') {
        return true
      }
      return false
    },
    getCover (article) {
      if (article.cover !== '') {
        const coverName = article.cover // .codice_commerciale.replace('/', '').toLowerCase() + '.jpg'
        const coverPathName = 'https://shop.apaspa.com/apa/images/lines/' + coverName
        return coverPathName
      }
      return ''
    },
    hasImage (article) {
      if (article.immagine !== '') {
        return true
      }
      return false
    },
    getImage (article) {
      if (article.immagine !== '') {
        const imageName = article.immagine // .codice_commerciale.replace('/', '').toLowerCase() + '.jpg'
        const imagePathName = 'https://shop.apaspa.com/apa/images/commercial_codes/' + imageName
        return imagePathName
      }
      return ''
    },
    getColor (article) {
      if (article.colore !== '') {
        return article.colore
      } else {
        return '#ffffff'
      }
    }
  }
}
</script>

<style scoped>
>>>.v-text-field__details {
  margin-bottom: 0px !important;
  min-height: 0px;
  height: 0px !important;
}
.article-image {
  min-width:51px;
  min-height:40px;
  max-width:51px;
  max-height:40px;
}
</style>

<style>
.theme--light.v-list {
  background: #eff3f7!important;
}
</style>
