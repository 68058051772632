<template>
  <div
    class="d-flex flex-column"
    style="position: relative;"
  >
    <v-autocomplete
      v-model="selectedClientId"
      :items="filteredClients"
      item-text="azienda"
      item-value="id"
      :label="label"
      :placeholder="$t('messages.Seleziona un Cliente')"
      clearable
      :loading="isLoadingDebounceFilter"
      hide-details
      filled
      :attach="attach?'#attachClientSelector':false"
      :search-input.sync="search"
      :filter="filterClients"
      style="width:100%; min-width:100%; max-width:100%;"
      :menu-props="{
        maxHeight:200,
      }"
      @keydown.enter="confirmEvent"
    >
      <template
        v-if="attach"
        #append-outer
      >
        <div id="attachClientSelector" />
      </template>
      <template slot="no-data">
        <v-list-item>
          <v-list-item-content>
            {{ isLoadingDebounceFilter ? $t('messages.Ricercappp') : (search && search !=='' ? $t('messages.Nessun Cliente trovato') : $t('messages.Digita nome clienteppp') ) }}
          </v-list-item-content>
        </v-list-item>
      </template>
      <template
        #[`item`]="{ item }"
      >
        <div class="d-flex align-center">
          <img
            v-if="item.is_projx == 1"
            src="/logo.svg"
            width="24"
            class="mr-2"
          >
          <div>
            {{ item.azienda }}
          </div>
        </div>
      </template>
      <template
        #[`selection`]="{ item }"
      >
        <img
          v-if="item.is_projx == 1"
          src="/logo.svg"
          width="24"
          class="mr-2"
        >
        <div>
          {{ item.azienda }}
        </div>
      </template>
    </v-autocomplete>
    <template v-if="expandAddress">
      <div
        v-if="selectedClientId && showClientDetails"
        style="position: relative;"
      >
        <div class="d-flex justify-center mt-2 mb-2">
          <img
            v-if="selectedClient.is_projx == 1"
            src="/logo.svg"
            width="24"
            class="mr-2"
          >
          <div>
            <h4>{{ selectedClient.azienda }}</h4>
          </div>
        </div>
        <div
          v-if="!selectedClientIndirizzo"
          class="d-flex justify-center"
        >
          <v-progress-circular
            indeterminate
            :size="50"
            :width="7"
            color="primary"
          />
        </div>
        <template v-else>
          <div class="d-flex justify-center mb-2 caption">
            <span class="caption">{{ selectedClientIndirizzo.indirizzo }}, {{ selectedClientIndirizzo.cap }} {{ selectedClientIndirizzo.citta }} ({{ selectedClientIndirizzo.provincia }})</span>
          </div>
        </template>
        <v-icon
          v-model="showClientDetails"
          color="grey"
          class="pa-1"
          style="background-color: #ffffff9e;border-radius: 50%; position: absolute; right:0px; top:5px"
          @click.stop="closeClientDetails"
        >
          mdi-window-minimize
        </v-icon>
      </div>
      <div
        v-if="selectedClientId && hideClientDetails"
        style="height:40px; min-height:40px; max-height:40px;"
      >
        <v-icon
          v-model="hideClientDetails"
          color="grey"
          class="pa-1"
          style="background-color: #ffffff9e;border-radius: 50%; position: absolute; right:0px; top:60px"
          @click.stop="openClientDetails"
        >
          mdi-window-maximize
        </v-icon>
      </div>
    </template>
  </div>
</template>
<script>

export default {
  name: 'ClientSelector',

  props: {
    value: {
      type: String,
      required: false,
      default: () => ''
    },
    label: {
      type: String,
      required: false,
      default: function () {
        return this.$t('messages.Seleziona un Cliente')
      }
    },
    clients: {
      type: Array,
      required: true
    },
    expandAddress: {
      type: Boolean,
      required: false,
      default: () => true
    },
    attach: {
      required: false,
      default: false,
      validator: () => {
        return true
      }
    }
  },
  data: () => ({
    selectedClientId: '',
    selectedClientIndirizzo: null,
    debounceFilterTimeout: null,
    isLoadingDebounceFilter: false,
    search: '',
    filteredClients: [],
    showClientDetails: false,
    hideClientDetails: true
  }),

  computed: {
    selectedClient () {
      return this.selectedClientId ? this.clients.find((c) => c.id === this.selectedClientId) : ''
    }
  },

  watch: {
    selectedClient (newVal) {
      if (newVal) {
        this.filteredClients = this.clients.filter(client => {
          return client.id === this.selectedClientId
        })
      }
      if (this.expandAddress) {
        if (newVal) {
          this.showClientDetails = true
          this.hideClientDetails = false
          this.getClientAddress()
        } else {
          this.selectedClientIndirizzo = null
        }
      }
    },
    clients () {
      this.search = ''
      this.filteredClients = []
    },
    value (newVal) {
      this.selectedClientId = newVal
    },
    selectedClientId (newVal) {
      if (this.selectedClientId !== this.value) { this.$emit('input', newVal) }
    },
    search (val) {
      val && val !== this.selectedClientId && this.querySelections(val)
    }
  },

  created () {
    this.selectedClientId = this.value
    if (this.selectedClientId) {
      this.filteredClients = this.clients.filter(client => {
        return client.id === this.selectedClientId
      })
    }
  },

  methods: {
    closeClientDetails () {
      this.showClientDetails = false
      this.hideClientDetails = true
    },
    openClientDetails () {
      this.showClientDetails = true
      this.hideClientDetails = false
    },

    async getClientAddress () {
      this.selectedClientIndirizzo = null
      this.selectedClientIndirizzo = await this.$store.dispatch('map/getClientAddress', { clientId: this.selectedClientId })
    },
    querySelections (queryText) {
      this.loadisLoadingDebounceFiltering = true
      clearTimeout(this.debounceFilterTimeout)
      this.isLoadingDebounceFilter = true
      this.debounceFilterTimeout = setTimeout(() => {
        if (queryText.length === 0) { this.filteredClients = [] }
        this.filteredClients = this.clients.filter(client => {
          return ((client.azienda ?? '').toLowerCase().includes(queryText.toLowerCase()) || (client.cd_clien ?? '').toLowerCase().includes(queryText.toLowerCase()))
        })
        this.isLoadingDebounceFilter = false
      }, 1000)
    },

    filterClients (client, queryText, itemText) {
      return ((client.azienda ?? '').toLowerCase().includes(queryText.toLowerCase()) || (client.cd_clien ?? '').toLowerCase().includes(queryText.toLowerCase()))
    },
    confirmEvent () {
      if (this.selectedClientId) {
        this.$emit('confirm', this.selectedClientId)
      }
    }
  }
}
</script>
