export default class MapLabel {
  constructor (options) {
    /* eslint-disable no-undef */
    const mapLabel = new google.maps.OverlayView()
    /* eslint-enable no-undef */
    mapLabel.set('fontFamily', 'sans-serif')
    mapLabel.set('fontSize', 12)
    mapLabel.set('fontColor', '#000000')
    mapLabel.set('strokeWeight', 4)
    mapLabel.set('strokeColor', '#ffffff')
    mapLabel.set('align', 'center')

    mapLabel.set('zIndex', 1e3)

    mapLabel.setValues(options)

    // window.MapLabel = mapLabel

    /** @inheritDoc */
    mapLabel.changed = function (prop) {
      switch (prop) {
      case 'fontFamily':
      case 'fontSize':
      case 'fontColor':
      case 'strokeWeight':
      case 'strokeColor':
      case 'align':
      case 'text':
        return this.drawCanvas_()
      case 'maxZoom':
      case 'minZoom':
      case 'position':
        return this.draw()
      }
    }

    /**
   * Draws the label to the canvas 2d context.
   * @private
   */
    mapLabel.drawCanvas_ = function () {
      const canvas = this.canvas_
      if (!canvas) return

      const style = canvas.style
      style.zIndex = /** @type number */(this.get('zIndex'))

      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.strokeStyle = this.get('strokeColor')
      ctx.fillStyle = this.get('fontColor')
      ctx.font = this.get('fontSize') + 'px ' + this.get('fontFamily')

      const strokeWeight = Number(this.get('strokeWeight'))

      const text = this.get('text')
      if (text) {
        if (strokeWeight) {
          ctx.lineWidth = strokeWeight
          ctx.strokeText(text, strokeWeight, strokeWeight)
        }

        ctx.fillText(text, strokeWeight, strokeWeight)

        const textMeasure = ctx.measureText(text)
        const textWidth = textMeasure.width + strokeWeight
        style.marginLeft = this.getMarginLeft_(textWidth) + 'px'
        // Bring actual text top in line with desired latitude.
        // Cheaper than calculating height of text.
        style.marginTop = '-0.4em'
      }
    }

    /**
 * @inheritDoc
 */
    mapLabel.onAdd = function () {
      const canvas = this.canvas_ = document.createElement('canvas')
      const style = canvas.style
      style.position = 'absolute'

      const ctx = canvas.getContext('2d')
      ctx.lineJoin = 'round'
      ctx.textBaseline = 'top'

      this.drawCanvas_()

      const panes = this.getPanes()
      if (panes) {
        panes.mapPane.appendChild(canvas)
      }
    }

    /**
 * Gets the appropriate margin-left for the canvas.
 * @private
 * @param {number} textWidth  the width of the text, in pixels.
 * @return {number} the margin-left, in pixels.
 */
    mapLabel.getMarginLeft_ = function (textWidth) {
      switch (this.get('align')) {
      case 'left':
        return 0
      case 'right':
        return -textWidth
      }
      return textWidth / -2
    }

    /**
 * @inheritDoc
 */
    mapLabel.draw = function () {
      const projection = this.getProjection()

      if (!projection) {
        // The map projection is not ready yet so do nothing
        return
      }

      if (!this.canvas_) {
        // onAdd has not been called yet.
        return
      }

      const latLng = /** @type {google.maps.LatLng} */ (this.get('position'))
      if (!latLng) {
        return
      }
      const pos = projection.fromLatLngToDivPixel(latLng)

      const style = this.canvas_.style

      style.top = pos.y + 'px'
      style.left = pos.x + 'px'

      style.visibility = this.getVisible_()
    }

    /**
 * Get the visibility of the label.
 * @private
 * @return {string} blank string if visible, 'hidden' if invisible.
 */
    mapLabel.getVisible_ = function () {
      const minZoom = /** @type number */(this.get('minZoom'))
      const maxZoom = /** @type number */(this.get('maxZoom'))

      if (minZoom === undefined && maxZoom === undefined) {
        return ''
      }

      const map = this.getMap()
      if (!map) {
        return ''
      }

      const mapZoom = map.getZoom()
      if (mapZoom < minZoom || mapZoom > maxZoom) {
        return 'hidden'
      }
      return ''
    }

    /**
 * @inheritDoc
 */
    mapLabel.onRemove = function () {
      const canvas = this.canvas_
      if (canvas && canvas.parentNode) {
        canvas.parentNode.removeChild(canvas)
      }
    }
    return mapLabel
  }
}
