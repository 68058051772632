<template>
  <v-dialog
    v-model="open"
    max-width="290"
    :persistent="persistent"
  >
    <v-card>
      <div class="d-flex justify-end ">
        <v-btn
          icon
          color="#C4C2BB"
          style="margin-top: -36px;top: 36px;position: relative;"
          @click="cancelDialog()"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>

      <v-card-text v-if="description">
        <span style="white-space: pre-wrap;">{{ description }}</span>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          :color="colorSecondary"
          class="btn btn-lg mb-4 ml-6"
          type="button"
          elevation="0"
          tile
          @click="titleSecondary ? confirmSecondary() : cancelDialog()"
        >
          {{ titleSecondary ? titleSecondary : $t('messages.Annulla') }}
        </v-btn>

        <v-btn
          :color="color"
          dark
          class="btn btn-lg float-right mb-4 mr-6"
          type="button"
          :loading="loading"
          elevation="0"
          tile
          @click="confirm()"
        >
          {{ title }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'ConfirmDialog',
  model: {
    prop: 'isOpen',
    event: 'change'
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    color: {
      type: String,
      required: false,
      default: 'primary'
    },
    titleSecondary: {
      type: String,
      required: false,
      default: ''
    },
    colorSecondary: {
      type: String,
      required: false,
      default: '#C4C2BB'
    },
    persistent: {
      type: Boolean,
      required: false,
      default: false,
      validator: () => {
        return true
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    open: false
  }),

  watch: {
    open (newVal) {
      if (newVal !== this.isOpen) {
        this.$emit('change', newVal)
      }
    },
    isOpen (newVal) {
      this.open = newVal
    }
  },

  methods: {
    confirm () {
      this.$emit('confirm', { secondary: false })
    },
    confirmSecondary () {
      this.$emit('confirm', { secondary: true })
    },
    cancelDialog () {
      this.$emit('cancel')
    }
  }
}
</script>
