<template>
  <div class="container">
    <div class="mb-2">
      <h4> {{ $t('messages.Buongiorno #nome#', [username]) }}</h4>
    </div>

    <v-progress-circular
      v-if="isInitializing > 0"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <div
        class="d-flex flex-wrap justify-center align-center mb-4"
      >
        <agent-selector
          v-if="agentsVisibleToAgent.length > 1"
          v-model="filter.selectedAgent"
          :agents="agentsVisibleToAgent"
          :label="$t('messages.Seleziona agente')"
          class="mx-4"
          style="max-width:320px"
          @input="reload"
        />
        <area-selector
          v-if="areasVisibleToAgent.length > 1"
          v-model="filter.selectedArea"
          class="mx-4"
          style="max-width:320px"
          :areas="areasVisibleToAgent"
          @input="reload"
        />
        <v-menu
          v-if="isSupervisor"
          v-model="selectedDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="formatDate(filter.selectedDate)"
              :placeholder="$t('messages.Seleziona una data')"
              prepend-icon="mdi-calendar"
              readonly
              class="mx-4"
              style="max-width:320px"
              filled
              v-bind="attrs"
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="filter.selectedDate"
            type="month"
            :locale="$t('messages.locale')"
            :first-day-of-week="1"
            :max="(new Date()).toISOString().substr(0, 10)"
            @input="reload"
          />
        </v-menu>
      </div>

      <div
        class="mx-n3 d-lg-flex justify-lg-space-around"
      >
        <div
          class="d-flex flex-column mb-4"
        >
          <div
            v-if="isReloadingData > 0"
            class="d-flex justify-center align-center"
            :style="'height:'+graphHeight+'px;width:'+graphWidth+'px;'"
          >
            <v-progress-circular
              indeterminate
              :size="100"
              :width="10"
              color="primary"
              style="max-width: 500px;"
            />
          </div>
          <div
            v-else
          >
            <div
              class="d-flex justify-center small"
              :class="$vuetify.breakpoint.smAndUp ? 'automargin':''"
            >
              <h5>{{ $t('messages.Fatturato #Mese#', [formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1)]) }} </h5>
            </div>
            <line-chart
              class=" small"
              :class="$vuetify.breakpoint.smAndUp ? 'automargin':''"
              :chart-data="salesCollection"
            />
          </div>
        </div>
        <div
          class="d-flex flex-column mb-4"
        >
          <div
            v-if="isReloadingData > 0"
            class="d-flex justify-center align-center"
            :style="'height:'+graphHeight+'px;width:'+graphWidth+'px;'"
          >
            <v-progress-circular
              indeterminate
              :size="100"
              :width="10"
              color="primary"
            />
          </div>
          <div
            v-else
            ref="graph2"
          >
            <div
              class="d-flex justify-center small"
              :class="$vuetify.breakpoint.smAndUp ? 'automargin':''"
            >
              <h5>{{ $t('messages.Clienti visitati a #Mese#', [formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1)]) }}</h5>
            </div>
            <line-chart
              class=" small"
              :class="$vuetify.breakpoint.smAndUp ? 'automargin':''"
              :chart-data="visitsCollection"
            />
          </div>
          <div class="d-flex justify-center">
            <v-switch
              v-model="filter.clientUnique"
              hide-details
              class="mt-0"
              :label="$t('messages.Visite clienti univoci')"
              :color="'primary'"
              :readonly="isReloadingData>0"
              @change="reload"
            />
          </div>
        </div>
        <div
          v-if="isSupervisor"
          class="d-flex flex-column mb-4"
        >
          <div
            v-if="isReloadingData > 0"
            class="d-flex justify-center align-center"
            :style="'height:'+graphHeight+'px;width:'+graphWidth+'px;'"
          >
            <v-progress-circular
              indeterminate
              :size="100"
              :width="10"
              color="primary"
              style="max-width: 500px;"
            />
          </div>
          <div
            v-else
          >
            <div
              class="d-flex justify-center small"
              :class="$vuetify.breakpoint.smAndUp ? 'automargin':''"
            >
              <h5>{{ $t('messages.Riepilogo Mese #Mese#', [formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1)]) }} </h5>
            </div>
            <table
              style="max-width:630px;margin-left:auto;margin-right:auto;"
            >
              <thead>
                <tr>
                  <th
                    class="hr.class"
                    style="min-width: 140px"
                  >
                    <div class="d-flex justify-start">
                      Area
                    </div>
                  </th>
                  <th
                    class="hr.class"
                    style="min-width: 120px"
                  >
                    <div class="d-flex justify-center">
                      {{ getSelectedYear(-2) }}
                    </div>
                  </th>
                  <th
                    class="hr.class"
                    style="min-width: 120px"
                  >
                    <div class="d-flex justify-center">
                      {{ getSelectedYear(-1) }}
                    </div>
                  </th>
                  <th
                    class="hr.class"
                    style="min-width: 120px"
                  >
                    <div class="d-flex justify-center">
                      {{ getSelectedYear(0) }}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(cdAgent, k) in Object.keys(homepageReport.totalSalesByArea)"
                >
                  <tr
                    :key="k"
                  >
                    <td>
                      <div
                        class="d-flex justify-left"
                        style="font-weight: bold; font-size: 0.765rem;"
                      >
                        {{ getArea(cdAgent) }}
                      </div>
                    </td>
                    <td>
                      <div>
                        <div
                          class="d-flex justify-space-between"
                          style="font-weight: bold; font-size: 0.765rem;"
                        >
                          <span>€</span>
                          <span>{{ formatCurrency(homepageReport.totalSalesByArea[cdAgent].lastLastYear) }}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div
                          class="d-flex justify-space-between"
                          style="font-weight: bold; font-size: 0.765rem;"
                        >
                          <span>€</span>
                          <span>{{ formatCurrency(homepageReport.totalSalesByArea[cdAgent].lastYear) }}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div
                          class="d-flex justify-space-between"
                          style="font-weight: bold; font-size: 0.765rem;"
                        >
                          <span>€</span>
                          <span>{{ formatCurrency(homepageReport.totalSalesByArea[cdAgent].thisYear) }}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <tr>
                  <td>
                    <div
                      class="d-flex justify-left"
                    />
                  </td>
                  <td>
                    <div
                      style="font-weight: bold;"
                    >
                      <div class="d-flex justify-space-between">
                        <span>€</span>
                        <span>{{ formatCurrency(Object.keys(homepageReport.totalSalesByArea).reduce((currentTotal, cdAgent) => { return homepageReport.totalSalesByArea[cdAgent].lastLastYear + currentTotal }, 0)) }}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      style="font-weight: bold;"
                    >
                      <div class="d-flex justify-space-between">
                        <span>€</span>
                        <span>{{ formatCurrency(Object.keys(homepageReport.totalSalesByArea).reduce((currentTotal, cdAgent) => { return homepageReport.totalSalesByArea[cdAgent].lastYear + currentTotal }, 0)) }}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      style="font-weight: bold;"
                    >
                      <div class="d-flex justify-space-between">
                        <span>€</span>
                        <span>{{ formatCurrency(Object.keys(homepageReport.totalSalesByArea).reduce((currentTotal, cdAgent) => { return homepageReport.totalSalesByArea[cdAgent].thisYear + currentTotal }, 0)) }}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '../components/charts/LineChart.js'
import AreaSelector from '../components/AreaSelector'
import AgentSelector from '../components/AgentSelector'

export default {
  name: 'Home',

  components: {
    LineChart,
    AreaSelector,
    AgentSelector
  },

  data: () => ({
    isInitializing: 2,
    confirmDialog: false,
    homepageReport: {},
    datacollection: {},
    salesCollection: {},
    visitsCollection: {},
    formattedMonth: '',
    agentsVisibleToAgent: [],
    areasVisibleToAgent: [],
    areaDefinition: [],
    filter: {
      selectedAgent: null,
      selectedArea: null,
      clientUnique: true,
      selectedDate: null
    },
    selectedDateMenu: false,
    isReloadingData: 0,
    graphHeight: 0,
    graphWidth: 0
  }),

  computed: {
    username () {
      return this.$store.getters['security/username']
    },
    isAdmin () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN')
    },
    isSupervisor () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN') || this.$store.getters['security/hasRole']('ROLE_AGENT_ADMIN')
    }
  },

  async created () {
    this.isInitializing = 4
    this.$store.dispatch('reports/getHomePageReport', this.filter).then((r) => { this.isInitializing--; this.homepageReport = r; this.fillData() })
    this.$store.dispatch('agents/agentsVisibleToAgent').then((r) => { this.isInitializing--; this.agentsVisibleToAgent = r })
    this.$store.dispatch('agents/areasVisibleToAgent').then((r) => { this.isInitializing--; this.areasVisibleToAgent = r })
    this.$store.dispatch('agents/listAreaDefinition').then((r) => { this.isInitializing--; r.forEach(a => { if (a.cd_agent) { this.areaDefinition[a.cd_agent] = a } }) })

    this.filter.selectedDate = new Date().toISOString().substr(0, 7)
  },

  methods: {
    reload () {
      this.isReloadingData = 1
      this.graphHeight = this.$refs.graph2.offsetHeight
      this.graphWidth = this.$refs.graph2.offsetWidth
      this.selectedDateMenu = false
      if (this.filter.selectedDate === new Date().toISOString().substr(0, 7)) {
        this.filter.selectedDate = null
      }
      this.$store.dispatch('reports/getHomePageReport', this.filter).then((r) => { this.isReloadingData--; this.homepageReport = r; this.fillData() })
      if (this.filter.selectedDate === null) {
        this.filter.selectedDate = new Date().toISOString().substr(0, 7)
      }
    },

    formatCurrency (number) {
      if (number === undefined) {
        number = 0
      }
      return (Number(number)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },

    getArea (cdAgent) {
      if (cdAgent === 'TTTT') return this.$t('messages.TUTTI')
      return this.areaDefinition[cdAgent]?.nome
    },

    getSelectedYear (difference) {
      const date = this.filter.selectedDate
      if (date === null) return ''

      const a = new Date(date)
      const year = a.getFullYear()
      return year + difference
    },

    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      // const day = ('0' + a.getDate()).slice(-2)
      const time = month + '/' + year
      return time
    },

    fillData () {
      const objDate = new Date(this.homepageReport.visitsClientsIncremental[0].date)
      const locale = this.$t('messages.locale')
      this.formattedMonth = objDate.toLocaleString(locale, { month: 'long' })
      this.visitsCollection = {
        labels: ['', ...this.homepageReport.salesLastYearIncremental.map((v) => v.date.slice(-2))],
        datasets: [
          {
            label: this.$t('messages.Clienti Totali'),
            backgroundColor: '#f87979',
            borderColor: '#f87979',
            fill: false,
            tension: 0,
            hidden: true,
            // pointRadius: 0,
            // drawTicks: false,
            data: [this.homepageReport.clientsTotal, ...this.homepageReport.salesLastYearIncremental.map((v) => this.homepageReport.clientsTotal)]
          },
          {
            label: this.$t('messages.Visitati'),
            borderColor: '#2559b9',
            fill: true,
            tension: 0,
            backgroundColor: '#2559b955',
            data: [0, ...this.homepageReport.visitsClientsIncremental.map((v) => v.tot)]
          },
          {
            label: this.$t('messages.Prospect'),
            borderColor: '#000000',
            fill: true,
            tension: 0,
            backgroundColor: '#00000055',
            data: [0, ...this.homepageReport.visitsProspectorsIncremental.map((v) => v.tot)]
          },
          {
            label: this.$t('messages.Serviti'),
            borderColor: '#008000',
            fill: false,
            tension: 0,
            // pointRadius: 0,
            // drawTicks: false,
            data: [0, ...this.homepageReport.clientsThisYearIncremental.map((v) => v.tot.toFixed(0))]
          },
          {
            label: this.$t('messages.Serviti2'),
            borderColor: '#becd32',
            fill: false,
            tension: 0,
            // pointRadius: 0,
            // drawTicks: false,
            data: [0, ...this.homepageReport.clientsLastYearIncremental.map((v) => v.tot.toFixed(0))]
          }
        ]
      }
      this.salesCollection = {
        labels: ['', ...this.homepageReport.salesLastYearIncremental.map((v) => v.date.slice(-2))],
        datasets: [
          {
            label: this.$t('messages.Obiettivo'),
            backgroundColor: '#38a82188',
            borderColor: '#38a82188',
            fill: false,
            tension: 0,
            // pointRadius: 0,
            // drawTicks: false,
            // data: [this.homepageReport.salesLastYearIncremental.slice(-1)[0].tot.toFixed(0), ...this.homepageReport.salesLastYearIncremental.map((v) => this.homepageReport.salesLastYearIncremental.slice(-1)[0].tot.toFixed(0))]
            data: [0, ...this.homepageReport.salesLastYearIncremental.map((v) => v.tot.toFixed(0))]
          }, {
            label: this.$t('messages.Attuale'),
            borderColor: '#2559b9',
            fill: true,
            tension: 0,
            backgroundColor: '#2559b955',
            orders: [0, ...this.homepageReport.ordersNumberThisYear.map((v) => v.val.toFixed(0))],
            data: [0, ...this.homepageReport.salesThisYearIncremental.map((v) => v.tot.toFixed(0))]
          }
        ]
      }
    }
  }

}
</script>

<style scoped>
  .small {
    max-width: 500px;
  }
  .automargin {
    margin-left: auto;
    margin-right: auto;
  }
  table, th, td {
    border: 1px solid;
    border-color: #ddd;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  table {
    border-collapse: collapse;
  }
</style>
