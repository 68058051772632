import MapsAPI from '../api/maps'

const FETCHING_CLOSE_CLIENTS = 'FETCHING_CLOSE_CLIENTS'
const FETCHING_CLOSE_CLIENTS_SUCCESS = 'FETCHING_CLOSE_CLIENTS_SUCCESS'
const FETCHING_CLOSE_CLIENTS_ERROR = 'FETCHING_CLOSE_CLIENTS_ERROR'

const MAP_FILTER_CHANGE = 'MAP_FILTER_CHANGE'
const MAP_MOVING_MARKER = 'MAP_MOVING_MARKER'

const SAVING_CLIENT_POSITION = 'SAVING_CLIENT_POSITION'
const SAVING_CLIENT_POSITION_SUCCESS = 'SAVING_CLIENT_POSITION_SUCCESS'
const SAVING_CLIENT_POSITION_ERROR = 'SAVING_CLIENT_POSITION_ERROR'

const GET_CLIENT_ADDRESS = 'GET_CLIENT_ADDRESS'
const GET_CLIENT_ADDRESS_SUCCESS = 'GET_CLIENT_ADDRESS_SUCCESS'
const GET_CLIENT_ADDRESS_ERROR = 'GET_CLIENT_ADDRESS_ERROR'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    closeClients: [],
    mapFilters: false,
    movingMarker: false
  },
  getters: {
    isLoading (state) {
      return state.isLoading
    },
    hasError (state) {
      return state.error !== null
    },
    closeClients (state) {
      return state.closeClients
    },
    error (state) {
      return state.error
    },
    errorMessage (state) {
      return state.error !== null ? state.error.response.data.detail : null
    },
    mapFilters (state) {
      return state.mapFilters
    },
    movingMarker (state) {
      return state.movingMarker
    }
  },
  mutations: {
    [FETCHING_CLOSE_CLIENTS] (state) {
      state.isLoading = true
      state.closeClients = []
      state.error = null
    },
    [FETCHING_CLOSE_CLIENTS_SUCCESS] (state, closeClients) {
      state.error = null
      state.closeClients = closeClients
      state.isLoading = false
    },
    [FETCHING_CLOSE_CLIENTS_ERROR] (state, error) {
      state.isLoading = false
      state.closeClients = []
      state.error = error
    },
    [MAP_FILTER_CHANGE] (state, data) {
      state.mapFilters = data
    },
    [MAP_MOVING_MARKER] (state, data) {
      state.movingMarker = data
    },
    [SAVING_CLIENT_POSITION] (state) {
      state.error = null
    },
    [SAVING_CLIENT_POSITION_SUCCESS] (state) {
      state.error = null
    },
    [SAVING_CLIENT_POSITION_ERROR] (state, error) {
      state.error = error
    },
    [GET_CLIENT_ADDRESS] (state) {
      state.error = null
    },
    [GET_CLIENT_ADDRESS_SUCCESS] (state) {
      state.error = null
    },
    [GET_CLIENT_ADDRESS_ERROR] (state, error) {
      state.error = error
    }

  },

  actions: {
    async getCloseClients ({ commit }, data) {
      commit(FETCHING_CLOSE_CLIENTS)
      try {
        const response = await MapsAPI.getCloseClients(data)
        commit(FETCHING_CLOSE_CLIENTS_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_CLOSE_CLIENTS_ERROR, error)
        return null
      }
    },
    async getClientsByArea ({ commit }, data) {
      commit(FETCHING_CLOSE_CLIENTS)
      try {
        const response = await MapsAPI.getClientsByArea(data)
        commit(FETCHING_CLOSE_CLIENTS_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(FETCHING_CLOSE_CLIENTS_ERROR, error)
        return null
      }
    },
    setMapFilters ({ commit }, data) {
      commit(MAP_FILTER_CHANGE, data)
    },
    setMovingMarker ({ commit }, data) {
      commit(MAP_MOVING_MARKER, data)
    },
    async saveNewPosition ({ commit }, data) {
      commit(SAVING_CLIENT_POSITION)
      try {
        const response = await MapsAPI.saveNewPosition(data)
        commit(SAVING_CLIENT_POSITION_SUCCESS)
        return response.data
      } catch (error) {
        commit(SAVING_CLIENT_POSITION_ERROR, error)
        return null
      }
    },
    async getClientAddress ({ commit }, data) {
      commit(GET_CLIENT_ADDRESS)
      try {
        const response = await MapsAPI.getClientAddress(data)
        commit(GET_CLIENT_ADDRESS_SUCCESS)
        return response.data
      } catch (error) {
        commit(GET_CLIENT_ADDRESS_ERROR, error)
        return null
      }
    }
  }
}
