import axios from 'axios'

export default {
  details (clientId) {
    return axios.get('/api/clientdetails/details/' + clientId)
  },
  update (data) {
    return axios.post('/api/clientdetails/update/', data)
  },
  merge (data) {
    return axios.post('/api/clientdetails/merge/', data)
  },
  mergeProjx (data) {
    return axios.post('/api/clientdetails/merge-projx', data)
  },
  devicesList () {
    return axios.get('/api/clientdetails/devicesList/')
  }
}
