import axios from 'axios'

export default {
  list ({ listDeleted = false, isProjx = false }) {
    let requestPath = null
    requestPath = '/api/clients/list?t=0'
    if (listDeleted === true) {
      requestPath += '&listDeleted=1'
    }
    if (isProjx === true) {
      requestPath += '&isProjx=1'
    }
    return axios.get(requestPath)
  },
  listMinimal ({ isProjx = false }) {
    let requestPath = null
    requestPath = '/api/clients/list-minimal'
    if (isProjx === true) {
      requestPath += '?isProjx=1'
    }
    return axios.get(requestPath)
  },
  listMinimalInactiveFeedbacks () {
    return axios.get('/api/clients/list-minimal-inactive-feedbacks')
  },
  create (data) {
    return axios.post('/api/clients/create', data)
  },
  delete (data) {
    return axios.post('/api/clients/delete', data)
  },
  restore (data) {
    return axios.post('/api/clients/restore', data)
  },
  deletePerma (data) {
    return axios.post('/api/clients/delete-permanently', data)
  },
  addToMicroArea (data) {
    return axios.post('/api/clients/addtomicroarea', data)
  },
  setForcedToMap (data) {
    return axios.post('/api/clients/set-forced-to-map', data)
  }
}
