import axios from 'axios'

export default {
  listListini () {
    return axios.get('/api/listini/list')
  },
  get () {
    return axios.get('/api/listini/get')
  }
}
