export default {
  it: {
    shop: {
      cart: 'Vai al Carrello',
      colors_indicative: 'ATTENZIONE: I colori a monitor sono puramente indicativi',
      scheda_tecnica: 'Scheda Tecnica',
      scheda_safety: 'Scheda di Sicurezza',
      catalogo: 'Catalogo',
      istruzioni: 'Istruzioni di utilizzo',
      approfondimento: 'Approfondimento',
      gallery: 'Gallery',
      orders_denied: 'L\'account non è abilitato ad effettuare ordini',
      item: 'Articolo',
      misure: 'Misure',
      quantity: 'Quantità',
      height: 'Altezza',
      length: 'Lunghezza',
      type: 'Tipo',
      cut_instructions: 'Tagli particolari',
      item_added: 'Articolo aggiunto',
      add_to_cart: 'AGGIUNGI AL CARRELLO',
      description: 'DESCRIZIONE',
      forato: 'Forato',
      non_forato: 'Non Forato',
      select_height: 'Selezionare un\'altezza',
      select_length: 'Selezionare una lunghezza',
      select_type: 'Selezionare una tipologia',
      suggested_products: 'PRODOTTI SUGGERITI',
      continue_shopping: 'CONTINUA I TUOI ACQUISTI OPPURE ',
      intresting_products: 'TI POTREBBE INTERESSARE ANCHE...',
      parent_article_string: 'Questo articolo si trova in:'

    }
  },
  en: {
    shop: {
      cart: 'Go to shopping basket',
      colors_indicative: 'ATTENTION: The colours displayed are purely indicative',
      scheda_tecnica: 'Data sheet',
      scheda_safety: 'Safety data Sheet',
      catalogo: 'Catalog',
      istruzioni: 'Instructions for use',
      approfondimento: 'Technical details',
      gallery: 'Gallery',
      orders_denied: 'The account is not authorised to place orders',
      item: 'Item',
      misure: 'Size',
      quantity: 'Quantity',
      height: 'Height',
      length: 'Length',
      type: 'Type',
      cut_instructions: 'Cut/Slit instructions',
      item_added: 'Item added',
      add_to_cart: 'ADD TO SHOPPING BASKET',
      description: 'DESCRIPTION',
      forato: 'Sprocketed',
      non_forato: 'Non sprocketed',
      select_height: 'Select height',
      select_length: 'Select length',
      select_type: 'Select type',
      suggested_products: 'SUGGESTED PRODUCTS',
      continue_shopping: 'CONTINUE WITH YOUR ORDER OR ',
      intresting_products: 'YOU MAY ALSO BE INTERESTED IN ...',
      parent_article_string: 'You can find this product in:'
    }
  },
  fr: {
    shop: {
      cart: 'Aller au panier',
      colors_indicative: 'ATTENTION: les couleurs sur le moniteur sont purement indicatives',
      scheda_tecnica: 'Fiche technique',
      scheda_safety: 'Fiche de données de sécurité',
      catalogo: 'Nuancier',
      istruzioni: 'Mode d\'emploi',
      approfondimento: 'Approfondissement',
      gallery: 'Galerie',
      orders_denied: 'Le compte n\'est pas autorisé à passer des commandes',
      item: 'Article',
      misure: 'Mesures',
      quantity: 'Quantité',
      height: 'Laize',
      length: 'Longueur',
      type: 'Catégorie',
      cut_instructions: 'Coupe du film',
      item_added: 'Article ajouté',
      add_to_cart: 'AJOUTER AU PANIER',
      description: 'DESCRIPTION',
      forato: 'Percé',
      non_forato: 'Non Percé',
      select_height: 'Sélectionnez la laize',
      select_length: 'Sélectionnez la longueur',
      select_type: 'Sélectionnez la catégorie',
      suggested_products: 'PRODUITS RECOMMANDÉS',
      continue_shopping: 'CONTINUEZ VOS ACHATS OU ',
      intresting_products: 'VOUS POUVEZ ÉGALEMENT ÊTRE INTÉRESSÉ PAR ...',
      parent_article_string: 'Cet article se trouve dans:'
    }
  },
  es: {
    shop: {
      cart: 'Ir al carrito',
      colors_indicative: 'ATENCIÓN: Los colores en la pantalla son puramente indicativos',
      scheda_tecnica: 'Ficha técnica',
      scheda_safety: 'Ficha de datos de seguridad',
      catalogo: 'Catálogo',
      istruzioni: 'Instrucciones de uso',
      approfondimento: 'Saber más',
      gallery: 'Galería',
      orders_denied: 'La cuenta no está autorizada para realizar pedidos.',
      item: 'Artículo',
      misure: 'Medidas',
      quantity: 'Cantidad',
      height: 'Altura',
      length: 'Longitud',
      type: 'Tipo',
      cut_instructions: 'Cortes particulares',
      item_added: 'Artículo agregado',
      add_to_cart: 'AÑADIR AL CARRITO',
      description: 'DESCRIPCIÓN',
      forato: 'Perforado',
      non_forato: 'No Perforado',
      select_height: 'Seleccione una altura',
      select_length: 'Seleccione una longitud',
      select_type: 'Seleccione un tipo',
      suggested_products: 'PRODUCTOS SUGERIDOS',
      continue_shopping: 'CONTINÚE SUS COMPRAS O',
      intresting_products: 'USTED TAMBIÉN PODRÍA ESTAR INTERESADO EN ...',
      parent_article_string: 'Este articulo se encuentra en:'
    }
  },
  de: {
    shop: {
      cart: 'Zum Warenkorb',
      colors_indicative: 'ACHTUNG: die Farben auf dem Monitor sind nur Richtwerte',
      scheda_tecnica: 'Datenblatt',
      scheda_safety: 'Sicherheitsblatt',
      catalogo: 'Katalog',
      istruzioni: 'Gebrauchsanleitungen',
      approfondimento: 'Erweiterung',
      gallery: 'Galerie',
      orders_denied: 'Das Konto ist nicht berechtigt, Bestellungen aufzugeben',
      item: 'Artikel',
      misure: 'Abmessungen',
      quantity: 'Menge',
      height: 'Breite',
      length: 'Länge',
      type: 'Typ',
      cut_instructions: 'Zuschnitt',
      item_added: 'hinzugefügter Artikel',
      add_to_cart: 'FÜGE ZUM WARENKORB',
      description: 'BESCHREIBUNG',
      forato: 'Gelocht',
      non_forato: 'Ungelocht',
      select_height: 'Breite wählen',
      select_length: 'Länge wählen',
      select_type: 'Typ wählen',
      suggested_products: 'EMPFOHLENE PRODUKTE',
      continue_shopping: 'ZURÜCK ZUM KAUF ODER ',
      intresting_products: 'DAS KÖNNTE DICH AUCH INTERESSIEREN ...',
      parent_article_string: 'Dieser Artikel befindet sich in:'
    }
  },
  nl: {
    shop: {
      cart: 'Ga naar de winkelwagen',
      colors_indicative: 'LET OP: De kleuren op de monitor zijn louter indicatief',
      scheda_tecnica: 'Technische gegevens',
      scheda_safety: 'Veiligheidsvoorschriften',
      catalogo: 'Catalogus',
      istruzioni: 'Gebruiksaanwijzing',
      approfondimento: 'Uitwijding',
      gallery: 'Galerij',
      orders_denied: 'Het account is niet geautoriseerd om bestellingen te plaatsen',
      item: 'Artikel',
      misure: 'Afmetingen',
      quantity: 'Aantal',
      height: 'Hoogte',
      length: 'Lengte',
      type: 'Type',
      cut_instructions: 'Gesneden',
      item_added: 'Artikel toegevoegd',
      add_to_cart: 'VOEG TOE AAN DE WINKELWAGEN',
      description: 'BESCHRIJVING',
      forato: 'Geperforeerd',
      non_forato: 'Niet geperforeerd',
      select_height: 'Kies een hoogte',
      select_length: 'Kies een lengte',
      select_type: 'Kies een type',
      suggested_products: 'GESUGGEREERDE PRODUCTEN',
      continue_shopping: 'GA DOOR MET UW BESTELLING OF ',
      intresting_products: 'MOGELIJK HEBT U OOK INTERESSE IN...',
      parent_article_string: 'Dit artikel bevindt zich in:'
    }
  }
}
