import lazy from './utils/lazy-value'

// export default function promiseLazy(loadGmapApi, GmapApi) {
export default function promiseLazy (loadGmapApi) {
  // If library should load the API
  return lazy(() => {
    return new Promise((resolve) => {
      try {
        window.googleMapsInit = resolve
        loadGmapApi({ key: 'AIzaSyA8yDWMI7-ASeQ0FJrDrZWgUsWXvNH2bRE' }, false)
      } catch (err) {
        resolve()
      }
    })
  })
}
