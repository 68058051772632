<template>
  <div class="container">
    <v-progress-circular
      v-if="isInitializing != 0"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <v-btn
        color="#217346"
        dark
        class="btn btn-lg"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-1' : 'ml-2'"
        type="button"
        elevation="0"
        tile
        :loading="isLoadingDatabase"
        @click="doSyncDatabase"
      >
        {{ $t('messages.Sync Users') }}
      </v-btn>
      <v-data-table
        v-model="selected"
        :search="search"
        :items="users"
        :mobile-breakpoint="0"
        :headers="headers"
        dense
      >
        <template #top>
          <v-text-field
            v-model="search"
            :label="$t('messages.Ricerca')"
            class="mx-4"
          />
        </template>
        <template #[`item.is_agent`]="{ item }">
          <v-icon
            v-if="item.is_agent == 1"
            color="primary"
          >
            mdi-badge-account-horizontal
          </v-icon>
          <v-icon
            v-if="item.is_agent == 1 && item.is_agent_admin == 1"
            color="primary"
          >
            mdi-shield-star
          </v-icon>
        </template>
        <template #[`item.listini`]="{ item }">
          <v-autocomplete
            :value="item.listini ? item.listini.split(';'):[]"
            :items="listini"
            :loading="isLoadingListini || ((item.isUpdatingListini) > 0)"
            :menu-props="{
              maxHeight:200,
            }"
            hide-details
            multiple
            dense
            label=""
            item-value="id"
            item-text="name"
            @input="updateAgentListino(item, $event)"
          >
            <template #[`item`]="appearance">
              {{ appearance.item.name }}
            </template>
            <template #[`selection`]="selection">
              <v-chip
                class="mx-1 white--text"
                color="success"
                small
              >
                {{ selection.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </template>
        <template #[`item.nations`]="{ item }">
          <v-autocomplete
            :value="item.nations ? item.nations.split(';'):[]"
            :items="nations"
            :loading="isLoadingNations || ((item.isUpdating) > 0)"
            :menu-props="{
              maxHeight:200,
            }"
            hide-details
            multiple
            dense
            label=""
            item-value="isocode"
            item-text="name"
            @input="updateAgentNation(item, $event)"
          >
            <template #[`item`]="appearance">
              {{ appearance.item.name }}
            </template>
            <template #[`selection`]="selection">
              <v-chip
                class="mx-1 white--text"
                color="success"
                small
              >
                {{ selection.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-icon
            v-if="item.is_agent == 1 && item.is_agent_admin == 0"
            color="error"
            @click="removeAgent(item)"
          >
            mdi-account-remove
          </v-icon>
          <v-icon
            v-if="item.is_agent == 0"
            color="primary"
            @click="addAgent(item)"
          >
            mdi-account-plus
          </v-icon>
          <v-icon
            v-if="item.is_agent == 1 && item.is_agent_admin == 0"
            color="primary"
            @click="upgradeAgent(item)"
          >
            mdi-chevron-double-up
          </v-icon>
          <v-icon
            v-if="item.is_agent == 1 && item.is_agent_admin == 1"
            color="error"
            @click="downgradeAgent(item)"
          >
            mdi-chevron-double-down
          </v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AgentAssign',
  data: () => ({
    isInitializing: 1,
    search: '',
    selected: [],
    nations: [],
    isLoadingNations: true,
    listini: [],
    isLoadingListini: true,
    isLoadingDatabase: false
  }),

  computed: {
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    users () {
      return this.$store.getters['agents/users']
    },
    isLoading () {
      return this.$store.getters['agents/isLoading']
    },
    headers () {
      return [
        { text: this.$t('messages.Login'), value: 'user_login', filterable: true },
        { text: this.$t('messages.Agente'), value: 'is_agent', filterable: true },
        { text: this.$t('messages.Listini'), value: 'listini', filterable: true },
        { text: this.$t('messages.Nazioni'), value: 'nations', filterable: true },
        { text: this.$t('messages.Azioni'), value: 'actions', filterable: true }
      ]
    }
  },

  async created () {
    this.isInitializing = 1
    this.$store.dispatch('agents/listUsers').then(() => this.isInitializing--)
    this.$store.dispatch('nations/get').then((r) => { this.nations = r; this.isLoadingNations = false })
    this.$store.dispatch('listini/get').then((r) => { this.listini = r; this.isLoadingListini = false })
  },

  methods: {
    async doSyncDatabase () {
      this.isLoadingDatabase = true
      const result = await this.$store.dispatch('importer/sendCommand', 'syncUsers')
      if (result === null) {
        const dataMessage = { isOpen: true, message: this.$store.getters['importer/error']('syncUsers'), color: 'error' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      } else {
        this.isInitializing = 1
        this.$store.dispatch('agents/listUsers').then(() => this.isInitializing--)
      }
      this.isLoadingDatabase = false
    },
    async removeAgent (user) {
      const data = { isAgent: false, userId: user.id }
      const r = await this.$store.dispatch('agents/setUserAgent', data)
      if (r) {
        user.is_agent = 0
      }
    },
    async addAgent (user) {
      const data = { isAgent: true, userId: user.id }
      const r = await this.$store.dispatch('agents/setUserAgent', data)
      if (r) {
        user.is_agent = 1
      }
    },
    async downgradeAgent (user) {
      const data = { isAgentAdmin: false, userId: user.id }
      const r = await this.$store.dispatch('agents/setUserAgent', data)
      if (r) {
        user.is_agent_admin = 0
      }
    },
    async upgradeAgent (user) {
      const data = { isAgentAdmin: true, userId: user.id }
      const r = await this.$store.dispatch('agents/setUserAgent', data)
      if (r) {
        user.is_agent_admin = 1
      }
    },

    async updateAgentNation (item, nations) {
      item.isUpdating = (item.isUpdating ?? 0) + 1
      const data = { nations: nations, userId: item.id }
      await this.$store.dispatch('agents/setNations', data)
      item.nations = nations.join(';')
      item.isUpdating--
    },

    async updateAgentListino (item, listini) {
      item.isUpdatingListini = (item.isUpdatingListini ?? 0) + 1
      const data = { listini: listini, userId: item.id }
      await this.$store.dispatch('agents/setListini', data)
      item.listini = listini.join(';')
      item.isUpdatingListini--
    }

  }
}
</script>
