import OrdersAPI from '../api/orders'

const SENDING_ORDERS = 'SENDING_ORDERS'
const SENDING_ORDERS_SUCCESS = 'SENDING_ORDERS_SUCCESS'
const SENDING_ORDERS_ERROR = 'SENDING_ORDERS_ERROR'

const CART = 'CART'
const CART_SUCCESS = 'CART_SUCCESS'
const CART_ERROR = 'CART_ERROR'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    orders: []
  },
  getters: {
    isLoading (state) {
      return state.isLoading
    },
    error (state) {
      return state.error
    },
    errorMessage (state) {
      return state.error.response.data.detail
    }
  },
  mutations: {
    [SENDING_ORDERS] (state) {
      state.isLoading = true
      state.orders = []
      state.error = null
    },
    [SENDING_ORDERS_SUCCESS] (state, data) {
      state.isLoading = false
      state.error = null
    },
    [SENDING_ORDERS_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
    [CART] (state) {
      state.isLoading = true
      state.error = null
    },
    [CART_SUCCESS] (state) {
      state.isLoading = false
      state.error = null
    },
    [CART_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    }
  },

  actions: {
    async send ({ commit }, data) {
      commit(SENDING_ORDERS)
      try {
        const response = await OrdersAPI.send(data)
        commit(SENDING_ORDERS_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(SENDING_ORDERS_ERROR, error)
        return null
      }
    },
    async sendcampionatura ({ commit }, data) {
      commit(SENDING_ORDERS)
      try {
        const response = await OrdersAPI.sendcampionatura(data)
        commit(SENDING_ORDERS_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(SENDING_ORDERS_ERROR, error)
        return null
      }
    },
    async saveCartCampionatura ({ commit }, data) {
      commit(CART)
      try {
        const response = await OrdersAPI.saveCartCampionatura(data)
        commit(CART_SUCCESS)
        return response.data
      } catch (error) {
        commit(CART_ERROR, error)
        return null
      }
    },
    async fetchCartCampionatura ({ commit }, data) {
      commit(CART)
      try {
        const response = await OrdersAPI.fetchCartCampionatura(data)
        commit(CART_SUCCESS)
        return response.data
      } catch (error) {
        commit(CART_ERROR, error)
        return null
      }
    }
  }
}
