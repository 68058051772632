import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    width: {
      default: 500,
      type: Number
    },
    height: {
      default: 500,
      type: Number
    }
  },
  data: () => ({
  }),
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    const label1 = this.$t('messages.Visitati')
    const label2 = this.$t('messages.Attuale')
    const label3 = this.$t('messages.Obiettivo')
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top'
        },
        title: {
          display: true,
          text: 'Chart.js Line Chart'
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      },
      tooltips: {
        filter: function (tooltipItem, data) {
          const datasetLabel = data.datasets[tooltipItem.datasetIndex].label
          if (datasetLabel === label1 || datasetLabel === label2 || datasetLabel === label3) {
            return true
          } else {
            return true
          }
        },
        callbacks: {
          label: (tooltipItems, data) => {
            // console.log(this)
            // console.log(tooltipItems)
            // console.log(data)
            // tooltipItems.datasetIndex
            // tooltipItems.index
            // tooltipItems.yLabel
            // data.datasets[tooltipItems.datasetIndex].label -- "Attuale"
            // data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] -- Valore

            const formatCurrency = (number) => {
              if (number === undefined) {
                number = 0
              }
              return (Number(number)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            }

            if (data.datasets[tooltipItems.datasetIndex].label === label2) { // Attuale
              const fatturato = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
              const differenza = tooltipItems.index === 0 ? data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] : data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] - data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index - 1]
              return 'Tot: ' + formatCurrency(fatturato) + ' - Day: ' + formatCurrency(differenza) + ' - Ord: ' + data.datasets[tooltipItems.datasetIndex].orders[tooltipItems.index]
            }

            if (data.datasets[tooltipItems.datasetIndex].label === label3) { // Obiettivo
              return data.datasets[tooltipItems.datasetIndex].label + ': ' + formatCurrency(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index])
            }
            return data.datasets[tooltipItems.datasetIndex].label + ': ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
            // return tooltipItems.yLabel + '£' + 'hallo'
          }
        }
      }
    }
    this.renderChart(this.chartData, options)
  }
}
