<template>
  <div>
    <div ref="flyaway">
      <!-- so named because it will fly away to another component -->
      <!-- <slot> </slot> -->
      <div
        class="d-flex justify-center "
      >
        <div
          class="container-info"
          style="background-color:rgb(37, 89, 185)"
        >
          <v-rating
            length="3"
            color="white"
            class="mb-n2"
            size="24"
            readonly
            :value="parseInt(client.stars)"
          />
        </div>
      </div>
      <div class="d-flex justify-center mt-1 mb-2">
        <router-link
          :to="'/client/details/' + client.id"
        >
          <h1>{{ client.azienda }}</h1>
        </router-link>
      </div>
      <div class="d-flex justify-center mb-2">
        <h5>{{ client.indirizzo }}, {{ client.cap }} {{ client.citta }} ({{ client.provincia }})</h5>
      </div>

      <div class="d-flex justify-center align-center mb-2">
        <h5
          style="width: 97px; text-align:end"
          class="pr-2"
        >
          {{ $t('messages.ultima visita') }}:
        </h5>
        <h5 style="width: 97px">
          {{ formatDate(client.last_visit) }}
          <v-icon
            v-if="client.last_visit_rating"
            :size="22"
            :color="lastVisitRatingIcon(client.last_visit_rating).color"
          >
            {{ lastVisitRatingIcon(client.last_visit_rating).icon }}
          </v-icon>
        </h5>
      </div>

      <div class="d-flex justify-center mb-2">
        <h5
          style="width: 56px; text-align:end"
          class="pr-2"
        >
          {{ $t('messages.ordine') }}:
        </h5>
        <h5 style="width: 56px">
          {{ formatDate(client.last_order) }}
        </h5>
      </div>

      <div
        v-if="!isMoving"
        class="d-flex justify-space-around mt-2"
      >
        <div
          class="d-flex flex-column align-center"
          style="cursor: pointer; width: 36px"
          @click="startMoving"
        >
          <v-btn
            icon
            color="primary"
            :disabled="globalMovingMarker"
          >
            <v-icon>
              mdi-cursor-move
            </v-icon>
          </v-btn>
          <h5>{{ $t('messages.Sposta') }}</h5>
        </div>
        <v-divider vertical />
        <div
          v-if="filterGiro"
          class="d-flex flex-column align-center"
          style="cursor: pointer; width: 36px"
          @click="setVisited"
        >
          <v-btn
            icon
            :color="client.visited ? 'primary' : '#E0E0E0'"
          >
            <v-icon>
              mdi-check-bold
            </v-icon>
          </v-btn>
          <h5>{{ $t('messages.Visitato') }}</h5>
        </div>
        <v-divider
          v-if="filterGiro"
          vertical
        />
        <div
          class="d-flex flex-column align-center"
          style="cursor: pointer; width: 36px"
          @click="openInMaps"
        >
          <v-btn
            icon
            color="primary"
          >
            <v-icon>
              mdi-car
            </v-icon>
          </v-btn>
          <h5>{{ $t('messages.Drive') }}</h5>
        </div>
      </div>
      <div
        v-else
        class="d-flex justify-space-around mt-2"
      >
        <div
          class="d-flex flex-column align-center"
          style="cursor: pointer; width: 36px"
          @click="endMoving(false)"
        >
          <v-btn
            icon
            color="red"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
          <h5>{{ $t('messages.Annulla') }}</h5>
        </div>
        <v-divider vertical />
        <div
          class="d-flex flex-column align-center"
          style="cursor: pointer; width: 36px"
          @click="endMoving(true)"
        >
          <v-btn
            icon
            color="primary"
            :loading="isSaving"
          >
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <h5>{{ $t('messages.Salva') }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    map: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      required: true
    },
    filterGiro: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    infowindow: null,
    oldPosition: null,
    isMoving: false,
    isSaving: false
  }),

  computed: {
    globalMovingMarker () {
      return this.$store.getters['map/movingMarker']
    }

  },

  watch: {
    value (newVal) {
      if (newVal) {
        this.infowindow.open(this.map, this.client.marker)
      } else {
        this.infowindow.close()
        this.endMoving(false)
      }
    }
  },

  mounted () {
    /* eslint-disable no-undef */
    this.infowindow = new google.maps.InfoWindow({
      content: this.$refs.flyaway
    })
    google.maps.event.addListener(this.infowindow, 'closeclick', () => {
      this.$emit('input', false)
      this.endMoving(false)
    })
    /* eslint-enable no-undef */
    const el = this.$refs.flyaway
    el.parentNode.removeChild(el)
    if (this.value) {
      this.infowindow.open(this.map, this.client.marker)
    }
  },

  beforeDestroy () {
    if (this.isMoving) {
      this.client.marker.setPosition(this.oldPosition)
      this.client.marker.setDraggable(false)
      this.$store.dispatch('map/setMovingMarker', false)
      this.isMoving = false
    }
    /* eslint-disable no-undef */
    this.infowindow.setMap()
    this.infowindow = null
  },

  methods: {

    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    },

    lastVisitRatingIcon (value) {
      switch (value) {
      case '1':
        return { icon: 'mdi-emoticon-sad', color: 'error' }
      case '2':
        return { icon: 'mdi-emoticon-neutral', color: 'amber darken-2' }
      case '3':
        return { icon: 'mdi-emoticon', color: 'success' }
      default:
        return { icon: 'mdi-border-none-variant', color: 'secondary' }
      }
    },

    startMoving () {
      if (!this.globalMovingMarker) {
        this.$store.dispatch('map/setMovingMarker', true)
        this.oldPosition = {
          lat: this.client.marker.position.lat(),
          lng: this.client.marker.position.lng()
        }
        this.client.marker.setDraggable(true)
        this.isMoving = true
      }
    },
    setVisited () {
      this.$emit('visited', this.client)
    },
    openInMaps () {
      const win = window.open('https://www.google.com/maps?daddr=' + this.client.marker.position.lat() + ',' + this.client.marker.position.lng())
      if (win) {
        // Browser has allowed it to be opened
        win.focus()
      } else {
        // Browser has blocked it
        alert('Permetti i popup per questo sito')
      }
    },
    async endMoving (save) {
      if (this.isSaving || !this.isMoving) return
      if (save) {
        const data = {
          clientId: this.client.id,
          indirizzoId: this.client.indirizzo_id,
          position: {
            lat: this.client.marker.position.lat(),
            lng: this.client.marker.position.lng()
          }
        }
        this.isSaving = true
        const r = await this.$store.dispatch('map/saveNewPosition', data)
        if (!r) {
          // errore
          const dataMessage = { isOpen: true, message: this.$t('messages.si è verificato un errore'), color: 'error' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          this.isSaving = false
        } else {
          // salvato ok
          const dataMessage = { isOpen: true, message: this.$t('messages.La posizione è stata aggiornata'), color: 'success' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
          this.isSaving = false
          this.client.marker.setDraggable(false)
          this.isMoving = false
          this.$store.dispatch('map/setMovingMarker', false)
        }
      } else {
        this.client.marker.setPosition(this.oldPosition)
        this.client.marker.setDraggable(false)
        this.$store.dispatch('map/setMovingMarker', false)
        this.isMoving = false

        /* eslint-disable vue/no-mutating-props */
        this.client.label.position = this.client.marker.position
        /* eslint-enable vue/no-mutating-props */
      }
    }
  }
}
</script>

<style scoped>
.container-info {
    margin-top: -10px;
    padding:4px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
</style>
