<template>
  <div class="container px-0 px-sm-3">
    <div class="d-flex align-center justify-space-around ml-1">
      <h1>{{ $t('messages.Visite Cliente') }}</h1>
    </div>
    <v-progress-circular
      v-if="isLoading"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <div
        v-if="reportClientVisits"
      >
        <v-btn
          v-if="isAdmin"
          color="#e74c3c"
          dark
          class="btn btn-lg"
          type="button"
          elevation="0"
          tile
          :loading="isLoading"
          @click="esportaPDF"
        >
          <v-icon>
            mdi-file-pdf-outline
          </v-icon>
          {{ $t('messages.Esporta in PDF') }}
        </v-btn>
        <h3>
          {{ $t('messages.Evidenzia') }}:
        </h3>
        <v-radio-group
          v-model="highlight"
          class="mt-0"
          hide-details
          row
        >
          <v-radio
            label="Anno Precedente"
            value="last"
          />
          <v-radio
            label="Anno Corrente"
            value="now"
          />
        </v-radio-group>
        <div>
          <span class="reportval highlight">
            {{ $t('messages.Clienti') }} <span class="reportprospector">+{{ $t('messages.Prospect') }}</span>
          </span>

          <v-dialog
            v-model="infoDialog"
            width="500"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="align-center justify-center"
                color="grey"
                icon
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-information
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>{{ $t('messages.Info') }}</v-card-title>
              <v-card-text>
                <span class="reportval highlight">{{ $t('messages.Attivi') }}:</span> {{ $t('messages.numero di clienti che hanno fatto un ordine negli ultimi 3 anni') }} <br>
                <span class="reportval highlight">{{ $t('messages.Numero Nero') }}:</span> {{ $t('messages.numero di clienti univoci visitati nel periodo di tempo selezionato (a prescidere dal fatti che questi abbiano effettuato un ordine negli ultimi 3 anni)') }}<br>
                <span
                  class="reportval highlight"
                  style="color:rgba(37, 89, 185, 0.719)"
                >{{ $t('messages.Numero Blu') }}</span> {{ $t('messages.numero di prospect univoci visitati nel periodo di tempo selezionato') }}
              </v-card-text>
              <v-card-actions>
                <v-col class="text-right">
                  <v-btn
                    tile
                    color="primary"
                    elevation="0"
                    @click="infoDialog = false"
                  >
                    {{ $t('messages.Chiudi') }}
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <div
          style="overflow-x: auto; overflow-y: hidden;"
          class="mx-1"
        >
          <table class="mt-1">
            <thead>
              <tr>
                <td class="total-row">
                  <div class="d-flex flex-column">
                    <span class="report-area">
                      {{ $t('messages.TOTALE') }}
                    </span>
                    <span class="report-area">
                      {{ $t('messages.attivi') }}: {{ filteredReport.clientsTotal }}
                    </span>
                  </div>
                </td>
                <td
                  class="text-right total-row"
                >
                  <div class="d-flex flex-column">
                    <span
                      class="reportval"
                      :class="highlight=='last' ? 'highlight':''"
                    >{{ filteredReport.years[0].year }}
                    </span>
                    <span
                      class="reportval"
                      :class="highlight=='now' ? 'highlight':''"
                    >{{ filteredReport.years[1].year }}
                    </span>
                  </div>
                </td>
                <td
                  class="text-right total-row"
                >
                  <div class="d-flex flex-column">
                    <span
                      class="reportval"
                      :class="highlight=='last' ? 'highlight':''"
                    >{{ filteredReport.years[0].clientsVisited }} <span class="reportprospector">+{{ filteredReport.years[0].prospectorVisited }}</span>
                    </span>
                    <span
                      class="reportval"
                      :class="highlight=='now' ? 'highlight':''"
                    >{{ filteredReport.years[1].clientsVisited }} <span class="reportprospector">+{{ filteredReport.years[1].prospectorVisited }}</span>
                    </span>
                  </div>
                </td>
                <td
                  v-for="(n, i) in 12"
                  :key="i"
                  class="text-right"
                >
                  <div class="d-flex flex-column">
                    <span
                      class="reportval"
                      :class="highlight=='last' ? 'highlight':''"
                    >{{ filteredReport.years[0].months[i].clientsVisited }} <span class="reportprospector">+{{ filteredReport.years[0].months[i].prospectorVisited }}</span>
                    </span>
                    <span
                      class="reportval"
                      :class="highlight=='now' ? 'highlight':''"
                    >{{ filteredReport.years[1].months[i].clientsVisited }} <span class="reportprospector">+{{ filteredReport.years[1].months[i].prospectorVisited }}</span>
                    </span>
                  </div>
                </td>
              </tr>

              <tr>
                <th
                  v-for="(hr, l) in headers"
                  :key="l"
                  :class="hr.class"
                  :style="hr.width?('min-width: ' + hr.width):''"
                >
                  {{ hr.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(area, m) in filteredReport.areas"
                :key="m"
              >
                <td>
                  <div class="d-flex flex-column">
                    <span class="report-area">
                      {{ area.area }} {{ getArea(area.area) }}
                    </span>
                    <span class="report-area">
                      {{ $t('messages.attivi') }}: {{ area.clientsTotal }}
                    </span>
                  </div>
                </td>
                <td
                  class="text-right"
                >
                  <div class="d-flex flex-column">
                    <span
                      class="reportval"
                      :class="highlight=='last' ? 'highlight':''"
                    >{{ area.years[0].year }}
                    </span>
                    <span
                      class="reportval"
                      :class="highlight=='now' ? 'highlight':''"
                    >{{ area.years[1].year }}
                    </span>
                  </div>
                </td>
                <td
                  class="text-right"
                >
                  <div class="d-flex flex-column">
                    <span
                      class="reportval"
                      :class="highlight=='last' ? 'highlight':''"
                    >{{ area.years[0].clientsVisited }} <span class="reportprospector">+{{ area.years[0].prospectorVisited }}</span>
                    </span>
                    <span
                      class="reportval"
                      :class="highlight=='now' ? 'highlight':''"
                    >{{ area.years[1].clientsVisited }} <span class="reportprospector">+{{ area.years[1].prospectorVisited }}</span>
                    </span>
                  </div>
                </td>
                <td
                  v-for="(n, i) in 12"
                  :key="i"
                  class="text-right"
                >
                  <div class="d-flex flex-column">
                    <span
                      class="reportval"
                      :class="highlight=='last' ? 'highlight':''"
                    >{{ area.years[0].months[i].clientsVisited }} <span class="reportprospector">+{{ area.years[0].months[i].prospectorVisited }}</span>
                    </span>
                    <span
                      class="reportval"
                      :class="highlight=='now' ? 'highlight':''"
                    >{{ area.years[1].months[i].clientsVisited }} <span class="reportprospector">+{{ area.years[1].months[i].prospectorVisited }}</span>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
  name: 'ReportClientVisits',
  data: () => ({
    reportClientVisits: null,
    search: '',
    isLoading: false,
    groupedByArea: false,
    filteredReport: [],
    areaDefinition: [],
    highlight: 'now',
    infoDialog: false,
    todayDate: new Date().toISOString().substr(0, 10)
  }),

  computed: {
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    isAdmin () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN')
    },
    headers () {
      return [
        { text: this.$t('messages.Area'), value: 'area' },
        { text: '', value: 'leg', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Tot'), value: 'tot1', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Gen'), value: 'gen1', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Feb'), value: 'feb1', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Mar'), value: 'mar1', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Apr'), value: 'apr1', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Mag'), value: 'mag1', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Giu'), value: 'giu1', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Lug'), value: 'lug1', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Ago'), value: 'ago1', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Set'), value: 'set1', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Ott'), value: 'ott1', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Nov'), value: 'nov1', align: 'right', width: '60px', filterable: false },
        { text: this.$t('messages.Dic'), value: 'dic1', align: 'right', width: '60px', filterable: false }
      ]
    }
  },

  async created () {
    await this.$store.dispatch('agents/listAreaDefinition').then((r) => {
      r.forEach(a => {
        if (a.cd_agent) { this.areaDefinition[a.cd_agent] = a }
      })
    })
    this.getReportVisiteCliente()
  },

  methods: {
    async esportaPDF () {
      // var body = []
      const d1 = this.todayDate
      const datestring = this.formatDate(d1)

      // let doc = new JsPDF('l') // orizzontale --- new JsPDF() // verticale
      const doc = new JsPDF('l')
      const totalPagesExp = '{total_pages_count_string}'

      const body = []
      let rowToPush = []
      let total = null

      total = this.filteredReport
      rowToPush = [(this.$t('messages.TOTALE') + '\n' + this.$t('messages.attivi') + ': ' + (total.clientsTotal ?? '')),
        parseFloat(total.years[0].year) + '\n' + parseFloat(total.years[1].year),
        parseFloat(total.years[0].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[0].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(total.years[1].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[1].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        parseFloat(total.years[0].months[0].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[0].months[0].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(total.years[1].months[0].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[1].months[0].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        parseFloat(total.years[0].months[1].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[0].months[1].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(total.years[1].months[1].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[1].months[1].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        parseFloat(total.years[0].months[2].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[0].months[2].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(total.years[1].months[2].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[1].months[2].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        parseFloat(total.years[0].months[3].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[0].months[3].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(total.years[1].months[3].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[1].months[3].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        parseFloat(total.years[0].months[4].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[0].months[4].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(total.years[1].months[4].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[1].months[4].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        parseFloat(total.years[0].months[5].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[0].months[5].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(total.years[1].months[5].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[1].months[5].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        parseFloat(total.years[0].months[6].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[0].months[6].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(total.years[1].months[6].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[1].months[6].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        parseFloat(total.years[0].months[7].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[0].months[7].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(total.years[1].months[7].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[1].months[7].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        parseFloat(total.years[0].months[8].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[0].months[8].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(total.years[1].months[8].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[1].months[8].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        parseFloat(total.years[0].months[9].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[0].months[9].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(total.years[1].months[9].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[1].months[9].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        parseFloat(total.years[0].months[10].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[0].months[10].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(total.years[1].months[10].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[1].months[10].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        parseFloat(total.years[0].months[11].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[0].months[11].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(total.years[1].months[11].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(total.years[1].months[11].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      ]
      body.push(rowToPush)

      this.filteredReport.areas.forEach((item) => {
        rowToPush = [((item.area ?? '') + '\n' + this.getArea(item.area) + '\n' + this.$t('messages.attivi') + ': ' + (item.clientsTotal ?? '')),
          parseFloat(item.years[0].year) + '\n' + parseFloat(item.years[1].year),
          parseFloat(item.years[0].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[0].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(item.years[1].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[1].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
          parseFloat(item.years[0].months[0].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[0].months[0].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(item.years[1].months[0].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[1].months[0].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
          parseFloat(item.years[0].months[1].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[0].months[1].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(item.years[1].months[1].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[1].months[1].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
          parseFloat(item.years[0].months[2].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[0].months[2].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(item.years[1].months[2].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[1].months[2].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
          parseFloat(item.years[0].months[3].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[0].months[3].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(item.years[1].months[3].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[1].months[3].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
          parseFloat(item.years[0].months[4].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[0].months[4].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(item.years[1].months[4].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[1].months[4].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
          parseFloat(item.years[0].months[5].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[0].months[5].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(item.years[1].months[5].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[1].months[5].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
          parseFloat(item.years[0].months[6].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[0].months[6].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(item.years[1].months[6].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[1].months[6].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
          parseFloat(item.years[0].months[7].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[0].months[7].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(item.years[1].months[7].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[1].months[7].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
          parseFloat(item.years[0].months[8].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[0].months[8].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(item.years[1].months[8].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[1].months[8].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
          parseFloat(item.years[0].months[9].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[0].months[9].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(item.years[1].months[9].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[1].months[9].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
          parseFloat(item.years[0].months[10].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[0].months[10].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(item.years[1].months[10].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[1].months[10].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
          parseFloat(item.years[0].months[11].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[0].months[11].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
          parseFloat(item.years[1].months[11].clientsVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '+' +
          parseFloat(item.years[1].months[11].prospectorVisited).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        ]
        body.push(rowToPush)
      })
      // const base64Img = images.logoBase64()
      doc.autoTable({
        head: [[this.$t('messages.Area'),
          this.$t('messages.Anno'),
          this.$t('messages.Tot'),
          this.$t('messages.Gen'),
          this.$t('messages.Feb'),
          this.$t('messages.Mar'),
          this.$t('messages.Apr'),
          this.$t('messages.Mag'),
          this.$t('messages.Giu'),
          this.$t('messages.Lug'),
          this.$t('messages.Ago'),
          this.$t('messages.Set'),
          this.$t('messages.Ott'),
          this.$t('messages.Nov'),
          this.$t('messages.Dic')]],
        body: body,
        alternateRowStyles: { fillColor: [232, 233, 252] },
        headStyles: {
          fillColor: [196, 199, 245],
          fontSize: 10,
          textColor: 40,
          halign: 'center',
          valign: 'center'
        },
        bodyStyles: {
          fontSize: 7,
          fontStyle: 'bold',
          cellPadding: 1,
          valign: 'middle'
        },
        didParseCell: (data) => {
          data.row.height = 11
        },
        columnStyles: {
          0: { cellWidth: 'auto', overflow: 'ellipsize' },
          1: { cellWidth: 16, halign: 'center' },
          2: { cellWidth: 15, halign: 'right' },
          3: { cellWidth: 15, halign: 'right' },
          4: { cellWidth: 15, halign: 'right' },
          5: { cellWidth: 15, halign: 'right' },
          6: { cellWidth: 15, halign: 'right' },
          7: { cellWidth: 15, halign: 'right' },
          8: { cellWidth: 15, halign: 'right' },
          9: { cellWidth: 15, halign: 'right' },
          10: { cellWidth: 15, halign: 'right' },
          11: { cellWidth: 15, halign: 'right' },
          12: { cellWidth: 15, halign: 'right' },
          13: { cellWidth: 15, halign: 'right' },
          14: { cellWidth: 15, halign: 'right' }
        },
        tableLineColor: [44, 62, 80],
        tableLineWidth: 0.1,
        styles: {
          lineColor: [44, 62, 80],
          lineWidth: 0.1
        },
        didDrawPage: function (data) {
          // Header
          doc.setFontSize(18)
          doc.setTextColor(40)

          const currentFont = doc.getFont()
          doc.getFont(currentFont.fontName, 'normal', 'normal')
          // doc.setFontStyle('normal')
          // doc.addImage(base64Img, 'JPEG', data.settings.margin.left, 15, 24.551, 11)
          doc.addImage('/logo.jpg', 'JPEG', data.settings.margin.left + 5, 13, 15, 15)
          doc.text(this.$t('messages.Report Visite Cliente del #data#', [datestring]), data.settings.margin.left + 24.551, 22)

          // Footer
          let str = 'Page ' + doc.internal.getNumberOfPages()
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' of ' + totalPagesExp
          }
          doc.setFontSize(7)

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          const pageSize = doc.internal.pageSize
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
          doc.text(str, data.settings.margin.left, pageHeight - 10)
        },
        margin: { top: 30 }
      })

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
      }
      doc.save(this.$t('ReportVisiteClienteppdf'))
    },

    async getReportVisiteCliente () {
      this.isLoading = true
      this.reportClientVisits = await this.$store.dispatch('reports/getReportClientVisits')

      this.filteredReport = this.reportClientVisits
      this.isLoading = false
    },

    getArea (cdAgent) {
      return this.areaDefinition[cdAgent]?.nome
    },

    groupByArea () {
    },

    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    }
  }
}
</script>

<style scoped>
table > thead > tr > th, table > tbody > tr > td {
  padding: 0px;
  padding-right: 4px;
}
.reportval {
  font-size: 0.665rem;
}
.reportprospector {
  color: rgba(37, 89, 185, 0.719);
  font-size: 0.6rem;
}
.reportval.highlight {
  font-weight: bold;
  font-size: 0.875rem;
}
.reportval.highlight .reportprospector{
  font-weight: bold;
  font-size: 0.8rem;
}
.reportval.highlight.reportval-negative {
  color: red
}
table th {
  position: sticky;
  top: 0;
}
table th:first-child {
  position: sticky;
  left: 0;
  top: 0;
  background-color: #ddd;
  margin-right: 4px;
  padding-right: 4px;
  max-width: 160px;
  width: 120px;
  z-index: 1;
}
table td:first-child {
  position: sticky;
  left: 0;
  background-color: #ddd;
  margin-right: 4px;
  padding-right: 4px;
  min-width: 120px;
  max-width: 160px;
}
table td:nth-child(2n+3) {background: rgb(230, 230, 230)}
.report-azienda {
  font-size: 0.665rem;
  font-weight: bold;
}
.report-area {
  font-size: 0.665rem;
  font-weight: bold;
  display: block;
  line-height: 0.9rem;
}
.report-cdclien {
  font-size: 0.665rem;
  font-weight: bold;
}

table > tbody > tr > th, table > thead > tr > th, table > tfoot > tr > th {
    font-size: 0.75rem;
}

.agent-header {
  background-color: #5e5e5e;
  min-height: 21px;
  color: white;
  font-size: 0.875rem;
}

table > thead > tr:last-child > th {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

table > thead > tr > td {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
table {
    border-spacing: 0;
}

</style>
