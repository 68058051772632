<template>
  <v-dialog
    v-model="open"
    fullscreen
  >
    <v-card>
      <v-container>
        <div class="d-flex justify-end ">
          <v-btn
            icon
            color="#C4C2BB"
            style="margin-top: -36px;top: 36px;position: relative;"
            @click="cancelDialog()"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-card-title class="headline d-flex justify-center">
          {{ $t('messages.Storico #Azienda#', [client.azienda]) }}
        </v-card-title>
        <v-card-text>
          <div
            v-if="loadingHistory"
            class="d-flex justify-center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              :size="70"
              class="mt-3 ml-3"
            />
          </div>
          <div v-else-if="clientHistory!=null">
            <div class="d-flex flex-column align-end">
              <h5>{{ clientHistory.client[0].Cd_Clien }} - {{ clientHistory.client[0].Azienda }}</h5>
              <h6>{{ clientHistory.client[0].Indirizz }} - {{ clientHistory.client[0].CAP }} - {{ clientHistory.client[0].Localita }}</h6>
            </div>
            <div class="d-flex">
              <div class="d-flex flex-column align-center justify-center">
                <span class="body-2 grey--text font-weight-black">{{ $t('messages.Fido') }}</span> <span class="body-2 font-weight-black">{{ formatCurrency(clientHistory.client[0].Fido) }}</span>
                <span class="body-2 grey--text font-weight-black">{{ $t('messages.Pagamento') }}</span> <span
                  class="body-2 font-weight-black"
                  style="text-align:center"
                >{{ client.pagamen }}</span>

                <span class="body-2 grey--text font-weight-black mt-2">{{ $t('messages.Scadp Aperte') }}</span> <span class="body-2 font-weight-black">{{ formatCurrency(totScadenzeAperte) }}</span>
                <span class="body-2 grey--text font-weight-black mt-2">{{ $t('messages.Scadp Chiuse') }}</span>
                <span class="body-2 grey--text mt-2">0/12</span>
                <span class="body-2 font-weight-black">{{ formatCurrency(totScadenzeChiusePrimiMesi) }}</span>
                <span class="body-2 grey--text mt-2">12/24</span>
                <span class="body-2 font-weight-black">{{ formatCurrency(totScadenzeChiuseUltimiMesi) }}</span>
              </div>
              <v-spacer />
              <div class="d-flex flex-column align-center justify-center">
                <span class="body-2 grey--text font-weight-black">{{ $t('messages.Valutazione ultimi 24 mesi') }}</span>
                <div class="d-flex">
                  <div class="d-flex flex-column align-center justify-center">
                    <span class="body-2 grey--text">{{ $t('messages.Np Insoluti') }}</span> <span class="body-2 font-weight-black">{{ getNumInsoluti() }}</span>
                  </div>
                  <v-divider
                    vertical
                    class="mx-2"
                  />
                  <div class="d-flex flex-column align-center justify-center">
                    <span class="body-2 grey--text">{{ $t('messages.Np Assp ppdp') }}</span> <span class="body-2 font-weight-black">{{ getNumAssegniPD() }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-center mt-8"
            >
              <h5>{{ $t('messages.Elenco Scadenze Aperte') }}</h5>
            </div>

            <div
              style="overflow-x: auto; overflow-y: hidden;"
              class="mx-1"
            >
              <table
                style="max-width:630px;margin-left:auto;margin-right:auto;"
              >
                <thead>
                  <tr>
                    <th
                      v-for="(hr, l) in headers"
                      :key="l"
                      :class="hr.class"
                      :style="hr.width?('min-width: ' + hr.width):''"
                    >
                      <div class="d-flex justify-center">
                        {{ hr.text }}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(item, k) in scadenzeAperte"
                  >
                    <tr
                      :key="k"
                    >
                      <td>
                        <div
                          class="d-flex justify-center"
                          style="font-weight: bold;"
                        >
                          {{ item.hideData ? '' : formatDate(item.Data) }}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-center">
                          {{ item.Oggetto }}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-center">
                          {{ formatDate(item.Data_Ogg) }}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-end">
                          {{ formatCurrency(item.Importo) }}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-center">
                          {{ item.Ritardo }}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-center">
                          {{ (item.Status1 ===null ? '':item.Status1) === '' ? '-' : item.Status1 }} | {{ (item.Status2 ===null ? '':item.Status2) === '' ? '-' : item.Status2 }}
                        </div>
                      </td>
                    </tr>
                    <tr
                      v-if="item.hasTotal"
                      :key="(k+1)*10000"
                    >
                      <td class="pb-2" />
                      <td class="pb-2" />
                      <td class="pb-2">
                        <div
                          class="d-flex justify-center"
                          style="font-weight: bold;"
                        >
                          {{ $t('messages.TOTALE') }}
                        </div>
                      </td>
                      <td class="pb-2">
                        <div
                          class="d-flex justify-end"
                          style="font-weight: bold;"
                        >
                          {{ formatCurrency((item.total/100)) }}
                        </div>
                      </td>
                      <td class="pb-2" />
                      <td class="pb-2" />
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>

            <!--
            <v-data-table
              style="max-width:630px;margin-left:auto;margin-right:auto;"
              :headers="headers"
              :items="scadenzeAperte"
              :locale="$t('messages.locale')"
              :items-per-page="-1"
              elevation="0"
              :mobile-breakpoint="0"
              :header-props="{ sortByText: $t('messages.Ordina per')}"
              :footer-props="{
                itemsPerPageAllText: $t('messages.Tutte'),
                itemsPerPageText: $t('messages.Per pagina')
              }"
            >
              <template #[`item.Data`]="{ item }">
                {{ formatDate(item.Data) }}
              </template>
              <template #[`item.Data_Ogg`]="{ item }">
                {{ formatDate(item.Data_Ogg) }}
              </template>
              <template #[`item.Importo`]="{ item }">
                <div class="d-flex justify-end">
                  {{ formatCurrency(item.Importo) }}
                </div>
              </template>
              <template #[`item.Status1`]="{ item }">
                {{ (item.Status1 ===null ? '':item.Status1) === '' ? '-' : item.Status1 }} | {{ (item.Status2 ===null ? '':item.Status2) === '' ? '-' : item.Status2 }}
              </template>
            </v-data-table>
            -->
            <div
              class="d-flex justify-center mt-8"
            >
              <h5>{{ $t('messages.Elenco Scadenze Chiuse') }}</h5>
            </div>

            <div
              style="overflow-x: auto; overflow-y: hidden;"
              class="mx-1"
            >
              <table
                style="max-width:630px;margin-left:auto;margin-right:auto;"
              >
                <thead>
                  <tr>
                    <th
                      v-for="(hr, l) in headers"
                      :key="l"
                      :class="hr.class"
                      :style="hr.width?('min-width: ' + hr.width):''"
                    >
                      <div class="d-flex justify-center">
                        {{ hr.text }}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(item, k) in scadenzeChiuse"
                  >
                    <tr
                      :key="k"
                    >
                      <td>
                        <div
                          class="d-flex justify-center"
                          style="font-weight: bold;"
                        >
                          {{ item.hideData ? '' : formatDate(item.Data) }}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-center">
                          {{ item.Oggetto }}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-center">
                          {{ formatDate(item.Data_Ogg) }}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-end">
                          {{ formatCurrency(item.Importo) }}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-center">
                          {{ item.Ritardo }}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-center">
                          {{ (item.Status1 ===null ? '':item.Status1) === '' ? '-' : item.Status1 }} | {{ (item.Status2 ===null ? '':item.Status2) === '' ? '-' : item.Status2 }}
                        </div>
                      </td>
                    </tr>
                    <tr
                      v-if="item.hasTotal"
                      :key="(k+1)*10000"
                    >
                      <td class="pb-2" />
                      <td class="pb-2" />
                      <td class="pb-2">
                        <div
                          class="d-flex justify-center"
                          style="font-weight: bold;"
                        >
                          {{ $t('messages.TOTALE') }}
                        </div>
                      </td>
                      <td class="pb-2">
                        <div
                          class="d-flex justify-end"
                          style="font-weight: bold;"
                        >
                          {{ formatCurrency((item.total/100)) }}
                        </div>
                      </td>
                      <td class="pb-2" />
                      <td class="pb-2" />
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <!--<v-data-table
              style="max-width:630px;margin-left:auto;margin-right:auto;"
              :headers="headers"
              :items="scadenzeChiuse"
              :locale="$t('messages.locale')"
              :items-per-page="-1"
              elevation="0"
              :mobile-breakpoint="0"
              :header-props="{ sortByText: $t('messages.Ordina per')}"
              :footer-props="{
                itemsPerPageAllText: $t('messages.Tutte'),
                itemsPerPageText: $t('messages.Per pagina')
              }"
            >
              <template #[`item.Data`]="{ item }">
                {{ formatDate(item.Data) }}
              </template>
              <template #[`item.Data_Ogg`]="{ item }">
                {{ formatDate(item.Data_Ogg) }}
              </template>
              <template #[`item.Importo`]="{ item }">
                <div class="d-flex justify-end">
                  {{ formatCurrency(item.Importo) }}
                </div>
              </template>
              <template #[`item.Status1`]="{ item }">
                {{ (item.Status1 ===null ? '':item.Status1) === '' ? '-' : item.Status1 }} | {{ (item.Status2 ===null ? '':item.Status2) === '' ? '-' : item.Status2 }}
              </template>
            </v-data-table>-->
          </div>
        </v-card-text>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'

export default {
  name: 'ViewClientHistory',
  model: {
    prop: 'isOpen',
    event: 'change'
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    client: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    open: false,
    clientHistory: null,
    loadingHistory: false
  }),

  computed: {
    headers () {
      return [
        { text: this.$t('messages.Scadenza'), value: 'Data', filterable: true, width: '110px' },
        { text: this.$t('messages.Fattura'), value: 'Oggetto', filterable: true, width: '110px' },
        { text: this.$t('messages.Data Fatp'), value: 'Data_Ogg', filterable: true, width: '110px' },
        { text: this.$t('messages.Importo'), value: 'Importo', filterable: true, width: '110px' },
        { text: this.$t('messages.Ritarp'), value: 'Ritardo', filterable: true, width: '80px' },
        { text: this.$t('messages.Stato'), value: 'Status1', filterable: true, width: '80px' }
      ]
    },
    scadenzeAperte () {
      return this.clientHistory.scadenze.filter(c => c.IsStorico === 0).sort((a, b) => {
        const dataScadA = a.Data
        const dataScadB = b.Data
        return dataScadA >= dataScadB ? 1 : -1
      })
    },
    totScadenzeAperte () {
      return this.scadenzeAperte.reduce((tot, item) => item.Importo + tot, 0)
    },
    scadenzeChiuse () {
      return this.clientHistory.scadenze.filter(c => c.IsStorico === 1).sort((a, b) => {
        const dataScadA = a.Data
        const dataScadB = b.Data
        return dataScadA <= dataScadB ? 1 : -1
      })
    },
    totScadenzeChiuse () {
      return this.scadenzeChiuse.reduce((tot, item) => item.Importo + tot, 0)
    },
    totScadenzeChiusePrimiMesi () {
      return this.scadenzeChiuse.reduce((tot, item) => {
        const itemDate = new Date(item.Data)
        const lastYear = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
        if (itemDate >= lastYear) {
          return item.Importo + tot
        }
        return tot
      }, 0)
    },
    totScadenzeChiuseUltimiMesi () {
      return this.scadenzeChiuse.reduce((tot, item) => {
        const itemDate = new Date(item.Data)
        const lastYear = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
        const lastTwoYears = new Date(new Date().setFullYear(new Date().getFullYear() - 2))
        if (itemDate < lastYear && itemDate >= lastTwoYears) {
          return item.Importo + tot
        }
        return tot
      }, 0)
    }
  },

  watch: {
    open (newVal) {
      if (newVal !== this.isOpen) {
        this.$emit('change', newVal)
      }
    },
    isOpen (newVal) {
      this.open = newVal
      if (this.open) {
        this.loadHistory()
      }
    }
  },

  methods: {
    cancelDialog () {
      this.open = false
    },
    async loadHistory () {
      if (this.clientHistory === null) {
        if (this.loadingHistory) {
          return
        }
        this.loadingHistory = true
        try {
          const r = await axios.get('/api/clients/insoluti/' + this.client.cd_clien)
          this.clientHistory = r.data
          let currentScadenza = null
          let totalCounter = 0
          let lastElement = null
          this.scadenzeAperte.forEach(e => {
            if (e.Data === currentScadenza) {
              e.hideData = true
              totalCounter += e.Importo * 100
              lastElement = e
            } else {
              e.hideData = false
              if (lastElement) {
                lastElement.hasTotal = true
                lastElement.total = totalCounter
              }
              lastElement = e
              totalCounter = e.Importo * 100
              currentScadenza = e.Data
            }
          })
          if (lastElement) {
            lastElement.hasTotal = true
            lastElement.total = totalCounter
            lastElement = null
          }

          this.scadenzeChiuse.forEach(e => {
            if (e.Data === currentScadenza) {
              e.hideData = true
              totalCounter += e.Importo * 100
              lastElement = e
            } else {
              e.hideData = false
              if (lastElement) {
                lastElement.hasTotal = true
                lastElement.total = totalCounter
              }
              lastElement = e
              totalCounter = e.Importo * 100
              currentScadenza = e.Data
            }
          })
          if (lastElement) {
            lastElement.hasTotal = true
            lastElement.total = totalCounter
            lastElement = null
          }
        } catch (error) {
          const message = error.response.data.detail
          const dataMessage = { isOpen: true, message: message, color: 'error' }
          this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
        }
        this.loadingHistory = false
      }
    },
    formatCurrency (currency) {
      if (currency === null) return ''
      return (new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(currency))
    },
    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    },

    getNumInsoluti () {
      return this.clientHistory.scadenze.filter(c => (c?.sched ?? '').startsWith('Insoluto')).length
    },
    getNumAssegniPD () {
      return this.clientHistory.scadenze.filter(c => (c?.Note ?? '').startsWith('#ASS') || (c?.Cd_Pagam ?? '') === '666').length
    }
  }
}
</script>
