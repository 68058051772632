const SETTING_LANGUAGE = 'SETTING_LANGUAGE'
export default {
  namespaced: true,
  state: {
    language: 1,
    langRoot: '',
    languageFull: ''
  },
  getters: {
    get (state) {
      return state.language
    },
    langRoot (state) {
      return state.langRoot
    },
    languageFull (state) {
      return state.languageFull
    }
  },

  mutations: {
    [SETTING_LANGUAGE] (state, language) {
      state.languageFull = language.toLowerCase()
      switch (language.toLowerCase()) {
      case 'it':
        state.language = 1
        state.langRoot = ''
        break
      case 'en':
        state.language = 2
        state.langRoot = 'en'
        break
      case 'es':
        state.language = 3
        state.langRoot = 'es'
        break
      case 'de':
        state.language = 4
        state.langRoot = 'de'
        break
      case 'fr':
        state.language = 5
        state.langRoot = 'fr'
        break
      case 'nl':
        state.language = 7
        state.langRoot = 'nl'
        break
      default:
        state.language = 1
        state.langRoot = ''
        state.languageFull = 'it'
      }
    }
  },
  actions: {
    set ({ commit }, language) {
      commit(SETTING_LANGUAGE, language)
    }
  }
}
