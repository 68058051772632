<template>
  <div>
    <v-app-bar
      app
      dense
      color="primary"
      dark
      elevation="0"
    >
      <v-container
        class="d-flex align-center mx-n1 px-0 px-md-4 mx-md-auto"
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

        <v-toolbar-title>
          <router-link
            v-if="$route.meta.title !== 'Estelio'"
            class="header-title"
            to="/home"
          >
            Estelio > {{ $t('messages.'+$route.meta.title) }}
          </router-link>
          <router-link
            v-if="$route.meta.title === 'Estelio'"
            class="header-title"
            to="/home"
          >
            Estelio
          </router-link>
        </v-toolbar-title>
        <template v-if="isAuthenticated">
          <a
            href="/api/security/logout"
            class="header-title ml-auto"
          >
            <v-icon
              :small="!$vuetify.breakpoint.smAndUp"
            >
              mdi-logout-variant
            </v-icon>
          </a>
        </template>
        <template v-else>
          <router-link
            to="/login"
            active-class="active"
            class="header-title ml-auto"
          >
            <v-icon
              :small="!$vuetify.breakpoint.smAndUp"
            >
              mdi-login-variant
            </v-icon>
          </router-link>
        </template>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      temporary
      color="#363636"
      fixed
    >
      <v-list
        dark
        dense
        expand
        tile
      >
        <v-list-item-group>
          <v-list-item
            v-if="isAuthenticated"
            class="apa-list-item"
            to="/home"
          >
            <v-list-item-title>
              <v-icon
                class="mr-1"
                :small="!$vuetify.breakpoint.smAndUp"
              >
                mdi-home
              </v-icon> {{ $t('messages.Home') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-group
            v-if="isAuthenticated && isAdmin"
            class="apa-list-item"
            color="white"
            :value="false"
          >
            <template #activator>
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-account-cog
                </v-icon> {{ $t('messages.Settings') }}
              </v-list-item-title>
            </template>
            <v-list-item
              v-if="isAuthenticated"
              to="/agent-assign"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-badge-account-horizontal
                </v-icon> {{ $t('messages.Imposta Agenti') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="isAuthenticated"
              to="/area-assign"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-file-tree
                </v-icon> {{ $t('messages.Assegna Aree') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="isAuthenticated"
              to="/classes-assign"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-format-list-checks
                </v-icon> {{ $t('messages.Gestione Classi') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="isAuthenticated"
              to="/campionature-assign"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-eyedropper-plus
                </v-icon> {{ $t('messages.Gestione Campionature') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="isAuthenticated"
              to="/projx-join-clients"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-cog-transfer
                </v-icon> {{ $t('messages.Trasferimento Feedback') }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group
            v-if="isAuthenticated"
            class="apa-list-item"
            color="white"
            :value="false"
          >
            <template #activator>
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-account-group
                </v-icon> {{ $t('messages.Clienti') }}
              </v-list-item-title>
            </template>

            <v-list-item
              v-if="isAuthenticated"
              to="/clientlist"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-card-account-details-outline
                </v-icon> {{ $t('messages.Lista Clienti') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="isAuthenticated"
              to="/client/details"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-account-plus
                </v-icon> {{ $t('messages.Nuovo Cliente') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="isAuthenticated"
              to="/scadenze-aperte"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-calendar-alert
                </v-icon> {{ $t('messages.Scadenze Aperte') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="isAuthenticated"
              to="/microaree"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-wan
                </v-icon> {{ $t('messages.Microaree') }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group
            v-if="isAuthenticated"
            class="apa-list-item"
            color="white"
            :value="false"
          >
            <template #activator>
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-file-edit-outline
                </v-icon> {{ $t('messages.Feedback') }}
              </v-list-item-title>
            </template>

            <v-list-item
              v-if="isAuthenticated"
              to="/feedbacks"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-book-open-variant
                </v-icon> {{ $t('messages.Consulta Feedbacks') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="isAuthenticated"
              to="/feedbackinsert"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-pencil-plus-outline
                </v-icon> {{ $t('messages.Nuovo Feedback') }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group
            v-if="isAuthenticated"
            class="apa-list-item"
            color="white"
            :value="false"
          >
            <template #activator>
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-camera
                </v-icon> {{ $t('messages.Gallery') }}
              </v-list-item-title>
            </template>
            <v-list-item
              v-if="isAuthenticated"
              to="/gallery"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-image-multiple
                </v-icon> {{ $t('messages.Consulta Gallery') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="isAuthenticated"
              to="/galleryinsert"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-camera-plus
                </v-icon> {{ $t('messages.Inserimento Immagini') }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-if="isAuthenticated"
            to="/clientsmap"
            class="apa-list-item"
          >
            <v-list-item-title>
              <v-icon
                class="mr-1"
                :small="!$vuetify.breakpoint.smAndUp"
              >
                mdi-earth
              </v-icon> {{ $t('messages.Mappa') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-group
            v-if="isAuthenticated"
            class="apa-list-item"
            color="white"
            :value="false"
          >
            <template #activator>
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-cart-variant
                </v-icon> {{ $t('messages.Ordini') }}
              </v-list-item-title>
            </template>
            <v-list-item
              v-if="isAuthenticated"
              to="/orderinsert"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-cart-plus
                </v-icon> {{ $t('messages.Nuovo Ordine') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="isAuthenticated"
              to="/ordercampionatura"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-eyedropper-variant
                </v-icon> {{ $t('messages.Campionatura') }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group
            v-if="isAuthenticated"
            class="apa-list-item"
            color="white"
            :value="false"
          >
            <template #activator>
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-chart-timeline-variant
                </v-icon> {{ $t('messages.Reports') }}
              </v-list-item-title>
            </template>

            <v-list-item
              v-if="isAuthenticated"
              to="/reports/client-sales"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-account-cash
                </v-icon> {{ $t('messages.Fatturato Cliente') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="isAuthenticated && isSupervisor"
              to="/reports/client-compact"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-chart-bar
                </v-icon> {{ $t('messages.Cliente Compatta') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="isAuthenticated && isSupervisor"
              to="/reports/client-compact-color"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-chart-bar-stacked
                </v-icon> {{ $t('messages.Cliente Compatta2') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="isAuthenticated && isSupervisor"
              to="/reports/agent-broad"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-account-key
                </v-icon> {{ $t('messages.Agente Esteso') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="isAuthenticated"
              to="/reports/color-sales"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-finance
                </v-icon> {{ $t('messages.Fatturato Colore') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="isAuthenticated"
              to="/reports/client-visits"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-store
                </v-icon> {{ $t('messages.Visite Cliente') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="isAuthenticated"
              to="/reports/visit-ratings"
              class="apa-list-item ml-6"
              color="white"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-emoticon
                </v-icon> {{ $t('messages.Rating Visite') }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-if="isAuthenticated"
            class="apa-list-item"
            to="/listini"
          >
            <v-list-item-title>
              <v-icon
                class="mr-1"
                :small="!$vuetify.breakpoint.smAndUp"
              >
                mdi-note-text
              </v-icon> {{ $t('messages.Listini') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-group
            v-if="isAuthenticated"
            class="apa-list-item"
            color="white"
            :value="false"
          >
            <template #activator>
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-web
                </v-icon> {{ $t('messages.Lingua') }}
              </v-list-item-title>
            </template>

            <v-list-item
              v-if="isAuthenticated"
              class="apa-list-item ml-6"
              color="grey"
              @click="changeLanguage('en')"
            >
              <v-list-item-title>
                English
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="isAuthenticated"
              class="apa-list-item ml-6"
              color="white"
              @click="changeLanguage('it')"
            >
              <v-list-item-title>
                Italiano
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="isAuthenticated"
              class="apa-list-item ml-6"
              color="grey"
              @click="changeLanguage('de')"
            >
              <v-list-item-title>
                Deutsch
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <a
            v-if="isAuthenticated"
            href="/api/security/logout"
            style="text-decoration: none"
          >
            <v-list-item
              class="mt-12"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-1"
                  :small="!$vuetify.breakpoint.smAndUp"
                >
                  mdi-logout-variant
                </v-icon> {{ $t('messages.Logout') }}
              </v-list-item-title>
            </v-list-item>
          </a>
          <v-list-item
            v-else
            to="/login"
          >
            <v-list-item-title>
              <v-icon
                class="mr-1"
                :small="!$vuetify.breakpoint.smAndUp"
              >
                mdi-login-variant
              </v-icon> {{ $t('messages.Login') }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data: () => ({
    drawer: false,
    changingLanguage: false
  }),

  computed: {
    currentRouteName () {
      return this.$route.name
    },
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    isAdmin () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN')
    },
    isSupervisor () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN') || this.$store.getters['security/hasRole']('ROLE_AGENT_ADMIN')
    }
  },

  methods: {
    async changeLanguage (language) {
      if (!this.changingLanguage) {
        if (this.$store.getters['language/languageFull'] === language) return
        this.changingLanguage = true
        const r = await this.$store.dispatch('agents/setLanguage', { language: language })
        if (r) {
          location.reload()
        }
        this.changingLanguage = false
      }
    }
  }
}

</script>

<style scoped>
.header-title {
  color: #FFFFFF!important;
  text-decoration: none;
}
.app-menu-header {
  background-color: transparent !important;
  color: transparent !important;
  background-image: linear-gradient(0deg,rgba(0,0,0,0) 10%,rgba(0,0,0,.45) 50%,rgba(0,0,0,.98) 100%) !important;
}
.apa-list-item {
  background-color:rgb(37, 89, 185);
  margin-bottom: 2px;
}
</style>
