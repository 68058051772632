import SecurityAPI from '../api/security'

const AUTHENTICATING = 'AUTHENTICATING'
const AUTHENTICATING_SUCCESS = 'AUTHENTICATING_SUCCESS'
const AUTHENTICATING_ERROR = 'AUTHENTICATING_ERROR'
const PROVIDING_DATA_ON_REFRESH_SUCCESS = 'PROVIDING_DATA_ON_REFRESH_SUCCESS'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    isAuthenticated: false,
    user: null
  },
  getters: {
    isLoading (state) {
      return state.isLoading
    },
    hasError (state) {
      return state.error !== null
    },
    error (state) {
      return state.error
    },
    errorMessage (state) {
      return state.error.response.data.detail
    },
    isAuthenticated (state) {
      return state.isAuthenticated
    },
    username (state) {
      return state.user ? state.user.userLogin.split('@')[0] : ''
    },
    userId (state) {
      return state.user ? state.user.id : '-1'
    },
    hasRole (state) {
      if (state.user == null) {
        return () => {
          return false
        }
      }
      return role => {
        return state.user.roles.indexOf(role) !== -1
      }
    }
  },
  mutations: {
    [AUTHENTICATING] (state) {
      state.isLoading = true
      state.error = null
      state.isAuthenticated = false
      state.user = null
    },
    [AUTHENTICATING_SUCCESS] (state, user) {
      state.isLoading = false
      state.error = null
      state.isAuthenticated = true
      state.user = user
    },
    [AUTHENTICATING_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
      state.isAuthenticated = false
      state.user = null
    },
    [PROVIDING_DATA_ON_REFRESH_SUCCESS] (state, payload) {
      state.isLoading = false
      state.error = null
      state.isAuthenticated = payload.isAuthenticated
      state.user = payload.user
    }
  },
  actions: {
    async login ({ commit }, payload) {
      commit(AUTHENTICATING)
      try {
        const response = await SecurityAPI.login(payload.login, payload.password)
        commit(AUTHENTICATING_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(AUTHENTICATING_ERROR, error)
        return null
      }
    },
    onRefresh ({ commit }, payload) {
      commit(PROVIDING_DATA_ON_REFRESH_SUCCESS, payload)
    }
  }
}
