// controlla corretto funzionamento tipo contatto in anagrafica cliente ok
// controlla corretto funzionamento tipo dispositivo in anagrafica cliente ok
// controlla corretto funzionamento tipo indirizzo in anagrafica cliente ok
// controlla parametro campioni rotolino a4 a5 etc es. in feedback insert e ordini ok
// sistemare in mail ordine in lingua ok
// prendere descrizione in lingua ok
// controlla parametro contact type e purpose in feedback ok
// controlla solo uso interno ok
export default {
  it: {
    messages: {
      Estelio: 'Estelio',
      'Clienti Totali': 'Clienti Totali',
      'Seleziona un Agente': 'Seleziona un Agente',
      'Nessun Agente presente': 'Nessun Agente presente',
      'Seleziona un Area': 'Seleziona un\'Area',
      'Nessun Area presente': 'Nessun Area presente',
      'Seleziona un Cliente': 'Seleziona un Cliente',
      Ricercappp: 'Ricerca...',
      'Nessun Cliente trovato': 'Nessun Cliente trovato',
      'Digita nome clienteppp': 'Digita nome cliente...',
      Annulla: 'Annulla',
      Home: 'Home',
      Settings: 'Settings',
      'Imposta Agenti': 'Imposta Agenti',
      'Assegna Aree': 'Assegna Aree',
      'Gestione Classi': 'Gestione Classi',
      'Gestione Campionature': 'Gestione Campionature',
      'Trasferimento Feedback': 'Trasferimento Feedback',
      Clienti: 'Clienti',
      'Lista Clienti': 'Lista Clienti',
      'Nuovo Cliente': 'Nuovo Cliente',
      'Scadenze Aperte': 'Scadenze Aperte',
      Microaree: 'Microaree',
      Feedback: 'Feedback',
      'Consulta Feedbacks': 'Consulta Feedbacks',
      'Nuovo Feedback': 'Nuovo Feedback',
      Gallery: 'Gallery',
      'Consulta Gallery': 'Consulta Gallery',
      'Inserimento Immagini': 'Inserimento Immagini',
      Mappa: 'Mappa',
      Ordini: 'Ordini',
      'Nuovo Ordine': 'Nuovo Ordine',
      Campionatura: 'Campionatura',
      Reports: 'Reports',
      'Fatturato Cliente': 'Fatturato Cliente',
      'Fatturato Colore': 'Fatturato Colore',
      'Visite Cliente': 'Visite Cliente',
      'Rating Visite': 'Rating Visite',
      'Agente Esteso': 'Agente Esteso',
      Listini: 'Listini',
      Logout: 'Logout',
      Login: 'Login',
      'Seleziona una Microarea': 'Seleziona una Microarea',
      'Nessuna microarea presente': 'Nessuna microarea presente',
      'Nessuna categoria presente': 'Nessuna categoria presente',
      'Nessuna sotto-categoria presente': 'Nessuna sotto-categoria presente',
      'Nessuna linea presente': 'Nessuna linea presente',
      'Nessun codice commerciale presente': 'Nessun codice commerciale presente',
      'Seleziona una categoria': 'Seleziona una categoria',
      'Seleziona una sotto-categoria': 'Seleziona una sotto-categoria',
      'Seleziona una linea': 'Seleziona una linea',
      'Seleziona un codice commerciale': 'Seleziona un codice commerciale',
      Chiudi: 'Chiudi',
      'Storico #Azienda#': 'Storico {0}',
      'Sconti Particolari #Azienda#': 'Sconti Particolari {0}',
      Sconti: 'Sconti',
      ValiditaDaA: 'Validità Da - A',
      Fido: 'Fido',
      'Scadp Aperte': 'Scad. Aperte',
      'Scadp Chiuse': 'Scad. Chiuse',
      'Valutazione ultimi 24 mesi': 'Valutazione ultimi 24 mesi',
      'Np Insoluti': 'N. Insoluti', // numero insoluti abbreviato
      'Np Assp ppdp': 'N. Ass. p.d.', // numero assegni post datati abbreviato
      'Elenco Scadenze Aperte': 'Elenco Scadenze Aperte',
      'Ordina per': 'Ordina per',
      Tutte: 'Tutte',
      'Per pagina': 'Per pagina',
      'Elenco Scadenze Chiuse': 'Elenco Scadenze Chiuse',
      locale: 'it-IT',
      Fattura: 'Fattura',
      'Data Fatp': 'Data Fat.', // data fattura abbreviato
      Importo: 'Importo',
      Ritarp: 'Ritar.', // ritardo in giorni abbreviato
      Stato: 'Stato',
      Salva: 'Salva',
      'si è verificato un errore': 'si è verificato un errore',
      'La posizione è stata aggiornata': 'La posizione è stata aggiornata',
      Drive: 'Drive',
      'ultima visita': 'ultima visita',
      ordine: 'ordine',
      Sposta: 'Sposta',
      Visitato: 'Visitato',
      'Dettagli Cliente': 'Dettagli Cliente',
      'Trasferimento Feedbacks Projx': 'Trasferimento Feedbacks Projx',
      'Modifica Feedback': 'Modifica Feedback',
      'Nuovo Ordine Campionatura': 'Nuovo Ordine Campionatura',
      'Sync Users': 'Sync Users',
      Ricerca: 'Ricerca',
      Agente: 'Agente',
      Nazioni: 'Nazioni',
      Azioni: 'Azioni',
      'Salva Modifiche': 'Salva Modifiche',
      'Aggiungi sottoagente a #agente#': 'Aggiungi sottoagente a {0}',
      'Aggiungi area a #agente#': 'Aggiungi area a {0}',
      'Salvare le modifiche effettuate?': 'Salvare le modifiche effettuate?',
      'Modifiche Salvate': 'Modifiche Salvate',
      Rimuovi: 'Rimuovi',
      'Rimuovere l area #nome#?': 'Rimuovere l\'area {0}?',
      'Inserire l area #nome#?': 'Inserire l\'area {0}?',
      'Impossibile assegnare l agente a se stesso': 'Impossibile assegnare l\'agente a se stesso',
      'Impossibile assegnare all agente un elemento direttamente ascendente': 'Impossibile assegnare all\'agente un elemento direttamente ascendente',
      Conferma: 'Conferma',
      'Attenzione L agente selezionato è già assegnatop Spostare l agente #nome# insieme a tutta la sua struttura discendente?': 'Attenzione\nL\'agente selezionato è già assegnato.\nSpostare l\'agente {0} insieme a tutta la sua struttura discendente?',
      'Inserire l agente #nome#?': 'Inserire l\'agente {0}?',
      Elimina: 'Elimina',
      'Eliminare l agente #nome# e tutta la sottostruttura?': 'Eliminare l\'agente {0} e tutta la sottostruttura?',
      'Aggiungi Nuovo': 'Aggiungi Nuovo',
      Codp: 'Cod.', // codice abbreviato
      Rotolino: 'Rotolino',
      'Print Roll': 'Print Roll',
      'Roll Print (40x70)': 'Roll Print (40x70)',
      A4: 'A4',
      A5: 'A5',
      'Solo Linea': 'Solo Linea',
      'Prodotti aggiornati': 'Prodotti aggiornati',
      'Cerca in': 'Cerca in',
      'Cerca ppp': 'Cerca ...',
      'Superclasse aggiornata': 'Superclasse aggiornata',
      'Trasferisce i feedback e le immagini da clienti projx chiusi a clienti projx attivi': 'Trasferisce i feedback e le immagini da clienti projx chiusi a clienti projx attivi.',
      'I feedback trasferiti avranno la dicitura Trasferito ex: XXXXX aggiunta alla fine delle note dei feedback trasferiti': 'I feedback trasferiti avranno la dicitura \'Trasferito ex: XXXXX\' aggiunta alla fine delle note dei feedback trasferiti.',
      'Clienti Projx chiusi': 'Clienti Projx chiusi.',
      'Trasferisci da': 'Trasferisci da',
      'Clienti Projx aperti': 'Clienti Projx aperti.',
      'Trasferisci a': 'Trasferisci a',
      'Feedback e Immagini trasferiti': 'Feedback e Immagini trasferiti',
      'Esporta in PDF': 'Esporta in PDF',
      Evidenzia: 'Evidenzia',
      'Anno Precedente': 'Anno Precedente',
      'Anno Corrente': 'Anno Corrente',
      Differenza: 'Differenza',
      'Raggruppa per area': 'Raggruppa per area',
      'Nascondi non evidenziati': 'Nascondi non evidenziati',
      'Filtra agente': 'Filtra agente',
      'Filtra area': 'Filtra area',
      'Filtra microarea': 'Filtra microarea',
      'DA VISITARE': 'DA VISITARE',
      Cliente: 'Cliente',
      Area: 'Area',
      Cap: 'Cap',
      Codice: 'Codice',
      Visita: 'Visita',
      Tot: 'Tot',
      Gen: 'Gen',
      Feb: 'Feb',
      Mar: 'Mar',
      Apr: 'Apr',
      Mag: 'Mag',
      Giu: 'Giu',
      Lug: 'Lug',
      Ago: 'Ago',
      Set: 'Set',
      Ott: 'Ott',
      Nov: 'Nov',
      Dic: 'Dic',
      'Report Fatturato Cliente del #data#': 'Report Fatturato Cliente del {0}', // data
      ReportFatturatoClienteppdf: 'ReportFatturatoCliente.pdf',
      Totale: 'Totale',
      Prospect: 'Prospect',
      Info: 'Info',
      Attivi: 'Attivi',
      'Numero Nero': 'Numero Nero',
      'Numero Blu': 'Numero Blu',
      'numero di clienti che hanno fatto un ordine negli ultimi 3 anni': 'numero di clienti che hanno fatto un ordine negli ultimi 3 anni',
      'numero di clienti univoci visitati nel periodo di tempo selezionato (a prescidere dal fatti che questi abbiano effettuato un ordine negli ultimi 3 anni)': 'numero di clienti unici visitati nel periodo di tempo selezionato (a prescidere dal fatto che questi abbiano effettuato un ordine negli ultimi 3 anni)',
      'numero di prospect univoci visitati nel periodo di tempo selezionato': 'numero di prospect unici visitati nel periodo di tempo selezionato',
      TOTALE: 'TOTALE',
      attivi: 'attivi',
      Anno: 'Anno',
      'Report Visite Cliente del #data#': 'Report Visite Cliente del {0}',
      ReportVisiteClienteppdf: 'ReportVisiteCliente.pdf',
      'ORDINA PER FATTURATO': 'ORDINA PER FATTURATO',
      'ORDINA PER METRI QUADRI': 'ORDINA PER METRI QUADRI',
      Colore: 'Colore',
      'Totale #Area#': 'Totale {0}', // Area
      'Totale Serie #Nome#': 'Totale Serie {0}', // Nome Serie
      TUTTI: 'TUTTI',
      Visualizza: 'Visualizza',
      Tutti: 'Tutti',
      'Seleziona Anno': 'Seleziona Anno',
      'Nessun report trovato': 'Nessun report trovato',
      'Report Rating Visite del #data#': 'Report Rating Visite del {0}', // data
      ReportRatingVisiteppdf: 'ReportRatingVisite.pdf',
      Ripristina: 'Ripristina',
      Modifica: 'Modifica',
      Rimuovere: 'Rimuovere',
      'Il Codice Cliente digitato è già memorizzato come Cliente Projxp Si conferma di voler unire il Prospect al Cliente #Nome# Indirizzo: #Indirizzo# #Citta# (CAP: #CAP#)?': 'Il Codice Cliente digitato è già memorizzato come Cliente Projx. Si conferma di voler unire il Prospect al Cliente {0}\nIndirizzo: {1} {2} (CAP: {3})?',
      'Visualizza Storico': 'Storico',
      'Sconti Cliente': 'Sconti Cliente',
      'Codice Cliente': 'Codice Cliente',
      'Ragione Sociale': 'Ragione Sociale',
      PpIVA: 'P.IVA',
      CodpFiscale: 'Cod.Fiscale',
      Indirizzo: 'Indirizzo',
      CAP: 'CAP',
      Città: 'Città',
      Provincia: 'Provincia',
      Nazione: 'Nazione',
      Telefono: 'Telefono',
      'E-Mail': 'E-Mail',
      Website: 'Website',
      Fornitore: 'Fornitore',
      Note: 'Note',
      'Note Projx': 'Note Projx',
      Azienda: 'Azienda',
      Via: 'Via',
      'Tipologia Indirizzo': 'Tipologia Indirizzo',
      Nome: 'Nome',
      'Tipo dispositivo': 'Tipo dispositivo',
      'Modello dispositivo': 'Modello dispositivo',
      Nominativo: 'Nominativo',
      Tipo: 'Tipo',
      Cellulare: 'Cellulare',
      'Cliente inattivo o non trovato': 'Cliente inattivo o non trovato',
      'Sede Operativa': 'Sede Operativa',
      'Sede Legale': 'Sede Legale',
      'Indirizzo di spedizione': 'Indirizzo di spedizione',
      Calandra: 'Calandra',
      Plotter: 'Plotter',
      Pressa: 'Pressa',
      'Stampante latex': 'Stampante latex',
      'Stampante solvente': 'Stampante solvente',
      'Stampante UV': 'Stampante UV',
      Amministrazione: 'Amministrazione',
      Referente: 'Referente',
      Commerciale: 'Commerciale',
      Responsabile: 'Responsabile',
      Segreteria: 'Segreteria',
      'Prospect Unito al Cliente #nome# Indirizzo: #via# #citta# (CAP: #cap#)': 'Prospect Unito al Cliente {0}\n Indirizzo: {1} {2} (CAP: {3})',
      'Digitare la Ragione Sociale': 'Digitare la Ragione Sociale',
      Compilare: 'Compilare',
      'Impostare almeno mail o telefono': 'Impostare almeno mail o telefono',
      'Cliente Creato': 'Cliente Creato',
      'Cliente Modificato': 'Cliente Modificato',
      'Salvare per confermare le modifiche': 'Salvare per confermare le modifiche',
      'Non è possibile eliminare l indirizzo preferito': 'Non è possibile eliminare l\'indirizzo preferito',
      'Sei sicuro di voler eliminare l indirizzo #azienda# selezionato?': 'Sei sicuro di voler eliminare l\'indirizzo {0} selezionato?',
      'Sei sicuro di voler eliminare il dispositivo #brand#?': 'Sei sicuro di voler eliminare il dispositivo {0}?',
      'Sei sicuro di voler eliminare il contatto #nome#?': 'Sei sicuro di voler eliminare il contatto {0}?',
      'Mostra attivi': 'Mostra attivi',
      'Mostra eliminati': 'Mostra eliminati',
      'Esporta in Excel': 'Esporta in Excel',
      'Data Ultimo Ordine': 'Data Ultimo Ordine',
      Da: 'Da', // data da
      A: 'A', // data a
      'Seleziona una data': 'Seleziona una data',
      'Data Ultima Visita': 'Data Ultima Visita',
      'Data Ultima Telefonata': 'Data Ultima Telefonata',
      Projx: 'Projx',
      Prospects: 'Prospects',
      'Non presenti in mappa': 'Non presenti in mappa',
      'Presenti in mappa': 'Presenti in mappa',
      'Numero Stelle': 'Numero Stelle',
      CodpCliente: 'Cod.Cliente',
      di: 'di', // es: 4 di 10
      'ATTENZIONE - Eliminare definitivamente lanagrafica #cliente#?': 'ATTENZIONE - Eliminare definitivamente l\'anagrafica {0}?',
      'Eliminare l anagrafica #cliente#?': 'Eliminare l\'anagrafica {0}?',
      'Ripristinare l anagrafica #cliente#?': 'Ripristinare l\'anagrafica {0}?',
      Microarea: 'Microarea',
      'Ultimo Ordine': 'Ultimo Ordine',
      'Ultima Visita': 'Ultima Visita',
      'Ultima Telefonata': 'Ultima Telef.',
      'Cliente Eliminato': 'Cliente Eliminato',
      'Il Cliente deriva da Projx: impossibile eliminare il Cliente': 'Il Cliente deriva da Projx: impossibile eliminare il Cliente',
      'Cliente Ripristinato': 'Cliente Ripristinato',
      'Codp Cliente': 'Cod. Cliente',
      Cd_Agent: 'Cd_Agent',
      PIVA: 'PIVA',
      Stelle: 'Stelle',
      'Anno Mobile 0/12': 'Anno Mobile 0/12',
      'Anno Mobile 12/24': 'Anno Mobile 12/24',
      Filtri: 'Filtri',
      'Il cliente non ordina da più di due anni ed è stato nascosto': 'Il cliente non ordina da più di due anni ed è stato nascosto',
      'Forzare la visualizzazione sulla mappa?': 'Forzare la visualizzazione sulla mappa?',
      'Attiva sulla mappa': 'Attiva sulla mappa',
      'Il cliente non ordina da più di due anni ed è stato forzato': 'Il cliente non ordina da più di due anni ed è stato forzato',
      'Nascondere la visualizzazione sulla mappa?': 'Nascondere la visualizzazione sulla mappa?',
      'Nascondi dalla mappa': 'Nascondi dalla mappa',
      Imposta: 'Imposta',
      'Filtro Area': 'Filtro Area',
      'Filtro Microarea': 'Filtro Microarea',
      'Giro Visita (Data)': 'Giro Visita (Data)',
      gg: 'gg', // giorni abbreviato 2 lettere
      'È necessario accettare i permessi di gelocalizzazione': 'È necessario accettare i permessi di gelocalizzazione',
      'Il browser non supporta le funzioni di geolocalizzazione': 'Il browser non supporta le funzioni di geolocalizzazione',
      'Errore: Richiesta di geolocalizzazione fallitap': 'Errore: Richiesta di geolocalizzazione fallita.',
      'Errore: Il browser non supporta le funzioni di geolocalizzazionep': 'Errore: Il browser non supporta le funzioni di geolocalizzazione.',
      'Stai già impostando una location Salvare o impostare l operazione corrente prima di continuare': 'Stai già impostando una location\nSalvare o impostare l\'operazione corrente prima di continuare',
      'Selezionare almeno un Cliente': 'Selezionare almeno un Cliente',
      'Giro salvato': 'Giro salvato',
      'Tipo Contatto': 'Tipo Contatto',
      'Finalità Contatto': 'Finalità Contatto',
      'Prodotti presentati': 'Prodotti presentati',
      'Nessun prodotto selezionato': 'Nessun prodotto selezionato',
      OK: 'OK',
      'Prodotti con problematiche': 'Prodotti con problematiche',
      'Note / Testo libero': 'Note / Testo libero',
      Campionature: 'Campionature',
      'Nessuna campionatura selezionata': 'Nessuna campionatura selezionata',
      Campioni: 'Campioni',
      Marketing: 'Marketing',
      Aggiungi: 'Aggiungi',
      'Nessun campione presente': 'Nessun campione presente',
      'Seleziona un campione': 'Seleziona un campione',
      'Esito visita': 'Esito visita',
      'Feedback non trovato o non modificabile': 'Feedback non trovato o non modificabile',
      'Cliente non trovato': 'Cliente non trovato',
      'Sei sicuro di voler eliminare questo Feedback?': 'Sei sicuro di voler eliminare questo Feedback?',
      'Feedback eliminato': 'Feedback eliminato',
      'Selezionare un Cliente': 'Selezionare un Cliente',
      'Selezionare la Data di visita': 'Selezionare la Data di visita',
      'Selezionare il tipo di contatto': 'Selezionare il tipo di contatto',
      'Selezionare la finalità del contatto': 'Selezionare la finalità del contatto',
      'Specificare rating prodotti presentati': 'Specificare rating prodotti presentati',
      'Specificare rating prodotti con problematiche': 'Specificare rating prodotti con problematiche',
      'Indicare l argomento della prossima visita': 'Indicare l\'argomento della prossima visita',
      'Selezionare l esito della visita': 'Selezionare l\'esito della visita',
      'Feedback generato': 'Feedback generato',
      'Feedback modificato': 'Feedback modificato',
      'Prodotto già presente': 'Prodotto già presente',
      'Ricerca libera': 'Ricerca libera',
      'Digita qui la parola o le parole da cercare': 'Digita qui la parola o le parole da cercare',
      'Comandi di ricerca': 'Comandi di ricerca',
      'Comandi riga1': '* dopo una parola (o parte di parola) consente di ricercare tutte quelle parole che iniziano con la stringa digitata.',
      'Comandi riga2': '- prima di una parola (o parte di parola) consente di escludere tutte quelle parole che contengono la stringa digitata.',
      'Ricerca testo in': 'Ricerca testo in',
      'Presentazione prodotti': 'Presentazione prodotti',
      Cerca: 'Cerca',
      Filtro: 'Filtro',
      'Deve contenere': 'Deve contenere',
      'Prodotti con problemi': 'Prodotti con problemi',
      Testo: 'Testo',
      'Reset / Nuova Ricerca': 'Reset / Nuova Ricerca',
      'Ordina campioni': 'Ordina campioni',
      'Sei sicuro di voler resettare il form di ricerca?': 'Sei sicuro di voler resettare il form di ricerca?',
      Reset: 'Reset',
      'Visualizza solo': 'Visualizza solo',
      'Argomento prossima visita': 'Argomento prossima visita',
      'Totale Feedbacks': 'Totale Feedbacks',
      'Data Visita': 'Data Visita',
      'Presentazione Prodotti': 'Presentazione Prodotti',
      'Gestione problematiche': 'Gestione problematiche',
      'Nessun feedback trovato secondo i parametri di ricerca impostati': 'Nessun feedback trovato secondo i parametri di ricerca impostati',
      'Selezionare almeno un campo di ricerca testo': 'Selezionare almeno un campo di ricerca testo',
      'Seleziona almeno un campo in cui ricercare il testo': 'Seleziona almeno un campo in cui ricercare il testo',
      'Imposta i parametri di ricerca!': 'Imposta i parametri di ricerca!',
      'Digita qui la parola o le parole da inserire': 'Digita qui la parola o le parole da inserire',
      'Etichette immagini selezionate': 'Etichette immagini selezionate',
      'Nessuna immagine selezionata': 'Nessuna immagine selezionata',
      'Sei sicuro di voler rimuovere questa Etichetta da #Numero# immagini?': 'Sei sicuro di voler rimuovere questa Etichetta da {0} immagini?',
      'Etichette da aggiungere': 'Etichette da aggiungere',
      'Nessuna etichetta inserita': 'Nessuna etichetta inserita',
      Operazioni: 'Operazioni',
      'Aggiungi Etichetta': 'Aggiungi Etichetta',
      'Elimina Immagini': 'Elimina Immagini',
      'Sei sicuro di voler aggiornare le etichette di #Numero# immagini?': 'Sei sicuro di voler aggiornare le etichette di {0} immagini?',
      'Sei sicuro di voler eliminare #Numero# immagini?': 'Sei sicuro di voler eliminare {0} immagini?',
      'Totale Immagini': 'Totale Immagini',
      Etichette: 'Etichette',
      'Nessuna immagine trovata secondo i parametri di ricerca impostati': 'Nessuna immagine trovata secondo i parametri di ricerca impostati',
      'Sei sicuro di voler rimuovere quest Immagine?': 'Sei sicuro di voler rimuovere quest\'Immagine?',
      'Immagine eliminata': 'Immagine eliminata',
      'Immagini eliminate': 'Immagini eliminate',
      'Etichetta rimossa': 'Etichetta rimossa',
      'ERRORE: Etichetta non rimossa': 'ERRORE: Etichetta non rimossa',
      'Etichette aggiornate': 'Etichette aggiornate',
      'Selezionare almeno un immagine': 'Selezionare almeno un\'immagine',
      'Selezionare almeno un Testo Libero o un Cliente o un Prodotto da inserire': 'Selezionare almeno un Testo Libero o un Cliente o un Prodotto da inserire',
      'Etichetta già presente': 'Etichetta già presente',
      'Imposta le proprietà dell immagine!': 'Imposta le proprietà dell\'immagine!',
      'Carica immagini': 'Carica immagini',
      'Solo uso interno': 'Internal use Only',
      'Cliente sconosciuto': 'Cliente sconosciuto',
      'Prodotti nelle immagini': 'Prodotti nelle immagini',
      Carica: 'Carica',
      'Immagini pre-caricate': 'Immagini pre-caricate',
      'Caricare almeno un immagine': 'Caricare almeno un\'immagine',
      'Compilare tutti campi dei prodotti': 'Compilare tutti campi dei prodotti',
      'Immagini caricate': 'Immagini caricate',
      'Immagine caricata': 'Immagine caricata',
      'Buongiorno #nome#': 'Buongiorno {0}',
      'Seleziona agente': 'Seleziona agente',
      'Fatturato #Mese#': 'Fatturato {0}',
      'Riepilogo Mese #Mese#': 'Riepilogo Mese {0}',
      'Clienti visitati a #Mese#': 'Clienti visitati a {0}',
      'Visite clienti univoci': 'Visite Uniche',
      Visitati: 'Visitati',
      Obiettivo: 'Obiettivo',
      Attuale: 'Attuale',
      'Aggiungi Microarea': 'Aggiungi Microarea',
      '#Numero# selezionatip': '{0} selezionati.',
      'Pulisci selezione': 'Pulisci selezione',
      'Assegna selezionati a microarea': 'Assegna selezionati a microarea',
      'Errore nella eliminazione della microarea': 'Errore nella eliminazione della microarea',
      'Errore nell aggiunta della microarea': 'Errore nell\'aggiunta della microarea',
      'Errore nell aggiornamento della microarea': 'Errore nell\'aggiornamento della microarea',
      'Clienti assegnati': 'Clienti assegnati',
      'Errore nell assegnazione della microarea': 'Errore nell\'assegnazione della microarea',
      'Ordine Effettuato': 'Ordine Effettuato',
      'Una mail di riepilogo è stata mandata a #mail#': 'Una mail di riepilogo è stata mandata a {0}',
      'Dati di spedizione': 'Dati di spedizione',
      'Azienda o Nome': 'Azienda o Nome',
      'Via e numero civico': 'Via e numero civico',
      'Rimuovere il prodotto #codice#?': 'Rimuovere il prodotto {0}?',
      campioni: 'campioni',
      marketing: 'marketing',
      'Salva bozza': 'Salva bozza',
      'Conferma ordine': 'Conferma ordine',
      'Non Salvare': 'Non Salvare',
      'Il carrello è stato modificato, salvarlo prima di uscire?': 'Il carrello è stato modificato, salvarlo prima di uscire?',
      Obbligatorio: 'Obbligatorio',
      'E-Mail non valida': 'E-Mail non valida',
      'I seguenti campioni non sono più disponibili e non sono stati aggiunti all ordine:': 'I seguenti campioni non sono più disponibili e non sono stati aggiunti all\'ordine:',
      'Seleziona un Prodotto': 'Seleziona un Prodotto',
      'Seleziona un Campione': 'Seleziona un Campione',
      'Compilare i dati di spedizione': 'Compilare i dati di spedizione',
      'Selezionare almeno un prodotto': 'Selezionare almeno un prodotto',
      'Selezionare il tipo di articolo': 'Selezionare il tipo di articolo',
      'Compilare i campi dell articolo': 'Compilare i campi dell\'articolo',
      'Si è verificato un errore': 'Si è verificato un errore',
      'Bozza salvata': 'Bozza salvata',
      'Spedisci ad un indirizzo diverso da quello di fatturazione': 'Spedisci ad un indirizzo diverso da quello di fatturazione',
      'Dati di fatturazione': 'Dati di fatturazione',
      'Partita Iva': 'Partita Iva',
      'Codice Fiscale': 'Codice Fiscale',
      IBAN: 'IBAN',
      Banca: 'Banca',
      Pagamento: 'Pagamento',
      SDI: 'SDI', // non tradurre
      PEC: 'PEC', // non tradurre
      'Email Notifica Ordine': 'Email Notifica Ordine',
      'Regime forfettario': 'Regime forfettario', // non tradurre
      Altp: 'Alt.', // abbreviazione altezza
      Lunp: 'Lun.', // abbreviazione lunghezza
      Qty: 'Qtà', // abbreviazione quantità
      prodotto: 'prodotto',
      campione: 'campione',
      'Note Extra': 'Note Extra',
      'Richiedi autorizzazione': 'Richiedi autorizzazione',
      Email: 'Email',
      'Descrizione Campione': 'Descrizione Campione',
      Prodotti: 'Prodotti',
      Forato: 'Forato',
      'Non Forato': 'Non Forato',
      'Compilare i dati di fatturazione': 'Compilare i dati di fatturazione',
      'Compilare la mail di autorizzazione con una mail valida': 'Compilare la mail di autorizzazione con una mail valida',
      'Selezionare un altezza': 'Selezionare un\'altezza',
      'Selezionare una lunghezza': 'Selezionare una lunghezza',
      'Selezionare le misure dell articolo': 'Selezionare le misure dell\'articolo',
      Totp: 'Tot.', // totale abbreviato
      Scadenza: 'Scadenza', // scadenza fattura
      Fattp: 'Fatt.', // numero fattura abbreviato
      Pagam: 'Pagam', // tipo di pagamento abbreviato
      Ritar: 'Ritar', // giorni di ritardo pagamento
      Lingua: 'Lingua', // giorni di ritardo pagamento
      visita: 'visita',
      telefonico: 'telefonico',
      assente: 'assente',
      affiancamento: 'affiancamento',
      vendita: 'vendita',
      assistenza: 'assistenza',
      incasso: 'incasso',
      'Visualizzato su mappa': 'Visualizzato su mappa',
      'È possibile inserire più mail separate da virgola ( , )': 'È possibile inserire più mail separate da virgola ( , )',
      Tagli: 'Tagli',
      CodpClasse: 'Cod. Classe',
      CodpSuperclasse: 'Cod. Superclasse',
      // da tradurre
      'Cliente Compatta': 'Cliente Compatta',
      'Scheda Clienti Compatta': 'Scheda Clienti Compatta',
      'Ordina per nome': 'Ordina per nome',
      'Ordina per cap': 'Ordina per cap',
      'Cliente Compatta2': 'Riepilogo Visivo',
      'Scheda Clienti Compatta2': 'Riepilogo Visivo',
      'Visualizza Compatta Visiva': 'Riepilogo Visivo',
      'Visualizza Compatta Classica': 'Compatta Classica',
      'Visualizza Riepilogo Visivo': 'Riepilogo Visivo',
      Serviti: 'Clienti Serviti',
      Serviti2: 'Serviti Anno prec.',
      'Report Scheda Compatta del #data#': 'Report Scheda Compatta del {0}', // data
      ReportSchedaCompattappdf: 'ReportSchedaCompatta.pdf'
    }
  },
  en: {
    messages: {
      Estelio: 'Estelio',
      'Clienti Totali': 'Total customers',
      'Seleziona un Agente': 'Select an Agent',
      'Nessun Agente presente': 'No Agent present',
      'Seleziona un Area': 'Select an Area',
      'Nessun Area presente': 'No Area present',
      'Seleziona un Cliente': 'Select a Customer',
      Ricercappp: 'Search...',
      'Nessun Cliente trovato': 'No Customer found',
      'Digita nome clienteppp': 'Enter customer name ...',
      Annulla: 'Cancel',
      Home: 'Home',
      Settings: 'Settings',
      'Imposta Agenti': 'Set Agent',
      'Assegna Aree': 'Assign Area',
      'Gestione Classi': 'Class Management',
      'Gestione Campionature': 'Sample request Management',
      'Trasferimento Feedback': 'Feedback Transfer',
      Clienti: 'Customers',
      'Lista Clienti': 'Customer List',
      'Nuovo Cliente': 'New Customer',
      'Scadenze Aperte': 'Open Invoices',
      Microaree: 'Microareas',
      Feedback: 'Feedback',
      'Consulta Feedbacks': 'Check Feedback',
      'Nuovo Feedback': 'New  Feedback',
      Gallery: 'Gallery',
      'Consulta Gallery': 'Check Gallery',
      'Inserimento Immagini': 'Images Inserting',
      Mappa: 'Map',
      Ordini: 'Orders',
      'Nuovo Ordine': 'New Order',
      Campionatura: 'Sample request',
      Reports: 'Report',
      'Fatturato Cliente': 'Customer Turnover',
      'Fatturato Colore': 'Turnover Colour',
      'Visite Cliente': 'Customer visits',
      'Rating Visite': 'Visit Rating',
      'Agente Esteso': 'Agent Broad',
      Listini: 'Pricelist',
      Logout: 'Logout',
      Login: 'Login',
      'Seleziona una Microarea': 'Select a Micro-area',
      'Nessuna microarea presente': 'No micro-area available',
      'Nessuna categoria presente': 'No category available',
      'Nessuna sotto-categoria presente': 'No subcategory available',
      'Nessuna linea presente': 'No line available',
      'Nessun codice commerciale presente': 'No Part-No. available',
      'Seleziona una categoria': 'Select a category',
      'Seleziona una sotto-categoria': 'Select a sub-category',
      'Seleziona una linea': 'Select a range',
      'Seleziona un codice commerciale': 'Select a Part-No.',
      Chiudi: 'Close',
      'Storico #Azienda#': 'History {0}',
      'Sconti Particolari #Azienda#': 'Particular Discounts {0}',
      Sconti: 'Discounts',
      ValiditaDaA: 'Valid Through',
      Fido: 'Credit',
      'Scadp Aperte': 'Open Invoices',
      'Scadp Chiuse': 'Paid Invoices',
      'Valutazione ultimi 24 mesi': 'Evaluation of last 24 months',
      'Np Insoluti': 'No. outstanding payments',
      'Np Assp ppdp': '',
      'Elenco Scadenze Aperte': 'List of open Invoices',
      'Ordina per': 'Sort by',
      Tutte: 'All',
      'Per pagina': 'By  page',
      'Elenco Scadenze Chiuse': 'List of paid invoices',
      locale: 'en-EN',
      Fattura: 'Invoice',
      'Data Fatp': 'Invoice date',
      Importo: 'Amount',
      Ritarp: 'Delay',
      Stato: 'Status',
      Salva: 'Save',
      'si è verificato un errore': 'an error has occurred',
      'La posizione è stata aggiornata': 'The location has been updated',
      Drive: 'Drive',
      'ultima visita': 'last visit',
      ordine: 'order',
      Sposta: 'Move',
      Visitato: 'Visited',
      'Dettagli Cliente': 'Customer Details',
      'Trasferimento Feedbacks Projx': 'Feedback Transfer',
      'Modifica Feedback': 'Modify Feedback',
      'Nuovo Ordine Campionatura': 'New Samples request',
      'Sync Users': 'Sync Users',
      Ricerca: 'Search',
      Agente: 'Agent',
      Nazioni: 'Country',
      Azioni: 'Actions',
      'Salva Modifiche': 'Save Changes',
      'Aggiungi sottoagente a #agente#': ' Add sub agent to {0}',
      'Aggiungi area a #agente#': ' Add area to {0}',
      'Salvare le modifiche effettuate?': 'Save the changes made?',
      'Modifiche Salvate': 'Changes Saved',
      Rimuovi: 'Remove',
      'Rimuovere l area #nome#?': 'Remove area {0}?',
      'Inserire l area #nome#?': 'Enter area {0}?',
      'Impossibile assegnare l agente a se stesso': 'Unable to assign agent',
      'Impossibile assegnare all agente un elemento direttamente ascendente': 'Cannot assign a directly ascending element to the agent',
      Conferma: 'Confirm',
      'Attenzione L agente selezionato è già assegnatop Spostare l agente #nome# insieme a tutta la sua struttura discendente?': 'Attention.\nThe selected agent is already assigned.\nMove agent {0} along with all its descendant structure?',
      'Inserire l agente #nome#?': 'Enter agent {0}?',
      Elimina: 'Delete',
      'Eliminare l agente #nome# e tutta la sottostruttura?': 'Delete agent {0} and all substructure?',
      'Aggiungi Nuovo': 'Add New',
      Codp: 'Part-No.',
      Rotolino: 'Small roll',
      'Print Roll': 'Print Roll',
      'Roll Print (40x70)': 'Print Roll (40x70)',
      A4: 'A4',
      A5: 'A5',
      'Solo Linea': 'The range only',
      'Prodotti aggiornati': 'Updated products',
      'Cerca in': 'Search in',
      'Cerca ppp': 'Search ...',
      'Superclasse aggiornata': 'Superclass updated',
      'Trasferisce i feedback e le immagini da clienti projx chiusi a clienti projx attivi': 'Transfer feedback and images from closed projx clients to active projx clients.',
      'I feedback trasferiti avranno la dicitura Trasferito ex': 'The transferred feedbacks will have the words \'Transferred ex: XXXXX\' added to the end of the transferred feedback notes.',
      'Clienti Projx chiusi': 'Closed Projx customers.',
      'Trasferisci da': 'Transfer from',
      'Clienti Projx aperti': 'Active Projx customers.',
      'Trasferisci a': 'Transfer to',
      'Feedback e Immagini trasferiti': 'Feedback and Images transferred',
      'Esporta in PDF': 'Export to PDF',
      Evidenzia: 'Highlight',
      'Anno Precedente': 'Last year',
      'Anno Corrente': 'Current year',
      Differenza: 'Difference',
      'Raggruppa per area': 'Group by area',
      'Nascondi non evidenziati': 'Hide unhighlighted',
      'Filtra agente': 'FIlter Agent',
      'Filtra area': 'Filter Area',
      'Filtra microarea': 'Filter Micro-area',
      'DA VISITARE': 'TO BE VISITED',
      Cliente: 'Customer',
      Area: 'Area',
      Cap: 'Postal code',
      Codice: 'Part No.',
      Visita: 'Visit',
      Tot: 'Total',
      Gen: 'January',
      Feb: 'February',
      Mar: 'March',
      Apr: 'April',
      Mag: 'May',
      Giu: 'June',
      Lug: 'July',
      Ago: 'August',
      Set: 'September',
      Ott: 'October',
      Nov: 'November',
      Dic: 'December',
      'Report Fatturato Cliente del #data#': 'Customer Turnover Report of {0}',
      ReportFatturatoClienteppdf: 'Customer Turnover Report.pdf',
      Totale: 'Total',
      Prospect: 'Prospect',
      Info: 'Info',
      Attivi: 'Active',
      'Numero Nero': 'Black Number',
      'Numero Blu': 'Blue Number',
      'numero di clienti che hanno fatto un ordine negli ultimi 3 anni': 'number of customers who have placed an order in the last 3 years',
      'numero di clienti univoci visitati nel periodo di tempo selezionato (a prescidere dal fatti che questi abbiano effettuato un ordine negli ultimi 3 anni)': 'number of unique customers visited in the selected time period (regardless of whether they have placed an order in the last 3 years)',
      'numero di prospect univoci visitati nel periodo di tempo selezionato': 'number of unique prospects visited in the selected time period',
      TOTALE: 'TOTAL',
      attivi: 'Active',
      Anno: 'Year',
      'Report Visite Cliente del #data#': 'Customer Visits Report of {0}',
      ReportVisiteClienteppdf: 'Customer Visits Report.pdf',
      'ORDINA PER FATTURATO': 'SORT BY TURNOVER',
      'ORDINA PER METRI QUADRI': 'SORT BY SQUARE METERS',
      Colore: 'Colour',
      'Totale #Area#': 'Total {0}',
      'Totale Serie #Nome#': 'Total Series {0}',
      TUTTI: 'ALL',
      Visualizza: 'View',
      Tutti: 'All',
      'Seleziona Anno': 'Select Year',
      'Nessun report trovato': 'No report found',
      'Report Rating Visite del #data#': 'Visits Rating Report of {0}',
      ReportRatingVisiteppdf: 'VisitsRatingReport.pdf',
      Ripristina: 'Reset',
      Modifica: 'Modify',
      Rimuovere: 'Remove',
      'Il Codice Cliente digitato è già memorizzato come Cliente Projxp Si conferma di voler unire il Prospect al Cliente #Nome# Indirizzo: #Indirizzo# #Citta# (CAP: #CAP#)?': 'The entered Customer Code is already stored as a Projx Customer. Do you confirm that you want to merge the Prospect to the Client {0}\nAddress: {1} {2} (ZIP: {3})?"',
      'Visualizza Storico': 'History',
      'Sconti Cliente': 'Client Discounts',
      'Codice Cliente': 'Customer Code',
      'Ragione Sociale': 'Company name',
      PpIVA: 'VAT No.',
      CodpFiscale: 'Tax Code',
      Indirizzo: 'Address',
      CAP: 'Postal Code',
      Città: 'City',
      Provincia: 'District',
      Nazione: 'Country',
      Telefono: 'Phone',
      'E-Mail': 'E-Mail',
      Website: 'Website',
      Fornitore: 'Supplier',
      Note: 'Note',
      'Note Projx': 'Projx Note',
      Azienda: 'Company',
      Via: 'Street',
      'Tipologia Indirizzo': 'Address type',
      Nome: 'Name',
      'Tipo dispositivo': 'Device type',
      'Modello dispositivo': 'Device model',
      Nominativo: 'Name',
      Tipo: 'Type',
      Cellulare: 'Mobile',
      'Cliente inattivo o non trovato': 'Inactive or not found customer',
      'Sede Operativa': 'Workshop Address',
      'Sede Legale': 'Billing Address',
      'Indirizzo di spedizione': 'Shipping Address',
      Calandra: 'Calender',
      Plotter: 'Plotter',
      Pressa: 'Press',
      'Stampante latex': 'Latex Printer',
      'Stampante solvente': 'Solvent Printer',
      'Stampante UV': 'UV Printer',
      Amministrazione: 'Administration',
      Referente: 'Contact Person',
      Commerciale: 'Commercial',
      Responsabile: 'Manager',
      Segreteria: 'Office',
      'Prospect Unito al Cliente #nome# Indirizzo: #via# #citta# (CAP: #cap#)': 'Prospect Merged with Client {0}\nAddress: {1} {2} (ZIP: {3})',
      'Digitare la Ragione Sociale': 'Enter the Company Name',
      Compilare: 'Fill in',
      'Impostare almeno mail o telefono': 'Set at least an E-Mail or telephone',
      'Cliente Creato': 'Customer Created',
      'Cliente Modificato': 'Customer Modified',
      'Salvare per confermare le modifiche': 'Save to confirm the changes',
      'Non è possibile eliminare l indirizzo preferito': 'The favourite address cannot be deleted',
      'Sei sicuro di voler eliminare l indirizzo #azienda# selezionato?': 'Are you sure you want to delete the selected {0} address?',
      'Sei sicuro di voler eliminare il dispositivo #brand#?': 'Are you sure you want to delete device {0}?',
      'Sei sicuro di voler eliminare il contatto #nome#?': 'Are you sure you want to delete the contact {0}?',
      'Mostra attivi': 'Show active',
      'Mostra eliminati': 'Show deleted',
      'Esporta in Excel': 'Export to Excel',
      'Data Ultimo Ordine': 'Date of Last Order',
      Da: 'From',
      A: 'To',
      'Seleziona una data': 'Select a date',
      'Data Ultima Visita': 'Date of Last Visit',
      Projx: 'Projx',
      Prospects: 'Prospects',
      'Non presenti in mappa': 'Not present on the map',
      'Presenti in mappa': 'Present on the map',
      'Numero Stelle': 'Number of Stars',
      CodpCliente: 'Customer Code',
      di: 'of',
      'ATTENZIONE - Eliminare definitivamente lanagrafica #cliente#?': 'ATTENTION - Do you want to permanently delete customer {0}?',
      'Eliminare l anagrafica #cliente#?': 'Do you want to delete customer {0}?',
      'Ripristinare l anagrafica #cliente#?': 'Do you want to restore customer {0}?',
      Microarea: 'Micro-area',
      'Ultimo Ordine': 'Last Order',
      'Ultima Visita': 'Last visit',
      'Cliente Eliminato': 'Deleted Customer',
      'Il Cliente deriva da Projx': 'The Customer derives from Projx: impossible to delete the Customer',
      'Cliente Ripristinato': 'Restored Customer',
      'Codp Cliente': 'Customer Code',
      Cd_Agent: 'Agent Code',
      PIVA: 'VAT No.',
      Stelle: 'Stars',
      'Anno Mobile 0/12': 'Anno Mobile 0/12',
      'Anno Mobile 12/24': 'Anno Mobile 12/24',
      Filtri: 'Filters',
      'Il cliente non ordina da più di due anni ed è stato nascosto': 'The customer hasn\'t ordered for more than two years and has been hidden',
      'Forzare la visualizzazione sulla mappa?': 'Do you want to force display on the map?',
      'Attiva sulla mappa': 'Activate on the map',
      'Il cliente non ordina da più di due anni ed è stato forzato': 'The customer hasn\'t ordered for more than two years and was forced',
      'Nascondere la visualizzazione sulla mappa?': 'Do you want to hide the map view?',
      'Nascondi dalla mappa': 'Hide the map view.',
      Imposta: 'Set up',
      'Filtro Area': 'Area filter',
      'Filtro Microarea': 'Micro-area filter',
      'Giro Visita (Data)': 'Visit Tour (Date)',
      gg: 'dd',
      'È necessario accettare i permessi di gelocalizzazione': 'You need to accept geo-location permissions',
      'Il browser non supporta le funzioni di geolocalizzazione': 'The browser does not support geolocation functions',
      'Errore: Richiesta di geolocalizzazione fallitap': 'Error: Geo-location request failed.',
      'Errore: Il browser non supporta le funzioni di geolocalizzazionep': 'Error: The browser does not support geo-location functions.',
      'Stai già impostando una location Salvare o impostare l operazione corrente prima di continuare': 'You are already setting up a location\nSave or set the current operation before continuing',
      'Selezionare almeno un Cliente': 'Select at least one Customer',
      'Giro salvato': 'Tour saved',
      'Tipo Contatto': 'Contact Type',
      'Finalità Contatto': 'Contact Purpose',
      'Prodotti presentati': 'Presented Products',
      'Nessun prodotto selezionato': 'No products selected',
      OK: 'OK',
      'Prodotti con problematiche': 'Products with problems',
      'Note / Testo libero': 'Notes / Free text',
      Campionature: 'Samples request',
      'Nessuna campionatura selezionata': 'No samples required',
      Campioni: 'Samples',
      Marketing: 'Marketing',
      Aggiungi: 'Add',
      'Nessun campione presente': 'No samples available',
      'Seleziona un campione': 'Select a sample',
      'Esito visita': 'Outcome of the visit',
      'Feedback non trovato o non modificabile': 'Feedback not found or not possible to be modified',
      'Cliente non trovato': 'Customer not found',
      'Sei sicuro di voler eliminare questo Feedback?': 'Are you sure you want to delete this Feedback?',
      'Feedback eliminato': 'Feedback deleted',
      'Selezionare un Cliente': 'Select a Customer',
      'Selezionare la Data di visita': 'Select the Date of visit',
      'Selezionare il tipo di contatto': 'Select the contact type',
      'Selezionare la finalità del contatto': 'Select the contact purpose',
      'Specificare rating prodotti presentati': 'Specify ratings for presented product',
      'Specificare rating prodotti con problematiche': 'Specify ratings for product with problems',
      'Indicare l argomento della prossima visita': 'Specify the topic of the next visit',
      'Selezionare l esito della visita': 'Select the outcome of the visit',
      'Feedback generato': 'Feedback generated',
      'Feedback modificato': 'Feedback modified',
      'Prodotto già presente': 'Product already present',
      'Ricerca libera': 'Free search',
      'Digita qui la parola o le parole da cercare': 'Type here the word or words you are searching',
      'Comandi di ricerca': 'Search commands',
      'Comandi riga1': '* after a word (or part of a word) allows you to search for all those words starting with the string entered.',
      'Comandi riga2': '- before a word (or part of a word) allows you to exclude all those words that contain the string entered.',
      'Ricerca testo in': 'Text search in',
      'Presentazione prodotti': 'Product presentation',
      Cerca: 'Search',
      Filtro: 'Filter',
      'Deve contenere': 'It must contain',
      'Prodotti con problemi': 'Products with problems',
      Testo: 'Text',
      'Reset / Nuova Ricerca': 'Reset / New Search',
      'Ordina campioni': 'Require samples',
      'Sei sicuro di voler resettare il form di ricerca?': 'Are you sure you want to reset the search form?',
      Reset: 'Reset',
      'Visualizza solo': 'View only',
      'Argomento prossima visita': 'Topic next visit',
      'Totale Feedbacks': 'Total of Feedbacks',
      'Data Visita': 'Date of Visit',
      'Presentazione Prodotti': 'Product presentation',
      'Gestione problematiche': 'Problem management',
      'Nessun feedback trovato secondo i parametri di ricerca impostati': 'No feedback found according to the  search parameters set',
      'Selezionare almeno un campo di ricerca testo': 'Select at least one search field text',
      'Seleziona almeno un campo in cui ricercare il testo': 'Select at least one field in which to search for text',
      'Imposta i parametri di ricerca!': 'Set your search parameters!',
      'Digita qui la parola o le parole da inserire': 'Type here the word or words to be entered',
      'Etichette immagini selezionate': 'Selected image labels',
      'Nessuna immagine selezionata': 'No image labels selected',
      'Sei sicuro di voler rimuovere questa Etichetta da #Numero# immagini?': 'Are you sure you want to remove this Label from {0} images?',
      'Etichette da aggiungere': 'Labels to add',
      'Nessuna etichetta inserita': 'No label add',
      Operazioni: 'Operations',
      'Aggiungi Etichetta': 'Add Label',
      'Elimina Immagini': 'Delete Images',
      'Sei sicuro di voler aggiornare le etichette di #Numero# immagini?': 'Are you sure you want to update the tags of {0} images?',
      'Sei sicuro di voler eliminare #Numero# immagini?': 'Are you sure you want to delete {0} images?',
      'Totale Immagini': 'Total Images',
      Etichette: 'Labels',
      'Nessuna immagine trovata secondo i parametri di ricerca impostati': 'No images found according to the search parameters set',
      'Sei sicuro di voler rimuovere quest Immagine?': 'Are you sure you want to remove this image?',
      'Immagine eliminata': 'Image deleted',
      'Immagini eliminate': 'Images deleted',
      'Etichetta rimossa': 'Label removed',
      'ERRORE: Etichetta non rimossa': 'ERROR: Label not removed',
      'Etichette aggiornate': 'Labels updated',
      'Selezionare almeno un immagine': 'Select at least one image',
      'Selezionare almeno un Testo Libero o un Cliente o un Prodotto da inserire': 'Select at least one Free Text or a Customer or a Product to insert',
      'Etichetta già presente': 'Label already foreseen',
      'Imposta le proprietà dell immagine!': 'Set image properties!',
      'Carica immagini': 'Upload images',
      'Solo uso interno': 'Confidential use only',
      'Cliente sconosciuto': 'Unknown customer',
      'Prodotti nelle immagini': 'Products visible in the images',
      Carica: 'Upload',
      'Immagini pre-caricate': 'Pre-loaded images',
      'Caricare almeno un immagine': 'Upload at least one image',
      'Compilare tutti campi dei prodotti': 'Fill in all product fields',
      'Immagini caricate': 'Uploaded images',
      'Immagine caricata': 'Uploaded image',
      'Buongiorno #nome#': 'Hello {0}',
      'Seleziona agente': 'Select agent',
      'Fatturato #Mese#': 'Turnover {0}',
      'Riepilogo Mese #Mese#': 'Month Summary {0}',
      'Clienti visitati a #Mese#': 'Visited customers at {0}',
      'Visite clienti univoci': 'Visits Unique customers',
      Visitati: 'Visited',
      Obiettivo: 'Target',
      Attuale: 'Current',
      'Aggiungi Microarea': 'Add Micro-area',
      '#Numero# selezionatip': '{0} selected',
      'Pulisci selezione': 'Clean selection',
      'Assegna selezionati a microarea': 'Assign selected to micro-area',
      'Errore nella eliminazione della microarea': 'Error in eliminating the micro-area',
      'Errore nell aggiunta della microarea': 'Error adding micro-area',
      'Errore nell aggiornamento della microarea': 'Error in updating the micro-area',
      'Clienti assegnati': 'Assigned customers',
      'Errore nell assegnazione della microarea': 'Error in assigning the micro-area',
      'Ordine Effettuato': 'Order placed',
      'Una mail di riepilogo è stata mandata a #mail#': 'A summary E-Mail has been sent to {0}',
      'Dati di spedizione': 'Shipping data',
      'Azienda o Nome': 'Company or Name',
      'Via e numero civico': 'Street and Street Number',
      'Rimuovere il prodotto #codice#?': 'Remove product {0}?',
      campioni: 'samples',
      marketing: 'marketing',
      'Salva bozza': 'Save draft',
      'Conferma ordine': 'Confirm order',
      'Non Salvare': 'Do not save',
      'Il carrello è stato modificato, salvarlo prima di uscire?': 'The cart has been modified, save it before leaving?',
      Obbligatorio: 'Required field',
      'E-Mail non valida': 'Invalid E-Mail',
      'I seguenti campioni non sono più disponibili e non sono stati aggiunti all ordine:': 'The following samples are no longer available and have not been added to the order:',
      'Seleziona un Prodotto': 'Select a Product',
      'Seleziona un Campione': 'Select a sample',
      'Compilare i dati di spedizione': 'Fill in the shipping information',
      'Selezionare almeno un prodotto': 'Select at least one product',
      'Selezionare il tipo di articolo': 'Select the type of article',
      'Compilare i campi dell articolo': 'Fill in the fields of the article',
      'Si è verificato un errore': 'An error has occurred',
      'Bozza salvata': 'Draft saved',
      'Spedisci ad un indirizzo diverso da quello di fatturazione': 'Ship to an address other than the billing address',
      'Dati di fatturazione': 'Billing information',
      'Partita Iva': 'VAT No.',
      'Codice Fiscale': 'Tax Code',
      IBAN: 'IBAN',
      Banca: 'Bank',
      Pagamento: 'Payment',
      SDI: 'SDI',
      PEC: 'PEC',
      'Email Notifica Ordine': 'E-Mail for Order Notification',
      Altp: 'Width',
      Lunp: 'Length',
      Qty: 'Qty',
      prodotto: 'Product',
      campione: 'Sample',
      'Note Extra': 'Extra Notes',
      'Richiedi autorizzazione': 'Request authorisation',
      Email: 'E-Mail',
      'Descrizione Campione': 'Sample description',
      Prodotti: 'Products',
      Forato: 'Sprocketed',
      'Non Forato': 'None Sprocketed',
      'Compilare i dati di fatturazione': 'Fill in your billing information',
      'Compilare la mail di autorizzazione con una mail valida': 'Fill in the authorisation E-Mail with a valid E-Mail',
      'Selezionare un altezza': 'Select a width',
      'Selezionare una lunghezza': 'Select a length',
      'Selezionare le misure dell articolo': 'Select the size of the item',
      Totp: 'Tot.',
      Scadenza: 'Expiry',
      Fattp: 'Invoice',
      Pagam: 'Payment',
      Ritar: 'Delay',
      Lingua: 'Language',
      visita: 'Visit',
      telefonico: 'telephone',
      assente: 'absent',
      vendita: 'sale',
      assistenza: 'assistance',
      incasso: 'collection of invoices',
      'Visualizzato su mappa': 'Displayed on the map',
      'È possibile inserire più mail separate da virgola ( , )': 'It is possible to insert multiple E-Mails separated by commas (,)',
      Tagli: 'Cut',
      CodpClasse: 'Class code',
      CodpSuperclasse: 'Superclass code'
    }
  },
  fr: {
    messages: {
    }
  },
  es: {
    messages: {
    }
  },
  de: {
    messages: {
      Estelio: 'Estelio',
      'Clienti Totali': 'Kunden gesamt',
      'Seleziona un Agente': 'Vertreter wählen',
      'Nessun Agente presente': 'Kein Vertreter vorhanden',
      'Seleziona un Area': 'Wählen Sie einen Gebiet',
      'Nessun Area presente': 'Kein Gebiet vorhanden',
      'Seleziona un Cliente': 'Wählen Sie einen Kunden',
      Ricercappp: 'Suchen ...',
      'Nessun Cliente trovato': 'Kein Kunde gefunden',
      'Digita nome clienteppp': 'Kundennamen eingeben...',
      Annulla: 'Löschen',
      Home: 'Home',
      Settings: 'Settings',
      'Imposta Agenti': 'Vertreter festlegen',
      'Assegna Aree': 'Gebiet zuweisen',
      'Gestione Classi': 'Klassenverwaltung',
      'Gestione Campionature': 'Verwaltung der Bemusterung',
      'Trasferimento Feedback': 'Feedback-Übertragung',
      Clienti: 'Kunden',
      'Lista Clienti': 'Kundenliste',
      'Nuovo Cliente': 'Neuer Kunde',
      'Scadenze Aperte': 'Offene Posten',
      Microaree: 'Mikroarea',
      Feedback: 'Feedback',
      'Consulta Feedbacks': 'Siehe Feedbacks',
      'Nuovo Feedback': 'Neues Feedback',
      Gallery: 'Galerie',
      'Consulta Gallery': 'Siehe Galerie',
      'Inserimento Immagini': 'Bilder hinzufügen',
      Mappa: 'Karte',
      Ordini: 'Bestellungen',
      'Nuovo Ordine': 'Neue Bestellung',
      Campionatura: 'Bemusterung',
      Reports: 'Bericht',
      'Fatturato Cliente': 'Umsatz Kunde',
      'Fatturato Colore': 'Umsatz Farbe',
      'Visite Cliente': 'Kundenbesuche',
      'Rating Visite': 'Bewertung Kundenbesuch',
      Listini: 'Preisliste',
      Logout: 'Logout',
      Login: 'Login',
      'Seleziona una Microarea': 'Wählen Sie eine Mikroarea',
      'Nessuna microarea presente': 'Keine Mikroarea vorhanden',
      'Nessuna categoria presente': 'Keine Kategorie vorhanden',
      'Nessuna sotto-categoria presente': 'Keine Unterkategorie vorhanden',
      'Nessuna linea presente': 'Keine Serie vorhanden',
      'Nessun codice commerciale presente': 'Keine Artikel-Nr. vorhanden',
      'Seleziona una categoria': 'Wählen Sie eine Kategorie aus',
      'Seleziona una sotto-categoria': 'Wählen Sie eine Unterkategorie',
      'Seleziona una linea': 'Wählen Sie eine Serie aus',
      'Seleziona un codice commerciale': 'Wählen Sie eine Artikel-Nr.',
      Chiudi: 'Schließen',
      'Storico #Azienda#': 'Historie {0}',
      Fido: 'Kredit',
      'Scadp Aperte': 'Offene Posten',
      'Scadp Chiuse': 'Bezahlte Rechnungen',
      'Valutazione ultimi 24 mesi': 'Auswertung der letzten 24 Monate',
      'Np Insoluti': 'Nr. ausstehende Zahlungen',
      'Np Assp ppdp': '',
      'Elenco Scadenze Aperte': 'Liste offener Posten',
      'Ordina per': 'Sortieren nach',
      Tutte: 'Alle',
      'Per pagina': 'Nach Seite',
      'Elenco Scadenze Chiuse': 'Liste bezahlter Rechnungen',
      locale: 'de-DE',
      Fattura: 'Rechnung',
      'Data Fatp': 'Rechnungsdatum',
      Importo: 'Betrag',
      Ritarp: 'Verspätung',
      Stato: 'Status',
      Salva: 'Speichern',
      'si è verificato un errore': 'Ein Fehler ist aufgetreten',
      'La posizione è stata aggiornata': 'Der Standort wurde aktualisiert',
      Drive: 'Drive',
      'ultima visita': 'letzter Besuch',
      ordine: 'Bestellung',
      Sposta: 'Bewegen',
      Visitato: 'Besucht',
      'Dettagli Cliente': 'Kundendetails',
      'Trasferimento Feedbacks Projx': 'Feedback übertragen',
      'Modifica Feedback': 'Feedback ändern',
      'Nuovo Ordine Campionatura': 'Neue Bemusterung',
      'Sync Users': 'Sync Users',
      Ricerca: 'Suche',
      Agente: 'Vertreter',
      Nazioni: 'Staat',
      Azioni: 'Aktionen',
      'Salva Modifiche': 'Änderungen speichern',
      'Aggiungi sottoagente a #agente#': 'Subvertreter zu {0} hinzufügen',
      'Aggiungi area a #agente#': ' Bereich zu {0} hinzufügen',
      'Salvare le modifiche effettuate?': 'Die vorgenommenen Änderungen speichern?',
      'Modifiche Salvate': 'Änderungen gespeichert',
      Rimuovi: 'Entfernen',
      'Rimuovere l area #nome#?': 'Bereich {0} entfernen?',
      'Inserire l area #nome#?': 'Bereich {0} hinzufügen?',
      'Impossibile assegnare l agente a se stesso': 'Vertreter kann sich nicht selbst zuordnen',
      'Impossibile assegnare all agente un elemento direttamente ascendente': 'Dem Vertreter kann kein direkt aufsteigendes Element zugewiesen werden',
      Conferma: 'Bestätigen',
      'Attenzione L agente selezionato è già assegnatop Spostare l agente #nome# insieme a tutta la sua struttura discendente?': 'Achtung. Der ausgewählte Vertreter ist bereits zugewiesen.\nVertreter {0} zusammen mit seiner Nachkommenstruktur verschieben?',
      'Inserire l agente #nome#?': 'Vertreter {0} hinzufügen ?',
      Elimina: 'Löschen',
      'Eliminare l agente #nome# e tutta la sottostruttura?': 'Vertreter {0} und alle Unterstruktur löschen?',
      'Aggiungi Nuovo': 'Neu hinzufügen',
      Codp: 'Artikel-Nr.',
      Rotolino: 'Kleine Rolle',
      'Print Roll': 'Print Roll',
      'Roll Print (40x70)': 'Print Roll (40x70)',
      A4: 'A4',
      A5: 'A5',
      'Solo Linea': 'Nur die Serie',
      'Prodotti aggiornati': 'Aktualisierte Produkte',
      'Cerca in': 'Suchen in',
      'Cerca ppp': 'Suchen ...',
      'Superclasse aggiornata': 'Superklasse aktualisiert',
      'Trasferisce i feedback e le immagini da clienti projx chiusi a clienti projx attivi': 'Übertragen Sie Feedback und Bilder von geschlossenen projx-Kunden an aktive projx-Kunden.',
      'I feedback trasferiti avranno la dicitura Trasferito ex: XXXXX aggiunta alla fine delle note dei feedback trasferiti': 'Die übertragenen Feedbacks werden am Ende der übertragenen Feedback-Notizen mit den Worten „Übertragen ex: XXXXX“ versehen.',
      'Clienti Projx chiusi': 'Geschlossene Projx-Kunden',
      'Trasferisci da': 'Übertragen von',
      'Clienti Projx aperti': 'Aktive Projx-Kunden',
      'Trasferisci a': 'Übertragen auf',
      'Feedback e Immagini trasferiti': 'Feedback und Bilder übertragen',
      'Esporta in PDF': 'Als PDF exportieren',
      Evidenzia: 'Markieren',
      'Anno Precedente': 'Letztes Jahr',
      'Anno Corrente': 'Laufendes Jahr',
      Differenza: 'Unterschied',
      'Raggruppa per area': 'Nach Bereich gruppieren',
      'Filtra agente': 'Vertreter filtern',
      'Filtra area': 'Bereich filtern',
      'Filtra microarea': 'Mikrobereich filtern',
      'DA VISITARE': 'ZU BESUCHEN',
      Cliente: 'Kunde',
      Area: 'Bereich',
      Cap: 'PLZ',
      Codice: 'Artikel-Nr.',
      Visita: 'Besuch',
      Tot: 'Gesamt',
      Gen: 'Januar',
      Feb: 'Februar',
      Mar: 'März',
      Apr: 'April',
      Mag: 'Mai',
      Giu: 'Juni',
      Lug: 'Juli',
      Ago: 'August',
      Set: 'September',
      Ott: 'Oktober',
      Nov: 'November',
      Dic: 'Dezember',
      'Report Fatturato Cliente del #data#': 'Kundenumsatzbericht von {0}',
      ReportFatturatoClienteppdf: 'Kundenumsatzbericht.PDF',
      Totale: 'Gesamt',
      Prospect: 'Potentielle Kunde',
      Info: 'Info',
      Attivi: 'Alktiv',
      'Numero Nero': 'Schwarze Nummer',
      'Numero Blu': 'Blaue Nummer',
      'numero di clienti che hanno fatto un ordine negli ultimi 3 anni': 'Anzahl der Kunden, die in den letzten 3 Jahren eine Bestellung aufgegeben haben',
      'numero di clienti univoci visitati nel periodo di tempo selezionato (a prescidere dal fatti che questi abbiano effettuato un ordine negli ultimi 3 anni)': 'Anzahl der im ausgewählten Zeitraum besuchten einzartigen Kunden (unabhängig davon, ob sie in den letzten 3 Jahren eine Bestellung aufgegeben haben)',
      'numero di prospect univoci visitati nel periodo di tempo selezionato': 'Anzahl der einzartigen potentiellen Kunden, die im ausgewählten Zeitraum besucht wurden',
      TOTALE: 'GESAMT',
      attivi: 'Aktiv',
      Anno: 'Jahr',
      'Report Visite Cliente del #data#': 'Kundenbesuchsbericht von {0}',
      ReportVisiteClienteppdf: 'Kundenbesuchsbericht.PDF',
      'ORDINA PER FATTURATO': 'NACH UMSATZ SORTIEREN',
      'ORDINA PER METRI QUADRI': 'NACH QUADRATMETER SORTIEREN',
      Colore: 'Farbe',
      'Totale #Area#': 'Gesamt {0}',
      'Totale Serie #Nome#': 'Gesamt Serie {0}',
      TUTTI: 'ALLE',
      Visualizza: 'Schauen',
      Tutti: 'Alle',
      'Seleziona Anno': 'Jahr auswählen',
      'Nessun report trovato': 'Kein Bericht gefunden',
      'Report Rating Visite del #data#': 'Bericht der Besuchebewertung von {0}',
      ReportRatingVisiteppdf: 'BerichtderBesuchebewertung.PDF',
      Ripristina: 'Zurücksetzen',
      Modifica: 'Ändern',
      Rimuovere: 'Löschen',
      'Il Codice Cliente digitato è già memorizzato come Cliente Projxp Si conferma di voler unire il Prospect al Cliente #Nome# Indirizzo: #Indirizzo# #Citta# (CAP: #CAP#)?': 'Die eingegebene Kundennummer ist bereits als Projx-Kunde gespeichert. Bestätigen Sie, dass Sie dem Kunden {0} mischen möchten.\nAdresse: {1} {2} (PLZ: {3})?',
      'Visualizza Storico': 'Historie',
      'Codice Cliente': 'Kunden-Nr.',
      'Ragione Sociale': 'Firmenname',
      PpIVA: 'VAT-Nr.',
      CodpFiscale: 'Steuernummer',
      Indirizzo: 'Adresse',
      CAP: 'PLZ',
      Città: 'Stadt',
      Provincia: 'Land',
      Nazione: 'Staat',
      Telefono: 'Tel._Nr.',
      'E-Mail': 'E-Mail',
      Website: 'Webseite',
      Fornitore: 'Lieferant',
      Note: 'Note',
      'Note Projx': 'Projx-Note',
      Azienda: 'Firma',
      Via: 'Straße',
      'Tipologia Indirizzo': 'Adresstyp',
      Nome: 'Name',
      'Tipo dispositivo': 'Gerätetyp',
      'Modello dispositivo': 'Gerätemodell',
      Nominativo: 'Name',
      Tipo: 'Typ',
      Cellulare: 'Handy',
      'Cliente inattivo o non trovato': 'Inaktiver oder nicht gefundener Kunde',
      'Sede Operativa': 'Werkstatt-Adresse',
      'Sede Legale': 'Rechnungsadresse',
      'Indirizzo di spedizione': 'Lieferadresse',
      Calandra: 'Kalander',
      Plotter: 'Plotter',
      Pressa: 'Drucker',
      'Stampante latex': 'Latex-Druker',
      'Stampante solvente': 'Solvent-Drucker',
      'Stampante UV': 'UV-Drucker',
      Amministrazione: 'Verwaltung',
      Referente: 'Ansprechpartner',
      Commerciale: 'Kaufmännischer Leiter',
      Responsabile: 'Leiter',
      Segreteria: 'Büro',
      'Prospect Unito al Cliente #nome# Indirizzo: #via# #citta# (CAP: #cap#)': '"Potentieller Kunde mit dem Kunden {0} gemischt\nAdresse: {1} {2} (PLZ: {3})"',
      'Digitare la Ragione Sociale': 'Firmenname eingeben',
      Compilare: 'Eintragen',
      'Impostare almeno mail o telefono': 'Zumindest E-Mail oder Telefon-Nr.  eingeben.',
      'Cliente Creato': 'Kunde erstellt',
      'Cliente Modificato': 'Kunde geändert',
      'Salvare per confermare le modifiche': 'Speichern, um die Änderungen zu bestätigen',
      'Non è possibile eliminare l indirizzo preferito': 'Die Favoritenadresse kann nicht gelöscht werden',
      'Sei sicuro di voler eliminare l indirizzo #azienda# selezionato?': 'Möchten Sie die ausgewählte {0}-Adresse wirklich löschen?',
      'Sei sicuro di voler eliminare il dispositivo #brand#?': 'Möchten Sie das Gerät {0} wirklich löschen?',
      'Sei sicuro di voler eliminare il contatto #nome#?': 'Möchten Sie den Kontakt {0} wirklich löschen?',
      'Mostra attivi': 'Aktive anzeigen',
      'Mostra eliminati': 'Gelöschte anzeigen',
      'Esporta in Excel': 'In Excel exportieren',
      'Data Ultimo Ordine': 'Letztes Bestelldatum',
      Da: 'Ab',
      A: 'Bis',
      'Seleziona una data': 'Wählen Sie ein Datum',
      'Data Ultima Visita': 'Datum des letzten Besuchs',
      Projx: 'Projx',
      Prospects: 'Potentieller Kunde',
      'Non presenti in mappa': 'Nicht auf der Karte vorhanden',
      'Presenti in mappa': 'Auf der Karte vorhanden',
      'Numero Stelle': 'Anzahl der Sterne',
      CodpCliente: 'Kunde-Nr.',
      di: 'von',
      'ATTENZIONE - Eliminare definitivamente lanagrafica #cliente#?': 'ACHTUNG - Möchten Sie definitiv der Kunde {0} löschen?',
      'Eliminare l anagrafica #cliente#?': 'Möchten Sie der Kunde {0} löschen?',
      'Ripristinare l anagrafica #cliente#?': 'Möchten Sie den Kunden {0} wiederherstellen?',
      Microarea: 'Mikroarea',
      'Ultimo Ordine': 'Letzte Bestellung',
      'Ultima Visita': 'Letzter Besuch',
      'Cliente Eliminato': 'Kunde gelöscht',
      'Il Cliente deriva da Projx: impossibile eliminare il Cliente': 'Der Kunde stammt von Projx: Der Kunde kann nicht gelöscht werden',
      'Cliente Ripristinato': 'Kunde wiederhergestellt',
      'Codp Cliente': 'Kunde-Nr.',
      Cd_Agent: 'Vertreter-Nr.',
      PIVA: 'VAT-Nr.',
      Stelle: 'Sterne',
      'Anno Mobile 0/12': 'Anno Mobile 0/12',
      'Anno Mobile 12/24': 'Anno Mobile 12/24',
      Filtri: 'Filter',
      'Il cliente non ordina da più di due anni ed è stato nascosto': 'Der Kunde hat seit mehr als zwei Jahren nicht bestellt und wurde ausgeblendet',
      'Forzare la visualizzazione sulla mappa?': 'Möchten Sie die Anzeige auf die Karte erzwingen?',
      'Attiva sulla mappa': 'Auf der Karte aktivieren',
      'Il cliente non ordina da più di due anni ed è stato forzato': 'Der Kunde hat seit mehr als zwei Jahren nicht bestellt und wurde erzwungen',
      'Nascondere la visualizzazione sulla mappa?': 'Möchten Sie den Kartenansicht ausblenden?',
      'Nascondi dalla mappa': 'Kartenansicht ausblenden.',
      Imposta: 'Erstellen',
      'Filtro Area': 'Filter Area',
      'Filtro Microarea': 'Filter Microarea',
      'Giro Visita (Data)': 'Tour Planung (Datum)',
      gg: 'Tage',
      'È necessario accettare i permessi di gelocalizzazione': 'Sie müssen die Berechtigungen für die Geolokalisierung akzeptieren',
      'Il browser non supporta le funzioni di geolocalizzazione': 'Der Browser unterstützt keine Geolokalisierungsfunktionen',
      'Errore: Richiesta di geolocalizzazione fallitap': 'Fehler: Geolocation-Anfrage fehlgeschlagen.',
      'Errore: Il browser non supporta le funzioni di geolocalizzazionep': 'Fehler: Der Browser unterstützt keine Geolokalisierungsfunktionen.',
      'Stai già impostando una location Salvare o impostare l operazione corrente prima di continuare': 'Sie richten bereits einen Standort ein\nSpeichern oder stellen Sie den aktuellen Vorgang ein, bevor Sie fortfahren',
      'Selezionare almeno un Cliente': 'Wählen Sie mindestens einen Kunden aus',
      'Giro salvato': 'Tour Planung gespeichert',
      'Tipo Contatto': 'Kontaktart',
      'Finalità Contatto': 'Kontaktgrund',
      'Prodotti presentati': 'Vorgestellte Produkte',
      'Nessun prodotto selezionato': 'Kein Produkt ausgewählt',
      OK: 'OK',
      'Prodotti con problematiche': 'Produkte mit Problemen',
      'Note / Testo libero': 'Notizen / Freitext',
      Campionature: 'Bemusterung',
      'Nessuna campionatura selezionata': 'Keine Bemusterung ausgewählt',
      Campioni: 'Muster',
      Marketing: 'Marketing',
      Aggiungi: 'Hinzufügen',
      'Nessun campione presente': 'Kein Muster vorhanden',
      'Seleziona un campione': 'Wählen Sie ein Muster',
      'Esito visita': 'Besuchsergebnis',
      'Feedback non trovato o non modificabile': 'Feedback nicht gefunden oder nicht möglich zu ändern',
      'Cliente non trovato': 'Kunde nicht gefunden',
      'Sei sicuro di voler eliminare questo Feedback?': 'Möchten Sie dieses Feedback wirklich löschen?',
      'Feedback eliminato': 'Feedback gelöscht',
      'Selezionare un Cliente': 'Wählen Sie einen Kunden aus',
      'Selezionare la Data di visita': 'Wählen Sie das Besuchsdatum aus',
      'Selezionare il tipo di contatto': 'Wählen Sie die Kontaktart aus',
      'Selezionare la finalità del contatto': 'Wählen Sie den Kontaktzweck aus',
      'Specificare rating prodotti presentati': 'Geben Sie die Bewertung der vorgestellten Produkten ein',
      'Specificare rating prodotti con problematiche': 'Geben Sie die Bewertung der Produkten mit Problemen ein',
      'Indicare l argomento della prossima visita': 'Geben Sie das Thema des nächsten Besuchs an',
      'Selezionare l esito della visita': 'Wählen Sie das Besuchsergebnis aus',
      'Feedback generato': 'Feedback generiert',
      'Feedback modificato': 'Feedback geändert',
      'Prodotto già presente': 'Produkt bereits vorhanden',
      'Ricerca libera': 'Freie Suche',
      'Digita qui la parola o le parole da cercare': 'Geben Sie hier das Wort oder die Wörter, die Sie suchen, ein',
      'Comandi di ricerca': 'Suchbefehle',
      'Comandi riga1': '* nach einem Wort (oder einem Wortteil) ermöglicht die Suche nach allen Wörtern, die mit der eingegebenen Zeichenfolge beginnen, zu finden',
      'Comandi riga2': '= - vor einem Wort (oder einem Wortteil) können Sie alle Wörter ausschließen, die die eingegebene Zeichenfolge enthalten.',
      'Ricerca testo in': 'Textsuche in',
      'Presentazione prodotti': 'Produktvorstellung',
      Cerca: 'Suchen',
      Filtro: 'Filter',
      'Deve contenere': 'Es muss enthalten',
      'Prodotti con problemi': 'Produkte mit Problemen',
      Testo: 'Text',
      'Reset / Nuova Ricerca': 'Zurücksetzen / Neue Suche',
      'Ordina campioni': 'Muster bestellen',
      'Sei sicuro di voler resettare il form di ricerca?': 'Möchten Sie das Suchformular wirklich zurücksetzen?',
      Reset: 'Zurücksetzen',
      'Visualizza solo': 'Nur ansehen',
      'Argomento prossima visita': 'Argument nächster Besuch',
      'Totale Feedbacks': 'Gesamt-Feedbacks',
      'Data Visita': 'Besuchsdatum',
      'Presentazione Prodotti': 'Produktvorstellung',
      'Gestione problematiche': 'Problemmanagement',
      'Nessun feedback trovato secondo i parametri di ricerca impostati': 'Kein Feedback gemäß den eingestellten Suchkriterien gefunden',
      'Selezionare almeno un campo di ricerca testo': 'Wählen Sie mindestens ein Textsuchfeld aus',
      'Seleziona almeno un campo in cui ricercare il testo': 'Wählen Sie mindestens ein Feld aus, in dem nach Text gesucht werden soll',
      'Imposta i parametri di ricerca!': 'Stellen Sie Ihre Suchparameter ein!',
      'Digita qui la parola o le parole da inserire': 'Geben Sie hier das einzugebende Wort oder die einzugebenden Wörter ein',
      'Etichette immagini selezionate': 'Ausgewählte Bildetiketten',
      'Nessuna immagine selezionata': 'Keine Bildetikette ausgewählt.',
      'Sei sicuro di voler rimuovere questa Etichetta da #Numero# immagini?': 'Möchten Sie diese Etikette aus {0} Bildern wirklich entfernen?',
      'Etichette da aggiungere': 'Etikette zum Hinzufügen',
      'Nessuna etichetta inserita': 'Keine Etikette hinzugefügt',
      Operazioni: 'Operationen',
      'Aggiungi Etichetta': 'Etikette hinzufügen',
      'Elimina Immagini': 'Bilder löschen',
      'Sei sicuro di voler aggiornare le etichette di #Numero# immagini?': 'Möchten Sie wirklich die Etiketten von {0} Bildern aktualisieren?',
      'Sei sicuro di voler eliminare #Numero# immagini?': 'Are you sure you want to delete {0} images?',
      'Totale Immagini': 'Gesamtzahl von den Bildern',
      Etichette: 'Etiketten',
      'Nessuna immagine trovata secondo i parametri di ricerca impostati': 'Keine Bilder gemäß den eingestellten Suchkriterien gefunden',
      'Sei sicuro di voler rimuovere quest Immagine?': 'Möchten Sie dieses Bild wirklich entfernen?',
      'Immagine eliminata': 'Bild gelöscht',
      'Immagini eliminate': 'Bilder gelöscht',
      'Etichetta rimossa': 'Etikette entfernt',
      'ERRORE: Etichetta non rimossa': 'FEHLER: Etikette nicht entfernt',
      'Etichette aggiornate': 'Etikette aktualisiert',
      'Selezionare almeno un immagine': 'Wählen Sie mindestens ein Bild aus',
      'Selezionare almeno un Testo Libero o un Cliente o un Prodotto da inserire': 'Wählen Sie mindestens einen Freitext oder einen Kunden oder ein Produkt zum Einfügen aus',
      'Etichetta già presente': 'Etikette schon vorhanden',
      'Imposta le proprietà dell immagine!': 'Bildeigenschaften einstellen!',
      'Carica immagini': 'Bilder hochladen',
      'Solo uso interno': 'Nur zur vertraulichen Verwendung',
      'Cliente sconosciuto': 'Unbekannter Kunde',
      'Prodotti nelle immagini': 'Produkte auf den Bildern sichtbar',
      Carica: 'Hochladen',
      'Immagini pre-caricate': 'Vorinstallierte Bilder',
      'Caricare almeno un immagine': 'Laden Sie mindestens ein Bild hoch',
      'Compilare tutti campi dei prodotti': 'Füllen Sie alle Produktfelder aus',
      'Immagini caricate': 'Hochgeladene Bilder',
      'Immagine caricata': 'Hochgeladenes Bild',
      'Buongiorno #nome#': 'Hallo {0}',
      'Seleziona agente': 'Vertreter auswählen',
      'Fatturato #Mese#': 'Umsatz {0}',
      'Clienti visitati a #Mese#': 'Besuchte Kunden in {0}',
      'Visite clienti univoci': 'Besuche der einzigartigen Kunden',
      Visitati: 'Besuchte Kunden',
      Obiettivo: 'Ziel',
      Attuale: 'Aktuell',
      'Aggiungi Microarea': 'Mikrobereich hinzufügen',
      '#Numero# selezionatip': '{0} ausgewählt',
      'Pulisci selezione': 'Auswahl saubern',
      'Assegna selezionati a microarea': 'Ausgewählte zu Mikrobereich zuweisen',
      'Errore nella eliminazione della microarea': 'Fehler beim Löschen des Mikrobereichs',
      'Errore nell aggiunta della microarea': 'Fehler beim Hinzufügen des Mikrobereichs',
      'Errore nell aggiornamento della microarea': 'Fehler beim Aktualisieren des Mikrobereichs',
      'Clienti assegnati': 'Zugewiesene Kunden',
      'Errore nell assegnazione della microarea': 'Fehler bei der Zuweisung des Mikrobereichs',
      'Ordine Effettuato': 'Bestellung zugestellt',
      'Una mail di riepilogo è stata mandata a #mail#': 'Eine zusammenfassende E-Mail wurde an {0} gesendet.',
      'Dati di spedizione': 'Versanddaten',
      'Azienda o Nome': 'Firma oder Name',
      'Via e numero civico': 'Straße und Hausnummer',
      'Rimuovere il prodotto #codice#?': 'Produkt {0} entfernen?',
      campioni: 'Muster',
      marketing: 'Marketing',
      'Salva bozza': 'Entwurf speichern',
      'Conferma ordine': 'Bestellung bestätigen',
      'Non Salvare': 'Nicht Speichern',
      'Il carrello è stato modificato, salvarlo prima di uscire?': 'Der Warenkorb wurde geändert, möchten Sie vor dem Beenden speichern?',
      Obbligatorio: 'Pflichtfeld',
      'E-Mail non valida': 'Ungültige E-Mail',
      'I seguenti campioni non sono più disponibili e non sono stati aggiunti all ordine:': 'Folgende Muster sind nicht mehr verfügbar und wurden der Bestellung nicht hinzugefügt:',
      'Seleziona un Prodotto': 'Wähle ein Produkt aus',
      'Seleziona un Campione': 'Wähle ein Muster aus',
      'Compilare i dati di spedizione': 'Füllen Sie die Versandinformationen aus',
      'Selezionare almeno un prodotto': 'Wählen Sie mindestens ein Produkt aus',
      'Selezionare il tipo di articolo': 'Wählen Sie die Art des Artikels aus',
      'Compilare i campi dell articolo': 'Füllen Sie die Felder des Artikels aus',
      'Si è verificato un errore': 'Ein Fehler ist aufgetreten',
      'Bozza salvata': 'Entwurf gespeichert',
      'Spedisci ad un indirizzo diverso da quello di fatturazione': 'An eine andere Adresse als die Rechnungsadresse versenden',
      'Dati di fatturazione': 'Rechnungsdaten',
      'Partita Iva': 'VAT-Nr.',
      'Codice Fiscale': 'Steuernummer',
      IBAN: 'IBAN',
      Banca: 'Bank',
      'Email Notifica Ordine': 'Bestellbenachrichtigungs-E-Mail',
      Altp: 'Breite',
      Lunp: 'Länge',
      Qty: 'Menge',
      prodotto: 'Produkt',
      campione: 'Muster',
      'Note Extra': 'Zusätzliche Hinweise',
      'Richiedi autorizzazione': 'Autorisierung anfordern',
      Email: 'E-Mail',
      'Descrizione Campione': 'Musterbeschreibung',
      Prodotti: 'Produkte',
      Forato: 'Gelocht',
      'Non Forato': 'Ungelocht',
      'Compilare i dati di fatturazione': 'Rechnungsdaten ausfüllen',
      'Compilare la mail di autorizzazione con una mail valida': 'Füllen Sie die Autorisierungs-E-Mail mit einer gültigen E-Mail aus',
      'Selezionare un altezza': 'Breite wählen',
      'Selezionare una lunghezza': 'Länge wählen',
      'Selezionare le misure dell articolo': 'Wählen Sie die Artikel-Abmessungen',
      Totp: 'Gesamt',
      Scadenza: 'Fälligkeit',
      Fattp: 'Rechnung',
      Pagam: 'Zahlung',
      Ritar: 'Verspätung',
      Lingua: 'Sprache',
      visita: 'Besuch',
      telefonico: 'telefonisch',
      assente: 'abwesend',
      vendita: 'Verkauf',
      assistenza: 'Kundendienst',
      incasso: 'Rechnungseinzug',
      'Visualizzato su mappa': 'Auf der Karte angezeigt',
      'È possibile inserire più mail separate da virgola ( , )': 'Es ist möglich, mehrere E-Mails durch Kommas (,) getrennt einzufügen.',
      Tagli: 'Absteckungen',
      CodpClasse: 'Class code',
      CodpSuperclasse: 'Superclass code'
    }
  },
  nl: {
    messages: {
    }
  }
}
