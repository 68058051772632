<template>
  <v-app>
    <Header />
    <v-main>
      <router-view />
    </v-main>
    <show-message />
  </v-app>
</template>

<script>

import ShowMessage from './components/ShowMessage.vue'
import Header from './components/Header.vue'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    ShowMessage,
    Header
  },

  /*
router.beforeEach((toRoute, fromRoute, next) => {
  // Vue.$t('messages.' +
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Estelio | APA'
  next()
}) */

  watch: {
    $route (to, from) {
      if (from.path !== to.path) {
        this.$nextTick(() => {
          this.$vuetify.goTo(0, { duration: 0 })
        })
      }
      this.setTitle(to)
    }
  },

  async created () {
    /*
    this.selectedLanguage = this.$route.params.lang

    this.$store.dispatch('language/set', this.selectedLanguage ? this.selectedLanguage : '')
    this.$i18n.locale = this.$store.getters['language/languageFull']
    */
    // console.log(this.$t('generic.non esiste').replace(/^(generic\.)/, ''))
    // console.log(this.$t('messages.test / : '))

    const isAuthenticated = JSON.parse(this.$parent.$el.attributes['data-is-authenticated'].value)
    const user = JSON.parse(this.$parent.$el.attributes['data-user'].value)

    const selectedLanguage = JSON.parse(this.$parent.$el.attributes['data-language'].value)
    this.$store.dispatch('language/set', selectedLanguage)
    this.$i18n.locale = this.$store.getters['language/languageFull']

    const payload = { isAuthenticated: isAuthenticated, user: user }
    this.$store.dispatch('security/onRefresh', payload)

    axios.interceptors.response.use(undefined, (err) => {
      return new Promise(() => {
        if (err.response.status === 401) {
          if (this.$route.path === '/login') {
            throw err
          }
          const payload = { isAuthenticated: false, user: {} }
          this.$store.dispatch('security/onRefresh', payload)
          this.$router.push({ path: '/login', query: { redirect: this.$router.currentRoute.fullPath } })
        } else if (err.response.status === 403) {
          this.$router.push({ path: '/home' })
        } else if (err.response.status === 500) {
          /* document.open();
          document.write(err.response.data);
          document.close(); */
        }
        throw err
      })
    })

    this.setTitle(this.$router.currentRoute)
  },
  methods: {
    setTitle (to) {
      const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
      // Se traduzione, scommenta la riga sotto
      // if (nearestWithTitle) document.title = this.$t('title.' + nearestWithTitle.meta.title) + ' | APA'
      if (nearestWithTitle) document.title = this.$t('messages.' + nearestWithTitle.meta.title) + ' | APA'
    }
  }
}
</script>
<style>
.persistent-placeholder .v-label { transform: translateY(-18px) scale(.75); }
.custom-transition-element {
  transition: all 0.5s;
}
.v-input--selection-controls{
  margin-top: 0px;
  padding-top: 0px;
}
.v-btn:not(.v-btn--round).v-size--default{
  margin-left: -4px;
}
.custom-transition-element {
  transition: all 0.5s;
}
.transition-element-enter-active,
.transition-element-leave-active {
  transition: all 0.3s;
  transition-timing-function: linear;
  max-height:200px;
}
.transition-element-enter,
.transition-element-leave-to
{
  opacity: 0;
  max-height: 0px;
}
.v-text-field--filled .v-label--active, .v-text-field--full-width .v-label--active {
  transform: translateY(-18px) scale(0.75) !important;
}
.gm-style-iw.gm-style-iw-c {
    padding-top:0px;
}
</style>
