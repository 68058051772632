<template>
  <div class="container px-0 px-sm-3">
    <div class="d-flex align-center justify-space-around ml-1">
      <h1>{{ $t('messages.Scheda Clienti Compatta2') }}</h1>
    </div>
    <v-progress-circular
      v-if="isLoading || isInitializing"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <area-selector
        v-model="filter.selectedArea"
        :areas="areasVisibleToAgent"
        :label="$t('messages.Filtra area')"
        @input="getReportClientCompact"
      />
      <div
        v-if="reportClientCompact.length > 0"
      >
        <!--<v-btn
          v-if="isAdmin"
          color="#e74c3c"
          dark
          class="btn btn-lg"
          type="button"
          elevation="0"
          tile
          :loading="isLoading"
          @click="esportaPDF"
        >
          <v-icon>
            mdi-file-pdf-outline
          </v-icon>
          {{ $t('messages.Esporta in PDF') }}
        </v-btn>-->
        <v-autocomplete
          v-model="filter.selectedMicroArea"
          :items="filter.microareas"
          :label="$t('messages.Seleziona una Microarea')"
          :placeholder="$t('messages.Seleziona una Microarea')"
          clearable
          filled
          hide-details
          :menu-props="{
            maxHeight:200,
          }"
          @input="doFilter"
        >
          <template slot="no-data">
            <v-list-item>
              <v-list-item-content>
                {{ $t('messages.Nessuna microarea presente') }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="filter.selectedClient"
          :items="filter.clients"
          :label="$t('messages.Seleziona un Cliente')"
          :placeholder="$t('messages.Seleziona un Cliente')"
          clearable
          filled
          hide-details
          :menu-props="{
            maxHeight:200,
          }"
          @input="doFilter"
        >
          <template slot="no-data">
            <v-list-item>
              <v-list-item-content>
                {{ $t('messages.Nessun Cliente trovato') }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="filter.selectedProducts"
          :items="filter.products"
          multiple
          :label="$t('messages.Seleziona un codice commerciale')"
          :placeholder="$t('messages.Seleziona un codice commerciale')"
          clearable
          filled
          hide-details
          :menu-props="{
            maxHeight:200,
          }"
          @input="doFilter"
        >
          <template slot="no-data">
            <v-list-item>
              <v-list-item-content>
                {{ $t('messages.Nessun codice commerciale presente') }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>

        <v-radio-group
          v-model="order"
          class="my-2"
          hide-details
          row
          @change="reOrder"
        >
          <v-radio
            :label="$t('messages.Ordina per nome')"
            value="nome"
          />
          <v-radio
            :label="$t('messages.Ordina per cap')"
            value="cap"
          />
        </v-radio-group>

        <div class="mb-2">
          <v-btn
            color="primary"
            dark
            class="btn"
            type="button"
            elevation="0"
            tile
            @click="changeView"
          >
            {{ $t('messages.Visualizza Compatta Classica') }}
          </v-btn>
        </div>

        <div
          v-for="(client, j) in filteredReport"
          :key="j"
        >
          <div
            class="agent-header px-1 d-flex"
            :style="'z-index: 3;position: sticky;top: 48px;'"
          >
            <h4
              :style="client.client_id ? 'cursor: pointer;':''"
              @click="viewClient(client.client_id)"
            >
              {{ client.cap }} - {{ client.azienda }} - {{ client.cd_clien }}
            </h4>
          </div>

          <div
            style="overflow-x: auto; overflow-y: hidden;"
            class="mx-1"
          >
            <table class="my-2">
              <thead>
                <tr>
                  <th
                    v-for="(hr, l) in headers"
                    :key="l"
                    :class="hr.class"
                    :style="hr.width?('min-width: ' + hr.width):''"
                  />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(product, k) in client.filteredProducts"
                  :key="k"
                >
                  <td>
                    <div>
                      <span class="report-colore">
                        {{ product.colore_calcolato }}
                      </span>
                    </div>
                  </td>

                  <template
                    v-for="(hr, m) in headers"
                  >
                    <td
                      v-if="!hr.hidden"
                      :key="m"
                      class="text-right"
                      :style="calculateCellStyle(product.max, product.min, product[hr.value], m)"
                    />
                  </template>
                  <td class="text-right">
                    <span class="report-colore">
                      Max: {{ euroFormat(product.max) }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="border-top: 3px double black;">
                    <span
                      class="report-colore"
                      style="font-size: 0.965rem;"
                    >
                      TOTALE
                    </span>
                  </td>
                  <template
                    v-for="(hr, m) in headers"
                  >
                    <td
                      v-if="!hr.hidden"
                      :key="m"
                      class="text-right"
                      style="border-top: 3px double black;"
                      :style="calculateCellStyle(client.max, 0, client[hr.value], m)"
                    />
                  </template>
                  <td
                    class="text-right"
                    style="border-top: 3px double black;"
                  >
                    <span class="report-colore">
                      Max: {{ euroFormat(client.max) }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import JsPDF from 'jspdf'
import 'jspdf-autotable'
import AreaSelector from '../../components/AreaSelector'

export default {
  name: 'ReportClientSales',

  components: {
    AreaSelector
  },

  data: () => ({
    reportClientCompact: [],
    isLoading: false,
    filteredReport: [],
    areasVisibleToAgent: [],
    microAreasVisibleToAgent: [],
    filter: {
      selectedArea: '',
      microareas: [],
      selectedMicroArea: '',
      clients: [],
      selectedClient: '',
      products: [],
      selectedProducts: []
    },
    isFiltering: false,
    todayDate: new Date().toISOString().substr(0, 10),
    isInitializing: 1,
    order: 'nome',
    initializeFilter: false
  }),

  computed: {
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    isAdmin () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN')
    },
    headers () {
      return [
        { text: '', value: 'colore', hidden: true },
        { text: '', value: 'months24Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months23Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months22Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months21Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months20Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months19Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months18Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months17Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months16Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months15Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months14Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months13Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months12Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months11Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months10Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months9Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months8Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months7Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months6Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months5Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months4Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months3Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months2Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'months1Ago', align: 'right', width: '10px', filterable: false },
        { text: '', value: 'max', hidden: true, align: 'right', width: '80px' }
      ]
    }
  },

  async created () {
    this.isLoading = true
    this.isInitializing = 2

    this.initializeFilter = false
    Object.keys(this.filter).forEach(key => {
      if (this.$route.query[key] !== undefined) {
        this.initializeFilter = true
        if (key === 'selectedArea' || key === 'selectedMicroArea' || key === 'selectedClient') {
          this.filter[key] = this.$route.query[key]
        } else if (key === 'selectedProducts') {
          // parsa le stringhe come array
          if (this.$route.query[key] !== '') {
            this.filter[key] = this.$route.query[key].split('-')
          }
        }
      }
    })

    this.$store.dispatch('agents/areasVisibleToAgent').then((r) => { this.isInitializing--; this.areasVisibleToAgent = r })
    this.$store.dispatch('agents/microAreasVisibleToAgent').then((r) => { this.isInitializing--; this.microAreasVisibleToAgent = r })
    if (this.filter.selectedArea !== '') {
      await this.getReportClientCompact()
    }
    this.isLoading = false
  },
  methods: {
    changeView () {
      this.$router.push({ path: '/reports/client-compact', query: this.$route.query })
    },

    viewClient (clientId) {
      if (clientId) {
        // this.$router.push({ path: '/client/details/' + clientId })
        window.open('/client/details/' + clientId, '_blank')
      }
    },

    updateFilterQuery () {
      this.$router.replace({
        query: {
          selectedArea: this.filter.selectedArea,
          selectedMicroArea: this.filter.selectedMicroArea,
          selectedClient: this.filter.selectedClient,
          selectedProducts: this.filter.selectedProducts.join('-')
        }
      }).catch(() => {})
    },
    async esportaPDF () {
      // // var body = []
      // const d1 = this.todayDate
      // const datestring = this.formatDate(d1)

      // // let doc = new JsPDF('l') // orizzontale --- new JsPDF() // verticale
      // const doc = new JsPDF('l')
      // const totalPagesExp = '{total_pages_count_string}'

      // const body = []

      // this.filteredReport.forEach((item) => {
      //   let rowToPush = []
      //   rowToPush = [(item.cd_agent + ' ' + (item.area ?? '') + '\n' + (item.cd_clien ? 'Codp cli: ' + item.cd_clien : '') + (item.azienda ? ' - cap: ' + item.cap : '') + '\n' + (item.azienda ? item.azienda : '')),
      //     this.formatDate(item.last_visit_date),
      //     item.tot1.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     item.tot2.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     (item.tot2 - item.tot1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      //     parseFloat(item.gen1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     parseFloat(item.gen2).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     (parseFloat(item.gen2) - parseFloat(item.gen1)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      //     parseFloat(item.feb1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     parseFloat(item.feb2).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     (parseFloat(item.feb2) - parseFloat(item.feb1)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      //     parseFloat(item.mar1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     parseFloat(item.mar2).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     (parseFloat(item.mar2) - parseFloat(item.mar1)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      //     parseFloat(item.apr1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     parseFloat(item.apr2).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     (parseFloat(item.apr2) - parseFloat(item.apr1)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      //     parseFloat(item.mag1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     parseFloat(item.mag2).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     (parseFloat(item.mag2) - parseFloat(item.mag1)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      //     parseFloat(item.giu1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     parseFloat(item.giu2).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     (parseFloat(item.giu2) - parseFloat(item.giu1)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      //     parseFloat(item.lug1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     parseFloat(item.lug2).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     (parseFloat(item.lug2) - parseFloat(item.lug1)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      //     parseFloat(item.ago1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     parseFloat(item.ago2).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     (parseFloat(item.ago2) - parseFloat(item.ago1)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      //     parseFloat(item.set1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     parseFloat(item.set2).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     (parseFloat(item.set2) - parseFloat(item.set1)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      //     parseFloat(item.ott1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     parseFloat(item.ott2).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     (parseFloat(item.ott2) - parseFloat(item.ott1)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      //     parseFloat(item.nov1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     parseFloat(item.nov2).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     (parseFloat(item.nov2) - parseFloat(item.nov1)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      //     parseFloat(item.dic1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     parseFloat(item.dic2).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '\n' +
      //     (parseFloat(item.dic2) - parseFloat(item.dic1)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      //   ]
      //   body.push(rowToPush)
      // })
      // // const base64Img = images.logoBase64()
      // doc.autoTable({
      //   head: [[this.$t('messages.Cliente'),
      //     this.$t('messages.Visita'),
      //     this.$t('messages.Tot'),
      //     this.$t('messages.Gen'),
      //     this.$t('messages.Feb'),
      //     this.$t('messages.Mar'),
      //     this.$t('messages.Apr'),
      //     this.$t('messages.Mag'),
      //     this.$t('messages.Giu'),
      //     this.$t('messages.Lug'),
      //     this.$t('messages.Ago'),
      //     this.$t('messages.Set'),
      //     this.$t('messages.Ott'),
      //     this.$t('messages.Nov'),
      //     this.$t('messages.Dic')]],
      //   body: body,
      //   alternateRowStyles: { fillColor: [232, 233, 252] },
      //   headStyles: {
      //     fillColor: [196, 199, 245],
      //     fontSize: 10,
      //     textColor: 40,
      //     halign: 'center',
      //     valign: 'center'
      //   },
      //   bodyStyles: {
      //     fontSize: 7,
      //     fontStyle: 'bold',
      //     cellPadding: 1,
      //     valign: 'middle'
      //   },
      //   didParseCell: (data) => {
      //     data.row.height = 11
      //   },
      //   columnStyles: {
      //     0: { cellWidth: 'auto', overflow: 'ellipsize' },
      //     1: { cellWidth: 16, halign: 'center' },
      //     2: { cellWidth: 15, halign: 'right' },
      //     3: { cellWidth: 15, halign: 'right' },
      //     4: { cellWidth: 15, halign: 'right' },
      //     5: { cellWidth: 15, halign: 'right' },
      //     6: { cellWidth: 15, halign: 'right' },
      //     7: { cellWidth: 15, halign: 'right' },
      //     8: { cellWidth: 15, halign: 'right' },
      //     9: { cellWidth: 15, halign: 'right' },
      //     10: { cellWidth: 15, halign: 'right' },
      //     11: { cellWidth: 15, halign: 'right' },
      //     12: { cellWidth: 15, halign: 'right' },
      //     13: { cellWidth: 15, halign: 'right' },
      //     14: { cellWidth: 15, halign: 'right' }
      //   },
      //   tableLineColor: [44, 62, 80],
      //   tableLineWidth: 0.1,
      //   styles: {
      //     lineColor: [44, 62, 80],
      //     lineWidth: 0.1
      //   },
      //   didDrawPage: function (data) {
      //     // Header
      //     doc.setFontSize(18)
      //     doc.setTextColor(40)

      //     const currentFont = doc.getFont()
      //     doc.getFont(currentFont.fontName, 'normal', 'normal')
      //     // doc.setFontStyle('normal')
      //     // doc.addImage(base64Img, 'JPEG', data.settings.margin.left, 15, 24.551, 11)
      //     doc.addImage('/logo.jpg', 'JPEG', data.settings.margin.left + 5, 13, 15, 15)
      //     doc.text(this.$t('messages.Report Fatturato Cliente del #data#', [datestring]), data.settings.margin.left + 24.551, 22)

      //     // Footer
      //     let str = 'Page ' + doc.internal.getNumberOfPages()
      //     // Total page number plugin only available in jspdf v1.0+
      //     if (typeof doc.putTotalPages === 'function') {
      //       str = str + ' of ' + totalPagesExp
      //     }
      //     doc.setFontSize(7)

      //     // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      //     const pageSize = doc.internal.pageSize
      //     const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
      //     doc.text(str, data.settings.margin.left, pageHeight - 10)
      //   },
      //   margin: { top: 30 }
      // })

      // // Total page number plugin only available in jspdf v1.0+
      // if (typeof doc.putTotalPages === 'function') {
      //   doc.putTotalPages(totalPagesExp)
      // }
      // doc.save(this.$t('ReportFatturatoClienteppdf'))
    },
    calculateCellStyle (max, min, current, index) {
      let style = ''
      if (max === 0) { max = 0.001 }
      if (current >= 0) {
        const perc = current / max
        const saturation = 66.7 * perc
        const light = 43.5 + ((88 - 43.5) * (1 - perc))
        // min: hsl(219, 0%, 88%)
        // max: hsl(219,66.7%,43.5%)
        style += 'background-color: hsl(219,' + saturation + '%' + ',' + light + '%);'
      } else {
        let perc = current / max * -1

        if (perc > 1) {
          perc = 1
        }

        const saturation = 100 * perc
        const light = 43.5 + ((88 - 50) * (1 - perc))
        // min: hsl(219, 0%, 88%)
        // max: hsl(0,100%,50%)
        style += 'background-color: hsl(0,' + saturation + '%' + ',' + light + '%);'
      }
      if ((index - 1) % 3 === 0) {
        style += 'border-left: 1px solid black;'
      }
      return style
    },
    async getReportClientCompact () {
      if (!this.filter.selectedArea) {
        return
      }
      this.updateFilterQuery()
      if (!this.initializeFilter) {
        this.filter.microareas = []
        this.filter.selectedMicroArea = ''
        this.filter.clients = []
        this.filter.selectedClient = ''
        this.filter.products = []
        this.filter.selectedProducts = []
      }
      this.order = 'nome'
      this.isLoading = true
      this.reportClientCompact = await this.$store.dispatch('reports/getReportClientCompactColor', this.filter.selectedArea)
      this.reportClientCompact.forEach((c) => {
        c.months24Ago = 0
        c.months23Ago = 0
        c.months22Ago = 0
        c.months21Ago = 0
        c.months20Ago = 0
        c.months19Ago = 0
        c.months18Ago = 0
        c.months17Ago = 0
        c.months16Ago = 0
        c.months15Ago = 0
        c.months14Ago = 0
        c.months13Ago = 0
        c.months12Ago = 0
        c.months11Ago = 0
        c.months10Ago = 0
        c.months9Ago = 0
        c.months8Ago = 0
        c.months7Ago = 0
        c.months6Ago = 0
        c.months5Ago = 0
        c.months4Ago = 0
        c.months3Ago = 0
        c.months2Ago = 0
        c.months1Ago = 0
        c.max = 0
        if (c.microarea && this.filter.microareas.findIndex((m) => m === c.microarea) < 0) {
          this.filter.microareas.push(c.microarea)
        }
        if (this.filter.clients.findIndex((c) => c === c.azienda) < 0) {
          this.filter.clients.push(c.azienda)
        }

        c.products.forEach((p) => {
          if (this.filter.products.findIndex((prod) => prod === p.colore_calcolato) < 0) {
            this.filter.products.push(p.colore_calcolato)
          }

          p.months24Ago = parseFloat(p.months24Ago)
          p.months23Ago = parseFloat(p.months23Ago)
          p.months22Ago = parseFloat(p.months22Ago)
          p.months21Ago = parseFloat(p.months21Ago)
          p.months20Ago = parseFloat(p.months20Ago)
          p.months19Ago = parseFloat(p.months19Ago)
          p.months18Ago = parseFloat(p.months18Ago)
          p.months17Ago = parseFloat(p.months17Ago)
          p.months16Ago = parseFloat(p.months16Ago)
          p.months15Ago = parseFloat(p.months15Ago)
          p.months14Ago = parseFloat(p.months14Ago)
          p.months13Ago = parseFloat(p.months13Ago)
          p.months12Ago = parseFloat(p.months12Ago)
          p.months11Ago = parseFloat(p.months11Ago)
          p.months10Ago = parseFloat(p.months10Ago)
          p.months9Ago = parseFloat(p.months9Ago)
          p.months8Ago = parseFloat(p.months8Ago)
          p.months7Ago = parseFloat(p.months7Ago)
          p.months6Ago = parseFloat(p.months6Ago)
          p.months5Ago = parseFloat(p.months5Ago)
          p.months4Ago = parseFloat(p.months4Ago)
          p.months3Ago = parseFloat(p.months3Ago)
          p.months2Ago = parseFloat(p.months2Ago)
          p.months1Ago = parseFloat(p.months1Ago)

          c.months24Ago += p.months24Ago
          c.months23Ago += p.months23Ago
          c.months22Ago += p.months22Ago
          c.months21Ago += p.months21Ago
          c.months20Ago += p.months20Ago
          c.months19Ago += p.months19Ago
          c.months18Ago += p.months18Ago
          c.months17Ago += p.months17Ago
          c.months16Ago += p.months16Ago
          c.months15Ago += p.months15Ago
          c.months14Ago += p.months14Ago
          c.months13Ago += p.months13Ago
          c.months12Ago += p.months12Ago
          c.months11Ago += p.months11Ago
          c.months10Ago += p.months10Ago
          c.months9Ago += p.months9Ago
          c.months8Ago += p.months8Ago
          c.months7Ago += p.months7Ago
          c.months6Ago += p.months6Ago
          c.months5Ago += p.months5Ago
          c.months4Ago += p.months4Ago
          c.months3Ago += p.months3Ago
          c.months2Ago += p.months2Ago
          c.months1Ago += p.months1Ago
        })
        c.max = Math.max(c.months24Ago, c.months23Ago, c.months22Ago, c.months21Ago, c.months20Ago, c.months19Ago, c.months18Ago, c.months17Ago, c.months16Ago, c.months15Ago, c.months14Ago, c.months13Ago, c.months12Ago, c.months11Ago, c.months10Ago, c.months9Ago, c.months8Ago, c.months7Ago, c.months6Ago, c.months5Ago, c.months4Ago, c.months3Ago, c.months2Ago, c.months1Ago)

        c.filteredProducts = c.products.filter(() => true)
      })
      this.filter.microareas.sort()
      this.filter.clients.sort()
      this.filter.products.sort()
      this.filteredReport = this.reportClientCompact.filter(() => true)
      if (this.initializeFilter) {
        this.initializeFilter = false
        this.doFilter()
      }
      this.isLoading = false
    },

    reOrder () {
      this.filteredReport.sort((x, y) => {
        if (this.order === 'nome') {
          if (x.azienda > y.azienda) {
            return 1
          }
          if (x.azienda < y.azienda) {
            return -1
          }
        } else if (this.order === 'cap') {
          if (x.cap > y.cap) {
            return 1
          }
          if (x.cap < y.cap) {
            return -1
          }
          if (x.cap === y.cap) {
            if (x.azienda > y.azienda) {
              return 1
            }
            if (x.azienda < y.azienda) {
              return -1
            }
          }
        }
        return 0
      })
    },

    async doFilter () {
      this.updateFilterQuery()
      this.filteredReport = this.reportClientCompact.filter(c => {
        let isFiltered = (
          ((this.filter.selectedMicroArea ?? '') === '' || c.microarea === this.filter.selectedMicroArea) &&
          ((this.filter.selectedClient ?? '') === '' || c.azienda === this.filter.selectedClient)
          // && ((this.filter.selectedAgent ?? '') === '' || areasSubAgentFilter.some((area) => { return c.cd_agent === area.cd_agent }))
        )
        if (isFiltered) {
          c.months24Ago = 0
          c.months23Ago = 0
          c.months22Ago = 0
          c.months21Ago = 0
          c.months20Ago = 0
          c.months19Ago = 0
          c.months18Ago = 0
          c.months17Ago = 0
          c.months16Ago = 0
          c.months15Ago = 0
          c.months14Ago = 0
          c.months13Ago = 0
          c.months12Ago = 0
          c.months11Ago = 0
          c.months10Ago = 0
          c.months9Ago = 0
          c.months8Ago = 0
          c.months7Ago = 0
          c.months6Ago = 0
          c.months5Ago = 0
          c.months4Ago = 0
          c.months3Ago = 0
          c.months2Ago = 0
          c.months1Ago = 0
          c.max = 0

          c.filteredProducts = c.products.filter(p => {
            const isProductFiltered = (
              this.filter.selectedProducts.length === 0 ||
              this.filter.selectedProducts.findIndex((sp) => sp === p.colore_calcolato) >= 0
            )
            if (isProductFiltered) {
              c.months24Ago += p.months24Ago
              c.months23Ago += p.months23Ago
              c.months22Ago += p.months22Ago
              c.months21Ago += p.months21Ago
              c.months20Ago += p.months20Ago
              c.months19Ago += p.months19Ago
              c.months18Ago += p.months18Ago
              c.months17Ago += p.months17Ago
              c.months16Ago += p.months16Ago
              c.months15Ago += p.months15Ago
              c.months14Ago += p.months14Ago
              c.months13Ago += p.months13Ago
              c.months12Ago += p.months12Ago
              c.months11Ago += p.months11Ago
              c.months10Ago += p.months10Ago
              c.months9Ago += p.months9Ago
              c.months8Ago += p.months8Ago
              c.months7Ago += p.months7Ago
              c.months6Ago += p.months6Ago
              c.months5Ago += p.months5Ago
              c.months4Ago += p.months4Ago
              c.months3Ago += p.months3Ago
              c.months2Ago += p.months2Ago
              c.months1Ago += p.months1Ago
              c.max = Math.max(c.months24Ago, c.months23Ago, c.months22Ago, c.months21Ago, c.months20Ago, c.months19Ago, c.months18Ago, c.months17Ago, c.months16Ago, c.months15Ago, c.months14Ago, c.months13Ago, c.months12Ago, c.months11Ago, c.months10Ago, c.months9Ago, c.months8Ago, c.months7Ago, c.months6Ago, c.months5Ago, c.months4Ago, c.months3Ago, c.months2Ago, c.months1Ago)
            }
            return isProductFiltered
          })
          if (c.filteredProducts.length === 0) {
            isFiltered = false
          }
        }
        return isFiltered
      })
    },
    formatDate (date) {
      if (date === null) return ''

      const a = new Date(date)
      if (!a) return ''
      if (isNaN(a.getFullYear())) { return '' }
      const year = a.getFullYear()
      const month = ('0' + (a.getMonth() + 1)).slice(-2)
      const day = ('0' + a.getDate()).slice(-2)
      const time = day + '/' + month + '/' + year
      return time
    },
    euroFormat (value) {
      return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €'
    }
  }
}
</script>

<style scoped>
table > thead > tr > th, table > tbody > tr > td {
  padding: 0px;
  padding-right: 4px;
  padding-left: 4px;
}
.reportval {
  font-size: 0.665rem;
}
.reportval.highlight {
  font-weight: bold;
  font-size: 0.875rem;
}
.reportval.highlight.reportval-negative {
  color: red
}
table th {
  position: sticky;
  top: 0;
}
table th:first-child {
  position: sticky;
  left: 0;
  top: 0;
  background-color: #ddd;
  margin-right: 4px;
  padding-right: 4px;
  max-width: 160px;
  width: 120px;
  z-index: 1;
}
table td:first-child {
  position: sticky;
  left: 0;
  background-color: #ddd;
  margin-right: 4px;
  padding-right: 4px;
  min-width: 120px;
  max-width: 160px;
}
table td:nth-child(2n+3) {background: rgb(230, 230, 230)}
.report-azienda {
  font-size: 0.665rem;
  font-weight: bold;
}
.report-colore {
  font-size: 0.665rem;
  font-weight: bold;
}
.report-cdclien {
  font-size: 0.665rem;
  font-weight: bold;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 40px;
}
.v-expansion-panel > .v-expansion-panel-header {
  min-height: 40px;
}
.accordion-title {
  font-size: 0.7rem !important;
}
table > tbody > tr > th, table > thead > tr > th, table > tfoot > tr > th {
    font-size: 0.75rem;
}

.agent-header {
  background-color: #5e5e5e;
  min-height: 21px;
  color: white;
  font-size: 0.875rem;
}

table > thead > tr:last-child > th {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
table {
    border-spacing: 0;
}
::v-deep.v-pagination__navigation {
  width: 24px;
  height: 24px;
}
::v-deep.v-pagination__item {
  min-width: 24px;
  height: 24px
}
</style>
