var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(_vm.isInitializing != 0)?_c('v-progress-circular',{staticClass:"ml-3",attrs:{"indeterminate":"","color":"primary"}}):_c('div',[_c('div',{staticClass:"row col"},[_c('h1',[_vm._v(_vm._s(_vm.$t('messages.Gestione Classi')))])]),_c('h4',[_vm._v(_vm._s(_vm.$t('messages.Cerca in'))+":")]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('v-checkbox',{staticClass:"mx-2 my-1",attrs:{"hide-details":"","dense":"","label":_vm.$t('messages.CodpClasse')},on:{"change":_vm.debounceSearch},model:{value:(_vm.searchFilter.cdClass),callback:function ($$v) {_vm.$set(_vm.searchFilter, "cdClass", $$v)},expression:"searchFilter.cdClass"}}),_c('v-checkbox',{staticClass:"mx-2 my-1",attrs:{"hide-details":"","dense":"","label":_vm.$t('messages.CodpSuperclasse')},on:{"change":_vm.debounceSearch},model:{value:(_vm.searchFilter.cdSuperClass),callback:function ($$v) {_vm.$set(_vm.searchFilter, "cdSuperClass", $$v)},expression:"searchFilter.cdSuperClass"}})],1),_c('v-text-field',{attrs:{"filled":"","dense":"","hide-details":"","loading":_vm.isLoadingFilter,"placeholder":_vm.$t('messages.Cerca ppp')},on:{"input":_vm.debounceSearch},model:{value:(_vm.searchFilter.search),callback:function ($$v) {_vm.$set(_vm.searchFilter, "search", $$v)},expression:"searchFilter.search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredClassList,"loading":_vm.isLoading,"locale":_vm.$t('messages.locale'),"elevation":"0","header-props":{ sortByText: _vm.$t('messages.Ordina per')},"footer-props":{
        itemsPerPageAllText: _vm.$t('messages.Tutte'),
        itemsPerPageText: _vm.$t('messages.Per pagina')
      },"page":_vm.searchFilter.page,"items-per-page":_vm.searchFilter.itemsPerPage},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item.cd_superclasse",fn:function(ref){
      var item = ref.item;
return [_c('v-autocomplete',{attrs:{"value":item.cd_superclasse,"items":_vm.superClasses,"loading":_vm.isLoading,"menu-props":{
            maxHeight:200,
          },"hide-details":"","dense":"","label":""},on:{"input":function($event){return _vm.updateSuperClass(item, $event)}},scopedSlots:_vm._u([{key:"item",fn:function(appearance){return [_vm._v(" "+_vm._s(appearance.item)+" ")]}},{key:"selection",fn:function(selection){return [_c('v-chip',{staticClass:"mx-1 white--text",attrs:{"color":"primary","small":""}},[_vm._v(" "+_vm._s(selection.item)+" ")])]}}],null,true)})]}},{key:"footer.page-text",fn:function(ref){
          var pageStart = ref.pageStart;
          var pageStop = ref.pageStop;
          var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" di "+_vm._s(itemsLength)+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }