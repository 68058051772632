import axios from 'axios'

export default {
  listNations () {
    const mainAxios = axios.create({
      baseURL: 'https://shop.apaspa.com/api/'
    })
    return mainAxios.get('user/listnationsiso')
  }
}
