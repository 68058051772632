<template>
  <div class="container">
    <v-progress-circular
      v-if="isInitializing != 0"
      indeterminate
      color="primary"
      class="ml-3"
    />
    <div
      v-else
    >
      <div class="row col mb-4">
        <h1>{{ $t('messages.Gestione Campionature') }}</h1>
      </div>

      <v-btn
        color="primary"
        dark
        class="btn btn-lg mr-1"
        type="button"
        :loading="isLoading"
        elevation="0"
        tile
        @click="addNew()"
      >
        {{ $t('messages.Aggiungi Nuovo') }}
      </v-btn>

      <v-btn
        color="green"
        dark
        class="btn btn-lg mx-1"
        type="button"
        elevation="0"
        :loading="isLoading"
        tile
        @click="save()"
      >
        {{ $t('messages.Salva') }}
      </v-btn>
      <v-data-table
        :headers="headers"
        :items="listcampioniprodotti"
        :locale="$t('messages.locale')"
        :items-per-page="-1"
        elevation="0"
        :header-props="{ sortByText: $t('messages.Ordina per')}"
        :footer-props="{
          itemsPerPageAllText: $t('messages.Tutte'),
          itemsPerPageText: $t('messages.Per pagina')
        }"
      >
        <template #[`item.codice`]="{ item }">
          <v-text-field
            v-model="item.codice"
            hide-details
            dense
            @input="setDirty(item)"
          />
        </template>

        <template #[`item.rotolino`]="{ item }">
          <v-checkbox
            v-model="item.rotolino"
            hide-details
            dense
            @change="setDirty(item)"
          />
        </template>

        <template #[`item.printRoll`]="{ item }">
          <v-checkbox
            v-model="item.printRoll"
            hide-details
            dense
            @change="setDirty(item)"
          />
        </template>

        <template #[`item.a4`]="{ item }">
          <v-checkbox
            v-model="item.a4"
            hide-details
            dense
            @change="setDirty(item)"
          />
        </template>

        <template #[`item.a5`]="{ item }">
          <v-checkbox
            v-model="item.a5"
            hide-details
            dense
            @change="setDirty(item)"
          />
        </template>

        <template #[`item.raggruppa`]="{ item }">
          <v-checkbox
            v-model="item.raggruppa"
            hide-details
            dense
            @change="setDirty(item)"
          />
        </template>

        <template #[`item.actions`]="{ item }">
          <v-icon
            medium
            color="error"
            @click="remove(item);setDirty()"
          >
            mdi-delete
          </v-icon>
        </template>

        <template #[`footer.page-text`]="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} di {{ itemsLength }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CampionatureProdottiAssign',

  data: () => ({
    searchFilter: {
      search: '',
      cdClass: true,
      cdSuperClass: true,
      page: 1,
      itemsPerPage: -1
    },
    isInitializing: 1,
    isLoading: false,
    listcampioniprodotti: [],
    isDirty: false,
    newItemCounter: 0
  }
  ),

  computed: {
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
    isAdmin () {
      return this.$store.getters['security/hasRole']('ROLE_ADMIN')
    },
    error () {
      return this.$store.getters['products/error']
    },
    headers () {
      return [
        { text: this.$t('messages.Codp'), value: 'codice', filterable: true, width: '100px' },
        { text: this.$t('messages.Rotolino'), value: 'rotolino', filterable: false, width: '120px' },
        { text: this.$t('messages.Print Roll'), value: 'printRoll', filterable: false, width: '120px' },
        { text: this.$t('messages.A4'), value: 'a4', filterable: false, width: '120px' },
        { text: this.$t('messages.A5'), value: 'a5', filterable: false, width: '120px' },
        { text: this.$t('messages.Solo Linea'), value: 'raggruppa', filterable: false, width: '120px' },
        { text: '', value: 'actions', filterable: false }
      ]
    }
  },

  created () {
    this.isInitializing = 1

    this.$store.dispatch('products/listcampioniprodotti').then((r) => { this.listcampioniprodotti = r; this.isInitializing-- })
  },

  methods: {
    remove (item) {
      const index = this.listcampioniprodotti.findIndex(c => {
        return c.id === item.id
      })
      this.listcampioniprodotti.splice(index, 1)
    },
    setDirty () {
      this.isDirty = true
    },
    addNew () {
      this.listcampioniprodotti.push({
        id: 'new-' + this.newItemCounter,
        codice: '',
        rotolino: false,
        printRoll: false,
        a4: false,
        a5: false,
        raggruppa: false
      })
      this.newItemCounter++
    },
    async save () {
      this.isLoading = true
      const data = { campionatureProdotti: this.listcampioniprodotti }
      const result = await this.$store.dispatch('products/savecampioniprodotti', data)
      if (result !== null) {
        const message = this.$t('messages.Prodotti aggiornati')
        const dataMessage = { isOpen: true, message: message, color: 'success' }
        this.$store.dispatch('appState/setMessageSnackbar', dataMessage)
      }
      this.isLoading = false
    }

  }
}
</script>
