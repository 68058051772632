import axios from 'axios'

export default {
  details (data) {
    return axios.post('/api/giro/details', data)
  },
  save (data) {
    return axios.post('/api/giro/save', data)
  },
  setVisited (data) {
    return axios.post('/api/giro/set-visited', data)
  },
  list (area) {
    return axios.get('/api/giro/list/' + area)
  }
}
